import { isBrowser } from '@wix/editor-elements-common-utils';

export const isSafariVersionLess = (x: number) => {
  if (!isBrowser()) {
    return false;
  }
  const [, safariVersion] =
    navigator?.userAgent?.match(/Version\/(\d+\.\d+)/) || [];
  if (!safariVersion) {
    return false;
  }
  const versionNumber = parseFloat(safariVersion) || Infinity;
  return versionNumber < x;
};
