
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Classicskin2264257968",
      {"classes":{"root":"Classicskin2264257968__root","forced-width":"Classicskin2264257968__forced-width"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  