
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "AccordionContainerHeader936294312",
      {"classes":{"root":"AccordionContainerHeader936294312__root","accordionLabel":"AccordionContainerHeader936294312__accordionLabel","accordionIcon":"AccordionContainerHeader936294312__accordionIcon"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  