import * as React from 'react';
import WPhoto from '../../WPhotoBase';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { BaseWPhotoSkinProps } from '../../../WPhoto.types';
import skinsStyles from './styles/ScotchDoubleVertical.scss';
import ScotchDoubleSkin from './ScotchDoubleSkin';

const ScotchDoubleVerticalSkin: React.FC<
  Omit<BaseWPhotoSkinProps, 'skinsStyle'>
> = props => <ScotchDoubleSkin {...props} skinsStyle={skinsStyles} />;

const ScotchDoubleVertical: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={ScotchDoubleVerticalSkin} />
);

export default ScotchDoubleVertical;
