import * as React from 'react';
import {
  IDatePickerProps,
  IDatePickerImperativeActions,
} from '../../../DatePicker.types';
import DatePickerBase from '../../DatePickerBase';
import NavbarTextYearNavSkin from './NavbarTextYearNavSkin';

const DatePickerTextYearNavSkin: React.ForwardRefRenderFunction<
  IDatePickerImperativeActions,
  IDatePickerProps
> = (props, ref) => (
  <DatePickerBase
    ref={ref}
    {...props}
    NavbarComponent={NavbarTextYearNavSkin}
  ></DatePickerBase>
);

export default React.forwardRef(DatePickerTextYearNavSkin);
