import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';

import {
  PinterestFollowProps,
  PinterestFollowDefinition,
} from '@wix/thunderbolt-components';
import { props as viewerMapperProps } from './PinterestFollow.mapper';

const props = composeMapper<
  PreviewWrapperProps<PinterestFollowProps>,
  PinterestFollowDefinition,
  PinterestFollowProps
>()(
  viewerMapperProps,
  {
    remove: ['fullNameCompType', 'language', 'compId', 'trackClicksAnalytics'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
