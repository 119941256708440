import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getMigratedStyleProps } from '@wix/editor-elements-common-utils';
import type {
  ICollapsibleTextMapperProps,
  CollapsibleTextDefinition,
  ButtonAlignment,
  ICollapsibleTextStateValues,
} from '../CollapsibleText.types';
import { alignmentToStyle } from '../constants';

export const props = withCompInfo<
  ICollapsibleTextMapperProps,
  CollapsibleTextDefinition
>()(
  [
    'compData',
    'compProps',
    'compStylableClass',
    'isConnectedToDataBinding',
    'styleProperties',
    'compId',
    'fullNameCompType',
    'language',
    'mainPageId',
    'trackClicksAnalytics',
  ],
  ({
    compData,
    compProps,
    styleProperties,
    isConnectedToDataBinding,
    compStylableClass: stylableClassName,
    ...restCompProps
  }) => {
    const migratedProps = getMigratedStyleProps({
      compProps,
      styleProperties,
      migratedFields: ['alignButton'],
    });
    return {
      ...compData,
      ...(isConnectedToDataBinding ? { tag: 'div' } : {}),
      ...compProps,
      ...restCompProps,
      ...migratedProps,
      stylableClassName,
      // TODO: Change when correct type is added to TB
      link: compData.link ? (compData.link as any).resolvedLink : null,
    };
  },
);

export const css = withCompInfo()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }) => {
    const { maxLines, expandControlSpacing } = compProps;

    const migratedProps = getMigratedStyleProps({
      compProps,
      styleProperties,
      migratedFields: ['alignButton'],
    });

    const alignSelf =
      alignmentToStyle[migratedProps.alignButton as ButtonAlignment] ||
      alignmentToStyle.left;
    return {
      height: 'auto',
      '--max-lines': maxLines,
      '--expand-control-spacing': `${expandControlSpacing}px`,
      '--expand-control-align': `${alignSelf}`,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof ICollapsibleTextStateValues>(
  ['reportBi'],
);
export const uiType = withCompInfo<any, CollapsibleTextDefinition>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const hasStCSS = styleProperties['st-css'] !== undefined;
    if (!hasStCSS) {
      return 'CollapsibleText';
    }
    return 'Default';
  },
);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
  uiType,
});
