import * as React from 'react';
import classNames from 'clsx';
import type {
  ISlideShowGalleryImperativeActions,
  ISlideShowGalleryProps,
} from '../../../SlideShowGallery.types';
import SlideShowGallery from '../../SlideShowGallery';
import skinsItemStyle from '../../../../SlideShowGalleryItem/viewer/style/SlideShowScotchTapeItem.scss';
import skinsStyle from './styles/SlideShowScotchTape.scss';

const Overlay: React.FC = () => (
  <div>
    <div
      className={classNames(skinsStyle.shadowTL, skinsStyle.overlayBottom)}
    />
    <div
      className={classNames(skinsStyle.shadowTR, skinsStyle.overlayBottom)}
    />
    <div
      className={classNames(skinsStyle.shadowBL, skinsStyle.overlayBottom)}
    />
    <div
      className={classNames(skinsStyle.shadowBR, skinsStyle.overlayBottom)}
    />
    <div className={classNames(skinsStyle.border, skinsStyle.overlayBottom)} />
    <div className={classNames(skinsStyle.scotchL, skinsStyle.overlayTop)} />
    <div className={classNames(skinsStyle.scotchR, skinsStyle.overlayTop)} />
  </div>
);

const SlideShowScotchTape: React.ForwardRefRenderFunction<
  ISlideShowGalleryImperativeActions,
  Omit<ISlideShowGalleryProps, 'skin'>
> = (props, ref) => (
  <SlideShowGallery
    ref={ref}
    {...props}
    skinsStyle={skinsStyle}
    skinsItemStyle={skinsItemStyle}
    overlay={<Overlay />}
  />
);

export default React.forwardRef(SlideShowScotchTape);
