import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import DoubleBorderScreen from '../../../../ScreenWidthContainer/viewer/skinComps/DoubleBorderScreen/DoubleBorderScreen';
import HeaderContainer from '../../HeaderContainer';

const DoubleBorderScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => (
  <HeaderContainer {...props} skin={DoubleBorderScreen}></HeaderContainer>
);

export default DoubleBorderScreenHeader;
