import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { MediaCSSVars } from '@wix/thunderbolt-components-native';
import { SectionDefinition, SectionProps } from '@wix/thunderbolt-components';

export const props = withCompInfo<
  SectionProps,
  SectionDefinition,
  SectionProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  MediaCSSVars,
  SectionDefinition,
  MediaCSSVars
>()([], (_, carmiCss) => {
  return {
    ...carmiCss,
    '--inherit-transition': 'var(--transition, none)',
  };
});

export default createComponentMapperModel({ props, css });
