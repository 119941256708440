import * as vectorImage from '@wix/thunderbolt-commons/dist/vectorImage';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import type {
  StylableButtonDefinition,
  StylableButtonPlatformProps,
  StylableButtonCarmiProps,
  StylableButtonSdkData,
  IStylableButtonStateValues,
  RenderDisabledLinkAsButtonProps,
  FlexDirection,
  LogicalAlignment,
} from '../StylableButton.types';

export const props = withCompInfo<
  StylableButtonPlatformProps & RenderDisabledLinkAsButtonProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(
  [
    'compProps',
    'isQaMode',
    'fullNameCompType',
    'language',
    'mainPageId',
    'compId',
    'experiments',
    'trackClicksAnalytics',
  ],
  ({ compProps, experiments, ...rest }, { className, ...carmiData }) => {
    return {
      ...compProps,
      ...carmiData,
      ...rest,
      stylableButtonClassName: className,
      isUdpExperimentOn: isExperimentOpen(
        experiments,
        'specs.thunderbolt.buttonUdp',
      ),
      link: carmiData.link && { ...carmiData.link },
      // FYI: Experiment is needed to gradually rollout a fix for ECL-2820
      // TODO: Remove this experiment once the fix is fully rolled out and no complains are received
      renderDisabledLinkAsButton:
        experiments['specs.thunderbolt.fixDisabledLinkButtonStyles'] === true,
    };
  },
);

const maxContentCSS = (isWrapText: boolean) => ({
  height: 'max-content',
  'min-width': isWrapText ? '10px' : 'max-content',
});

export const css = withCompInfo()(
  ['compProps', 'styleProperties'],
  ({ compProps: { isMaxContent, isWrapText }, styleProperties }) => {
    const hasStCSS = styleProperties['st-css'] !== undefined;
    if (!hasStCSS) {
      const flexDirection = styleProperties[
        'container-flex-direction'
      ] as FlexDirection;
      const logicalHorizontalAlignment = styleProperties[
        'content-horizontal-alignment'
      ] as LogicalAlignment;
      const logicalHorizontalAlignmentProperty = flexDirection.startsWith('row')
        ? '--container-justify-content'
        : '--container-align-items';

      const logicalVerticalAlignmentProperty = flexDirection.startsWith('row')
        ? '--container-align-items'
        : '--container-justify-content';
      return {
        [logicalHorizontalAlignmentProperty]: logicalHorizontalAlignment,
        [logicalVerticalAlignmentProperty]: 'center',
        ...(isMaxContent ? maxContentCSS(isWrapText) : {}),
      };
    }
    return {
      ...(isMaxContent ? maxContentCSS(isWrapText) : {}),
    };
  },
);

export const sdkData = withCompInfo<
  StylableButtonSdkData,
  StylableButtonDefinition
>()(['compData', 'topology'], ({ compData, topology }) => {
  const { mediaRootUrl } = topology;
  const { getSvgBaseUrl } = vectorImage.buildSvgUrlFactory();
  return {
    mediaSvgUrl: getSvgBaseUrl(mediaRootUrl),
    svgId: compData.svgId,
  };
});

export const stateRefs = withStateRefsValues<keyof IStylableButtonStateValues>([
  'reportBi',
]);

export const uiType = withCompInfo<any, StylableButtonDefinition>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const hasStCSS = styleProperties['st-css'] !== undefined;
    if (!hasStCSS) {
      return 'StylableButton'; // UDP
    }
    return 'Default'; // Stylable
  },
);

export default createComponentMapperModel({
  props,
  css,
  sdkData,
  stateRefs,
  uiType,
});
