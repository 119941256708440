import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';
import {
  VerticalLineDefinition,
  VerticalLineSkin,
} from '../VerticalLine.types';

const isInEditor = (viewMode: string) => viewMode === 'editor';

const skinsWithMinHeight: Array<VerticalLineSkin> = [
  'VerticalDottedLine',
  'VerticalDashedLine',
  'VerticalSolidLine',
];

const css = withCompPreviewInfo<
  {
    'min-width'?: string;
    width?: string;
  },
  VerticalLineDefinition
>()(
  ['skin', 'componentViewMode', 'hasResponsiveLayout'],
  ({ skin, componentViewMode, hasResponsiveLayout }) => {
    if (isInEditor(componentViewMode) && skinsWithMinHeight.includes(skin)) {
      return { width: hasResponsiveLayout ? 'auto' : 'auto !important' };
    }

    return {};
  },
);

export default createComponentPreviewMapperModel({
  css,
});
