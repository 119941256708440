import React, { useCallback, useEffect, useState } from 'react';
import type { IElementPropsSDKActions } from '@wix/editor-elements-corvid-utils';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { WIDGET_TYPE_MAP, WidgetType } from '../constants';
import { useIFrame, IFrameEvent } from '../../../providers/useIFrame/useIFrame';
import { ConsentPolicyWrapperContext } from '../../ConsentPolicyWrapper/viewer/ConsentPolicyWrapper';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';

type WTwitterContentProps = {
  id: string;
  title: string;
  widgetType: WidgetType;
  queryString: string;
  className?: string;
  isConsentPolicyActive: boolean;
} & Partial<IElementPropsSDKActions>;

interface CSSPropertiesWithVars extends React.CSSProperties {
  '--width'?: string;
  '--height'?: string;
}

export const WTwitterContent: React.FC<WTwitterContentProps> = props => {
  const {
    id,
    className,
    title,
    widgetType,
    queryString,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const iframeSrc = `https://platform.twitter.com/widgets/${WIDGET_TYPE_MAP[widgetType]}.html?${queryString}`;
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();
  const [render, setRender] = useState(false);
  const context = React.useContext(ConsentPolicyWrapperContext);

  const handleMessage = useCallback(
    ({ type, payload }: IFrameEvent) => {
      if (type !== 'message' || !payload || typeof payload === 'string') {
        return;
      }
      const buttonObj = payload['twttr.button'];

      if (buttonObj && buttonObj.method === 'twttr.private.resizeButton') {
        const size = buttonObj.params[0];
        setWidth(size.width);
        setHeight(size.height);
        context.setWrapperStyle({
          '--width': size.width,
          '--height': size.height,
        });
      }
    },
    [context],
  );

  const [ref] = useIFrame({ reducer: handleMessage });

  useEffect(() => {
    setRender(true);
  }, []);

  const rootStyles: CSSPropertiesWithVars = {
    ...(height ? { '--height': height } : {}),
    ...(width ? { '--width': width } : {}),
    width: 'calc(var(--width) * 1px)',
    height: 'calc(var(--height) * 1px)',
  };

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className,
    style: rootStyles,
    ...getDataAttributes(props),
  });

  return (
    <div
      {...baseComponentProps}
      title={title}
      aria-label={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          <iframe
            title={title}
            aria-label={title}
            data-src={iframeSrc}
            scrolling="no"
            ref={ref}
            style={{ width: '100%', height: '100%' }}
          />
        </wix-iframe>
      )}
    </div>
  );
};

/**
 * NOTE: fix for registry conventions,
 * registry still create entry for this component
 */
export default WTwitterContent;
