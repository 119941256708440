import { withCompController } from '@wix/editor-elements-integrations';

import {
  IFacebookLikeBoxProps,
  FacebookLikeBoxMapperProps,
  FacebookLikeBoxControllerProps,
} from '../FacebookLikeBox.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

export default withCompController<
  FacebookLikeBoxMapperProps,
  FacebookLikeBoxControllerProps,
  IFacebookLikeBoxProps
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});
