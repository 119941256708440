import * as React from 'react';

import type { ILeanTestComponentProps } from '../LeanTestComponent.props';

/**
 * As a rule of thumb, we use functional components, and use React's hooks and forwardRef for more advanced capabilities.
 * To learn more about React components guidelines, read [the code-guidelines](https://bo.wix.com/pages/editor-elements-docs/docs/development/contributing#code-guidelines)
 */
const LeanTestComponent: React.FC<ILeanTestComponentProps> = props => {
  const { id, label, alignment, value, onChange } = props;

  return (
    <label id={id} dir={alignment}>
      {label}: <input type="color" value={value} onChange={onChange} />
    </label>
  );
};

export default LeanTestComponent;
