export const getRowHeight = (
  galleryHeight: number,
  margin: number,
  rows: number,
  galleryBottomPadding: number,
) => {
  const totalMargins = margin * (rows - 1);

  return Math.max(
    Math.floor((galleryHeight - totalMargins - galleryBottomPadding) / rows),
    0,
  );
};
