import { withCompController } from '@wix/editor-elements-integrations';
import {
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
  DisqusCommentsControllerProps,
} from '../DisqusComments.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

const compController = withCompController<
  DisqusCommentsMapperProps,
  DisqusCommentsControllerProps,
  never,
  DisqusCommentsStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrlWithQueryParams,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});

export default compController;
