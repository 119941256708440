import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  useOnVideoStopCallback,
  useVideoPlayEffect,
} from '@wix/editor-elements-preview-utils';
import { VideoBoxActions, VideoBoxProps } from '../VideoBox.types';

export default (ViewerComponent: React.ComponentType<VideoBoxProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<VideoBoxActions, VideoBoxProps>(
      (
        {
          previewWrapperProps = {},
          ...viewerProps
        }: PreviewWrapperProps<
          VideoBoxProps,
          {
            autoPlayVideo?: boolean;
            isPlayingAllowed?: boolean;
            showAudio?: boolean;
            shouldShowTransparentVideoPosterOnStop?: boolean;
          }
        >,
        ref,
      ) => {
        const {
          autoPlayVideo,
          isPlayingAllowed,
          showAudio,
          shouldShowTransparentVideoPosterOnStop,
        } = previewWrapperProps;

        const compRef = React.useRef<VideoBoxActions | null>(null);

        React.useImperativeHandle<VideoBoxActions | null, any>(
          ref,
          () => compRef && compRef.current,
        );

        useVideoPlayEffect({ compRef, isPlayingAllowed, autoPlayVideo });

        React.useEffect(() => {
          const compEl: HTMLElement | null = document.querySelector(
            `#${viewerProps.id}`,
          );

          if (compEl) {
            if (showAudio) {
              compEl.dataset.showAudio = '';
            } else {
              delete compEl.dataset.showAudio;
            }
          }
        }, [showAudio, viewerProps.id]);

        const onStop = useOnVideoStopCallback();

        return (
          <ViewerComponent
            {...viewerProps}
            ref={compRef}
            onStop={isPlayingAllowed ? undefined : onStop}
            shouldShowTransparentVideoPosterOnStop={
              shouldShowTransparentVideoPosterOnStop
            }
          />
        );
      },
    ),
  );
