
import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';


const props = withCompInfo<any, any>()(
  ['compData', 'compMeshProps'],
  ({compData, compMeshProps}) => {
      
    return {
      ...{ title: 'Your card title',
 },
...compData,
meshProps: compMeshProps,

    };
  },
);




export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
