import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  IAccordionContainerDefinition,
  IAccordionContainerMapperProps,
  IAccordionContainerCarmiProps,
} from '../types';
import { props as viewerPropsMapper } from './AccordionContainer.mapper';

const props = composeMapper<
  PreviewWrapperProps<IAccordionContainerMapperProps>,
  IAccordionContainerDefinition,
  IAccordionContainerCarmiProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode', 'compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState, componentViewMode } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);
    const isEditor = dependencies.componentViewMode === 'editor';

    return {
      ...viewerProps,
      animated: isEditor ? false : viewerProps.animated,
      previewWrapperProps: {
        compPreviewState,
        componentViewMode,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
