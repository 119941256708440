import { Transition, TransitionDirection } from '../../constants';

type AnimantionNames = {
  prevOut: string;
  prevIn: string;
  nextOut: string;
  nextIn: string;
};

const animationsMap: Record<Transition, AnimantionNames> = {
  none: {
    prevOut: 'none',
    prevIn: 'none',
    nextOut: 'none',
    nextIn: 'none',
  },
  slide: {
    prevOut: 'slidePrevOut',
    prevIn: 'slidePrevIn',
    nextOut: 'slideNextOut',
    nextIn: 'slideNextIn',
  },
  crossFade: {
    prevOut: 'fadeOut',
    prevIn: 'fadeIn',
    nextOut: 'fadeOut',
    nextIn: 'fadeIn',
  },
};

const wrapAnimationNames = (animationNames: AnimantionNames) =>
  Object.entries(animationNames).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [`--animation-${key}`]: `SlideshowRepeater_${value}`,
    }),
    {},
  );

export const getAnimations = (transitionEffect: Transition) =>
  wrapAnimationNames(animationsMap[transitionEffect]);

export const getSlideAnimationsForPreview = (
  transitionDirection: TransitionDirection,
) => {
  const { prevOut, prevIn, nextOut, nextIn } = animationsMap.slide;
  return wrapAnimationNames({
    prevOut,
    prevIn,
    nextOut: `${nextOut}__${transitionDirection}`,
    nextIn: `${nextIn}__${transitionDirection}`,
  });
};

type SlideShift = {
  '--shift-x': string;
  '--shift-y': string;
};

const slideShiftMap: Record<TransitionDirection, SlideShift> = {
  leftToRight: {
    '--shift-x': '-100%',
    '--shift-y': '0%',
  },
  rightToLeft: {
    '--shift-x': '100%',
    '--shift-y': '0%',
  },
  bottomToTop: {
    '--shift-x': '0%',
    '--shift-y': '100%',
  },
  topToBottom: {
    '--shift-x': '0%',
    '--shift-y': '-100%',
  },
};

export const getSlideVariables = (transitionDirection: TransitionDirection) =>
  slideShiftMap[transitionDirection];

const previewStateToForceAnimation = [
  ...Object.values(Transition),
  ...Object.values(TransitionDirection),
];
export const shouldForceAnimation = (
  compPreviewState: string | null | undefined,
) => previewStateToForceAnimation.includes(compPreviewState as any);
