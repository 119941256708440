
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "AccordionContainerHeaderIcon3990976808",
      {"classes":{"root":"AccordionContainerHeaderIcon3990976808__root"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  