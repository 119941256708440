import classNames from 'clsx';
import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { QuickActionBarItemProps } from '../QuickActionBarItem.types';

const QuickActionBarItem: React.ForwardRefRenderFunction<
  HTMLAnchorElement,
  QuickActionBarItemProps
> = (props, ref) => {
  const {
    compId: id,
    className,
    text,
    svgString,
    itemType,
    hasNotifications,
    color,
    link = {},
    skinStyles = {},
    clickHandler,
    keyDownHandler,
    role,
    tabIndex,
  } = props;
  const { linkPopupId, href, anchorDataId, anchorCompId, target } = link;

  const style = color
    ? ({ '--color': color } as React.CSSProperties)
    : undefined;

  return (
    <a
      id={id}
      {...getDataAttributes(props)}
      aria-label={text}
      data-quick-action="action"
      data-aid={itemType}
      data-anchor={anchorDataId}
      data-anchor-comp-id={anchorCompId}
      onClick={clickHandler}
      onKeyDown={keyDownHandler}
      className={classNames(className, skinStyles.action)}
      href={href}
      target={target}
      ref={ref}
      style={style}
      data-popupid={linkPopupId}
      {...(role && { role })}
      {...(tabIndex !== undefined && { tabIndex })}
    >
      <div
        className={skinStyles.icon}
        data-quick-action="icon"
        dangerouslySetInnerHTML={{ __html: svgString }}
      ></div>
      <div className={skinStyles.text} data-quick-action="text">
        {text}
      </div>
      {hasNotifications && (
        <div
          className={skinStyles.notifications}
          data-quick-action="notifications"
        />
      )}
    </a>
  );
};

export default React.forwardRef(QuickActionBarItem);
