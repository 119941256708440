import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  DocumentMediaDefinition,
  DocumentMediaProps,
} from '@wix/thunderbolt-components/dist/components/DocumentMedia/DocumentMedia';
import { props as viewerPropsMapper } from './DocumentMedia.mapper';

const props = composeMapper<
  PreviewWrapperProps<DocumentMediaProps>,
  DocumentMediaDefinition,
  DocumentMediaProps
>()(
  viewerPropsMapper,
  {
    remove: ['compId', 'fullNameCompType', 'language', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
