export const confirmationEmailDialogTranslationKeys = {
  title: 'confirmationEmailTitle',
  signUpTitle: 'confirmationEmailSignUpTitle',
  subtitle: 'confirmationEmailSubtitle',
  signUpSubtitle: 'confirmationEmailSignUpSubtitle',
  signUpSecondarySubtitle: 'confirmationEmailSignUpSecondarySubtitle',
  description: 'confirmationEmailDescription',
  descriptionSecondary: 'confirmationEmailDescriptionSecondary',
  descriptionLink: 'confirmationEmailDescriptionLink',
};

export const testIds = {
  signUpSubtitle: 'sign-up-subtitle',
  signUpSecondarySubtitle: 'sign-up-secondary-subtitle',
  description: 'description',
  secondaryDescription: 'secondary-description',
  resendConfirmationEmailButton: 'resend-confirmation-email',
} as const;
