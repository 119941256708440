import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import type { IPaginationProps } from '../Pagination.types';
import { classes } from './style/Pagination.st.css';

function withComponentPreview(
  WrappedComponent: React.ComponentType<IPaginationProps>,
): IComponentPreviewWrapper<IPaginationProps> {
  return ({ previewWrapperProps = {}, ...viewerProps }) => {
    const { compPreviewState } = previewWrapperProps;

    usePreviewState(viewerProps.id, compPreviewState, {
      disabled: {
        selectors: `.${classes.navButton}`,
        type: 'plural',
      },
    });

    return <WrappedComponent {...viewerProps} />;
  };
}

export default (ViewerComponent: React.ComponentType<IPaginationProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
