import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IRSSButtonProps,
  IRSSButtonStateValues,
  IRSSButtonMapperProps,
  IRSSButtonControllerProps,
} from '../RSSButton.types';

const useComponentProps = ({
  mapperProps,
  stateValues,
}: IPlatformData<
  IRSSButtonMapperProps,
  IRSSButtonControllerProps,
  IRSSButtonStateValues
>): IRSSButtonProps => {
  const {
    compId,
    language,
    mainPageId,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick: IRSSButtonControllerProps['reportBiOnClick'] =
    event => {
      const { link, image } = restMapperProps;
      const { reportBi } = stateValues;

      tryReportAnalyticsClicksBi(reportBi, {
        link,
        language,
        trackClicksAnalytics,
        elementTitle: image?.title,
        details: { uri: image?.uri },
        elementType: fullNameCompType,
        pagesMetadata: { mainPageId },
        elementGroup: AnalyticsClicksGroups.Button,
        element_id: compId ?? event.currentTarget.id,
      });
    };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
};

export default withCompController(useComponentProps);
