import * as React from 'react';
import type { IMatrixGalleryProps } from '../../../MatrixGallery.types';
import MatrixGallery from '../../MatrixGallery';
import skinsItemStyle from '../../../../MatrixGalleryItem/viewer/style/BlogMatrixGalleryItem.scss';
import MatrixGalleryItemWithOverlayPanel from '../../../../MatrixGalleryItem/viewer/MatrixGalleryItemWithOverlayPanel';
import skinsStyle from './style/BlogMatrixGallery.scss';

const BlogMatrixGallery: React.FC<IMatrixGalleryProps> = props => {
  return (
    <MatrixGallery
      {...props}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
      MatrixGalleryItemComponent={MatrixGalleryItemWithOverlayPanel}
    />
  );
};

export default BlogMatrixGallery;
