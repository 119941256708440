import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewState,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type {
  IDefaultPreviewWrapperProps,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import {
  IRichTextBoxProps,
  IRichTextBoxImperativeActions,
} from '../RichTextBox.types';
import editorStyles from './editor/editorStyles.scss';
import toolbarStyles from './toolbars/toolbar.scss';

export default (ViewerComponent: React.ComponentType<IRichTextBoxProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<IRichTextBoxImperativeActions, IRichTextBoxProps>(
      function (
        {
          previewWrapperProps,
          ...viewerProps
        }: PreviewWrapperProps<
          IRichTextBoxProps,
          {
            isMobileView: boolean;
            componentViewMode: string;
          } & IDefaultPreviewWrapperProps
        >,
        ref,
      ) {
        const {
          compPreviewState,
          shouldResetComponent,
          isMobileView,
          componentViewMode,
        } = previewWrapperProps || {};
        const { id, setValidityIndication, closeMobileLinkModalTemp } =
          viewerProps;
        const key = useResetComponent({
          id,
          shouldResetComponent,
          onResetComponent: () => {
            setValidityIndication(false);
            if (closeMobileLinkModalTemp) {
              closeMobileLinkModalTemp();
            }
          },
        });

        usePreviewState(viewerProps.id, compPreviewState, {
          hover: {
            selectors: [`.${editorStyles.editor}`, `.${toolbarStyles.toolbar}`],
            type: 'single',
          },
          focus: {
            selectors: [`.${editorStyles.editor}`, `.${toolbarStyles.toolbar}`],
            type: 'single',
          },
          error: {
            selectors: [`.${editorStyles.editor}`, `.${toolbarStyles.toolbar}`],
            type: 'single',
          },
          disabled: {
            selectors: [`.${editorStyles.editor}`, `.${toolbarStyles.toolbar}`],
            type: 'single',
          },
        });

        const { toolbarPosition, openMobileLinkModalTemp } = viewerProps;

        return (
          <ViewerComponent
            {...viewerProps}
            openMobileLinkModalTemp={
              isMobileView ? openMobileLinkModalTemp : undefined
            }
            key={`${key}-${isMobileView}-${toolbarPosition}`}
            ref={ref}
            componentViewMode={componentViewMode}
          />
        );
      },
    ),
  );
