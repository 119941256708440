import * as React from 'react';
import classNames from 'clsx';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonBackground from '../../MenuButtonBackground';
import type { MenuButtonProps } from '../../../MenuButton.types';
import MenuButtonRoot from '../../MenuButtonRoot';
import skinsStyle from './styles/skins.scss';

const DiagonalMenuButtonSkin: React.FC<MenuButtonProps> = props => {
  const {
    id,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    'aria-describedby': ariaDescribedBy,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(props.className, skinsStyle.DiagonalMenuButtonSkin)}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton ? ariaHasPopup : 'false',
            ariaDescribedBy,
            isMoreButton,
          }}
          className={skinsStyle.linkElement}
          link={link}
        >
          <div className={skinsStyle.arrow}>
            <div className={skinsStyle.tail}>
              <div className={skinsStyle.tailTop} />
            </div>
            <div
              className={classNames(skinsStyle.sq, skinsStyle.dropSizeTarget)}
            />
            <div
              className={classNames(skinsStyle.head, skinsStyle.dropSizeTarget)}
            >
              <div className={skinsStyle.headTop} />
            </div>
          </div>
          <MenuButtonBackground
            wrapperProps={{ dir, textAlign, id }}
            classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
          >
            {label}
          </MenuButtonBackground>
          <div className={skinsStyle.hitArea} />
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default DiagonalMenuButtonSkin;
