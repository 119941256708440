export type FontObject = {
  style: string;
  variant: string;
  weight: string;
  size: number;
  lineHeight: string;
  family: Array<string>;
  bold: boolean;
  italic: boolean;
};

const invalidFonObj: FontObject = {
  style: '',
  variant: '',
  weight: '',
  size: -1,
  lineHeight: '',
  family: [],
  bold: false,
  italic: false,
};

const fontRegEx =
  /(?<style>.*?)\s(?<variant>.*?)\s(?<weight>.*?)\s(?<size>.*?)\s(?<fullFontFamily>.*)/;

const extractFromQuotes = (str: string): string => {
  const match = str.match(/'(?<strInQuotes>.*?)'/);

  return match ? match.groups!.strInQuotes : str;
};

export const parseFontStr = (font: string): FontObject => {
  const match = font.match(fontRegEx);
  if (!match) {
    return invalidFonObj;
  }

  const { style, variant, weight, size, fullFontFamily } = match.groups!;
  const sizeSplit = size ? size.split('/') : [];
  const fullFontFamilyArr = fullFontFamily
    .split(',')
    .map(currFamily => extractFromQuotes(currFamily));

  return {
    style,
    variant,
    weight,
    size: parseInt(sizeSplit[0].replace('px', ''), 10),
    lineHeight: sizeSplit[1],
    family: fullFontFamilyArr,
    bold: weight === 'bold' || parseInt(weight, 10) >= 700,
    italic: style === 'italic',
  };
};

const fixFontWithSpaceOrNotLatinChars = (font: string): string =>
  font.replace(
    /[^,]*[^\w,\d-][^,]*/g,
    fontFamilyStr => `'${fontFamilyStr.replace(/\+/g, ' ')}'`,
  );

export const fontObjectToString = ({
  style,
  variant,
  weight,
  size,
  lineHeight,
  family,
}: FontObject): string => {
  const fullFontFamily = fixFontWithSpaceOrNotLatinChars(family.join(','));

  return `${style} ${variant} ${weight} ${size}px/${lineHeight} ${fullFontFamily}`;
};
