import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { useResetComponent } from '@wix/editor-elements-preview-utils';
import React from 'react';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
  IDefaultPreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import { ICaptchaImperativeActions, ICaptchaProps } from '../Captcha.types';

export type CaptchaPreviewWrapperProps = IDefaultPreviewWrapperProps & {
  shouldResetComponent: boolean;
};

function withComponentPreview(
  WrappedComponent: React.ComponentType<ICaptchaProps>,
): IComponentPreviewWrapper<ICaptchaProps, CaptchaPreviewWrapperProps> {
  return React.forwardRef<
    ICaptchaImperativeActions,
    PreviewWrapperProps<ICaptchaProps, CaptchaPreviewWrapperProps>
  >(
    (
      {
        previewWrapperProps,
        ...viewerProps
      }: PreviewWrapperProps<ICaptchaProps, CaptchaPreviewWrapperProps>,
      ref,
    ) => {
      const { shouldResetComponent } = previewWrapperProps || {};
      const { id } = viewerProps;

      const compRef = React.useRef<ICaptchaImperativeActions | null>(null);
      React.useImperativeHandle<ICaptchaImperativeActions, any>(
        ref,
        () => compRef && compRef.current,
      );

      useResetComponent({
        id,
        shouldResetComponent: !!shouldResetComponent,
        onResetComponent: () => compRef.current?.reset(),
      });

      return <WrappedComponent {...viewerProps} ref={compRef} />;
    },
  );
}

export default (ViewerComponent: React.ComponentType<ICaptchaProps>) =>
  createComponentPreviewEntry<ICaptchaProps, CaptchaPreviewWrapperProps>(
    withComponentPreview(ViewerComponent),
  );
