import * as React from 'react';
import type { ISelectableContainerImperativeActions } from '../../types';

export const useFocusableHandle = (
  ref: React.ForwardedRef<ISelectableContainerImperativeActions>,
) => {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useImperativeHandle(ref, () => ({
    blur: () => {
      inputRef.current?.blur();
    },
    focus: () => {
      inputRef.current?.focus();
    },
  }));

  return inputRef;
};
