import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import SolidScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/SolidScreen';
import HeaderContainer from '../../HeaderContainer';

const SolidScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => <HeaderContainer {...props} skin={SolidScreen}></HeaderContainer>;

export default SolidScreenHeader;
