import React from 'react';
import classnames from 'clsx';

import { TestIds } from '../../../constants';
import styles from './Scroller.scss';

export default (props: any) => {
  const { direction, onClick } = props;
  return (
    <button
      className={classnames(styles.container, styles.button)}
      onClick={onClick}
      data-testid={TestIds.scroller}
    >
      <span className={direction === 'right' ? styles.right : styles.left} />
    </button>
  );
};
