
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TabsScrollButton1157534303",
      {"classes":{"root":"TabsScrollButton1157534303__root","icon":"TabsScrollButton1157534303__icon"},"vars":{},"stVars":{"tabsListScrollButtonsDisplay":"var(--tabs-list-scroll-buttons-display, flex)","tabsListScrollButtonsTransform":"var(--tabs-list-scroll-buttons-transform, scaleX(1))","tabsListScrollIconsTransform":"var(--tabs-list-scroll-icons-transform, rotate(0))"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  