import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { useEffect } from 'react';
import {
  ICheckboxGroupProps,
  ICheckboxGroupImperativeActions,
  ICheckboxGroupPropsOnClick,
  CheckboxGroupOption,
} from '../CheckboxGroup.types';
import Checkbox from '../../Checkbox/viewer/Checkbox';
import { ICheckboxImperativeActions } from '../../Checkbox/Checkbox.types';
import semanticClassNames from '../CheckboxGroup.semanticClassNames';
import { InlineErrorMessage } from '../../../core/inlineErrorMessage';
import style from './style/CheckboxGroupDefaultSkin.scss';

const noop = () => {};

const isAnyOptionCheckedFunc = (options: Array<CheckboxGroupOption>) =>
  options.some(option => option.checked);

const useTabOutside = <E extends React.RefObject<HTMLElement>>(
  reference: E,
  callback: Function,
) => {
  const handleTabOutside = (event: any) => {
    if (event.key === 'Tab') {
      if (reference.current && !reference.current.contains(event.target)) {
        callback();
      }
    }
  };

  useEffect(() => {
    window?.document?.addEventListener('keydown', handleTabOutside);
    return () => {
      window?.document?.removeEventListener('keydown', handleTabOutside);
    };
  });
};

const useClickOutside = <E extends React.RefObject<HTMLElement>>(
  ref: E,
  callback: Function,
) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };
  useEffect(() => {
    window?.document?.addEventListener('click', handleClick);
    return () => {
      window?.document?.removeEventListener('click', handleClick);
    };
  });
};

const CheckboxGroup: React.ForwardRefRenderFunction<
  ICheckboxGroupImperativeActions,
  ICheckboxGroupProps
> = (props, ref) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [isFirstInteraction, setIsFirstInteraction] = React.useState(true);

  const {
    id,
    label = '',
    options,
    required,
    isDisabled,
    translations,
    errorMessageType,
    isMinMaxEnabled = false,
    componentViewMode,
    shouldShowValidityIndication,
    className,
    customClassNames = [],
    reportBiOnClick,
    validateValueAndShowIndication = noop,
    validateValue = noop,
    onOptionsChange = noop,
    onChange = noop,
    onClick = noop,
    onDblClick = noop,
    onMouseEnter = noop,
    onMouseLeave = noop,
    max,
    min,
    onBlur = noop,
    isValid,
  } = props;

  const validate = required ? validateValueAndShowIndication : validateValue;

  const shouldShowInlineValidityIndication =
    shouldShowValidityIndication && !isValid;

  const inputRefs = React.useRef<Array<ICheckboxImperativeActions | null>>([]);

  const defaultValidationMessage = translations?.validationMessage;

  const [errorMessageContent, setErrorMessageContent] = React.useState(
    defaultValidationMessage,
  );

  const isAnyOptionChecked = React.useMemo(() => {
    return isAnyOptionCheckedFunc(options);
  }, [options]);

  React.useImperativeHandle(ref, () => {
    return {
      focus: () => {
        const checkedItem =
          inputRefs.current?.find(_ref => _ref && _ref.isChecked) ||
          inputRefs.current?.[0];

        checkedItem?.focus();
      },
      blur: () => {
        const focusedItem = inputRefs.current?.find(
          _ref => _ref && _ref.isFocused,
        );
        focusedItem?.blur();
      },
      setCustomValidity: message => {
        const errMsg = (message as { type: 'message'; message: string })
          .message;
        setErrorMessageContent(errMsg || defaultValidationMessage);
        inputRefs.current?.forEach(_ref => {
          _ref?.setCustomValidity(message);
        });
      },
    };
  });

  const isMaxReached = (newOptions: Array<any>): boolean => {
    return (
      isMinMaxEnabled &&
      !!max &&
      newOptions.filter(option => option.checked).length >= max
    );
  };

  const validateMinValues = (newOptions: Array<any>): boolean => {
    if (isFirstInteraction || !isMinMaxEnabled) {
      return true;
    }

    if (!min) {
      return true;
    }

    return newOptions.filter(option => option.checked).length >= min;
  };

  const _onChange = (
    index: number,
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newOptions = options.map((option, _index) => ({
      ...option,
      checked: _index === index ? event.target.checked : option.checked,
    }));
    onOptionsChange(newOptions);
    onChange(event);
    validate();
  };

  const _onClick: ICheckboxGroupPropsOnClick = (event, option) => {
    reportBiOnClick?.(event, option);
    !isDisabled && onClick(event);
  };

  const _onDblClick: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onDblClick(event);
    }
  };

  const _onMouseEnter: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseEnter(event);
    }
  };

  const _onMouseLeave: React.MouseEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onMouseLeave(event);
    }
  };

  const _onBlur: React.FocusEventHandler<HTMLDivElement> = event => {
    if (!isDisabled) {
      onBlur(event);
      validate();
    }
  };

  const containerClasses = classNames(
    style.CheckboxGroupDefaultSkin,
    className,
    formatClassNames(semanticClassNames.root, ...customClassNames),
    {
      [style.requiredIndication]: required && label,
    },
  );

  const parentRef = React.useRef<HTMLDivElement>(null);
  const validationCallback = () => {
    if (isFocused && isFirstInteraction) {
      setIsFocused(false);
      setIsFirstInteraction(false);
    }
  };

  useClickOutside(parentRef, validationCallback);
  useTabOutside(parentRef, validationCallback);

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={containerClasses}
      onDoubleClick={_onDblClick}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      ref={parentRef}
      onFocus={() => {
        if (isFirstInteraction) {
          setIsFocused(true);
        }
      }}
      onBlur={_onBlur}
    >
      <fieldset>
        <legend className={style.groupLabelLegend}>
          {label && (
            <div
              className={classNames(
                style.groupLabel,
                formatClassNames(semanticClassNames.label),
              )}
              data-testid="label"
            >
              {label}
            </div>
          )}
        </legend>
        <div className={style.items} data-testid="items">
          {options.map((option, index) => (
            <Checkbox
              ref={el => (inputRefs.current[index] = el)}
              key={index}
              {...option}
              id={`${index}_${id}`}
              required={
                (required && !isAnyOptionChecked) || !validateMinValues(options)
              }
              isDisabled={
                isDisabled ||
                Boolean(option.isDisabled) ||
                (isMaxReached(options) && !option.checked)
              }
              readOnly={false}
              shouldShowValidityIndication={shouldShowValidityIndication}
              validateValueAndShowIndication={() => {}}
              hideValidityIndication={() => {}}
              setValidityIndication={() => {}}
              onCheckedChange={() => {}}
              onBlur={() => {}}
              onFocus={() => {}}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                _onChange(index, event)
              }
              onClick={event => _onClick(event, option)}
              onDblClick={() => {}}
              name={id}
            ></Checkbox>
          ))}
        </div>
      </fieldset>
      <InlineErrorMessage
        errorMessageType={errorMessageType}
        errorMessage={errorMessageContent}
        shouldShowValidityIndication={shouldShowInlineValidityIndication}
        translations={translations}
        componentViewMode={componentViewMode}
      />
    </div>
  );
};

export default React.forwardRef(CheckboxGroup);
