import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  usePreviewEffect,
  usePreviewState,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import {
  FileMetaData,
  IFileUploaderImperativeActions,
  IFileUploaderProps,
} from '../FileUploader.types';
import componentStyle from './style/FileUploader.scss';
import numOfFilesStyle from './NumberOfFilesLink/style/NumberOfFilesLink.scss';
import fileNameStyle from './FileName/style/FileName.scss';

const noop = () => {};

export default (ViewerComponent: React.ComponentType<IFileUploaderProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<IFileUploaderImperativeActions | null, IFileUploaderProps>(
      (
        {
          previewWrapperProps = {},
          ...viewerProps
        }: PreviewWrapperProps<
          IFileUploaderProps,
          {
            shouldRenderDummyFileName?: boolean;
            shouldResetComponent?: boolean;
            componentViewMode?: string;
            compPreviewState?: string;
          }
        >,
        ref,
      ) => {
        const {
          shouldRenderDummyFileName,
          shouldResetComponent,
          componentViewMode,
          compPreviewState,
        } = previewWrapperProps;
        const {
          id,
          translations,
          value,
          resetFileUploader = noop,
          validateValue = noop,
        } = viewerProps;

        const compRef = React.useRef<IFileUploaderImperativeActions | null>(
          null,
        );
        React.useImperativeHandle<IFileUploaderImperativeActions | null, any>(
          ref,
          () => compRef && compRef.current,
        );

        usePreviewEffect({
          componentViewMode,
          onPreviewViewMode: validateValue,
        });

        useResetComponent({
          shouldResetComponent,
          id,
          onResetComponent: () => {
            compRef.current?.resetFiles();
            resetFileUploader();
            validateValue();
          },
        });

        usePreviewState(id, compPreviewState, {
          focus: {
            selectors: [
              `.${componentStyle.uploadFileButton}`,
              `.${fileNameStyle.xIcon}`,
            ],
            type: 'single',
          },
          error: {
            selectors: [
              `.${componentStyle.uploadFileButton}`,
              `.${fileNameStyle.fileWithIcon}`,
            ],
            type: 'single',
          },
          hover: {
            selectors: [
              `.${componentStyle.uploadFileButton}`,
              `.${numOfFilesStyle.numOfFilesLink}`,
              `.${numOfFilesStyle.hasFiles}`,
              `.${fileNameStyle.fileWithIcon}`,
            ],
            type: 'single',
          },
          disabled: {
            selectors: [
              `.${componentStyle.uploadFileButton}`,
              `.${fileNameStyle.fileWithIcon}`,
            ],
            type: 'single',
          },
        });

        const shouldShowValidityIndication =
          compPreviewState === 'error' ||
          viewerProps.shouldShowValidityIndication;

        let dummyValue: Array<FileMetaData> = [];

        if (shouldRenderDummyFileName && !value?.length) {
          dummyValue = [
            {
              name: translations.previewFileName!,
              size: 1,
              valid: true,
              validityInfo: {
                invalidKey: '',
                invalidInfo: '',
              },
              fileInfo: null,
              uploadStatus: 'Not_Started',
            },
          ];
        }

        return (
          <ViewerComponent
            {...viewerProps}
            shouldShowValidityIndication={shouldShowValidityIndication}
            {...(dummyValue?.length && {
              value: dummyValue,
              disableFileNameXIconFocus: true,
            })}
            ref={compRef}
          />
        );
      },
    ),
  );
