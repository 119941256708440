import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type { ILottieEmbedStateRefValues } from '../LottieEmbed.types';

export default withCompController<
  Record<string, any>,
  ILottieEmbedStateRefValues,
  never,
  ILottieEmbedStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion } = stateValues;
  return { ...mapperProps, reducedMotion };
});
