export const ResetPasswordDialogTranslationKeys = {
  title: 'resetPasswordTitle',
  subtitle: 'resetPasswordSubtitle',
  buttonText: 'resetPasswordButtonText',
  passwordInputText: 'resetPasswordPasswordInputText',
  retypePasswordInputText: 'resetPasswordRetypePasswordInputText',
  policies: {
    termsOfUseLink: 'membersPoliciesTermsOfUseLink',
    privacyLink: 'membersPoliciesPrivacyLink',
    and: 'membersPoliciesAnd',
  },
};

export const testIds = {
  headline: 'resetPassword.headline',
  reset: 'resetPassword.reset',
  policies: {
    privacyPolicyLink: 'resetPassword.privacyPolicyLink',
    and: 'resetPassword.policiesAnd',
    termsOfUseLink: 'resetPassword.termsOfUseLink',
  },
};
