import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  IQuickActionBarMapperProps,
  QuickActionBarDefinition,
  QuickActionBarCSSVars,
  IQuickActionBarStateValues,
} from '../QuickActionBar.types';

export const props = withCompInfo<
  IQuickActionBarMapperProps,
  QuickActionBarDefinition,
  IQuickActionBarMapperProps
>()(
  [
    'translate',
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
  ],
  ({ translate, ...compPropsRest }, carmiData) => {
    return {
      ...carmiData,
      ...compPropsRest,
      translations: {
        menuButtonAriaLabel: translate(
          'ariaLabels',
          'Mobile_QuickActionBar_AriaLabel_MenuButton',
        ),
      },
    };
  },
);

export const css = withCompInfo<
  QuickActionBarCSSVars,
  QuickActionBarDefinition,
  QuickActionBarCSSVars
>()([], (_, carmiCSSVars) => {
  return carmiCSSVars;
});

const stateRefs = withStateRefsValues<keyof IQuickActionBarStateValues>([
  'trackEvent',
  'reportBi',
]);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
