import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  DisqusCommentsDefinition,
  DisqusCommentsMapperProps,
  DisqusCommentsStateRefs,
} from '../DisqusComments.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  consentPolicyPropsDeps,
  withConsentPolicyProps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

export const props = withCompInfo<
  DisqusCommentsMapperProps,
  DisqusCommentsDefinition
>()(['compData', ...consentPolicyPropsDeps], compInfo => {
  const originalProps = {
    disqusId: compInfo.compData.disqusId,
  };
  return withConsentPolicyProps(
    compInfo,
    originalProps,
    consetPolicyKeys.componentNameKey,
    consetPolicyKeys.componentNameDefault,
  );
});

export const stateRefs = withStateRefsValues<keyof DisqusCommentsStateRefs>([
  'currentUrlWithQueryParams',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
