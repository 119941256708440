import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getHeightInPixels } from '@wix/editor-elements-common-utils';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  StripShowcaseDefinition,
  StripShowcasePlatformProps,
  StripShowcaseCarmiData,
  StripShowcaseCSSVars,
} from '../StripShowcase.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

export const props = withCompInfo<
  StripShowcasePlatformProps,
  StripShowcaseDefinition,
  StripShowcaseCarmiData
>()(propsDependencies, (data, carmiProps) => ({
  ...propsResolver<StripShowcaseDefinition>(
    data,
    carmiProps,
    'StripShowcase/StripShowcase.html',
  ),
  currentIndex: 0,
  isScreenWidth: true,
}));

const sdkData = withCompInfo<TPAGallerySdkData, StripShowcaseDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<StripShowcaseCSSVars, StripShowcaseDefinition>()(
  ['compLayout', 'compSingleLayout', 'isOneDocMigrated'],
  ({ compLayout, compSingleLayout, isOneDocMigrated }) => {
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;
    return {
      '--height': `${height}px`,
    };
  },
);

export default createComponentMapperModel({ css, props, sdkData });
