import * as React from 'react';
import { createPortal } from 'react-dom';

import { IModalSuggestionsProps } from '../Suggestion.types';
import { SuggestionList } from '../SuggestionList';
import { Footer } from '../footer/Footer';
import { MODAL_SEARCH_SUGGESTIONS_TEST_ID } from '../../constants';

import { CloseButton } from './CloseButton';
import { Loader } from './Loader';
import style from './ModalSuggestions.scss';

export const ModalSuggestions: React.FC<IModalSuggestionsProps> = ({
  children,
  clearValue,
  closeSuggestions,
  direction,
  id,
  inputRef,
  onSuggestionsFooterClick,
  query,
  scrollIntoRef,
  suggestions,
  suggestionsLoading,
  translations,
  scopedClassName,
}) => {
  const onClickCloseSuggestions = () => {
    closeSuggestions();
    clearValue();
  };

  const [modalWrapper, setModalWrapper] = React.useState<Element>();

  React.useEffect(() => {
    const modalWrapperElement = document.createElement('div');
    document.body.appendChild(modalWrapperElement);

    if (scopedClassName) {
      modalWrapperElement.classList.add(scopedClassName);
    }

    setModalWrapper(modalWrapperElement);

    return () => {
      document.body.removeChild(modalWrapperElement);
    };
  }, [scopedClassName]);

  const handleScroll = () => {
    inputRef.current?.blur();
  };

  React.useEffect(() => {
    // Autofocus the input with a workaround. On iOS we need to wait a bit until the virtual keyboard
    // shows with an animation - to avoid SearchBox being scrolled out of view in some websites.
    setTimeout(() => {
      inputRef.current?.focus();
    }, 400);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return modalWrapper
    ? createPortal(
        <div
          dir={direction}
          className={style.root}
          data-testid={MODAL_SEARCH_SUGGESTIONS_TEST_ID}
          role="dialog"
        >
          <div className={style.searchBoxInputContainer}>
            <CloseButton
              direction={direction}
              onClick={onClickCloseSuggestions}
            />
            <div className={style.searchBoxBorderContainer}>{children}</div>
          </div>
          <div className={style.suggestionContainer} onScroll={handleScroll}>
            <SuggestionList
              scrollIntoRef={scrollIntoRef}
              suggestions={suggestions}
            />
            {suggestions?.footer && (
              <Footer
                id={id}
                onSuggestionsFooterClick={onSuggestionsFooterClick}
                query={query}
                translations={translations}
                useModalSuggestions
              />
            )}
            {suggestionsLoading && <Loader />}
          </div>
        </div>,
        modalWrapper,
      )
    : null;
};
