import type {
  IMemberLoginDialogProps,
  ISocialVendors,
} from '../MemberLoginDialog/MemberLoginDialog.types';
import type { SiteMembersTranslations } from '../MemberLoginDialog/viewer/utils';

export enum IPrivacyNoteType {
  Checkbox = 'CHECKBOX',
  Note = 'NOTE',
}

export type ISignUpDialogProps = Omit<
  IMemberLoginDialogProps,
  'onForgetYourPasswordClick'
> &
  SiteMembersTranslations & {
    submit: (
      email: string,
      password: string,
      options?: {
        recaptchaToken?: string;
        isCommunityChecked?: boolean;
      },
    ) => Promise<void>;
    isCommunityInstalled: boolean;
    isTermsOfUseNeeded: boolean;
    isPrivacyPolicyNeeded: boolean;
    isCodeOfConductNeeded: boolean;
    codeOfConductLink: ILink;
    privacyPolicyLink: ILink;
    termsOfUseLink: ILink;
    privacyNoteType?: IPrivacyNoteType;
    joinCommunityCheckedByDefault: boolean;
    onTokenMessage: (
      token: string,
      vendor: ISocialVendors, // TODO: whenever we delete `sm_googleAuthViaSDK` experiment we can replace it with 'facebook' val.
      isCommunityChecked?: boolean,
    ) => Promise<void>;

    onBackendSocialLogin: (
      data: any,
      idpConnectionId: string,
      isCommunityChecked?: boolean,
    ) => Promise<void>;
    getHostReadyPayload?: () => any;
    className?: string;
    externalBaseUrl?: string;
    headlessRedirectUrl?: string;
  };

export type ILink = { href: string; target: ILinkTarget };

export type ILinkTarget = '_self' | '_blank';

export interface IPolicyLink {
  onCloseDialogCallback: () => void;
  privacyLinkLabel?: string;
  link: ILink;
  testId: string;
  className?: string;
}
