export const DataType = 'ToggleSwitch';
export const PropertiesType = 'ToggleSwitchProperties';

export const translationKeys = {
  layoutPanel: {
    layoutLabel: 'ToggleSwitch_Layout_Label',
    layoutTitle: 'ToggleSwitch_Layout_Layout',
    trackHeight: 'ToggleSwitch_Layout_Track_Height',
    knobSize: 'ToggleSwitch_Knob_Size',
    switchHeight: 'ToggleSwitch_Layout_label_switch_height',
    labelMargin: 'ToggleSwitch_Layout_label_margin',
    labelPadding: 'ToggleSwitch_Layout_label_padding',
    alignmentLabel: 'ToggleSwitch_Layout_Placement_Alignment_Label',
    displayKnobIcons: 'ToggleSwitch_Settings_Display_Knob_Icons',
    layoutPlacementInside: 'ToggleSwitch_Layout_Placement_Inside',
    layoutPlacementOutside: 'ToggleSwitch_Layout_Placement_Outside',
  },
};

export const dataHooks = {
  layoutPanel: {
    handlePlacement: 'handlePlacement',
    handleSize: 'handleSize',
    trackSize: 'trackSize',
    handleAlignment: 'handleAlignment',
    displayHandleIcons: 'displayHandleIcons',
    labelMargin: 'labelMargin',
    labelPadding: 'labelPadding',
    switchHeight: 'switchHeight',
  },
};
export const LayoutSliderLimits = {
  minSwitchHeight: 24,
  maxSwitchHeight: 10000,
  minKnobSize: 50,
  maxKnobSize: 100,
  minTrackHeight: 33,
  maxTrackHeight: 99,
  minSliderValue: 0,
  maxSliderValue: 100,
};

export const helpIds = {
  layoutPanel: '69a1d1cd-81f8-4c58-88cb-7b81eea54e60',
};

export const TestIds = {
  label: 'ToggleSwitch_label',
  checkedIcon: 'ToggleSwitch_checked_icon',
  uncheckedIcon: 'ToggleSwitch_unchecked_icon',
  outerLabel: 'ToggleSwitch_outer_label',
  switchWrapper: 'ToggleSwitch_switch_wrapper',
} as const;
