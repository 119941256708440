import * as React from 'react';
import { IAudioPlayerProps } from '../../../AudioPlayer.types';
import BasePlayer from '../../BasePlayer';
import style from './styles/ShinyPlayer.scss';

const ShinyPlayer: React.FC<IAudioPlayerProps> = props => {
  return <BasePlayer {...props} style={style} />;
};

export default ShinyPlayer;
