
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Steppercomponent2494944297",
      {"classes":{"root":"Steppercomponent2494944297__root","stepper":"Steppercomponent2494944297__stepper"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  