import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PinterestPinItMapperProps,
  PinterestPinItDefinition,
  PinterestPinItStateRefs,
  PinterestPinItCSSVars,
} from '../PinterestPinIt.types';
import { BUTTON_SIZES, consetPolicyKeys } from '../constants';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';

export const props = withCompInfo<
  PinterestPinItMapperProps,
  PinterestPinItDefinition
>()(
  [
    'compData',
    'compProps',
    'topology',
    'externalBaseUrl',
    ...consentPolicyPropsDeps,
  ],
  compInfo => {
    const {
      compData: { uri, description, urlFormat, isHttpsEnabled },
      compProps,
      topology,
      externalBaseUrl,
    } = compInfo;
    const originalProps = {
      uri,
      description,
      size: compProps.size,
      color: compProps.color,
      counterPosition: compProps.counterPosition,
      staticMediaUrl: topology.staticMediaUrl,
      urlFormat,
      externalBaseUrl,
      isHttpsEnabled,
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

export const css = withCompInfo<
  PinterestPinItCSSVars,
  PinterestPinItDefinition
>()(['compProps'], ({ compProps }) => {
  const { width, height } =
    BUTTON_SIZES[compProps.counterPosition][compProps.size];

  return {
    width: `${width}px`,
    height: `${height}px`,
  };
});

export const stateRefs = withStateRefsValues<keyof PinterestPinItStateRefs>([
  'pageId',
  'relativeUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
