export const DataType = 'CustomMenuDataRef';
export const MenuRef = '#CUSTOM_MAIN_MENU';
export const ViewerType = 'wixui.Menu';

export const MenuOrientation = {
  Horizontal: 'horizontal',
  Vertical: 'vertical',
} as const;
export const MenuOverflow = {
  Scroll: 'Scroll',
  Wrap: 'Wrap',
} as const;
export const ComponentMetaData = {
  displayName: 'Menu',
  componentType: ViewerType,
  nickName: 'menu',
  skinName: 'wixui.skins.Menu',
};

export const TranslationKeys = {
  MenuDefinition: 'component-editors.wixui.Menu_DisplayName',
  gfppManageLabel: 'menu_gfpp_manage_label',
  layoutPanel: {
    MenuLayout: 'menu_layout_header_label',
    Menu: 'menu_layout_tab_menu_label',
    Dropdown: 'menu_layout_tab_dropdown_label',
    Orientation: 'menu_layout_display_label',
    Horizontal: 'menu_layout_display_horizontal',
    Vertical: 'menu_layout_display_vertical',
    OverflowItems: 'menu_layout_overflow_label',
    Scroll: 'menu_layout_overflow_scroll',
    Wrap: 'menu_layout_overflow_wrap',
    ShowScrollIcon: 'menu_layout_scroll_toggle_label',
    ShowDropdownIcon: 'menu_layout_dropdown_toggle_label',
    Spacing: 'menu_layout_spacing_section_title',
    SpaceBetweenItems: 'menu_layout_spacing_label',
    Padding: 'menu_layout_padding_section_title',
    ItemPadding: 'menu_layout_padding_label',
    MenuPadding: 'menu_layout_menu_padding_label',
    Add_dividers: 'menu_layout_dividers_toggle',
    Space_between_items_divider: 'menu_layout_spacing_divider_label',
    Space_between_text_icon: 'menu_layout_spacing_icon_label',
  },
  designPanel: {
    title: 'menu_design_header_label',
    itemPart: 'menu_design_part_selection_items',
    containerPart: 'menu_design_part_selection_container',
    scrollButtonPart: 'menu_design_part_selection_scroll',
  },
};

// TODO: replace with real help ids
export const helpIds = {
  layoutPanel: 'to-do',
  designPanel: 'to-do',
};

export const MenuParts = {
  Menu: 1,
  Dropdown: 2,
};

export const DataHooks = {
  layoutPanel: {
    orientationThumbnails: 'orientation-thumbnails',
    overflowThumbnails: 'overflow-thumbnails',
    showScrollButton: 'show-scroll-button',
    showDropDownIcon: 'show-dropdown-icon',
    addDividers: 'add-dividers',
    horizontalSpacingBetweenItems: 'horizontal-spacing-between-items',
    spaceBetweenItemsDivider: 'space-between-items-divider',
    verticalSpacingBetweenItems: 'vertical-spacing-between-items',
    spacingBetweenItems: 'spacing-between-items',
    spacingBetweenTextAndDropdownIcon: 'spacing-between-text-and-dropdown-icon',
    padding: 'padding',
    verticalPadding: 'vertical-padding',
    horizontalPadding: 'horizontal-padding',
  },
};
