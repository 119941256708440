import React from 'react';

export const Plus: React.FC = () => (
  <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.2222 5.05556H8.94444V0.777778C8.94444 0.35 8.59444 0 8.16667 0H5.83333C5.40556 0 5.05556 0.35 5.05556 0.777778V5.05556H0.777778C0.35 5.05556 0 5.40556 0 5.83333V8.16667C0 8.59444 0.35 8.94444 0.777778 8.94444H5.05556V13.2222C5.05556 13.65 5.40556 14 5.83333 14H8.16667C8.59444 14 8.94444 13.65 8.94444 13.2222V8.94444H13.2222C13.65 8.94444 14 8.59444 14 8.16667V5.83333C14 5.40556 13.65 5.05556 13.2222 5.05556Z" />
  </svg>
);

export const Minus: React.FC = () => (
  <svg viewBox="0 0 14 4" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="4" rx="1" />
  </svg>
);
