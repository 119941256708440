import type { MenuItemProps } from '@wix/thunderbolt-components-native';
import type { Overwrite } from '@wix/editor-elements-definitions';
import type { PagesMap } from '@wix/thunderbolt-becky-types';
import type {
  IBreadcrumbMetaData,
  IBreadcrumbsProps,
  IBreadcrumbsMapperProps,
  PagesMapById,
  PageData,
} from './Breadcrumbs.types';

/**
 * home/current items should be ignored during calculations
 * of amount of items which should be rendered
 * in order to preserve position of ellipsis
 *
 * As an example:
 *
 * home/item/current - without ellipsis
 * home/.../current - with ellipsis
 * .../current - homePage hidden
 * home/... - currentPage hidden
 *
 * Same with longer breadcrumbs
 * home/item/.../item/current => item/.../item/current || home/item/.../item
 */

export const getHeadIndex = (
  itemsBeforeCollapse: IBreadcrumbsProps['itemsBeforeCollapse'],
  showHomePage: IBreadcrumbsProps['showHomePage'],
) => itemsBeforeCollapse - Number(!showHomePage);

export const getTailIndex = (
  itemsAfterCollapse: IBreadcrumbsProps['itemsAfterCollapse'],
  showCurrentPage: IBreadcrumbsProps['showCurrentPage'],
) => itemsAfterCollapse - Number(!showCurrentPage);

type CanRenderEllipsis = (
  value: Pick<
    IBreadcrumbsProps,
    | 'itemsBeforeCollapse'
    | 'itemsAfterCollapse'
    | 'showHomePage'
    | 'showCurrentPage'
  > & {
    breadcrumbs: IBreadcrumbsProps['breadcrumbs'] | Array<IBreadcrumbMetaData>;
  },
) => boolean;

export const canRenderEllipsis: CanRenderEllipsis = ({
  itemsBeforeCollapse,
  itemsAfterCollapse,
  breadcrumbs,
  showHomePage,
  showCurrentPage,
}) => {
  /**
   * Even when "props.shouldWrap === false", there is a situation,
   * when ellipsis will not be rendered.
   *
   * And this situation is when we do not have enough items
   */
  const headIndex = getHeadIndex(itemsBeforeCollapse, showHomePage);
  const tailIndex = getTailIndex(itemsAfterCollapse, showCurrentPage);

  return headIndex + tailIndex < breadcrumbs.length;
};

type IPropsData = Pick<
  IBreadcrumbsMapperProps,
  | 'svgString'
  | 'showOnlyPreviousPageOnMobile'
  | 'showHomePageAsIcon'
  | 'showHomePage'
  | 'showCurrentPage'
>;

type IBreadcrumbsMenuItemProps = Overwrite<
  MenuItemProps,
  { link?: MenuItemProps['link']; label?: string }
> & {
  isCurrent?: boolean;
  icon?: string;
};

type IBreadcrumbsTrail = Array<IBreadcrumbsMenuItemProps>;
type IBreadcrumbsItem = Overwrite<IBreadcrumbsMenuItemProps, { link?: string }>;

export interface IPagesInfo {
  pagesMenuItems: Array<MenuItemProps>;
  isMobile: boolean;
}

interface IRouteInfo {
  currentUrl: string;
  pageTitle: string;
  homePageHref: string;
  pageId: string;
}

const copy = <T extends Record<string, any> | Array<any>>(target: T): T =>
  JSON.parse(JSON.stringify(target));

const startsWithHomepage = (trail: IBreadcrumbsTrail, homePageHref: string) =>
  trail[0]?.link?.href === homePageHref;

const endsWithCurrent = (trail: IBreadcrumbsTrail, currentUrl: string) => {
  const lastItem = trail[trail.length - 1];

  return Boolean(lastItem?.link?.href === currentUrl || lastItem?.isCurrent);
};

const getCurrentPageBreadcrumb = (
  pagesMenuItems: IPagesInfo['pagesMenuItems'],
  routeInfo: IRouteInfo,
) => {
  const { currentUrl, pageTitle } = routeInfo;
  const currentBreadcrumbItem = getMenuItemByHref(pagesMenuItems, currentUrl);

  const label = currentBreadcrumbItem?.label || pageTitle;

  return label && { label, isCurrent: true };
};

const getMenuItemByHref = (
  pagesMenuItems: IPagesInfo['pagesMenuItems'],
  href: string,
): MenuItemProps | undefined => {
  for (const item of pagesMenuItems) {
    if (!item) {
      continue;
    }

    if (item.link?.href === href) {
      return { ...item };
    }

    if (item.items?.length) {
      const result = getMenuItemByHref(item.items, href);

      if (result) {
        return result;
      }
    }
  }

  return;
};

const getFullBreadcrumbsTrail = (
  trail: IBreadcrumbsTrail,
  { pagesMenuItems }: IPagesInfo,
  routeInfo: IRouteInfo,
  homePageHref: string,
  mapperProps: IPropsData,
) => {
  const { currentUrl } = routeInfo;
  const { showHomePage, showCurrentPage } = mapperProps;

  const homeBreadcrumb = getHomeBreadcrumb(
    pagesMenuItems,
    currentUrl,
    homePageHref,
    mapperProps,
  );

  const currentPageBreadcrumb = getCurrentPageBreadcrumb(
    pagesMenuItems,
    routeInfo,
  );

  if (showHomePage && homeBreadcrumb) {
    trail.unshift(homeBreadcrumb);
  }

  const shouldAddCurrentPage =
    showCurrentPage && !endsWithCurrent(trail, currentUrl);

  if (shouldAddCurrentPage && currentPageBreadcrumb) {
    trail.push(currentPageBreadcrumb);
  }

  return trail;
};

const getHomeBreadcrumb = (
  pagesMenuItems: IPagesInfo['pagesMenuItems'],
  currentUrl: string,
  homePageHref: string,
  propsData: IPropsData,
) => {
  const { showHomePageAsIcon, svgString } = propsData;
  const homeBreadcrumb: IBreadcrumbsMenuItemProps | undefined =
    getMenuItemByHref(pagesMenuItems, homePageHref);

  if (homeBreadcrumb) {
    if (currentUrl === homePageHref) {
      homeBreadcrumb.isCurrent = true;
      delete homeBreadcrumb.link;
    }

    if (showHomePageAsIcon) {
      homeBreadcrumb.icon = svgString;
      delete homeBreadcrumb.label;
    }
  }

  return homeBreadcrumb;
};

const getPreviousPageOnlyTrail = (
  trail: IBreadcrumbsTrail,
  { pagesMenuItems }: IPagesInfo,
  { currentUrl }: IRouteInfo,
  homePageHref: string,
  propsData: IPropsData,
) => {
  const findPreviousPage = (items: IBreadcrumbsTrail) => {
    for (let i = items.length - 1; i >= 0; i--) {
      if (items[i].link?.href) {
        return items[i];
      }
    }
    // We need this one, because if there is no previous page in trail,
    // we will return home page from `pagesMenuItems`
    return getHomeBreadcrumb(
      pagesMenuItems,
      currentUrl,
      homePageHref,
      propsData,
    );
  };

  const previousPageItem = trail.length
    ? findPreviousPage(trail)
    : getHomeBreadcrumb(pagesMenuItems, currentUrl, homePageHref, propsData);

  return previousPageItem ? [previousPageItem] : [];
};

const getBreadcrumbsTrail = (
  menuItems: IBreadcrumbsTrail,
  routeInfo: IRouteInfo,
  res: IBreadcrumbsTrail = [],
): IBreadcrumbsTrail => {
  for (const item of menuItems) {
    if (item.link?.href === routeInfo.currentUrl) {
      return [...res, item];
    }

    if (item.items?.length) {
      const possibleTrail = getBreadcrumbsTrail(item.items, routeInfo, [
        ...res,
        item,
      ]);

      if (possibleTrail.length) {
        return possibleTrail;
      }
    }
  }
  return [];
};

const flattenBreadcrumbsLink = (trail: IBreadcrumbsTrail) =>
  trail.map((item): IBreadcrumbsItem => {
    const { link, ...itemWithoutLink } = item;
    const flatLink = link?.href ? { link: link?.href } : {};
    return { ...itemWithoutLink, ...flatLink };
  });

export const getBreadcrumbsItems = (
  routeInfo: IRouteInfo,
  breadcrumbsPageConfig: IPagesInfo,
  propsData: IPropsData,
) => {
  const { currentUrl, homePageHref } = routeInfo;
  /**
   * Further, we will mutate data in order to build breadcrumbs,
   * so we make deep copies just not to break shared stuff
   */
  const clonedPageConfig = copy(breadcrumbsPageConfig);

  const { pagesMenuItems } = clonedPageConfig;
  const { showOnlyPreviousPageOnMobile } = propsData;

  const trail = getBreadcrumbsTrail(pagesMenuItems, routeInfo);

  // home will be taken from the pages menu if necessary
  if (startsWithHomepage(trail, homePageHref)) {
    trail.shift();
  }

  // current page will be taken from the pages menu if necessary
  if (endsWithCurrent(trail, currentUrl)) {
    trail.pop();
  }

  const getBreadcrumbs = showOnlyPreviousPageOnMobile
    ? getPreviousPageOnlyTrail
    : getFullBreadcrumbsTrail;

  const breadcrumbs = getBreadcrumbs(
    trail,
    clonedPageConfig,
    routeInfo,
    homePageHref,
    propsData,
  );

  return flattenBreadcrumbsLink(breadcrumbs);
};

const getPageMapById = (
  pagesMap: PagesMap,
  homePageId: string,
  mainUrl: string,
): PagesMapById => {
  return Object.entries(pagesMap).reduce(
    (result, [pageUriSeo, { title, id, parentPageId }]) => {
      const isHomePage = id === homePageId;
      const parentHomePageId = isHomePage ? undefined : homePageId;

      result[id] = {
        title,
        pageUriSeo: isHomePage ? mainUrl : `${mainUrl}/${pageUriSeo}`,
        parentPageId: parentPageId || parentHomePageId,
      };

      return result;
    },
    {} as PagesMapById,
  );
};

const createBreadcrumb = ({
  title,
  pageUriSeo,
  icon,
  isCurrent,
}: PageData) => ({
  label: title,
  link: pageUriSeo,
  isCurrent,
  icon,
});

export const getPageHierarchyBreadcrumbs = (
  pagesMap: PagesMap,
  routeInfo: IRouteInfo,
  mainPageId: string,
  propsData: IPropsData,
) => {
  const currentPageId = routeInfo.pageId;
  const pagesMapById = getPageMapById(
    pagesMap,
    mainPageId,
    routeInfo.homePageHref,
  );
  const {
    showHomePageAsIcon,
    showCurrentPage,
    showHomePage,
    svgString,
    showOnlyPreviousPageOnMobile: showOnlyPreviousPage,
  } = propsData;

  const breadcrumbsTrail = [];
  let pageId: string | undefined = currentPageId;

  while (pageId) {
    const current: PageData | undefined = pagesMapById[pageId];
    const isCurrentPage = pageId === currentPageId;
    const isHomePage = pageId === mainPageId;
    const isPreviousPage = pageId === pagesMapById[currentPageId].parentPageId;

    const isOnlyItem = isCurrentPage && isHomePage;

    const isVisibleAsCurrentPage = isCurrentPage ? showCurrentPage : true;
    const isVisibleAsHomePage = isHomePage ? showHomePage : true;
    const isVisibleAsPrevPage = isPreviousPage ? true : !showOnlyPreviousPage;
    const isVisible =
      isVisibleAsCurrentPage && isVisibleAsHomePage && isVisibleAsPrevPage;

    if (isOnlyItem || isVisible) {
      breadcrumbsTrail.push(
        createBreadcrumb({
          ...current,
          isCurrent: isCurrentPage,
          icon: showHomePageAsIcon && isHomePage ? svgString : undefined,
        }),
      );
    }

    pageId = current.parentPageId;
  }

  return breadcrumbsTrail.reverse();
};
