import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobilePlatformProps,
  IFreemiumBannerRefState,
} from '../FreemiumBannerMobile.types';
import {
  NAMESPACE,
  shouldUsePreferredAppExperiment,
  TranslationFallbacks,
  TranslationKeys,
} from './constants';
import type { FreemiumBannerDesktopDefinition } from '../../FreemiumBannerDesktop/FreemiumBannerDesktop.types';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import translationKeys from '../../FreemiumBannerDesktop/viewer/constants';

export const uiType = withCompInfo<any, FreemiumBannerDesktopDefinition>()(
  ['experiments'],
  ({ experiments }) => {
    const shouldShowNewBanner = isExperimentOpen(
      experiments,
      'specs.thunderbolt.WixFreeSiteBannerMobile',
    );
    return shouldShowNewBanner ? 'FreemiumBannerMobileNewBanner' : undefined;
  },
);

export const props = withCompInfo<
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileDefinition
>()(
  ['direction', 'metaSiteId', 'experiments', 'translate'],
  ({ direction, metaSiteId, experiments, translate }) => {
    const shouldUsePreferredApp =
      experiments[shouldUsePreferredAppExperiment] === true ||
      experiments[shouldUsePreferredAppExperiment] === 'new';

    const shouldShowNewBanner = isExperimentOpen(
      experiments,
      'specs.thunderbolt.WixFreeSiteBannerMobile',
    );

    const genericAppBannerText = shouldShowNewBanner
      ? translate(
          translationKeys.NAMESPACE,
          TranslationKeys.genericAppNewBanner,
        ) || TranslationFallbacks.genericAppNewBanner
      : translate(NAMESPACE, TranslationKeys.genericApp) ||
        TranslationFallbacks.genericApp;

    const href = shouldShowNewBanner
      ? `https://www.wix.com/lpviral/enviral`
      : `https://apps.wix.com/place-invites/join-lp/${metaSiteId}?ref=free_banner`;

    return {
      useOverlay: false,
      direction,
      href,
      classNames: [direction],
      shouldReportBi: true,
      shouldUsePreferredApp,
      translations: {
        genericAppBannerText,
        oneAppBannerText:
          translate(NAMESPACE, TranslationKeys.spacesApp) ||
          TranslationFallbacks.WixOneApp,
        fitnessAppBannerText:
          translate(NAMESPACE, TranslationKeys.fitnessApp) ||
          TranslationFallbacks.WixFitnessApp,
        restaurantsAppBannerText:
          translate(NAMESPACE, TranslationKeys.dineApp) ||
          TranslationFallbacks.WixRestaurantsApp,
      },
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IFreemiumBannerRefState>([
  'getAppInstanceByAppDefId',
  'reportBi',
]);

export default createComponentMapperModel({ props, stateRefs, uiType });
