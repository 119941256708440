export enum Border {
  Top = 0,
  Right = 1,
  Bottom = 2,
  Left = 3,
}

const DEFAULT_BORDER_WIDTH = '1px';

export const extractBorderWidth = (
  borderWidthStyle: string,
  border: Border,
) => {
  const borders = (borderWidthStyle || DEFAULT_BORDER_WIDTH).split(' ');
  return borders.length === 1 ? borders[0] : borders[border];
};
