import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getSocialCompBlockingLayerProps,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  VKShareButtonDefinition,
  VKShareButtonMapperProps,
} from '../VKShareButton.types';
import { props as viewerPropsMapper } from './VKShareButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<VKShareButtonMapperProps>,
  VKShareButtonDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    // TODO - remove this workaround once the feature is working properly
    const currentUrl = 'https://www.wix.com/';
    const socialUrl = 'https://www.wix.com/';

    return {
      ...viewerProps,
      currentUrl,
      socialUrl,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
