import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  RadioGroupProps as RadioGroupPlatformProps,
  RadioGroupDefinition,
} from '@wix/thunderbolt-components';
import { props as viewerPropsMapper } from './RadioGroup.mapper';

const props = composeMapper<
  PreviewWrapperProps<RadioGroupPlatformProps>,
  RadioGroupDefinition,
  RadioGroupPlatformProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'],
    remove: ['compId', 'language', 'fullNameCompType', 'trackClicksAnalytics'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const { shouldResetComponent, compPreviewState, componentViewMode } =
      dependencies;

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: {
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
