
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ShareButtonscomponent2477460758",
      {"classes":{"root":"ShareButtonscomponent2477460758__root","container":"ShareButtonscomponent2477460758__container","button":"ShareButtonscomponent2477460758__button"},"vars":{},"stVars":{"btns-list-column-gap":"var(--btns-list-column-gap)","btns-list-row-gap":"var(--btns-list-row-gap)","btns-list-flex-direction":"var(--btns-list-flex-direction)","btns-list-justify-content":"var(--btns-list-justify-content)","btns-list-align-items":"var(--btns-list-align-items)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  