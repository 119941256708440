import { withCompController } from '@wix/editor-elements-integrations';
import { getSocialUrl } from '@wix/editor-elements-common-utils';
import {
  VKShareButtonMapperProps,
  VKShareButtonControllerProps,
  IVKShareButtonProps,
  VKShareButtonStateRefs,
} from '../VKShareButton.types';

const compController = withCompController<
  VKShareButtonMapperProps,
  VKShareButtonControllerProps,
  IVKShareButtonProps,
  VKShareButtonStateRefs
>(({ stateValues, mapperProps }) => {
  const { urlFormat, externalBaseUrl, isHttpsEnabled, ...restProps } =
    mapperProps;

  const { pageId, relativeUrl, currentUrlWithQueryParams } = stateValues;

  const socialUrl = getSocialUrl({
    urlFormat,
    isMasterPage: false,
    pageId,
    relativeUrl,
    externalBaseUrl,
    isHttpsEnabled,
    currentUrl: currentUrlWithQueryParams,
  });

  return {
    ...restProps,
    socialUrl,
    currentUrl: currentUrlWithQueryParams,
  };
});

export default compController;
