import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ISkipToContentButtonProps } from '../SkipToContentButton.types';
import {
  A11Y_BUTTON_LABEL_TRANS_DEFAULT_VAL,
  A11Y_BUTTON_LABEL_TRANS_FEATURE,
  A11Y_BUTTON_LABEL_TRANS_KEY,
} from './constants';

export const props = withCompInfo<
  ISkipToContentButtonProps,
  any,
  ISkipToContentButtonProps
>()(['translate'], ({ translate }, carmiProps) => {
  return {
    ...carmiProps,
    translations: {
      buttonLabel:
        translate(
          A11Y_BUTTON_LABEL_TRANS_FEATURE,
          A11Y_BUTTON_LABEL_TRANS_KEY,
        ) || A11Y_BUTTON_LABEL_TRANS_DEFAULT_VAL,
    },
  };
});

export default createComponentMapperModel({ props });
