import * as React from 'react';
import { useState } from 'react';
import { IRatingsInputImperativeActions } from '../../RatingsInput.types';
import { st, classes } from './RatingsInputItem.component.st.css';
import { RatingIcon } from './RatingIcon';

const wrapSvgString = (svgString: string) => {
  return (
    <div
      dangerouslySetInnerHTML={{
        __html: svgString || '',
      }}
    />
  );
};

export interface RatingsInputItemProps {
  className?: string;
  name: string;
  value: number;
  hovered?: number;
  ratingInputValue?: number | null;
  onChange?: React.ChangeEventHandler;
  required: boolean;
  ref?: React.Ref<IRatingsInputImperativeActions>;
  ariaLabel?: string | undefined;
  onMouseEnter?: React.MouseEventHandler;
  onMouseLeave?: React.MouseEventHandler;
  svgString: string;
  error: boolean;
}

const RatingsInputItem: React.ForwardRefRenderFunction<
  IRatingsInputImperativeActions,
  RatingsInputItemProps
> = (props, ref) => {
  const {
    value,
    name,
    required,
    className,
    ariaLabel,
    onMouseEnter,
    onMouseLeave,
    hovered,
    ratingInputValue: rating,
    svgString,
    onChange,
    error,
  } = props;

  const radioRef = React.useRef<HTMLInputElement>(null);

  React.useImperativeHandle(ref, () => {
    return {
      focus() {
        radioRef.current?.focus();
      },
      blur() {
        radioRef.current?.blur();
      },
      setCustomValidity: message => {
        if (message.type === 'message') {
          radioRef.current?.setCustomValidity(message.message);
        }
      },
    };
  });

  const [focused, setFocused] = useState(false);
  const [focusVisible, setFocusVisible] = useState(false);
  const [focusedByMouse, setFocusedByMouse] = useState(false);

  const handleClick: React.MouseEventHandler = event => {
    setFocusedByMouse(true);
    radioRef.current?.click();
    radioRef.current?.focus();
    event.preventDefault();
  };

  const onFocus = () => {
    setFocused(true);
    setFocusVisible(!focusedByMouse);
  };

  const onInputBlur = () => {
    setFocused(false);
    setFocusVisible(false);
    setFocusedByMouse(false);
  };

  const handleInputKeyDown = () => {
    setFocusVisible(true);
    radioRef.current?.click();
  };

  const visuallyCheckedPredicate = (val: number) =>
    val <= (hovered || rating || 0);

  const isVisuallyChecked = visuallyCheckedPredicate(value);

  const icon = wrapSvgString(svgString);

  const _onChange: React.ChangeEventHandler = event => {
    onChange?.(event);
  };

  const onLabelClick: React.MouseEventHandler = event => {
    event.preventDefault(); // click on label will trigger unwanted change event on input
  };

  return (
    <label
      className={st(
        classes.root,
        {
          checked: isVisuallyChecked,
          focused,
          'focus-visible': focusVisible,
          error,
        },
        className,
      )}
      role="radio"
      aria-checked={isVisuallyChecked}
      aria-label={ariaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onLabelClick}
    >
      <input
        type="radio"
        className={classes.hiddenInput}
        required={required}
        onFocus={onFocus}
        onBlur={onInputBlur}
        checked={rating === value}
        value={value}
        name={name}
        onKeyDown={handleInputKeyDown}
        ref={radioRef}
        onChange={_onChange}
      />
      <RatingIcon
        svg={icon}
        className={st(classes.icon, { checked: isVisuallyChecked })}
        onClick={handleClick}
      />
    </label>
  );
};

export default React.forwardRef(RatingsInputItem);
