import React, { useRef, useImperativeHandle } from 'react';
import {
  getDataAttributes,
  keyCodes,
  formatClassNames,
  getAriaAttributes,
  getTabIndexAttribute,
} from '@wix/editor-elements-common-utils';

import { useSwitchAnimation } from '@wix/thunderbolt-elements/providers/useSwitchAnimation';
import { useDidUpdate } from '@wix/thunderbolt-elements/providers/useDidUpdate';
import { useMSBStates } from '../../MultiStateBox/viewer/useMSBStates';
import type {
  ITabsProps,
  ITabsImperativeActions,
  ITabsListItem,
} from '../Tabs.types';
import {
  AnimationStyleVars,
  TabItemClickedEventType,
  Transition,
} from '../constants';
import semanticClassNames from '../Tabs.semanticClassNames';
import ResponsiveBox from '../../ResponsiveBox/viewer/ResponsiveBox';
import TabsList from './TabsList/TabsList';
import { st, classes } from './style/Tabs.component.st.css';
import TabWrapper from './TabWrapper';

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `${id}-container`,
  // TODO - wire correctly
  overlowWrapperClassName: `${id}-overflow-wrapper`,
  // TODO - wire correctly
  hasOverflow: true,
});

const getTabId = (
  tabItems: Array<ITabsListItem>,
  tabElement: React.ReactElement,
) => {
  const tabItem = tabItems.find(item => item.id === tabElement.props.id);
  return tabItem?.tabId ?? '';
};

const secondsToMilliseconds = (time: string) => {
  const timeWithoutSecs = parseFloat(time);
  return timeWithoutSecs * 1000;
};

const Tabs: React.ForwardRefRenderFunction<
  ITabsImperativeActions,
  ITabsProps
> = (props, ref) => {
  const {
    id,
    currentTabId,
    tabItems,
    className,
    customClassNames = [],
    stylableClassName,
    children,
    setCurrentTabId,
    onTabItemClicked,
    onChange,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    hasResponsiveLayout,
    containerProps,
    containerRootClassName,
    observeChildListChange,
    a11y = {},
    ariaAttributes = {},
    tabIndex,
  } = props;

  const tabIds = tabItems.map(item => item.tabId);

  const getElementComputedValue = (property: string) => {
    const elem = document.getElementById(id) as HTMLDivElement;
    return elem != null
      ? getComputedStyle(elem).getPropertyValue(property)
      : null;
  };

  const getEnterAnimationName = () => {
    return getElementComputedValue(AnimationStyleVars.ENTER);
  };

  const getAnimationDuration = () => {
    const timeComputedValue = getElementComputedValue(
      AnimationStyleVars.DURATION,
    );

    const hasTranisition = getEnterAnimationName() !== Transition.None;

    return timeComputedValue && hasTranisition
      ? secondsToMilliseconds(timeComputedValue)
      : 0;
  };

  const [tabIdToAnimationState, switchToTab] = useSwitchAnimation({
    elementIds: tabIds,
    currentElementId: currentTabId,
    setCurrentElementId: setCurrentTabId,
    getAnimationDuration,
  });

  const interactionProps = {
    onClick,
    onDoubleClick: onDblClick,
    onMouseEnter,
    onMouseLeave,
  };

  useImperativeHandle(ref, () => ({
    setCurrentTabId,
  }));

  const tabRefs = useRef<Record<string, HTMLDivElement>>({});
  // focuses the tab after is visible
  useDidUpdate(() => {
    tabRefs.current[currentTabId]?.focus({ preventScroll: true });
  }, [currentTabId]);

  const a11yProps = getAriaAttributes({
    ...a11y,
    ...ariaAttributes,
  });

  const tabIndexAttribute = getTabIndexAttribute({
    tabIndex: tabIndex as number | undefined,
    tabindex: a11y?.tabindex,
  });

  const wrap = (tabElement: React.ReactElement) => {
    const tabId = getTabId(tabItems, tabElement);
    return TabWrapper({
      containerId: id,
      tabElement,
      currentTabRef: (tabRef: HTMLDivElement) => {
        tabRefs.current[getTabId(tabItems, tabElement)] = tabRef;
      },
      animationState: tabIdToAnimationState[tabId],
    });
  };

  const tabsToRender = useMSBStates({
    children,
    getStateId: (tabElement: React.ReactElement) =>
      getTabId(tabItems, tabElement),
    selectedStateId: currentTabId,
    onChange,
    shouldRenderAllTabs: true,
    wrap,
  });

  const activeMenuItemRef = useRef<HTMLDivElement>(null);

  const tabsContainerRef = useRef<HTMLDivElement>(null);

  const handleTabItemClick = (tabId: string, uniqueId: string) => {
    switchToTab(tabId);
    onTabItemClicked?.({ type: TabItemClickedEventType, tabId: uniqueId });
  };

  const handleKeyboardNav = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.keyCode === keyCodes.escape) {
      activeMenuItemRef.current?.focus({ preventScroll: true });
    }
  };

  React.useEffect(() => {
    if (observeChildListChange && tabsContainerRef?.current) {
      observeChildListChange(id, tabsContainerRef.current as HTMLElement);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const content = (
    <div
      className={st(
        classes.root,
        stylableClassName,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
    >
      <TabsList
        className={classes.tabsList}
        currentTabId={currentTabId}
        tabItems={tabItems}
        onTabItemClick={handleTabItemClick}
        activeMenuItemRef={activeMenuItemRef}
        a11y={{ ...a11yProps, ...tabIndexAttribute }}
      />
      <div
        className={st(
          classes.multiStateBoxWrapper,
          formatClassNames(semanticClassNames.tabContainer),
        )}
        onKeyDown={handleKeyboardNav}
        ref={tabsContainerRef}
      >
        {tabsToRender}
      </div>
    </div>
  );

  return hasResponsiveLayout ? (
    <ResponsiveBox
      {...getDataAttributes(props)}
      {...interactionProps}
      className={className}
      hasPlatformClickHandler={false}
      containerRootClassName={containerRootClassName}
      containerProps={containerProps || getDefaultContainerProps(id)}
      id={id}
      shouldUseContainerLayoutClass={true}
    >
      {() => content}
    </ResponsiveBox>
  ) : (
    <div
      {...getDataAttributes(props)}
      {...interactionProps}
      className={className}
      id={id}
    >
      {content}
    </div>
  );
};

export default React.forwardRef(Tabs);
