import type { FC } from 'react';
import React from 'react';

export const LineArrow: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
    <g>
      <g>
        <polygon points="13,20.4 0,7.4 1.8,5.6 13,16.8 24.2,5.6 26,7.4   " />
      </g>
    </g>
  </svg>
);
