import React from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { SkinPageProps } from '../SkinPage';
import Page from '../../Page';
import semanticClassNames from '../../../Page.semanticClassNames';
import styles from './styles/VerySimpleSkin.scss';

const VerySimpleSkin: React.FC<SkinPageProps> = ({
  id,
  className,
  customClassNames = [],
  pageDidMount,
  onClick,
  onDblClick,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={classNames(
        styles.pageWrapper,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      ref={pageDidMount}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children()}
    </div>
  );
};

const VerySimplePage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={VerySimpleSkin} />
);

export default VerySimplePage;
