import classNames from 'clsx';
import React from 'react';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import skinsStyle from './styles/skins.scss';

const FiveGridLine: React.FC<SkinFiveGridLineProps> = props => {
  return (
    <FiveGridLineWrapper
      {...props}
      className={classNames(skinsStyle.root, props.className)}
    >
      <div className={skinsStyle.lineCnt}>
        <div className={skinsStyle.line}></div>
      </div>
      <div className={skinsStyle.leftKnobCnt}>
        <div className={skinsStyle.leftKnob}></div>
      </div>
      <div className={skinsStyle.middleKnobCnt}>
        <div className={skinsStyle.middleKnob}></div>
      </div>
      <div className={skinsStyle.rightKnobCnt}>
        <div className={skinsStyle.rightKnob}></div>
      </div>
    </FiveGridLineWrapper>
  );
};

export default FiveGridLine;
