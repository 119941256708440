import type { RefObject } from 'react';
import { useState, useEffect } from 'react';

export function useScrollBase(containerRef: RefObject<HTMLElement>): number {
  const [scrollBase, setScrollBase] = useState(0);

  useEffect(() => {
    let observedContainer: HTMLElement | null = null;
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && observedContainer) {
          const rect = observedContainer.getBoundingClientRect();
          setScrollBase(rect.top + window.pageYOffset);
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: '0px',
      },
    );

    if (containerRef.current) {
      observedContainer = containerRef.current;
      const rect = observedContainer.getBoundingClientRect();
      setScrollBase(rect.top + window.pageYOffset);
      observer.observe(observedContainer);
    }

    return () => {
      if (observedContainer) {
        observer.unobserve(observedContainer);
      }
    };
  }, [containerRef]);

  return scrollBase;
}
