import * as React from 'react';

import { SuggestionGroupProps } from '../../Suggestion.types';

import style from './Group.scss';

export const Group: React.FC<SuggestionGroupProps> = ({ item }) => {
  return (
    <div className={style.root}>
      <div className={style.inner}>
        <span>{item.title}</span>
      </div>
    </div>
  );
};
