import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IPinterestFollowProps } from '../PinterestFollow.types';
import { TestIds } from '../constants';
import Link from '../../Link/viewer/Link';
import style from './style/PinterestFollow.scss';

const PinterestFollow: React.FC<IPinterestFollowProps> = props => {
  const {
    id,
    label,
    className,
    urlChoice,
    onMouseEnter,
    onMouseLeave,
    reportBiOnClick,
  } = props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className, style.root)}
      onClick={reportBiOnClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Link
        className={style.followButton}
        title={label}
        href={urlChoice}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className={style.followButtonTag}
          data-testid={TestIds.labelWrapper}
        >
          {label}
        </div>
        <div className={style.icon}></div>
      </Link>
    </div>
  );
};

export default PinterestFollow;
