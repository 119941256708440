import classNames from 'clsx';
import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IWFacebookLikeProps } from '../WFacebookLike.types';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import {
  BASE_FACEBOOK_LIKE_API_URL,
  TRANSLATIONS_TITLE_DEFAULT,
} from './constants';
import styles from './styles/WFacebookLike.scss';

const getApiUrl = (params: Record<string, string>) =>
  `${BASE_FACEBOOK_LIKE_API_URL}?${new URLSearchParams(params)}`;

const WFacebookLike: React.FC<IWFacebookLikeProps> = props => {
  const {
    id,
    className,
    translations,
    socialUrl,
    layout,
    showFaces,
    action,
    colorScheme,
    enableSend,
    locale,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const title = translations.title || TRANSLATIONS_TITLE_DEFAULT;

  const apiUrl = React.useMemo(
    () =>
      getApiUrl({
        href: socialUrl,
        layout,
        show_faces: showFaces.toString(),
        action,
        colorscheme: colorScheme,
        send: enableSend.toString(),
        locale,
      }),
    [socialUrl, layout, showFaces, action, colorScheme, enableSend, locale],
  );
  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className: classNames(className, styles.root),
    ...getDataAttributes(props),
  });

  return (
    <wix-iframe
      {...baseComponentProps}
      data-src={apiUrl}
      title={title}
      aria-label={title}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <iframe
        className={styles.iframe}
        data-src={apiUrl}
        title={title}
        aria-label={title}
        scrolling="no"
      />
    </wix-iframe>
  );
};

export default WFacebookLike;
