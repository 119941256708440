import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import * as React from 'react';

import classNames from 'clsx';
import type { ITestCompProps } from '../../../TestComp.types';
import { TestIds, labelText } from '../../../constants';
import styles from './TestComp.scss';

const TestComp: React.FC<ITestCompProps> = props => {
  const {
    id,
    className,
    stylableClassName,
    label = labelText.default,
    onFocus,
    onBlur,
  } = props;

  const inputId = `${id}-input`;

  return (
    // the viewer platform passes the id to the component
    // and uses it to inject css variables scoped for the component
    <div {...getDataAttributes(props)} id={id} className={className}>
      <div
        className={classNames(
          styles.root,
          stylableClassName,
          formatClassNames('test-comp'),
        )}
      >
        <label
          htmlFor={inputId}
          data-testid={TestIds.InputLabel}
          className={styles.label}
        >
          {label}
        </label>
        <input id={inputId} onFocus={onFocus} onBlur={onBlur} type="text" />
        <button id="my-button" className={styles.button}>
          <span className={styles.icon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className={styles.icon}
              viewBox="0 0 20 10"
            >
              <polygon strokeWidth="0" points="0,10 20,10 10,0" />
            </svg>
          </span>
          <span>Button label</span>
        </button>
      </div>
    </div>
  );
};

export default TestComp;
