import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import LineTopScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/LineTopScreen';
import FooterContainer from '../../FooterContainer';

const LineTopScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => (
  <FooterContainer {...props} skin={LineTopScreen}></FooterContainer>
);

export default LineTopScreenFooter;
