
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IStretchedContainerProps,
  IStretchedContainerMapperProps,
  IStretchedContainerControllerProps,
  IStretchedContainerStateValues,
} from '../../../components/StretchedContainer/StretchedContainer.props';

export default withCompController<
  IStretchedContainerMapperProps,
  IStretchedContainerControllerProps,
  IStretchedContainerProps,
  IStretchedContainerStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
