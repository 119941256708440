import React from 'react';
import {
  toQueryString,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { IWTwitterTweetProps } from '../WTwitterTweet.types';
import { WTwitterContent } from '../../WTwitterContent/viewer/WTwitterContent';
import { WidgetType } from '../../WTwitterContent/constants';
import { TITLE_NAME, TWITTER_URL } from './constants';

const WTwitterTweet: React.FC<IWTwitterTweetProps> = props => {
  const {
    id,
    className,
    translations,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const title = translations.title || TITLE_NAME;
  const queryString = toQueryString({
    href: TWITTER_URL,
    lang: props.lang,
    url: props.socialUrl,
    text: props.defaultText,
    related: props.accountToFollow,
    compId: props.id,
    origin: props.currentUrl,
    widgetType: WidgetType.TWEET,
    ...(props.isMobileView ? { size: 'l' } : {}),
  });

  return (
    <WTwitterContent
      {...{
        id,
        ...getDataAttributes(props),
        className,
        title,
        widgetType: WidgetType.TWEET,
        queryString,
        onMouseEnter,
        onMouseLeave,
        isConsentPolicyActive,
      }}
    />
  );
};

export default WTwitterTweet;
