import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';
import type { ResponsiveContainerProps } from '@wix/thunderbolt-components';
import type {
  IHamburgerOverlayProps,
  IHamburgerOverlayStateValues,
} from '../HamburgerOverlay.props';
import type { IHamburgerOverlayDefinition } from '../documentManagement/HamburgerOverlay.definition';
import { HAMBURGER_SANTA_VIEWER_NAMESPACE } from '../../consts';
import { translationKeys } from './constants';

export type IHamburgerOverlayCarmiProps = {
  containerProps?: ResponsiveContainerProps;
  containerRootClassName?: string;
};
export const props = withCompInfo<
  IHamburgerOverlayProps,
  IHamburgerOverlayDefinition,
  IHamburgerOverlayCarmiProps
>()(
  [
    'hasResponsiveLayout',
    'compData',
    'compStylableClass',
    'compId',
    'translate',
  ],
  (
    { hasResponsiveLayout, compData, compStylableClass, compId, translate },
    carmiData,
  ) => ({
    ...carmiData,
    ...compData,
    compId,
    hasResponsiveLayout,
    ariaLabel: translate(
      HAMBURGER_SANTA_VIEWER_NAMESPACE,
      translationKeys.ariaLabel,
    ),
    stylableClassName: compStylableClass,
  }),
);
export const stateRefs = withStateRefsValues<
  keyof IHamburgerOverlayStateValues
>(['setSiteScrollingBlocked', 'enableCyclicTabbing', 'disableCyclicTabbing']);

export default createComponentMapperModel({ props, stateRefs });
