import React from 'react';

export const AlignCenterIcon = () => (
  <path
    fillRule="evenodd"
    d="M14.8 5H4.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h10.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2zm0 6H4.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h10.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2zm-2-3H6.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h6.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2zm-1 6H7.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h4.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2z"
  />
);

export const AlignJustifyIcon = () => (
  <path
    fillRule="evenodd"
    d="M4.2 5h10.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2zm0 6h10.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2zm0-3h10.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2zm0 6h10.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2z"
  />
);

export const AlignRightIcon = () => (
  <path
    fillRule="evenodd"
    d="M14.8 5H4.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h10.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2zm0 6H4.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h10.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2zm0-3H8.2a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h6.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2zm0 6h-4.6a.2.2 0 0 0-.2.2v.6c0 .11.09.2.2.2h4.6a.2.2 0 0 0 .2-.2v-.6a.2.2 0 0 0-.2-.2z"
  />
);

export const AlignLeftIcon = () => (
  <path
    fillRule="evenodd"
    d="M4.2 5h10.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2zm0 6h10.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2zm0-3h6.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2zm0 6h4.6c.11 0 .2.09.2.2v.6a.2.2 0 0 1-.2.2H4.2a.2.2 0 0 1-.2-.2v-.6c0-.11.09-.2.2-.2z"
  />
);
