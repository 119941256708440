import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  IDefaultPreviewWrapperProps,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import { DISABLED_STATE, HOVER_STATE } from '@wix/editor-elements-panel';
import type { ICollapsibleTextCompProps } from '../CollapsibleText.types';
import styles from './skinComps/CollapsibleText/CollapsibleText.scss';

const selectors = {
  button: `.${styles['expand-control']}`,
};

const states = {
  buttonHover: 'button-hover',
  buttonDisabled: 'button-disabled',
};

const previewStateMap = {
  [states.buttonHover]: {
    selectors: selectors.button,
    type: 'single',
    state: HOVER_STATE.id,
  },
  [states.buttonDisabled]: {
    selectors: selectors.button,
    type: 'single',
    state: DISABLED_STATE.id,
  },
};

function withComponentPreview(
  ViewerComponent: React.ComponentType<ICollapsibleTextCompProps>,
): IComponentPreviewWrapper<ICollapsibleTextCompProps, any> {
  return (
    props: PreviewWrapperProps<
      ICollapsibleTextCompProps,
      IDefaultPreviewWrapperProps & { shouldUseSimplePreviewState?: boolean }
    >,
  ) => {
    const { previewWrapperProps = {}, ...viewerProps } = props;
    const { compPreviewState, shouldUseSimplePreviewState } =
      previewWrapperProps;
    const { id } = viewerProps;

    usePreviewState(
      id,
      compPreviewState,
      previewStateMap,
      shouldUseSimplePreviewState,
    );

    return <ViewerComponent {...viewerProps} />;
  };
}

export default (
  ViewerComponent: React.ComponentType<ICollapsibleTextCompProps>,
) =>
  createComponentPreviewEntry<ICollapsibleTextCompProps, any>(
    withComponentPreview(ViewerComponent),
  );
