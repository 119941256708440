import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type { CompInfoDependency } from '@wix/editor-elements-types/thunderbolt';
import type {
  AccordionDefinition,
  AccordionPropsCarmiData,
  AccordionPropsPlatformProps,
} from '../Accordion.types';
import { props as viewerPropsMapper } from './Accordion.mapper';

const viewerDeps = Object.keys(
  viewerPropsMapper.deps,
) as Array<CompInfoDependency>;

const props = withCompPreviewProps<
  AccordionPropsPlatformProps,
  AccordionDefinition,
  AccordionPropsCarmiData
>()(['isPlayingAllowed', ...viewerDeps], (deps, carmiData) => {
  const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
  return {
    ...viewerProps,
    isPlayingAllowed: deps.isPlayingAllowed,
  };
});

export default createComponentPreviewMapperModel({
  props: props as any,
});
