import type { ReactElement } from 'react';
import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type { MeshContainerProps } from './MeshContainer';

const renderRotatedComponentsWrapper = (child: ReactElement): ReactElement => (
  <div
    key={`${child.props.id}-rotated-wrapper`}
    data-mesh-id={`${child.props.id}-rotated-wrapper`}
  >
    <div
      key={`${child.props.id}-non-rotated`}
      data-mesh-id={`${child.props.id}-non-rotated`}
      id={`${child.props.id}-non-rotated`}
    />
    {child}
  </div>
);

export default (ViewerComponent: React.ComponentType<MeshContainerProps>) =>
  createComponentPreviewEntry(function ({
    ...viewerProps
  }: PreviewWrapperProps<MeshContainerProps>) {
    viewerProps.renderRotatedComponents = renderRotatedComponentsWrapper;
    return <ViewerComponent {...viewerProps} />;
  });
