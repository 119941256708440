import React, { useRef } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  usePreviewState,
  usePrevious,
  useResetComponent,
  usePreviewEffect,
} from '@wix/editor-elements-preview-utils';
import {
  ITextInputImperativeActions,
  ITextInputProps,
} from '../TextInput.types';
import style from './style/TextInput.scss';
import { useResizeObserverForContentHeight } from './providers/useResizeObserverForContentHeight';

const noop = () => {};

export default (ViewerComponent: React.ComponentType<ITextInputProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<ITextInputImperativeActions, ITextInputProps>(
      (
        {
          previewWrapperProps = {},
          ...viewerProps
        }: PreviewWrapperProps<
          ITextInputProps,
          {
            shouldResetComponent?: boolean;
            compPreviewState?: string;
            componentViewMode?: string;
            hasResponsiveLayout?: boolean;
          }
        >,
        ref,
      ) => {
        const {
          shouldResetComponent,
          compPreviewState,
          componentViewMode,
          hasResponsiveLayout,
        } = previewWrapperProps;
        const {
          id,
          value = '',
          setValidityIndication = noop,
          onValueChange = noop,
          validateValue = noop,
        } = viewerProps;

        const defaultValue = useRef(value);
        const previousViewMode = usePrevious(componentViewMode);

        React.useEffect(() => {
          if (previousViewMode === 'editor' && componentViewMode === 'editor') {
            defaultValue.current = value;
          }
        }, [value, previousViewMode, componentViewMode]);

        usePreviewEffect({
          componentViewMode,
          onPreviewViewMode: validateValue,
        });

        useResetComponent({
          shouldResetComponent,
          id,
          onResetComponent: () => {
            onValueChange(defaultValue.current);
            setValidityIndication(false);
            validateValue();
          },
        });

        usePreviewState(id, compPreviewState, {
          focus: {
            selectors: `.${style.input}`,
            type: 'single',
          },
          error: {
            selectors: `.${style.input}`,
            type: 'single',
            state: 'invalid',
          },
          hover: {
            selectors: `.${style.input}`,
            type: 'single',
          },
          disabled: {
            selectors: `.${style.input}`,
            type: 'single',
          },
        });

        const shouldShowValidityIndication =
          compPreviewState === 'error' ||
          viewerProps.shouldShowValidityIndication;

        const renderPreviewWrapper = () => {
          return (
            <ViewerComponent
              {...viewerProps}
              ref={ref}
              shouldShowValidityIndication={shouldShowValidityIndication}
              componentViewMode={componentViewMode}
            />
          );
        };

        // Logic for Classic Editor case:
        // TextInput component may have decimal height, so wrap it with extra div with integer height
        const wrapperRef = React.useRef<HTMLDivElement>(null);
        const contentRef = React.useRef<HTMLDivElement>(null);

        useResizeObserverForContentHeight(contentRef, wrapperRef);

        const renderPreviewWrapperWithExtraDiv = () => {
          return (
            <div id={viewerProps.id} ref={wrapperRef}>
              <ViewerComponent
                {...viewerProps}
                ref={ref}
                contentRef={contentRef}
                id={`content-${viewerProps.id}`}
                shouldShowValidityIndication={shouldShowValidityIndication}
                componentViewMode={componentViewMode}
              />
            </div>
          );
        };

        return hasResponsiveLayout
          ? renderPreviewWrapper()
          : renderPreviewWrapperWithExtraDiv();
      },
    ),
  );
