export const translationFeature = 'loginButtonTranslations';

export const LoginButtonKeys = {
  hello: 'Login_Button_Hello',
  signIn: 'Login_Button_Sign_In',
  signOut: 'Login_Button_Sign_Out',
} as const;

export type LoginButtonKey = keyof typeof LoginButtonKeys;

export const TestIds = {
  memberTitle: 'member_title',
  actionTitle: 'action_title',
} as const;
