import type { FC } from 'react';
import React from 'react';

import { ArrowShape } from '../../../LoginSocialBar.types';
import { FullArrow, LineArrow } from '../loggedInSocialBar/icons';

interface ArrowProps {
  shape: ArrowShape;
  className: string;
}

const ArrowShapeMap: Record<
  Exclude<ArrowShape, ArrowShape.None>,
  JSX.Element
> = {
  [ArrowShape.Line]: <LineArrow />,
  [ArrowShape.Full]: <FullArrow />,
};

export const Arrow: FC<ArrowProps> = ({ className, shape }) => {
  if (shape === ArrowShape.None) {
    return null;
  }

  return <div className={className}>{ArrowShapeMap[shape]}</div>;
};
