import * as React from 'react';
import classNames from 'clsx';
import { IVideoLegacyProps } from '../../../VideoLegacy.types';
import { ConsentPolicyWrapper } from '../../../../ConsentPolicyWrapper/viewer/ConsentPolicyWrapper';
import VideoLegacy from '../../VideoLegacy';
import style from '../../style/VideoLegacy.scss';

const VideoLegacyWithConsentWrapper: React.FC<IVideoLegacyProps> = props => {
  const { className, skin = 'VideoDefault' } = props;

  return (
    <ConsentPolicyWrapper
      componentConsentPolicy={{ advertising: true }}
      rootClassName={classNames(className, style.root, style[skin])}
      {...props}
    >
      <VideoLegacy {...props}></VideoLegacy>
    </ConsentPolicyWrapper>
  );
};

export default VideoLegacyWithConsentWrapper;
