import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  SignatureInputDefinition,
  ISignatureInputMapperProps,
} from '../SignatureInput.types';
import { props as viewerPropsMapper } from './SignatureInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<ISignatureInputMapperProps>,
  SignatureInputDefinition
>()(
  viewerPropsMapper,
  { add: ['shouldResetComponent', 'componentViewMode', 'compPreviewState'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { shouldResetComponent, componentViewMode, compPreviewState } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
