import * as React from 'react';
import style from '../../style/FreemiumBannerMobileNewBanner.scss';
import GenericContentNew from '../../GenericContentBannerNew';
import type { FreemiumBannerMobileProps } from '../../../FreemiumBannerMobile.types';
import FreemiumBannerMobileCommon from '../../FreemiumBannerMobileCommon';

const FreemiumBannerMobileNewBanner: React.FC<
  FreemiumBannerMobileProps
> = props => {
  const { translations } = props;

  return (
    <FreemiumBannerMobileCommon {...props} styles={style}>
      <GenericContentNew bannerText={translations.genericAppBannerText!} />
    </FreemiumBannerMobileCommon>
  );
};

export default FreemiumBannerMobileNewBanner;
