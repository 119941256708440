import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IGridControllerProps,
  IGridMapperProps,
  IGridProps,
} from '../Grid.types';

export default withCompController<
  IGridMapperProps,
  IGridControllerProps,
  IGridProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    onChangePage: page => {
      controllerUtils.updateProps({ currentPage: page });
    },
    onChangeSelectedCell: selectedCell => {
      controllerUtils.updateProps({ selectedCell, selectedRow: undefined });
    },
    onChangeSelectedRow: selectedRow => {
      controllerUtils.updateProps({ selectedRow, selectedCell: undefined });
    },
  };
});
