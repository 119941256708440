/**
 * ProgressBar data-hook props used for testing.
 */
export enum TestIDs {
  container = 'progressbar-container',
  background = 'progressbar-background',
  foreground = 'progressbar-foreground',
}

/**
 * ProgressBar attribute keys used to store internal data (current value, min, max etc...)
 */
export enum DataKeys {
  value = 'data-progress-value',
  min = 'data-progress-min-value',
  max = 'data-progress-max-value',
}

export enum AriaKeys {
  valuenow = 'aria-valuenow',
  valuemax = 'aria-valuemax',
  valuemin = 'aria-valuemin',
}
