import * as React from 'react';
import {
  QuickActionBarProps,
  QuickActionBarSkinProps,
  SkinComponent,
} from '../../../QuickActionBar.types';
import BaseFixedSkin from '../BaseFixedSkin';
import QuickActionBar from '../../QuickActionBar';
import styles from './styles/AnchoredSkin.scss';

export const AnchoredSkinComp: React.ForwardRefRenderFunction<
  never,
  QuickActionBarSkinProps
> = (props, ref) => <BaseFixedSkin {...props} ref={ref} />;

const AnchoredSkin: React.FC<QuickActionBarProps> = props => {
  return (
    <QuickActionBar
      {...props}
      skin={React.forwardRef(AnchoredSkinComp) as SkinComponent}
      skinStyles={styles}
    />
  );
};

export default AnchoredSkin;
