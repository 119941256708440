import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type { CompInfoDependency } from '@wix/editor-elements-types/thunderbolt';
import {
  CollageCarmiData,
  CollageDefinition,
  CollagePlatformProps,
} from '../Collage.types';
import { props as viewerPropsMapper } from './Collage.mapper';

const viewerDeps = Object.keys(
  viewerPropsMapper.deps,
) as Array<CompInfoDependency>;

const props = withCompPreviewProps<
  CollagePlatformProps,
  CollageDefinition,
  CollageCarmiData
>()(['isPlayingAllowed', ...viewerDeps], (deps, carmiData) => {
  const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
  return {
    ...viewerProps,
    isPlayingAllowed: deps.isPlayingAllowed,
  };
});

export default createComponentPreviewMapperModel({
  props: props as any,
});
