import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { WPhotoDefinition } from '@wix/thunderbolt-components';
import type { IWPhotoMapperProps, IWPhotoStateValues } from '../WPhoto.types';
import { TranslationKeys } from '../constants';

export const props = withCompInfo<
  IWPhotoMapperProps,
  WPhotoDefinition,
  IWPhotoMapperProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'experiments',
    'trackClicksAnalytics',
    'translate',
  ],
  ({ experiments, translate, ...compPropsRest }, carmiData) => {
    return {
      ...carmiData,
      ...compPropsRest,
      allowAnimatedTransform:
        !!experiments?.['specs.thunderbolt.allowAnimatedImageTransform'],
      magnifyKeyboardOperabilityEnabled:
        !!experiments?.['specs.thunderbolt.magnifyKeyboardOperability'],
      popupA11yExperimentEnabled:
        !!experiments?.[
          'specs.thunderbolt.A11yWPhotoPopupSemanticsAndKeyboardOperability'
        ],

      translations: {
        expandAriaLabel:
          translate(
            TranslationKeys.ARIA_LABEL_NAMESPACE,
            TranslationKeys.EXPAND_LABEL_KEY,
          ) || TranslationKeys.EXPAND_FALLBACK_LABEL,
        zoomInButtonAriaLabel:
          translate(
            TranslationKeys.ARIA_LABEL_NAMESPACE,
            TranslationKeys.ZOOM_IN_LABEL_KEY,
          ) || TranslationKeys.ZOOM_IN_FALLBACK_LABEL,
      },
    };
  },
);

const stateRefs = withStateRefsValues<keyof IWPhotoStateValues>(['reportBi']);

export default createComponentMapperModel({ props, stateRefs });
