import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  HtmlComponentProps,
  HtmlComponentDefinition,
} from '../HtmlComponent.types';
import * as translations from '../constants';

export const props = withCompInfo<
  HtmlComponentProps,
  HtmlComponentDefinition,
  HtmlComponentProps
>()(['translate'], ({ translate }, carmiData) => {
  return {
    ...carmiData,
    translations: {
      title:
        translate(
          translations.HTML_COMPONENT_NAMESPACE,
          translations.HTML_COMPONENT_TITLE_KEY,
        ) || translations.HTML_COMPONENT_TITLE_DEFAULT,
    },
  };
});

export default createComponentMapperModel({ props });
