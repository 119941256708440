import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import DoubleBorderScreen from '../../../../ScreenWidthContainer/viewer/skinComps/DoubleBorderScreen/DoubleBorderScreen';
import FooterContainer from '../../FooterContainer';

const DoubleBorderScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => (
  <FooterContainer {...props} skin={DoubleBorderScreen}></FooterContainer>
);

export default DoubleBorderScreenFooter;
