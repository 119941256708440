
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IFastGalleryProps,
  IFastGalleryMapperProps,
  IFastGalleryControllerProps,
  IFastGalleryStateValues,
} from '../../../components/FastGallery/FastGallery.props';

export default withCompController<
  IFastGalleryMapperProps,
  IFastGalleryControllerProps,
  IFastGalleryProps,
  IFastGalleryStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
