export const TestIds = {
  marqueeItemText: 'marquee-item-text',
  marqueeItemSeparator: 'marquee-item-separator',
};

export const TranslationKeys = {
  ariaLabel: 'TextMarquee_AriaLabel_PlayMarquee',
  pauseLabel: 'TextEffects_MediaButton_Pause',
  playLabel: 'TextEffects_MediaButton_Play',
  namespace: 'ariaLabels',
};
