import type { ClientSdk } from '@wix/thunderbolt-symbols';

declare global {
  interface Window {
    __clientSdk__?: ClientSdk;
  }
}

type InvokeInput = Parameters<ClientSdk['invoke']>[0];

export const clientSdkInvocationHandler = ({
  requestId,
  args,
  appDefinitionId,
  method,
  namespace,
  sendMessage,
}: {
  requestId: string;
  args: InvokeInput['args'];
  appDefinitionId?: InvokeInput['applicationId'];
  method: InvokeInput['method'];
  namespace: InvokeInput['namespace'];
  sendMessage: (message: { type: string; requestId: string } & any) => void;
}) => {
  if (window.__clientSdk__) {
    if (appDefinitionId) {
      const adjustedArgs = args.map((arg: any) =>
        arg.__clientSdkCallbackId
          ? (...callbackArgs: any) => {
              sendMessage({
                type: 'clientSdkCallbackListener',
                requestId,
                __clientSdkCallbackId: arg.__clientSdkCallbackId,
                args: callbackArgs,
              });
            }
          : arg,
      );
      window.__clientSdk__
        .invoke({
          applicationId: appDefinitionId,
          namespace,
          method,
          args: adjustedArgs,
        })
        .then(invokeResult => {
          sendMessage({
            type: 'clientSdkInvocations',
            requestId,
            result: invokeResult,
          });
        })
        .catch(error => {
          sendMessage({
            type: 'clientSdkInvocations',
            requestId,
            error,
          });
        });
    } else {
      // eslint-disable-next-line no-console
      console.error('Cannot use client sdk without app definition id');
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Client SDK is not available. call is ignored', {
      namespace,
      method,
      args,
    });
  }
};
