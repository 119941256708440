
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Dialog86051365",
      {"classes":{"root":"Dialog86051365__root","box":"Dialog86051365__box","boxContent":"Dialog86051365__boxContent","closeButton":"Dialog86051365__closeButton","title":"Dialog86051365__title"},"vars":{"wix-font-stack":"--wix-font-stack","wix-ads-height":"--wix-ads-height"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  