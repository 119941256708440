import { getDataAttributes } from '@wix/editor-elements-common-utils';
import * as React from 'react';
import { Stepper as WSRStepper } from 'wix-style-react';
import type {
  IStepperProps,
  StepperStep,
  StepperStepType,
  ManagedOption,
} from '../Stepper.props';
import { st, classes } from './style/Stepper.component.st.css';

const mapOptionToStepperType = (option: ManagedOption): StepperStepType => {
  if (option.disabled || option.value === 'disabled') {
    return 'disabled';
  }
  if (option.value === 'error') {
    return 'error';
  }
  if (option.value === 'completed') {
    return 'completed';
  }
  return undefined;
};

const convertOptionsToSteps = (
  managedOptions: Array<ManagedOption>,
): Array<StepperStep> =>
  managedOptions.map(propStep => ({
    text: propStep.label,
    type: mapOptionToStepperType(propStep),
  }));

const Stepper: React.FC<IStepperProps & { className: string }> = props => {
  const {
    id,
    activeStep,
    steps,
    className,
    stylableClassName,
    fit,
    updateComponentPropsInViewer,
  } = props;

  function onStepClick(stepNumber: number) {
    updateComponentPropsInViewer({ activeStep: stepNumber });
  }

  const stepperSteps = convertOptionsToSteps(steps);
  return (
    <div
      {...getDataAttributes(props)}
      id={id}
      style={{ height: 'auto' }}
      className={className}
    >
      <div className={st(classes.root, {}, stylableClassName)}>
        <WSRStepper
          onClick={onStepClick}
          fit={fit}
          activeStep={activeStep}
          steps={stepperSteps}
        />
      </div>
    </div>
  );
};

export default Stepper;
