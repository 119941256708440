import {
  getRefCompIdFromInflatedId,
  getUniqueId,
  REF_DELIMITER,
} from '@wix/thunderbolt-commons/dist/refUtils';

// When MSB is inside a Master, we need to infer the state ids of the slave instance
const getSlaveStateId = (stateId: string | undefined, msbId: string) => {
  if (!stateId) {
    return undefined;
  }
  // Already formatted
  if (stateId.includes(REF_DELIMITER)) {
    return stateId;
  }
  const refId = getRefCompIdFromInflatedId(msbId);
  return getUniqueId(refId, stateId);
};

export const formatStateId = (
  baseStateId: string | undefined,
  msbId: string,
) => {
  const isInMaster = msbId.includes(REF_DELIMITER);
  const stateId = isInMaster
    ? getSlaveStateId(baseStateId, msbId)
    : baseStateId;
  return stateId;
};

export const getStateToRender = ({
  defaultStateId,
  selectedStateId,
  stateIds,
  msbId,
  isEditing,
}: {
  defaultStateId: string | undefined;
  selectedStateId: string | undefined;
  stateIds: Array<string>;
  msbId: string;
  isEditing: boolean;
}) => {
  const [firstStateId] = stateIds;
  // The currently selected state in the editor
  const editorSelectedState =
    isEditing || !defaultStateId ? selectedStateId : undefined;
  const formattedStateId = formatStateId(editorSelectedState, msbId);

  if (!formattedStateId || !stateIds.includes(formattedStateId)) {
    return firstStateId;
  }

  return formattedStateId;
};
