import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import React, { useState, useEffect, useImperativeHandle } from 'react';
import type { RenderFlagsPreviewInfo } from '@wix/editor-elements-types/thunderbolt';
import {
  IMusicPlayerProps,
  IMusicPlayerImperativeActions,
} from '../MusicPlayer.types';

type IMusicPlayerPreviewProps = {
  previewWrapperProps: {
    isPlayingAllowed?: RenderFlagsPreviewInfo['isPlayingAllowed'];
  };
};

export default (ViewerComponent: React.ComponentType<IMusicPlayerProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<
      IMusicPlayerImperativeActions,
      IMusicPlayerProps & IMusicPlayerPreviewProps
    >(({ previewWrapperProps, ...viewerProps }, ref) => {
      const { isPlayingAllowed } = previewWrapperProps;

      const playerRef = React.useRef<IMusicPlayerImperativeActions | null>(
        null,
      );

      useImperativeHandle<IMusicPlayerImperativeActions | null, any>(
        ref,
        () => playerRef && playerRef.current,
      );

      const [previousIsPlayingAllowed, setPreviousIsPlayingAllowed] = useState<
        boolean | undefined
      >(isPlayingAllowed);

      useEffect(() => {
        if (previousIsPlayingAllowed && !isPlayingAllowed) {
          playerRef.current?.stop();
        }
        setPreviousIsPlayingAllowed(isPlayingAllowed);
      }, [isPlayingAllowed, previousIsPlayingAllowed, playerRef]);

      return <ViewerComponent {...viewerProps} ref={playerRef} />;
    }),
  );
