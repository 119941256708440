import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewEffect,
  usePreviewState,
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import React, { useRef } from 'react';
import {
  CheckboxPreviewWrapperProps,
  ICheckboxImperativeActions,
  ICheckboxProps,
} from '../Checkbox.types';
import styles from './style/CheckboxBasicSkin.scss';

const noop = () => {};

function withComponentPreview(
  WrappedComponent: React.ComponentType<ICheckboxProps>,
): IComponentPreviewWrapper<ICheckboxProps, CheckboxPreviewWrapperProps> {
  return React.forwardRef<ICheckboxImperativeActions, ICheckboxProps>(
    (
      {
        previewWrapperProps = {},
        ...viewerProps
      }: PreviewWrapperProps<ICheckboxProps, CheckboxPreviewWrapperProps>,
      ref,
    ) => {
      const { shouldResetComponent, compPreviewState, componentViewMode } =
        previewWrapperProps;
      const {
        id,
        checked,
        onChange = noop,
        setValidityIndication = noop,
        validateValue = noop,
      } = viewerProps;

      const previousCompViewMode = usePrevious(componentViewMode);
      const defaultChecked = useRef(checked);

      React.useEffect(() => {
        if (
          previousCompViewMode === 'editor' &&
          componentViewMode === 'editor'
        ) {
          defaultChecked.current = checked;
        }
      }, [previousCompViewMode, componentViewMode, checked]);

      usePreviewEffect({
        componentViewMode: componentViewMode || '',
        onPreviewViewMode: validateValue,
      });
      const key = useResetComponent({
        shouldResetComponent,
        id,
        onResetComponent: () => {
          onChange({
            target: { checked: defaultChecked.current },
          } as React.ChangeEvent<HTMLInputElement>);
          setValidityIndication(false);
          validateValue();
        },
      });

      usePreviewState(viewerProps.id, compPreviewState, {
        focus: {
          selectors: `.${styles.input}`,
          type: 'single',
        },
        error: {
          selectors: `.${styles.input}`,
          type: 'single',
          state: 'invalid',
        },
      });

      usePreviewState(viewerProps.id, compPreviewState);

      const shouldShowValidityIndication =
        compPreviewState === 'error' ||
        viewerProps.shouldShowValidityIndication;

      return (
        <WrappedComponent
          {...viewerProps}
          key={key}
          ref={ref}
          shouldShowValidityIndication={shouldShowValidityIndication}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<ICheckboxProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
