export const TWITTER_URL_PREFIX = 'https://twitter.com';
export const WIDGET_TYPE = 'FOLLOW';
export const COMPONENT_LABEL = 'component_label';
export const TITLE_NAME = 'component_label_ twitterFollowTitle';

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_WTwitterFollow',
  componentNameDefault: 'Twitter Follow',
  companyNameKey: 'CookieConsent_Dataset_Company_Twitter',
  companyNameDefault: 'Twitter',
};
