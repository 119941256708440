import * as React from 'react';
import { SlavePlayer, PlayPause } from '@wix/soundhead-react';
import classnames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IAudioPlayerProps } from '../AudioPlayer.types';
import { TEST_IDS } from './constants';

type IBasePlayer = IAudioPlayerProps & {
  style: Record<string, string>;
  playIcon?: JSX.Element;
  pauseIcon?: JSX.Element;
};

const BasePlayer: React.FC<IBasePlayer> = props => {
  const {
    id,
    playlist,
    volume,
    loop,
    autoPlay,
    originalFileName,
    style,
    skin,
    playIcon,
    pauseIcon,
    reducedMotion,
    className,
  } = props;

  const PLAY_ICON = playIcon || (
    <span
      data-testid={TEST_IDS.icon}
      className={classnames(style.icon, style.pausing)}
    ></span>
  );
  const PAUSE_ICON = pauseIcon || (
    <span
      data-testid={TEST_IDS.icon}
      data-playing="true"
      className={classnames(style.icon, style.playing)}
    ></span>
  );

  return (
    <div
      id={id}
      className={classnames(style[skin], className)}
      role="application"
      aria-label={`Application, Audio player, ${originalFileName}`}
      data-skin={skin}
      {...getDataAttributes(props)}
    >
      <SlavePlayer
        playerId={id}
        playlist={playlist}
        volume={volume}
        loop={loop}
        autoplay={autoPlay && !reducedMotion}
      >
        <PlayPause
          dataHook={TEST_IDS.button}
          className={style.mediaButton}
          iconPlay={PLAY_ICON}
          iconPause={PAUSE_ICON}
        />
      </SlavePlayer>
    </div>
  );
};

export default BasePlayer;
