const prefixes = {
  item: 'tinymenu-item',
  seoListItem: 'tinymenu-seolist-item',
  subItem: 'tinymenu-subitem',
  membersButton: 'tinymenu-members-button',
};

export const Keys = prefixes;

export const TestIds = {
  ...prefixes,
  menuButton: 'tinymenu-menubutton',
  subMenuHeaderSuffix: 'header',
  subMenuTogglerSuffix: 'toggler',
  selectedItemSuffix: 'selected',
  counter: 'counter',
  logInButton: 'tinymenu-loginbutton',
  welcomeItem: 'tinymenu-welcomeitem',
  membersArea: 'tinymenu-membersarea',
  lanugageSection: 'tinymenu-languagesection',
  selectedLanguage: 'tinymenu-selectedlanguage',
};

export const Translations = {
  ariaLabelNamespace: 'AriaLabels',
  ariaLabelKey: 'dropDownMenu_AriaLabel_TopLevel_SiteNavigation',
  ariaLabelDefault: 'Site',
};

export const Ids = {
  membersIconSectionId: 'tiny-menu-members-icon-section',
  membersAreaAvatar: 'tiny-menu-members-area-avatar',
};

export const ICON_SIZE = 30;
