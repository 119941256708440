import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { HoverBoxDefinition } from '@wix/thunderbolt-components';
import {
  MediaContainerProps,
  HoverBoxSelectorCSSVars,
  HoverBoxCss,
} from '@wix/thunderbolt-components-native';

export const props = withCompInfo<
  MediaContainerProps,
  HoverBoxDefinition,
  MediaContainerProps
>()([], (_, carmiData) => {
  return carmiData;
});

// 'selectorToCss' should be deleted once 'specs.thunderbolt.comp_designCss_selectorToCss_Mappers_catharsis' is merged
export const selectorToCss = withCompInfo<
  HoverBoxSelectorCSSVars,
  HoverBoxDefinition,
  HoverBoxSelectorCSSVars
>()([], (_, carmiCss) => {
  return carmiCss;
});

export const designCss = withCompInfo<
  HoverBoxCss,
  HoverBoxDefinition,
  HoverBoxCss
>()([], (_, carmiCss) => {
  return carmiCss;
});

export default createComponentMapperModel({ props, designCss });
