
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ErrorDialog3134635113",
      {"classes":{"root":"ErrorDialog3134635113__root","errorModalBox":"ErrorDialog3134635113__errorModalBox","errorModalTextTitle":"ErrorDialog3134635113__errorModalTextTitle"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  