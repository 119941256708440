import {
  REGULAR_STATE,
  HOVER_STATE,
  SELECTED_STATE,
  generateSkinDefaults,
} from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

const regular = REGULAR_STATE.id;
const hover = HOVER_STATE.id;
const selected = SELECTED_STATE.id;

// Replace with 'none' once supported by UDP
// Currently makes value in BackgroundDesign.tsx to be undefined
const backgroundDefaultValue = '#ffffff';

// Replace with 'revert' once supported by UDP
// Currently makes font in TextDesign.tsx to be undefined
const fontDefaultValue = 'font_6';

export const skinDefinition: Record<string, SkinDefinition> = {
  'wixui.skins.Menu': {
    // item background
    'item-background': {
      type: 'BACKGROUND_FILL',
      defaultValue: backgroundDefaultValue,
      state: regular,
    },
    'item-hover-background': {
      type: 'BACKGROUND_FILL',
      state: hover,
    },
    'item-selected-background': {
      type: 'BACKGROUND_FILL',
      state: selected,
    },
    // item text regular
    'item-font': {
      type: 'FONT',
      defaultValue: fontDefaultValue,
      state: regular,
    },
    'item-color': {
      type: 'CSS_COLOR',
      defaultValue: 'revert',
      state: regular,
    },
    'item-text-decoration': {
      type: 'CSSString',
      defaultValue: 'none',
      state: regular,
    },
    'item-text-transform': {
      type: 'CSSString',
      defaultValue: 'revert',
      state: regular,
    },
    'item-text-outline': {
      type: 'CSSString',
      defaultValue:
        '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
      state: regular,
    },
    'item-text-highlight': {
      type: 'CSS_COLOR',
      defaultValue: 'none',
      state: regular,
    },
    'item-letter-spacing': {
      type: 'SIZE',
      defaultValue: 'revert',
      state: regular,
    },
    'item-line-height': {
      type: 'SIZE',
      defaultValue: 'revert',
      state: regular,
    },
    'item-text-shadow': {
      type: 'TEXT_SHADOW',
      defaultValue: '0px 0px transparent',
      state: regular,
    },
    // item text hover
    'item-hover-font': {
      type: 'FONT',
      state: hover,
    },
    'item-hover-color': {
      type: 'CSS_COLOR',
      state: hover,
    },
    'item-hover-text-decoration': {
      type: 'CSSString',
      state: hover,
    },
    'item-hover-text-outline': {
      type: 'CSSString',
      state: hover,
    },
    'item-hover-text-highlight': {
      type: 'CSS_COLOR',
      state: hover,
    },
    // item text selected
    'item-selected-font': {
      type: 'FONT',
      state: selected,
    },
    'item-selected-color': {
      type: 'CSS_COLOR',
      state: selected,
    },
    'item-selected-text-decoration': {
      type: 'CSSString',
      state: selected,
    },
    'item-selected-text-outline': {
      type: 'CSSString',
      state: selected,
    },
    'item-selected-text-highlight': {
      type: 'CSS_COLOR',
      state: selected,
    },
    // item border regular
    'item-border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'item-border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'item-border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'item-border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    // item border hover
    'item-hover-border-left': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    'item-hover-border-right': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    'item-hover-border-top': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    'item-hover-border-bottom': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    // item border selected
    'item-selected-border-left': {
      type: 'BORDER_SIDE',
      state: selected,
    },
    'item-selected-border-right': {
      type: 'BORDER_SIDE',
      state: selected,
    },
    'item-selected-border-top': {
      type: 'BORDER_SIDE',
      state: selected,
    },
    'item-selected-border-bottom': {
      type: 'BORDER_SIDE',
      state: selected,
    },
    // item border radius regular
    'item-border-top-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'item-border-top-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'item-border-bottom-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'item-border-bottom-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    // item border radius hover
    'item-hover-border-top-left-radius': {
      type: 'BORDER',
      state: hover,
    },
    'item-hover-border-top-right-radius': {
      type: 'BORDER',
      state: hover,
    },
    'item-hover-border-bottom-left-radius': {
      type: 'BORDER',
      state: hover,
    },
    'item-hover-border-bottom-right-radius': {
      type: 'BORDER',
      state: hover,
    },
    // item border radius selected
    'item-selected-border-top-left-radius': {
      type: 'BORDER',
      state: selected,
    },
    'item-selected-border-top-right-radius': {
      type: 'BORDER',
      state: selected,
    },
    'item-selected-border-bottom-left-radius': {
      type: 'BORDER',
      state: selected,
    },
    'item-selected-border-bottom-right-radius': {
      type: 'BORDER',
      state: selected,
    },
    // item box shadow
    'item-box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: 'none',
      state: regular,
    },
    'item-hover-box-shadow': {
      type: 'BOX_SHADOW',
      state: hover,
    },
    'item-selected-box-shadow': {
      type: 'BOX_SHADOW',
      state: selected,
    },
    // item icon regular
    'item-icon-enabled': {
      type: 'CSSString',
      defaultValue: 'initial',
      state: regular,
    },
    'item-icon-size': {
      type: 'SIZE',
      defaultValue: '10px',
      state: regular,
    },
    'item-icon-color': {
      type: 'CSS_COLOR',
      defaultValue: 'revert',
      state: regular,
    },
    'item-icon-rotation': {
      type: 'CSSString',
      defaultValue: '0',
      state: regular,
    },
    // item icon hover
    'item-hover-icon-enabled': {
      type: 'CSSString',
      state: hover,
    },
    'item-hover-icon-size': {
      type: 'SIZE',
      state: hover,
    },
    'item-hover-icon-color': {
      type: 'CSS_COLOR',
      state: hover,
    },
    'item-hover-icon-rotation': {
      type: 'CSSString',
      state: hover,
    },
    // container
    'container-background': {
      type: 'BACKGROUND_FILL',
      defaultValue: backgroundDefaultValue,
      state: regular,
    },
    'container-box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: 'none',
      state: regular,
    },
    // container border
    'container-border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'container-border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'container-border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'container-border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    // container border radius
    'container-border-top-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'container-border-top-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'container-border-bottom-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'container-border-bottom-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    // scroll button background regular
    'scroll-button-background': {
      type: 'BACKGROUND_FILL',
      defaultValue: backgroundDefaultValue,
      state: regular,
    },
    'scroll-button-hover-background': {
      type: 'BACKGROUND_FILL',
      state: hover,
    },
    // scroll button border regular
    'scroll-button-border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'scroll-button-border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'scroll-button-border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    'scroll-button-border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: regular,
    },
    // scroll button border hover
    'scroll-button-hover-border-left': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    'scroll-button-hover-border-right': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    'scroll-button-hover-border-top': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    'scroll-button-hover-border-bottom': {
      type: 'BORDER_SIDE',
      state: hover,
    },
    // scroll button border radius regular
    'scroll-button-border-top-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'scroll-button-border-top-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'scroll-button-border-bottom-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    'scroll-button-border-bottom-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: regular,
    },
    // scroll-button border radius hover
    'scroll-button-hover-border-top-left-radius': {
      type: 'BORDER',
      state: hover,
    },
    'scroll-button-hover-border-top-right-radius': {
      type: 'BORDER',
      state: hover,
    },
    'scroll-button-hover-border-bottom-left-radius': {
      type: 'BORDER',
      state: hover,
    },
    'scroll-button-hover-border-bottom-right-radius': {
      type: 'BORDER',
      state: hover,
    },
    // scroll button box shadow
    'scroll-button-box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: 'none',
      state: regular,
    },
    'scroll-button-hover-box-shadow': {
      type: 'BOX_SHADOW',
      state: hover,
    },
    // scroll button icon regular
    'scroll-button-display': {
      type: 'CSSString',
      defaultValue: 'initial',
      state: regular,
    },
    'scroll-button-icon-size': {
      type: 'SIZE',
      defaultValue: '10px',
      state: regular,
    },
    'scroll-button-icon-color': {
      type: 'CSS_COLOR',
      defaultValue: 'revert',
      state: regular,
    },
    'scroll-button-icon-rotation': {
      type: 'CSSString',
      defaultValue: '0',
      state: regular,
    },
    // scroll button icon hover
    'scroll-button-hover-display': {
      type: 'CSSString',
      state: hover,
    },
    'scroll-button-hover-icon-size': {
      type: 'SIZE',
      state: hover,
    },
    'scroll-button-hover-icon-color': {
      type: 'CSS_COLOR',
      state: hover,
    },
    'scroll-button-hover-icon-rotation': {
      type: 'CSSString',
      state: hover,
    },

    'spacing-between-label-and-dropdown-icon': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    'item-vertical-padding': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    'item-horizontal-padding': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    'menu-items-main-axis-gap': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
    'menu-items-cross-axis-gap': {
      type: 'SIZE',
      defaultValue: '0px',
      state: regular,
    },
  },
};

export default generateSkinDefaults(skinDefinition);
