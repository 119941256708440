import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  convertFlexDirectionToCssFlexDirection,
  convertPhysicalAlignmentToLogical,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import type {
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuPropsCarmiData,
  VerticalAnchorsMenuItemCarmiData,
  VerticalAnchorsMenuStyleProperties,
  VerticalAnchorsMenuCSSVars,
  VerticalAnchorsMenuItem,
  IVerticalAnchorsMenuStateRefValues,
  IVerticalAnchorsMenuMapperProps,
} from '../VerticalAnchorsMenu.types';
import { PAGE_ANCHORS } from '../constants';
import * as translationKeys from './constants';
import { getFallbackBulletPosition, getPaddingDirection } from './utils';

export const props = withCompInfo<
  IVerticalAnchorsMenuMapperProps,
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuPropsCarmiData
>()(
  [
    'compData',
    'compProps',
    'compId',
    'fullNameCompType',
    'language',
    'translate',
    'trackClicksAnalytics',
  ],
  ({ compData, compProps, translate, ...restCompProps }, carmiProps) => {
    const { pageTopLabel, hiddenAnchorIds } = compData;
    const { autoColor, itemsAlignment, orientation } = compProps;
    const { requestedPageId, pageAnchors, shouldSort } = carmiProps;
    const pageHiddenAnchorIds: Array<string> =
      hiddenAnchorIds && hiddenAnchorIds.hasOwnProperty(requestedPageId)
        ? (hiddenAnchorIds as Record<string, Array<string>>)[requestedPageId]
        : [];
    const topAnchor: VerticalAnchorsMenuItemCarmiData = {
      compId: PAGE_ANCHORS.TOP_ANCHOR_COMP_ID,
      dataId: PAGE_ANCHORS.TOP_ANCHOR_ID,
      name: pageTopLabel || '',
    };
    const reducedAnchors = () => {
      const res = Object.values(pageAnchors);
      if (shouldSort) {
        res.sort(
          (
            a: VerticalAnchorsMenuItemCarmiData,
            b: VerticalAnchorsMenuItemCarmiData,
          ) => a.top! - b.top!,
        );
      }
      return res.map(
        (item: VerticalAnchorsMenuItemCarmiData): VerticalAnchorsMenuItem => {
          const { dataId, compId, name } = item;
          return { dataId, compId, name };
        },
      );
    };
    const anchors = [topAnchor]
      .concat(reducedAnchors())
      .filter(
        (anchorItem: VerticalAnchorsMenuItem) =>
          !pageHiddenAnchorIds.includes(anchorItem.compId),
      );
    return {
      ...restCompProps,
      pageTopLabel,
      anchors: anchors.length ? anchors : [topAnchor],
      autoColor,
      itemsAlignment,
      orientation,
      activeAnchor: {
        compId: PAGE_ANCHORS.TOP_ANCHOR_COMP_ID,
        dataId: PAGE_ANCHORS.TOP_ANCHOR_ID,
      },
      translations: {
        ariaLabel:
          translate(
            translationKeys.ARIA_LABEL_NAMESPACE,
            translationKeys.ARIA_LABEL_KEY,
          ) || translationKeys.ARIA_LABEL_DEFAULT,
      },
    };
  },
);

const css = withCompInfo<
  VerticalAnchorsMenuCSSVars,
  VerticalAnchorsMenuDefinition,
  never,
  { styleProperties: VerticalAnchorsMenuStyleProperties }
>()(
  ['compProps', 'compData', 'styleProperties'],
  ({ styleProperties, compData }) => {
    const { direction = 'inherit', bulletPosition } = compData;
    const { align, textSpacing = '14px' } = styleProperties;

    return {
      '--direction': direction,
      '--text-alignment': align,
      '--orientation': convertFlexDirectionToCssFlexDirection(bulletPosition!),
      ...getPaddingDirection(align, bulletPosition!, textSpacing),
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'itemsAlignment', target: 'align' }],
        enhancer: convertPhysicalAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'orientation', target: 'bulletPosition' }],
        enhancer: getFallbackBulletPosition,
      },
    ]),
  ],
);

export const stateRefs = withStateRefsValues<
  keyof IVerticalAnchorsMenuStateRefValues
>(['currentUrl', 'reportBi']);

export default createComponentMapperModel({ css, props, stateRefs });
