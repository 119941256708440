import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  ProgressBarDefinition,
  ProgressBarProps,
  ProgressBarCSSVars,
} from '../ProgressBar.types';
import { getInnerBorderRadius } from './helpers';

export const props = withCompInfo<ProgressBarProps, ProgressBarDefinition>()(
  ['compData'],
  ({ compData }) => {
    return { value: compData.value, targetValue: compData.targetValue };
  },
);

export const css = withCompInfo<ProgressBarCSSVars, ProgressBarDefinition>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }): ProgressBarCSSVars => {
    const { borderRadius = '0px', borderSize = '0px' } = styleProperties;
    return {
      '--direction': compProps.direction,
      '--borderRadius': borderRadius,
      '--borderSize': borderSize,
      '--innerBorderRadius': getInnerBorderRadius(borderRadius, borderSize),
    };
  },
);

export default createComponentMapperModel({ css, props });
