import { ViewerType as MenuViewerType } from '../../../components/Menu/constants';
import { ViewerType as SHMenuViewerType } from '../../../components/StylableHorizontalMenu/constants';

export const MenuTypes = [MenuViewerType, SHMenuViewerType];

export enum ModalResolveType {
  MAIN_ACTION = 'mainActionClicked',
  SECONDARY_ACTION = 'secActionClicked',
  CLOSE_ACTION = 'closeActionClicked',
}

export const DESTRUCTIVE_CONFIRMATION_PANEL_NAME =
  'platformPanels.platformErrorPanel';

export const Selectors = {
  containerPositionBox: '.root::containerPositionBox',
};

export const PREVIEW_STATE_DELIMETER = '::';
export const PREVIEW_STATE = {
  FREEZE_MEGA_MENU: 'freezeMegaMenu',
  UNFREEZE_MEGA_MENU: 'unfreezeMegaMenu',
};

export const TranslationKeys = {
  megaMenuEditMode: {
    label: 'mega_menu_edit_mode_label',
    exitLabel: 'mega_menu_edit_mode_exit_label',
    tooltip: 'mega_menu_topbar_mode_exit_notification',
  },
  megaMenuEditModeInX: {
    label: 'editor-package-megaMenu.Edit-Mode-Label',
    exitLabel: 'editor-package-megaMenu.Edit-Mode-Close-Button-Label',
  },
};

export const HelpIds = {
  MEGA_MENU_EDIT_MODE: '186290ac-21d4-48a2-a7eb-c9310ae70f5f',
  MEGA_MENU_EDIT_MODE_EDITORX:
    'component-editors.wixui.MegaMenuContainerItem_help_editorx',
  MEGA_MENU_EDIT_MODE_STUDIO:
    'component-editors.wixui.MegaMenuContainerItem_help_studio',
  MEGA_MENU_LAYOUT_PANEL: 'b04e6b30-7bd5-4b60-a35e-b92c828c606c',
  MEGA_MENU_LAYOUT_PANEL_EDITORX: '69bea570-26a0-44cb-978f-ed703ef19053',
  MEGA_MENU_LAYOUT_PANEL_STUDIO: '5e270374-9f47-44cd-b7bf-06bacd9a8329',
};
