import React, { useContext } from 'react';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';

import classnames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import styles from '../button.scss';
import { TestIds } from '../../../../constants';
import StylesContext from '../../../StylesContext';
import semanticClassNames from '../../../../RichTextBox.semanticClassNames';
import {
  createStateWithNextTextSize,
  getActiveTextSize,
  TextSizeBlockType,
} from './utils/utils';

import DefaultTextSizeIcon from './icons/TextSizeDefault';
import TextSize1Icon from './icons/TextSize1';
import TextSize2Icon from './icons/TextSize2';

export const textSizeButtons: Record<TextSizeBlockType, JSX.Element> = {
  unstyled: <DefaultTextSizeIcon />,
  'header-two': <TextSize1Icon />,
  'header-three': <TextSize2Icon />,
};

export default (props: ToolbarChildrenProps) => {
  const stylesContext = useContext(StylesContext);
  const { setEditorState, getEditorState } = props;

  const changeTextSize = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const newState = createStateWithNextTextSize(getEditorState());
    setEditorState(newState);
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className={styles.buttonContainer}>
      <button
        className={classnames(styles.button, {
          [styles.hovered]: stylesContext.hovered,
          [styles.focused]: stylesContext.focused,
          [styles.error]: stylesContext.error,
        })}
        onMouseDown={preventBubblingUp}
        onClick={changeTextSize}
        data-testid={TestIds.textSizeButton}
        type="button"
      >
        <div
          className={classnames(
            styles.buttonContent,
            formatClassNames(semanticClassNames.icons),
          )}
        >
          {textSizeButtons[getActiveTextSize(getEditorState())]}
        </div>
      </button>
    </div>
  );
};
