import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  keyCodes,
  debounce,
  formatClassNames,
} from '@wix/editor-elements-common-utils';
import { useResizeObserver } from '@wix/thunderbolt-elements/providers/useResizeObserver';
import { useDidUpdate } from '@wix/thunderbolt-elements/providers/useDidUpdate';
import { TestIds } from '../../constants';
import type { AllTabsRef, ITabsListProps } from '../../Tabs.types';
import semanticClassNames from '../../Tabs.semanticClassNames';
import TabsListItem from './TabsListItem';
import { LeftScrollButton, RightScrollButton } from './ScrollButton';
import { classes, st } from './style/TabsList.st.css';
import { animateElementByProp } from './animation';

enum ScrollDirection {
  FORWARD = 1,
  BACKWARD = -1,
}

const clamp = (value: number = 0, min: number = 0, max: number = 0) =>
  Math.max(Math.min(value, max), min);

const TabsList: React.FC<ITabsListProps> = ({
  currentTabId,
  tabItems,
  onTabItemClick,
  className,
  activeMenuItemRef,
  a11y,
}) => {
  const [activeTabId, setActiveTabId] = useState(currentTabId);
  const [shouldShowBackButton, setShouldShowBackButton] = useState(false);
  const [shouldShowForwardButton, setShouldShowForwardButton] = useState(false);

  // Handle external change of current tab
  useDidUpdate(() => {
    if (currentTabId !== activeTabId) {
      setActiveTabId(activeTabId);
    }
  }, [currentTabId]);

  const navRef = useRef<HTMLDivElement>(null);
  const backwardScrollButtonRef = useRef<HTMLDivElement>(null);
  const allTabsRef: AllTabsRef = {};

  const addTabRef = (
    ref: React.RefObject<HTMLDivElement>,
    idx: number,
    isActive: boolean,
  ) => {
    if (!ref || !activeMenuItemRef) {
      return;
    }
    allTabsRef[idx] = isActive ? activeMenuItemRef : ref;
  };

  const isTabActive = (tabId: string) => activeTabId === tabId;
  const isHorizontal = () => {
    if (!navRef.current) {
      return true;
    }
    const navStyles = getComputedStyle(navRef.current);
    return navStyles.getPropertyValue('--orientation') === 'horizontal';
  };

  const checkActiveTabVisible = () => {
    if (!activeMenuItemRef?.current || !navRef.current) {
      return false;
    }

    const activeTab = activeMenuItemRef.current;
    const tabList = navRef.current;
    const activeTabStartPosition = isHorizontal()
      ? activeTab.offsetLeft
      : activeTab.offsetTop;
    const activeTabEndPosition = isHorizontal()
      ? activeTab.offsetLeft + activeTab.offsetWidth
      : activeTab.offsetTop + activeTab.offsetHeight;
    const navScrollStartPosition = isHorizontal()
      ? tabList.scrollLeft
      : tabList.scrollTop;
    const navScrollEndPosition = isHorizontal()
      ? tabList.scrollLeft + tabList.offsetWidth
      : tabList.scrollTop + tabList.offsetHeight;

    return (
      navScrollEndPosition >= activeTabEndPosition &&
      navScrollStartPosition <= activeTabStartPosition
    );
  };

  const isRtl = () => {
    if (!navRef.current) {
      return false;
    }
    const navStyles = getComputedStyle(navRef.current);
    return navStyles.direction === 'rtl';
  };
  const isScrollMode = () => {
    if (!backwardScrollButtonRef.current) {
      return false;
    }
    const scrollButtonStyles = getComputedStyle(
      backwardScrollButtonRef.current,
    );
    return scrollButtonStyles.display !== 'none';
  };

  useEffect(() => {
    if (isScrollMode()) {
      const isActiveTabVisible = checkActiveTabVisible();
      if (isActiveTabVisible) {
        handleScrollPosition(0);
      } else {
        navRef.current?.scrollTo?.({
          left: activeMenuItemRef?.current?.offsetLeft,
          top: activeMenuItemRef?.current?.offsetTop,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabItems]); // to react every change in tabItems(when tabs are added/removed)

  // Scroll to active tab after selection
  useDidUpdate(() => {
    const isActiveTabVisible = checkActiveTabVisible();
    if (!isActiveTabVisible) {
      navRef.current?.scrollTo?.({
        left: activeMenuItemRef?.current?.offsetLeft,
        top: activeMenuItemRef?.current?.offsetTop,
        behavior: 'smooth',
      });
    }
  }, [currentTabId]);

  // Returns boundaries of TabList scroll
  const getMaxMinScroll = () => {
    if (!navRef.current) {
      return { max: 0, min: 0 };
    }

    const { scrollWidth, offsetWidth, scrollHeight, offsetHeight } =
      navRef.current;

    return {
      max: Math.abs(
        isHorizontal()
          ? scrollWidth - offsetWidth
          : scrollHeight - offsetHeight,
      ),
      min: 0,
    };
  };

  const getScrollBuffer = () => {
    if (!navRef.current) {
      return 0;
    }

    const { offsetWidth, offsetHeight } = navRef.current;
    return (isHorizontal() ? offsetWidth : offsetHeight) / 50;
  };

  // Sets the visibility of forward and backwards button to match whether there's more content
  const handleScrollPosition = (scrollPosition: number) => {
    const { min, max } = getMaxMinScroll();
    const buffer = getScrollBuffer();
    const canScrollBackwards = scrollPosition > min + buffer;
    const canScrollForwards = scrollPosition + buffer < max;
    setShouldShowBackButton(canScrollBackwards);
    setShouldShowForwardButton(canScrollForwards);
  };

  /**
   * @param scrollDirection {Number} 1 if moving forward or -1 if moving backwards
   * When pressing back or forward button, scrolls TabList toward the received direction (with animation)
   */
  const handleScrollButton = (scrollDirection: number) => {
    if (!navRef.current) {
      return;
    }
    const rtl = isRtl();
    const horizontal = isHorizontal();
    const { min, max } = getMaxMinScroll();
    const { scrollLeft, clientWidth, scrollTop, clientHeight } = navRef.current;
    const rtlDirectionFix = horizontal && rtl ? -1 : 1; // in Horizontal RTL , the scroll position is represented as a negative value
    const scrollPosition = horizontal
      ? Math.abs(scrollLeft) + scrollDirection * (clientWidth / 2)
      : Math.abs(scrollTop) + scrollDirection * (clientHeight / 2);
    const animationProps = {
      propToAnimate: horizontal ? 'scrollLeft' : 'scrollTop',
      element: navRef.current,
      moveTo: rtlDirectionFix * clamp(scrollPosition, min, max),
      duration: 400,
    };

    animateElementByProp(animationProps);
  };

  const handleScrollForward = () => {
    handleScrollButton(ScrollDirection.FORWARD);
  };
  const handleScrollBackward = () => {
    handleScrollButton(ScrollDirection.BACKWARD);
  };
  const onScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollLeft, scrollTop } = event.currentTarget;
    debouncedHandelScrollPosition(
      Math.abs(isHorizontal() ? scrollLeft : scrollTop),
    );
  };

  // Debounce scroll handling to reduce re-renders
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandelScrollPosition = useCallback(
    debounce(handleScrollPosition, 100),
    [],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateScrollState = useCallback(
    debounce(() => handleScrollPosition(navRef.current?.scrollLeft ?? 0), 100),
    [],
  );

  useResizeObserver({
    ref: navRef,
    callback: debouncedUpdateScrollState,
  });

  const handleKeyboardNavScroll = (
    direction: 1 | -1,
    tabIndexToFocus: number,
  ) => {
    if (navRef.current) {
      const {
        scrollWidth,
        clientWidth: tabsContainerWidth,
        scrollLeft,
      } = navRef.current;
      const tabsArray = Object.keys(allTabsRef).map((_, i) => allTabsRef[i]);
      if (direction === 1) {
        const leftTabs = tabsArray.slice(0, tabIndexToFocus + 1);
        const leftTabsOverallWidth = leftTabs.reduce(
          (sum, currTab) => sum + (currTab.current?.offsetWidth || 0),
          0,
        );
        const focusReachedRightContainerEdge =
          (leftTabsOverallWidth - scrollLeft) / tabsContainerWidth > 1;
        if (focusReachedRightContainerEdge) {
          handleScrollButton(direction);
        }
      } else {
        const rightTabs = tabsArray.slice(tabIndexToFocus, tabsArray.length);
        const rightTabsOverallWidth = rightTabs.reduce(
          (sum, currTab) => sum + (currTab.current?.offsetWidth || 0),
          0,
        );
        const scrollRight = scrollWidth - tabsContainerWidth - scrollLeft;
        const focusReachedLeftContainerEdge =
          (rightTabsOverallWidth - scrollRight) / tabsContainerWidth > 1;
        if (focusReachedLeftContainerEdge) {
          handleScrollButton(direction);
        }
      }
    }
  };

  const handleKeyboardNav = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const rtl = isRtl();
    const horizontal = isHorizontal();
    const target = e.target as HTMLDivElement;
    const tabItemIdx = Number(target.getAttribute('data-idx'));

    const moveFocus = (direction: 1 | -1) => {
      const tabIndexToFocus = tabItemIdx + direction;
      if (allTabsRef[tabIndexToFocus]) {
        allTabsRef[tabIndexToFocus].current?.focus();
        handleKeyboardNavScroll(direction, tabIndexToFocus);
      }
    };

    switch (e.keyCode) {
      case keyCodes.arrowRight:
      case keyCodes.arrowDown:
        e.preventDefault();
        return rtl && horizontal ? moveFocus(-1) : moveFocus(1);
      case keyCodes.arrowLeft:
      case keyCodes.arrowUp:
        e.preventDefault();
        return rtl && horizontal ? moveFocus(1) : moveFocus(-1);
      case keyCodes.enter:
      case keyCodes.space:
        e.preventDefault();
        target.click();
        break;
      default:
        break;
    }
  };

  return (
    <div
      className={st(
        classes.root,
        className,
        formatClassNames(semanticClassNames.menuContainer),
      )}
    >
      <LeftScrollButton
        isVisible={shouldShowBackButton}
        onClick={handleScrollBackward}
        dataHook={TestIds.BackwardScrollBtn}
        className={classes.scrollButton}
        ref={backwardScrollButtonRef}
      />
      <div
        className={classes.tabsList}
        ref={navRef}
        data-hook={TestIds.TabsList}
        onScroll={onScroll}
        role="tablist"
        onKeyDown={handleKeyboardNav}
        {...a11y}
      >
        {tabItems?.map((item, index) => {
          const isActive = isTabActive(item.tabId);
          const handleClick = () => {
            onTabItemClick(item.tabId, item.id);
            setActiveTabId(item.tabId);
          };
          return (
            <TabsListItem
              {...item}
              isActive={isActive}
              key={`${item.label}-${index}`}
              onClick={handleClick}
              ref={isActive ? activeMenuItemRef : undefined}
              idx={index}
              addRef={addTabRef}
            />
          );
        })}
      </div>
      <div className={classes.border} />
      <RightScrollButton
        isVisible={shouldShowForwardButton}
        onClick={handleScrollForward}
        dataHook={TestIds.ForwardScrollBtn}
        className={classes.scrollButton}
      />
    </div>
  );
};

export default TabsList;
