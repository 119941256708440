import * as React from 'react';
import { QuickActionBarSkinProps } from '../../../QuickActionBar.types';
import { ACTION_CONTAINER } from '../../constants';

const FloatingSkin: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  QuickActionBarSkinProps
> = (props, ref) => {
  const {
    rootProps,
    actionsContainerProps,
    hasNotifications,
    controlButtonProps,
    classes = {},
    id,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const rootStyles = {
    display: rootProps.display,
    position: rootProps.position,
    ...props.style,
  };
  return (
    <div
      className={classes.root}
      style={rootStyles}
      id={id}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classes.overlay}
        onClick={props.overlayProps.onClick}
      ></div>
      <button
        className={classes.controlButton}
        aria-label={controlButtonProps.ariaLabel}
        aria-expanded={controlButtonProps.ariaExpanded}
        onClick={controlButtonProps.onClick}
        ref={ref}
      >
        {hasNotifications && (
          <div
            className={classes.notifications}
            data-quick-action="notifications"
          />
        )}
        <ul className={classes.dots}>
          <li className={classes.firstDot}></li>
          <li className={classes.secondDot}></li>
          <li className={classes.thirdDot}></li>
        </ul>
      </button>
      <div
        className={classes.actionsContainer}
        {...actionsContainerProps}
        style={{
          display: actionsContainerProps.display,
        }}
        id={`${id}${ACTION_CONTAINER}`}
      >
        {props.children}
      </div>
    </div>
  );
};

export default React.forwardRef(FloatingSkin);
