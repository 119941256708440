import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';

// TODO add types
export const props = withCompInfo<any, any>()(
  ['compData', 'compProps'],
  ({ compData, compProps }) => {
    return {
      ...compData,
      ...compProps,
    };
  },
);

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({
  props,
  stateRefs,
});
