import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { ILineShareButtonProps } from '../LineShareButton.types';
import {
  getSDK,
  lineShareSDK,
  ILineShareSDK,
} from '../../../providers/ScriptLoader';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';

const LineShareButton: React.FC<ILineShareButtonProps> = props => {
  const {
    id,
    className,
    language,
    buttonType,
    currentUrl,
    isConsentPolicyActive,
  } = props;

  React.useEffect(() => {
    const waitForSDK = getSDK<ILineShareSDK>(lineShareSDK);
    waitForSDK
      .then(sdk => {
        sdk.loadButton();
      })
      .catch(e => {
        // TODO - handle errors properly
        throw e;
      });
  }, [language, buttonType]);

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className,
    ...getDataAttributes(props),
  });

  return (
    <div {...baseComponentProps} key={`${buttonType}-${language}`}>
      <div
        className="line-it-button"
        data-lang={language}
        data-type={buttonType}
        data-ver="2"
        data-url={currentUrl}
        style={{ display: 'none' }}
      ></div>
    </div>
  );
};

export default LineShareButton;
