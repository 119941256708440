import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { VideoBoxAudioProps } from '../../VideoBox/VideoBox.types';
import * as TranslationKeys from './constants';

export const props = withCompInfo<
  VideoBoxAudioProps,
  any,
  VideoBoxAudioProps
>()(['translate'], ({ translate }, carmiData) => {
  return {
    ...carmiData,
    translations: {
      ariaLabel:
        translate(
          TranslationKeys.ARIA_LABEL_NAMESPACE,
          TranslationKeys.VIDEOBOX_MUTE_ARIA_LABEL_KEY,
        ) || TranslationKeys.VIDEOBOX_MUTE_ARIA_LABEL_DEFAULT,
    },
  };
});

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
