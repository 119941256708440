
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "PopoverMenu1941626580",
      {"classes":{"root":"PopoverMenu1941626580__root","label":"StylableButton2545352419__label","icon":"StylableButton2545352419__icon","text":"ListItemAction2066756189__text","button":"PopoverMenu1941626580__button"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  