import type { IMenuItemProps } from '../../Menu.types';

type Item = IMenuItemProps['item'];
type ForceOpenIfNeededProps = {
  items: Array<Item>;
  editSlotId?: string;
};

export const forceOpenIfNeeded = ({
  items,
  editSlotId,
}: ForceOpenIfNeededProps): Array<Item> => {
  if (editSlotId) {
    return items.map(item =>
      item.slot === editSlotId ? { ...item, forceHovered: true } : item,
    );
  }

  return items;
};
