import { withCompController } from '@wix/editor-elements-integrations';
import { getSocialUrl } from '@wix/editor-elements-common-utils';
import {
  FacebookShareMapperProps,
  FacebookShareControllerProps,
  FacebookShareProps,
  FacebookShareStateRefs,
} from '../FacebookShare.types';

const compController = withCompController<
  FacebookShareMapperProps,
  FacebookShareControllerProps,
  FacebookShareProps,
  FacebookShareStateRefs
>(({ stateValues, mapperProps }) => {
  const {
    urlFormat,
    isMasterPage,
    externalBaseUrl,
    isHttpsEnabled,
    ...restProps
  } = mapperProps;

  const { pageId, relativeUrl, currentUrlWithQueryParams } = stateValues;

  const socialUrl = getSocialUrl({
    urlFormat,
    isMasterPage,
    pageId,
    relativeUrl,
    externalBaseUrl,
    isHttpsEnabled,
    currentUrl: isMasterPage ? externalBaseUrl : currentUrlWithQueryParams,
  });

  return {
    ...restProps,
    socialUrl,
  };
});

export default compController;
