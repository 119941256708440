import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import { VideoBoxPlayProps } from '@wix/thunderbolt-components';

const props = withCompPreviewProps<VideoBoxPlayProps, any, VideoBoxPlayProps>()(
  ['componentViewMode'],
  ({ componentViewMode }, carmiData: VideoBoxPlayProps) => {
    return {
      ...carmiData,
      shouldRender:
        componentViewMode === 'editor' ? true : carmiData.shouldRender,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
