import * as React from 'react';
import {
  QuickActionBarProps,
  QuickActionBarSkinProps,
} from '../../../QuickActionBar.types';
import QuickActionBar from '../../QuickActionBar';
import BasicSkin from './FloatingSkinContent';
import styles from './styles/FloatingSkin.scss';

export const FloatingSkinComp: React.ForwardRefRenderFunction<
  HTMLButtonElement,
  QuickActionBarSkinProps
> = (props, ref) => <BasicSkin {...props} ref={ref} />;

const FloatingSkin: React.FC<QuickActionBarProps> = props => {
  return (
    <QuickActionBar
      {...props}
      isFloating={true}
      skin={React.forwardRef(FloatingSkinComp)}
      skinStyles={styles}
    />
  );
};

export default FloatingSkin;
