import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  RSSButtonProps,
  RSSButtonPropsCarmiData,
} from '@wix/thunderbolt-components';
import { RSSButtonDefinition } from '@wix/thunderbolt-components-native';
import { IRSSButtonStateValues } from '../RSSButton.types';

export const props = withCompInfo<
  RSSButtonProps,
  RSSButtonDefinition,
  RSSButtonPropsCarmiData
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
  ],
  (compProps, carmiData) => {
    const link = carmiData.link;
    if (carmiData.image && link) {
      link.href = `./feed.xml`;
    }
    return {
      ...carmiData,
      ...compProps,
      link,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IRSSButtonStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({
  props,
  stateRefs,
});
