import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  IAudioPlayerControllerProps,
  IAudioPlayerMapperProps,
  IAudioPlayerStateRefValues,
  IAudioPlayerProps,
} from '../AudioPlayer.types';

export default withCompController<
  IAudioPlayerMapperProps,
  IAudioPlayerControllerProps,
  IAudioPlayerProps,
  IAudioPlayerStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion } = stateValues;
  return { ...mapperProps, reducedMotion };
});
