
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ProgressBar3682656523",
      {"classes":{"root":"ProgressBar3682656523__root","barContainer":"ProgressBar3682656523__barContainer","barBackground":"ProgressBar3682656523__barBackground","barForeground":"ProgressBar3682656523__barForeground"},"vars":{"display":"--display"},"stVars":{"barBackgroundColor":"rgba(var(--barBackgroundColor, var(--color_8)), var(--alpha-barBackgroundColor, 1))","barForegroundColor":"rgba(var(--barForegroundColor, var(--color_6)), var(--alpha-barForegroundColor, 1))","borderColor":"rgba(var(--borderColor, var(--color_5)), var(--alpha-borderColor, 1))","borderRadius":"var(--borderRadius, 0px)","borderSize":"var(--borderSize, 0px)","boxShadow":"var(--boxShadow, 0 1px 4px rgba(0, 0, 0, 0.6))"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  