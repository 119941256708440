import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import MenuButton from '../../../../MenuButton/viewer/skinComps/OverlineMenuButtonNSkin/OverlineMenuButtonHorizontalMenuAdaptationSkin';
import OverlineMenuButton from '../../OverlineMenuButton';
import styles from './OverlineMenuButtonHorizontalMenuAdaptationSkin.scss';

const OverlineMenuButtonHorizontalMenuAdaptationSkin: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuProps
> = (props, ref) => {
  return (
    <OverlineMenuButton
      {...props}
      ref={ref}
      styles={styles}
      Button={MenuButton}
    />
  );
};

export default React.forwardRef(OverlineMenuButtonHorizontalMenuAdaptationSkin);
