export const TestIds = {
  backToSiteButton: 'backToSiteBtn',
  switchAccountLinkBtn: 'switchAccountLinkBtn',
} as const;

export const NoPermissionsToPageTranslations = {
  TITLE: 'noPermissionsToPageTitle',
  BACK_TO_SITE_BTN: 'welcomeBackToSite',
  SWITCH_ACCOUNT_LINK: 'noPermissionsToPageSwitchAccount',
};
