import * as React from 'react';
import { CoBrandingBannerProps } from '../../../CoBrandingBannerDesktop.types';
import responsiveStyle from '../../styles/ResponsiveCoBrandingBannerDesktop.scss';
import CoBrandingBannerDesktop from '../../CoBrandingBannerDesktop';

const ResponsiveCoBrandingBannerDesktop: React.FC<
  CoBrandingBannerProps
> = props => {
  return <CoBrandingBannerDesktop {...props} style={responsiveStyle} />;
};

export default ResponsiveCoBrandingBannerDesktop;
