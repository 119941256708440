import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { legacyTransitionTypesMap } from '../../commons/constants';
import type {
  SlideShowGalleryCarmiData,
  SlideShowGalleryDefinition,
  ISlideShowGalleryMapperProps,
  SlideShowGalleryCSS,
  SlideShowGallerySdkData,
} from '../SlideShowGallery.types';
import { translations } from '../constants';

export const props = withCompInfo<
  ISlideShowGalleryMapperProps,
  SlideShowGalleryDefinition,
  SlideShowGalleryCarmiData
>()(
  ['skin', 'compProps', 'translate'],
  ({ skin, compProps, translate }, carmiData) => {
    const transition =
      legacyTransitionTypesMap[compProps.transition] || 'NoTransition';
    const hasTransition = transition !== 'NoTransition';
    const transitionDuration = compProps.transDuration * 1000;
    const autoPlayInterval =
      compProps.autoplayInterval * 1000 +
      (hasTransition ? transitionDuration : 0);

    return {
      skin,
      items: carmiData.itemsProps,
      currentIndex: 0,
      transition,
      transitionDuration,
      transitionReverse: compProps.reverse,
      shouldPreserveAspectRatio: compProps.imageMode === 'flexibleHeight',
      autoPlay: compProps.autoplay,
      autoPlayInterval,
      showNavigation: compProps.showNavigation,
      showAutoplay: compProps.showAutoplay,
      showCounter: compProps.showCounter,
      reducedMotion: false,
      imageOnClickAction: compProps.galleryImageOnClickAction,
      alignTextRight: compProps.alignText === 'right',
      imageMode: compProps.imageMode,
      translations: {
        galleryAriaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.SLIDE_SHOW_GALLERY_ARIA_LABEL_KEY,
          ) || translations.SLIDE_SHOW_GALLERY_ARIA_LABEL_DEFAULT,
        platButtonAriaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.PLAY_BUTTON_ARIA_LABEL_KEY,
          ) || translations.PLAY_BUTTON_ARIA_LABEL_DEFAULT,
      },
    };
  },
);

export const css = withCompInfo<
  SlideShowGalleryCSS,
  SlideShowGalleryDefinition
>()(
  ['compLayout', 'compProps', 'isOneDocMigrated'],
  ({ compLayout, compProps, isOneDocMigrated }) => {
    const { transDuration, imageMode } = compProps;

    return {
      ...(!isOneDocMigrated && {
        height:
          imageMode === 'flexibleHeight' ? 'auto' : `${compLayout.height}px`,
      }),
      '--transition-duration': `${transDuration * 1000}ms`,
      '--alpha-txt': '1',
    };
  },
);

const imageModeToDisplayMode = {
  clipImage: 'fill',
  flexibleHeight: 'fill',
  flexibleWidthFixed: 'fit',
} as const;

export const sdkData = withCompInfo<
  SlideShowGallerySdkData,
  SlideShowGalleryDefinition
>()(['compProps', 'compData'], ({ compProps, compData }) => {
  return {
    imageDisplayMode: imageModeToDisplayMode[compProps.imageMode], // todo - should be always fill after TB-2206 will be closed
    dataId: compData.id,
  };
});

export default createComponentMapperModel({ props, sdkData, css });
