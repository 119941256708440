import * as React from 'react';

import {
  WixRicosViewer,
  pluginEmojiViewer as pluginEmoji,
  pluginHashtagViewer as pluginHashtag,
  pluginMentionsViewer as pluginMentions,
  pluginSpoilerViewer as pluginSpoiler,
  pluginTextColorViewer as pluginTextColor,
  pluginTextHighlightViewer as pluginTextHighlight,
  pluginLinkButtonViewer as pluginLinkButton,
  pluginDividerViewer as pluginDivider,
  pluginVerticalEmbedViewer as pluginVerticalEmbed,
  pluginCollapsibleListViewer as pluginCollapsibleList,
  pluginFileUploadViewer as pluginFileUpload,
  pluginGalleryViewer as pluginGallery,
  pluginGiphyViewer as pluginGiphy,
  pluginHtmlViewer as pluginHtml,
  pluginImageViewer as pluginImage,
  pluginLinkViewer as pluginLink,
  pluginLinkPreviewViewer as pluginLinkPreview,
  pluginPollViewer as pluginPoll,
  pluginTableViewer as pluginTable,
  pluginVideoViewer as pluginVideo,
  pluginAudioViewer as pluginAudio,
} from '@wix/ricos';

import '@wix/ricos/css/ricos-viewer.global.css';
import '@wix/ricos/css/plugin-hashtag-viewer.global.css';
import '@wix/ricos/css/plugin-mentions-viewer.global.css';
import '@wix/ricos/css/plugin-spoiler-viewer.global.css';
import '@wix/ricos/css/plugin-button-viewer.global.css';
import '@wix/ricos/css/plugin-divider-viewer.global.css';
import '@wix/ricos/css/plugin-vertical-embed-viewer.global.css';
import '@wix/ricos/css/plugin-collapsible-list-viewer.global.css';
import '@wix/ricos/css/plugin-file-upload-viewer.global.css';
import '@wix/ricos/css/plugin-gallery-viewer.global.css';
import '@wix/ricos/css/plugin-giphy-viewer.global.css';
import '@wix/ricos/css/plugin-html-viewer.global.css';
import '@wix/ricos/css/plugin-image-viewer.global.css';
import '@wix/ricos/css/plugin-link-viewer.global.css';
import '@wix/ricos/css/plugin-link-preview-viewer.global.css';
import '@wix/ricos/css/plugin-social-polls-viewer.global.css';
import '@wix/ricos/css/plugin-table-viewer.global.css';
import '@wix/ricos/css/plugin-video-viewer.global.css';
import '@wix/ricos/css/plugin-audio-viewer.global.css';

import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import type { IRichContentViewerProps } from '../RichContentViewer.types';

import semanticClassNames from '../RichContentViewer.semanticClassNames';
import { styleParamDefaults } from '../styleParamDefaults';
import { st, classes } from './style/RichContentViewer.component.st.css';
import { RichContentViewerTrimContainer } from './RichContentViewerTrimContainer';
import { useFileUrlResolution } from './hooks/useFileUrlResolution';

const RichContentViewer: React.FC<
  DefaultCompPlatformProps & IRichContentViewerProps
> = props => {
  const {
    id,
    customClassNames = [],
    className,
    content,
    previewContent,
    metaSiteId,
    isMobileView,
    onNodeClick,
    language,
    onResolveFileUrlRequest,
    fileResolutions,
  } = props;
  const { filePluginConfig } = useFileUrlResolution({
    onResolveFileUrlRequest,
    fileResolutions,
  });

  const contentResolved = content ?? previewContent;
  if (!contentResolved) {
    return null;
  }

  return (
    <div id={id} className={className} style={{ height: 'auto' }}>
      <div
        className={st(
          classes.root,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        style={{
          backgroundColor: `rgb(var(--bgColor, var(--${styleParamDefaults.background.color})), var(--alpha-bgColor, ${styleParamDefaults.background.alpha}))`,
          borderRadius: 'var(--borderRadius, 0)',
          border: `calc(1px * var(--borderWidth, 0)) solid rgb(var(--borderColor, var(--${styleParamDefaults.border.color})), var(--alpha-borderColor, ${styleParamDefaults.border.alpha}))`,
          padding: `calc(1px * var(--verticalPadding, ${styleParamDefaults.padding.vertical})) calc(1px * var(--horizontalPadding, ${styleParamDefaults.padding.horizontal}))`,
        }}
      >
        <RichContentViewerTrimContainer
          options={props}
          content={contentResolved}
          ellipsisStyle={{
            ...getTextThemeForTag('p', isMobileView),
            color: `rgba(${actionColor})`,
            textDecoration: 'underline',
            cursor: 'pointer',
            marginTop: '1em',
          }}
        >
          {contentTrimmed => (
            <WixRicosViewer
              content={contentTrimmed}
              locale={language}
              biSettings={{
                consumer: 'editor-element',
                platform: 'Livesite',
                postId: '',
                containerId: '',
              }}
              msid={metaSiteId}
              instance=""
              theme={{
                parentClass: classes.root,
                customStyles: {
                  p: getTextThemeForTag('p', isMobileView),
                  h1: getTextThemeForTag('h1', isMobileView),
                  h2: getTextThemeForTag('h2', isMobileView),
                  h3: getTextThemeForTag('h3', isMobileView),
                  h4: getTextThemeForTag('h4', isMobileView),
                  h5: getTextThemeForTag('h5', isMobileView),
                  h6: getTextThemeForTag('h6', isMobileView),
                  quote: getTextThemeForTag('quote', isMobileView),
                },
                palette: {
                  type: 'rgb',
                  textColor: 'var(--color_15)',
                  actionColor,
                  textColorLow: 'var(--color_14)',
                  disabledColor: 'var(--color_13)',
                  bgColor: 'var(--bgColor)',
                },
              }}
              plugins={[
                pluginCollapsibleList(),
                pluginDivider(),
                pluginEmoji(),
                pluginGiphy(),
                pluginHashtag(),
                pluginImage(),
                pluginGallery(),
                pluginVideo(),
                pluginAudio(),
                pluginFileUpload(filePluginConfig),
                pluginLink(),
                pluginMentions(),
                pluginTable(),
                pluginTextColor(),
                pluginTextHighlight(),
                pluginHtml(),
                pluginLinkPreview(),
                pluginVerticalEmbed(),
                pluginSpoiler(),
                pluginPoll(),
                pluginLinkButton(),
              ]}
              mediaSettings={{
                fullscreenProps: {
                  container() {
                    return document.querySelector('.site-root');
                  },
                },
              }}
              onNodeClick={onNodeClick}
            />
          )}
        </RichContentViewerTrimContainer>
      </div>
    </div>
  );
};

function getTextThemeForTag(
  tag: keyof typeof styleParamDefaults.font,
  isMobileView: boolean,
) {
  return {
    color: `rgb(var(--${tag}Color, ${styleParamDefaults.fontColor}))`,
    fontStyle: `var(--${tag}FontStyle, normal)`,
    fontWeight: `var(--${tag}FontWeight, normal)`,
    fontSize: `calc(1px * var(--${tag}FontSize, ${
      isMobileView
        ? styleParamDefaults.font[tag].mobileSize
        : styleParamDefaults.font[tag].size
    }))`,
    lineHeight: '1.5em',
    fontFamily: `var(--${tag}FontFamily, var(--${styleParamDefaults.font[tag].familyFrom}-family))`,
    textDecoration: `var(--${tag}TextDecoration, none)`,
  };
}

const actionColor = 'var(--linkColor, var(--color_18))';

export default RichContentViewer;
