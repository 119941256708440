import React from 'react';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';

function withComponentPreview(
  ViewerComponent: React.ComponentType<any>,
): IComponentPreviewWrapper<any, any> {
  return props => {
    return <ViewerComponent {...props} />;
  };
}

export default (ViewerComponent: React.ComponentType<any>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
