import * as React from 'react';
import {
  ArtistName,
  TrackName,
  PlayPause,
  ProgressBar,
  TimeStamp,
} from '@wix/soundhead-react';
import classnames from 'clsx';
import {
  ISingleAudioPlayerProps,
  IPlayerProps,
} from '../../../SingleAudioPlayer.types';
import VolumeControls from '../../components/VolumeControls/VolumeControls';
import PlayerContainer from '../../components/PlayerContainer/PlayerContainer';
import { TestHooks } from '../../constants';
import style from '../../style/EPlayerLargePlay.scss';

const Player: React.FC<IPlayerProps> = props => {
  const {
    shouldDisplayRepeatIcon,
    isMute,
    id,
    className,
    onMouseEnter,
    onMouseLeave,
    ariaLabel,
  } = props;
  return (
    <div
      id={id}
      className={classnames(className, style.EPlayerLargePlay)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="application"
      aria-label={ariaLabel}
    >
      <div className={style.player}>
        <div className={style.mediaControls}>
          <div className={style.mediaButtonContainer}>
            <div className={style.waiting} data-testid={TestHooks.play}>
              <PlayPause
                className={classnames(style.mediaButton, style.button, {
                  [style.repeat]: shouldDisplayRepeatIcon,
                })}
                iconPlay={
                  <span
                    className={classnames(style.mediaButtonIcon, style.play)}
                    data-testid={TestHooks.playIcon}
                  />
                }
                iconPause={
                  <span
                    className={classnames(style.mediaButtonIcon, style.pause)}
                    data-testid={TestHooks.pauseIcon}
                  />
                }
              />
            </div>
          </div>
          <span className={style.info}>
            <TrackName
              className={classnames(style.trackLabel, style.ellipsis)}
            />
            <ArtistName
              className={classnames(style.artistLabel, style.ellipsis)}
            />
          </span>
          <div className={style.volumeContainer}>
            <TimeStamp
              display="duration"
              className={classnames(style.timeStampsContainer)}
            />
            <VolumeControls className={style.volumeControls} isMute={isMute} />
          </div>
        </div>
        <ProgressBar className={style.progressbar} />
      </div>
    </div>
  );
};

const EPlayerLargePlay: React.FC<ISingleAudioPlayerProps> = props => {
  return <PlayerContainer {...props} player={Player} />;
};

export default EPlayerLargePlay;
