export const PREVIEW_STATE = {
  MENU_TAB: 'menuTabOpened',
  SUBMENU_TAB: 'submenuTabOpened',
  MEGAMENU_TAB: 'megaMenuTabOpened',
} as const;

export const Selectors = {
  scrollButton: '.root::scrollButton',
  submenu: '.root::columnsLayout',
};
