import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IStateBoxControllerProps,
  IStateBoxMapperProps,
  IStateBoxProps,
  IStateBoxStateValues,
} from '../StateBox.types';

export default withCompController<
  IStateBoxMapperProps,
  IStateBoxControllerProps,
  IStateBoxProps,
  IStateBoxStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  return {
    ...mapperProps,
    changeSlide: slideIndex => {
      controllerUtils.updateProps({
        currentSlideIndex: slideIndex,
      });
    },
    observeChildListChange: mapperProps.isMasterPage
      ? stateValues.observeChildListChangeMaster
      : stateValues.observeChildListChange,
  };
});
