import React from 'react';
import { formatClassNames, keyCodes } from '@wix/editor-elements-common-utils';
import { useIsomorphicLayoutEffect } from '@wix/thunderbolt-elements/providers/useIsomorphicLayoutEffect';
import type { IPageStripProps } from '../../../Pagination.types';
import semanticClassNames from '../../../Pagination.semanticClassNames';
import { st, classes } from './style/PaginationStrip.st.css';
import { testIds } from './constants';
import {
  createPlaceholderLayout,
  createResponsiveLayout,
  createResponsiveLayoutTemplate,
} from './stripLayoutUtil';

const PageButton: React.FC<{
  index: number;
  current: boolean;
  isDisabled: boolean;
  onChange(index: number): void;
}> = ({ index, current, isDisabled, onChange }) => {
  if (current) {
    return <CurrentPageButton index={index} ariaLabel={`Page ${index}`} />;
  } else if (index === 0) {
    return <GapButton />;
  }

  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    const { keyCode } = event;
    if (keyCode === keyCodes.enter || keyCode === keyCodes.space) {
      event.preventDefault(); // prevents viewer from scrolling after keyboard press
      onChange(index);
    }
  };

  return (
    <a
      className={st(
        classes.pageButton,
        formatClassNames(semanticClassNames.pageNumber),
      )}
      aria-label={`Page ${index}`}
      aria-disabled={isDisabled}
      {...(!isDisabled && {
        tabIndex: 0,
        onClick: () => onChange(index),
        onKeyDown: onKeyDownHandler,
      })}
    >
      {index}
    </a>
  );
};

const GapButton: React.FC = () => (
  <span className={st(classes.gapLabel)}>...</span>
);

const CurrentPageButton: React.FC<{ ariaLabel?: string; index: number }> = ({
  ariaLabel,
  index,
}) => (
  <span
    className={st(
      classes.currentPage,
      formatClassNames(semanticClassNames.currentPage),
    )}
    aria-label={ariaLabel}
  >
    {index}
  </span>
);

// This component will never be seen by the user. It's a dummy representing the approximate width
// of a "real" button, which we use to measure how many buttons can actually fit in the container
// we've been given!
const TemplateButton: React.FC<{ index: number }> = ({ index }) => {
  if (index === 0) {
    return <GapButton />;
  }

  return <CurrentPageButton index={index} />;
};

const regenerateTemplate = (
  totalPages: number,
  currentPage: number,
  maxPagesToShow: number,
) => {
  const template = createResponsiveLayoutTemplate({
    totalPages,
    currentPage,
    maxPagesToShow,
  });

  return template.map((pageNumber, idx) => (
    <TemplateButton key={`t${idx}-${pageNumber}`} index={pageNumber} />
  ));
};

const PageStrip: React.FC<IPageStripProps> = ({
  totalPages,
  currentPage,
  showFirstLastPageButtons,
  isDisabled,
  maxPagesToShow,
  showPageNumbersBar,
  onChange,
}) => {
  // State for showing the strip only after the initial size calculations
  const [showStrip, setShowStrip] = React.useState(false);

  // Measure
  const [templateButtons, setTemplateButtons] = React.useState(() =>
    regenerateTemplate(totalPages, currentPage, maxPagesToShow),
  );

  const templateContainerRef = React.useRef<HTMLDivElement>(null);

  // Apply
  const [pagesToRender, setPagesToRender] = React.useState(() =>
    createPlaceholderLayout({
      currentPage,
      totalPages,
      showFirstLastPage: showFirstLastPageButtons,
    }),
  );

  const isFirstRunRef = React.useRef(true);

  // Renders the maximum possible amount of pages
  useIsomorphicLayoutEffect(() => {
    if (isFirstRunRef.current) {
      // Don't update template buttons on first run
      isFirstRunRef.current = false;
      return;
    }
    setTemplateButtons(() => regenerateTemplate(totalPages, currentPage, 100));
  }, [totalPages, currentPage]);

  /** After rendering all pages (templateButtons),
   *  measures PageStrip's inner children, and decides how many can be shown without overflowing the container */
  useIsomorphicLayoutEffect(() => {
    // In cursor mode, we only need to render the current page if (!totalPages)
    if (!totalPages) {
      return setPagesToRender([currentPage]);
    }

    const layout = createResponsiveLayout({
      container: templateContainerRef.current,
      totalPages,
      currentPage,
      maxPagesToShow,
      showFirstLastPage: showFirstLastPageButtons,
    });

    setPagesToRender(layout);
    setShowStrip(true);
  }, [templateButtons, showFirstLastPageButtons, maxPagesToShow]);

  const pageButtons = pagesToRender.map((pageNumber, index) => (
    <PageButton
      key={`${index}-${pageNumber}`}
      index={pageNumber}
      current={pageNumber === currentPage}
      isDisabled={isDisabled}
      onChange={onChange}
    />
  ));

  return (
    <div
      data-testid={testIds.PageStripRoot}
      className={st(classes.root, { disabled: isDisabled })}
      style={showStrip ? {} : { visibility: 'hidden' }}
    >
      {showPageNumbersBar && (
        <>
          <div className={st(classes.pageStripInner)}>{pageButtons}</div>
          {maxPagesToShow > 0 && (
            <div
              ref={templateContainerRef}
              className={st(classes.pageStripInner, classes.pageStripTemplate)}
            >
              {templateButtons}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default PageStrip;
