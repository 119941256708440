import {
  castFromInheritIfNeeded,
  convertPhysicalInputAlignmentToDirection,
  convertPhysicalInputAlignmentToLogical,
  getRequiredIndicationDisplay,
  getScaledFont,
  LogicalAlignment,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { RadioGroupProps as RadioGroupPlatformProps } from '@wix/thunderbolt-components';
import type { ErrorMessageType } from '@wix/editor-elements-types/components';
import {
  IRadioGroupStateValues,
  RadioGroupCssVars,
  RadioGroupDefinition,
  RadioGroupStyleProperties,
} from '../RadioGroup.types';
import { translationKeys } from './constants';

export const props = withCompInfo<
  RadioGroupPlatformProps,
  RadioGroupDefinition,
  RadioGroupPlatformProps
>()(
  [
    'compId',
    'language',
    'fullNameCompType',
    'compProps',
    'trackClicksAnalytics',
    'translate',
  ],
  (compInfo, carmiData) => {
    const { compProps, translate, ...restCompInfo } = compInfo;

    const { errorMessageType } = compProps as {
      errorMessageType: ErrorMessageType;
    };

    const fallbackValidationMessage = 'Select an option.';

    return {
      ...carmiData,
      ...restCompInfo,
      errorMessageType,
      translations: {
        errorMessage: translate(
          translationKeys.NAMESPACE,
          translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE,
        ),
        validationMessage:
          translate(
            translationKeys.NAMESPACE,
            translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_VALIDATION_MESSAGE,
          ) || fallbackValidationMessage,
      },
    };
  },
);

const getOptionLabelMargin = (spacing: number) => {
  return {
    '--optionLabelMargin_start': `${spacing + 1}px`,
    '--optionLabelMargin_end': '0',
  };
};

const getOptionCssVars = (
  margin: number,
  alignment: LogicalAlignment,
  layout: 'vertical' | 'horizontal',
) => {
  const marginWithPx = `${margin}px`;

  if (layout === 'horizontal') {
    return {
      '--optionsFlexDirection': 'row',
      '--itemsWidth': 'auto',
      '--optionsJustifyContent': alignment,
      '--optionsAlignItems': 'unset',
      '--optionsGap': marginWithPx,
    };
  }

  return {
    '--optionsFlexDirection': 'column',
    '--itemsWidth': '100%',
    '--optionsJustifyContent': 'unset',
    '--optionsAlignItems': alignment,
    '--optionsGap': marginWithPx,
  };
};

type Overrides = {
  styleProperties: RadioGroupStyleProperties;
};

export const css = withCompInfo<
  RadioGroupCssVars,
  RadioGroupDefinition,
  never,
  Overrides
>()(
  [
    'compId',
    'compLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'siteFontsSpxResolved',
  ],
  compInfo => {
    const { compData, compProps, styleProperties, hasResponsiveLayout } =
      compInfo;

    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      optionsDirection = 'inherit',
    } = compData;

    const { align, optionsAlign } = styleProperties;

    const {
      buttonsMargin,
      labelMargin,
      buttonSize,
      spacing,
      labelMobileFontSize,
      inputMobileFontSize,
      layout,
    } = compProps;

    const isResponsive = hasResponsiveLayout;

    const heightProp = isResponsive
      ? {}
      : {
          height: 'auto',
        };

    const calculatedOptionAlign = castFromInheritIfNeeded(optionsAlign, align);
    const optionCssVars = getOptionCssVars(
      buttonsMargin,
      calculatedOptionAlign,
      layout,
    );

    return {
      '--fnt2': getScaledFont(compInfo, 'fnt2', labelMobileFontSize),
      '--fnt': getScaledFont(compInfo, 'fnt', inputMobileFontSize),
      '--buttonSize': `${buttonSize}px`,
      '--direction': direction,
      '--labelDirection': labelDirection,
      '--optionsDirection': optionsDirection,
      '--align': align,
      '--groupLabelMargin': `0 0 ${labelMargin}px 0`,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      ...optionCssVars,
      ...heightProp,
      ...getOptionLabelMargin(spacing),
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'alignment', target: 'align' }],
        enhancer: convertPhysicalInputAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'alignment', target: 'direction' }],
        enhancer: convertPhysicalInputAlignmentToDirection,
      },
    ]),
  ],
);

export const stateRefs = withStateRefsValues<keyof IRadioGroupStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ props, stateRefs, css });
