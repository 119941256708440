import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  TextMarqueeProps,
  TextMarqueeCarmiProps,
  TextMarqueeDefinition,
} from '../TextMarquee.types';
import { props as viewerPropsMapper } from './TextMarquee.mapper';

const props = composeMapper<
  PreviewWrapperProps<TextMarqueeProps>,
  TextMarqueeDefinition,
  TextMarqueeCarmiProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    return {
      ...viewerProps,
      isInEditor: dependencies.componentViewMode !== 'preview',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
