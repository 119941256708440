import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  usePreviewEffect,
  usePreviewState,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import {
  ISignatureInputImperativeActions,
  ISignatureInputProps,
} from '../SignatureInput.types';
import { classes } from './style/SignatureInput.st.css';

const noop = () => {};

export default (ViewerComponent: React.ComponentType<ISignatureInputProps>) =>
  createComponentPreviewEntry(
    React.forwardRef<
      ISignatureInputImperativeActions | null,
      ISignatureInputProps
    >(
      (
        {
          previewWrapperProps = {},
          ...viewerProps
        }: PreviewWrapperProps<
          ISignatureInputProps,
          {
            shouldResetComponent?: boolean;
            componentViewMode?: string;
            compPreviewState?: string;
          }
        >,
        ref,
      ) => {
        const { shouldResetComponent, componentViewMode, compPreviewState } =
          previewWrapperProps;
        const { id, validateValue = noop } = viewerProps;

        const compRef = React.useRef<ISignatureInputImperativeActions | null>(
          null,
        );
        React.useImperativeHandle<ISignatureInputImperativeActions | null, any>(
          ref,
          () => compRef && compRef.current,
        );

        usePreviewEffect({
          componentViewMode,
          onPreviewViewMode: validateValue,
        });

        useResetComponent({
          shouldResetComponent,
          id,
          onResetComponent: () => {
            compRef.current?.clear();
          },
        });

        usePreviewState(id, compPreviewState, {
          focus: {
            selectors: `.${classes.clearButton}`,
            type: 'single',
          },
          hover: {
            selectors: `.${classes.padContainer}`,
            type: 'single',
          },
        });

        usePreviewState(id, compPreviewState);

        const shouldShowValidityIndication =
          compPreviewState === 'error' ||
          viewerProps.shouldShowValidityIndication;

        return (
          <ViewerComponent
            {...viewerProps}
            shouldShowValidityIndication={shouldShowValidityIndication}
            ref={compRef}
          />
        );
      },
    ),
  );
