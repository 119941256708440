import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterFollowDefinition,
  WTwitterFollowMapperProps,
  WTwitterFollowStateRefs,
  IWTwitterFollowCSSVars,
} from '../WTwitterFollow.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys, COMPONENT_LABEL, TITLE_NAME } from './constants';

export const props = withCompInfo<
  WTwitterFollowMapperProps,
  WTwitterFollowDefinition
>()(
  ['compData', 'compProps', 'language', 'translate', ...consentPolicyPropsDeps],
  compInfo => {
    const { compData, compProps, language, translate } = compInfo;
    const originalProps = {
      showCount: compProps.showCount.toString(),
      showScreenName: compProps.showScreenName.toString(),
      accountToFollow: compData.accountToFollow.replace('@', ''),
      lang: getLanguage(compProps.dataLang, language),
      translations: {
        title: translate(COMPONENT_LABEL, TITLE_NAME),
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

export const css = withCompInfo<
  IWTwitterFollowCSSVars,
  WTwitterFollowDefinition
>()(['compLayout', 'isOneDocMigrated'], ({ compLayout, isOneDocMigrated }) => {
  return {
    ...(!isOneDocMigrated && {
      '--width': compLayout.width,
      '--height': compLayout.height,
      width: 'calc(var(--width) * 1px)',
      height: 'calc(var(--height) * 1px)',
    }),
    display: 'var(--display)',
    '--display': 'flex',
  };
});

export const stateRefs = withStateRefsValues<keyof WTwitterFollowStateRefs>([
  'currentUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
