import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getTopCSS, isFixedPosition } from '@wix/editor-elements-common-utils';
import {
  HeaderContainerDefinition,
  HeaderContainerCarmiProps,
  HeaderContainerCarmiCss,
  HeaderSdkStyles,
  IHeaderContainerMapperProps,
} from '../HeaderContainer.types';

export const props = withCompInfo<
  IHeaderContainerMapperProps,
  HeaderContainerDefinition,
  HeaderContainerCarmiProps
>()(
  [
    'compLayout',
    'compSingleLayout',
    'compId',
    'isMobileView',
    'isOneDocMigrated',
  ],
  (
    { compLayout, compSingleLayout, compId, isMobileView, isOneDocMigrated },
    carmiData,
  ) => {
    const fixedPosition = isOneDocMigrated
      ? isFixedPosition(compSingleLayout)
      : !!compLayout.fixedPosition;
    const marginTop = getTopCSS(compLayout, compSingleLayout);
    return {
      ...carmiData,
      marginTop,
      compId,
      isMobileView,
      isFixed: fixedPosition,
    };
  },
);

export const css = withCompInfo<
  HeaderContainerCarmiCss,
  HeaderContainerDefinition,
  HeaderContainerCarmiCss
>()([], (_, carmiCss) => {
  return carmiCss;
});

export const sdkData = withCompInfo<
  HeaderSdkStyles,
  HeaderContainerDefinition,
  HeaderSdkStyles
>()([], (_, carmiSdkData) => {
  return carmiSdkData;
});

export default createComponentMapperModel({ props, css, sdkData });
