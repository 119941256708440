import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import type { MenuButtonProps } from '../../../../MenuButton/MenuButton.types';

type PointerMenuButtonProps = IDropDownMenuProps & {
  styles: any;
  Button: React.FC<MenuButtonProps>;
};

const PointerMenuButton: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  PointerMenuButtonProps
> = (props, ref) => {
  const { styles, Button, ...rest } = props;
  return (
    <DropDownMenuBase {...rest} ref={ref} styles={styles} Button={Button} />
  );
};

export default React.forwardRef(PointerMenuButton);
