import * as React from 'react';
import classNames from 'clsx';
import Image from '@wix/thunderbolt-elements/src/components/Image/viewer/Image';
import type { IMatrixGalleryItemProps } from '../MatrixGalleryItem.types';
import ItemClickActionWrapper from '../../commons/viewer/ItemClickActionWrapper/ItemClickActionWrapper';
import { TestIds } from '../../commons/constants';
import ImageSizer from '../../commons/viewer/ImageSizer/ImageSizer';
import Panel from './Panel';

const MatrixGalleryItem: React.FC<IMatrixGalleryItemProps> = props => {
  const {
    dataId,
    title,
    description,
    link,
    image,
    itemId,
    skinsStyle,
    extraClassNames,
    imageOnClickAction,
    openImageZoom = () => {},
    itemClickHandler = () => {},
    translations,
    isFocused,
    isFitMode,
  } = props;

  const openImageZoomCallback = React.useCallback(
    () => openImageZoom(dataId),
    [dataId, openImageZoom],
  );

  let itemRef: HTMLDivElement;
  const focusIfNeeded = (element: HTMLDivElement) => {
    if (isFocused && element) {
      element.focus();
    }
  };

  const focusItemRoot = () => {
    if (itemRef) {
      itemRef.focus();
    }
  };

  return (
    <div
      className={classNames(skinsStyle.root, extraClassNames.root, {
        [skinsStyle.fitMode]: isFitMode,
      })}
      data-testid={TestIds.item}
      onClick={itemClickHandler}
      tabIndex={-1}
      ref={(element: HTMLDivElement) => {
        itemRef = element;
        focusIfNeeded(element);
      }}
    >
      <ItemClickActionWrapper
        imageOnClickAction={imageOnClickAction}
        openImageZoom={openImageZoomCallback}
        focusItemRoot={focusItemRoot}
        link={link}
        imgTitle={image.title || ''}
        imgAlt={image.alt}
        itemId={itemId}
        skinsStyle={skinsStyle}
        translations={translations}
        focusDisabledItems={true}
      >
        <ImageSizer
          className={skinsStyle.imageSizer}
          isFitMode={isFitMode}
          imageWidth={image.width}
          imageHeight={image.height}
        >
          <div className={skinsStyle.imageWrapper}>
            <div className={skinsStyle.imageBorder}>
              <Image
                className={skinsStyle.image}
                id={`img_${image.containerId}`}
                {...image}
                alt={image.alt || (image.title as string)}
              />
              <Panel
                skinsStyle={skinsStyle}
                title={title}
                description={description}
              />
            </div>
          </div>
        </ImageSizer>
      </ItemClickActionWrapper>
    </div>
  );
};

export default MatrixGalleryItem;
