import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { IResponsivePopupContainerProps } from '../ResponsivePopupContainer.types';

export const props = withCompInfo<
  IResponsivePopupContainerProps,
  any, // There is no ResponsivePopupContainerDefinition in TB Components
  IResponsivePopupContainerProps
>()([], (_, carmiData) => carmiData);

export default createComponentMapperModel({ props });
