const getValueFromTheme = (val: string | number, colors: Array<string>) => {
  const colorNumber =
    typeof val === 'string' && Number.parseInt(val.split('_')[1], 10);
  return (typeof colorNumber === 'number' && colors[colorNumber]) || val;
};

const capitalize = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);

export const getCompStyles = (
  compStyleKeys: Record<string, string | number>,
  styleProperties: Record<string, string>,
  colors: Array<string>,
) => {
  const compStyles: Record<string, string | number> = {};
  Object.keys(compStyleKeys).forEach(key => {
    const value = compStyleKeys[key];
    compStyles[key] = getValueFromTheme(styleProperties[value], colors);
    compStyles[`alpha${capitalize(key)}`] = getValueFromTheme(
      styleProperties[`alpha-${value}`],
      colors,
    );
  });
  return compStyles;
};

export const getUrl = (
  baseUrl: string,
  compId: string,
  deviceType: string,
  locale: string,
) => {
  const viewMode = 'site';
  const secureBaseUrl =
    baseUrl.indexOf('https') !== 0 ? baseUrl.replace('http', 'https') : baseUrl;

  return `${secureBaseUrl}?${new URLSearchParams({
    compId,
    deviceType,
    locale,
    viewMode,
  })}`;
};

export { SlideShowGalleryPropertiesSchema } from './SlideShowGalleryProperties.schema';
