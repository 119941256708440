import * as React from 'react';
import type { MouseEvent, KeyboardEvent } from 'react';
import classnames from 'clsx';
import { performOnEnter } from '@wix/editor-elements-common-utils';
import type { ISiteMembersInputRef } from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/SiteMembersInput';
import SiteMembersInput from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/SiteMembersInput';
import {
  serverErrorsHandler,
  validateSiteMembersEmail,
  validateSiteMembersPassword,
} from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/utils';
import Captcha from '@wix/thunderbolt-elements/src/components/Captcha/viewer/Captcha';
import type {
  IServerErrorResponse,
  ITranslationKeys,
} from '@wix/thunderbolt-elements/src/components/SiteMembersInput/SiteMembersInput.types';
import type {
  ICaptchaImperativeActions,
  IEmailAuthProps,
} from '../MemberLoginDialog.types';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import type { SiteMembersTranslations } from './utils';
import {
  MemberLoginDialogTranslationKeys as keys,
  SM_ERROR_CODES,
  testIds,
} from './utils';

export const EmailAuth: React.FC<IEmailAuthProps> = ({
  id,
  translations,
  onForgetYourPasswordClick,
  onSubmitStart,
  onSubmitEnd,
  submit,
  style,
  mode,
  submitButtonLabel = '',
  isCommunityChecked,
  language,
  directionByLanguage,
  shouldForceCaptchaVerification = false,
  serverError,
}) => {
  const [email, setEmail] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [recaptchaToken, setReCaptchaToken] = React.useState('');
  const [isButtonDisabled, setIsButtonDisabled] = React.useState(false);
  const [shouldRenderACaptchaState, setShouldRenderACaptchaState] =
    React.useState(shouldForceCaptchaVerification);
  const captchaRequiredButMissing =
    shouldRenderACaptchaState && !recaptchaToken;
  const isSignIn = mode === 'login';
  const startLoading = () => {
    onSubmitStart();
    setIsButtonDisabled(true);
  };
  const stopLoading = () => {
    setIsButtonDisabled(false);
    onSubmitEnd();
  };

  const mobileForgotPassword = translations[keys.mobileForgotPassword];
  const forgotPassword = translations[keys.forgotPassword];
  const passwordText = {
    title: translations[keys.password.title],
    label: translations[keys.password.label],
  };
  const emailText = {
    title: translations[keys.email.title],
    label: translations[keys.email.label],
  };
  const emailRef = React.useRef<ISiteMembersInputRef>(null);
  const passwordRef = React.useRef<ISiteMembersInputRef>(null);
  const captchaRef = React.useRef<ICaptchaImperativeActions>(null);
  const handlerServerError = React.useCallback(
    (_serverError: string | number | IServerErrorResponse) =>
      handleServerErrorResponse({
        setShouldRenderACaptchaState,
        serverError: _serverError,
        shouldRenderACaptchaState,
        passwordRef,
        captchaRef,
        translations,
        emailRef,
        isSignIn,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldRenderACaptchaState, isSignIn],
  );
  React.useEffect(() => {
    if (!serverError) {
      return;
    }
    handlerServerError(serverError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverError]);

  const submitForm = React.useCallback(
    (e: MouseEvent | KeyboardEvent) =>
      _submitForm(
        e,
        passwordRef,
        emailRef,
        translations,
        startLoading,
        submit,
        email,
        password,
        recaptchaToken,
        stopLoading,
        handlerServerError,
        isCommunityChecked,
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [email, password, recaptchaToken, isCommunityChecked],
  );
  const onKeyDownHandler = performOnEnter(submitForm);

  return (
    <>
      <form
        data-testid={testIds.emailAuth}
        className={style.customLogin}
        onKeyDown={onKeyDownHandler}
      >
        <div className={style.content}>
          <div className={style.email}>
            <SiteMembersInput
              id={`emailInput_${id}`}
              inputType="email"
              data-testid="emailInput"
              value={email}
              label={emailText.label}
              onValueChanged={(_email: string) => setEmail(_email)}
              ref={emailRef}
              isValid={true}
              autoFocus={true}
              validationFn={validateSiteMembersEmail}
              directionByLanguage={directionByLanguage}
            />
          </div>
          <div className={style.password}>
            <SiteMembersInput
              id={`passwordInput_${id}`}
              inputType="password"
              data-testid="passwordInput"
              value={password}
              label={passwordText.label}
              onValueChanged={(_password: string) => setPassword(_password)}
              ref={passwordRef}
              isValid={true}
              validationFn={validateSiteMembersPassword[mode]}
              directionByLanguage={directionByLanguage}
            />
          </div>
        </div>
        {isSignIn && (
          <div className={style.switchLinkContainer}>
            <button
              className={classnames(style.forgotYourPasswordLink, style.mobile)}
              data-testid="forgotPasswordMobile"
              onClick={onForgetYourPasswordClick}
              type="button"
            >
              {mobileForgotPassword}
            </button>
            <button
              className={classnames(
                style.forgotYourPasswordLink,
                style.desktop,
              )}
              data-testid="forgotPasswordDesktop"
              onClick={onForgetYourPasswordClick}
              type="button"
            >
              {forgotPassword}
            </button>
          </div>
        )}
        {shouldRenderACaptchaState && (
          <div className={style.captchaWrapper}>
            <Captcha
              language={language}
              id="signInCaptcha"
              onTokenChange={(token = '') => {
                setReCaptchaToken(token);
              }}
              token={recaptchaToken}
              isMobileFriendly={false}
              ref={captchaRef}
            />
          </div>
        )}
        <div data-testid="submit" className={style.actionButton}>
          <BasicButton
            label={submitButtonLabel}
            id={`okButton_${id}`}
            isDisabled={isButtonDisabled || captchaRequiredButMissing}
            hasPlatformClickHandler={true}
            link={undefined}
            onClick={submitForm}
          />
        </div>
      </form>
    </>
  );
};

async function _submitForm(
  e: MouseEvent | KeyboardEvent,
  passwordRef: React.RefObject<ISiteMembersInputRef>,
  emailRef: React.RefObject<ISiteMembersInputRef>,
  translations: ITranslationKeys,
  startLoading: () => void,
  submit: (
    email: string,
    password: string,
    options?: {
      recaptchaToken?: string;
      isCommunityChecked?: boolean;
    },
  ) => Promise<void>,
  email: string,
  password: string,
  recaptchaToken: string,
  stopLoading: () => void,
  handlerServerError: (
    serverError: string | number | IServerErrorResponse,
  ) => void,
  isCommunityChecked?: boolean,
) {
  e.preventDefault();
  const isPasswordValid = passwordRef.current!.validate(translations);
  const isEmailValid = emailRef.current!.validate(translations);
  if (isEmailValid && isPasswordValid) {
    startLoading();
    try {
      await submit(email, password, {
        // an empty recaptchaToken might be '' so in case the captcha is empty we prefer
        // to pass undefined over an empty string.
        recaptchaToken: recaptchaToken ? recaptchaToken : undefined,
        isCommunityChecked,
      });
    } catch (error: any) {
      const _error = error?.response?.data ?? error;
      handlerServerError(_error as string | number | IServerErrorResponse);
    }
    stopLoading();
  }
}

const handleServerErrorResponse = ({
  setShouldRenderACaptchaState,
  shouldRenderACaptchaState,
  serverError,
  passwordRef,
  captchaRef,
  translations,
  emailRef,
  isSignIn,
}: {
  setShouldRenderACaptchaState: React.Dispatch<React.SetStateAction<boolean>>;
  captchaRef: React.RefObject<ICaptchaImperativeActions>;
  serverError: string | number | IServerErrorResponse;
  passwordRef: React.RefObject<ISiteMembersInputRef>;
  emailRef: React.RefObject<ISiteMembersInputRef>;
  shouldRenderACaptchaState: boolean;
  isSignIn: boolean;
} & SiteMembersTranslations) => {
  // In case of admin approval / email approval flow the dialog will be replaced with
  // another dialog so the following refs will be lost.
  if (serverError === 'authentication canceled') {
    return;
  }
  const errorMsg = serverErrorsHandler(serverError);
  resetCaptcha({
    errorMsg,
    setRenderCaptcha: setShouldRenderACaptchaState,
    shouldRenderCaptcha: shouldRenderACaptchaState,
    captchaRef,
  });
  showError({
    emailRef,
    errorMsg,
    translations,
    passwordRef,
    shouldAttachToPassword: isSignIn,
  });
};

const resetCaptcha = ({
  errorMsg,
  setRenderCaptcha: renderCaptcha,
  shouldRenderCaptcha,
  captchaRef,
}: {
  errorMsg: string;
  shouldRenderCaptcha: boolean;
  setRenderCaptcha: React.Dispatch<React.SetStateAction<boolean>>;
  captchaRef: React.RefObject<ICaptchaImperativeActions>;
}) => {
  if (errorMsg.includes(SM_ERROR_CODES.CAPTCHA_REQUIRED)) {
    // There is an edge case where the client settings for verifying a captcha might be
    // off while the server settings might be on, in here we handle this case by
    // overriding the client FT state in case we get from the server a requirement
    // for sending a captcha token.
    renderCaptcha(true);
  }
  if (shouldRenderCaptcha) {
    captchaRef?.current?.reset?.();
  }
};

const showError = ({
  errorMsg,
  translations,
  passwordRef,
  emailRef,
  shouldAttachToPassword,
}: {
  passwordRef: React.RefObject<ISiteMembersInputRef>;
  emailRef: React.RefObject<ISiteMembersInputRef>;
  errorMsg: string;
  shouldAttachToPassword: boolean;
} & SiteMembersTranslations) => {
  const defaultErrorMsg = translations.membersErrorGeneral;
  if (shouldAttachToPassword) {
    passwordRef.current?.setError(translations[errorMsg] ?? defaultErrorMsg);
    return emailRef.current?.setError();
  }
  emailRef.current?.setError(translations[errorMsg] ?? defaultErrorMsg);
};
