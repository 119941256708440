import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { WPhotoDefinition } from '@wix/thunderbolt-components';
import { IWPhotoMapperProps } from '../WPhoto.types';
import { props as viewerPropsMapper } from './WPhoto.mapper';

const props = composeMapper<
  PreviewWrapperProps<IWPhotoMapperProps>,
  WPhotoDefinition,
  IWPhotoMapperProps
>()(
  viewerPropsMapper,
  {
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
