import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import type {
  MenuToggleDefinition,
  IMenuToggleMapperProps,
  MenuToggleCarmiCSS,
  IMenuToggleStateRefs,
} from '../MenuToggle.types';

const ARIA_LABEL_NAMESPACE = 'AriaLabels';

export const props = withCompInfo<
  IMenuToggleMapperProps,
  MenuToggleDefinition,
  IMenuToggleMapperProps
>()(['translate'], ({ translate }, carmiData) => {
  return {
    ...carmiData,
    translations: {
      ariaLabelOpen: translate(
        ARIA_LABEL_NAMESPACE,
        'Mobile_Hamburger_Menu_AriaLabel_Open',
      ),
      ariaLabelClose: translate(
        ARIA_LABEL_NAMESPACE,
        'Mobile_Hamburger_Menu_AriaLabel_Close',
      ),
    },
  };
});

export const css = withCompInfo<
  MenuToggleCarmiCSS,
  MenuToggleDefinition,
  MenuToggleCarmiCSS
>()([], (_, carmiCss) => {
  return carmiCss;
});

export const stateRefs = withStateRefs<IMenuToggleStateRefs>()(
  ['refApi', 'compBehaviors'],
  ({ refApi, compBehaviors }) => {
    const menuContainerId = compBehaviors?.[0]?.behavior.targetId;
    if (!menuContainerId) {
      return {};
    }
    return {
      isOpen: refApi.menuContainer.isOpen(menuContainerId),
      toggle: refApi.menuContainer.toggle(menuContainerId),
    };
  },
);

export default createComponentMapperModel({ props, css, stateRefs });
