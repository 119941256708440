import * as React from 'react';
import {
  ArtistName,
  TrackName,
  PlayPause,
  ProgressBar,
  TimeStamp,
} from '@wix/soundhead-react';
import classnames from 'clsx';
import {
  ISingleAudioPlayerProps,
  IPlayerProps,
} from '../../../SingleAudioPlayer.types';
import VolumeControls from '../../components/VolumeControls/VolumeControls';
import PlayerContainer from '../../components/PlayerContainer/PlayerContainer';
import { TestHooks } from '../../constants';
import style from '../../style/EPlayerFramedPlay.scss';

const Player: React.FC<IPlayerProps> = props => {
  const {
    shouldDisplayRepeatIcon,
    isMute,
    id,
    className,
    onMouseEnter,
    onMouseLeave,
    ariaLabel,
  } = props;

  return (
    <div
      id={id}
      className={classnames(className, style.EPlayerFramedPlay)}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      role="application"
      aria-label={ariaLabel}
    >
      <div className={style.player}>
        <div className={style.infoContainer}>
          <div className={style.info}>
            <TrackName className={style.trackLabel} />
            <span className={style.separator}>{' - '}</span>
            <ArtistName className={style.artistLabel} />
          </div>
          <TimeStamp
            display="duration"
            className={classnames(style.timeStampsContainer)}
          />
        </div>
        <div className={style.mediaControls}>
          <div className={style.btnContainer}>
            <div
              className={style.mediaButtonContainer}
              data-testid={TestHooks.play}
            >
              <PlayPause
                className={classnames(style.mediaButton, style.button, {
                  [style.repeat]: shouldDisplayRepeatIcon,
                })}
                iconPlay={
                  <span
                    className={classnames(style.mediaButtonIcon, style.play)}
                    data-testid={TestHooks.playIcon}
                  />
                }
                iconPause={
                  <span
                    className={classnames(style.mediaButtonIcon, style.pause)}
                    data-testid={TestHooks.pauseIcon}
                  />
                }
              />
            </div>
          </div>
          <ProgressBar className={style.progressbar} />
          <VolumeControls className={style.volumeControls} isMute={isMute} />
        </div>
      </div>
    </div>
  );
};

const EPlayerFramedPlay: React.FC<ISingleAudioPlayerProps> = props => {
  return <PlayerContainer {...props} player={Player} />;
};

export default EPlayerFramedPlay;
