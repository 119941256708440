import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any, any>()(
  ['compData', 'compMeshProps'],
  ({ compData, compMeshProps }) => {
    return {
      ...{},
      ...compData,
      meshProps: compMeshProps,
    };
  },
);

export const css = withCompInfo<any, any>()([], () => {
  return {
    width: '100%',
    height: '100%',
  };
});

export default createComponentMapperModel({
  props,
  css,
});
