import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { IHamburgerOverlayProps } from '../HamburgerOverlay.props';
import type { IHamburgerOverlayDefinition } from '../documentManagement/HamburgerOverlay.definition';
import type { IHamburgerOverlayCarmiProps } from './HamburgerOverlay.mapper';
import {
  props as viewerPropsMapper,
  stateRefs,
} from './HamburgerOverlay.mapper';

const props = composeMapper<
  IHamburgerOverlayProps,
  IHamburgerOverlayDefinition,
  IHamburgerOverlayCarmiProps
>()(
  viewerPropsMapper,
  {
    add: ['componentViewMode'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { componentViewMode } = dependencies;

    return {
      ...viewerProps,
      hideFromDOM: componentViewMode === 'editor',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  stateRefs,
});
