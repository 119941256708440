export const BASE_FACEBOOK_LIKE_API_URL =
  'https://www.facebook.com/plugins/like.php';

export const TRANSLATIONS_TITLE_NAMESPACE = 'component_label';
export const TRANSLATIONS_TITLE_KEY = 'component_label_ facebookLikeTitle';
export const TRANSLATIONS_TITLE_DEFAULT = 'Facebook Like';

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_WFacebookLike',
  componentNameDefault: 'Facebook Like',
  companyNameKey: 'CookieConsent_Dataset_Company_Meta',
  companyNameDefault: 'Meta',
};
