import {
  withCompInfo,
  withCompPreviewInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  ClassicSectionDefinition,
  ClassicSectionCarmiData,
  ClassicSectionCss,
} from '../ClassicSection.types';

export const props = withCompInfo<
  ClassicSectionCarmiData,
  ClassicSectionDefinition,
  ClassicSectionCarmiData
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompPreviewInfo<
  ClassicSectionCss,
  ClassicSectionDefinition,
  ClassicSectionCss
>()([], (_, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({
  props,
  css,
});
