
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "PayDialog1976191406",
      {"classes":{"root":"PayDialog1976191406__root","buttonsSection":"PayDialog1976191406__buttonsSection","textField":"PayDialog1976191406__textField","field":"PayDialog1976191406__field","fieldTitle":"PayDialog1976191406__fieldTitle","inputModalContent":"PayDialog1976191406__inputModalContent","textFieldWithErrorMessage":"PayDialog1976191406__textFieldWithErrorMessage","button":"PayDialog1976191406__button","payButton":"PayDialog1976191406__payButton","cancelButton":"PayDialog1976191406__cancelButton","paymentDetail":"PayDialog1976191406__paymentDetail"},"vars":{"wix-font-stack":"--PayDialog1976191406-wix-font-stack"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  