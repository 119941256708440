import React from 'react';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { IAccordionLayoutProps } from '../../types';
import { handleKeyDown } from '../utils/handleKeyDown';
import { iconsMap } from '../icons';
import { st, classes } from '../AccordionContainer.component.st.css';
import { classes as headerClasses } from '../AccordionContainerHeader.st.css';
import { classes as headerLabelClasses } from '../AccordionContainerHeaderLabel.st.css';
import { classes as headerIconClasses } from '../AccordionContainerHeaderIcon.st.css';
import semanticClassNames from '../../AccordionContainer.semanticClassNames';

export const AccordionLayout: React.FC<IAccordionLayoutProps> = ({
  item: { id, label, content },
  isOpened,
  toggleItem,
  icon,
  animationProps,
  addAccordionItemPreviewRef,
}) => {
  const { isContentShown = isOpened, onTransitionEnd } = animationProps || {};
  const [iconCollapsed, iconExpanded] = iconsMap[icon];

  return (
    <li
      className={st(
        classes.accordion,
        { isOpened },
        formatClassNames(semanticClassNames.item),
      )}
    >
      {/* this div can have a11y role heading in future (phase 2) */}
      <div ref={ref => addAccordionItemPreviewRef(ref!)}>
        <button
          className={st(headerClasses.root, classes.accordionHeader)}
          onClick={() => toggleItem(id)}
          onKeyDown={handleKeyDown}
          type="button"
          id={id}
          aria-expanded={isOpened}
          aria-haspopup
        >
          <span
            className={st(
              headerLabelClasses.root,
              headerClasses.accordionLabel,
              formatClassNames(semanticClassNames.title),
            )}
          >
            {label}
          </span>
          <span
            className={st(
              headerIconClasses.root,
              headerClasses.accordionIcon,
              formatClassNames(semanticClassNames.icon),
            )}
          >
            {isOpened ? iconExpanded : iconCollapsed}
          </span>
        </button>
      </div>
      <div
        className={animationProps && classes.animationBox}
        onTransitionEnd={onTransitionEnd}
        aria-labelledby={id}
      >
        <div
          className={st(
            classes.accordionContent,
            {
              isContentHidden: !isContentShown,
            },
            formatClassNames(semanticClassNames.container),
          )}
        >
          {content}
        </div>
      </div>
    </li>
  );
};
