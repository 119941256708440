import * as React from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { IGridProps } from '../Grid.types';
import semanticClassNames from '../Grid.semanticClassNames';
import { getCellWidth } from '../utils';
import styles from './styles/Grid.scss';

export interface ITableHead {
  setScrollLeft(scrollLeft: number): void;
}

type ITableHeadProps = Pick<IGridProps, 'columns' | 'columnLayout'> & {
  tableWidth?: number;
  columnWidthUnitMap?: Record<string, string>;
};

const TableHead: React.ForwardRefRenderFunction<ITableHead, ITableHeadProps> = (
  { columns, columnWidthUnitMap, columnLayout, tableWidth },
  ref,
) => {
  const theadRef = React.useRef<HTMLTableSectionElement>(null);

  React.useImperativeHandle(ref, () => ({
    setScrollLeft(scrollLeft) {
      const theadElement = theadRef.current;
      if (theadElement) {
        theadElement.scrollLeft = scrollLeft;
      }
    },
  }));

  return (
    <thead
      ref={theadRef}
      className={classNames(
        styles.head,
        formatClassNames(semanticClassNames.header),
      )}
    >
      <tr
        className={classNames(
          styles.headRow,
          formatClassNames(semanticClassNames.row),
        )}
        style={{ width: tableWidth }}
      >
        {columns.map(({ id, label, width }, columnIndex) => {
          const widthUnit = columnWidthUnitMap?.[id] || 'px';

          return (
            <th
              key={columnIndex}
              className={classNames(
                styles.headCell,
                formatClassNames(semanticClassNames.cell),
              )}
              style={{
                width: getCellWidth({ width, widthUnit, columnLayout }),
              }}
            >
              <div className={styles.cellContent}>
                {!!label && <div dangerouslySetInnerHTML={{ __html: label }} />}
              </div>
            </th>
          );
        })}
      </tr>
    </thead>
  );
};

export default React.forwardRef(TableHead);
