import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  generatePreviewStateDataMap,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  IMenuDefinition,
  IMenuMapperProps,
  IMenuCarmiData,
  IMenuPreviewMapperProps,
} from '../Menu.types';
import { props as viewerPropsMapper } from './Menu.mapper';

export const props = composeMapper<
  PreviewWrapperProps<IMenuMapperProps>,
  IMenuDefinition,
  IMenuCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
  },
  (
    resolver,
    dependencies,
    carmiData,
  ): PreviewWrapperProps<IMenuPreviewMapperProps> => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState } = dependencies;

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
      previewStateMap: generatePreviewStateDataMap(compPreviewState),
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
