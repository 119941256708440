import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  MatrixGalleryCarmiData,
  MatrixGalleryDefinition,
  IMatrixGalleryMapperProps,
} from '../MatrixGallery.types';
import { props as viewerPropsMapper } from './MatrixGallery.mapper';

export const props = composeMapper<
  IMatrixGalleryMapperProps,
  MatrixGalleryDefinition,
  MatrixGalleryCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['shouldResetGalleryToOriginalState'],
  },
  (resolver, deps, carmiData) => {
    const { shouldResetGalleryToOriginalState } = deps;
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { shouldResetGalleryToOriginalState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
