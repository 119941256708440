export const testIds = {
  root: 'Pagination_Root',
  navButtonFirst: 'Pagination_NavButton_First',
  navButtonLast: 'Pagination_NavButton_Last',
  navButtonPrev: 'Pagination_NavButton_Prev',
  navButtonNext: 'Pagination_NavButton_Next',
};

export const collapseAlignmentMap = {
  left: 'flex-start',
  center: 'center',
  spread: 'space-between',
  right: 'flex-end',
};

export const PaginationMode = {
  Pages: 'pages',
  Arrows: 'arrows',
  Input: 'input',
} as const;

export const PaginationAlignment = {
  Left: 'left',
  Center: 'center',
  Spread: 'spread',
  Right: 'right',
} as const;
