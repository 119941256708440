
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "ShareButtoncomponent1171347912",
      {"classes":{"root":"ShareButtoncomponent1171347912__root","item":"ShareButtoncomponent1171347912__item","icon":"ShareButtoncomponent1171347912__icon","label":"ShareButtoncomponent1171347912__label","tooltipWrapper":"ShareButtoncomponent1171347912__tooltipWrapper","showTooltip":"ShareButtoncomponent1171347912__showTooltip","copyButtonWrapper":"ShareButtoncomponent1171347912__copyButtonWrapper","tooltip":"ShareButtoncomponent1171347912__tooltip","tooltipArrow":"ShareButtoncomponent1171347912__tooltipArrow"},"vars":{},"stVars":{"btn-icon-text-spacing":"var(--btn-icon-text-spacing)","btn-icon-position":"var(--btn-icon-position)","btn-icon-display":"var(--btn-icon-display)","btn-label-display":"var(--btn-label-display)","btn-tooltip-display":"var(--btn-tooltip-display)","btn-copy-to-clipboard-display":"var(--btn-copy-to-clipboard-display)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  