import { generateSkinDefaults } from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

export const testCompSkinDefinition: Record<string, SkinDefinition> = {
  'wixui.skins.TestComp': {
    'label-text-align': {
      type: 'CSSString',
      defaultValue: 'revert',
      state: 'regular',
    },
    'label-text-highlight': {
      type: 'COLOR_ALPHA',
      defaultValue: 'none',
      state: 'regular',
    },
    'label-text-transform': {
      type: 'CSSString',
      defaultValue: 'none',
      state: 'regular',
    },
    'label-text-outline': {
      type: 'CSSString',
      defaultValue:
        '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
      state: 'regular',
    },
    'label-text-shadow': {
      type: 'TEXT_SHADOW',
      defaultValue: '0px 0px transparent',
      state: 'regular',
    },
    'label-font': {
      type: 'FONT',
      state: 'regular',
      defaultValue: 'font_6',
    },
    'label-color': {
      type: 'TEXT_COLOR',
      state: 'regular',
      defaultValue: '#ff0000',
    },
    'label-letter-spacing': {
      type: 'SIZE',
      state: 'regular',
      defaultValue: '1em',
    },
    'label-line-height': {
      type: 'SIZE',
      state: 'regular',
      defaultValue: '1.4em',
    },
    'label-text-decoration': {
      type: 'CSSString',
      state: 'regular',
      defaultValue: 'none',
    },
    'label-direction': {
      type: 'CSSString',
      state: 'regular',
      defaultValue: 'ltr',
    },
    'border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'padding-bottom': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'padding-top': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'padding-left': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'padding-right': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    background: {
      type: 'BACKGROUND_FILL',
      defaultValue: '#fac313',
      state: 'regular',
    },
    'box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: '0 1px 4px rgba(0, 0, 0, .6)',
      state: 'regular',
    },
    'border-radius': {
      type: 'SIDES',
      defaultValue: '0 0 0 0',
      state: 'regular',
    },
    'border-top-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'border-top-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'border-bottom-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'border-bottom-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'button-icon-enabled': {
      type: 'CSSString',
      defaultValue: 'initial',
      state: 'regular',
    },
    'button-icon-size': {
      type: 'SIZE',
      defaultValue: '10px',
      state: 'regular',
    },
    'button-icon-color': {
      type: 'COLOR',
      defaultValue: 'rgba(0,0,255,0.5)',
      state: 'regular',
    },
    'button-icon-rotation': {
      type: 'CSSString',
      defaultValue: '0',
      state: 'regular',
    },
    size: {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
  },
};

export default generateSkinDefaults(testCompSkinDefinition);
