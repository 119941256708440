import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { CoBrandingMobileProps } from '../CoBrandingBannerMobile.types';
import { TEST_IDS } from '../constants';
import BannerWrapper from '../../CoBrandingBannerDesktop/viewer/BannerWrapper';
import defaultStyle from './styles/CoBrandingBannerMobile.scss';

const CoBrandingBannerMobile: React.FC<CoBrandingMobileProps> = props => {
  const {
    id = 'WIX_ADS',
    translations,
    brandLogoUrl,
    brandSiteUrl,
    direction,
    className,
    brandName,
    style = defaultStyle,
  } = props;

  const titleText =
    brandName === 'Dayful'
      ? translations.dayfulTitleText
      : translations.titleText;

  const titleTextWithLogo = titleText!.replace(
    '{Logo}',
    `<img data-testid="${TEST_IDS.logo}" src="${brandLogoUrl}"/>`,
  );

  return (
    <BannerWrapper
      id={id}
      {...getDataAttributes(props)}
      className={className}
      brandSiteUrl={brandSiteUrl}
      direction={direction}
      style={style}
    >
      <span
        data-testid={TEST_IDS.logoWrapper}
        className={style.logoWrapper}
        dangerouslySetInnerHTML={{ __html: titleTextWithLogo }}
      />
    </BannerWrapper>
  );
};

export default CoBrandingBannerMobile;
