import * as React from 'react';
import classnames from 'clsx';
import type { IDirection } from '../MemberLoginDialog.types';
import style from './style/style.scss';
import type { SiteMembersTranslations } from './utils';
import { MemberLoginDialogTranslationKeys as keys } from './utils';

export type HeadlessLoginDialogProps = {
  id: string;
  directionByLanguage: IDirection;
  headlineId: string;
  children: React.ReactNode;
} & SiteMembersTranslations;

export const HeadlessLoginDialog: React.FC<HeadlessLoginDialogProps> = ({
  id,
  directionByLanguage,
  headlineId,
  translations,
  children,
}) => {
  return (
    <div
      id={id}
      className={classnames(style.memberLoginContent, {
        [style.rtl]: directionByLanguage === 'rtl',
      })}
    >
      <h1 id={headlineId} className={style.title}>
        {translations[keys.headlessTitle] ?? 'Log in to your member'}
      </h1>
      <h2 className={style.ssoSubtitle}>
        {translations[keys.headlesSubtitle] ??
          'To login please send love and peace or some other content'}
      </h2>
      <div className={classnames(style.contentWrapper, style.wrapper)}>
        {children}
      </div>
    </div>
  );
};
