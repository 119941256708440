import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  ImageButtonPlatformProps,
  ImageButtonDefinition,
  ImageButtonCarmiProps,
  ImageButtonStateValues,
} from '../ImageButton.types';

export const props = withCompInfo<
  ImageButtonPlatformProps,
  ImageButtonDefinition,
  ImageButtonCarmiProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
  ],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

const stateRefs = withStateRefsValues<keyof ImageButtonStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({
  props,
  stateRefs,
});
