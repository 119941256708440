import {
  getMigratedStyleProps,
  isThemeFont,
} from '@wix/editor-elements-common-utils';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { SearchBoxCSSVars as SearchBoxCarmiCSSVars } from '@wix/thunderbolt-components';
import { LayoutType } from '../documentManagement/SearchBox.schemaType';
import {
  ISearchBoxMapperProps,
  ISearchBoxMapperCSSVars,
  ElementDisplay,
  SearchBoxDefinition,
  SearchBoxCarmiProps,
  ISearchBoxStateRefs,
} from '../SearchBox.types';
import { Border, extractBorderWidth } from './extractBorderWidth';
import { migratedFields } from './constants';
import * as Translations from './i18n.constants';

const responsiveSearchBoxSkin = 'ResponsiveSearchBox';

const searchButtonVisibilityMap: Record<LayoutType, ElementDisplay> = {
  icon: 'none',
  button: 'flex',
  'icon-only': 'flex',
};

const searchIconVisibilityMap: Record<LayoutType, ElementDisplay> = {
  icon: 'flex',
  button: 'none',
  'icon-only': 'none',
};

export const props = withCompInfo<
  ISearchBoxMapperProps,
  SearchBoxDefinition,
  SearchBoxCarmiProps
>()(
  ['direction', 'compData', 'compProps', 'isMobileView', 'compId', 'translate'],
  (
    { direction, compData, compProps, isMobileView, compId, translate },
    carmiProps,
  ) => {
    const { suggestionsEnabled, placeholder } = compData;
    const { textAlignment, trendingItemsEnabled = true, layout } = compProps;
    const useModalSuggestions = isMobileView;

    const autocompleteEnabled =
      direction !== 'rtl' &&
      (textAlignment !== 'center' ||
        (suggestionsEnabled && useModalSuggestions));

    return {
      ...carmiProps,
      compId,
      isMobileView,
      autocompleteEnabled,
      direction,
      placeholder,
      suggestionsEnabled,
      trendingItemsEnabled,
      useModalSuggestions,
      layout,
      translations: {
        clearButtonText:
          translate(
            Translations.NAMESPACE,
            Translations.CLEAR_BUTTON_LABEL_KEY,
          ) || Translations.CLEAR_BUTTON_LABEL_DEFAULT,
        numberOfSuggestionsEntries:
          translate(
            Translations.NAMESPACE,
            Translations.SUGGESTIONS_NUM_ENTRIES_KEY,
          ) || Translations.SUGGESTIONS_NUM_ENTRIES_DEFAULT,
        footerSearchAllText:
          translate(
            Translations.NAMESPACE,
            Translations.SUGGESTION_FOOTER_SEARCH_ALL_KEY,
          ) || Translations.SUGGESTION_FOOTER_SEARCH_ALL_DEFAULT,
      },
    };
  },
);
export const css = withCompInfo<
  ISearchBoxMapperCSSVars,
  SearchBoxDefinition,
  SearchBoxCarmiCSSVars
>()(
  [
    'compProps',
    'direction',
    'isMobileView',
    'styleProperties',
    'siteFontsSpxResolved',
    'isMobileView',
    'siteFonts',
    'compLayout',
    'skin',
  ],
  (compInfo, carmiCss) => {
    const {
      compProps,
      direction,
      isMobileView,
      styleProperties,
      skin,
      siteFontsSpxResolved,
      siteFonts,
    } = compInfo;
    const { textAlignment, iconAlignment, layout } = compProps;
    const migratedStyleProps = getMigratedStyleProps({
      compProps,
      styleProperties,
      migratedFields,
    });
    const { iconSpacing, textSpacing } = migratedStyleProps;
    const isCenterTextAlignment = textAlignment === 'center';
    const textPaddingEnd = isCenterTextAlignment && isMobileView ? '4px' : '0';
    const textPadding =
      direction === 'rtl'
        ? `8px 0 8px ${textPaddingEnd}`
        : `8px ${textPaddingEnd} 8px 0`;

    const iconDirection =
      !iconAlignment || iconAlignment === 'left' ? 'row' : 'row-reverse';

    const containerDirection = layout === 'button' ? 'row' : iconDirection;

    const inputFocusBottomBorderWidth = extractBorderWidth(
      styleProperties.inputFocusBorderWidth,
      Border.Bottom,
    );

    const inputLeftBorderWidth = extractBorderWidth(
      styleProperties.inputBorderWidth,
      Border.Left,
    );
    const inputHoverLeftBorderWidth = extractBorderWidth(
      styleProperties.inputHoverBorderWidth,
      Border.Left,
    );
    const inputFocusLeftBorderWidth = extractBorderWidth(
      styleProperties.inputFocusBorderWidth,
      Border.Left,
    );

    const fontVar = { '--inputFont': carmiCss['--inputFont'] };
    if (skin === responsiveSearchBoxSkin) {
      const inputFont = styleProperties.inputFont;
      fontVar['--inputFont'] = isThemeFont(inputFont)
        ? siteFontsSpxResolved?.[inputFont] ?? siteFonts[inputFont]
        : inputFont;
    }

    return {
      ...carmiCss,
      '--input-text-align': isCenterTextAlignment ? textAlignment : undefined,
      '--text-padding': textPadding,
      '--container-direction': containerDirection,
      '--icon-spacing': `${iconSpacing}px`,
      '--input-focus-bottom-border-width': inputFocusBottomBorderWidth,
      '--input-left-border-width': inputLeftBorderWidth,
      '--input-hover-left-border-width': inputHoverLeftBorderWidth,
      '--input-focus-left-border-width': inputFocusLeftBorderWidth,
      '--search-button-display': searchButtonVisibilityMap[layout] || 'none',
      '--search-icon-display': searchIconVisibilityMap[layout] || 'none',
      '--text-spacing': `${textSpacing}px`,
      '--align': textAlignment === 'center' ? 'center' : 'start',
      ...fontVar,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof ISearchBoxStateRefs>([
  'isScrollingBlocked',
  'setSiteScrollingBlocked',
  'enableCyclicTabbing',
  'disableCyclicTabbing',
  'getMenuContainerApiByChildId',
  'scopedClassName',
]);

export default createComponentMapperModel({ props, css, stateRefs });
