import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getFocusRingValue } from '@wix/editor-elements-common-utils';
import type {
  AnimationCSSVars,
  MenuCSSVars,
  SubsubmenuBoxCSSVars,
  MenuItemCSSVars,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuSdkData,
  StylableHorizontalMenuPropsCarmiData,
  StylableHorizontalMenuStateRefs,
} from '../StylableHorizontalMenu.types';

export const props = withCompInfo<
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData
>()(
  [
    'compProps',
    'compLayout',
    'compSingleLayout',
    'compStylableClass',
    'isQaMode',
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'compData',
    'trackClicksAnalytics',
    'isOneDocMigrated',
    'translate',
  ],
  (
    {
      compProps,
      compLayout,
      compSingleLayout,
      compStylableClass,
      fullNameCompType,
      isQaMode,
      compData,
      isOneDocMigrated,
      translate,
      ...restCompProps
    },
    carmiProps,
  ) => {
    const { menuMode, submenuMode, isContainerStretched } = compProps;
    const { a11y } = compData;
    const { items, containerRootClassName } = carmiProps;

    const isMenuStretched = () => {
      const itemLayout = compSingleLayout?.itemLayout;
      return (
        (itemLayout?.type === 'MeshItemLayout' ||
          itemLayout?.type === 'FixedItemLayout') &&
        itemLayout.justifySelf === 'stretch'
      );
    };

    const isHorizontallyStretched: boolean = isOneDocMigrated
      ? isMenuStretched()
      : !!(compLayout.docked?.right && compLayout.docked?.left);

    return {
      ...restCompProps,
      menuMode,
      submenuMode,
      items,
      a11y,
      containerRootClassName,
      isStretched: isHorizontallyStretched,
      isContainerStretched,
      stylableClassName: compStylableClass,
      isQaMode,
      fullNameCompType,
      navigationHint:
        translate('ariaLabels', 'menu_navigation_accessibilty_message') ||
        'To navigate this menu, use the tab key',
    };
  },
);

export const css = withCompInfo<
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuDefinition
>()(['compProps'], ({ compProps }): StylableHorizontalMenuCSSVars => {
  const { menuMode, submenuMode } = compProps;
  const isWrap = menuMode === 'wrap';
  const isFlyout = submenuMode === 'flyout';

  const anumationCSSVars: AnimationCSSVars = {
    '--is-animated': 1,
    '--position-box-z-index': 47,
  };

  const menuCSSVars: MenuCSSVars = {
    '--menu-flex-wrap': isWrap ? 'wrap' : 'nowrap',
  };

  const subsubmenuBoxCSSVars: SubsubmenuBoxCSSVars = {
    '--subsubmenu-box-display': isFlyout ? 'none' : 'block',
    '--subsubmenu-box-position': isFlyout ? 'absolute' : 'static',
  };

  const menuItemCSSVars: MenuItemCSSVars = {
    '--white-space': isFlyout ? 'nowrap' : 'normal',
    '--label-word-wrap': isFlyout ? 'normal' : 'break-word',
    '--focus-ring': getFocusRingValue(isWrap),
  };

  return {
    ...anumationCSSVars,
    ...menuCSSVars,
    ...subsubmenuBoxCSSVars,
    ...menuItemCSSVars,
    ...(isWrap && {
      height: 'auto',
      'max-height': 'unset',
    }),
  };
});

export const sdkData = withCompInfo<
  StylableHorizontalMenuSdkData,
  StylableHorizontalMenuDefinition
>()(
  ['pagesMap', 'compData', 'hasResponsiveLayout'],
  ({ pagesMap, compData, hasResponsiveLayout }) => {
    const isAdvancedMenu =
      typeof compData.menuRef === 'string'
        ? compData.menuRef !== 'CUSTOM_MAIN_MENU'
        : compData?.menuRef?.id !== 'CUSTOM_MAIN_MENU';

    return {
      pageList: pagesMap,
      isSubSubEnabled: !hasResponsiveLayout && isAdvancedMenu,
    };
  },
);

export const stateRefs = withStateRefsValues<
  keyof StylableHorizontalMenuStateRefs
>(['currentUrl', 'reportBi', 'experiments']);

export default createComponentMapperModel({ css, props, stateRefs, sdkData });
