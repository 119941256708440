import {
  withCompInfo,
  withCompPreviewInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ColumnDefinition, ColumnCarmiData, ColumnCss } from '../Column.types';
import {
  ARIA_LABEL_DEFAULT,
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../constants';

export const props = withCompInfo<
  ColumnCarmiData,
  ColumnDefinition,
  ColumnCarmiData
>()(['translate'], ({ translate }, carmiData) => {
  return {
    ...carmiData,
    translations: {
      ariaLabel:
        translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY) || ARIA_LABEL_DEFAULT,
    },
  };
});

export const css = withCompPreviewInfo<
  ColumnCss,
  ColumnDefinition,
  ColumnCss
>()([], (_, carmiData) => {
  return carmiData;
});

export default createComponentMapperModel({
  props,
  css,
});
