import React, { useContext, useEffect, useRef, useState } from 'react';
import classnames from 'clsx';

import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin'; // maybe here??
import { formatClassNames } from '@wix/editor-elements-common-utils';
import StylesContext from '../StylesContext';
import { ToolbarProps } from '../../RichTextBox.types';
import semanticClassNames from '../../RichTextBox.semanticClassNames';
import Separator from './separator/Separator';
import Scroller from './scroller/Scroller';
import BoldButton from './buttons/BoldButton';
import UnderlineButton from './buttons/UnderlineButton';
import ItalicButton from './buttons/ItalicButton';
import TextSizeButton from './buttons/TextSizeButton';
import OrderedListButton from './buttons/OrderedListIcon';
import UnorderedListButton from './buttons/UnorderedListIcon';
import LinkButtonInline from './buttons/LinkButton/Inline';
import LinkButtonStatic from './buttons/LinkButton/Static';
import TextAlignButtonsStatic from './buttons/TextAlignButtons/Static';
import TextAlignButtonsInline from './buttons/TextAlignButtons/Inline';

import styles from './toolbar.scss';

const Toolbar: React.FC<ToolbarProps> = ({
  type,
  ToolbarInstance,
  allowLinks,
  linkModalId,
  openMobileLinkModalTemp,
  closeMobileLinkModalTemp,
  onOverrideDialogClose,
  onOverrideDialogOpen,
  scopedClassName,
}) => {
  const [scrollerDirection, setScrollerDirection] = useState('right');
  const stylesContext = useContext(StylesContext);
  useEffect(() => {
    // The styles should be loaded async, otherwise the "scrollWidth" and the "clientWidth" are equal and the scroll button does not render
    async function loadStyles() {
      const importStaticCss = () =>
        import('draft-js-static-toolbar-plugin/lib/plugin.css');
      const importInlineCss = () =>
        import('draft-js-inline-toolbar-plugin/lib/plugin.css');

      if (type === 'top' || type === 'bottom') {
        await importStaticCss();
      } else {
        await importInlineCss();
      }

      if (
        actionButtonsContainerRef.current!.scrollWidth >
        actionButtonsContainerRef.current!.clientWidth
      ) {
        setScrollerVisible(true);
      }
    }

    void loadStyles();
  }, [type]);

  const actionButtonsContainerRef = useRef<HTMLDivElement>(null);

  const [scrollerVisible, setScrollerVisible] = useState(false);

  const scroll = () => {
    const element = actionButtonsContainerRef!.current!;
    const scrollWidth = actionButtonsContainerRef!.current!.scrollWidth;
    if (scrollerDirection === 'right') {
      element.scrollLeft += scrollWidth;
      setScrollerDirection('left');
    } else {
      element.scrollLeft -= scrollWidth;
      setScrollerDirection('right');
    }
  };

  const TextAlignButtons = (externalProps: ToolbarChildrenProps) => {
    return type === 'inline' ? (
      <TextAlignButtonsInline {...externalProps} />
    ) : (
      <TextAlignButtonsStatic {...externalProps} />
    );
  };

  const LinkButton = (externalProps: ToolbarChildrenProps) => {
    return type === 'inline' ? (
      <LinkButtonInline {...externalProps} />
    ) : (
      <LinkButtonStatic
        {...externalProps}
        type={type}
        linkModalId={linkModalId}
        openMobileLinkModalTemp={openMobileLinkModalTemp}
        closeMobileLinkModalTemp={closeMobileLinkModalTemp}
        onOverrideDialogClose={onOverrideDialogClose}
        onOverrideDialogOpen={onOverrideDialogOpen}
        scopedClassName={scopedClassName}
      />
    );
  };

  return (
    <ToolbarInstance>
      {(externalProps: ToolbarChildrenProps) => (
        <div
          className={classnames(
            styles.toolbar,
            formatClassNames(semanticClassNames.toolbar),
            {
              [styles.hovered]: stylesContext.hovered,
              [styles.focused]: stylesContext.focused,
              [styles.error]: stylesContext.error,
              [styles.disabled]: stylesContext.disabled,
              [styles.bottomToolbar]: type === 'bottom',
              [styles.topToolbar]: type === 'top',
              [styles.inlineToolbar]: type === 'inline',
            },
          )}
        >
          <div
            ref={actionButtonsContainerRef}
            className={styles.actionButtonsContainer}
          >
            <BoldButton {...externalProps} />
            <ItalicButton {...externalProps} />
            <UnderlineButton {...externalProps} />
            <TextSizeButton {...externalProps} />
            <Separator />
            {TextAlignButtons(externalProps)}
            <OrderedListButton {...externalProps} />
            <UnorderedListButton {...externalProps} />
            {allowLinks && (
              <>
                <Separator /> {LinkButton(externalProps)}
              </>
            )}
          </div>
          {scrollerVisible && (
            <Scroller direction={scrollerDirection} onClick={scroll} />
          )}
        </div>
      )}
    </ToolbarInstance>
  );
};

export default Toolbar;
