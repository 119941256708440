
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TestComp2928943396",
      {"classes":{"root":"TestComp2928943396__root","text":"TestComp2928943396__text"},"vars":{"display":"--display","text-color":"--TestComp2928943396-text-color"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  