import * as React from 'react';
import SiteButton from '../../SiteButton';
import type { SkinButtonProps } from '../SkinButton.types';
import type {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import ButtonArrowBaseSkin from './ButtonArrow';
import skinsStyle from './styles/ButtonArrowLeft.scss';

const ButtonArrowLeftSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>((props, ref) => (
  <ButtonArrowBaseSkin
    {...props}
    skinsStyle={skinsStyle}
    ref={ref}
  ></ButtonArrowBaseSkin>
));

const ButtonArrowLeft: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ButtonArrowLeftSkin} ref={ref} />
);

export default React.forwardRef(ButtonArrowLeft);
