import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FiveGridLineProps,
  FiveGridLineDefinition,
  FiveGridLineCSSVars,
} from '../FiveGridLine.types';

/**
 * This mapper makes sure horizontalLine will be affected by the css editing mappers
 */
export const props = withCompInfo<
  FiveGridLineProps,
  FiveGridLineDefinition,
  FiveGridLineProps
>()([], (_, carmiData) => {
  return {
    ...carmiData,
  };
});

const studioSkins = ['SolidLineStudio', 'DashedLineStudio', 'DottedLineStudio'];

export const css = withCompInfo<
  FiveGridLineCSSVars,
  FiveGridLineDefinition,
  FiveGridLineCSSVars
>()(
  ['editorName', 'skin', 'experiments'],
  ({ editorName, skin, experiments }, carmiCss) => {
    const applyNewDesign = experiments[
      'specs.thunderbolt.fiveGridLineStudioSkins'
    ]
      ? studioSkins.includes(skin)
      : editorName === 'Studio';
    return {
      ...carmiCss,
      ...(applyNewDesign
        ? { height: '0 !important', 'box-sizing': 'content-box' }
        : {}),
    };
  },
);

const knownSkins = [
  'ArrowLine',
  'ArrowRightLine',
  'FiveGridLineSkin',
  'DashedLine',
  'DottedLine',
  'DoubleLine',
  'FadeNotchBottomLine',
  'FadeNotchTopLine',
  'FiveGridLine',
  'IronLine',
  'NotchDashedLine',
  'NotchLine',
  'ShadowBottomLine',
  'ShadowTopLine',
  'SloppyLine',
  'SolidLine',
  'ZigzagLineFlipSkin',
  'ZigzagLineSkin',
  // For studio
  ...studioSkins,

  // Deprecated Skins
  'FadeLine',
  'DoubleLine2',
  'DoubleLine3',
];
export const uiType = withCompInfo<any, any>()(['skin'], ({ skin }) => {
  return knownSkins.includes(skin) ? skin : 'SkinNotFound';
});

export default createComponentMapperModel({ props, css, uiType });
