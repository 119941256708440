
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IRangeSliderProps,
  IRangeSliderMapperProps,
  IRangeSliderControllerProps,
  IRangeSliderStateValues,
} from '../../../components/RangeSlider/RangeSlider.props';

export default withCompController<
  IRangeSliderMapperProps,
  IRangeSliderControllerProps,
  IRangeSliderProps,
  IRangeSliderStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
