import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { MediaContainerProps } from '@wix/editor-elements-definitions';
import {
  ARIA_LABEL_NAMESPACE,
  ARIA_LABEL_KEY,
  ARIA_LABEL_DEFAULT,
} from './constants';

export const props = withCompInfo<
  MediaContainerProps,
  any,
  MediaContainerProps
>()(['translate'], ({ translate }, carmiProps) => {
  return {
    ...carmiProps,
    translations: {
      ariaLabel:
        translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY) || ARIA_LABEL_DEFAULT,
    },
  };
});

export default createComponentMapperModel({ props });
