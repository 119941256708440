import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';
import { useResizeObserver } from '@wix/thunderbolt-elements/src/providers/useResizeObserver/useResizeObserver';
import styles from '../../style/WRichText.previewWrapper.scss';

export const useResizeObserverForRichText = (
  contentRef: RefObject<any>,
  wrapperRef: RefObject<any>,
  {
    isFitToHeight,
    getContentHeight,
  }: {
    isFitToHeight: boolean;
    getContentHeight: () => number;
  },
) => {
  useEffect(() => {
    if (isFitToHeight) {
      contentRef.current?.classList.remove(styles.fitToLayout);
    } else {
      contentRef.current?.classList.add(styles.fitToLayout);
      wrapperRef.current?.style.removeProperty('--height');
    }
  }, [isFitToHeight, contentRef, wrapperRef]);

  const callback = useCallback(() => {
    if (isFitToHeight) {
      const textHeight = getContentHeight();

      /**
       *  Validating the height and width are not zero, in order
       * to solve an issue: When there is text in header/footer,
       * then hiding the header and footer from the page, then
       * bringing them back, after that the text was displayed
       * wrong because the height and width were 0, so ignoring
       * them fixes this issue
       */
      if (textHeight > 0) {
        wrapperRef.current?.style.setProperty('--height', `${textHeight}px`);
      }
    }
  }, [wrapperRef, isFitToHeight, getContentHeight]);

  useResizeObserver({
    callback,
    ref: contentRef,
  });
};
