import * as React from 'react';
import { VerticalLineProps } from '../VerticalLine';
import { IVerticalLineProps } from '../../../VerticalLine.types';
import VerticalLine from '../../VerticalLine';
import skinsStyle from './styles/VerticalShadowLeftLine.scss';
import { BaseVerticalLine } from './BaseVerticalLine';

const VerticalShadowLeftLineSkin: React.FC<VerticalLineProps> = props => (
  <BaseVerticalLine {...props} skinsStyle={skinsStyle} />
);

const VerticalShadowLeftLine: React.FC<
  Omit<IVerticalLineProps, 'skin'>
> = props => {
  return <VerticalLine {...props} skin={VerticalShadowLeftLineSkin} />;
};

export default VerticalShadowLeftLine;
