import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type { MediaContainerVideoAPI } from '@wix/editor-elements-types/components';
import { HoverBoxCompProps } from '../HoverBox.types';

export function withComponentPreview(
  ViewerComponent: React.ComponentType<HoverBoxCompProps>,
) {
  return React.forwardRef<MediaContainerVideoAPI, HoverBoxCompProps>(
    (viewerProps: PreviewWrapperProps<HoverBoxCompProps>, ref) => {
      return <ViewerComponent ref={ref} {...viewerProps} />;
    },
  );
}

export default (ViewerComponent: React.ComponentType<HoverBoxCompProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
