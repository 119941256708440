export default (prefix: string, separator: string = '_') =>
  <NamespaceType extends string, KeyType extends string>(
    namespace: NamespaceType,
    keys: Array<KeyType>,
  ): Record<NamespaceType, Record<KeyType, string>> => {
    const scopedKeys = keys.reduce((acc: { [k: string]: string }, key) => {
      acc[key] = `${prefix}${separator}${namespace}${separator}${key}`;
      return acc;
    }, {});

    return { [namespace]: scopedKeys } as Record<
      string,
      Record<KeyType, string>
    >;
  };
