import type { Language } from '@wix/thunderbolt-becky-types';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PayPalButtonDefinition,
  PayPalButtonMapperProps,
  PayPalButtonStateRefs,
} from '../PayPalButton.types';
import {
  BUY_BUTTON_LABEL,
  DONATE_BUTTON_LABEL,
  LOCALES,
  translationViewerFeatureName,
  TranslationKeys,
} from '../constants';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';

const useRedirectFlowSpec = 'specs.thunderbolt.PayPalButtonRedirectFlow';

const getLocale = (propLang: string, userLang: Language) => {
  const lang = (propLang === 'userLang' ? userLang : propLang).toLowerCase();
  return LOCALES[lang] || LOCALES.en;
};

const getParamsBasedOnButtonType = (
  compProps: PayPalButtonDefinition['property'],
) => {
  return {
    buy: {
      itemName: compProps.itemName,
      itemNumber: compProps.itemID,
      submitImageAriaLabel: BUY_BUTTON_LABEL,
    },
    donate: {
      itemName: compProps.organizationName,
      itemNumber: compProps.organizationID,
      submitImageAriaLabel: DONATE_BUTTON_LABEL,
    },
  }[compProps.buttonType];
};

const getAmount = (amount: string) => {
  if (amount && Number(amount) > 0) {
    return amount;
  }
  return '';
};

const getSubmitImgSrc = (
  locale: { image: string; cc: string; pixel: string },
  compProps: PayPalButtonDefinition['property'],
) => {
  const buttonType = compProps.buttonType === 'buy' ? 'buynow' : 'donate';
  const showCreditCards =
    compProps.showCreditCards && !compProps.smallButton ? 'CC' : '';
  const buttonSize = compProps.smallButton ? '_SM' : '_LG';
  const localeString =
    compProps.showCreditCards && !compProps.smallButton
      ? locale.image + locale.cc
      : locale.image;
  return `https://www.paypalobjects.com/${localeString}/i/btn/btn_${buttonType}${showCreditCards}${buttonSize}.gif`;
};

export const props = withCompInfo<
  PayPalButtonMapperProps,
  PayPalButtonDefinition
>()(
  [
    'compData',
    'compProps',
    'language',
    'metaSiteId',
    'isMobileView',
    'direction',
    'translate',
    'compId',
    'fullNameCompType',
    'trackClicksAnalytics',
    'experiments',
  ],
  ({
    compData,
    compProps,
    language,
    metaSiteId,
    isMobileView,
    direction,
    compId,
    fullNameCompType,
    trackClicksAnalytics,
    translate,
    experiments,
  }) => {
    const locale = getLocale(compProps.language, language);
    const { itemName, itemNumber, submitImageAriaLabel } =
      getParamsBasedOnButtonType(compProps);
    const { buttonType } = compProps;
    const amount = getAmount(compProps.amount);

    const shouldUseRedirectFlow = isExperimentOpen(
      experiments,
      useRedirectFlowSpec,
    );

    const translationKeys = [
      ...Object.values(TranslationKeys.errorDialog),
      ...Object.values(TranslationKeys.DonateModal),
      ...Object.values(TranslationKeys.BuyModal),
    ];
    const translations: { [key: string]: string | undefined } = {};
    translationKeys.forEach(
      key => (translations[key] = translate(translationViewerFeatureName, key)),
    );

    return {
      compId,
      language,
      fullNameCompType,
      formTarget: compProps.target,
      itemName,
      itemNumber,
      business: compData.merchantID,
      currencyCode: compProps.currencyCode,
      amount,
      trackingPixelImgSrc: `https://www.paypalobjects.com/${locale.pixel}/i/scr/pixel.gif`,
      submitImageSrc: getSubmitImgSrc(locale, compProps),
      submitImageAriaLabel,
      metaSiteId,
      locale: locale.pixel.replace('_', '-'),
      buttonType,
      isMobileView,
      direction,
      translations,
      trackClicksAnalytics,
      shouldUseRedirectFlow,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof PayPalButtonStateRefs>([
  'getVisitorId',
  'userId',
  'currentUrlWithQueryParams',
  'getAppInstanceByAppDefId',
  'reportBi',
]);

export default createComponentMapperModel({ props, stateRefs });
