const flexDirectionTypes = {
  row: 'row',
  'row-reverse': 'row-reverse',
  center: 'center',
};

const alignmentTypes = {
  left: 'left',
  right: 'right',
  center: 'center',
};

export const getSpacing = (elementPosition: string, elementSpacing: number) => {
  switch (elementPosition) {
    case 'top':
      return `0 0 ${elementSpacing}px 0`;
    case 'right':
      return `0 0 0 ${elementSpacing}px`;
    case 'bottom':
      return `${elementSpacing}px 0 0 0`;
    case 'left':
      return `0 ${elementSpacing}px 0 0`;
    default:
      return 0;
  }
};

export const getFlexDirection = (
  layoutAlignment: string,
  elementsDirection: string,
) => {
  let flexDirection = 'column';

  if (layoutAlignment === 'horizontal') {
    flexDirection = elementsDirection === 'ltr' ? 'row' : 'row-reverse';
  }

  return flexDirection;
};

export const getAlignmentBasedFlexDirection = (
  flexDirection: string,
  alignmentDirection: string,
) => {
  const flow =
    alignmentDirection === 'center' ? flexDirectionTypes.center : flexDirection;
  switch (flow) {
    case flexDirectionTypes.row: {
      return {
        'justify-content':
          alignmentDirection === alignmentTypes.left
            ? 'flex-start'
            : 'flex-end',
        'align-items': 'center',
      };
    }
    case flexDirectionTypes['row-reverse']: {
      return {
        'justify-content':
          alignmentDirection === alignmentTypes.left
            ? 'flex-end'
            : 'flex-start',
        'align-items': 'center',
      };
    }
    case flexDirectionTypes.center: {
      return {
        'justify-content': 'center',
        'align-items': 'center',
      };
    }
    default: {
      return {
        'justify-content': 'center',
        'align-items':
          alignmentDirection === alignmentTypes.left
            ? 'flex-start'
            : 'flex-end',
      };
    }
  }
};
