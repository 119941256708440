import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  props as stylableButtonMapperProps,
  sdkData,
  stateRefs,
} from '../../../StylableButton/viewer/StylableButton.mapper';
import type {
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps,
} from '../../../StylableButton/StylableButton.types';
import { HAMBURGER_SANTA_VIEWER_NAMESPACE } from '../../consts';
import { translationKeys } from './constants';

const props = composeMapper<
  StylableButtonPlatformProps,
  StylableButtonDefinition,
  StylableButtonCarmiProps
>()(
  stylableButtonMapperProps,
  { add: ['translate'] },
  (resolver, deps, carmiData) => {
    const { translate, ...viewerDeps } = deps;
    const viewerProps = resolver(viewerDeps, carmiData);
    return {
      ...viewerProps,
      ariaLabel: translate(
        HAMBURGER_SANTA_VIEWER_NAMESPACE,
        translationKeys.ariaLabel,
      ),
    };
  },
);

export const css = withCompInfo()([], () => {
  return {
    height: 'inherit',
    width: 'inherit',
  };
});

export default createComponentMapperModel({
  props,
  css,
  sdkData,
  stateRefs,
});
