import React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { ISpotifyPlayerProps } from '../SpotifyPlayer.types';
import { A11Y_TITLE } from '../constants';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import style from './style/SpotifyPlayer.scss';

const SpotifyPlayer: React.FC<ISpotifyPlayerProps> = props => {
  const {
    id,
    className,
    iframeSrc,
    translations,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const ariaLabel = translations.ariaLabel || A11Y_TITLE;

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className,
    ...getDataAttributes(props),
  });
  return (
    <wix-iframe
      {...baseComponentProps}
      title={A11Y_TITLE}
      data-src={iframeSrc}
      aria-label={ariaLabel}
      tabIndex={0}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <iframe
        className={classNames(style.iframe, {
          [style.displayNone]: iframeSrc === null,
        })}
        style={{ width: '100%', height: `calc(100% - 2px)` }}
        title={A11Y_TITLE}
        data-src={iframeSrc}
        scrolling="no"
      />
      <div className={style.placeholder} />
    </wix-iframe>
  );
};

export default SpotifyPlayer;
