import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import type {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
} from '../FreemiumBannerDesktop.types';
import translationKeys from './constants';

export const uiType = withCompInfo<any, FreemiumBannerDesktopDefinition>()(
  ['experiments'],
  ({ experiments }) => {
    const isNewLogo = isExperimentOpen(
      experiments,
      'specs.thunderbolt.WixFreeSiteBannerDesktop',
    );
    return isNewLogo ? 'FreemiumBannerDesktopNewLogo' : undefined;
  },
);

export const props = withCompInfo<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition
>()(
  ['direction', 'metaSiteId', 'translate', 'experiments'],
  ({ direction, metaSiteId, translate, experiments }) => {
    const isNewLogo = isExperimentOpen(
      experiments,
      'specs.thunderbolt.WixFreeSiteBannerDesktop',
    );

    const bannerText = isNewLogo
      ? translate(
          translationKeys.NAMESPACE,
          translationKeys.MAIN_TEXT_KEY_NEW,
        ) || translationKeys.MAIN_TEXT_DEFAULT_NEW
      : translate(translationKeys.NAMESPACE, translationKeys.MAIN_TEXT_KEY) ||
        translationKeys.MAIN_TEXT_DEFAULT;

    const buttonText = isNewLogo
      ? translate(
          translationKeys.NAMESPACE,
          translationKeys.BUTTON_TEXT_KEY_NEW,
        ) || translationKeys.BUTTON_TEXT_DEFAULT_NEW
      : translate(translationKeys.NAMESPACE, translationKeys.BUTTON_TEXT_KEY) ||
        translationKeys.BUTTON_TEXT_DEFAULT;

    return {
      useOverlay: false,
      direction,
      href: `//www.wix.com/lpviral/enviral?utm_campaign=vir_wixad_live&adsVersion=white&orig_msid=${metaSiteId}`,
      classNames: [direction],
      translations: {
        bannerText,
        buttonText,
      },
    };
  },
);
export default createComponentMapperModel({
  props,
  uiType,
});
