import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import DiagonalMenuButtonSkin from '../../../../MenuButton/viewer/skinComps/DiagonalMenuButtonSkin/DiagonalMenuButtonSkin';
import styles from './DiagonalMenuSkin.scss';

const DiagonalMenuSkin: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuProps
> = (props, ref) => {
  return (
    <DropDownMenuBase
      {...props}
      ref={ref}
      styles={styles}
      Button={DiagonalMenuButtonSkin}
    />
  );
};

export default React.forwardRef(DiagonalMenuSkin);
