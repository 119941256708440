import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  YouTubeSubscribeButtonProps,
  YouTubeSubscribeButtonMapperProps,
  YouTubeSubscribeButtonStateRefValues,
  YouTubeSubscribeButtonControllerProps,
} from '../YouTubeSubscribeButton.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

export default withCompController<
  YouTubeSubscribeButtonMapperProps,
  YouTubeSubscribeButtonControllerProps,
  YouTubeSubscribeButtonProps,
  YouTubeSubscribeButtonStateRefValues
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});
