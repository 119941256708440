import * as React from 'react';
import {
  getAriaAttributes,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import classNames from 'clsx';
import type { IMultiStateBoxProps } from '../MultiStateBox.types';
import semanticClassNames from '../MultiStateBox.semanticClassNames';
import ResponsiveBox from '../../ResponsiveBox/viewer/ResponsiveBox';
import { useMSBStates } from './useMSBStates';
import styles from './style/MultiStateBox.scss';

export const REPEATER_DELIMITER = '__';

// cut repeater delimiter
const getBaseStateId = (stateId: string | undefined) => {
  const [baseId] = stateId?.split(REPEATER_DELIMITER) ?? [];
  return baseId;
};

const getDefaultContainerProps = (id: string) => ({
  // TODO - wire correctly
  containerLayoutClassName: `container-${id}`,
  // TODO - wire correctly
  overlowWrapperClassName: `overflow-wrapper-${id}`,
  // TODO - wire correctly
  hasOverflow: true,
});

const MultiStateBox: React.FC<IMultiStateBoxProps> = props => {
  const {
    id,
    className,
    customClassNames = [],
    children,
    renderContainer,
    selectedStateId,
    a11y = {},
    ariaAttributes,
    containerProps,
    role,
    containerRootClassName,
    onChange,
    onFocus,
    ...rest
  } = props;
  const states = useMSBStates({
    children,
    getStateId: state => getBaseStateId(state.props.id),
    selectedStateId: getBaseStateId(selectedStateId),
    onChange,
  });

  const a11yProps = getAriaAttributes({
    ...a11y,
    ...ariaAttributes,
  });

  return renderContainer ? (
    renderContainer({ state: states })
  ) : (
    <ResponsiveBox
      {...getDataAttributes(props)}
      shouldUseContainerLayoutClass={true}
      role={role}
      containerRootClassName={classNames(
        styles.root,
        containerRootClassName,
        className,
      )}
      hasPlatformClickHandler={false}
      containerProps={containerProps || getDefaultContainerProps(id)}
      id={id}
      onFocus={onFocus}
      a11y={a11yProps}
      customClassNames={customClassNames}
      semanticClassNames={semanticClassNames}
      {...rest}
    >
      {() => states}
    </ResponsiveBox>
  );
};

export default MultiStateBox;
