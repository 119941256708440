import { createComponentMapperModel } from '@wix/editor-elements-integrations';
import {
  props,
  css,
  sdkData,
  stateRefs,
} from '../../../StylableButton/viewer/StylableButton.mapper';

export default createComponentMapperModel({
  stateRefs,
  props,
  css,
  sdkData,
});
