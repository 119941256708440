
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IPopoverMenuProps,
  IPopoverMenuMapperProps,
  IPopoverMenuControllerProps,
  IPopoverMenuStateValues,
} from '../../../components/PopoverMenu/PopoverMenu.props';

export default withCompController<
  IPopoverMenuMapperProps,
  IPopoverMenuControllerProps,
  IPopoverMenuProps,
  IPopoverMenuStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
