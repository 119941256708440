import {
  withCompPreviewProps,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type { CompInfoDependency } from '@wix/editor-elements-types/thunderbolt';
import {
  Tpa3DGalleryDefinition,
  Tpa3DGalleryPropsCarmiData,
  TPA3DGalleryPropsPlatformProps,
} from '../TPA3DGallery.types';
import { props as viewerPropsMapper } from './TPA3DGallery.mapper';

const viewerDeps = Object.keys(
  viewerPropsMapper.deps,
) as Array<CompInfoDependency>;

const props = withCompPreviewProps<
  TPA3DGalleryPropsPlatformProps,
  Tpa3DGalleryDefinition,
  Tpa3DGalleryPropsCarmiData
>()(['isPlayingAllowed', ...viewerDeps], (deps, carmiData) => {
  const viewerProps = viewerPropsMapper.resolver(deps, carmiData);
  return {
    ...viewerProps,
    isPlayingAllowed: deps.isPlayingAllowed,
  };
});

export default createComponentPreviewMapperModel({
  props: props as any,
});
