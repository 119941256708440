import { withCompController } from '@wix/editor-elements-integrations';
import {
  PagesToShare,
  SHOW_TOOLTIP_ON_COPY_LINK_BUTTON_SPEC,
} from '../constants';
import type {
  IShareButtonsMapperProps,
  IShareButtonsStateRefs,
  IShareButtonsViewerProps,
} from '../types';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';

export default withCompController<
  IShareButtonsMapperProps,
  IShareButtonsMapperProps,
  IShareButtonsViewerProps,
  IShareButtonsStateRefs
>(({ mapperProps, stateValues }) => {
  const { currentUrlWithQueryParams } = stateValues;
  const { pageToShare, externalBaseUrl } = mapperProps;

  const shareUrlByPage: { [key: string]: string } = {
    [PagesToShare.home]: externalBaseUrl,
    [PagesToShare.current]: currentUrlWithQueryParams,
  };
  const isCustomUrl = !shareUrlByPage[pageToShare];
  const shareUrl = isCustomUrl ? pageToShare : shareUrlByPage[pageToShare];

  return {
    ...mapperProps,
    shareUrl,
    isTooltipForCopyToClipboardEnabled: isExperimentOpen(
      stateValues.experiments,
      SHOW_TOOLTIP_ON_COPY_LINK_BUTTON_SPEC,
    ),
  };
});
