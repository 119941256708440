import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getHeightCSS } from '@wix/editor-elements-common-utils';
import {
  TextInputDefinition,
  ITextInputMapperProps,
  TextInputCSSVars,
  TextInputPreviewCSSVars,
} from '../TextInput.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './TextInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<ITextInputMapperProps>,
  TextInputDefinition
>()(
  viewerPropsMapper,
  {
    add: [
      'shouldResetComponent',
      'componentViewMode',
      'compPreviewState',
      'hasResponsiveLayout',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      shouldResetComponent,
      componentViewMode,
      compPreviewState,
      hasResponsiveLayout,
    } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
        hasResponsiveLayout,
      },
    };
  },
);

const css = composeMapper<
  TextInputPreviewCSSVars | TextInputCSSVars,
  TextInputDefinition
>()(
  viewerCssMapper,
  { add: ['compLayout', 'compSingleLayout'] },
  (resolver, dependencies, carmiData) => {
    const { compLayout, compSingleLayout, hasResponsiveLayout } = dependencies;

    const viewerCss = resolver(dependencies, carmiData);

    if (hasResponsiveLayout) {
      return { ...viewerCss };
    }

    const heightCSS = getHeightCSS(compLayout, compSingleLayout);

    return {
      ...viewerCss,
      '--height': heightCSS,
      height: 'var(--height)',
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
