import type { JSONSchema4, ValidationError } from 'json-schema';
import { validate } from 'json-schema';
import lottieWebAnimationJson from 'lottie-web/docs/json/animation.json';

const validationSchema = lottieWebAnimationJson as JSONSchema4;
validationSchema.additionalProperties = { meta: {}, markers: [] };

export const isValidLottieJson = (json: any): Array<ValidationError> => {
  return validate(json, validationSchema).errors;
};

export const addSelfDestructingEventListener = (
  element: { removeEventListener: Function; addEventListener: Function },
  eventType: string,
  callback: Function,
) => {
  const handler = () => {
    callback();
    element.removeEventListener(eventType, handler);
  };
  element.addEventListener(eventType, handler);
};

const urlRegExp =
  /^(?:(?:https?:)\/\/)(?:(?:[\u0400-\uA69F\w][\u0400-\uA69F\w-]*)?[\u0400-\uA69F\w]\.)+(?:[\u0400-\uA69Fa-z]+|\d{1,3})(?::[\d]{1,5})?(?:[/?#].*)?$/i;

export const isLottieUrl = (url: string) => urlRegExp.test(url);

export const calcNewDemensions = (
  prev: { w: number; h: number },
  next: { w: number; h: number },
  pageWidth: number,
) => {
  let finalWidth, finalHeight;
  finalWidth = next.w * (prev.h / next.h);
  finalHeight = prev.h;
  if (finalWidth > pageWidth) {
    finalHeight = finalHeight * (pageWidth / finalWidth);
    finalWidth = pageWidth;
  }
  return { width: finalWidth, height: finalHeight };
};
