import {
  withCompInfo,
  createComponentPreviewMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import type {
  IMenuContainerMapperProps,
  MenuContainerDefinition,
  MenuContainerCarmiCssVars,
  IMenuContainerCssVars,
  IMenuContainerStateRefs,
} from '../MenuContainer.types';

const ARIA_LABEL_NAMESPACE = 'AriaLabels';
const CONTAINER_ARIA_LABEL_KEY =
  'Mobile_Hamburger_Menu_AriaLabel_MenuContainer';
const CONTAINER_ARIA_LABEL_DEFAULT = 'Site navigation';

const props = withCompInfo<
  IMenuContainerMapperProps,
  MenuContainerDefinition,
  IMenuContainerMapperProps
>()(
  ['isMobileView', 'compId', 'translate'],
  ({ isMobileView, compId, translate }, carmiData) => ({
    ...carmiData,
    isMobileView,
    compId,
    translations: {
      containerAriaLabel:
        translate(ARIA_LABEL_NAMESPACE, CONTAINER_ARIA_LABEL_KEY) ||
        CONTAINER_ARIA_LABEL_DEFAULT,
    },
  }),
);

const css = withCompInfo<
  IMenuContainerCssVars,
  MenuContainerDefinition,
  MenuContainerCarmiCssVars
>()([], (_, carmiData) => ({
  ...carmiData,
  '--screen-width': '100vw',
}));

export const stateRefs = withStateRefs<IMenuContainerStateRefs>()(
  ['refApi', 'compId'],
  ({ refApi, compId }) => {
    return {
      isOpen: refApi.menuContainer.isOpen(compId),
      isVisible: refApi.menuContainer.isVisible(compId),
      toggle: refApi.menuContainer.toggle(compId),
      open: refApi.menuContainer.open(compId),
      close: refApi.menuContainer.close(compId),
      animate: refApi.menuContainer.animate(compId),
    };
  },
);

export default createComponentPreviewMapperModel({ props, css, stateRefs });
