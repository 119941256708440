import React from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type {
  IVerticalAnchorsMenuProps,
  ILinkContent,
} from '../VerticalAnchorsMenu.types';
import semanticClassNames from '../VerticalAnchorsMenu.semanticClassNames';
import VerticalAnchorsMenuCommonSkin from './skinComps/VerticalAnchorsMenuCommonSkin';
import styles from './styles/VerticalAnchorsMenu.scss';

const LinkContent = (props: ILinkContent) => {
  const { label, id } = props;
  return (
    <span
      id={id}
      className={classNames(
        styles.label,
        formatClassNames(semanticClassNames.itemLabel),
      )}
    >
      {label}
    </span>
  );
};

const VerticalAnchorsMenuTextSkin: React.FC<
  IVerticalAnchorsMenuProps
> = props => {
  return (
    <VerticalAnchorsMenuCommonSkin
      {...props}
      style={styles}
      skin="VerticalAnchorsMenuTextSkin"
      linkContent={LinkContent}
    />
  );
};

export default VerticalAnchorsMenuTextSkin;
