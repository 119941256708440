import React from 'react';
import { keyCodes } from '@wix/editor-elements-common-utils';
import type { INavButtonProps } from '../../Pagination.types';
import { st, classes } from './style/NavButton.st.css';

const NavButton: React.FC<INavButtonProps> = ({
  testId,
  className,
  children,
  onChange,
  isDisabled: disabled,
  ariaLabel,
  url,
}) => {
  const onKeyDownHandler = (event: React.KeyboardEvent) => {
    const { keyCode } = event;
    if (keyCode === keyCodes.enter || keyCode === keyCodes.space) {
      event.preventDefault(); // prevents viewer from scrolling after keyboard press
      !disabled && onChange();
    }
  };

  const onClickHandler = (event: React.MouseEvent) => {
    event.preventDefault();
    !disabled && onChange();
  };

  return (
    <a
      data-testid={testId}
      className={st(classes.root, { disabled }, className)}
      aria-label={ariaLabel}
      aria-disabled={disabled}
      {...(!disabled && {
        tabIndex: 0,
      })}
      onClick={onClickHandler}
      onKeyDown={onKeyDownHandler}
      href={url}
    >
      {children}
    </a>
  );
};

export default NavButton;
