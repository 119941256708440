import * as React from 'react';
import type { MouseEvent, KeyboardEvent } from 'react';
import {
  getDataAttributes,
  performOnEnter,
} from '@wix/editor-elements-common-utils';
import type { ISiteMembersInputRef } from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/SiteMembersInput';
import SiteMembersInput from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/SiteMembersInput';
import SiteMembersDialogLayout from '@wix/thunderbolt-elements/src/components/SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import {
  serverErrorsHandler,
  validateSiteMembersEmail,
} from '@wix/thunderbolt-elements/src/components/SiteMembersInput/viewer/utils';
import type { IRequestPasswordResetDialogProps } from '../RequestPasswordResetDialog.types';
import BasicButton from '../../SiteButton/viewer/skinComps/BaseButton/BasicButton.skin';
import style from './style/RequestPasswordResetDialog.scss';
import { RequestPasswordResetDialogTranslationKeys as keys } from './constants';

const RequestPasswordResetDialog: React.FC<
  IRequestPasswordResetDialogProps
> = props => {
  const {
    id,
    className,
    isCloseable,
    onCloseDialogCallback,
    onSubmitCallback,
    directionByLanguage = 'ltr',
    translations,
  } = props;

  const [email, setEmail] = React.useState('');

  const headlineId = `requestPasswordResetHeadline_${id}`;

  const titleText = translations[keys.title];
  const pleaseEnterEmailText = translations[keys.pleaseEnterEmail];
  const submitButtonText = translations[keys.resetPasswordButton]!;

  const emailText = {
    title: translations[keys.email.title],
    label: translations[keys.email.label],
  };
  const emailRef = React.useRef<ISiteMembersInputRef>(null);
  const submitForm = async (e: MouseEvent | KeyboardEvent) => {
    e.preventDefault();
    if (emailRef.current!.validate(translations)) {
      try {
        await onSubmitCallback(email);
      } catch (error: any) {
        const errorMsg = serverErrorsHandler(error);
        const defaultErrorMsg = translations.membersErrorGeneral;
        emailRef.current!.setError(translations[errorMsg] ?? defaultErrorMsg);
      }
    }
  };

  const onKeyDownHandler = performOnEnter(submitForm);

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      translations={translations}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
    >
      <div id={id} className={style.requestPasswordResetDialog}>
        <h3 id={headlineId} className={style.title} data-testid="title">
          {titleText}
        </h3>
        <form className={style.wrapper} onKeyDown={onKeyDownHandler}>
          <div data-testid="enterEmailSubtitle" className={style.subtitle}>
            {pleaseEnterEmailText}
          </div>
          <div className={style.content}>
            <SiteMembersInput
              id={`emailInput_${id}`}
              inputType="email"
              data-testid="emailInput"
              value={email}
              label={emailText.label}
              onValueChanged={setEmail}
              ref={emailRef}
              isValid={true}
              autoFocus={true}
              validationFn={validateSiteMembersEmail}
              directionByLanguage={directionByLanguage}
            />
          </div>
          <div className={style.footer}>
            <div data-testid="submit" className={style.actionButton}>
              <BasicButton
                label={submitButtonText}
                id={`okButton_${id}`}
                isDisabled={false}
                hasPlatformClickHandler={true}
                link={undefined}
                onClick={submitForm}
              />
            </div>
          </div>
        </form>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default RequestPasswordResetDialog;
