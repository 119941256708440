import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';
import {
  PopupPageDefinition,
  PopupPageProps,
} from '@wix/thunderbolt-components';

const props = withCompPreviewInfo<
  PopupPageProps,
  PopupPageDefinition,
  PopupPageProps
>()(['isMobileView', 'compProps'], ({ isMobileView, compProps }, carmiData) => {
  return {
    isPositionFixedAllowed: !isMobileView,
    ...compProps,
    ...carmiData,
  };
});

export default createComponentPreviewMapperModel({
  props,
});
