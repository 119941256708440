import { formatClassNames } from '@wix/editor-elements-common-utils';
import * as React from 'react';
import { TestIds } from '../../constants';
import semanticClassNames from '../../Tabs.semanticClassNames';
import type { ITabsListItem } from '../../Tabs.types';
import { st, classes } from './style/TabsList.st.css';

export type ITabsListItemProps = ITabsListItem & {
  isActive: boolean;
  idx: number;
  addRef: (
    ref: React.RefObject<HTMLDivElement>,
    idx: number,
    isActive: boolean,
  ) => void;
  onClick: () => void;
};

const TabsListItem: React.ForwardRefRenderFunction<
  HTMLDivElement,
  ITabsListItemProps
> = (props, ref) => {
  const { label, id, isActive, idx, addRef, onClick } = props;
  const tabRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => addRef(tabRef, idx, isActive), [addRef, idx, isActive]);

  return (
    <div
      className={st(
        classes.tab,
        { current: isActive },
        formatClassNames(semanticClassNames.menuItem),
      )}
      id={`tab-${id}`}
      data-hook={TestIds.TabItem}
      data-testid={isActive && TestIds.ActiveTabItem}
      data-idx={idx}
      onClick={onClick}
      ref={ref || tabRef}
      role="tab"
      // tb has styles that excludes *[tabIndex=-1] from focus ring styles
      tabIndex={isActive ? 0 : -2}
      aria-current={isActive}
    >
      <span className={classes.label} data-hook={TestIds.TabItemLabel}>
        {label}
      </span>
    </div>
  );
};

export default React.forwardRef(TabsListItem);
