import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  IAccordionItemDefinition,
  IAccordionItemCarmiProps,
} from '../types';

export const props = withCompInfo<
  {},
  IAccordionItemDefinition,
  IAccordionItemCarmiProps
>()([], (_, carmiData) => carmiData);

export const css = withCompInfo()([], () => ({
  width: '100%',
}));

export default createComponentMapperModel({ props, css });
