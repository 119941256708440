import type { Language } from '@wix/thunderbolt-becky-types';
import type { FacebookLanguage, LanguageDecode } from './types';

const FACEBOOK_LANGUAGE_CODES = {
  en: 'en_US',
  es: 'es_ES',
  pt: 'pt_BR',
  ru: 'ru_RU',
  fr: 'fr_FR',
  de: 'de_DE',
  ja: 'ja_JP',
  ko: 'ko_KR',
  it: 'it_IT',
  pl: 'pl_PL',
  tr: 'tr_TR',
  nl: 'nl_NL',
  sv: 'sv_SE',
  da: 'da_DK',
  no: 'nn_NO',
} as LanguageDecode;

export const getLanguage = (language: Language): FacebookLanguage => {
  return (
    FACEBOOK_LANGUAGE_CODES[language as keyof typeof FACEBOOK_LANGUAGE_CODES] ||
    'en_US'
  );
};
