import * as React from 'react';
import FreemiumBannerMobileCommon from './FreemiumBannerMobileCommon';
import type { FreemiumBannerMobileProps } from '../FreemiumBannerMobile.types';
import style from './style/FreemiumBannerMobile.scss';
import GenericContent from './GenericContent';

const FreemiumBannerMobile: React.FC<FreemiumBannerMobileProps> = props => {
  const { translations } = props;

  return (
    <FreemiumBannerMobileCommon {...props} styles={style}>
      <GenericContent bannerText={translations.genericAppBannerText!} />
    </FreemiumBannerMobileCommon>
  );
};

export default FreemiumBannerMobile;
