import {
  withCompPreviewInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type { CompPreviewInfoDependency } from '@wix/editor-elements-types/thunderboltPreview';

import {
  css as mediaContainerCssMapper,
  props as mediaContainerPropsMapper,
} from '../../MediaContainers/MediaContainer/viewer/MediaContainer.previewMapper';

import {
  ClassicSectionCss,
  ClassicSectionDefinition,
} from '../ClassicSection.types';

const mediaContainerCssDeps = Object.keys(
  mediaContainerCssMapper.deps,
) as Array<CompPreviewInfoDependency>;

const css = withCompPreviewInfo<
  ClassicSectionCss,
  ClassicSectionDefinition,
  ClassicSectionCss
>()(['compProps', ...mediaContainerCssDeps], (deps, carmiData) => {
  const mediaContainerCss = mediaContainerCssMapper.resolver(deps, carmiData);
  return { ...carmiData, ...mediaContainerCss };
});

export default createComponentPreviewMapperModel({
  props: mediaContainerPropsMapper,
  css,
});
