(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("@wix/image-kit"), require("lodash"), require("react"), require("react-dom"));
	else if(typeof define === 'function' && define.amd)
		define("rb_wixui.thunderboltPreview", ["imageClientApi", "lodash", "react", "reactDOM"], factory);
	else if(typeof exports === 'object')
		exports["rb_wixui.thunderboltPreview"] = factory(require("@wix/image-kit"), require("lodash"), require("react"), require("react-dom"));
	else
		root["rb_wixui.thunderboltPreview"] = factory(root["__imageClientApi__"], root["_"], root["React"], root["ReactDOM"]);
})((typeof self !== 'undefined' ? self : this), function(__WEBPACK_EXTERNAL_MODULE__42662__, __WEBPACK_EXTERNAL_MODULE__60484__, __WEBPACK_EXTERNAL_MODULE__5329__, __WEBPACK_EXTERNAL_MODULE__95561__) {
return 