import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  CoBrandingBannerDefinition,
  CoBrandingBannerPlatformProps,
} from '../CoBrandingBannerMobile.types';
import { TRANSLATIONS } from '../../CoBrandingBannerDesktop/constants';

export const props = withCompInfo<
  CoBrandingBannerPlatformProps,
  CoBrandingBannerDefinition
>()(['direction', 'translate'], ({ direction, translate }) => {
  return {
    direction,
    translations: {
      titleText:
        translate(TRANSLATIONS.feature, TRANSLATIONS.mobile.title.key) ||
        TRANSLATIONS.mobile.title.defaultText,
      dayfulTitleText:
        translate(TRANSLATIONS.feature, TRANSLATIONS.dayful.title.key) ||
        TRANSLATIONS.dayful.title.defaultText,
    },
  };
});

export default createComponentMapperModel({ props });
