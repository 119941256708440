import { generateSkinDefaults } from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

// At the moment we need to keep this in sync with packages/editor-elements-design-panel-params/src/designPanelsConfigs/params.json
export const stylableButtonSkinDefinitionMap: Record<string, SkinDefinition> = {
  'wixui.skins.StylableButton': {
    font: {
      type: 'FONT',
      defaultValue: 'font_8',
      state: 'regular',
    },
    color: {
      type: 'COLOR',
      defaultValue: '#000000',
      state: 'regular',
    },
    'label-display': {
      type: 'CSSString',
      defaultValue: 'initial',
      state: 'regular',
    },
    'letter-spacing': {
      type: 'SIZE',
      defaultValue: '0em',
      state: 'regular',
    },
    'line-height': {
      type: 'SIZE',
      defaultValue: 'unset',
      state: 'regular',
    },
    'text-decoration': {
      type: 'CSSString',
      defaultValue: 'none',
      state: 'regular',
    },
    direction: {
      type: 'CSSString',
      defaultValue: 'ltr',
      state: 'regular',
    },
    'text-align': {
      type: 'CSSString',
      defaultValue: 'revert',
      state: 'regular',
    },
    'text-highlight': {
      type: 'COLOR_ALPHA',
      defaultValue: 'none',
      state: 'regular',
    },
    'text-transform': {
      type: 'CSSString',
      defaultValue: 'none',
      state: 'regular',
    },
    'text-outline': {
      type: 'CSSString',
      defaultValue:
        '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
      state: 'regular',
    },
    'text-shadow': {
      type: 'TEXT_SHADOW',
      defaultValue: '0px 0px transparent',
      state: 'regular',
    },
    background: {
      type: 'BACKGROUND_FILL',
      defaultValue: '#fac313',
      state: 'regular',
    },
    'box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: '0 1px 4px rgba(0, 0, 0, .6)',
      state: 'regular',
    },
    'border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'padding-bottom': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'padding-top': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'padding-left': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'padding-right': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'border-top-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'border-top-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'border-bottom-left-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'border-bottom-right-radius': {
      type: 'BORDER',
      defaultValue: '0',
      state: 'regular',
    },
    'icon-display': {
      type: 'CSSString',
      defaultValue: 'initial',
      state: 'regular',
    },
    'icon-size': {
      type: 'SIZE',
      defaultValue: '10px',
      state: 'regular',
    },
    'icon-color': {
      type: 'CSS_COLOR',
      defaultValue: 'rgba(0,0,255,0.5)',
      state: 'regular',
    },
    'icon-rotation': {
      type: 'CSSString',
      defaultValue: '0',
      state: 'regular',
    },
    'hover-font': {
      type: 'FONT',
      state: 'hover',
    },
    'hover-color': {
      type: 'COLOR',
      state: 'hover',
    },
    'hover-label-display': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-letter-spacing': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-line-height': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-text-decoration': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-direction': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-text-align': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-text-highlight': {
      type: 'COLOR_ALPHA',
      state: 'hover',
    },
    'hover-text-transform': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-text-outline': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-text-shadow': {
      type: 'TEXT_SHADOW',
      state: 'hover',
    },
    'hover-background': {
      type: 'BACKGROUND_FILL',
      state: 'hover',
    },
    'hover-box-shadow': {
      type: 'BOX_SHADOW',
      state: 'hover',
    },
    'hover-border-left': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'hover-border-right': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'hover-border-top': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'hover-border-bottom': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'hover-padding-bottom': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-padding-top': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-padding-left': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-padding-right': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-border-top-left-radius': {
      type: 'BORDER',
      state: 'hover',
    },
    'hover-border-top-right-radius': {
      type: 'BORDER',
      state: 'hover',
    },
    'hover-border-bottom-left-radius': {
      type: 'BORDER',
      state: 'hover',
    },
    'hover-border-bottom-right-radius': {
      type: 'BORDER',
      state: 'hover',
    },
    'hover-icon-display': {
      type: 'CSSString',
      state: 'hover',
    },
    'hover-icon-size': {
      type: 'SIZE',
      state: 'hover',
    },
    'hover-icon-color': {
      type: 'CSS_COLOR',
      state: 'hover',
    },
    'hover-icon-rotation': {
      type: 'CSSString',
      state: 'hover',
    },
    'disabled-font': {
      type: 'FONT',
      state: 'disabled',
    },
    'disabled-color': {
      type: 'COLOR',
      state: 'disabled',
    },
    'disabled-label-display': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-letter-spacing': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-line-height': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-text-decoration': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-direction': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-text-align': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-text-highlight': {
      type: 'COLOR_ALPHA',
      state: 'disabled',
    },
    'disabled-text-transform': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-text-outline': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-text-shadow': {
      type: 'TEXT_SHADOW',
      state: 'disabled',
    },
    'disabled-background': {
      type: 'BACKGROUND_FILL',
      state: 'disabled',
    },
    'disabled-box-shadow': {
      type: 'BOX_SHADOW',
      state: 'disabled',
    },
    'disabled-border-left': {
      type: 'BORDER_SIDE',
      state: 'disabled',
    },
    'disabled-border-right': {
      type: 'BORDER_SIDE',
      state: 'disabled',
    },
    'disabled-border-top': {
      type: 'BORDER_SIDE',
      state: 'disabled',
    },
    'disabled-border-bottom': {
      type: 'BORDER_SIDE',
      state: 'disabled',
    },
    'disabled-padding-bottom': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-padding-top': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-padding-left': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-padding-right': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-border-top-left-radius': {
      type: 'BORDER',
      state: 'disabled',
    },
    'disabled-border-top-right-radius': {
      type: 'BORDER',
      state: 'disabled',
    },
    'disabled-border-bottom-left-radius': {
      type: 'BORDER',
      state: 'disabled',
    },
    'disabled-border-bottom-right-radius': {
      type: 'BORDER',
      state: 'disabled',
    },
    'disabled-icon-display': {
      type: 'CSSString',
      state: 'disabled',
    },
    'disabled-icon-size': {
      type: 'SIZE',
      state: 'disabled',
    },
    'disabled-icon-color': {
      type: 'CSS_COLOR',
      state: 'disabled',
    },
    'disabled-icon-rotation': {
      type: 'CSSString',
      state: 'disabled',
    },
    'container-flex-direction': {
      type: 'CSSString',
      defaultValue: 'row',
      state: 'regular',
    },
    'container-justify-content': {
      type: 'CSSString',
      defaultValue: 'center',
      state: 'regular',
    },
    'container-align-items': {
      type: 'CSSString',
      defaultValue: 'center',
      state: 'regular',
    },
    'content-horizontal-alignment': {
      type: 'CSSString',
      defaultValue: 'flex-start',
      state: 'regular',
    },
    'content-gap': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
  },
};

export default generateSkinDefaults(stylableButtonSkinDefinitionMap);
