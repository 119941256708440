import { useCallback, useMemo, useRef, useState } from 'react';
import { debounce } from '@wix/editor-elements-common-utils';
import { useResizeObserver } from '@wix/thunderbolt-elements/providers/useResizeObserver';
import {
  ProGalleryOptions,
  processProGalleryOptions,
  flattenObject,
  optionsConverter,
} from '@wix/native-pro-gallery';
import { parseValue } from '../common/utils';
import { PRO_GALLERY_CSS_VARS_PREFIX } from '../common/consts';

export function stylableOptionManager<T extends Record<string, any>>(
  element: React.MutableRefObject<HTMLDivElement | null>,
  styleId: string,
) {
  const memoMap = new Map<string, String>();
  const prefix = `--${styleId}-${PRO_GALLERY_CSS_VARS_PREFIX}-`;
  const varsToUpdate = Object.keys(ProGalleryOptions);
  function read() {
    if (!element.current) {
      return {
        hasChanges: false,
        options: {} as Partial<T>,
      };
    }
    const newOptions = {} as Partial<T>;
    let hasChanges = false;
    const style = window.getComputedStyle(element.current);
    for (const key of varsToUpdate) {
      const styleName = `${prefix}${key}`;
      const cssVar = style.getPropertyValue(styleName);
      if (!cssVar) {
        continue;
      }
      if (cssVar === memoMap.get(key)) {
        continue;
      }
      const value = parseValue(cssVar);
      memoMap.set(key, cssVar);
      hasChanges = true;
      newOptions[key as keyof T] = value;
    }
    return {
      hasChanges,
      options: newOptions,
    };
  }
  return read;
}

export interface EditorOptionsOverrides {
  alwaysShowHover: boolean;
}

export interface ManualStyleParams {
  [key: string]: any;
}

interface UseOptionsProps<T> {
  styleId: string;
  isExperimentOpen: (experimentName: string) => boolean;
  compProperties: T;
  setIsKnownOptions: (isKnownOptions: boolean) => void;
  viewMode: string;
  experimentalFeatures: {
    newInfoElements?: boolean;
  };
  imageOnClickAction: string;
  manualStyleParams: ManualStyleParams | undefined;
  editorOverrides?: EditorOptionsOverrides;
}

export function useOptions<T extends object = Record<string, any>>(
  props: UseOptionsProps<T>,
) {
  const elementRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<T>(props.compProperties as T);
  const readCssDataStore = useMemo(
    () => stylableOptionManager<T>(elementRef, props.styleId),
    [props.styleId],
  );

  const update = useCallback(() => {
    const { hasChanges, options: newOptions } = readCssDataStore();
    if (hasChanges) {
      setOptions(opt => ({ ...opt, ...newOptions }));
    }
    props.setIsKnownOptions(true);
  }, [readCssDataStore, props]);

  const debouncedUpdate = useMemo(() => debounce(update, 100), [update]);

  useResizeObserver({
    elem: elementRef.current || null,
    callback: debouncedUpdate,
  });

  // TO DO - add experiment to disable the css data store
  const { options: convertedOptions, fullscreenOverrides } = useMemo(() => {
    const processed = {
      ...processProGalleryOptions(options),
      ...(props.imageOnClickAction && {
        itemClick: props.imageOnClickAction,
      }),
      ...flattenObject(props.manualStyleParams || {}), // TODO - remove flatten on a separate PR.
      ...props.editorOverrides,
    };
    return optionsConverter(processed, props.viewMode);
  }, [
    options,
    props.viewMode,
    props.imageOnClickAction,
    props.manualStyleParams,
    props.editorOverrides,
  ]);

  return {
    options: convertedOptions as T,
    rawOptions: options,
    fullscreenOverrides,
    forceUpdate: update,
    elementRef,
  };
}
