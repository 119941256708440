import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import {
  IRatingsInputProps,
  IRatingsInputImperativeActions,
} from '../RatingsInput.types';
import semanticClassNames from '../RatingsInput.semanticClassNames';
import RatingsInputItem from './RatingsInputItem/RatingsInputItem';
import { classes, st } from './RatingsInput.component.st.css';
import { DataHooks, TestIds } from './constants';

const fiveItems = [1, 2, 3, 4, 5];

const getLabelsWidthWithMargins = (labelsRefs: Array<HTMLElement> | null) => {
  if (!labelsRefs) {
    return 0;
  }

  return Math.max(
    ...labelsRefs.map(el => {
      if (!el) {
        return 0;
      }
      const elemStyle = window.getComputedStyle(el);

      // set element width to auto in order to take only text width, measure width, and reset width to original value
      const widthStyle = el.style.width;
      el.style.width = 'auto';
      const width = elemStyle
        ? parseInt(elemStyle.marginLeft, 10) +
          el.offsetWidth +
          parseInt(elemStyle.marginRight, 10)
        : 0;
      el.style.width = widthStyle;

      return width;
    }),
  );
};

const LabelsContainer = React.memo<{
  showTitle: IRatingsInputProps['showTitle'];
  showLabels: IRatingsInputProps['showLabels'];
  text: string;
  labelsRefs: React.MutableRefObject<Array<HTMLElement>>;
  labels: IRatingsInputProps['labels'];
  title: IRatingsInputProps['title'];
}>(({ showTitle, showLabels, text, labelsRefs, labels, title }) => (
  <span className={classes.labelsContainer}>
    {(showTitle || showLabels) && (
      <span className={classes.title} data-hook={DataHooks.ratingInputTitle}>
        {text}
      </span>
    )}
    {showLabels && (
      <>
        {fiveItems.map((value, index) => {
          return (
            <span
              key={value}
              className={st(
                classes.label,
                formatClassNames(semanticClassNames.label),
              )}
              ref={(el: HTMLSpanElement) => (labelsRefs.current[index] = el)}
            >
              {labels[value]}
            </span>
          );
        })}
      </>
    )}
    {showTitle && <span className={classes.label}>{title}</span>}
  </span>
));

type OnIconHoverType = { value: number; label: string };

type styleObjectType = {
  minWidth?: number;
  marginLeft?: string;
  marginRight?: string;
};

const Icons = React.memo<{
  value: IRatingsInputProps['value'];
  id: IRatingsInputProps['id'];
  hoveredItemIndex?: number;
  _onChange: React.ChangeEventHandler<HTMLInputElement>;
  required: IRatingsInputProps['required'];
  labels: IRatingsInputProps['labels'];
  onIconHover: (icon: OnIconHoverType) => void;
  onIconBlur: () => void;
  svgString: IRatingsInputProps['svgString'];
  itemRefs: React.MutableRefObject<
    Array<IRatingsInputImperativeActions | null>
  >;
  shouldDisplayError: boolean;
  style: styleObjectType;
}>(
  ({
    value,
    id,
    hoveredItemIndex,
    _onChange,
    required,
    labels,
    onIconHover,
    onIconBlur,
    svgString,
    itemRefs,
    shouldDisplayError,
    style,
  }) => (
    <span
      className={classes.icons}
      aria-label={value ? `${value} out of 5` : undefined}
      style={style}
    >
      {fiveItems.map(itemValue => (
        <RatingsInputItem
          key={itemValue}
          className={classes.item}
          name={id}
          value={itemValue}
          ratingInputValue={value}
          hovered={hoveredItemIndex}
          onChange={_onChange}
          required={required}
          ariaLabel={!value ? labels[itemValue] : undefined}
          onMouseEnter={() =>
            onIconHover({ value: itemValue, label: labels[itemValue] })
          }
          onMouseLeave={onIconBlur}
          svgString={svgString}
          ref={el => {
            if (itemRefs.current) {
              itemRefs.current[itemValue - 1] = el;
            }
          }}
          error={shouldDisplayError}
        />
      ))}
    </span>
  ),
);

const useCalculateA11yLabelMargin = (
  a11yLabelRef: React.RefObject<HTMLDivElement>,
  a11yLabelMargin: number,
  setA11yLabelMargin: (v: number) => void,
  a11yLabelPosition: IRatingsInputProps['a11yLabelPosition'],
  shapeSize: number,
  title: string,
  compWidth?: number,
) => {
  // calculate margin to align a11yLabel with rating icons
  React.useLayoutEffect(() => {
    if (a11yLabelRef.current && a11yLabelPosition === 'side') {
      const { clientHeight } = a11yLabelRef.current;

      if (clientHeight >= shapeSize && a11yLabelMargin) {
        setA11yLabelMargin(0);
      } else if (clientHeight < shapeSize) {
        const margin = (shapeSize - clientHeight) / 2;

        if (a11yLabelMargin !== margin) {
          setA11yLabelMargin(margin);
        }
      }
    }
  }, [
    a11yLabelMargin,
    a11yLabelPosition,
    shapeSize,
    compWidth,
    title,
    a11yLabelRef,
    setA11yLabelMargin,
  ]);
};

const RatingsInput: React.ForwardRefRenderFunction<
  IRatingsInputImperativeActions,
  IRatingsInputProps
> = (props, ref) => {
  const {
    id,
    className,
    customClassNames = [],
    onChange = () => {},
    onRatingChange = () => {},
    onClick = () => {},
    onDblClick = () => {},
    onMouseEnter = () => {},
    onMouseLeave = () => {},
    iconMouseIn = () => {},
    onFocus = () => {},
    onBlur = () => {},
    labels,
    showLabels,
    showTitle,
    title = '',
    svgString,
    labelPosition,
    a11yLabelPosition,
    required,
    value,
    isDisabled,
    shouldShowValidityIndication,
    isValid,
    reCalculateIconsSizeDeps = [],
    overrideIconsStyle,
    validateValueAndShowIndication = () => {},
    showA11yLabel,
    shapeSize,
    rootMinWidth,
    compWidth,
    fixShiftLeft,
  } = props;
  const [hoveredItemIndex, setHoveredItemIndex] = React.useState<
    number | undefined
  >(undefined);
  const [style, setStyle] = React.useState<styleObjectType>({});
  const [a11yLabelMargin, setA11yLabelMargin] = React.useState<number>(0);

  React.useLayoutEffect(
    () => {
      let iconsStyle = {};

      if (labelPosition === 'side') {
        const labelWidth = getLabelsWidthWithMargins(labelsRefs.current);
        iconsStyle = !labelWidth ? {} : overrideIconsStyle?.(labelWidth) || {};
      }

      setStyle(iconsStyle);
    },
    /*
      Every time icons attribured are modified in editor (layout panel),
      we need to recalculate the icons styles based on that values.
      We don't want that to happen every render in live sites, so we used reCalculateIconsSizeDeps
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
    reCalculateIconsSizeDeps,
  );
  const labelsRefs = React.useRef<Array<HTMLElement>>([]);
  const a11yLabelRef = React.useRef<HTMLDivElement>(null);

  const itemRefs = React.useRef<Array<IRatingsInputImperativeActions | null>>(
    [],
  );

  React.useLayoutEffect(() => {
    const label = a11yLabelRef.current;

    if (rootMinWidth && label) {
      // set element width to min-content in order to take width of the widest letter, measure width, and reset width to original value
      const widthStyle = label.style.width;
      label.style.width = 'min-content';
      const { offsetWidth } = label;
      label.style.width = widthStyle;

      setStyle({ minWidth: rootMinWidth + offsetWidth });
    }
  }, [rootMinWidth, title]);

  useCalculateA11yLabelMargin(
    a11yLabelRef,
    a11yLabelMargin,
    setA11yLabelMargin,
    a11yLabelPosition,
    shapeSize,
    title,
    compWidth,
  );

  React.useImperativeHandle(ref, () => {
    const index = (value || 1) - 1;
    return {
      focus() {
        itemRefs.current[index]?.focus();
      },
      blur() {
        itemRefs.current[index]?.blur();
      },
      setCustomValidity: message => {
        itemRefs.current[index]?.setCustomValidity(message);
      },
    };
  });

  const onIconBlur = () => {
    if (isDisabled) {
      return;
    }
    setHoveredItemIndex(undefined);
  };

  const onIconHover = (icon: OnIconHoverType) => {
    if (isDisabled) {
      return;
    }

    setHoveredItemIndex(icon.value);
    iconMouseIn({ ...icon, type: 'iconMouseIn' });
  };

  const getCurrentText = React.useCallback(() => {
    if (hoveredItemIndex && showLabels) {
      return labels[hoveredItemIndex];
    } else if (value && showLabels) {
      return labels[value];
    }

    return (showTitle && title) || '';
  }, [hoveredItemIndex, labels, value, showLabels, showTitle, title]);

  const shouldDisplayError =
    shouldShowValidityIndication && !hoveredItemIndex && !isValid;

  const styleStates = {
    labelPosition,
    required,
    disabled: isDisabled,
    error: shouldDisplayError,
  };
  const _onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const intValue = parseInt(event.target.value, 10);
    const isValueUnchanged = value === intValue;
    if (isDisabled || isValueUnchanged) {
      return;
    }
    onRatingChange(event);
    validateValueAndShowIndication();
    onChange?.(event);
  };
  const a11yProps = showA11yLabel ? { 'aria-labelledby': `${id}_label` } : {};
  const a11yMarginPos = labelPosition === 'top' ? 'marginBottom' : 'marginTop';
  const a11yStyles =
    a11yLabelPosition === 'side' && a11yLabelMargin
      ? { style: { [a11yMarginPos]: `${a11yLabelMargin}px` } }
      : {};

  return (
    <div
      {...getDataAttributes(props)}
      className={st(
        classes.root,
        styleStates,
        classNames({ [classes.a11yRoot]: showA11yLabel }),
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      role="radiogroup"
      id={id}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onFocus={onFocus}
      onBlur={onBlur}
      style={fixShiftLeft ? {} : style}
      {...a11yProps}
    >
      {showA11yLabel ? (
        <>
          <div
            className={classes.a11yLabel}
            id={`${id}_label`}
            ref={a11yLabelRef}
            data-testid={TestIds.a11yLabel}
            {...a11yStyles}
          >
            {title}
          </div>
          <div
            className={classNames(classes.ratingWrapper, {
              [classes.limitWidth]: a11yLabelPosition === 'side',
              [classes.fullWidth]: a11yLabelPosition === 'top',
            })}
          >
            {showLabels && (
              <LabelsContainer
                showTitle={showTitle}
                showLabels={showLabels}
                text={getCurrentText()}
                labelsRefs={labelsRefs}
                labels={labels}
                title={title}
              />
            )}
            <Icons
              value={value}
              id={id}
              hoveredItemIndex={hoveredItemIndex}
              _onChange={_onChange}
              required={required}
              labels={labels}
              onIconHover={onIconHover}
              onIconBlur={onIconBlur}
              svgString={svgString}
              itemRefs={itemRefs}
              shouldDisplayError={shouldDisplayError}
              style={fixShiftLeft ? style : {}}
            />
          </div>
        </>
      ) : (
        <>
          <LabelsContainer
            showTitle={showTitle}
            showLabels={showLabels}
            text={getCurrentText()}
            labelsRefs={labelsRefs}
            labels={labels}
            title={title}
          />
          <Icons
            value={value}
            id={id}
            hoveredItemIndex={hoveredItemIndex}
            _onChange={_onChange}
            required={required}
            labels={labels}
            onIconHover={onIconHover}
            onIconBlur={onIconBlur}
            svgString={svgString}
            itemRefs={itemRefs}
            shouldDisplayError={shouldDisplayError}
            style={fixShiftLeft ? style : {}}
          />
        </>
      )}
    </div>
  );
};

export default React.forwardRef(RatingsInput);
