import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import semanticClassNames from '../WIP_MatrixGallery.semanticClassNames';
import type { MatrixGallerySkinProps } from '../MatrixGallery.types';
import { TestIds } from '../constants';
import { generateItemIndexString } from '../../commons/itemUtils';

const emptyFn = () => {};

const MatrixGallery: React.FC<MatrixGallerySkinProps> = props => {
  const {
    id,
    items,
    rows,
    columns,
    extraRows,
    showMoreLabel,
    imageOnClickAction,
    className,
    customClassNames = [],
    openImageZoom = emptyFn,
    skinsStyle,
    skinsItemStyle,
    translations,
    MatrixGalleryItemComponent,
    isFitMode,
    onItemClicked,
    onMouseEnter,
    onMouseLeave,
    numberOfExtraRows = 0,
    setNumberOfExtraRows,
    itemToFocusIndex = -1,
    setItemToFocusIndex,
    getPlaceholder,
  } = props;

  const numberOfInitialItems = rows * columns;
  const numberOfItemsToShow = Math.min(
    numberOfInitialItems + numberOfExtraRows * columns,
    items.length,
  );
  const columnClass = `columns-${columns}`;
  const itemsToShow = items.slice(0, numberOfItemsToShow).map((item, index) => {
    const itemClickHandler = () => {
      onItemClicked?.({
        itemIndex: index,
        type: 'itemClicked',
      });
    };
    item.image.getPlaceholder = getPlaceholder;

    return (
      <MatrixGalleryItemComponent
        key={`item-${index}`}
        extraClassNames={{
          root: classNames(
            skinsStyle.item,
            formatClassNames(semanticClassNames.galleryItem),
          ),
        }}
        skinsStyle={skinsItemStyle}
        imageOnClickAction={imageOnClickAction}
        openImageZoom={(dataId: string) => openImageZoom(dataId, id)}
        itemClickHandler={itemClickHandler}
        translations={translations}
        isFocused={index === itemToFocusIndex}
        isFitMode={isFitMode}
        itemId={generateItemIndexString(index, id)}
        {...item}
      />
    );
  });

  const showMore = React.useCallback(() => {
    setItemToFocusIndex(numberOfItemsToShow);
    setNumberOfExtraRows(numberOfExtraRows + extraRows);
  }, [
    numberOfExtraRows,
    extraRows,
    setNumberOfExtraRows,
    setItemToFocusIndex,
    numberOfItemsToShow,
  ]);

  const canShowMore = numberOfItemsToShow < items.length;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        skinsStyle.root,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      role="region"
      aria-label={translations.galleryAriaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classNames(
          skinsStyle.itemsContainer,
          skinsStyle[columnClass],
        )}
        data-testid={TestIds.itemsContainer}
      >
        {itemsToShow}
      </div>
      <div className={skinsStyle.showMoreContainer}>
        {canShowMore && (
          <button
            data-testid={TestIds.showMoreButton}
            className={skinsStyle.showMore}
            onClick={showMore}
          >
            {showMoreLabel}
          </button>
        )}
      </div>
    </div>
  );
};

export default MatrixGallery;
