import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  ItunesButtonProps,
  ItunesButtonMapperProps,
  ItunesButtonStateValues,
  ItunesButtonControllerProps,
} from '../ItunesButton.types';

const useComponentProps = ({
  mapperProps,
  stateValues,
}: IPlatformData<
  ItunesButtonMapperProps,
  ItunesButtonControllerProps,
  ItunesButtonStateValues
>): ItunesButtonProps => {
  const {
    compId,
    siteLanguage,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick = (imageUrl: string, title: string) => {
    const { link, language } = restMapperProps;

    tryReportAnalyticsClicksBi(stateValues.reportBi, {
      trackClicksAnalytics,
      value: link?.href,
      element_id: compId,
      elementTitle: title,
      language: siteLanguage,
      elementType: fullNameCompType,
      elementGroup: AnalyticsClicksGroups.Button,
      details: { uri: imageUrl, buttonLanguage: language },
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
};

export default withCompController(useComponentProps);
