import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import MenuButton from '../../../../MenuButton/viewer/skinComps/PointerMenuButtonNSkin/PointerMenuButtonNSkin';
import PointerMenuButton from './PointerMenuButton';
import styles from './PointerMenuButtonSkin.scss';

const PointerMenuButtonSkin: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuProps
> = (props, ref) => {
  return (
    <PointerMenuButton
      {...props}
      ref={ref}
      styles={styles}
      Button={MenuButton}
    />
  );
};

export default React.forwardRef(PointerMenuButtonSkin);
