import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewEffect,
  usePreviewState,
  usePrevious,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
  IDefaultPreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import React from 'react';
import { useResizeObserver } from '@wix/thunderbolt-elements/src/providers/useResizeObserver';
import type {
  IAddressInputImperativeActions,
  IAddressInputProps,
  InternalAddress,
  Suggestion,
} from '../AddressInput.types';
import { classes } from './style/AddressInput.st.css';

type ComponentViewMode = 'editor' | 'preview';

export type AddressInputPreviewWrapperProps = IDefaultPreviewWrapperProps & {
  shouldResetComponent: boolean;
  componentViewMode?: ComponentViewMode;
};

const noop = () => {};

const shouldChangeEditorValue = (
  componentViewMode: ComponentViewMode | undefined,
  lastComponentViewMode: ComponentViewMode | undefined,
) => componentViewMode === 'editor' && lastComponentViewMode === 'editor';

export const PREVIEW_STATES = {
  LIST: 'list',
} as const;

const isListPreviewState = (compPreviewState: string | null | undefined) =>
  compPreviewState?.includes('list');

export const getExampleOptions = (
  translations: IAddressInputProps['translations'],
): Array<Suggestion> =>
  [1, 2, 3, 4].map(id => ({
    id: `${id}`,
    value: `${translations.exampleAddressText}${id}`,
  }));

export const getForceOptions = ({
  componentViewMode,
  compPreviewState,
  translations,
}: {
  componentViewMode: ComponentViewMode | undefined;
  compPreviewState: string | null | undefined;
  translations: IAddressInputProps['translations'];
}): Array<Suggestion> | undefined =>
  componentViewMode === 'editor' && isListPreviewState(compPreviewState)
    ? getExampleOptions(translations)
    : undefined;

function withComponentPreview(
  WrappedComponent: React.ComponentType<IAddressInputProps>,
): IComponentPreviewWrapper<
  IAddressInputProps,
  AddressInputPreviewWrapperProps
> {
  return React.forwardRef<
    IAddressInputImperativeActions,
    PreviewWrapperProps<IAddressInputProps, AddressInputPreviewWrapperProps>
  >(
    (
      {
        previewWrapperProps,
        ...viewerProps
      }: PreviewWrapperProps<
        IAddressInputProps,
        AddressInputPreviewWrapperProps
      >,
      ref,
    ) => {
      const { compPreviewState, shouldResetComponent, componentViewMode } =
        previewWrapperProps || {};
      const {
        id,
        value = {},
        onValueChange = noop,
        setValidityIndication = noop,
        validateValue = noop,
        onSuggestionsVisibilityUpdate = noop,
        translations,
      } = viewerProps;
      const editorValue = React.useRef<InternalAddress>(value);
      const wrapperRef = React.useRef<HTMLDivElement>(null);
      const lastComponentViewMode = usePrevious(componentViewMode);

      const [clientHeight, setClientHeight] = React.useState(
        wrapperRef?.current?.clientHeight,
      );

      React.useEffect(() => {
        if (shouldChangeEditorValue(componentViewMode, lastComponentViewMode)) {
          editorValue.current = value;
        }
      }, [value, lastComponentViewMode, componentViewMode]);

      const updateComponentHeight = React.useCallback(() => {
        setClientHeight(wrapperRef?.current?.clientHeight);
      }, [wrapperRef]);

      useResizeObserver({
        callback: updateComponentHeight,
        ref: wrapperRef,
      });

      usePreviewState(id, compPreviewState, {
        focus: {
          selectors: `.${classes.input}`,
          type: 'single',
        },
        'list regular': {
          selectors: `.${classes.input}`,
          type: 'single',
          state: 'focus',
        },
        'list hover': [
          {
            selectors: `.${classes.input}`,
            type: 'single',
            state: 'focus',
          },
          {
            selectors: `.${classes.option}`,
            type: 'single',
            state: 'hovered',
          },
        ],
        error: {
          selectors: `.${classes.input}`,
          type: 'single',
        },
        hover: {
          selectors: `.${classes.input}`,
          type: 'single',
        },
        disabled: {
          selectors: `.${classes.input}`,
          type: 'single',
        },
      });

      usePreviewEffect({
        componentViewMode,
        onPreviewViewMode: validateValue,
      });

      useResetComponent({
        id,
        shouldResetComponent: !!shouldResetComponent,
        onResetComponent: () => {
          onValueChange(editorValue.current);
          setValidityIndication(false);
          validateValue();
          onSuggestionsVisibilityUpdate(false);
        },
      });

      const autocompleteFakeData = React.useMemo(
        () =>
          getForceOptions({
            componentViewMode,
            compPreviewState,
            translations,
          }),
        [componentViewMode, compPreviewState, translations],
      );

      return (
        <WrappedComponent
          wrapperRef={wrapperRef}
          {...viewerProps}
          {...(autocompleteFakeData
            ? { suggestions: autocompleteFakeData, suggestionsVisibility: true }
            : {})}
          ref={ref}
          height={clientHeight}
        />
      );
    },
  );
}

export default (ViewerComponent: React.ComponentType<IAddressInputProps>) =>
  createComponentPreviewEntry<
    IAddressInputProps,
    AddressInputPreviewWrapperProps
  >(withComponentPreview(ViewerComponent));
