import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  ItunesButtonCSSVars,
  ItunesButtonDefinition,
  ItunesButtonStateValues,
  ItunesButtonPlatformProps,
} from '../ItunesButton.types';
import { translations } from './constants';

export const props = withCompInfo<
  ItunesButtonPlatformProps,
  ItunesButtonDefinition,
  ItunesButtonPlatformProps
>()(
  [
    'compId',
    'fullNameCompType',
    'language',
    'translate',
    'trackClicksAnalytics',
  ],
  ({ language, translate, ...compPropsRest }, carmiData) => {
    return {
      ...carmiData,
      ...compPropsRest,
      siteLanguage: language,
      translations: {
        ariaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.ARIA_LABEL_KEY,
          ) || translations.ARIA_LABEL_DEFAULT,
      },
    };
  },
);

export const css = withCompInfo<
  ItunesButtonCSSVars,
  ItunesButtonDefinition,
  ItunesButtonCSSVars
>()([], (_deps, carmiProps) => carmiProps);

const stateRefs = withStateRefsValues<keyof ItunesButtonStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
