import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  CaptchaDefinition,
  CaptchaLanguageKey,
  ICaptchaMapperProps,
} from '../Captcha.types';
import { WIX_RECAPTCHA_LANGUAGE_MAP } from '../constants';

export const props = withCompInfo<ICaptchaMapperProps, CaptchaDefinition>()(
  ['language'],
  ({ language }) => {
    return {
      language:
        WIX_RECAPTCHA_LANGUAGE_MAP[language as CaptchaLanguageKey] || 'en',
    };
  },
);

export default createComponentMapperModel({
  props,
});
