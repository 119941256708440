import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { DataHooks } from '../constants';
import {
  ConsentPolicyWrapperProps,
  ConsentPolicyComponentProps,
  WrappedComponentProps,
  CookieConsentTypes,
} from '../ConsentPolicyWrapper.types';
import CookieIcon from './assets/CookieIcon.svg';
import style from './styles/ConsentPolicyWrapper.scss';
import { getConsentWrapperTexts } from './utils';
import { Tooltip } from './Tooltip/Tooltip';
import { TextWithTooltip } from './TextWithTooltip/TextWithTooltip';

export const ConsentPolicyWrapperContext = React.createContext<{
  setWrapperStyle: (wrapperStyles: Record<string, string>) => void;
}>({ setWrapperStyle: () => {} });

export const ConsentPolicyWrapper: React.ForwardRefRenderFunction<
  {},
  ConsentPolicyWrapperProps
> = props => {
  const [render, setRender] = React.useState(false);
  const [rootStyle, setRootStyle] = React.useState<Record<string, string>>({});

  const {
    componentConsentPolicy,
    id,
    consentPolicy,
    openSettingModal,
    className,
    rootClassName,
    isMobile,
    translations,
  } = props;

  const componentName = translations.componentName;

  const policy = consentPolicy.policy;

  React.useEffect(() => {
    setRender(true);
  }, [consentPolicy]);

  const hasConsent = Object.keys(componentConsentPolicy || {}).reduce(
    (accumulator, consentKey) =>
      accumulator && policy[consentKey as keyof typeof policy],
    true,
  );

  const requiredConsentTypes = Object.keys(componentConsentPolicy || {});

  const {
    largeText,
    mediumText,
    smallText,
    tinyTooltipText,
    tinyAriaLabelText,
    smallTooltipText,
    smallAriaLabelText,
  } = getConsentWrapperTexts(
    requiredConsentTypes as Array<CookieConsentTypes>,
    componentName || '',
    translations,
  );

  const setWrapperStyle = React.useCallback(
    (styleToUpdate: Record<string, string>) => {
      setRootStyle(styleToUpdate);
    },
    [],
  );

  if (!render) {
    return <div id={id} {...getDataAttributes(props)} />;
  }

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      style={rootStyle}
      className={classNames(rootClassName, className, style.root)}
    >
      {hasConsent ? (
        <ConsentPolicyWrapperContext.Provider value={{ setWrapperStyle }}>
          {props.children}
        </ConsentPolicyWrapperContext.Provider>
      ) : (
        <div data-hook={DataHooks.NoConsentBox} className={style.noConsentBox}>
          <div className={classNames(style.textBox, style.largeTextBox)}>
            <TextWithTooltip
              id={id}
              datahook="large"
              text={largeText}
              isMobile={isMobile}
              onSettingsClick={openSettingModal}
            ></TextWithTooltip>
            <CookieIcon aria-hidden="true" />
          </div>
          <div className={classNames(style.textBox, style.mediumTextBox)}>
            <TextWithTooltip
              id={id}
              datahook="medium"
              text={mediumText}
              isMobile={isMobile}
              onSettingsClick={openSettingModal}
            ></TextWithTooltip>
            <CookieIcon aria-hidden="true" />
          </div>
          <div className={classNames(style.textBox, style.smallTextBox)}>
            <TextWithTooltip
              id={id}
              datahook="small"
              text={smallText}
              tooltipText={smallTooltipText}
              ariaLabelText={smallAriaLabelText}
              isMobile={isMobile}
              onSettingsClick={openSettingModal}
            ></TextWithTooltip>
          </div>
          <div className={style.tinyTextBox}>
            <Tooltip
              id={id}
              datahook="tiny"
              text={tinyTooltipText}
              ariaLabelText={tinyAriaLabelText}
              isMobile={isMobile}
              onSettingsClick={openSettingModal}
            ></Tooltip>
          </div>
        </div>
      )}
    </div>
  );
};

export const WithConsentPolicy = <
  T extends WrappedComponentProps,
  R extends {},
>(
  Component: React.ForwardRefRenderFunction<R, T> | React.FC<T>,
  consentPolicyProps: ConsentPolicyComponentProps,
) => {
  return React.forwardRef((props: T, ref) => (
    <ConsentPolicyWrapper {...props} {...consentPolicyProps}>
      <Component {...props} ref={ref} />
    </ConsentPolicyWrapper>
  ));
};

export default WithConsentPolicy;
