import * as React from 'react';
import SiteButton from '../../SiteButton';
import type { SkinButtonProps } from '../SkinButton.types';
import type {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import BaseButtonSkin from './BaseButton';
import skinsStyle from './styles/ApplyButtonEcom.scss';

const ApplyButtonEcomSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>((props, ref) => (
  <BaseButtonSkin {...props} skinsStyle={skinsStyle} ref={ref}></BaseButtonSkin>
));

const ApplyButtonEcom: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => (
  <SiteButton {...props} skin={ApplyButtonEcomSkin} ref={ref} />
);

export default React.forwardRef(ApplyButtonEcom);
