
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "NavButton1074373401",
      {"classes":{"root":"NavButton1074373401__root"},"vars":{},"stVars":{"navButtonBG":"rgba(var(--navButtonBG, 0,0,0), var(--alpha-navButtonBG))","navButtonDisabledBG":"rgba(var(--navButtonDisabledBG, var(--color_27)), var(--alpha-navButtonDisabledBG, 1))","bRadius":"var(--bRadius, 100px)","navButtonBorderWidth":"var(--navButtonBorderWidth, 3)","navButtonBorderColor":"rgba(var(--navButtonBorderColor, var(--color_32)), var(--alpha-navButtonBorderColor, 1))","navButtonDisabledBorderWidth":"var(--navButtonDisabledBorderWidth, 3)","navButtonDisabledBorderColor":"rgba(var(--navButtonDisabledBorderColor, var(--color_43)), var(--alpha-navButtonDisabledBorderColor, 1))"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  