import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { LoginSocialBarSdkData } from '@wix/thunderbolt-components';
import type {
  ILoginSocialBarMapperProps,
  LoginSocialBarDefinition,
  LoginSocialBarCSSVars,
} from '../LoginSocialBar.types';
import { ArrowShape } from '../LoginSocialBar.types';
import {
  CHOOSE_OPTION_TEXT_DEFAULT,
  LOG_OUT_TEXT,
  LOG_OUT_TEXT_DEFAULT,
  LOGGED_OUT_TEXT,
  LOGGED_OUT_TEXT_DEFAULT,
  LOGIN_SOCIAL_BAR_NAMESPACE,
  OPTION_VALUE_TEXT,
} from '../constants';

export const props = withCompInfo<
  ILoginSocialBarMapperProps,
  LoginSocialBarDefinition,
  ILoginSocialBarMapperProps
>()(
  [
    'compProps',
    'fullNameCompType',
    'language',
    'compId',
    'translate',
    'trackClicksAnalytics',
  ],
  ({ compProps, translate, ...compPropsRest }, carmiData) => {
    const loggedOutText =
      carmiData.loggedOutText === LOGGED_OUT_TEXT_DEFAULT
        ? translate(LOGIN_SOCIAL_BAR_NAMESPACE, LOGGED_OUT_TEXT) ||
          LOGGED_OUT_TEXT_DEFAULT
        : carmiData.loggedOutText;
    const logOutText =
      carmiData.logOutText === LOG_OUT_TEXT_DEFAULT
        ? translate(LOGIN_SOCIAL_BAR_NAMESPACE, LOG_OUT_TEXT) ||
          LOG_OUT_TEXT_DEFAULT
        : carmiData.logOutText;

    return {
      ...carmiData,
      ...compPropsRest,
      arrowShape: compProps.arrowShape ?? ArrowShape.Line,
      showBellIcon: compProps.showBellIcon ?? true,
      menuItemsAlignment: compProps.dropDownTextAlignment,
      translations: {
        loggedOutText,
        logOutText,
        menuOptionValueText:
          translate(LOGIN_SOCIAL_BAR_NAMESPACE, OPTION_VALUE_TEXT) ||
          CHOOSE_OPTION_TEXT_DEFAULT,
      },
    };
  },
);

export const css = withCompInfo<
  LoginSocialBarCSSVars,
  LoginSocialBarDefinition,
  LoginSocialBarCSSVars
>()(
  ['compProps', 'styleProperties'],
  ({ compProps, styleProperties }, carmiCss) => {
    let avatarAndArrowStrokeWidth: string;

    const typeofAvatarStrokeWidth =
      typeof styleProperties.avatarAndArrowStrokeWidth;
    // we receive avatarAndArrowStrokeWidth sometimes with 'px' sometimes without
    if (
      typeofAvatarStrokeWidth === 'number' ||
      (typeofAvatarStrokeWidth === 'string' &&
        !styleProperties.avatarAndArrowStrokeWidth.includes('px'))
    ) {
      avatarAndArrowStrokeWidth = `${styleProperties.avatarAndArrowStrokeWidth}px`;
    } else {
      avatarAndArrowStrokeWidth = styleProperties.avatarAndArrowStrokeWidth;
    }

    return {
      ...carmiCss,
      ...(compProps.arrowSize && {
        '--arrow-size': `${compProps.arrowSize}px`,
      }),
      ...(avatarAndArrowStrokeWidth && {
        '--avatarAndArrowStrokeWidth': avatarAndArrowStrokeWidth,
      }),
    };
  },
);

export const sdkData = withCompInfo<
  LoginSocialBarSdkData,
  LoginSocialBarDefinition,
  LoginSocialBarSdkData
>()([], (_deps, carmiSdkData) => {
  return carmiSdkData;
});

export const stateRefs = withStateRefsValues([
  'memberDetails',
  'reportBi',
  'currentUrl',
  'navigateTo',
  'logout',
  'promptLogin',
]);

export default createComponentMapperModel({ props, css, sdkData, stateRefs });
