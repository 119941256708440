import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  getValidationControllerProps,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import type {
  ICheckboxGroupMapperProps,
  ICheckboxGroupControllerProps,
  ICheckboxGroupProps,
} from '../CheckboxGroup.types';

export default withCompController<
  ICheckboxGroupMapperProps,
  ICheckboxGroupControllerProps,
  ICheckboxGroupProps
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { updateProps } = controllerUtils;
  const { reportBi } = stateValues;
  const { setValidityIndication } = getValidationControllerProps(updateProps);
  const { compId, language, trackClicksAnalytics, ...restMapperProps } =
    mapperProps;

  const reportBiOnClick: ICheckboxGroupControllerProps['reportBiOnClick'] = (
    event,
    option,
  ) => {
    const { label, fullNameCompType } = restMapperProps;

    tryReportAnalyticsClicksBi(reportBi, {
      language,
      trackClicksAnalytics,
      actionName: 'None',
      value: option.checked,
      elementTitle: option.label,
      elementType: fullNameCompType,
      elementGroup: AnalyticsClicksGroups.Input,
      element_id: compId ?? event.currentTarget.id,
      details: { value: option.value, groupLabel: label },
    });
  };

  return {
    ...mapperProps,
    reportBiOnClick,
    onOptionsChange: options => {
      updateProps({
        options,
      });
    },
    setValidityIndication,
  };
});
