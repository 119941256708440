import { IVideoLegacyProps } from '../VideoLegacy.types';

export const TestIds = {
  DAILYMOTION: 'dailymotion',
  YOUTUBE: 'youtube',
  FACEBOOK: 'facebook',
  VIMEO: 'vimeo',
} as Record<IVideoLegacyProps['videoType'], string>;

export const componentNameKey = 'CookieConsent_Dataset_Component_Video';
export const componentNameDefault = 'Video';
