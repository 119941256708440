import React from 'react';
import { ChevronRight, WixLogoNew } from '../../common/assets/logos';
import { wixLogoPlaceHolder } from './constants';
import style from './style/FreemiumBannerMobileNewBanner.scss';
import { TestIds } from '../../common/constants';

type GenericContentProps = { bannerText: string };

const GenericContent: React.FC<GenericContentProps> = ({ bannerText }) => {
  const [textBeforeLogo, textAfterLogo] = bannerText.split(wixLogoPlaceHolder);
  return (
    <div className={style.content}>
      <div>{textBeforeLogo}</div>

      <div data-testid={TestIds.logo} className={style.wixLogoWrapper}>
        <WixLogoNew className={style.wixLogo} />
      </div>

      <div>{textAfterLogo}</div>

      <div className={style.wixChevronLogoWrapper}>
        <ChevronRight />
      </div>
    </div>
  );
};
export default GenericContent;
