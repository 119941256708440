import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  SpotifyPlayerDefinition,
  SpotifyPlayerCSSVars,
  SpotifyPlayerSize,
  SpotifyPlayerMapperProps,
  SpotifyPlayerStateRefs,
} from '../SpotifyPlayer.types';
import {
  TRANSLATION_FEATURE,
  TRANSLATION_KEY,
  consetPolicyKeys,
} from '../constants';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';

export const props = withCompInfo<
  SpotifyPlayerMapperProps,
  SpotifyPlayerDefinition
>()(
  ['compData', 'compProps', 'translate', ...consentPolicyPropsDeps],
  compInfo => {
    const { compData, compProps, translate } = compInfo;
    let iframeSrc = null;

    const { uri } = compData;
    const { color, style } = compProps;
    if (uri !== '') {
      iframeSrc = `https://embed.spotify.com/?${new URLSearchParams({
        uri,
        color,
        style,
      })}`;
    }

    const originalProps = {
      iframeSrc,
      translations: {
        ariaLabel: translate(TRANSLATION_FEATURE, TRANSLATION_KEY),
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

export const css = withCompInfo<
  SpotifyPlayerCSSVars,
  SpotifyPlayerDefinition
>()(
  ['compData', 'compProps', 'compLayout', 'isOneDocMigrated'],
  ({ compData, compProps, compLayout, isOneDocMigrated }) => {
    let placeholderDisplay = 'none';
    const sizeLimits: Record<
      SpotifyPlayerSize,
      Record<'minW' | 'minH', number>
    > = {
      compact: { minW: 250, minH: 80 },
      large: { minW: 250, minH: 330 },
    };

    const getHeight = () => {
      const height = sizeLimits[compProps.size].minH + 2;
      return compProps.size === 'compact'
        ? height
        : height + (compLayout.width || 0) - sizeLimits[compProps.size].minW;
    };

    if (compData.uri === '') {
      placeholderDisplay = 'block';
    }

    return {
      '--placeholder-display': placeholderDisplay,
      ...(!isOneDocMigrated && {
        width: `${compLayout.width}px`,
        height: `${getHeight()}px`,
      }),
    };
  },
);

export const stateRefs = withStateRefsValues<keyof SpotifyPlayerStateRefs>([
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
