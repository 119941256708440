import React, { createContext, useContext } from 'react';
import type {
  ISelectableContainerContextProps,
  ISelectableContainerContextProviderProps,
} from './types';

const SelectableContainerContext =
  createContext<ISelectableContainerContextProps>({
    inputName: '',
    selected: false,
    inputType: 'radio',
    inputLabel: '',
    inputAriaLabel: '',
    inputRef: null,
    onFocus: () => {},
    onBlur: () => {},
  });

export const useSelectableContainerContext = () => {
  return useContext(SelectableContainerContext);
};

export const SelectableContainerContextProvider = ({
  children,
  ...props
}: ISelectableContainerContextProviderProps) => (
  <SelectableContainerContext.Provider value={props}>
    {children}
  </SelectableContainerContext.Provider>
);
