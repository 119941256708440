import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  migrateFields,
  castToInheritIfNeeded,
  castFromInheritIfNeeded,
  getOppositeAlign,
} from '@wix/editor-elements-common-utils';
import {
  SignatureInputDefinition,
  ISignatureInputMapperProps,
  SignatureInputStyleProperties,
  SignatureInputLabelPaddings,
  SignatureInputCSSVars,
  SignatureInputCssCarmiData,
} from '../SignatureInput.types';

const isThemeColor = (color: string) => /^color_\d+$/.test(color);
const getFromColorMap = (rawColor: string, colorsMap: Array<string>) => {
  const [, themeColorIndex] = rawColor.split('_');
  const i = parseInt(themeColorIndex, 10);
  return typeof i === 'number' && isNaN(i) === false ? colorsMap[i] : rawColor;
};

export const props = withCompInfo<
  ISignatureInputMapperProps,
  SignatureInputDefinition
>()(
  ['compData', 'compProps', 'styleProperties', 'siteColors'],
  ({ compData, compProps, styleProperties, siteColors }) => {
    const { signatureSize, signatureColor: signatureColorProp } =
      styleProperties;

    return {
      label: compData.titleText,
      clearButtonText: compData.clearButtonText,
      required: compProps.required || false,
      isDisabled: compProps.isDisabled || false,
      signatureSize: signatureSize.endsWith('px')
        ? signatureSize.slice(0, -2)
        : signatureSize,
      signatureColor: isThemeColor(signatureColorProp)
        ? getFromColorMap(signatureColorProp, siteColors)
        : signatureColorProp,
    };
  },
);

export const css = withCompInfo<
  SignatureInputCSSVars,
  SignatureInputDefinition,
  SignatureInputCssCarmiData,
  { styleProperties: SignatureInputStyleProperties }
>()(
  ['compProps', 'compData', 'styleProperties'],
  ({ compProps, compData, styleProperties }, cssVars) => {
    const { titlePaddingStart, titleMarginBottom } = compProps;
    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
      buttonDirection = 'inherit',
    } = compData;
    const {
      align = 'start',
      labelAlign = 'start',
      buttonAlign = 'end',
    } = styleProperties;

    const realLabelAlign = castFromInheritIfNeeded(labelAlign, align);
    const labelPaddigns =
      realLabelAlign === 'center'
        ? {
            '--labelPadding_start': '0',
            '--labelPadding_end': '0',
          }
        : ({
            [`--labelPadding_${realLabelAlign}`]: `${titlePaddingStart}px`,
            [`--labelPadding_${getOppositeAlign(realLabelAlign)}`]: '0',
          } as SignatureInputLabelPaddings);

    return {
      ...cssVars,
      ...labelPaddigns,
      '--direction': direction,
      '--align': align,
      '--labelDirection': labelDirection,
      '--labelAlign': labelAlign,
      '--inputDirection': inputDirection,
      '--buttonDirection': buttonDirection,
      '--buttonAlign': buttonAlign,
      '--labelMarginBottom': `${titleMarginBottom}px`,
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: ['direction'],
        enhancer: direction => castToInheritIfNeeded(direction, 'ltr'),
      },
    ]),
  ],
);

export default createComponentMapperModel({ props, css });
