
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "TabsList2027980309",
      {"classes":{"root":"TabsList2027980309__root","tabsList":"TabsList2027980309__tabsList","tab":"TabsList2027980309__tab","scrollButton":"TabsList2027980309__scrollButton","label":"TabsList2027980309__label"},"vars":{"orientation":"--orientation","focus-ring-box-shadow":"--focus-ring-box-shadow"},"stVars":{"activeIndicatorColor":"color-8","tabsListOrientation":"var(--tabs-list-orientation, horizontal)","tabsListWidth":"var(--tabs-list-width, unset)","tabsListHeight":"var(--tabs-list-height, auto)","tabsListRowGap":"var(--tabs-list-items-row-gap, 0)","tabsListColGap":"var(--tabs-list-items-col-gap, 0)","tabsListAlignContent":"var(--tabs-list-content-alignment, flex-start)","tabsListHorizontalPadding":"var(--tabs-list-horizontal-padding, 20px)","tabsListVerticalPadding":"var(--tabs-list-vertical-padding, 20px)","tabsListFlexGrow":"var(--tabs-list-item-flex-grow, 0)","tabsListItemBorderWidth":"var(--tabs-list-item-border-width, 0)","tabsFirstChildMarginStart":"var(--tabs-list-first-child-margin-start, 0)","tabsLastChildMarginEnd":"var(--tabs-list-last-child-margin-end, 0)","tabsListJustifyContent":"var(--tabs-list-justify-content, normal)","tabsListOverflowX":"var(--tabs-list-overflow-x, visible)","tabsListOverflowY":"var(--tabs-list-overflow-y, visible)","tabsListFlexWrap":"var(--tabs-list-flex-wrap, nowrap)","tabsListDirection":"var(--tabs-list-direction, initial)","tabsListBackButtonInlineEnd":"var(--tabs-list-back-button-inline-end, unset)","tabsListForwardButtonInlineStart":"var(--tabs-list-forward-button-inline-start, unset)","tabsListBackButtonBlockEnd":"var(--tabs-list-back-button-block-end, unset)","tabsListForwardButtonBlockStart":"var(--tabs-list-forward-button-block-start, unset)","tabsListFlexDirection":"var(--tabs-list-flex-direction, row)","tabsListPosition":"var(--tabs-list-position, relative)","tabLabelOverflow":"var(--tab-label-overflow, unset)","tabLabelTextOverflow":"var(--tab-label-text-overflow, unset)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  