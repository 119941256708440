import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IPopupContainerMapperProps,
  IPopupContainerControllerProps,
  IPopupContainerProps,
  IPopupContainerStateRefs,
} from '../PopupContainer.types';

export default withCompController<
  IPopupContainerMapperProps,
  IPopupContainerControllerProps,
  IPopupContainerProps,
  IPopupContainerStateRefs
>(({ mapperProps, stateValues }) => {
  const { pageId } = stateValues;

  return {
    ...mapperProps,
    pageId,
  };
});
