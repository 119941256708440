import {
  createComponentPreviewMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  FormContainerProps,
  FormContainerDefinition,
} from '@wix/thunderbolt-components';
import { IFormContainerProps } from '../FormContainer.types';

export const props = withCompInfo<
  FormContainerProps,
  FormContainerDefinition,
  IFormContainerProps
>()([], (_deps, carmiData) => ({ ...carmiData, inlineBorder: true }));

export default createComponentPreviewMapperModel({
  props,
});
