import * as React from 'react';
import { RatingIconProps } from '../../RatingsInput.types';
import { TestIds } from '../constants';

export const RatingIcon: React.FC<RatingIconProps> = props => {
  const { svg, className, onClick } = props;
  return (
    <span
      className={className}
      onClick={onClick}
      data-testid={TestIds.ratingsIcon}
    >
      {svg}
    </span>
  );
};
