import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import InnerShadowScreen from '../../../../ScreenWidthContainer/viewer/skinComps/InnerShadowScreen/InnerShadowScreen';
import HeaderContainer from '../../HeaderContainer';

const InnerShadowScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => (
  <HeaderContainer {...props} skin={InnerShadowScreen}></HeaderContainer>
);

export default InnerShadowScreenHeader;
