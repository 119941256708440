import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISelectionTagsListControllerProps,
  ISelectionTagsListMapperProps,
  ISelectionTagsListProps,
} from '../SelectionTagsList.types';

export default withCompController<
  ISelectionTagsListMapperProps,
  ISelectionTagsListControllerProps,
  ISelectionTagsListProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    onChange: nextValue => {
      controllerUtils.updateProps({ value: nextValue });
    },
  };
});
