
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IExternalComponentProps,
  IExternalComponentMapperProps,
  IExternalComponentControllerProps,
  IExternalComponentStateValues,
} from '../../../components/ExternalComponent/ExternalComponent.props';

export default withCompController<
  IExternalComponentMapperProps,
  IExternalComponentControllerProps,
  IExternalComponentProps,
  IExternalComponentStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
