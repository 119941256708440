import classNames from 'clsx';
import * as React from 'react';
import styles from './styles/Grid.scss';

const ArrowIcon = () => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={classNames(styles.arrowIcon)}
  >
    <polygon points="12.8,20 12,19.4 17.7,13 12,6.6 12.8,6 19,13" />
  </svg>
);

export const PaginationArrow = ({ isNext = false }) => (
  <div
    className={classNames(
      styles.arrowContainer,
      isNext ? styles.next : styles.prev,
    )}
  >
    <ArrowIcon />
    <ArrowIcon />
  </div>
);
