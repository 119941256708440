export enum ScriptQueryParams {
  legacyGalleriesScriptPath = 'legacyGalleriesScriptPath',
  impressScriptPath = 'impressScriptPath',
  seedRandom = 'seedRandom',
  utils = 'utils',
  html5Dataset = 'html5Dataset',
  app = 'app',
  classlist = 'classlist',
}

export enum AssetQueryParams {
  leftArrowImagePath = 'leftArrowImagePath',
  rightArrowImagePath = 'rightArrowImagePath',
}
