import * as React from 'react';
import { Slider } from 'wix-style-react';
import type { SliderProps as WSRSliderProps } from 'wix-style-react';
import type { IRangeSliderProps } from '../../RangeSlider.types';

type ISteppedSliderProps = Pick<
  IRangeSliderProps,
  'min' | 'max' | 'prefix' | 'suffix' | 'width'
> &
  Pick<
    WSRSliderProps,
    | 'id'
    | 'step'
    | 'value'
    | 'rtl'
    | 'className'
    | 'dataHook'
    | 'disabled'
    | 'pushable'
    | 'ariaLabelForHandle'
    | 'onChange'
  >;

interface Marks {
  [key: number]: string | number;
}

const maxTickDensity: number = 1 / 8;

const getValueEnhancer =
  (prefix: string, suffix: string) => (value: number) => {
    return `${prefix} ${value} ${suffix}`;
  };

const getSliderMarks = (
  min: number,
  max: number,
  enhancer: (v: number) => string,
  step: WSRSliderProps['step'],
  sliderWidth?: number,
): Marks | undefined => {
  const marks: Marks = {};
  if (min === null || max === null) {
    min = 0;
    max = 100;
  }
  const total = max - min;
  const totalTickCount = step ? total / step : total;
  sliderWidth = sliderWidth ? sliderWidth : 1;
  const density = Math.min(totalTickCount / sliderWidth, maxTickDensity);
  let adjustedStepRaw = total / (sliderWidth * density);
  if (adjustedStepRaw < 0.05) {
    adjustedStepRaw = 0.05;
  }
  const adjustedStep = parseFloat(adjustedStepRaw.toFixed(1));
  for (let index = min; index <= max; index += adjustedStep) {
    index = parseFloat(index.toFixed(1));
    marks[index] = enhancer(index);
  }
  if (!marks[max]) {
    marks[max] = enhancer(max);
  }
  return marks;
};

const SteppedSlider: React.FC<ISteppedSliderProps> = props => {
  const {
    className,
    dataHook,
    id,
    rtl,
    min,
    max,
    step,
    value,
    prefix,
    suffix,
    disabled,
    width,
    pushable,
    ariaLabelForHandle,
    onChange,
  } = props;
  const { marks, enhancer } = React.useMemo(() => {
    const valueEnhancer = getValueEnhancer(prefix, suffix);
    const sliderMarks = getSliderMarks(min, max, valueEnhancer, step, width);
    return { marks: sliderMarks, enhancer: valueEnhancer };
  }, [min, max, prefix, suffix, step, width]);

  return (
    <Slider
      className={className}
      dataHook={dataHook}
      id={id}
      rtl={rtl}
      min={min ?? 0}
      max={max ?? 100}
      value={value}
      step={step}
      marks={marks}
      displayTooltip={true}
      allowCross={false}
      onChange={onChange}
      disabled={disabled}
      pushable={pushable}
      ariaLabelForHandle={ariaLabelForHandle}
      enhanceValue={enhancer}
    />
  );
};

export default SteppedSlider;
