import { withCompController } from '@wix/editor-elements-integrations';
import type {
  CustomElementComponentControllerProps,
  ICustomElementComponentMapperProps,
} from '../types';

export default withCompController<
  ICustomElementComponentMapperProps,
  CustomElementComponentControllerProps
>(({ mapperProps, controllerUtils }) => {
  const { updateStyles } = controllerUtils;
  return {
    ...mapperProps,
    fixHeight: ({ height }) => {
      if (height > 0) {
        updateStyles({
          '--custom-element-height': `${height}px !important`,
          '--custom-element-min-height': `auto !important`,
        });
      }
    },
    updateMinHeight: ({ height }) => {
      updateStyles({
        '--custom-element-min-height': `${height}px !important`,
      });
    },
  };
});
