import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { css as viewerCssMapper } from './MegaMenuContainerItem.mapper';

const css = composeMapper()(
  viewerCssMapper,
  { add: ['componentViewMode', 'hasResponsiveLayout'] },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const isEditing = dependencies.componentViewMode === 'editor';

    /* during resize viewerApi adds "position:fixed" to the root div,
       which breaks layout of the container */
    const overridePositionForResize = isEditing &&
      dependencies.hasResponsiveLayout && {
        position: 'static !important',
      };

    return {
      ...viewerCss,
      ...overridePositionForResize,
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
});
