import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  getValidationControllerProps,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IRadioGroupMapperProps,
  IRadioGroupControllerProps,
  IRadioGroupProps,
  IRadioGroupStateValues,
} from '../RadioGroup.types';

export default withCompController<
  IRadioGroupMapperProps,
  IRadioGroupControllerProps,
  IRadioGroupProps,
  IRadioGroupStateValues
>(({ controllerUtils, mapperProps, stateValues }) => {
  const {
    compId,
    language,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const { setValidityIndication, onValueChange } = getValidationControllerProps(
    controllerUtils.updateProps,
  );

  const reportBiOnClick: IRadioGroupControllerProps['reportBiOnClick'] = (
    event,
    option,
  ) => {
    const { reportBi } = stateValues;

    tryReportAnalyticsClicksBi(reportBi, {
      language,
      trackClicksAnalytics,
      value: option?.label,
      elementType: fullNameCompType,
      elementTitle: restMapperProps.label,
      elementGroup: AnalyticsClicksGroups.Input,
      element_id: compId ?? event.currentTarget.id,
      details: { options: restMapperProps.options?.map(({ label }) => label) },
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
    setValidityIndication,
    setValue: onValueChange,
  };
});
