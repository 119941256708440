import type {
  Direction,
  LogicalAlignment,
  PhysicalAlignment,
  WithInherit,
  FlexDirection,
  FlexDirectionCSS,
} from './types';

const physicalToLogicalAlignmentMap: Record<
  PhysicalAlignment,
  LogicalAlignment
> = {
  left: 'start',
  center: 'center',
  right: 'end',
};

const directionToLogicalAlignmentMap: Record<Direction, LogicalAlignment> = {
  ltr: 'start',
  rtl: 'end',
};

type IValue = Direction | LogicalAlignment;

export const castToInheritIfNeeded = <V extends IValue>(
  value: V,
  parentValue?: V,
): WithInherit<V> => (value === parentValue ? 'inherit' : value);

export const castFromInheritIfNeeded = <V extends IValue>(
  value: WithInherit<V> | undefined,
  parentValue: V,
): V => (!value || value === 'inherit' ? parentValue : value);

export const convertPhysicalAlignmentToLogical = (
  physicalAlignment: PhysicalAlignment,
): LogicalAlignment => physicalToLogicalAlignmentMap[physicalAlignment];

export const convertDirectionToLogicalAlignment = (
  direction: Direction,
): LogicalAlignment => directionToLogicalAlignmentMap[direction];

export const getOppositeAlign = (align: LogicalAlignment) =>
  align === 'start' ? 'end' : 'start';

export const convertDirectionToFlexDirection = (
  direction: Direction,
): FlexDirection => {
  return direction === 'rtl' ? 'rowReverse' : 'row';
};

export const getOppositeFlexDirection = (
  flexDirection: FlexDirection,
): FlexDirection => {
  if (flexDirection.startsWith('row')) {
    return flexDirection === 'row' ? 'rowReverse' : 'row';
  }
  return flexDirection === 'column' ? 'columnReverse' : 'column';
};

export const convertFlexDirectionToCssFlexDirection = (
  flexDirection: FlexDirection,
): FlexDirectionCSS => {
  switch (flexDirection) {
    case 'rowReverse':
      return 'row-reverse';
    case 'columnReverse':
      return 'column-reverse';
    default:
      return flexDirection;
  }
};
