import React from 'react';

import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import type { IExternalComponentProps } from '../ExternalComponent.types';
import PreviewExternalComponent from './components/PreviewExternalComponent/PreviewExternalComponent';
import type { ExternalComponentPreviewProps } from './components/PreviewExternalComponent/PreviewExternalComponent.types';

function withComponentPreview(): IComponentPreviewWrapper<
  IExternalComponentProps,
  ExternalComponentPreviewProps
> {
  return (
    props: PreviewWrapperProps<
      IExternalComponentProps,
      ExternalComponentPreviewProps
    >,
  ) => {
    const { previewWrapperProps, className = '', ...viewerProps } = props;

    return (
      <PreviewExternalComponent
        className={className}
        {...viewerProps}
        {...previewWrapperProps}
      />
    );
  };
}

export default () => createComponentPreviewEntry(withComponentPreview());
