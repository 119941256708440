import React from 'react';
import { platformIds, sharerData } from '../constants';
import type { ButtonContentWrapperProps } from '../types';
import CopyButtonWrapper from './CopyButtonWrapper';
import { classes, st } from './style/ShareButton.component.st.css';
import { classes as shareButtonsClasses } from './style/ShareButtons.component.st.css';

const ButtonContentWrapper: React.FC<ButtonContentWrapperProps> = ({
  id,
  children,
  platformId,
  url,
  ariaLabel,
  onCopyButtonClicked,
  linkCopiedText,
  isTooltipForCopyToClipboardEnabled,
}) => (
  <>
    {platformId === platformIds.copyButton ? (
      <CopyButtonWrapper
        id={id}
        url={url}
        ariaLabel={ariaLabel}
        onCopyButtonClicked={onCopyButtonClicked}
        linkCopiedText={linkCopiedText}
        isTooltipForCopyToClipboardEnabled={isTooltipForCopyToClipboardEnabled}
      >
        {children}
      </CopyButtonWrapper>
    ) : (
      <a
        id={id}
        className={st(classes.root, shareButtonsClasses.button)}
        target="popup"
        href={url}
        onClick={event => {
          event.preventDefault();
          window.open(url, sharerData.name, sharerData.params);
        }}
        rel="noreferrer"
        data-testid={platformId}
        aria-label={ariaLabel}
      >
        {children}
      </a>
    )}
  </>
);

export default ButtonContentWrapper;
