import * as React from 'react';
import type { IShareButtonProps } from '../types';
import {
  COPY_CONFIRMATION_TIMEOUT,
  platformIds,
  toastTextKey,
} from '../constants';
import getPlatformIdWithFallback from '../utils/getPlatformIdWithFallback';
import ShareButtonIcon from './ShareButtonIcon';
import { classes, st } from './style/ShareButton.component.st.css';
import ButtonContentWrapper from './ButtonContentWrapper';

const ShareButton: React.FC<IShareButtonProps> = ({
  id,
  platformId,
  value,
  label,
  illustration,
  url = '',
  displayLabel,
  displayIcon,
  translations,
  isTooltipForCopyToClipboardEnabled,
}) => {
  const platformIdWithFallback = getPlatformIdWithFallback(platformId, value);
  const translatedCopyLinkLabel = translations[label];
  const initializedLabel =
    platformIdWithFallback === platformIds.copyButton && translatedCopyLinkLabel
      ? translatedCopyLinkLabel
      : label;
  const [buttonLabel, setButtonLabel] = React.useState(initializedLabel);

  const linkCopiedText = translations[toastTextKey] ?? 'Link Copied!';

  const onCopyButtonClicked = () => {
    setButtonLabel(linkCopiedText);
    setTimeout(() => {
      setButtonLabel(initializedLabel);
    }, COPY_CONFIRMATION_TIMEOUT);
  };

  return (
    <ButtonContentWrapper
      id={id}
      platformId={platformIdWithFallback}
      url={url}
      onCopyButtonClicked={onCopyButtonClicked}
      ariaLabel={buttonLabel}
      linkCopiedText={linkCopiedText}
      isTooltipForCopyToClipboardEnabled={isTooltipForCopyToClipboardEnabled}
    >
      {displayIcon && (
        <ShareButtonIcon
          className={st(classes.icon)}
          svg={illustration?.svgId ?? ''}
        />
      )}
      {displayLabel && <span className={st(classes.label)}>{buttonLabel}</span>}
    </ButtonContentWrapper>
  );
};

export default ShareButton;
