import React from 'react';
import { VerticalLineProps } from '../VerticalLine';
import skinsStyle from './styles/VerticalLineSkin.scss';

const VerticalLineSkin: React.FC<VerticalLineProps> = ({
  id,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={skinsStyle.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div className={skinsStyle.lineCnt}>
        <div className={skinsStyle.line} />
      </div>
      <div className={skinsStyle.topKnobCnt} />
      <div className={skinsStyle.middleKnobCnt} />
      <div className={skinsStyle.bottomKnobCnt} />
    </div>
  );
};

export default VerticalLineSkin;
