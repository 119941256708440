import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  withComponentPreview,
  ScrollableContainerTypes,
  withScrollAPIForResponsiveContainer,
} from '@wix/editor-elements-preview-utils';
import React from 'react';

export default (
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
) =>
  createComponentPreviewEntry(
    withComponentPreview(withScrollAPIForResponsiveContainer(ViewerComponent)),
  );
