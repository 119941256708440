import * as React from 'react';
import { VerticalLineProps } from '../VerticalLine';
import { IVerticalLineProps } from '../../../VerticalLine.types';
import VerticalLine from '../../VerticalLine';
import skinsStyle from './styles/VerticalDottedLine.scss';
import { BaseVerticalLine } from './BaseVerticalLine';

const VerticalDottedLineSkin: React.FC<VerticalLineProps> = props => (
  <BaseVerticalLine {...props} skinsStyle={skinsStyle} />
);

const VerticalDottedLine: React.FC<
  Omit<IVerticalLineProps, 'skin'>
> = props => {
  return <VerticalLine {...props} skin={VerticalDottedLineSkin} />;
};

export default VerticalDottedLine;
