import * as React from 'react';
import ResponsiveRepeater from '../../ResponsiveRepeater';
import type { IResponsiveRepeaterCustomElement } from '../../../ResponsiveRepeater.types';
import styles from '../Responsive/Responsive.scss';

const ResponsiveSkin: React.FC<IResponsiveRepeaterCustomElement> = props => {
  return <ResponsiveRepeater {...props} classes={styles} useCustomElement />;
};

export default ResponsiveSkin;
