import React, { forwardRef, useMemo, useReducer } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  PreviewWrapperProps,
  IComponentPreviewWrapper,
} from '@wix/editor-elements-types/thunderboltPreview';
import { usePreviewState as useStylablePreviewState } from '@wix/editor-elements-preview-utils';
import { useCompStyleElementObserver } from '@wix/thunderbolt-elements/providers/useCompStyleElementObserver';
import { useResizeObserver } from '@wix/thunderbolt-elements/providers/useResizeObserver';
import type {
  IStylableHorizontalMenuUITypeProps,
  IStylableHorizontalMenuImperativeActions,
  IStylableHorizontalMenuPreviewWrapperProps,
} from '../StylableHorizontalMenu.types';
import { useEditSlotId } from '../../../common/menu/megaMenuParent';
import { PREVIEW_STATE, Selectors } from './previewWrapperUtils/constants';
import { forceOpenIfNeeded, useMinSize } from './previewWrapperUtils';
import { classes } from './StylableHorizontalMenu.component.st.css';

export const previewStateMap = {
  [Selectors.scrollButton]: {
    selectors: `.${classes.scrollButton}`,
    type: 'plural',
    state: 'isVisible',
  },
};

type IStylableHorizontalMenuUITypePreviewProps =
  IStylableHorizontalMenuUITypeProps & {
    forceState?: { selectionSelector: string };
  };

const noop = () => null;

function withComponentPreview(
  ViewerComponent: React.ComponentType<IStylableHorizontalMenuUITypeProps>,
): IComponentPreviewWrapper<
  IStylableHorizontalMenuUITypePreviewProps,
  IStylableHorizontalMenuPreviewWrapperProps
> {
  return forwardRef<
    IStylableHorizontalMenuImperativeActions,
    PreviewWrapperProps<
      IStylableHorizontalMenuUITypePreviewProps,
      IStylableHorizontalMenuPreviewWrapperProps
    >
  >((props, ref) => {
    const { items, previewWrapperProps, forceState, ...viewerProps } = props;
    const compPreviewState = previewWrapperProps?.compPreviewState;
    const cssSelector = forceState?.selectionSelector || '';
    const { id, menuMode, submenuMode } = viewerProps;

    const editSlotId = useEditSlotId(previewWrapperProps?.compPreviewState);
    // when key is updated, items are re-generated and component is re-rendered
    const [itemsRerenderKey, forceItemsRerender] = useReducer(
      key => key + 1,
      1,
    );

    useStylablePreviewState(id, cssSelector, previewStateMap);

    // rerender on flyout mode alignment is changed in the layout panel
    useCompStyleElementObserver({
      compId: id,
      shouldObserve:
        compPreviewState === PREVIEW_STATE.SUBMENU_TAB &&
        submenuMode === 'flyout',
      callback: forceItemsRerender,
    });

    useResizeObserver({
      elem: document.body,
      callback: editSlotId ? forceItemsRerender : noop,
    });

    const itemsEnhanced = useMemo(
      () =>
        forceOpenIfNeeded({
          items,
          editSlotId,
          compPreviewState,
          cssSelector,
        }),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [items, editSlotId, compPreviewState, cssSelector, itemsRerenderKey],
    );

    return (
      <ViewerComponent
        {...viewerProps}
        items={itemsEnhanced}
        style={useMinSize({ id, menuMode })}
        ref={ref}
      />
    );
  });
}

export default (
  ViewerComponent: React.ComponentType<IStylableHorizontalMenuUITypePreviewProps>,
) =>
  createComponentPreviewEntry<
    IStylableHorizontalMenuUITypePreviewProps,
    IStylableHorizontalMenuPreviewWrapperProps
  >(withComponentPreview(ViewerComponent));
