import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';
import {
  IRefComponentProps,
  IRefComponentWrapperProps,
  IRefComponentCss,
} from '../RefComponent.types';

const props = withCompPreviewProps<
  IRefComponentProps,
  any,
  IRefComponentProps,
  IRefComponentWrapperProps
>()(['structure'], ({ structure }, carmiData: IRefComponentProps) => {
  return {
    ...carmiData,
    previewWrapperProps: {
      firstChildId: structure.components && structure.components[0],
    },
  };
});

const css = withCompPreviewInfo<any, any, IRefComponentCss>()(
  ['componentViewMode', 'experiments'],
  ({ componentViewMode, experiments }, carmiData: IRefComponentCss) => {
    return {
      ...carmiData,
      '--ref-container-pointer-events':
        experiments['specs.editor-elements.allowRefPointerEventsInEditMode'] ===
          true && componentViewMode === 'editor'
          ? 'auto'
          : 'none',
    };
  },
);
export default createComponentPreviewMapperModel({
  props,
  css,
});
