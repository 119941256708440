import type {
  FileUploadPluginFile,
  IRichContentViewerProps,
} from '../../RichContentViewer.types';
import * as React from 'react';

export const useFileUrlResolution = ({
  onResolveFileUrlRequest,
  fileResolutions,
}: Pick<
  IRichContentViewerProps,
  'onResolveFileUrlRequest' | 'fileResolutions'
>) => {
  const fileResolveRegistry = React.useRef<
    Map<
      string,
      {
        resolve: (url: string) => void;
        promise: Promise<string>;
        status: 'PENDING' | 'RESOLVED';
      }
    >
  >(new Map());

  React.useEffect(() => {
    if (!fileResolutions) {
      return;
    }

    Object.entries(fileResolutions).forEach(([key, value]) => {
      const resolveEntry = fileResolveRegistry.current.get(key);

      if (resolveEntry?.status === 'PENDING') {
        resolveEntry.resolve(value);
        resolveEntry.status = 'RESOLVED';
      }
    });
  }, [fileResolutions]);

  const resolveFileUrl = React.useCallback(
    async (file: FileUploadPluginFile) => {
      if (!onResolveFileUrlRequest) {
        return;
      }
      const key = file.id;
      const resolveEntry = fileResolveRegistry.current.get(key);

      if (resolveEntry) {
        const { promise } = resolveEntry;
        onResolveFileUrlRequest(file);
        return promise;
      }

      let resolve: (url: string) => void;
      const promise = new Promise<string>(res => {
        resolve = res;
      });

      fileResolveRegistry.current.set(key, {
        // @ts-expect-error used before assign
        resolve,
        promise,
        status: 'PENDING',
      });

      onResolveFileUrlRequest(file);
      return promise;
    },
    [onResolveFileUrlRequest],
  );

  const filePluginConfig = React.useMemo(() => {
    if (onResolveFileUrlRequest) {
      return { resolveFileUrl };
    }

    return undefined;
  }, [onResolveFileUrlRequest, resolveFileUrl]);

  return { filePluginConfig };
};
