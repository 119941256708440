import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  SocialAuthDefinition,
  ISocialAuthMapperProps,
} from '../SocialAuth.types';

export const props = withCompInfo<
  ISocialAuthMapperProps,
  SocialAuthDefinition
>()(['compProps', 'skin', 'language'], ({ compProps, skin, language }) => {
  const {
    isSocialLoginFacebookEnabled,
    isSocialLoginGoogleEnabled,
    mode,
    isSmallIcons,
  } = compProps;
  return {
    skin,
    language,
    isSocialLoginGoogleEnabled,
    isSocialLoginFacebookEnabled,
    mode,
    isSmallIcons,
  };
});

const stateRefs = withStateRefsValues(['reportBi']);

export default createComponentMapperModel({ props, stateRefs });
