import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { getCompStyles } from '../../../tpaGallery';
import {
  AccordionDefinition,
  AccordionPropsPlatformProps,
  AccordionPropsCarmiData,
  AccordionCSSVars,
} from '../Accordion.types';
import {
  generateCss,
  generateSdkData,
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  textColor: 'color1',
  descriptionColor: 'color2',
  textBackgroundColor: 'color3',
  borderColor: 'color4',
};

export const props = withCompInfo<
  AccordionPropsPlatformProps,
  AccordionDefinition,
  AccordionPropsCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => ({
    ...propsResolver<AccordionDefinition>(
      data,
      carmiProps,
      'Accordion/Accordion.html',
    ),
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
  }),
);

const sdkData = generateSdkData<AccordionDefinition>();

const css = generateCss<AccordionCSSVars, AccordionDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
