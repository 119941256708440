
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SlideshowButton2197735472",
      {"classes":{"root":"SlideshowButton2197735472__root","disabled":"SlideshowButton2197735472__disabled"},"vars":{"visibility":"--SlideshowButton2197735472-visibility"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  