export type SpotifyFollowUrlSize =
  | 'small'
  | 'large'
  | 'smallWithCounter'
  | 'largeWithCounter';

type SpotifyFollowUrlType = 'detail' | 'basic';

export type SpotifyFollowUrlDetails = {
  width: number;
  height: number;
  type: SpotifyFollowUrlType;
};

export const DEFAULT_SIZES: Record<
  SpotifyFollowUrlSize,
  SpotifyFollowUrlDetails
> = {
  largeWithCounter: {
    width: 228,
    height: 58,
    type: 'detail',
  },
  large: {
    width: 170,
    height: 60,
    type: 'detail',
  },
  smallWithCounter: {
    width: 162,
    height: 27,
    type: 'basic',
  },
  small: {
    width: 92,
    height: 27,
    type: 'basic',
  },
};

export const PLACEHOLDER_DIMENSIONS: Record<
  SpotifyFollowUrlType,
  Omit<SpotifyFollowUrlDetails, 'type'>
> = {
  basic: { width: 156, height: 25 },
  detail: { width: 225, height: 56 },
};

export const SPOTIFY_FOLLOW_URL = 'https://open.spotify.com/follow/1/';
