import { ISearchBoxProps } from '../../../SearchBox.types';
import { SUGGESTION_ITEM_TEST_ID_PREFIX } from '../../constants';
import { SearchSuggestions } from '../Suggestion.types';

export function buildSuggestions(
  {
    id,
    suggestions,
    onSuggestionGroupSelect,
    onSuggestionSelect,
  }: Pick<
    ISearchBoxProps,
    'id' | 'suggestions' | 'onSuggestionGroupSelect' | 'onSuggestionSelect'
  >,
  activeItemIndex: number,
): SearchSuggestions {
  if (!suggestions?.items) {
    return { ...suggestions, items: [] };
  }

  return {
    ...suggestions,
    items: suggestions.items.map((item, index) => {
      const type = item.type;
      const idSegments = [SUGGESTION_ITEM_TEST_ID_PREFIX, index];
      const testId = idSegments.join('');

      if ('image' in item && item.image) {
        idSegments.push(item.image.name);
      }

      const action = () => {
        const commonSelectParams = { compId: id, syntheticEvent: item };

        if (type === 'group') {
          onSuggestionGroupSelect?.({
            type: 'suggestionGroupSelect',
            ...commonSelectParams,
          });
        } else {
          onSuggestionSelect?.({
            type: 'suggestionSelect',
            ...commonSelectParams,
          });
        }
      };

      return {
        action,
        id: idSegments.join('_'),
        isActive: index === activeItemIndex,
        item: {
          ...item,
          url: type === 'group' ? undefined : item.url,
        },
        testId,
        type,
      };
    }),
  };
}
