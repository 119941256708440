import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { WFacebookCommentProps } from '../WFacebookComment.types';
import { useFacebookComment } from '../../../providers/useFacebook/useFacebook';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import styles from './styles/WFacebookComment.scss';

const WFacebookComment: React.FC<WFacebookCommentProps> = props => {
  const {
    id,
    className,
    colorScheme,
    language,
    numPosts,
    width,
    socialUrl = '',
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const ref = useFacebookComment({
    colorScheme,
    socialUrl,
    language,
    numPosts,
    width,
  });

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className: classNames(className, styles.root),
    ...getDataAttributes(props),
  });

  return (
    <div
      {...baseComponentProps}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className="fb-comments"
        data-href={socialUrl}
        data-width={width}
        data-numposts={numPosts}
        data-colorscheme={colorScheme}
        data-mobile="false"
      />
    </div>
  );
};

export default WFacebookComment;
