import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import {
  usePreviewState,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { ISelectionTagsListProps } from '../SelectionTagsList.types';
import { classes } from './style/SelectionTagsList.st.css';

export default (
  ViewerComponent: React.ComponentType<ISelectionTagsListProps>,
) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps,
    ...viewerProps
  }: PreviewWrapperProps<ISelectionTagsListProps>) {
    const { compPreviewState, shouldResetComponent } =
      previewWrapperProps || {};
    const { id, hideValidityIndication } = viewerProps;

    // Hide validity indication when shouldResetComponent becomes true
    useResetComponent({
      id,
      shouldResetComponent,
      onResetComponent: hideValidityIndication,
    });

    usePreviewState(viewerProps.id, compPreviewState, {
      selected: {
        selectors: `.${classes.tag}`,
        type: 'plural',
      },
      error: {
        selectors: `.${classes.tag}`,
        type: 'plural',
      },
      hover: {
        selectors: `.${classes.tag}`,
        type: 'plural',
      },
      disabled: {
        selectors: `.${classes.tag}`,
        type: 'plural',
      },
    });

    return <ViewerComponent {...viewerProps} />;
  });
