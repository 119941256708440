import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type React from 'react';
import type { ScrollableContainerTypes } from '@wix/editor-elements-preview-utils';
import { withScrollAPIForResponsiveContainer } from '@wix/editor-elements-preview-utils';

export default (
  ViewerComponent: React.ComponentType<ScrollableContainerTypes>,
) =>
  createComponentPreviewEntry(
    withScrollAPIForResponsiveContainer(ViewerComponent),
  );
