import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { getHeightInPixels } from '@wix/editor-elements-common-utils';
import type {
  MatrixGalleryDefinition,
  IMatrixGalleryMapperProps,
  MatrixGalleryCSSVars,
  MatrixGalleryCarmiData,
  MatrixGallerySdkData,
} from '../MatrixGallery.types';
import { translations as translationKeys } from '../constants';
import { getCommonTranslations } from '../../commons/utils';

export const props = withCompInfo<
  IMatrixGalleryMapperProps,
  MatrixGalleryDefinition,
  MatrixGalleryCarmiData
>()(
  ['skin', 'compProps', 'translate'],
  ({ skin, compProps, translate }, carmiData) => {
    const {
      incRows: extraRows,
      maxRows: rows,
      numCols: columns,
      showMoreLabel,
      galleryImageOnClickAction,
      imageMode,
    } = compProps;

    return {
      skin,
      extraRows,
      rows,
      columns,
      items: carmiData.itemsProps,
      showMoreLabel: showMoreLabel || '',
      imageOnClickAction: galleryImageOnClickAction,
      isFitMode:
        imageMode === 'flexibleWidthFixed' &&
        skin !== 'MatrixGalleryCircleSkin',
      translations: {
        galleryAriaLabel:
          translate(
            translationKeys.ARIA_LABEL_NAMESPACE,
            translationKeys.MATRIX_GALLERY_ARIA_LABEL_KEY,
          ) || translationKeys.MATRIX_GALLERY_ARIA_LABEL_DEFAULT,
        ...getCommonTranslations(translate),
      },
    };
  },
);

const MIN_HEIGHT = 70;

const showMoreHeightMap = {
  MatrixGalleryDefaultSkin: 25,
  MatrixGalleryLiftedShadow: 25,
  MatrixGallerySloopy: 25,
  MatrixGalleryCircleSkin: 40,
  MatrixGalleryTextSlideUpSkin: 25,
  MatrixGalleryIronSkin: 40,
  MatrixGalleryScotchTapeSkin: 50,
  MatrixGalleryTextOnCenterSkin: 25,
  MatrixGalleryTransparentSkin: 25,
  TextBottomCustomHeightSkin: 25,
  PolaroidCustomHeightSkin: 25,
  MatrixGallerySeparateTextBoxSkin: 40,
  MatrixGalleryPolaroidSkin: 25,
  MatrixGalleryTextBelowSkin: 25,
  BlogMatrixGallery: 25,
  MatrixGalleryVintage: 25,
  PolaroidMatrixGallery: 0,
} as const;

const getRowHeight = (
  galleryHeight: number,
  margin: number,
  rows: number,
  galleryBottomPadding: number,
) => {
  const totalMargins = margin * (rows - 1);

  return Math.max(
    Math.floor((galleryHeight - totalMargins - galleryBottomPadding) / rows),
    0,
  );
};

export const css = withCompInfo<
  MatrixGalleryCSSVars,
  MatrixGalleryDefinition
>()(
  ['compProps', 'compLayout', 'compSingleLayout', 'skin', 'isOneDocMigrated'],
  ({ compProps, compLayout, compSingleLayout, skin, isOneDocMigrated }) => {
    const height =
      (isOneDocMigrated
        ? getHeightInPixels(compSingleLayout)
        : compLayout.height) ?? MIN_HEIGHT;
    const {
      margin,
      maxRows: rows,
      numCols: columns,
      alignText = 'left',
      itemHeight,
    } = compProps;
    const showMoreContainerHeight = showMoreHeightMap[skin];
    const rowHeight =
      itemHeight || getRowHeight(height, margin, rows, showMoreContainerHeight);

    return {
      ...(!isOneDocMigrated && {
        height: 'auto',
      }),
      '--number-of-columns': columns,
      '--spacing': `${margin}px`,
      '--row-height': `${rowHeight}px`,
      '--text-align': alignText,
      '--show-more-container-height': `${showMoreContainerHeight}px`,
    };
  },
);

export const sdkData = withCompInfo<
  MatrixGallerySdkData,
  MatrixGalleryDefinition
>()(['compData'], ({ compData }) => {
  return {
    imageDisplayMode: 'fill',
    dataId: compData.id,
  };
});

export default createComponentMapperModel({
  props,
  css,
  sdkData,
});
