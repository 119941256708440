import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PinItPinWidgetDefinition,
  PinItPinWidgetMapperProps,
  PinItPinWidgetStateRefs,
} from '../PinItPinWidget.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

export const props = withCompInfo<
  PinItPinWidgetMapperProps,
  PinItPinWidgetDefinition
>()(['compData', ...consentPolicyPropsDeps], compInfo => {
  const originalProps = {
    pinUrl: compInfo.compData.pinId,
  };
  return withConsentPolicyProps(
    compInfo,
    originalProps,
    consetPolicyKeys.componentNameKey,
    consetPolicyKeys.componentNameDefault,
  );
});

export const stateRefs = withStateRefsValues<keyof PinItPinWidgetStateRefs>([
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
