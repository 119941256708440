import React from 'react';

export const LazyTooltipBox = React.lazy(() =>
  import(
    /* webpackChunkName: "ConsentPolicyWrapperTooltipBox" */
    './ToolTipBox'
  ).then(({ TooltipBox }) => ({
    default: TooltipBox,
  })),
);
