import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  SpotifyFollowDefinition,
  SpotifyFollowProps,
  SpotifyFollowCSSVars,
  SpotifyFollowProperties,
} from '../SpotifyFollow.types';
import {
  DEFAULT_SIZES,
  PLACEHOLDER_DIMENSIONS,
  SpotifyFollowUrlSize,
  SPOTIFY_FOLLOW_URL,
} from '../constants';

const sanitizeSpotifyURI = (uri: string) => {
  const spotifyParts = uri.split(':');
  if (spotifyParts.length !== 3) {
    return '';
  }
  return `${spotifyParts[0].toLowerCase()}:${spotifyParts[1].toLowerCase()}:${
    spotifyParts[2]
  }`;
};

const getStyleType = (
  compProps: SpotifyFollowProperties,
): SpotifyFollowUrlSize => {
  if (!compProps.showFollowersCount) {
    return compProps.size;
  }
  return compProps.size === 'small' ? `smallWithCounter` : `largeWithCounter`;
};

const getPlaceholderImage = (compProps: SpotifyFollowProperties) => {
  if (compProps.size === 'small') {
    return compProps.showFollowersCount ? 'basic_show' : 'basic_hide';
  }
  if (compProps.theme === 'light') {
    return compProps.showFollowersCount
      ? 'detail_light_show'
      : 'detail_light_hide';
  }
  return compProps.showFollowersCount ? 'detail_dark_show' : 'detail_dark_hide';
};

export const props = withCompInfo<
  SpotifyFollowProps,
  SpotifyFollowDefinition
>()(['compData', 'compProps'], ({ compData, compProps }) => {
  const { width, height, type } = DEFAULT_SIZES[getStyleType(compProps)];
  const placeholderImage = getPlaceholderImage(compProps);

  if (compData.uri === '') {
    return { iframeSrc: null, width, height, placeholderImage };
  }

  const url = new URL(SPOTIFY_FOLLOW_URL);
  url.searchParams.append('uri', sanitizeSpotifyURI(compData.uri));
  url.searchParams.append('size', type);
  url.searchParams.append('theme', compProps.theme);
  url.searchParams.append(
    'show-count',
    compProps.showFollowersCount ? '1' : '0',
  );

  return { iframeSrc: url.toString(), width, height, placeholderImage };
});

export const css = withCompInfo<
  SpotifyFollowCSSVars,
  SpotifyFollowDefinition
>()(['compProps'], ({ compProps }) => {
  const { type } = DEFAULT_SIZES[getStyleType(compProps)];
  const { width, height } = PLACEHOLDER_DIMENSIONS[type];

  return {
    '--placeholder-width': `${width}px`,
    '--placeholder-height': `${height}px`,
  };
});

export default createComponentMapperModel({ props, css });
