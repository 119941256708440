import {
  castFromInheritIfNeeded,
  convertPhysicalInputAlignmentToDirection,
  convertPhysicalInputAlignmentToLogical,
  getScaledFont,
  LogicalAlignment,
  WithInherit,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { CheckboxGroupProps as CheckboxGroupPlatformProps } from '@wix/thunderbolt-components';
import { generateCheckboxCSSVars } from '../../Checkbox/viewer/Checkbox.mapper';

import {
  ICheckboxGroupStateValues,
  CheckboxGroupCSSVars,
  CheckboxGroupDefinition,
} from '../CheckboxGroup.types';
import { translationKeys } from './constants';

export const props = withCompInfo<
  CheckboxGroupPlatformProps,
  CheckboxGroupDefinition,
  CheckboxGroupPlatformProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'compProps',
    'trackClicksAnalytics',
    'translate',
    'experiments',
  ],
  (compInfo, carmiData) => {
    const {
      compProps: { errorMessageType },
      translate,
      experiments,
      ...restCompInfo
    } = compInfo;

    const { 'specs.thunderbolt.minMaxInCheckboxGroup': minMaxExperiment } =
      experiments;

    const isExperimentOpen = (
      experiment: boolean | string | undefined,
    ): boolean => Boolean(experiment && String(experiment) === 'true');

    const fallbackValidationMessage = 'Select at least one option.';

    return {
      ...carmiData,
      ...restCompInfo,
      errorMessageType,
      isMinMaxEnabled: isExperimentOpen(minMaxExperiment),
      translations: {
        errorMessage: translate(
          translationKeys.NAMESPACE,
          translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE,
        ),
        validationMessage:
          translate(
            translationKeys.NAMESPACE,
            translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_VALIDATION_MESSAGE,
          ) || fallbackValidationMessage,
      },
    };
  },
);

export const css = withCompInfo<
  CheckboxGroupCSSVars,
  CheckboxGroupDefinition,
  never,
  {
    styleProperties: {
      align: LogicalAlignment;
      optionsAlign: WithInherit<LogicalAlignment>;
      txtlblrq?: string;
    };
  }
>()(
  [
    'compId',
    'compLayout',
    'compProps',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'structure',
    'siteFontsSpxResolved',
  ],
  compInfo => {
    const {
      compProps,
      styleProperties: { align, optionsAlign, txtlblrq },
      compData,
    } = compInfo;

    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      optionsDirection = 'inherit',
      label,
    } = compData;

    const {
      labelMargin,
      layout,
      buttonSize,
      buttonsMargin,
      labelMobileFontSize,
      spacing,
    } = compProps;

    const calculatedLabelMargin =
      label?.length > 0 && labelMargin ? labelMargin : 0;

    const notTransparentText = txtlblrq && txtlblrq !== 'transparent';

    const fnt2 = getScaledFont(compInfo, 'fnt2', labelMobileFontSize);
    const groupLabelMargin = `0 0 ${calculatedLabelMargin}px 0`;

    const checkboxCssVars = generateCheckboxCSSVars(compInfo, {
      size: buttonSize,
      direction: optionsDirection,
      labelMarginStart: `${spacing + 1}px`,
    });

    const calculatedOptionsAlignment = castFromInheritIfNeeded(
      optionsAlign,
      align,
    );

    const varsByLayout = {
      horizontal: {
        '--itemsFlexDirection': 'row',
        '--itemsJustifyContent': calculatedOptionsAlignment,
        '--itemsAlignItems': 'unset',
      },
      vertical: {
        '--itemsFlexDirection': 'column',
        '--itemsJustifyContent': 'unset',
        '--itemsAlignItems': calculatedOptionsAlignment,
      },
    } as const;

    return {
      '--fnt2': fnt2,
      '--rootDirection': direction,
      '--labelDirection': labelDirection,
      '--optionsDirection': optionsDirection,
      '--groupLabelMargin': groupLabelMargin,
      '--align': align,
      '--requiredIndicationDisplay': notTransparentText ? 'inline' : 'none',
      '--itemsMargin': `${buttonsMargin}px`,
      ...checkboxCssVars,
      ...varsByLayout[layout],
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'alignment', target: 'align' }],
        enhancer: convertPhysicalInputAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'alignment', target: 'direction' }],
        enhancer: convertPhysicalInputAlignmentToDirection,
      },
    ]),
  ],
);

const stateRefs = withStateRefsValues<keyof ICheckboxGroupStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
