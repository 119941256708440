import type { CompInfo } from '@wix/thunderbolt-components-native';
import { translations as translationKeys } from './constants';

export type GalleryCommonTranslations =
  | 'prevButtonAriaLabel'
  | 'nextButtonAriaLabel'
  | 'navigationButtonAriaLabel'
  | 'imageZoomDescribedByLabel';

export const getCommonTranslations = (
  translate: CompInfo['translate'],
): Record<GalleryCommonTranslations, string> => {
  const prevButtonAriaLabel =
    translate(
      translationKeys.ARIA_LABEL_NAMESPACE,
      translationKeys.PREV_BUTTON_ARIA_LABEL_KEY,
    ) || translationKeys.PREV_BUTTON_ARIA_LABEL_DEFAULT;
  const nextButtonAriaLabel =
    translate(
      translationKeys.ARIA_LABEL_NAMESPACE,
      translationKeys.NEXT_BUTTON_ARIA_LABEL_KEY,
    ) || translationKeys.NEXT_BUTTON_ARIA_LABEL_DEFAULT;
  const navigationButtonAriaLabel =
    translate(
      translationKeys.ARIA_LABEL_NAMESPACE,
      translationKeys.NAVIGATION_BUTTONS_ARIA_LABEL_KEY,
    ) || translationKeys.NAVIGATION_BUTTONS_ARIA_LABEL_DEFAULT;
  const imageZoomDescribedByLabel =
    translate(
      translationKeys.ARIA_LABEL_NAMESPACE,
      translationKeys.IMAGE_ZOOM_ARIA_LABEL_KEY,
    ) || translationKeys.IMAGE_ZOOM_ARIA_LABEL_DEFAULT;

  return {
    prevButtonAriaLabel,
    nextButtonAriaLabel,
    navigationButtonAriaLabel,
    imageZoomDescribedByLabel,
  };
};
