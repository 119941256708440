import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { DocumentMediaProps } from '@wix/thunderbolt-components';
import { DocumentMediaDefinition } from '@wix/thunderbolt-components/dist/components/DocumentMedia/DocumentMedia';
import { IDocumentMediaStateValues } from '../DocumentMedia.types';

export const props = withCompInfo<
  DocumentMediaProps,
  DocumentMediaDefinition,
  DocumentMediaProps
>()(
  ['compId', 'fullNameCompType', 'language', 'trackClicksAnalytics'],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

const stateRefs = withStateRefsValues<keyof IDocumentMediaStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ props, stateRefs });
