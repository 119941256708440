import * as React from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { ItunesButtonProps } from '../ItunesButton.types';
import Link from '../../Link/viewer/Link';
import style from './style/ItunesButton.scss';
import { translations as constTranslations, image } from './constants';

const ItunesButton: React.FC<ItunesButtonProps> = props => {
  const {
    id,
    className,
    language = 'EN',
    link = {},
    reportBiOnClick,
    translations,
  } = props;
  const imageUrl = image.URL.replace(
    image.languagePlaceholder,
    language.toUpperCase(),
  );

  const onClick = () => {
    reportBiOnClick(imageUrl, translations.ariaLabel!);
  };

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(style.root, className)}
      title={constTranslations.ARIA_LABEL_DEFAULT}
      onClick={onClick}
    >
      <Link
        className={style.link}
        {...link}
        aria-label={translations.ariaLabel}
      >
        <img className={style.img} src={imageUrl} alt="" />
      </Link>
    </div>
  );
};

export default ItunesButton;
