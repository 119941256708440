import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  IShareButtonsCustomControls,
  IShareButtonsMapperProps,
  IShareButtonsStateRefs,
} from '../types';
import { shouldDisplayIcon, shouldDisplayLabel } from '../utils/display';
import {
  getSpacing,
  getFlexDirection,
  getAlignmentBasedFlexDirection,
} from '../../../common/viewer/mapper/cssMapperUtils';
import { toastTextKey, TranslationKeys, componentName } from '../constants';

const props = withCompInfo<any, any>()(
  [
    'compProps',
    'compData',
    'compStylableClass',
    'externalBaseUrl',
    'translate',
  ],
  ({ compProps, compData, compStylableClass, externalBaseUrl, translate }) => {
    const translationKeys = [
      toastTextKey,
      TranslationKeys.component.label,
      TranslationKeys.manage.copy_link_default_text,
    ];
    const translations = translationKeys.reduce(
      (acc: { [key: string]: string | undefined }, key) => {
        acc[key] = translate(componentName, key);
        return acc;
      },
      {},
    );

    return {
      ...compProps,
      ...compData,
      stylableClassName: compStylableClass,
      externalBaseUrl,
      translations,
    };
  },
);

const iconPositionToFlexDirectionMap: Record<
  IShareButtonsCustomControls['IconPosition'],
  string
> = {
  left: 'row',
  right: 'row-reverse',
  top: 'column',
  bottom: 'column-reverse',
};

const getIconTextSpacing = (
  buttonContent: any,
  iconPosition: string,
  iconTextSpacing: number,
) => {
  if (shouldDisplayLabel(buttonContent)) {
    return getSpacing(iconPosition, iconTextSpacing);
  }
  return 0;
};

export const css = withCompInfo<any, any>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const {
      buttonsAlignment = 'horizontal',
      rowsSpacing = 10,
      buttonsSpacing = 10,
      iconPosition = 'left',
      iconTextSpacing = 10,
      buttonContent = 'Text and Icon',
      horizontalDirection = 'ltr',
      alignmentDirection = 'left',
    } = styleProperties as Partial<IShareButtonsMapperProps>;

    const btnsFlexDirection = getFlexDirection(
      buttonsAlignment,
      horizontalDirection,
    );
    const btnsAlignmentDirection = getAlignmentBasedFlexDirection(
      btnsFlexDirection,
      alignmentDirection,
    );

    const buttonsListCSSVariables = {
      '--btns-list-column-gap':
        buttonsAlignment === 'horizontal' ? buttonsSpacing : 0,
      '--btns-list-row-gap': rowsSpacing,
      '--btns-list-flex-direction': btnsFlexDirection,
      '--btns-list-justify-content': btnsAlignmentDirection['justify-content'],
      '--btns-list-align-items': btnsAlignmentDirection['align-items'],
    };

    const buttonCSSVariables = {
      '--btn-icon-position': iconPositionToFlexDirectionMap[iconPosition],
      '--btn-icon-text-spacing': getIconTextSpacing(
        buttonContent,
        iconPosition,
        iconTextSpacing,
      ),
      ...{
        '--btn-label-display': shouldDisplayLabel(buttonContent)
          ? 'inline-block'
          : 'none',
        '--btn-icon-display': shouldDisplayIcon(buttonContent)
          ? 'inline-block'
          : 'none',
        '--btn-tooltip-display': !shouldDisplayLabel(buttonContent)
          ? 'flex'
          : 'none',
        '--btn-copy-to-clipboard-display': !shouldDisplayLabel(buttonContent)
          ? 'none'
          : 'inline-block',
      },
    };
    return {
      height: 'auto',
      ...(buttonsAlignment === 'vertical' && { width: 'auto' }),
      'min-width': 'min-content',
      ...buttonCSSVariables,
      ...buttonsListCSSVariables,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IShareButtonsStateRefs>([
  'currentUrlWithQueryParams',
  'experiments',
]);

export default createComponentMapperModel({
  props,
  css,
  stateRefs,
});
