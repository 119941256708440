import {
  createComponentPreviewMapperModel,
  withStateRefs,
} from '@wix/editor-elements-integrations';
import { IPopupContainerStateRefs } from '../PopupContainer.types';

export const stateRefs = withStateRefs<IPopupContainerStateRefs>()(
  ['refApi'],
  ({ refApi }) => {
    return {
      pageId: refApi.router.pageId(),
    };
  },
);

export default createComponentPreviewMapperModel({ stateRefs });
