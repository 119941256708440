
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "CollapsibleTextcomponent2568482278",
      {"classes":{"root":"CollapsibleTextcomponent2568482278__root","text-wrapper":"CollapsibleTextcomponent2568482278__text-wrapper","text":"CollapsibleTextcomponent2568482278__text","expand-control":"CollapsibleTextcomponent2568482278__expand-control"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  