
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "Pagination670306077",
      {"classes":{"root":"Pagination670306077__root","navButtonsContainer":"Pagination670306077__navButtonsContainer","navButton":"Pagination670306077__navButton"},"vars":{"force-state-metadata":"--force-state-metadata","display":"--display"},"stVars":{"font":"var(--fnt)","arrowsColor":"rgba(var(--arrowsColor, var(--color_32)), var(--alpha-arrowsColor, 1))","arrowsDisabledColor":"rgba(var(--arrowsDisabledColor, var(--color_37)), var(--alpha-arrowsDisabledColor, 1))","navButtonTextSize":"var(--navButtonTextSize, 15px)","navButtonTextColor":"rgba(var(--navButtonTextColor, var(--color_15)), var(--alpha-navButtonTextColor, 1))","navButtonDisabledTextColor":"rgba(var(--navButtonDisabledTextColor, var(--color_11)), var(--alpha-navButtonDisabledTextColor, 1))","paginationAlignment":"var(--pagination-alignment, center)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  