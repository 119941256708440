import {
  createComponentPreviewMapperModel,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';
import type {
  FreemiumBannerDesktopDefinition,
  FreemiumBannerDesktopPlatformProps,
} from '../FreemiumBannerDesktop.types';
import previewTranslationKeys from './constants.preview';

const getPreviewUrl = (metaSiteId?: string) =>
  `//premium.wix.com/wix/api/premiumStart?siteGuid=${metaSiteId}&referralAdditionalInfo=edhtml_PREVIEW_FREE_SITE_BANNER_CLICK`;

export const props = withCompPreviewProps<
  FreemiumBannerDesktopPlatformProps,
  FreemiumBannerDesktopDefinition
>()(
  ['direction', 'metaSiteId', 'translate'],
  ({ translate, direction, metaSiteId }) => {
    return {
      useOverlay: false,
      direction,
      href: getPreviewUrl(metaSiteId),
      classNames: [direction],
      translations: {
        bannerText:
          translate(
            previewTranslationKeys.NAMESPACE,
            previewTranslationKeys.MAIN_TEXT_KEY,
          ) ||
          previewTranslationKeys.MAIN_TEXT_DEFAULT ||
          '',
        buttonText:
          translate(
            previewTranslationKeys.NAMESPACE,
            previewTranslationKeys.BUTTON_TEXT_KEY,
          ) || previewTranslationKeys.BUTTON_TEXT_DEFAULT,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
