export const TestIds = {
  AddPanel: 'test-component-add-panel',
  InputLabel: 'test-comp-label',
  Input: 'test-comp-input',
  Root: 'test-comp-root',
} as const;

export const ViewerType = 'wixui.TestComp' as const;

export const labelText = {
  default: 'label default value',
  mapper: 'label from mapper',
  blurredBySdk: 'label blurred by Sdk',
  focusedBySdk: 'label focused by Sdk',
};
