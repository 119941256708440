export const TestIds = {
  link: 'link',
  prev: 'prev',
  next: 'next',
  close: 'close',
  container: 'container',
  closeButton: 'closeButton',
  closeIcon: 'closeIcon',
  nextButton: 'nextButton',
  nextIcon: 'nextIcon',
  prevButton: 'prevButton',
  prevIcon: 'prevIcon',
  root: 'root',
};

export const TranslationKeys = {
  ARIA_LABEL_NAMESPACE: 'ariaLabels',
  CLOSE_LABEL_KEY: 'ImageZoom_AriaLabel_Close',
  CLOSE_FALLBACK_LABEL: 'Close',
  NEXT_LABEL_KEY: 'ImageZoom_AriaLabel_Next',
  NEXT_FALLBACK_LABEL: 'Next',
  PREVIOUS_LABEL_KEY: 'ImageZoom_AriaLabel_Previous',
  PREVIOUS_FALLBACK_LABEL: 'Previous',
};
