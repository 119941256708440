import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  generatePreviewStateDataMap,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type { IFastGalleryDefinition } from '../documentManagment/FastGallery.definition';
import type { FastGalleryPreviewMapperProps } from '../FastGallery.types';
import { props as viewerPropsMapper } from './FastGallery.mapper';

const props = composeMapper<
  PreviewWrapperProps<FastGalleryPreviewMapperProps>,
  IFastGalleryDefinition
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;
    const previewStateMap = generatePreviewStateDataMap(compPreviewState);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: { compPreviewState },
      previewStateMap,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
