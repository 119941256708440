import * as React from 'react';
import type { MenuButtonProps } from '../../../MenuButton.types';
import SeparateShinyIIMenuButton from './SeparateShinyIIMenuButton';
import skinsStyle from './styles/SeparateShinyIIMenuButtonNSkin.scss';

const SeparateShinyIIMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  return (
    <SeparateShinyIIMenuButton
      {...props}
      skin="SeparateShinyIIMenuButtonNSkin"
      skinsStyle={skinsStyle}
    />
  );
};

export default SeparateShinyIIMenuButtonNSkin;
