import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  ISoundCloudWidgetControllerProps,
  ISoundCloudWidgetMapperProps,
  ISoundCloudWidgetStateRefValues,
  ISoundCloudWidgetProps,
} from '../SoundCloudWidget.types';

export default withCompController<
  ISoundCloudWidgetMapperProps,
  ISoundCloudWidgetControllerProps,
  ISoundCloudWidgetProps,
  ISoundCloudWidgetStateRefValues
>(({ stateValues, mapperProps }) => {
  const { reducedMotion } = stateValues;
  return { ...mapperProps, reducedMotion };
});
