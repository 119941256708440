import { formatClassNames } from '@wix/editor-elements-common-utils';
import classNames from 'clsx';
import * as React from 'react';
import semanticClassNames from '../../../SiteButton.semanticClassNames';
import type { ISiteButtonImperativeActions } from '../../../SiteButton.types';
import SiteButtonContent from '../../SiteButtonContent';
import type { SkinButtonProps } from '../SkinButton.types';

type ButtonForkedSkinProps = SkinButtonProps & {
  skinsStyle: Record<string, string>;
};

const ButtonForkedSkin: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  ButtonForkedSkinProps
> = (
  {
    wrapperProps,
    linkProps,
    a11yProps,
    elementType,
    skinsStyle,
    label,
    onFocus,
    onBlur,
    customClassNames = [],
  },
  ref,
) => {
  return (
    <div
      {...wrapperProps}
      className={classNames(skinsStyle.root, wrapperProps.className)}
    >
      <div className={skinsStyle.wrapper}>
        <div className={`${skinsStyle.container} ${skinsStyle.top}`}>
          <div className={skinsStyle.ribbon} />
        </div>
        <div className={`${skinsStyle.container} ${skinsStyle.bottom}`}>
          <div className={skinsStyle.ribbon} />
        </div>
      </div>
      <SiteButtonContent
        linkProps={linkProps}
        a11yProps={a11yProps}
        elementType={elementType}
        className={classNames(
          skinsStyle.link,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      >
        <span
          className={classNames(
            skinsStyle.label,
            formatClassNames(semanticClassNames.buttonLabel),
          )}
        >
          {label}
        </span>
      </SiteButtonContent>
    </div>
  );
};

export default React.forwardRef(ButtonForkedSkin);
