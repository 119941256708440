import React from 'react';
import { DownloadLogo } from '../../common/assets/logos';
import { TestIds } from '../../common/constants';
import { FreemiumBannerMobileProps } from '../FreemiumBannerMobile.types';
import { AppsNames } from './constants';
import style from './style/FreemiumBannerMobile.scss';

type PreferredAppContentProps = {
  preferredApp: string | undefined;
  translations: FreemiumBannerMobileProps['translations'];
};

const PreferredAppContent: React.FC<PreferredAppContentProps> = ({
  preferredApp,
  translations,
}) => {
  let bannerText = translations.oneAppBannerText;

  if (preferredApp === AppsNames.WixFitnessApp) {
    bannerText = translations.fitnessAppBannerText;
  } else if (preferredApp === AppsNames.WixRestaurantsApp) {
    bannerText = translations.restaurantsAppBannerText;
  }

  return (
    <>
      <span data-hook={TestIds.content}>{bannerText}</span>
      <DownloadLogo className={style.downloadLogo} />
    </>
  );
};
export default PreferredAppContent;
