import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getMigratedStyleProps,
  isSupportSpExperimentOn,
} from '@wix/editor-elements-common-utils';
import type { CompInfo, Spx } from '@wix/editor-elements-types/thunderbolt';
import {
  LinkBarDefinition,
  LinkBarCarmiProps,
  LinkBarCSSVars,
  LinkBarStateValues,
  LinkBarMapperPropsNoStyle,
} from '../LinkBar.types';
import * as translations from './constants';

export const props = withCompInfo<
  LinkBarMapperPropsNoStyle,
  LinkBarDefinition,
  LinkBarCarmiProps
>()(
  [
    'compProps',
    'experiments',
    'compId',
    'fullNameCompType',
    'language',
    'translate',
    'trackClicksAnalytics',
  ],
  ({ compProps, experiments, translate, ...compPropsRest }, carmiData) => {
    const { iconSize } = compProps;
    return {
      ...carmiData,
      ...compPropsRest,
      iconSize,
      shouldRenderPlaceholders:
        experiments['specs.thunderbolt.LinkBarPlaceholderImages'] === true,
      translations: {
        ariaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.ARIA_LABEL_KEY,
          ) || translations.ARIA_LABEL_DEFAULT,
      },
    };
  },
);

const stateRefs = withStateRefsValues<keyof LinkBarStateValues>(['reportBi']);

const calculateMobileSize = (
  numOfItems: number,
  iconSize: number,
  spacing: number,
  isHorizontal: boolean,
  totalItemSize: number,
) => {
  if (numOfItems === 0) {
    return { height: `${iconSize}px`, width: '5px' };
  }

  const itemSize = iconSize + spacing;
  const actualTotalSize = totalItemSize > 300 ? 300 : totalItemSize;
  const width = isHorizontal ? actualTotalSize : iconSize;
  const height = isHorizontal
    ? Math.ceil(numOfItems / Math.floor((width + spacing) / itemSize)) *
      iconSize
    : totalItemSize;
  return { width: `${width}px`, height: `${height}px` };
};

const calculateDesktopSize = (
  numOfItems: number,
  iconSize: number,
  isHorizontal: boolean,
  totalItemSize: number,
) => {
  if (numOfItems === 0) {
    return { height: `${iconSize}px`, width: `${iconSize}px` };
  }

  return {
    width: `${isHorizontal ? totalItemSize : iconSize}px`,
    height: `${isHorizontal ? iconSize : totalItemSize}px`,
  };
};

const calculateResponsiveSize = (
  numOfItems: number,
  isHorizontal: boolean,
  totalItemSize: string,
  singleItemSize: string,
) => {
  return numOfItems > 0 && !isHorizontal
    ? { height: totalItemSize, width: singleItemSize }
    : { height: singleItemSize, width: totalItemSize };
};

const calculateSize = ({
  hasResponsiveLayout,
  isMobileView,
  sizeProperties,
  compData,
  formatCssValue,
  spx,
}: {
  hasResponsiveLayout: boolean;
  isMobileView: boolean;
  sizeProperties: {
    iconSize: string;
    spacing: string;
    orientation: LinkBarDefinition['property']['orientation'];
  };
  compData: LinkBarDefinition['data'];
  formatCssValue?: CompInfo['formatCssValue'];
  spx?: Spx;
}): { width?: string; height?: string } => {
  const { iconSize, spacing, orientation } = sizeProperties;
  const numericIconSize = parseFloat(iconSize as string);
  const numericSpacing = parseFloat(spacing as string);
  const numOfItems = compData.items?.length ?? 0;
  const totalItemSize =
    numOfItems * (numericIconSize + numericSpacing) - numericSpacing;
  const singleItemSizeCssVal = formatCssValue
    ? `${formatCssValue(iconSize, spx)}`
    : iconSize;
  const totalItemSizeCssVal = formatCssValue
    ? `calc(${numOfItems} * (${formatCssValue(
        iconSize,
        spx,
      )} + ${formatCssValue(spacing, spx)}) - ${formatCssValue(spacing, spx)})`
    : totalItemSize;
  const isHorizontal = orientation === 'HORIZ';
  if (hasResponsiveLayout) {
    return calculateResponsiveSize(
      numOfItems,
      isHorizontal,
      String(totalItemSizeCssVal),
      String(singleItemSizeCssVal),
    );
  }
  if (isMobileView) {
    return calculateMobileSize(
      numOfItems,
      numericIconSize,
      numericSpacing,
      isHorizontal,
      totalItemSize,
    );
  }
  return calculateDesktopSize(
    numOfItems,
    numericIconSize,
    isHorizontal,
    totalItemSize,
  );
};

export const css = withCompInfo<LinkBarCSSVars, LinkBarDefinition>()(
  [
    'hasResponsiveLayout',
    'compProps',
    'compData',
    'isMobileView',
    'styleProperties',
    'formatCssValue',
    'experiments',
  ],
  ({
    hasResponsiveLayout,
    compProps,
    compData,
    isMobileView,
    styleProperties,
    formatCssValue,
    experiments,
  }) => {
    const { spx } = styleProperties;
    const isSupportSpOn = isSupportSpExperimentOn(experiments);

    const { orientation, iconSize, spacing } = isSupportSpOn
      ? getMigratedStyleProps({
          compProps,
          styleProperties,
          migratedFields: ['orientation', 'iconSize', 'spacing'],
        })
      : compProps;
    const isHorizontal = orientation === 'HORIZ';
    const sizeProperties = {
      orientation,
      iconSize,
      spacing,
    };
    const getItemMargin = () => {
      if (isSupportSpOn) {
        return isHorizontal
          ? formatCssValue(`0 ${spacing} 0 0`, spx)
          : formatCssValue(`0 0 ${spacing} 0`, spx);
      } else {
        return isHorizontal
          ? `0 ${compProps.spacing}px 0 0`
          : `0 0 ${compProps.spacing}px 0`;
      }
    };

    return {
      '--item-size': isSupportSpOn
        ? formatCssValue(iconSize, spx)
        : `${iconSize}px`,
      '--item-margin': getItemMargin(),
      '--item-display': isHorizontal ? 'inline-block' : 'block',
      ...(hasResponsiveLayout && {
        '--flex-direction': isHorizontal ? 'row' : 'column',
      }),
      ...calculateSize({
        hasResponsiveLayout,
        isMobileView,
        sizeProperties,
        compData,
        formatCssValue: isSupportSpOn ? formatCssValue : undefined,
        spx,
      }),
    };
  },
);

export default createComponentMapperModel({ props, stateRefs, css });
