import * as React from 'react';
import {
  IDatePickerProps,
  IDatePickerImperativeActions,
} from '../../../DatePicker.types';
import DatePickerBase from '../../DatePickerBase';
import NavbarDefaultSkin from './NavbarDefaultSkin';

const DatePickerDefaultSkin: React.ForwardRefRenderFunction<
  IDatePickerImperativeActions,
  IDatePickerProps
> = (props, ref) => (
  <DatePickerBase
    ref={ref}
    {...props}
    NavbarComponent={NavbarDefaultSkin}
  ></DatePickerBase>
);

export default React.forwardRef(DatePickerDefaultSkin);
