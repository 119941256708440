import * as React from 'react';
import type {
  IContainerProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import { BubbleSkinContainer } from '../../shared/BubbleSkinContainer';
import styles from './BubbleAreaLeft.scss';

const BubbleAreaLeft: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IContainerProps
> = (props, ref) => {
  return <BubbleSkinContainer ref={ref} {...props} classes={styles} />;
};

export default React.forwardRef(BubbleAreaLeft);
