export const TestIds = {
  noReviewsPlaceholder: 'no-reviews-placeholder-test-id',
  ratingValue: 'rating-value-test-id',
  ratingIcons: 'rating-icons-test-id',
  splitIcon: 'split-icon-test-id',
  reviewsCounter: 'reviews-counter-test-id',
  srOnly: 'sr-only-test-id',
} as const;

export const NoReviewsMode = {
  NOTHING: 'nothing',
  EMPTY_ICONS: 'emptyIcons',
  PLACEHOLDER_TEXT: 'placeholderText',
};

export const DEFAULT_SHAPE_SIZE = 25;
export const DEFAULT_SHAPE_SPACING = 8;

const TranslationNamespace = 'Accessibility';

export const Translation = {
  a11yAverage: {
    namespace: TranslationNamespace,
    key: 'ratings_read_a11y_avarage_rating',
    default: 'average rating is',
  },
  a11yOutOf: {
    namespace: TranslationNamespace,
    key: 'ratings_read_a11y_out_of',
    default: 'out of 5',
  },
  a11yBasedOn: {
    namespace: TranslationNamespace,
    key: 'ratings_read_a11y_based_on',
    default: 'based on',
  },
  a11yVotes: {
    namespace: TranslationNamespace,
    key: 'ratings_read_a11y_votes',
    default: 'votes',
  },
};
