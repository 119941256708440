import * as React from 'react';
import { SUGGESTIONS_SHOW_ALL_BUTTON_TEST_ID } from '../../constants';
import style from './ShowAllButton.scss';

interface ShowAllButtonProps {
  onClick: React.MouseEventHandler;
  children?: React.ReactNode;
}

export const ShowAllButton: React.FC<ShowAllButtonProps> = ({
  children,
  onClick,
}) => {
  return (
    <div className={style.root}>
      <button
        className={style.button}
        role="link"
        data-testid={SUGGESTIONS_SHOW_ALL_BUTTON_TEST_ID}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};
