import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import WoodScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/WoodScreen';
import HeaderContainer from '../../HeaderContainer';

const WoodScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => <HeaderContainer {...props} skin={WoodScreen}></HeaderContainer>;

export default WoodScreenHeader;
