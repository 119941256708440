export const TestIds = {
  submitImg: 'submit-image',
  trackingPixel: 'tracking-pixel-image',
  payModal: 'pay-modal',
  errorModal: 'error-modal',
  itemName: 'item-name',
  itemNameInput: 'item-name-input',
  amount: 'amount',
  amountInput: 'amount-input',
  business: 'business',
  currencyCode: 'currency-code',
  payModalTitle: 'pay-modal-title',
} as const;

export const BUY_BUTTON_LABEL = 'Buy now via PayPal';
export const DONATE_BUTTON_LABEL = 'Donate via PayPal';

export const LOCALES: Record<
  string,
  { image: string; cc: string; pixel: string }
> = {
  da: { image: 'da_DK', cc: '/DK', pixel: 'da_DK' },
  de: { image: 'de_DE/DE', cc: '', pixel: 'de_DE' },
  en: { image: 'en_US', cc: '', pixel: 'en_US' },
  es: { image: 'es_ES/ES', cc: '', pixel: 'es_ES' },
  fr: { image: 'fr_FR/FR', cc: '', pixel: 'fr_FR' },
  it: { image: 'it_IT/IT', cc: '', pixel: 'it_IT' },
  ja: { image: 'ja_JP/JP', cc: '', pixel: 'ja_JP' },
  jp: { image: 'ja_JP/JP', cc: '', pixel: 'ja_JP' },
  nl: { image: 'nl_NL/NL', cc: '', pixel: 'nl_NL' },
  no: { image: 'no_NO', cc: '/NO', pixel: 'en_US' },
  pl: { image: 'pl_PL/PL', cc: '', pixel: 'pl_PL' },
  pt: { image: 'pt_BR', cc: '/BR', pixel: 'pt_BR' },
  ru: { image: 'ru_RU', cc: '/RU', pixel: 'en_US' },
  sv: { image: 'sv_SE', cc: '/SE', pixel: 'sv_SE' },
  tr: { image: 'tr_TR', cc: '', pixel: 'tr_TR' },
};

export const CURRENCY_DATA = [
  {
    value: 'EUR',
    symbol: '€',
    label: 'PayPal_Buy_Settings_Currency_EUR',
    supportsDecimal: true,
  },
  {
    value: 'USD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_USD',
    supportsDecimal: true,
  },
  {
    value: 'AUD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_AUD',
    supportsDecimal: true,
  },
  {
    value: 'BRL',
    symbol: 'R$',
    label: 'PayPal_Buy_Settings_Currency_BRL',
    supportsDecimal: true,
  },
  {
    value: 'CAD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_CAD',
    supportsDecimal: true,
  },
  {
    value: 'CZK',
    symbol: 'Kc',
    label: 'PayPal_Buy_Settings_Currency_CZK',
    supportsDecimal: true,
  },
  {
    value: 'DKK',
    symbol: 'kr',
    label: 'PayPal_Buy_Settings_Currency_DKK',
    supportsDecimal: true,
  },
  {
    value: 'HKD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_HKD',
    supportsDecimal: true,
  },
  {
    value: 'HUF',
    symbol: 'Ft',
    label: 'PayPal_Buy_Settings_Currency_HUF',
    supportsDecimal: false,
  },
  {
    value: 'ILS',
    symbol: '₪',
    label: 'PayPal_Buy_Settings_Currency_ILS',
    supportsDecimal: true,
  },
  {
    value: 'JPY',
    symbol: '¥',
    label: 'PayPal_Buy_Settings_Currency_JPY',
    supportsDecimal: false,
  },
  {
    value: 'MXN',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_MXN',
    supportsDecimal: true,
  },
  {
    value: 'NOK',
    symbol: 'kr',
    label: 'PayPal_Buy_Settings_Currency_NOK',
    supportsDecimal: true,
  },
  {
    value: 'MYR',
    symbol: 'RM',
    label: 'PayPal_Buy_Settings_Currency_MYR',
    supportsDecimal: true,
  },
  {
    value: 'NZD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_NZD',
    supportsDecimal: true,
  },
  {
    value: 'PHP',
    symbol: '₱',
    label: 'PayPal_Buy_Settings_Currency_PHP',
    supportsDecimal: true,
  },
  {
    value: 'PLN',
    symbol: 'zł',
    label: 'PayPal_Buy_Settings_Currency_PLN',
    supportsDecimal: true,
  },
  {
    value: 'GBP',
    symbol: '£',
    label: 'PayPal_Buy_Settings_Currency_GBP',
    supportsDecimal: true,
  },
  {
    value: 'SGD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_SGD',
    supportsDecimal: true,
  },
  {
    value: 'SEK',
    symbol: 'kr',
    label: 'PayPal_Buy_Settings_Currency_SEK',
    supportsDecimal: true,
  },
  {
    value: 'CHF',
    symbol: 'CHF',
    label: 'PayPal_Buy_Settings_Currency_CHF',
    supportsDecimal: true,
  },
  {
    value: 'TWD',
    symbol: '$',
    label: 'PayPal_Buy_Settings_Currency_TWD',
    supportsDecimal: false,
  },
  {
    value: 'THB',
    symbol: 'THB',
    label: 'PayPal_Buy_Settings_Currency_THB',
    supportsDecimal: true,
  },
  {
    value: 'TRY',
    symbol: 'TL',
    label: 'PayPal_Buy_Settings_Currency_TRY',
    supportsDecimal: true,
  },
  {
    value: 'RUB',
    symbol: 'руб',
    label: 'PayPal_Buy_Settings_Currency_RUB',
    supportsDecimal: true,
  },
  //      Currently Not supported By PayPal        {value: 'CLP', symbol: '$', label:  'Chilean peso', supportsDecimal: true},
  //      Currently Not supported By PayPal  {value: 'ARS', symbol: '$', label:  'Argentine peso', supportsDecimal: true},
];

export const TranslationKeys = {
  errorDialog: {
    title: 'PayPalButton_UoU_Error_Modal_Header',
    subTitle: 'PayPalButton_UoU_Error_Modal_Body',
  },
  DonateModal: {
    title: 'PayPalButton_UoU_DonationModal_Header',
    subTitle: 'PayPalButton_UoU_DonationModal_Body',
    amountInputLabel: 'PayPalButton_UoU_DonationModal_InputLabel',
    donateButton: 'PayPalButton_UoU_DonationModal_Donate_Button',
    cancelButton: 'PayPalButton_UoU_DonationModal_Cancel_Button',
    validationError: 'PayPalButton_UoU_DonationModal_ValidationError',
    organizationLabel: 'PayPalButton_UoU_PaymentModal_Organization_Label',
  },
  BuyModal: {
    title: 'PayPalButton_UoU_PaymentModal_Header',
    subTitle: 'PayPalButton_UoU_PaymentModal_Body',
    descriptionInputLabel:
      'PayPalButton_UoU_PaymentModal_Description_InputLabel',
    descriptionInputPlaceholder:
      'PayPalButton_UoU_PaymentModal_Description_Placeholder',
    amountInputLabel: 'PayPalButton_UoU_PaymentModal_Amount_InputLabel',
    fixedAmountLabel: 'PayPalButton_UoU_PaymentModal_Fixed_Amount_InputLabel',
    buyButton: 'PayPalButton_UoU_PaymentModal_Buy_Button',
    cancelButton: 'PayPalButton_UoU_PaymentModal_Cancel_Button',
    descriptionValidationError:
      'PayPalButton_UoU_PaymentModal_Description_ValidationError',
    amountValidationError: 'PayPalButton_UoU_PaymentModal_ValidationError',
    amountValidationErrorWholeNumbers:
      'PayPalButton_UoU_Modal_Validation_Error_Whole_Numbers',
    amountValidationErrorMinAmount:
      'PayPalButton_UoU_Modal_Validation_Error_Minimum',
  },
};

export const TranslationsFallback: typeof TranslationKeys = {
  errorDialog: {
    title: 'There’s an issue on our end',
    subTitle:
      'We’re not able to accept this PayPal payment right now. Please get in touch with us to resolve the issue.',
  },
  DonateModal: {
    title: 'Make a donation',
    subTitle: 'to <%=paypalemail%> for <%=organizationname%>',
    amountInputLabel: 'Enter an amount',
    donateButton: 'Donate with PayPal',
    cancelButton: 'Cancel',
    validationError: 'Enter at least <%=currency%><%=minamount%>.',
    organizationLabel: 'Organization',
  },
  BuyModal: {
    title: 'Make a payment',
    subTitle: 'to <%=paypalemail%> ',
    descriptionInputLabel: 'Description',
    descriptionInputPlaceholder: 'e.g., Product name',
    amountInputLabel: 'Enter an amount',
    fixedAmountLabel: 'Amount',
    buyButton: 'Buy with PayPal',
    cancelButton: 'Cancel',
    descriptionValidationError: 'Enter a description.',
    amountValidationError: 'Enter at least <%=currency%><%=minamount%>.',
    amountValidationErrorMinAmount: 'Enter at least 0.01.',
    amountValidationErrorWholeNumbers: 'Enter whole numbers only.',
  },
};

export const translationViewerFeatureName = 'PayPalButton';

export const CREATE_ORDER_URL = '/_serverless/paypal-api-v2/create-order';
export const CREATE_ORDER_REDIRECT_URL =
  '/_serverless/paypal-api-v2/create-order-redirect';
