import type { Component } from '@wix/editor-elements-types/thunderbolt';
import type { DeviceType } from '@wix/thunderbolt-ssr-api';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { DatePickerCarmiProps } from '@wix/thunderbolt-components-native';
import {
  addUnitToEveryField,
  castFromInheritIfNeeded,
  convertPhysicalAlignmentToLogical,
  convertPhysicalInputAlignmentToDirection,
  convertPhysicalInputAlignmentToLogical,
  getHeightInPixels,
  getInputHeight,
  getLabelPaddingsValues,
  getOppositeAlign,
  getRequiredIndicationDisplay,
  getScaledFont,
  isSupportSpExperimentOn,
  LogicalAlignment,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import { SingleLayoutData } from '@wix/thunderbolt-becky-types';
import type {
  IDatePickerMapperProps,
  DatePickerDefinition,
  DatePickerCSSVars,
  DatePickerCssCarmiData,
  DatePickerCSSInputPaddingVars,
  DatePickerMapperOverrides,
} from '../DatePicker.types';
import { getAllTranslations } from '../utils';
import { DEFAULT_TRANSLATIONS } from '../constants';

export const isMobileViewOrDevice = (
  isMobileView: boolean,
  deviceType: DeviceType,
) => isMobileView || deviceType === 'Smartphone';

export const props = withCompInfo<
  IDatePickerMapperProps,
  DatePickerDefinition,
  DatePickerCarmiProps
>()(
  [
    'compData',
    'compProps',
    'skin',
    'isMobileView',
    'hasResponsiveLayout',
    'deviceType',
    'experiments',
    'compId',
    'translate',
  ],
  (
    {
      compData,
      compProps,
      skin,
      isMobileView,
      hasResponsiveLayout,
      deviceType,
      experiments,
      compId,
      translate,
    },
    carmiProps,
  ) => {
    const { templateId } = carmiProps;
    const {
      placeholder: dataPlaceholder,
      minDate,
      maxDate,
      allowPastDates,
      allowFutureDates,
      disabledDates,
      enabledDateRanges,
      disabledDateRanges,
      disabledDaysOfWeek,
      label,
    } = compData;

    const {
      placeholder: propsPlaceholder,
      defaultTextType,
      isDisabled,
      required,
      readOnly,
      dateFormat,
      dayToStart,
    } = compProps;

    const withCalendarPortal =
      experiments['specs.thunderbolt.DatePickerPortal'] === 'new' ||
      experiments['specs.thunderbolt.DatePickerPortal'] === true;

    const isMobile = isMobileViewOrDevice(isMobileView, deviceType);

    const normalizedSkin = skin || 'DatePickerDefaultSkin';

    const translations = getAllTranslations(translate);

    return {
      skin: normalizedSkin,
      label,
      placeholder:
        defaultTextType === 'today'
          ? ''
          : dataPlaceholder || propsPlaceholder || '',
      minDate,
      maxDate,
      allowPastDates,
      allowFutureDates,
      disabledDates,
      enabledDateRanges:
        !enabledDateRanges || enabledDateRanges.length === 0
          ? null
          : enabledDateRanges,
      disabledDateRanges: disabledDateRanges || [],
      disabledDaysOfWeek,
      dateFormat,
      useTodayAsDefaultValue: defaultTextType === 'today',
      weekStartDay: dayToStart === 'Sunday' ? 0 : 1,
      readOnly,
      required,
      isDisabled,
      isCompactMode: isMobile,
      isResponsive: hasResponsiveLayout,
      timeZone: null,
      withCalendarPortal,
      templateId,
      compId,
      translations: {
        ...translations,
        titleContent:
          translate('ariaLabels', 'DatePicker_AriaLabel_OpenCalender') ||
          DEFAULT_TRANSLATIONS.titleContent,
      },
    };
  },
);

const getInputPadding = (
  alignment: LogicalAlignment,
  padding: number,
  isNewLayout: boolean,
): DatePickerCSSInputPaddingVars => {
  if (alignment === 'center') {
    return {
      '--inputPadding_start': isNewLayout ? '56px' : `${padding}px`,
      '--inputPadding_end': isNewLayout ? '0' : '2px',
    };
  }

  return {
    [`--inputPadding_${alignment}`]: `${padding}px`,
    [`--inputPadding_${getOppositeAlign(alignment)}`]: '2px',
  } as DatePickerCSSInputPaddingVars;
};

const editorSpecificStyling = ({
  compProps,
  compLayout,
  compSingleLayout,
  isMobileView,
  hasResponsiveLayout,
  isOneDocMigrated,
}: {
  compProps: DatePickerDefinition['property'];
  compLayout: Component['layout'];
  compSingleLayout?: SingleLayoutData;
  isMobileView: boolean;
  hasResponsiveLayout: boolean;
  isOneDocMigrated: boolean;
}) => {
  if (!hasResponsiveLayout) {
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;

    return {
      '--inputWrapperHeight': getInputHeight({
        inputHeightProps: compProps,
        height,
        isMobileView,
      }),
      ...(!isOneDocMigrated && {
        height: 'auto' as 'auto',
      }),
    };
  }
  return {};
};

const getIconInset = (
  inputAlignment: LogicalAlignment,
  isNewLayout: boolean,
) => {
  if (isNewLayout || inputAlignment !== 'end') {
    return {
      '--iconInset_start': 'auto',
      '--iconInset_end': '20px',
    };
  }
  return {
    '--iconInset_start': '20px',
    '--iconInset_end': 'auto',
  };
};

const getInputWrapperAlignItems = (
  calculatedInputAlign: LogicalAlignment,
  isNewLayout: boolean,
): { '--inputWrapperAlignItems'?: LogicalAlignment } => {
  if (isNewLayout || calculatedInputAlign !== 'end') {
    return {};
  }
  return { '--inputWrapperAlignItems': 'end' };
};

export const css = withCompInfo<
  DatePickerCSSVars,
  DatePickerDefinition,
  DatePickerCssCarmiData,
  DatePickerMapperOverrides
>()(
  [
    'compProps',
    'compLayout',
    'compSingleLayout',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'compData',
    'siteFonts',
    'formatCssValue',
    'experiments',
    'siteFontsSpxResolved',
    'isOneDocMigrated',
  ],
  (compInfo, cssVars) => {
    const {
      compData,
      compProps,
      compLayout,
      compSingleLayout,
      styleProperties,
      isMobileView,
      hasResponsiveLayout,
      experiments,
      isOneDocMigrated,
    } = compInfo;

    const {
      textPadding,
      labelMargin,
      labelMobileFontSize,
      inputMobileFontSize,
      labelPadding,
    } = compProps as {
      textAlignment: 'left' | 'right';
      textPadding: number;
      labelMargin: number;
      labelMobileFontSize: number;
      inputMobileFontSize: number;
      labelPadding: number | string;
    };
    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
    } = compData;

    const isNewLayout = Boolean(compData.direction || compData.inputDirection);

    const { align, labelAlign, inputAlign } = styleProperties;

    const calculatedLabelAlign = castFromInheritIfNeeded(labelAlign, align);

    const labelPaddingsValues = getLabelPaddingsValues({
      labelPadding,
      align: calculatedLabelAlign,
    });

    const labelPaddingVars = addUnitToEveryField(
      labelPaddingsValues,
      labelPadding,
    );

    const fonts = isSupportSpExperimentOn(experiments)
      ? {
          ...cssVars,
          '--fntlbl': getScaledFont(
            compInfo,
            'fntlbl',
            labelMobileFontSize,
            16,
          ),
          '--fnt': getScaledFont(compInfo, 'fnt', inputMobileFontSize, 13),
        }
      : cssVars;

    const calculatedInputAlign = castFromInheritIfNeeded(inputAlign, align);

    return {
      ...fonts,
      '--direction': direction,
      '--labelDirection': labelDirection,
      '--inputDirection': inputDirection,
      '--align': align,
      '--labelAlign': calculatedLabelAlign,
      '--inputAlign': calculatedInputAlign,
      '--labelMarginBottom': `${labelMargin}px`,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      ...getInputWrapperAlignItems(calculatedInputAlign, isNewLayout),
      ...editorSpecificStyling({
        compProps,
        compLayout,
        compSingleLayout,
        isMobileView,
        hasResponsiveLayout,
        isOneDocMigrated,
      }),
      ...labelPaddingVars,
      ...getInputPadding(calculatedInputAlign, textPadding, isNewLayout),
      ...getIconInset(calculatedInputAlign, isNewLayout),
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: convertPhysicalAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'labelAlign' }],
        enhancer: convertPhysicalInputAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'textAlignment', target: 'labelDirection' }],
        enhancer: convertPhysicalInputAlignmentToDirection,
      },
    ]),
  ],
);

const stateRefs = withStateRefsValues([
  'enableCyclicTabbing',
  'disableCyclicTabbing',
  'setSiteScrollingBlocked',
  'scopedClassName',
]);

export default createComponentMapperModel({ props, css, stateRefs });
