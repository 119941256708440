import type {
  FlexDirection,
  LogicalAlignment,
} from '@wix/editor-elements-common-utils';
import type { VerticalAnchorsMenuCSSVars } from '../VerticalAnchorsMenu.types';

export const getFallbackBulletPosition = (
  orientation: 'left' | 'right',
): FlexDirection => {
  return orientation === 'left' ? 'row' : 'rowReverse';
};

export const getPaddingDirection = (
  align: LogicalAlignment,
  bulletPosition: FlexDirection,
  textSpacing: string,
): Pick<VerticalAnchorsMenuCSSVars, '--paddingStart' | '--paddingEnd'> => {
  if (align === 'start') {
    return { '--paddingStart': textSpacing };
  }
  if (align === 'end') {
    return { '--paddingEnd': textSpacing };
  }
  if (bulletPosition === 'row') {
    return { '--paddingStart': textSpacing };
  }
  return { '--paddingEnd': textSpacing };
};
