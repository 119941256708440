import * as React from 'react';
import classNames from 'clsx';

import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IYouTubeSubscribeButtonProps } from '../YouTubeSubscribeButton.types';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import styles from './styles/YouTubeSubscribeButton.scss';
import baseIframeUrl from './assets/iframe.html?resource';

const YouTubeSubscribeButton: React.FC<
  IYouTubeSubscribeButtonProps
> = props => {
  const {
    id,
    className,
    channel,
    layout,
    theme,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;
  const [iframeExpanded, setIframeExpanded] = React.useState(false);

  const iframeSrc = React.useMemo(() => {
    const params = { channel, layout, theme };
    return `${baseIframeUrl}?${new URLSearchParams(params)}`;
  }, [channel, layout, theme]);

  const expandIframe = () => setIframeExpanded(true);
  const collapseIframe = () => setIframeExpanded(false);
  const handleMouseEnter: React.MouseEventHandler = event => {
    expandIframe();
    onMouseEnter?.(event);
  };
  const handleMouseLeave: React.MouseEventHandler = event => {
    collapseIframe();
    onMouseLeave?.(event);
  };

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className,
    ...getDataAttributes(props),
  });

  return (
    <div
      {...baseComponentProps}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <wix-iframe
        {...getDataAttributes(props)}
        data-src={iframeSrc}
        class={classNames(styles.iframe, {
          [styles.expanded]: iframeExpanded,
        })}
      >
        {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
        <iframe
          data-src={iframeSrc}
          scrolling="no"
          frameBorder="0"
          allowFullScreen
        />
        <div
          className={styles.hoverOutVertical}
          onMouseEnter={collapseIframe}
        />
        <div
          className={styles.hoverOutHorizontal}
          onMouseEnter={collapseIframe}
        />
      </wix-iframe>
    </div>
  );
};

export default YouTubeSubscribeButton;
