import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import InnerShadowScreen from '../../../../ScreenWidthContainer/viewer/skinComps/InnerShadowScreen/InnerShadowScreen';
import FooterContainer from '../../FooterContainer';

const InnerShadowScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => (
  <FooterContainer {...props} skin={InnerShadowScreen}></FooterContainer>
);

export default InnerShadowScreenFooter;
