import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { VideoBoxDefinition } from '@wix/thunderbolt-components';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  VideoBoxProps,
  VideoBoxPreviewMapperProps,
} from '../VideoBox.types';
import { imageOverlayPreviewMap } from './constants';
import { props as viewerPropsMapper } from './VideoBox.mapper';

const getFillLayersPreviewProps = (
  carmiData: VideoBoxProps,
  {
    isPlayingAllowed,
    componentViewMode,
  }: { isPlayingAllowed: boolean; componentViewMode: string },
) => {
  let { fillLayers } = carmiData;
  const isEditorMode = componentViewMode === 'editor';

  if (fillLayers.video) {
    fillLayers = JSON.parse(JSON.stringify(fillLayers));
    fillLayers.video!.videoInfo.autoPlay =
      fillLayers.video!.videoInfo.autoPlay && isPlayingAllowed;

    if (
      isEditorMode &&
      fillLayers &&
      fillLayers.backgroundOverlay &&
      fillLayers.backgroundOverlay.imageOverlay
    ) {
      const overlayValue =
        imageOverlayPreviewMap[fillLayers.backgroundOverlay.imageOverlay.uri];

      if (overlayValue) {
        fillLayers.backgroundOverlay.imageOverlay.uri = overlayValue;
      }
    }

    return {
      fillLayers,
      previewWrapperProps: {
        autoPlayVideo: fillLayers.video?.videoInfo.autoPlay,
        isPlayingAllowed,
        showAudio: isEditorMode,
        shouldShowTransparentVideoPosterOnStop: isEditorMode,
      },
    };
  }
  return {};
};

const props = composeMapper<
  PreviewWrapperProps<VideoBoxProps, VideoBoxPreviewMapperProps>,
  VideoBoxDefinition,
  VideoBoxProps
>()(
  viewerPropsMapper,
  {
    add: ['isPlayingAllowed', 'componentViewMode'],
  },
  (resolver, dependencies, carmiData) => {
    const { isPlayingAllowed, componentViewMode } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);
    const previewWrapperProps = getFillLayersPreviewProps(carmiData, {
      isPlayingAllowed,
      componentViewMode,
    });

    return {
      ...viewerProps,
      ...previewWrapperProps,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
