import * as React from 'react';
import { useState } from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { useGesture } from '@wix/thunderbolt-elements/src/providers/useGesture/useGesture';
import type { IMobileActionsMenuProps } from '../MobileActionsMenu.types';
import { TestIDs } from '../constants';
import style from './style/MobileActionsMenu.scss';
import MobileActionsMenuItem from './MobileActionsMenuItem';
import type { MobileActionsButtonMenuItem } from './MobileActionButtonMenu';
import MobileActionButtonMenu from './MobileActionButtonMenu';
import {
  pageLinksToButtonMenuItems,
  socialLinksToButtonMenuItems,
} from './utils';
import { useZoomRatio } from './hooks/useZoomRatio';

type TouchOrMouseEvent = React.MouseEvent | TouchEvent;

const MobileActionsMenu: React.FC<IMobileActionsMenuProps> = props => {
  const {
    id,
    theme = 'dark',
    className,
    configuration: {
      addressEnabled,
      emailEnabled,
      navigationMenuEnabled,
      phoneEnabled,
      socialLinksEnabled,
    } = {},
    contactInfo,
    socialLinks = [],
    pageItems = [],
  } = props;

  const rootContainerRef = React.useRef<HTMLDivElement>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [actionButtonMenuItems, setActionButtonMenuItems] = useState<
    Array<MobileActionsButtonMenuItem> | undefined
  >();
  const [actionButtonMenuTitle, setActionButtonMenuTitle] = useState<
    string | undefined
  >();

  const zoomRatio = useZoomRatio();

  const lightStyleIndicator = {
    [style.light]: theme === 'light',
  };

  const handleKnobClick = () => {
    setOpen(!isOpen);
  };

  const handleShowActionsMenu = (e: TouchOrMouseEvent) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    setOpen(true);
  };

  const handleHideActionsMenu = (e: TouchOrMouseEvent) => {
    e.stopPropagation?.();
    e.preventDefault?.();
    setOpen(false);
  };

  useGesture('onSwipeUp', handleShowActionsMenu, rootContainerRef);
  useGesture('onSwipeDown', handleHideActionsMenu, rootContainerRef);

  const handleSetPages = () => {
    setActionButtonMenuItems(pageLinksToButtonMenuItems(pageItems));
    setActionButtonMenuTitle('Pages');
  };

  const handleSetSocialLinks = () => {
    setActionButtonMenuItems(socialLinksToButtonMenuItems(socialLinks));
    setActionButtonMenuTitle('See me on...');
  };

  const handleCloseButtonMenu = () => {
    setActionButtonMenuItems(undefined);
    setActionButtonMenuTitle(undefined);
  };

  return (
    <div id={id} ref={rootContainerRef} {...getDataAttributes(props)}>
      <div
        className={classNames(className, style.actionsMenu, {
          [style.opened]: isOpen,
        })}
        style={{ zoom: zoomRatio }}
        data-testid={TestIDs.actionsMenu}
      >
        <div
          className={classNames(style.knob, lightStyleIndicator)}
          onClick={handleKnobClick}
          data-testid={TestIDs.knob}
        />
        <ul className={classNames(style.menuContainer, lightStyleIndicator)}>
          {navigationMenuEnabled && (
            <MobileActionsMenuItem
              type="navigation"
              theme={theme}
              onClick={handleSetPages}
              dataTestId={TestIDs.actions.navigation}
            />
          )}
          {phoneEnabled && (
            <MobileActionsMenuItem
              type="phone"
              theme={theme}
              href={contactInfo?.phone}
              dataTestId={TestIDs.actions.phone}
            />
          )}
          {emailEnabled && (
            <MobileActionsMenuItem
              type="email"
              theme={theme}
              href={contactInfo?.email}
              dataTestId={TestIDs.actions.email}
            />
          )}
          {addressEnabled && (
            <MobileActionsMenuItem
              type="address"
              theme={theme}
              href={contactInfo?.address}
              dataTestId={TestIDs.actions.address}
            />
          )}
          {socialLinksEnabled && (
            <MobileActionsMenuItem
              type="socialLinks"
              theme={theme}
              onClick={handleSetSocialLinks}
              dataTestId={TestIDs.actions.socialLinks}
            />
          )}
        </ul>
      </div>
      {actionButtonMenuItems && (
        <div className={style.buttonMenu}>
          <MobileActionButtonMenu
            title={actionButtonMenuTitle}
            items={actionButtonMenuItems}
            onClose={handleCloseButtonMenu}
          />
        </div>
      )}
    </div>
  );
};

export default MobileActionsMenu;
