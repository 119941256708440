import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  SlideShowSlideDefinition,
  SlideShowSlideProps,
  SlideShowSlideCSSVars,
  SlideShowSlideSdkData,
  SlideShowSlideSDKCarmiData,
  SlideShowSlideCarmiData,
  SlideShowSlideCSSCarmiData,
} from '@wix/thunderbolt-components-native';

export const props = withCompInfo<
  SlideShowSlideProps,
  SlideShowSlideDefinition,
  SlideShowSlideCarmiData
>()(['skin'], ({ skin }, carmiProps) => {
  return {
    fillLayers: carmiProps.fillLayers,
    meshProps: carmiProps.meshProps,
    skin,
  };
});

export const css = withCompInfo<
  SlideShowSlideCSSVars,
  SlideShowSlideDefinition,
  SlideShowSlideCSSCarmiData
>()([], (deps, carmiData) => ({
  position: carmiData.isStateBoxStateType ? 'relative' : 'absolute',
  width: '100%',
  height: carmiData.isStateBoxStateType ? 'auto' : '100%',
  ...carmiData.cssVars,
}));

export const sdkData = withCompInfo<
  SlideShowSlideSdkData,
  SlideShowSlideDefinition,
  SlideShowSlideSDKCarmiData
>()(['compData'], ({ compData }, carmiData) =>
  carmiData.isStateBoxStateType
    ? { isStateBoxStateType: carmiData.isStateBoxStateType }
    : {
        isStateBoxStateType: carmiData.isStateBoxStateType,
        name: compData.title,
      },
);

export default createComponentMapperModel({ props, css, sdkData });
