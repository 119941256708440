import React from 'react';
import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import type {
  IPaginationFormProps,
  IPaginationProps,
  IPaginationStripProps,
} from '../Pagination.types';
import { isNextEnabled, isPreviousEnabled } from '../utils';
import semanticClassNames from '../Pagination.semanticClassNames';
import { NavButton } from './NavButton';
import { First, Last, Next, Previous } from './assets';
import { st, classes } from './style/Pagination.st.css';
import { testIds } from './constants';
import PageForm from './skinComps/PaginationForm/PageForm';
import PageStrip from './skinComps/PaginationStrip/PageStrip';

const Pagination: React.FC<IPaginationProps> = props => {
  const {
    id,
    customClassNames = [],
    isDisabled,
    forceNextButtonEnabled,
    forcePreviousButtonEnabled,
    onChange,
    paginationDirection,
    currentPage,
    totalPages,
    showFirstLastNavButtons,
    firstText,
    previousText,
    paginationMode,
    nextText,
    lastText,
    navigationType,
    onClick,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    isResponsiveHeight,
    onNextClick,
    onPrevClick,
    className,
    previousPageUrl,
    nextPageUrl,
  } = props;

  const notifyPageChanged = (page: number) =>
    onChange({
      type: 'change',
      target: { value: { currentPage: page } },
    });

  const pageSelectorChangeHandler = (page: number) => notifyPageChanged(page);

  const navButtonChangeHandler = (page: number) => () =>
    notifyPageChanged(page);

  const nextButtonChangeHandler = () => {
    onNextClick?.({ type: 'onNextClick' });
    navButtonChangeHandler(currentPage + 1)();
  };

  const prevButtonChangeHandler = () => {
    onPrevClick?.({ type: 'onPrevClick' });
    navButtonChangeHandler(currentPage - 1)();
  };

  // Adjustments for the component height on mobile with PageStrip - required for pixel-perfect match w/legacy
  const [minHeight, setMinHeight] = React.useState<number>(0);
  const nextButtonContainerRef = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    const nextButtonContainerElem = nextButtonContainerRef.current;
    if (
      paginationMode === 'input' ||
      !isResponsiveHeight ||
      nextButtonContainerElem === null
    ) {
      return;
    }

    setMinHeight(nextButtonContainerElem.offsetHeight); // Web standards will round this up!
  }, [isResponsiveHeight, paginationMode]);

  return (
    <nav
      id={id}
      dir={paginationDirection}
      className={st(
        classes.root,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      {...getDataAttributes(props)}
      data-testid={testIds.root}
      aria-label="Pagination"
      style={minHeight > 0 ? { minHeight: `${minHeight}px` } : {}}
      {...(!isDisabled && {
        onClick,
        onDoubleClick: onDblClick,
        onMouseEnter,
        onMouseLeave,
      })}
    >
      <div className={classes.navButtonsContainer}>
        {showFirstLastNavButtons && (
          <NavButton
            testId={testIds.navButtonFirst}
            className={st(
              classes.navButton,
              { navigationType },
              formatClassNames(semanticClassNames.navigationButton),
            )}
            ariaLabel="First Page"
            onChange={navButtonChangeHandler(1)}
            isDisabled={isDisabled || currentPage <= 1}
          >
            {navigationType === 'arrows' ? <First /> : <span>{firstText}</span>}
          </NavButton>
        )}
        <NavButton
          testId={testIds.navButtonPrev}
          className={st(
            classes.navButton,
            { navigationType },
            formatClassNames(semanticClassNames.navigationButton),
          )}
          url={previousPageUrl}
          ariaLabel="Previous Page"
          onChange={prevButtonChangeHandler}
          isDisabled={
            !isPreviousEnabled({
              forcePreviousButtonEnabled,
              isDisabled,
              currentPage,
            })
          }
        >
          {navigationType === 'arrows' ? (
            <Previous />
          ) : (
            <span>{previousText}</span>
          )}
        </NavButton>
      </div>
      {props.paginationMode === 'input' ? (
        <PageForm
          key={props.currentPage}
          {...(props as IPaginationFormProps)}
          onChange={pageSelectorChangeHandler}
        />
      ) : (
        <PageStrip
          key={props.currentPage}
          {...(props as IPaginationStripProps)}
          onChange={pageSelectorChangeHandler}
        />
      )}
      <div className={classes.navButtonsContainer} ref={nextButtonContainerRef}>
        <NavButton
          testId={testIds.navButtonNext}
          className={st(
            classes.navButton,
            { navigationType },
            formatClassNames(semanticClassNames.navigationButton),
          )}
          url={nextPageUrl}
          ariaLabel="Next Page"
          onChange={nextButtonChangeHandler}
          isDisabled={
            !isNextEnabled({
              forceNextButtonEnabled,
              isDisabled,
              currentPage,
              totalPages,
            })
          }
        >
          {navigationType === 'arrows' ? <Next /> : <span>{nextText}</span>}
        </NavButton>
        {showFirstLastNavButtons && (
          <NavButton
            testId={testIds.navButtonLast}
            className={st(
              classes.navButton,
              { navigationType },
              formatClassNames(semanticClassNames.navigationButton),
            )}
            ariaLabel="Last Page"
            onChange={navButtonChangeHandler(totalPages)}
            isDisabled={isDisabled || currentPage >= totalPages}
          >
            {navigationType === 'arrows' ? <Last /> : <span>{lastText}</span>}
          </NavButton>
        )}
      </div>
    </nav>
  );
};

export default Pagination;
