export const TestIds = {
  linkButton: 'linkButton',
  linkInput: 'linkInput',
  linkUpdate: 'linkUpdate',
  linkCancel: 'linkCancel',
  linkRemove: 'linkCancel',
  textSizeButton: 'textSizeButton',
  boldButton: 'boldButton',
  italicButton: 'italicButton',
  alignButton: 'alignButton',
  underlineButton: 'underlineButton',
  orderedListButton: 'orderedListButton',
  unOrderedListButton: 'unOrderedListButton',
  scroller: 'scroller',
  label: 'label',
} as const;
