import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import {
  IQuickActionBarMapperProps,
  QuickActionBarDefinition,
} from '../QuickActionBar.types';
import { props as viewerPropsMapper } from './QuickActionBar.mapper';

const props = composeMapper<
  IQuickActionBarMapperProps,
  QuickActionBarDefinition,
  IQuickActionBarMapperProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const { compPreviewState } = deps;

    return {
      ...resolver(deps, carmiData),
      previewWrapperProps: { compPreviewState },
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
