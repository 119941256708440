import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';

const props = withCompInfo<any, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...compData,
      stylableClassName: compStylableClass,
    };
  },
);

export default createComponentMapperModel({
  props,
});
