import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import BoxScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/BoxScreen';
import HeaderContainer from '../../HeaderContainer';

const BoxScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => <HeaderContainer {...props} skin={BoxScreen}></HeaderContainer>;

export default BoxScreenHeader;
