export const testIds = {
  designableList: 'designable_list',
  designableListOption: 'designable_list_option',
};

export const migratedFields = [
  'dropdownItemsSpacing',
  'textPadding',
  'textAlignment',
];
