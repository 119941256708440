import {
  IPlatformData,
  withCompController,
} from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IPinterestFollowControllerProps,
  IPinterestFollowMapperProps,
  IPinterestFollowProps,
  IPinterestFollowStateValues,
} from '../PinterestFollow.types';

const useComponentProps = ({
  mapperProps,
  stateValues,
}: IPlatformData<
  IPinterestFollowMapperProps,
  IPinterestFollowControllerProps,
  IPinterestFollowStateValues
>): IPinterestFollowProps => {
  const {
    compId,
    language,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick: IPinterestFollowControllerProps['reportBiOnClick'] =
    event => {
      const { reportBi } = stateValues;
      const { label, urlChoice } = restMapperProps;

      tryReportAnalyticsClicksBi(reportBi, {
        language,
        trackClicksAnalytics,
        value: urlChoice,
        elementTitle: label,
        elementType: fullNameCompType,
        elementGroup: AnalyticsClicksGroups.Social,
        element_id: compId ?? event.currentTarget.id,
      });
    };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
};

export default withCompController(useComponentProps);
