import * as React from 'react';
import classnames from 'clsx';
import {
  activateByEnterButton,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import type { IIFrameConfig } from '../../MemberLoginDialog/MemberLoginDialog.types';
import { SocialAuth } from '../../MemberLoginDialog/viewer/SocialAuth';
import { SocialAuthButtons } from '../../MemberLoginDialog/viewer/SocialAuthButtons';
import { EmailAuth } from '../../MemberLoginDialog/viewer/EmailAuth';
import { useSocialErrorHandler } from '../../MemberLoginDialog/useSocialErrorHandler';
import { UnsupportedSocialAuthMessage } from '../../MemberLoginDialog/viewer/UnsupportedSocialAuthMessage';
import {
  getReportCopyToClipboardBiCallback,
  isFacebookEnabled,
  isGoogleEnabled,
  isMandatorySSO,
} from '@wix/editor-elements-library/src/components/MemberLoginDialog/viewer/utils';
import { MandatorySSODialog } from '../../MemberLoginDialog/viewer/MandatorySSODialog';
import { HeadlessLoginDialog } from '../../MemberLoginDialog/viewer/HeadlessLoginDialog';
import { HeadlessAuthButtons } from '../../MemberLoginDialog/viewer/HeadlessAuthButtons';
import SiteMembersDialogLayout from '@wix/thunderbolt-elements/src/components/SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import type { ISignUpDialogProps } from '../SignUpDialog.types';
import { Community } from './Community';
import { Policies } from './Policies';
import style from './style/style.scss';
import { SignUpDialogTranslationKeys as keys, testIds } from './constants';

const SignUpDialog: React.FC<ISignUpDialogProps> = props => {
  const {
    id,
    className,
    isEmailLoginEnabled,
    isSocialAuthSupported,
    idps,
    submit,
    onSwitchDialogLinkClick,
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityInstalled,
    isPrivacyPolicyNeeded,
    isTermsOfUseNeeded,
    isCodeOfConductNeeded,
    codeOfConductLink,
    privacyPolicyLink,
    termsOfUseLink,
    onCloseDialogCallback,
    isCloseable,
    joinCommunityCheckedByDefault,
    onBackendSocialLogin,
    reportBi,
    displayMode,
    directionByLanguage,
    privacyNoteType,
    serverError,
    reportSocialAuthStarted,
    translations,
    externalBaseUrl,
    headlessRedirectUrl,
  } = props;

  const [
    socialAuthError,
    translateAndSetSocialAuthError,
    resetSocialAuthError,
  ] = useSocialErrorHandler(translations);
  const [isCommunityChecked, setCommunityChecked] = React.useState<boolean>(
    isCommunityInstalled && joinCommunityCheckedByDefault,
  );

  const headlineId = `signUpHeadline_${id}`;

  const wrappedOnBackendSocialLogin =
    resetSocialAuthError(onBackendSocialLogin);

  const iframeConfig: IIFrameConfig = {
    language,
    biVisitorId,
    bsi,
    smCollectionId,
    svSession,
    metaSiteId,
    isCommunityChecked,
    useGoogleSdk: props.useGoogleSdk,
  };

  const isExternalWebsiteInSocialInAppBrowser =
    !isSocialAuthSupported && isEmailLoginEnabled;
  const isSocialAuthEnabled = idps.length > 0;
  // If no social auth enabled or we in external wix site inside social in app browser we skip to sign up with email page
  const [showSocialAuthScreen, setShowSocialAuthScreen] = React.useState(
    isSocialAuthEnabled && !isExternalWebsiteInSocialInAppBrowser,
  );
  const [isFormLoading, setIsFormLoading] = React.useState(false);

  const title = translations[keys.title];
  const submitButtonLabel = translations[keys.submitButton];
  const switchDialogLink = translations[keys.switchDialogLink];
  const switchToSignUp = translations[keys.switchToSignIn];
  const emailSectionDivider = translations[keys.emailSectionDivider];
  const switchToAuthWithEmail = translations[keys.switchToAuthWithEmail];
  const socialSectionDividerLabel = translations[keys.socialSectionDivider];

  const presentedAuthMethodScreen = showSocialAuthScreen ? (
    <SocialAuth
      id={id}
      emailSectionDivider={emailSectionDivider}
      switchToAuthWithEmail={switchToAuthWithEmail}
      goToEmailAuthScreen={resetSocialAuthError(setShowSocialAuthScreen)}
      idps={idps}
      iframeConfig={iframeConfig}
      style={style}
      mode="signup"
      isEmailLoginEnabled={isEmailLoginEnabled}
      translations={translations}
      error={socialAuthError}
      reportSocialAuthStarted={reportSocialAuthStarted}
      onBackendSocialLogin={wrappedOnBackendSocialLogin}
      onSocialError={translateAndSetSocialAuthError}
    />
  ) : (
    <EmailAuth
      id={id}
      translations={translations}
      onSubmitStart={() => setIsFormLoading(true)}
      onSubmitEnd={() => setIsFormLoading(false)}
      submit={submit}
      style={style}
      mode="signup"
      submitButtonLabel={submitButtonLabel}
      isCommunityChecked={isCommunityChecked}
      language={language}
      directionByLanguage={directionByLanguage}
      shouldForceCaptchaVerification={props.shouldForceCaptchaVerification}
      serverError={serverError}
    />
  );

  const isMandatorySSOEnabled = isMandatorySSO(idps, isEmailLoginEnabled);

  const socialAuthButtons = (
    <SocialAuthButtons
      id={id}
      idps={idps}
      iframeConfig={iframeConfig}
      style={style}
      translations={translations}
      mode="signup"
      error={socialAuthError}
      isHorizontal={true}
      hideSSOButton={!isMandatorySSOEnabled}
      reportSocialAuthStarted={reportSocialAuthStarted}
      onBackendSocialLogin={wrappedOnBackendSocialLogin}
      onSocialError={translateAndSetSocialAuthError}
    />
  );

  let dialogContent;

  if (isMandatorySSOEnabled) {
    dialogContent = (
      <MandatorySSODialog
        id={id}
        directionByLanguage={directionByLanguage}
        headlineId={headlineId}
        translations={translations}
      >
        <div className={style.mandatorySsoButtonContainer}>
          {socialAuthButtons}
        </div>
        <div className={style.formFooter}>
          {isCommunityInstalled && (
            <Community
              privacyNoteType={privacyNoteType}
              checked={isCommunityChecked}
              setChecked={setCommunityChecked}
              translations={translations}
              directionByLanguage={directionByLanguage}
              isCodeOfConductNeeded={isCodeOfConductNeeded}
              codeOfConductLink={codeOfConductLink}
              onCloseDialogCallback={onCloseDialogCallback}
            />
          )}
          {(isPrivacyPolicyNeeded || isTermsOfUseNeeded) && (
            <Policies
              translations={translations}
              isPrivacyPolicyNeeded={isPrivacyPolicyNeeded}
              isTermsOfUseNeeded={isTermsOfUseNeeded}
              termsOfUseLink={termsOfUseLink}
              privacyPolicyLink={privacyPolicyLink}
              onCloseDialogCallback={onCloseDialogCallback}
            />
          )}
        </div>
      </MandatorySSODialog>
    );
  } else if (headlessRedirectUrl) {
    dialogContent = (
      <HeadlessLoginDialog
        id={id}
        directionByLanguage={directionByLanguage}
        headlineId={headlineId}
        translations={translations}
      >
        <HeadlessAuthButtons
          loginUrl={headlessRedirectUrl}
          style={style}
          iframeConfig={iframeConfig}
          onBackendSocialLogin={wrappedOnBackendSocialLogin}
          onSocialError={translateAndSetSocialAuthError}
          error={socialAuthError}
          externalBaseUrl={externalBaseUrl}
        />
        <div className={style.formFooter}>
          {isCommunityInstalled && (
            <Community
              privacyNoteType={privacyNoteType}
              checked={isCommunityChecked}
              setChecked={setCommunityChecked}
              translations={translations}
              directionByLanguage={directionByLanguage}
              isCodeOfConductNeeded={isCodeOfConductNeeded}
              codeOfConductLink={codeOfConductLink}
              onCloseDialogCallback={onCloseDialogCallback}
            />
          )}
          {(isPrivacyPolicyNeeded || isTermsOfUseNeeded) && (
            <Policies
              translations={translations}
              isPrivacyPolicyNeeded={isPrivacyPolicyNeeded}
              isTermsOfUseNeeded={isTermsOfUseNeeded}
              termsOfUseLink={termsOfUseLink}
              privacyPolicyLink={privacyPolicyLink}
              onCloseDialogCallback={onCloseDialogCallback}
            />
          )}
        </div>
      </HeadlessLoginDialog>
    );
  } else {
    dialogContent = (
      <>
        <div
          id={id}
          className={classnames(classnames(style.memberLoginContent), {
            [style.formLoading]: isFormLoading,
            [style.rtl]: directionByLanguage === 'rtl',
          })}
        >
          <h1
            id={`signUpHeadline_${id}`}
            className={style.title}
            data-testid={testIds.headline}
          >
            {title}
          </h1>
          <div
            className={classnames(style.contentWrapper, style.wrapper, {
              [style.horizontal]: isSocialAuthEnabled,
              [style.socialLoginMode]: showSocialAuthScreen,
              [style.vertical]: !isSocialAuthEnabled,
              [style.emailLoginMode]: !showSocialAuthScreen,
            })}
          >
            <div className={style.switchToSignUpContainer}>
              <span
                data-testid={testIds.switchToSignUp.description}
                className={style.switchToSignUpText}
              >
                {switchToSignUp}
              </span>
              <button
                className={style.switchDialogLink}
                data-testid={testIds.switchToSignUp.title}
                onClick={onSwitchDialogLinkClick}
                onKeyDown={activateByEnterButton}
                autoFocus
                type="button"
              >
                {switchDialogLink}
              </button>
            </div>
            {presentedAuthMethodScreen}
            {isSocialAuthEnabled &&
              (isExternalWebsiteInSocialInAppBrowser ? (
                <UnsupportedSocialAuthMessage
                  isSocialLoginGoogleEnabled={isGoogleEnabled(idps)}
                  isSocialLoginFacebookEnabled={isFacebookEnabled(idps)}
                  translations={translations}
                  mode="Signup"
                  styleMode="dialog"
                  reportCopyToClipboardBi={getReportCopyToClipboardBiCallback(
                    reportBi,
                    { biVisitorId, metaSiteId },
                  )}
                />
              ) : (
                <div
                  className={classnames({
                    [style.hideAuthMethod]: showSocialAuthScreen,
                  })}
                >
                  <div
                    className={classnames(
                      style.socialSectionDivider,
                      style.sectionDivider,
                    )}
                  >
                    <span>{socialSectionDividerLabel}</span>
                  </div>
                  {socialAuthButtons}
                </div>
              ))}
          </div>
          <div className={style.formFooter}>
            {isCommunityInstalled && (
              <Community
                privacyNoteType={privacyNoteType}
                checked={isCommunityChecked}
                setChecked={setCommunityChecked}
                translations={translations}
                directionByLanguage={directionByLanguage}
                isCodeOfConductNeeded={isCodeOfConductNeeded}
                codeOfConductLink={codeOfConductLink}
                onCloseDialogCallback={onCloseDialogCallback}
              />
            )}
            {(isPrivacyPolicyNeeded || isTermsOfUseNeeded) && (
              <Policies
                translations={translations}
                isPrivacyPolicyNeeded={isPrivacyPolicyNeeded}
                isTermsOfUseNeeded={isTermsOfUseNeeded}
                termsOfUseLink={termsOfUseLink}
                privacyPolicyLink={privacyPolicyLink}
                onCloseDialogCallback={onCloseDialogCallback}
              />
            )}
          </div>
        </div>
        <div
          className={classnames(style.preloader, style.circlePreloader, {
            [style.formLoading]: isFormLoading,
          })}
        />
      </>
    );
  }

  return (
    <SiteMembersDialogLayout
      {...getDataAttributes(props)}
      className={className}
      isCloseable={isCloseable}
      translations={translations}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      displayMode={displayMode}
      dialogPosition="start"
    >
      {dialogContent}
    </SiteMembersDialogLayout>
  );
};

export default SignUpDialog;
