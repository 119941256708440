import React from 'react';
import type { AnimationState } from '@wix/thunderbolt-elements/providers/useSwitchAnimation';
import { TestIds } from '../constants';
import { st, classes } from './style/Tabs.component.st.css';

type ITabWrapperProps = {
  containerId: string;
  currentTabRef: (tabRef: HTMLDivElement) => void;
  tabElement: React.ReactElement;
  animationState: AnimationState;
};

const TabWrapper: React.FC<ITabWrapperProps> = ({
  tabElement,
  currentTabRef,
  animationState,
}) => {
  const uniqueId = tabElement.props?.id;

  return (
    <div
      className={st(classes.animationWrapper, {
        phase: animationState?.phase,
      })}
    >
      <div
        aria-hidden={!animationState?.isVisible}
        className={st(classes.tabPanelWrapper, {
          isVisible: animationState?.isVisible,
          phase: animationState?.phase,
        })}
        ref={currentTabRef}
        tabIndex={-1}
        role="tabpanel"
        aria-labelledby={`tab-${uniqueId}`}
        key={uniqueId}
        data-hook={TestIds.TabPanel}
      >
        {tabElement}
      </div>
    </div>
  );
};

export default TabWrapper;
