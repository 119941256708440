import { MenuOrientation, MenuOverflow } from '../../constants';
import type {
  MenuOrientationType,
  IMenuOrientationCssVars,
  IMenuStretchedCssVars,
} from '../../Menu.types';
import type { Overflow } from '@wix/editor-elements-common-utils';

export const orientationToCssVarsMap: Record<
  MenuOrientationType,
  IMenuOrientationCssVars
> = {
  [MenuOrientation.Horizontal]: {
    '--flex-direction': 'row',
    '--item-wrapper-width': 'unset',
    '--menu-items-row-gap': 'var(--menu-items-cross-axis-gap)',
    '--menu-items-column-gap': 'var(--menu-items-main-axis-gap)',
  },
  [MenuOrientation.Vertical]: {
    '--flex-direction': 'column',
    '--item-wrapper-width': '100%',
    '--menu-items-row-gap': 'var(--menu-items-main-axis-gap)',
    '--menu-items-column-gap': 'var(--menu-items-cross-axis-gap)',
  },
};

export const stretchedCssVars: IMenuStretchedCssVars = {
  '--position-box-not-stretched-left': 'unset',
  '--position-box-width': '100vw',
  '--mega-menu-min-width': '100%',
};

export const notStretchedCssVars: IMenuStretchedCssVars = {
  '--position-box-not-stretched-left': 'auto',
  '--position-box-width': '100%',
  '--mega-menu-min-width': 'unset',
};

export const overflowToCssVarsMap: Record<
  Overflow,
  Record<MenuOrientationType, { [key: string]: string }>
> = {
  [MenuOverflow.Scroll]: {
    [MenuOrientation.Horizontal]: {
      'min-width': 'initial',
      '--overflow-x': 'auto',
      '--flex-wrap': 'nowrap',
    },
    [MenuOrientation.Vertical]: {},
  },
  [MenuOverflow.Wrap]: {
    [MenuOrientation.Horizontal]: {
      'min-width': 'min-content',
      '--overflow-x': 'hidden',
      '--flex-wrap': 'wrap',
    },
    [MenuOrientation.Vertical]: {
      'min-width': 'min-content',
      '--flex-wrap': 'wrap',
    },
  },
};
