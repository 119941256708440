import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import WoodScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/WoodScreen';
import FooterContainer from '../../FooterContainer';

const WoodScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => <FooterContainer {...props} skin={WoodScreen}></FooterContainer>;

export default WoodScreenFooter;
