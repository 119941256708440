import * as React from 'react';
import classNames from 'clsx';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import style from './style/MobileActionsMenuItem.scss';

type ItemType = 'navigation' | 'phone' | 'email' | 'address' | 'socialLinks';

const typeStyles: Record<ItemType, string> = {
  navigation: style.navigation,
  phone: style.phone,
  email: style.email,
  address: style.address,
  socialLinks: style.socialLinks,
};

export type MobileActionsMenuItem = {
  dataTestId?: string;
  onClick?: () => void;
  href?: string;
  theme: string;
  type: ItemType;
};

const MobileActionsMenuItem: React.FC<MobileActionsMenuItem> = ({
  onClick,
  href,
  theme,
  type,
  dataTestId,
}) => {
  const lightStyleIndicator = {
    [style.light]: theme === 'light',
  };

  const buttonClassName: string = classNames(
    style.button,
    lightStyleIndicator,
    typeStyles[type],
  );

  const handleOnClick = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    e?.preventDefault();
    if (onClick) {
      onClick();
    }
  };

  return (
    <li
      className={classNames(style.menuItem, lightStyleIndicator)}
      data-testid={dataTestId}
    >
      {href ? (
        <Link className={buttonClassName} href={href} />
      ) : (
        <Link className={buttonClassName} onClick={handleOnClick} />
      )}
    </li>
  );
};

export default MobileActionsMenuItem;
