import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuProps as VerticalAnchorsMenuPlatformProps,
  VerticalAnchorsMenuPropsCarmiData,
} from '../VerticalAnchorsMenu.types';
import { props as viewerMapperProps } from './VerticalAnchorsMenu.mapper';

type VerticalAnchorsMenuPreviewProps = Omit<
  VerticalAnchorsMenuPlatformProps,
  'anchors'
>;
const props = composeMapper<
  PreviewWrapperProps<VerticalAnchorsMenuPreviewProps>,
  VerticalAnchorsMenuDefinition,
  VerticalAnchorsMenuPropsCarmiData
>()(
  viewerMapperProps,
  {
    add: ['compPreviewState'],
    remove: ['compId', 'fullNameCompType', 'language', 'trackClicksAnalytics'],
  },
  (resolver, dependencies, carmiData) => {
    const { compPreviewState } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
