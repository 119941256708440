import React from 'react';
import { Map } from 'immutable';
import { ContentBlock, EditorProps, EditorState, Modifier } from 'draft-js';
import {
  AlignCenterIcon,
  AlignJustifyIcon,
  AlignLeftIcon,
  AlignRightIcon,
} from './icons';
import styles from './textAlightStyles.scss';

export const textAlignmentStylesFn = (
  contentBlock: ContentBlock,
  defaultAlignment: EditorProps['textAlignment'],
) => {
  const { data } = contentBlock.toJS();

  const textAlign: AlignButtonType =
    data.textAlignment || defaultAlignment || 'left';
  const className = {
    left: styles.textAlignLeft,
    right: styles.textAlignRight,
    center: styles.textAlignCenter,
    justify: styles.textAlignJustify,
  };
  return className[textAlign];
};

export type AlignButtonType = 'left' | 'right' | 'center' | 'justify';

export const alignTypes: Array<AlignButtonType> = [
  'left',
  'right',
  'center',
  'justify',
];

export const alignmentButtons: Record<AlignButtonType, JSX.Element> = {
  left: <AlignLeftIcon />,
  right: <AlignRightIcon />,
  center: <AlignCenterIcon />,
  justify: <AlignJustifyIcon />,
};

export const getActiveAlignment = (
  editorState: EditorState,
): AlignButtonType => {
  const startKey = editorState.getSelection().getStartKey();
  const contentBlock = editorState.getCurrentContent().getBlockForKey(startKey);
  const {
    data: { textAlignment },
  } = contentBlock.toJS();
  return alignTypes.find(alignment => alignment === textAlignment) || 'left';
};

export const preventBubblingUp = (
  event: React.MouseEvent<HTMLOrSVGElement>,
) => {
  event.preventDefault();
  event.stopPropagation();
};

export const getStateWithNewTextAlign = (
  textAlignment: AlignButtonType,
  editorState: EditorState,
) => {
  const newContent = Modifier.mergeBlockData(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    Map({ textAlignment }),
  );

  return EditorState.push(editorState, newContent, 'change-block-data');
};
