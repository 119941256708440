import {
  withCompInfo,
  createComponentPreviewMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  TinyMenuDefinition,
  TinyMenuProps,
} from '@wix/thunderbolt-components';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type { SkinType } from '../TinyMenu.types';
import { props as viewerPropsMapper } from './TinyMenu.mapper';

const props = composeMapper<
  PreviewWrapperProps<TinyMenuProps>,
  TinyMenuDefinition,
  TinyMenuProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'], remove: ['trackClicksAnalytics'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      isOpen: deps.compPreviewState === 'open',
    };
  },
);

const uiType = withCompInfo()(['skin'], (({ skin }: { skin: SkinType }) =>
  skin === 'TinyMenuSkin' ? skin : 'TinyMenuDefaultSkin') as any);

export default createComponentPreviewMapperModel({ props, uiType });
