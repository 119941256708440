import * as React from 'react';
import type {
  ISlideshowIndicatorIconProps,
  ISlideshowIndicatorIconsProps,
} from '../types';
import { classes, st } from './style/SlideshowIndicator.component.st.css';

const SlideshowIndicatorIcon: React.FC<
  ISlideshowIndicatorIconProps
> = props => {
  const { svg, index, className } = props;

  return React.createElement('div', {
    dangerouslySetInnerHTML: {
      __html: svg || '',
    },
    className,
    'aria-hidden': true,
    'aria-labelledby': `slide-indicator-${index}`,
  });
};

const SlideshowIndicatorIcons: React.FC<
  ISlideshowIndicatorIconsProps
> = props => {
  const { slidesToDisplay, currentSlide, icon, updateIndex } = props;

  return (
    <nav aria-label="slideshow">
      <ol className={st(classes.iconsList)}>
        {[...Array(slidesToDisplay)].map((e, i) => (
          <li
            id={`${i}`}
            key={`slideshow-indicator-${i}`}
            onClick={() => updateIndex(i)}
            aria-current={currentSlide === i}
          >
            <a href={`#${i}`}>
              <SlideshowIndicatorIcon
                index={i}
                className={st(classes.icon, {
                  isSelected: currentSlide === i,
                })}
                svg={icon?.svgId ?? ''}
              />
            </a>
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default SlideshowIndicatorIcons;
