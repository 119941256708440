import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  getSlideAnimationsForPreview,
  shouldForceAnimation,
} from '../utils/animations';

import type {
  ISlideshowCarmiProps,
  ISlideshowDefinition,
  ISlideshowMapperProps,
} from '../../types';
import type { TransitionDirection } from '../../constants';
import { Transition } from '../../constants';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './Slideshow.mapper';

const isInEditor = (componentPreviewMode: string) =>
  componentPreviewMode === 'editor';

const props = composeMapper<
  PreviewWrapperProps<ISlideshowMapperProps>,
  ISlideshowDefinition,
  ISlideshowCarmiProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode', 'compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const { componentViewMode, compPreviewState } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);

    return {
      ...viewerProps,
      isPlaying: isInEditor(componentViewMode) ? false : viewerProps.isPlaying,
      previewWrapperProps: {
        compPreviewState,
      },
    };
  },
);

const css = composeMapper()(
  viewerCssMapper,
  { add: ['componentViewMode', 'compPreviewState'] },
  (resolver, dependencies, carmiData) => {
    const { componentViewMode, compPreviewState, styleProperties } =
      dependencies;
    const { transitionEffect, transitionDirection } = styleProperties;
    const viewerCss = resolver(dependencies, carmiData);
    const shouldSkipAnimation =
      componentViewMode === 'editor' && !shouldForceAnimation(compPreviewState);

    return {
      ...viewerCss,
      ...(shouldSkipAnimation && { '--transitionDuration': 0 }),
      ...(transitionEffect === Transition.Slide &&
        getSlideAnimationsForPreview(
          transitionDirection as TransitionDirection,
        )),
    };
  },
);
export default createComponentPreviewMapperModel({
  props,
  css,
});
