import { sdk as imageClientSdk } from '@wix/image-kit';
import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import {
  MusicPlayerDefinition,
  IMusicPlayerMapperProps,
  MusicPlayerCSSVars,
  MusicPlayerCompData,
  MusicPlayerCompProps,
  IconId,
  MusicPlayerSdkData,
  IMusicPlayerStateValues,
} from '../MusicPlayer.types';

const CLASSIC_LAYOUT = 'classic';
const DEFAULT_ICON_ID = 'icon0';
const MAX_COVER_SIZE = 140;
const COVER_TRANSFORM_TYPE = { transformType: 'fit', quality: 90 };
const FALLBACK_COVER_URI = 'f9a47e_a45ee66e0d00442cbdbfcc323bb135d8.svg';

const SOURCE = { UPLOADS: 'uploads', URL: 'url' };

const staticAudioUrl = 'https://music.wixstatic.com/mp3'; // TODO add to topology

export const props = withCompInfo<
  IMusicPlayerMapperProps,
  MusicPlayerDefinition
>()(
  ['compData', 'compProps', 'isMobileView', 'topology', 'experiments'],
  ({ compData, compProps, isMobileView, topology, experiments }) => {
    const iconId = (compProps.iconId as IconId) || DEFAULT_ICON_ID; // TODO fix iconId type in MusicPlayerProperties

    return {
      displayTime: isMobileView ? 'remaining' : 'full',
      isClassicLayout: compProps.layout === CLASSIC_LAYOUT,
      showCover: compProps.showCover,
      showArtistName: compProps.showArtistName,
      showTrackName: compProps.showTrackName,
      showProgressBar: compProps.showProgressBar,
      showTimeStamp: compProps.showTimeStamp,
      loop: compProps.loop,
      iconId,
      playlist: [
        {
          artistName: compData.artistName,
          trackName: compData.trackName,
          cover: getCoverUrl(compData),
          url: getAudioUrl(compData, compProps),
        },
      ],
      duration: compData.audioRef && compData.audioRef.duration,
      fallbackCover: `${topology.mediaRootUrl}shapes/${FALLBACK_COVER_URI}`,
      playClassName: playClassNames[iconId] || 'oneColor',
      pauseClassName: pauseClassNames[iconId] || 'oneColor',
      isWaitUntilPlayedExperiment: isExperimentOpen(
        experiments,
        'specs.thunderbolt.musicPlayerWaitUntilPlayed',
      ),
    };
  },
);

export const css = withCompInfo<MusicPlayerCSSVars, MusicPlayerDefinition>()(
  ['hasResponsiveLayout', 'compProps', 'compLayout', 'isOneDocMigrated'],
  ({ hasResponsiveLayout, compProps, compLayout, isOneDocMigrated }) => {
    if (hasResponsiveLayout || isOneDocMigrated) {
      return {};
    }

    if (compProps.layout === CLASSIC_LAYOUT) {
      return { height: 'auto' };
    }

    return { height: `${compLayout.width}px` };
  },
);

export const sdkData = withCompInfo<
  MusicPlayerSdkData,
  MusicPlayerDefinition
>()(['compData', 'compProps'], ({ compData, compProps }) => {
  const audioData =
    compProps.source === SOURCE.URL && compData.audioUrl
      ? { uri: compData.audioUrl, title: '' }
      : {
          uri: compData.audioRef?.uri || '',
          title: compData.audioRef?.title || '',
        };
  const coverData = compData.coverUrl
    ? { uri: compData.coverUrl, title: '', width: 0, height: 0 }
    : {
        title: compData.coverRef?.title,
        uri: compData.coverRef?.uri,
        width: compData.coverRef?.width,
        height: compData.coverRef?.height,
      };
  return {
    audioData,
    coverData,
  };
});

function getCoverUrl(compData: MusicPlayerCompData) {
  const coverUrl = compData.coverUrl;

  if (coverUrl) {
    return coverUrl;
  }

  const coverRef = compData.coverRef;

  if (!coverRef || typeof coverRef !== 'object') {
    return undefined;
  }

  return imageClientSdk.getScaleToFillImageURL(
    coverRef.uri,
    coverRef.width,
    coverRef.height,
    MAX_COVER_SIZE,
    MAX_COVER_SIZE,
    COVER_TRANSFORM_TYPE,
  ) as string;
}

function getAudioUrl(
  compData: MusicPlayerCompData,
  compProps: MusicPlayerCompProps,
) {
  if (compProps.source === SOURCE.URL) {
    const audioUrl = compData.audioUrl;

    if (audioUrl) {
      return audioUrl;
    }
  }

  const audioRef = compData.audioRef;

  if (audioRef) {
    return `${staticAudioUrl}/${audioRef.uri}`;
  }

  return '';
}

const playClassNames: Partial<Record<IconId, string>> = {
  icon8: 'twoColors',
  icon9: 'twoColors',
  icon10: 'twoColors',
  icon11: 'twoColors',
  icon15: 'twoColors',
  icon16: 'twoColors',
  icon17: 'twoColors',
  icon18: 'twoColors',
  icon19: 'twoColors',
  icon20: 'twoColors',
};

const pauseClassNames: Partial<Record<IconId, string>> = {
  icon2: 'oneColorPause',
  icon4: 'oneColorPause',
  icon5: 'oneColorPause',
  icon8: 'twoColors',
  icon9: 'twoColors',
  icon10: 'twoColors',
  icon11: 'twoColors',
  icon12: 'oneColorPause',
  icon15: 'twoColors',
  icon16: 'twoColors',
  icon17: 'twoColors',
  icon18: 'twoColors',
  icon19: 'twoColors',
  icon20: 'twoColors',
  icon23: 'oneColorPause',
};

const stateRefs = withStateRefsValues<keyof IMusicPlayerStateValues>([
  'reducedMotion',
]);

export default createComponentMapperModel({ props, css, sdkData, stateRefs });
