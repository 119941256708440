import { withCompController } from '@wix/editor-elements-integrations';
import { getValidationControllerProps } from '@wix/editor-elements-common-utils';
import type {
  IRichTextBoxMapperProps,
  IRichTextBoxControllerProps,
  IRichTextBoxProps,
  IRichTextStateRefs,
} from '../RichTextBox.types';

export default withCompController<
  IRichTextBoxMapperProps,
  IRichTextBoxControllerProps,
  IRichTextBoxProps,
  IRichTextStateRefs
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { compId, isMobileView, ...restProps } = mapperProps;
  const { setValidityIndication, onValueChange } = getValidationControllerProps(
    controllerUtils.updateProps,
  );

  const {
    setSiteScrollingBlocked,
    enableCyclicTabbing,
    disableCyclicTabbing,
    scopedClassName,
  } = stateValues;

  const closeMobileLinkModalTemp = () => {
    setSiteScrollingBlocked(false, compId);
    disableCyclicTabbing(restProps.linkModalId);
  };

  const openMobileLinkModalTemp = () => {
    enableCyclicTabbing(restProps.linkModalId);
    setSiteScrollingBlocked(true, compId);
  };

  return {
    ...restProps,
    scopedClassName,
    setValidityIndication,
    onValueChange,
    ...(isMobileView
      ? { openMobileLinkModalTemp, closeMobileLinkModalTemp }
      : {}),
  };
});
