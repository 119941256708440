import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import LinesMenuButtonNBorderRadiusFixSkin from '../../../../MenuButton/viewer/skinComps/BaseButton/LinesMenuButtonNBorderRadiusFixSkin';
import LinesMenuButton from './LinesMenuButton';
import styles from './LinesMenuButtonBorderRadiusFixSkin.scss';

const LinesMenuButtonBorderRadiusFixSkin: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuProps
> = (props, ref) => {
  return (
    <LinesMenuButton
      {...props}
      ref={ref}
      styles={styles}
      Button={LinesMenuButtonNBorderRadiusFixSkin}
    />
  );
};

export default React.forwardRef(LinesMenuButtonBorderRadiusFixSkin);
