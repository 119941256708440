import React from 'react';
import { IDatePickerProps } from '../DatePicker.types';
import {
  DatePickerTextBetweenNavSkin,
  DatePickerTextYearNavSkin,
  DatePickerDefaultSkin,
} from './skinComps';

export const DatePickerSkinMap: {
  [P in IDatePickerProps['skin']]: React.FC<IDatePickerProps>;
} = {
  DatePickerTextBetweenNavSkin,
  DatePickerTextYearNavSkin,
  DatePickerDefaultSkin,
};
