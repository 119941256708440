import type { RichContent } from '@wix/ricos';
import { setupContentBuilder } from '@wix/ricos';

const generateKey = () => Math.random().toString(36).substring(2, 9);

export const getEmptyEditorContent = ({
  title,
  label,
}: {
  title: string;
  label: string;
}): RichContent => {
  const api = setupContentBuilder(generateKey);

  return {
    nodes: [
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
      ...api.addHeading({
        text: title,
        content: { nodes: [] },
        // This any is needed to avoid importing TextStyle_TextAlignment enum from ricos-schema
        data: { level: 2, textStyle: { textAlignment: 'CENTER' as any } },
      }).nodes,
      ...api.addParagraph({
        text: label,
        content: { nodes: [] },
        data: { textStyle: { textAlignment: 'CENTER' as any } },
      }).nodes,
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
      ...api.addParagraph({
        content: { nodes: [] },
      }).nodes,
    ],
    metadata: {
      version: 1,
      createdTimestamp: new Date('2022-12-11T21:02:58.993Z'),
      updatedTimestamp: new Date('2022-12-11T21:02:58.993Z'),
      id: 'cd6505af-e738-4c10-b759-05659d7c8083',
    },
    documentStyle: {},
  };
};
