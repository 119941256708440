import * as React from 'react';
import classNames from 'clsx';
import {
  formatClassNames,
  getDataAttributes,
  isEmptyObject,
} from '@wix/editor-elements-common-utils';
import { SkinWPhotoProps } from '../SkinWPhoto';
import { WPhotoWrapper } from '../../WPhotoWrapper';
import Link from '../../../../Link/viewer/Link';
import WPhoto from '../../WPhotoBase';
import { selectProperComponent, getPropsForLink } from '../../../utils';
import semanticClassNames from '../../../WPhoto.semanticClassNames';
import skinsStyle from './styles/skins.scss';

const ScotchTapePhotoSkin: React.FC<SkinWPhotoProps> = props => {
  const {
    id,
    className,
    customClassNames = [],
    link,
    imageProps,
    title,
    onClick,
    hasPlatformClickHandler = false,
    onClickBehavior,
    onDblClick,
    onMouseEnter,
    onMouseLeave,
    filterEffectSvgString,
    filterEffectSvgUrl,
  } = props;
  const ImageComp = selectProperComponent(onClickBehavior);
  const isPopUp = onClickBehavior === 'zoomMode';
  const linkProps = getPropsForLink({
    onClickBehavior,
    className: skinsStyle.link,
    link,
  });

  return (
    <WPhotoWrapper
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        skinsStyle.ScotchTapePhoto,
        className,
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
      title={title}
      onClick={onClick}
      onDblClick={onDblClick}
      withOnClickHandler={
        !isEmptyObject(link) || hasPlatformClickHandler || isPopUp
      }
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      filterEffectSvgString={filterEffectSvgString}
      filterEffectSvgUrl={filterEffectSvgUrl}
    >
      <Link {...linkProps}>
        <div className={skinsStyle.wrp}>
          <ImageComp
            id={`img_${id}`}
            {...imageProps}
            className={skinsStyle.image}
            link={isPopUp ? link : undefined}
          />
        </div>
        <div className={skinsStyle.left} />
        <div className={skinsStyle.right} />
        <div className={skinsStyle.xxx} />
      </Link>
    </WPhotoWrapper>
  );
};

const ScotchTapePhoto: React.FC<SkinWPhotoProps> = props => (
  <WPhoto {...props} skin={ScotchTapePhotoSkin} />
);

export default ScotchTapePhoto;
