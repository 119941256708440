import * as React from 'react';
import type { ISlideshowIndicatorPaginationProps } from '../types';

const SlideshowIndicatorPagination: React.FC<
  ISlideshowIndicatorPaginationProps
> = props => {
  const { slidesToDisplay, currentSlide } = props;

  return (
    <div>
      {currentSlide}/{slidesToDisplay}
    </div>
  );
};

export default SlideshowIndicatorPagination;
