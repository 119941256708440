import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { IResponsiveBoxProps } from '../../Container/Container.types';
import {
  ARIA_LABEL_KEY,
  ARIA_LABEL_NAMESPACE,
} from '../../Container/viewer/shared/constants';

export const props = withCompInfo<
  IResponsiveBoxProps,
  any,
  IResponsiveBoxProps
>()(['translate'], ({ translate }, carmiProps) => {
  return {
    ...carmiProps,
    translations: {
      ariaLabel: translate(ARIA_LABEL_NAMESPACE, ARIA_LABEL_KEY),
    },
  };
});

export default createComponentMapperModel({ props });
