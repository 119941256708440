import { useEffect } from 'react';
import type { ISelectableContainerProps } from '../../types';

interface UseSelectVariantProps {
  id: ISelectableContainerProps['id'];
  applySelectedVariant: ISelectableContainerProps['applySelectedVariant'];
  removeSelectedVariant: ISelectableContainerProps['applySelectedVariant'];
  isEditor: ISelectableContainerProps['isEditor'];
  selected: ISelectableContainerProps['selected'];
}

export const useSelectVariant = ({
  id,
  applySelectedVariant,
  removeSelectedVariant,
  isEditor,
  selected,
}: UseSelectVariantProps) => {
  useEffect(() => {
    if (isEditor) {
      return;
    }

    if (selected) {
      applySelectedVariant(id);
    } else {
      removeSelectedVariant(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selected, id, isEditor]);
};
