export const TRANS_KEYS = {
  title: 'enterPasswordTitle',
  subtitle: 'enterPasswordSubTitle',
  submitButton: 'enterPasswordSubmitButton',
  passwordPlaceholder: 'enterPasswordPasswordPlaceholder',
  generalError: 'enterPasswordGeneralError',
  errMsgPrefix: 'membersServerError_',
  blankPassword: 'enterPasswordBlankPassword',
  closeDialog: 'enterPasswordCloseDialog',
};

export const TEST_IDS = {
  errorMessage: 'errorMessage',
  closeButton: 'closeButton',
  submitButton: 'submitButton',
  password: 'password',
};
