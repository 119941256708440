import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import ThreeDeeScreen from '../../../../ScreenWidthContainer/viewer/skinComps/ThreeDeeScreen/ThreeDeeScreen';
import FooterContainer from '../../FooterContainer';

const ThreeDeeScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => (
  <FooterContainer {...props} skin={ThreeDeeScreen}></FooterContainer>
);

export default ThreeDeeScreenFooter;
