import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  ImageXProps,
  ImageXDefinition,
} from '@wix/thunderbolt-components-native';
import type { IImageXProps } from '../ImageX.types';
import { props as viewerPropsMapper } from './ImageX.mapper';

const props = composeMapper<ImageXProps, ImageXDefinition, IImageXProps>()(
  viewerPropsMapper,
  {
    add: ['componentViewMode', 'experiments'],
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
