import * as React from 'react';
import type { SvgImageProps } from '../../../SvgImage.types';
import { getVectorId } from '../../constants';
import { SvgImageBase } from '../../SvgImageBase';

export const getMaskId = (id: string) => `svg-mask-${id}`;
export const getMaskBgId = (id: string) => `svg-mask-bg-${id}`;

function SvgImageTintSkin(props: SvgImageProps) {
  const { id } = props;
  const vectorId = getVectorId(id);

  return (
    <SvgImageBase {...props}>
      <defs>
        <mask id={getMaskBgId(id)} mask-type="alpha">
          <use href={`#${vectorId}`} />
        </mask>
        <mask id={getMaskId(id)} mask-type="luminance">
          <use href={`#${vectorId}`} filter={`url(#invert-${id})`} />
        </mask>
        {/* Optimization: this part will be moved to be global so it can be reused */}
        <filter id={`invert-${id}`} color-interpolation-filters="sRGB">
          <feComponentTransfer>
            <feFuncR type="table" tableValues="1 0" />
            <feFuncG type="table" tableValues="1 0" />
            <feFuncB type="table" tableValues="1 0" />
          </feComponentTransfer>
        </filter>
      </defs>
      <rect
        fill="white"
        width="100%"
        height="100%"
        mask={`url(#${getMaskBgId(id)})`}
      />
      <rect
        fill="rgb(var(--svg-color-1))"
        width="100%"
        height="100%"
        mask={`url(#${getMaskId(id)})`}
      />
    </SvgImageBase>
  );
}

export default SvgImageTintSkin;
