import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';

import {
  ISearchBoxMapperProps,
  SearchBoxCarmiProps,
  SearchBoxDefinition,
} from '../SearchBox.types';
import { props as viewerPropsMapper } from './SearchBox.mapper';

const props = composeMapper<
  ISearchBoxMapperProps,
  SearchBoxDefinition,
  SearchBoxCarmiProps
>()(
  viewerPropsMapper,
  { add: ['compPreviewState'] },
  (resolver, deps, carmiProps) => {
    const viewerProps = resolver(deps, carmiProps);
    const { compPreviewState } = deps;

    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
