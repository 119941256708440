import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  BackToTopButtonCarmiProps,
  BackToTopButtonDefinition,
  IBackToTopButtonMapperProps,
  IBackToTopButtonPreviewMapperProps,
} from '../BackToTopButton.types';
import { legacyComponentCompType } from '../constants';
import { props as viewerPropsMapper } from './BackToTopButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<
    IBackToTopButtonMapperProps,
    IBackToTopButtonPreviewMapperProps
  >,
  BackToTopButtonDefinition,
  BackToTopButtonCarmiProps
>()(
  viewerPropsMapper,
  { add: ['componentViewMode', 'fullNameCompType', 'isMobileView'] },
  (resolver, deps, carmiData) => {
    const { componentViewMode, fullNameCompType, isMobileView } = deps;
    const isLegacy = fullNameCompType === legacyComponentCompType;
    const isVisibleInEditor = !isLegacy;

    return {
      ...resolver(deps, carmiData),
      previewWrapperProps: {
        componentViewMode,
        isVisibleInEditor,
        isMobileView,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
