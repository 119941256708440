import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { css as viewerCss } from './StretchedContainer.mapper';

const css = composeMapper()(
  viewerCss,
  { add: [] },
  (resolver, dependencies, carmiData) => {
    const data = resolver(dependencies, carmiData);
    return {
      ...data,
      outline: '1px dashed #868AA5',
      background: '#FFFFFF',
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
});
