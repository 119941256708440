import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  PaginationDefinition,
  IPaginationMapperProps,
  PaginationPropsCarmiData,
  PaginationCSSVars,
  PaginationCssCarmiData,
  PaginationModeProps,
} from '../Pagination.types';
import {
  collapseAlignmentMap,
  PaginationAlignment,
  // PaginationMode,
} from './constants';

export const props = withCompInfo<
  IPaginationMapperProps,
  PaginationDefinition,
  PaginationPropsCarmiData
>()(
  ['compData', 'compProps', 'isMobileView', 'isConnectedToDataBinding'],
  (
    { compData, compProps, isMobileView, isConnectedToDataBinding },
    carmiProps,
  ) => {
    const {
      totalPages,
      currentPage,
      nextText,
      previousText,
      firstText,
      lastText,
    } = compData;
    const { navigationType } = carmiProps;
    const {
      showFirstLastNavButtons,
      showFirstPage,
      showLastPage,
      showInputModeTotalPages,
      paginationDirection,
      paginationMode,
      isDisabled,
      showPageNumbersBar,
    } = compProps;
    const paginationProps = {
      totalPages,
      currentPage,
      nextText,
      previousText,
      firstText,
      lastText,
      showFirstLastNavButtons,
      navigationType,
      paginationDirection,
      showPageNumbersBar,
      paginationMode,
      isDisabled,
    };

    const paginationModeProps: PaginationModeProps = {
      input: { showTotalPages: showInputModeTotalPages },
      pages: {
        showFirstLastPageButtons: showFirstPage || showLastPage,
        isResponsiveHeight: isMobileView,
        maxPagesToShow: 100,
        showPageNumbersBar: true,
      },
      arrows: {
        isResponsiveHeight: isMobileView,
        maxPagesToShow: 0,
        showTotalPages: false,
        ...(isConnectedToDataBinding
          ? {
              showFirstLastPageButtons: false,
              showPageNumbersBar: false,
            }
          : {}),
      },
    };

    // In case of data binding, we want to force PaginationMode to be 'arrows'
    // TODO - this is temporarily removed due to https://jira.wixpress.com/browse/ECL-4649
    // const actualPaginationMode = isConnectedToDataBinding
    //   ? PaginationMode.Arrows
    //   : paginationMode;
    const actualPaginationMode = paginationMode;
    return {
      ...paginationProps,
      ...paginationModeProps[actualPaginationMode],
      paginationModeProps,
      paginationMode: actualPaginationMode,
    };
  },
);

export const css = withCompInfo<
  PaginationCSSVars,
  PaginationDefinition,
  PaginationCssCarmiData
>()(['compProps'], ({ compProps }, cssVars) => {
  const { alignment } = compProps;

  return {
    ...cssVars,
    '--pagination-alignment':
      collapseAlignmentMap[alignment ?? PaginationAlignment.Center],
    'pagination-flex-grow': alignment ? 0 : 1,
  };
});

export default createComponentMapperModel({ props, css });
