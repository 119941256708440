import React, { ReactNode } from 'react';
import classnames from 'clsx';
import { RichUtils } from 'draft-js';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';

import { formatClassNames } from '@wix/editor-elements-common-utils';
import styles from '../button.scss';
import semanticClassNames from '../../../../RichTextBox.semanticClassNames';

export type InlineStyleButtonProps = ToolbarChildrenProps & {
  children: ReactNode;
  styleType: string;
};

export default (props: InlineStyleButtonProps & { dataTestId?: string }) => {
  const { styleType, children, setEditorState, getEditorState, dataTestId } =
    props;

  const toggleStyle = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setEditorState(RichUtils.toggleInlineStyle(getEditorState(), styleType));
  };

  const preventBubblingUp = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const selected =
    getEditorState().getCurrentInlineStyle().get(styleType) !== undefined;

  return (
    <div className={styles.buttonContainer}>
      <button
        className={styles.button}
        onMouseDown={preventBubblingUp}
        onClick={toggleStyle}
        type="button"
        data-testid={dataTestId}
      >
        <div
          className={classnames(
            styles.buttonContent,
            formatClassNames(semanticClassNames.icons),
            selected && styles.buttonContentSelected,
          )}
        >
          {children}
        </div>
      </button>
    </div>
  );
};
