import React from 'react';
import { createPortal } from 'react-dom';
import { TestIds } from '../../constants';
import styles from './SlideShowIcon.scss';

// Yes this is actually not translated in the original implementation.
// https://github.com/wix-private/santa/blob/master/packages/componentsPreviewExtensions/src/main/previewExtensions/slideShowGalleryPreviewExtension.js#L35
const SLIDE_SHOW_ICON_TEXT = 'Slide Deck Gallery' as const;

interface SlideShowIconProps {
  targetId: string;
}

const SlideShowIcon: React.FC<SlideShowIconProps> = ({ targetId }) => {
  const componentRootRef = React.useRef<HTMLElement | null>(null);
  const [rendered, setRendered] = React.useState(false);

  React.useEffect(() => {
    setRendered(true);
    componentRootRef.current = document.getElementById(targetId);
  }, [targetId]);

  return rendered && componentRootRef.current
    ? createPortal(
        <div
          data-testid={TestIds.slideShowIcon}
          className={styles.slideShowIconContainer}
        >
          <span className={styles.slideShowIconBackground}>
            {SLIDE_SHOW_ICON_TEXT}
          </span>
        </div>,
        componentRootRef.current,
      )
    : null;
};

export default SlideShowIcon;
