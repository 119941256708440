import {
  createComponentPreviewMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  StylableHorizontalMenuMapperProps,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData,
  StylableHorizontalMenuCSSVars,
  AnimationCSSVars,
} from '../StylableHorizontalMenu.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './StylableHorizontalMenu.mapper';

function getMenuMode({
  menuMode,
  metaSiteId,
}: {
  menuMode: string;
  metaSiteId: string;
}) {
  const isSHMInAddPanel = !metaSiteId;
  return isSHMInAddPanel ? 'scroll' : menuMode;
}
const demoData = [
  {
    label: 'Menu item 1',
    items: [],
    link: undefined,
  },
  {
    label: 'Menu item 2',
    items: [],
    link: undefined,
  },
  {
    label: 'Menu item 3',
    items: [],
    link: undefined,
  },
];

const props = composeMapper<
  PreviewWrapperProps<StylableHorizontalMenuMapperProps>,
  StylableHorizontalMenuDefinition,
  StylableHorizontalMenuPropsCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState', 'metaSiteId', 'isWixBlocks'],
    remove: ['language', 'compId', 'mainPageId'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const { compPreviewState, isWixBlocks } = dependencies;
    if (isWixBlocks) {
      // TODO: replace labels with translation keys
      viewerProps.items = demoData;
    }

    return {
      ...viewerProps,
      menuMode: getMenuMode({
        menuMode: viewerProps.menuMode,
        metaSiteId: dependencies.metaSiteId,
      }),
      previewWrapperProps: { compPreviewState },
    };
  },
);

export const css = composeMapper<
  StylableHorizontalMenuCSSVars,
  StylableHorizontalMenuDefinition
>()(
  viewerCssMapper,
  { add: ['compProps', 'metaSiteId', 'componentViewMode'] },
  (resolver, dependencies, carmiData): StylableHorizontalMenuCSSVars => {
    const viewerCss = resolver(dependencies, carmiData);
    const { metaSiteId, componentViewMode } = dependencies;

    const isMiniSite = !metaSiteId;
    if (isMiniSite) {
      // wrap mode looks bad in a mini site (add panel / copy design)
      return {
        ...viewerCss,
        '--menu-flex-wrap': 'nowrap',
        '--scroll-controls-display': 'none',
      };
    }
    const isEditorFlow = componentViewMode === 'editor';
    const animationCssVarInEditor = {
      '--is-animated': 0, // no animation for the editor
      '--position-box-z-index': 'var(--above-all-z-index)',
    } as AnimationCSSVars;

    return {
      ...viewerCss,
      ...(isEditorFlow && animationCssVarInEditor),
    };
  },
);

const uiType = withCompInfo()([], (() => 'Default') as any);

export default createComponentPreviewMapperModel({
  props,
  css,
  uiType,
} as any);
