import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { withComponentPreview } from '@wix/editor-elements-preview-utils';
import React from 'react';
import { IWTwitterTweetProps } from '../WTwitterTweet.types';

const withCustomUrl =
  (WrappedComponent: React.ComponentType<IWTwitterTweetProps>) =>
  (props: IWTwitterTweetProps) =>
    <WrappedComponent {...props} currentUrl="https://www.wix.com" />;

export default (ViewerComponent: React.ComponentType<IWTwitterTweetProps>) =>
  createComponentPreviewEntry(
    withComponentPreview(withCustomUrl(ViewerComponent)),
  );
