import * as React from 'react';
import { IAudioPlayerProps } from '../AudioPlayer.types';
import BasePlayer from './BasePlayer';
import styles from './styles/AudioPlayer.scss';

const AudioPlayer: React.FC<IAudioPlayerProps> = props => {
  return <BasePlayer {...props} style={styles} />;
};

export default AudioPlayer;
