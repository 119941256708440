import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FacebookShareDefinition,
  FacebookShareMapperProps,
  FacebookShareStateRefs,
} from '../FacebookShare.types';

export const props = withCompInfo<
  FacebookShareMapperProps,
  FacebookShareDefinition
>()(
  ['compData', 'externalBaseUrl'],
  ({
    compData: { label, urlFormat, urlChoice, isHttpsEnabled },
    externalBaseUrl,
  }) => {
    return {
      label,
      urlFormat,
      isMasterPage: urlChoice.toLowerCase() === 'site',
      externalBaseUrl,
      isHttpsEnabled,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof FacebookShareStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
]);

export default createComponentMapperModel({ props, stateRefs });
