import classNames from 'clsx';
import React from 'react';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import skinsStyle from './DoubleLine3.scss';

const DoubleLine3: React.FC<SkinFiveGridLineProps> = wrapperProps => {
  return (
    <FiveGridLineWrapper
      {...wrapperProps}
      className={classNames(wrapperProps.className, skinsStyle.root)}
    >
      <div className={skinsStyle.line}>
        <div className={skinsStyle.top} />
        <div className={skinsStyle.bottom} />
      </div>
      <div className={skinsStyle.tile} />
    </FiveGridLineWrapper>
  );
};

export default DoubleLine3;
