import scopedObjBuilder from '../../scopedObjBuilder';

export const viewerType = 'wixui.SelectableContainer';
export const baseId = 'wixui_SelectableContainer';
const designPanelBaseId = 'StylablePanel';

const selectableContainerScopedObj = scopedObjBuilder(baseId);
const designPanelScopedObj = scopedObjBuilder(designPanelBaseId);

const managePanelScopedObj = scopedObjBuilder(baseId);
const gfppScopedObj = scopedObjBuilder(baseId);

export const translationKeys = {
  definition: {
    label: 'component_label_SelectableContainer',
  },
  ...selectableContainerScopedObj('layout', [
    'controlTitle',
    'visible',
    'hidden',
  ]),
  design: {
    ...designPanelScopedObj('design', [
      'State_Dropdown_Regular',
      'State_Dropdown_Hover',
      'Fills_Tab_Title',
      'Borders_Tab_Title',
      'Corners_Tab_Title',
      'Shadows_Tab_Title',
    ]).design,
    ...selectableContainerScopedObj('design', ['selectedState']).design,
  },
  ...managePanelScopedObj('manage', [
    'panelTitle',
    'chooseState',
    'defaultState',
    'selectedState',
  ]),
  ...gfppScopedObj('gfpp', [
    'mainActions_manageLabel',
    'mainActions_layoutLabel',
  ]),
};

export const helpIds = {
  managePanel: 'wixui_SelectableContainer_help_manage',
  gfpp: 'wixui_SelectableContainer_help_gfpp',
  gfppMobile: 'wixui_SelectableContainer_help_mobile_gfpp',
};

export const cssVariables = {
  inputDisplay: '--input-display',
  corvidInputDisplay: '--corvid-input-display',
  containerFocusRing: '--container-focus-ring',
};

export const designPanelIds = {
  fill: `${baseId}_fill`,
  borders: `${baseId}_borders`,
  corners: `${baseId}_corners`,
  shadow: `${baseId}_shadow`,
};

export const designPanelSelectors = {
  root: '.root',
};

export const designPanelStateSelectors = {
  root: {
    regular: designPanelSelectors.root,
  },
};

export const defaultInputDisplay = 'inline-flex';
export const activeFocusRingStyle = '0 0 0 1px #ffffff, 0 0 0 3px #116dff';

export enum InputContent {
  TextAndButton = 'Text and Button',
  Text = 'Text',
  Button = 'Button',
}
