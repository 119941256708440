import React from 'react';
import EmptyStateIllustration from '../../../../../assets/ExternalComponent/EmptyStateIllustration.svg';
import type { IExternalComponentProps } from '../../../ExternalComponent.types';
import { classes } from './EditorEmptyState.st.css';
import { emptyStateTranslationKeys } from './constants';

const EmptyState: React.FC<{
  translations: IExternalComponentProps['translations'];
}> = ({ translations }) => {
  return (
    <div className={classes.root}>
      <EmptyStateIllustration className={classes.illustration} />
      <span className={classes.header}>
        {translations[emptyStateTranslationKeys.header]}
      </span>
      <span className={classes.body}>
        {translations[emptyStateTranslationKeys.body]}
      </span>
    </div>
  );
};

export default EmptyState;
