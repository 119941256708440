export const isElmInViewport = (rect: DOMRect) => {
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getCenteredVerticalPosition = (rect: DOMRect) => {
  const elementCenter = rect.top + rect.height / 2;
  const viewportCenter = window.innerHeight / 2;
  return window.scrollY + elementCenter - viewportCenter;
};
