import { withCompController } from '@wix/editor-elements-integrations';
import {
  ISignatureInputControllerProps,
  ISignatureInputMapperProps,
  ISignatureInputProps,
} from '../SignatureInput.types';

export default withCompController<
  ISignatureInputMapperProps,
  ISignatureInputControllerProps,
  ISignatureInputProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    onValueChange: value => {
      controllerUtils.updateProps({
        value,
      });
    },
  };
});
