import * as React from 'react';
import type { IMatrixGalleryProps } from '../../../MatrixGallery.types';
import MatrixGallery from '../../MatrixGallery';
import skinsItemStyle from '../../../../MatrixGalleryItem/viewer/style/TextBottomCustomHeightSkinItem.scss';
import MatrixGalleryItemWithPanel from '../../../../MatrixGalleryItem/viewer/MatrixGalleryItemWithPanel';
import skinsStyle from './style/TextBottomCustomHeightSkin.scss';

const TextBottomCustomHeightSkin: React.FC<IMatrixGalleryProps> = props => {
  return (
    <MatrixGallery
      {...props}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
      MatrixGalleryItemComponent={MatrixGalleryItemWithPanel}
    />
  );
};

export default TextBottomCustomHeightSkin;
