
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "UnsupportedComponent4060717634",
      {"classes":{"root":"UnsupportedComponent4060717634__root"},"vars":{"display":"--display"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  