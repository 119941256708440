import { withCompController } from '@wix/editor-elements-integrations';
import {
  IPinItPinWidgetProps,
  PinItPinWidgetStateRefs,
  PinItPinWidgetMapperProps,
  PinItPinWidgetControllerProps,
} from '../PinItPinWidget.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

const compController = withCompController<
  PinItPinWidgetMapperProps,
  PinItPinWidgetControllerProps,
  IPinItPinWidgetProps,
  PinItPinWidgetStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});

export default compController;
