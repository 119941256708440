import React from 'react';
import { IconButton, TextField } from 'wix-ui-tpa/cssVars';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import {
  PayPalButtonButtonType,
  PayPalButtonMapperProps,
  ViewerTranslateType,
} from '../../PayPalButton.types';
import {
  TestIds,
  TranslationKeys,
  TranslationsFallback,
} from '../../constants';
import { classes as dialogClasses } from '../style/Dialog.st.css';
import { classes, st } from '../style/PayDialog.st.css';
import {
  getCurrencyData,
  getCurrencyMinAmount,
  isBuy,
  isPayableAmount,
  isValidAmountInput,
} from '../utils';
import Dialog from './Dialog';

type PaymentDetailProps = {
  title: string;
  children?: React.ReactNode;
  testId?: string;
};

const PaymentDetail: React.FC<PaymentDetailProps> = ({
  title,
  children,
  testId,
}) => {
  return (
    <div
      data-testId={testId}
      className={st(classes.paymentDetail, classes.field)}
    >
      <div className={classes.fieldTitle}>{title}</div>
      {children}
    </div>
  );
};

type PayDialogProps = {
  business: string;
  originalAmount: string;
  originalItemName: string;
  currencyCode: string;
  isOpen: boolean;
  isMobileView: boolean;
  direction: PayPalButtonMapperProps['direction'];
  buttonType: PayPalButtonButtonType;
  translate: ViewerTranslateType;
  onPayClick: (amount: string, itemName: string) => void;
  onClose(): void;
};

const PayDialog: React.FC<PayDialogProps> = props => {
  const {
    isOpen,
    business,
    currencyCode,
    originalAmount,
    originalItemName,
    buttonType,
    isMobileView,
    direction,
    onClose,
    onPayClick,
    translate,
  } = props;
  const generalAmountInputError = isBuy(buttonType)
    ? translate(
        TranslationKeys.BuyModal.amountValidationError,
        TranslationsFallback.BuyModal.amountValidationError,
        {
          currency: getCurrencyData(currencyCode)?.symbol || '',
          minamount: getCurrencyMinAmount(currencyCode),
        },
      )
    : translate(
        TranslationKeys.DonateModal.validationError,
        TranslationsFallback.DonateModal.validationError,
        {
          currency: getCurrencyData(currencyCode)?.symbol || '',
          minamount: getCurrencyMinAmount(currencyCode),
        },
      );

  const [payerAmount, setPayerAmount] = React.useState('1.00');
  const [payerItemName, setPayerItemName] = React.useState('');
  const [amountInputError, setAmountInputError] = React.useState(
    generalAmountInputError,
  );
  const [descriptionInputHasChanged, setDescriptionInputHasChanged] =
    React.useState(false);

  const isErrorState = () => !isPayableAmount(payerAmount, currencyCode);

  const updateAmountInputError = (amount: string) => {
    if (getCurrencyData(currencyCode)?.supportsDecimal) {
      return setAmountInputError(generalAmountInputError);
    }
    const numericAmount = Number(amount);
    if (numericAmount <= 0) {
      return setAmountInputError(
        translate(
          TranslationKeys.BuyModal.amountValidationErrorMinAmount,
          TranslationsFallback.BuyModal.amountValidationErrorMinAmount,
        ),
      );
    }
    const isInteger = numericAmount % 1 === 0 && numericAmount > 0;
    if (!isInteger) {
      return setAmountInputError(
        translate(
          TranslationKeys.BuyModal.amountValidationErrorWholeNumbers,
          TranslationsFallback.BuyModal.amountValidationErrorWholeNumbers,
        ),
      );
    }
  };

  const handleAmountInputChange = (e: any) => {
    const amount = e.target?.value || '';
    const isValidNumericInput = isValidAmountInput(amount);
    const isInputEmpty = amount === '';
    if (isValidNumericInput || isInputEmpty) {
      setPayerAmount(amount);
    }
    updateAmountInputError(amount);
  };

  const handlePayButtonClick = async () => {
    const amount = originalAmount || payerAmount;
    const itemName = originalItemName || payerItemName;
    return onPayClick(amount, itemName);
  };

  return (
    <Dialog
      isMobileView={isMobileView}
      isOpen={isOpen}
      onClose={onClose}
      className={classes.root}
    >
      <div
        dir={direction}
        className={st(dialogClasses.box, classes.inputModalContent)}
        data-testid={TestIds.payModal}
      >
        <div className={dialogClasses.boxContent}>
          <header>
            <div
              className={dialogClasses.title}
              data-testid={TestIds.payModalTitle}
            >
              {isBuy(buttonType)
                ? translate(
                    TranslationKeys.BuyModal.title,
                    TranslationsFallback.BuyModal.title,
                  )
                : translate(
                    TranslationKeys.DonateModal.title,
                    TranslationsFallback.DonateModal.title,
                  )}
            </div>
            <div data-testid={TestIds.business}>
              {isBuy(buttonType)
                ? translate(
                    TranslationKeys.BuyModal.subTitle,
                    TranslationsFallback.BuyModal.subTitle,
                    {
                      paypalemail: business,
                    },
                  )
                : translate(
                    TranslationKeys.DonateModal.subTitle,
                    TranslationsFallback.DonateModal.subTitle,
                    {
                      paypalemail: business,
                      organizationname: originalItemName,
                    },
                  )}
            </div>
          </header>
          <section>
            {isBuy(buttonType) &&
              (originalItemName ? (
                <PaymentDetail
                  title={translate(
                    TranslationKeys.BuyModal.descriptionInputLabel,
                    TranslationsFallback.BuyModal.descriptionInputLabel,
                  )}
                  testId={TestIds.itemName}
                >
                  {originalItemName}
                </PaymentDetail>
              ) : (
                <TextField
                  inputClassName={classes.input}
                  data-testId={TestIds.itemNameInput}
                  className={st(
                    classes.textField,
                    classes.textFieldWithErrorMessage,
                    classes.field,
                  )}
                  label={translate(
                    TranslationKeys.BuyModal.descriptionInputLabel,
                    TranslationsFallback.BuyModal.descriptionInputLabel,
                  )}
                  value={payerItemName}
                  type="text"
                  onChange={e => {
                    if (!descriptionInputHasChanged) {
                      setDescriptionInputHasChanged(true);
                    }
                    setPayerItemName(e.target.value);
                  }}
                  error={!payerItemName && descriptionInputHasChanged}
                  placeholder={translate(
                    TranslationKeys.BuyModal.descriptionInputPlaceholder,
                    TranslationsFallback.BuyModal.descriptionInputPlaceholder,
                  )}
                  newErrorMessage
                  errorMessage={translate(
                    TranslationKeys.BuyModal.descriptionValidationError,
                    TranslationsFallback.BuyModal.descriptionValidationError,
                  )}
                />
              ))}

            {originalAmount ? (
              <PaymentDetail
                title={translate(
                  TranslationKeys.BuyModal.fixedAmountLabel,
                  TranslationsFallback.BuyModal.fixedAmountLabel,
                )}
                testId={TestIds.amount}
              >
                {getCurrencyData(currencyCode)?.symbol}
                {originalAmount}
              </PaymentDetail>
            ) : (
              <TextField
                inputClassName={classes.input}
                data-testId={TestIds.amountInput}
                className={st(
                  classes.textField,
                  classes.textFieldWithErrorMessage,
                  classes.field,
                )}
                label={
                  isBuy(buttonType)
                    ? translate(
                        TranslationKeys.BuyModal.amountInputLabel,
                        TranslationsFallback.BuyModal.amountInputLabel,
                      )
                    : translate(
                        TranslationKeys.DonateModal.amountInputLabel,
                        TranslationsFallback.DonateModal.amountInputLabel,
                      )
                }
                value={payerAmount}
                type="number"
                onChange={handleAmountInputChange}
                prefix={getCurrencyData(currencyCode)?.symbol}
                error={isErrorState()}
                newErrorMessage
                errorMessage={amountInputError}
              />
            )}

            <div className={classes.buttonsSection}>
              <button
                className={st(classes.button, classes.payButton)}
                onClick={handlePayButtonClick}
                disabled={
                  !isPayableAmount(payerAmount, currencyCode) ||
                  !(originalItemName || payerItemName)
                }
              >
                <span>
                  {isBuy(buttonType)
                    ? translate(
                        TranslationKeys.BuyModal.buyButton,
                        TranslationsFallback.BuyModal.buyButton,
                      )
                    : translate(
                        TranslationKeys.DonateModal.donateButton,
                        TranslationsFallback.DonateModal.donateButton,
                      )}
                </span>
              </button>
              <button
                className={st(classes.button, classes.cancelButton)}
                onClick={() => onClose()}
              >
                <span>
                  {isBuy(buttonType)
                    ? translate(
                        TranslationKeys.BuyModal.cancelButton,
                        TranslationsFallback.BuyModal.cancelButton,
                      )
                    : translate(
                        TranslationKeys.DonateModal.cancelButton,
                        TranslationsFallback.DonateModal.cancelButton,
                      )}
                </span>
              </button>
            </div>
          </section>
        </div>
        <IconButton
          className={dialogClasses.closeButton}
          icon={<Close />}
          onClick={() => onClose()}
        />
      </div>
    </Dialog>
  );
};

export default PayDialog;
