import scopedObjBuilder from '../../scopedObjBuilder';

export const viewerType = 'wixui.SelectableContainerInput';
export const baseId = 'wixui_SelectableContainerInput';
const designPanelBaseId = 'StylablePanel';

const selectableContainerScopedObj = scopedObjBuilder(baseId);
const designPanelScopedObj = scopedObjBuilder(designPanelBaseId);
const layoutPanelScopedObj = scopedObjBuilder(baseId);
const gfppScopedObj = scopedObjBuilder(baseId);

export const translationKeys = {
  definition: {
    label: 'wixui_compDisplayName_SelectableContainerInput',
  },
  design: {
    ...designPanelScopedObj('design', [
      'State_Dropdown_Regular',
      'State_Dropdown_Hover',
      'Fills_Tab_Title',
      'Borders_Tab_Title',
      'Corners_Tab_Title',
      'Shadows_Tab_Title',
      'Text_Tab_Title',
    ]).design,
    ...selectableContainerScopedObj('design', [
      'selectedState',
      'selectionFillsTitle',
      'sizeTitle',
    ]).design,
  },
  ...layoutPanelScopedObj('layout', [
    'inputContent_label',
    'inputContent_option_textAndButton',
    'inputContent_option_text',
    'inputContent_option_button',
    'buttonPosition_label',
    'buttonPosition_left_tooltipInfo',
    'buttonPosition_right_tooltipInfo',
    'buttonPosition_top_tooltipInfo',
    'buttonPosition_bottom_tooltipInfo',
    'contentAlignment_label',
    'contentAlignment_left_tooltipInfo',
    'contentAlignment_center_tooltipInfo',
    'contentAlignment_right_tooltipInfo',
  ]),
  ...gfppScopedObj('gfpp', ['mainActions_layoutLabel']),
};

export const helpIds = {
  gfpp: 'wixui_SelectableContainerInput_help_gfpp',
  gfppMobile: 'wixui_SelectableContainerInput_help_mobile_gfpp',
};

export const designPanelIds = {
  fill: `${baseId}_fill`,
  selectionFill: `${baseId}_selectionFill`,
  text: `${baseId}_text`,
  borders: `${baseId}_borders`,
  corners: `${baseId}_corners`,
  shadow: `${baseId}_shadow`,
  size: `${baseId}_size`,
};

export const designPanelSelectors = {
  root: '.root',
  label: `.root::label`,
  input: `.root::customInput`,
  inputSelectedIndicator: `.root:selected::customInput::after`,
};

export const designPanelStateSelectors = {
  root: {
    regular: designPanelSelectors.root,
  },
};

export const dataHooks = {
  selectableContainerInput: 'selectable-container-input',
  selectableContainerInputContainer: 'selectable-container-input-container',
};

export enum ButtonPosition {
  Left = 'left',
  Right = 'right',
  Top = 'top',
  Bottom = 'bottom',
}

export enum ContentAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export const labelPlaceholder = 'Option';
