import * as React from 'react';
import classnames from 'clsx';
import type { IUnsupportedSocialAuthMessageProps } from '../MemberLoginDialog.types';
import GoogleIcon from './assets/google.svg';
import FacebookIcon from './assets/facebook.svg';
import { MemberLoginDialogTranslationKeys as keys, testIds } from './utils';
import style from './style/style.scss';

export const UnsupportedSocialAuthMessage: React.FC<
  IUnsupportedSocialAuthMessageProps
> = ({
  isSocialLoginFacebookEnabled,
  isSocialLoginGoogleEnabled,
  translations,
  mode,
  reportCopyToClipboardBi,
  styleMode = 'dialog',
}) => {
  const [isToastVisible, setToastVisible] = React.useState(false);

  const copyUrlToClipboard = () => {
    const dummyElement = document.createElement('input');
    const currURL = window.location.href;
    document.body.appendChild(dummyElement);
    dummyElement.value = currURL;
    dummyElement.select();
    document.execCommand('copy');
    document.body.removeChild(dummyElement);
    void reportCopyToClipboardBi();
    setToastVisible(true);
    setTimeout(() => {
      setToastVisible(false);
    }, 3000);
  };

  const authMessageStyle = {
    dialog: style.dialogSocialAuthUnsupportedMessage,
    custom: style.customSocialAuthUnsupportedMessage,
  };

  const linkCopied = translations[keys.linkCopied];
  const copyLinkText = translations[keys.copyLinkText];

  let inAppMessageTranslation;
  if (isSocialLoginGoogleEnabled || isSocialLoginFacebookEnabled) {
    // @ts-expect-error
    const authMethodsTranslationKey:
      | 'Google'
      | 'Facebook'
      | 'GoogleFacebook' = `${isSocialLoginGoogleEnabled ? 'Google' : ''}${
      isSocialLoginFacebookEnabled ? 'Facebook' : ''
    }`;
    inAppMessageTranslation =
      translations[
        keys.inAppBrowserSocialAuth(mode, authMethodsTranslationKey)
      ];
  }

  return (
    <>
      <div
        data-testid={testIds.socialAuthMessage}
        className={classnames(
          style.inAppBrowserSocialAuth,
          authMessageStyle[styleMode],
        )}
      >
        <div className={classnames(style.socialAuthIcons)}>
          {isSocialLoginGoogleEnabled && (
            <GoogleIcon className={classnames(style.socialIcon)} />
          )}
          {isSocialLoginFacebookEnabled && (
            <FacebookIcon className={classnames(style.socialIcon)} />
          )}
        </div>
        <div className={classnames(style.inAppSocialAuthText)}>
          {inAppMessageTranslation}
          &nbsp;
          <button
            type="button"
            className={classnames(style.copyLink)}
            onClick={copyUrlToClipboard}
            data-testid={testIds.copyLinkButton}
          >
            {copyLinkText}
          </button>
        </div>
      </div>
      <div
        className={classnames(style.hiddenLinkCopiedToast, {
          [style.visiblelinkCopiedToast]: isToastVisible,
        })}
      >
        {linkCopied}
      </div>
    </>
  );
};
