import { withCompController } from '@wix/editor-elements-integrations';
import { getComponentProps as getTPAGalleryProps } from '../../TPAGallery/viewer/TPAGallery.controller';
import {
  IStripSlideshowControllerProps,
  IStripSlideshowMapperProps,
  IStripSlideShowStateValues,
} from '../StripSlideshow.types';

export default withCompController<
  IStripSlideshowMapperProps,
  IStripSlideshowControllerProps,
  Record<string, any>,
  IStripSlideShowStateValues
>(platformData => {
  const { stateValues, mapperProps } = platformData;
  const { reducedMotion } = stateValues;

  return {
    ...getTPAGalleryProps(platformData),
    ...mapperProps,
    reducedMotion,
  };
});
