import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import {
  getHeightInPixels,
  getWidthInPixels,
} from '@wix/editor-elements-common-utils';
import type {
  CustomElementComponentDefinition,
  ICustomElementComponentMapperProps,
  CustomElementComponentCSSVars,
} from '../types';

export const props = withCompInfo<
  ICustomElementComponentMapperProps,
  CustomElementComponentDefinition
>()(
  ['compData'],
  ({ compData: { initialAttributes, shouldReload, appDefinitionId } }) => {
    return { initialAttributes, shouldReload, appDefinitionId };
  },
);

export const css = withCompInfo<
  Partial<CustomElementComponentCSSVars>,
  CustomElementComponentDefinition
>()(
  ['compLayout', 'hasResponsiveLayout', 'isOneDocMigrated', 'compSingleLayout'],
  ({ compLayout, hasResponsiveLayout, isOneDocMigrated, compSingleLayout }) => {
    const getCustomElementWidth = () => {
      if (hasResponsiveLayout) {
        return '100%';
      }

      return isOneDocMigrated
        ? `${getWidthInPixels(compSingleLayout)}px`
        : `${compLayout.width}px`;
    };

    const getCustomElementHeight = () => {
      if (hasResponsiveLayout) {
        return '100%';
      }

      return isOneDocMigrated
        ? `${getHeightInPixels(compSingleLayout)}px`
        : `${compLayout.height}px`;
    };

    return {
      ...(!hasResponsiveLayout && {
        width: 'auto',
        height: 'auto',
      }),
      '--custom-element-width': getCustomElementWidth(),
      '--custom-element-height': getCustomElementHeight(),
      display: 'var(--display)',
      '--display': 'flex',
    };
  },
);

export default createComponentMapperModel({ props, css });
