
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RatingIconscomponent1528570634",
      {"classes":{"root":"RatingIconscomponent1528570634__root","icons":"RatingIconscomponent1528570634__icons","iconWrapper":"RatingIconscomponent1528570634__iconWrapper","icon":"RatingIconscomponent1528570634__icon","halfIcon":"RatingIconscomponent1528570634__halfIcon"},"vars":{},"stVars":{"filledShapeColor":"var(--filledShapeColorStorybook, rgba(var(--filledShapeColor, var(--color_5)), var(--alpha-filledShapeColor, 1)))","emptyShapeColor":"var(--emptyShapeColorStorybook, rgba(var(--emptyShapeColor, var(--color_15)), var(--alpha-emptyShapeColor, 1)))","shapeBorderColor":"var(--shapeBorderColorStorybook, rgba(var(--shapeBorderColor, var(--color_32)), var(--alpha-shapeBorderColor, 1)))","shapeBorderWidth":"var(--shapeBorderWidth, 3)","iconsOrder":"var(--iconsOrder, 2)","iconsMinWidth":"var(--iconsMinWidth, auto)","iconPaddingLeft":"var(--iconPaddingLeft, 0)","iconPaddingRight":"var(--iconPaddingRight, 0)","iconSize":"var(--iconSize, auto)","halfIconWidth":"var(--halfIconWidth, auto)","halfIconLeft":"var(--halfIconLeft, 0)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  