import type { StateMapperArgs } from '@wix/editor-elements-types/editor';
import type { DS } from '@wix/editorx-core-api';

/** To add new mode, add it to the enum
 *  and you will get automatically the mode API
 *  from  stageModesAPI
 */

export enum StageModes {
  Selected = 'Selected',
}

type StageModesAPI = Record<
  Lowercase<StageModes>,
  ReturnType<typeof modeGetterFactory>
>;

const getComponentsStageModes = (editorAPI: StateMapperArgs['editorAPI']) =>
  editorAPI.componentsStageModes;

const getStageMode = (
  editorAPI: StateMapperArgs['editorAPI'],
): Record<StageModes, StageModes> =>
  getComponentsStageModes(editorAPI).StageMode;

const getEnterMode =
  (editorAPI: StateMapperArgs['editorAPI'], mode: StageModes) =>
  (compRef: DS.CompRef): void =>
    getComponentsStageModes(editorAPI).enter(
      compRef,
      getStageMode(editorAPI)[mode],
    );

const getExitMode =
  (editorAPI: StateMapperArgs['editorAPI'], mode: StageModes) =>
  (compRef: DS.CompRef): void =>
    getComponentsStageModes(editorAPI).exit(
      compRef,
      getStageMode(editorAPI)[mode],
    );

const getIsInMode =
  (editorAPI: StateMapperArgs['editorAPI'], mode: StageModes) =>
  (compRef: DS.CompRef): boolean =>
    getComponentsStageModes(editorAPI).isIn(
      compRef,
      getStageMode(editorAPI)[mode],
    );

const getSelectedCompRef = (editorAPI: StateMapperArgs['editorAPI']) => {
  const [selectedCompRef] = editorAPI.selection.getSelectedComponents() as [
    DS.CompRef,
  ];
  return selectedCompRef;
};

const modeGetterFactory =
  (mode: StageModes) => (editorAPI: StateMapperArgs['editorAPI']) => ({
    /** enters component into stage mode
     * @param compRef - component reference to enter stage mode, if not provided, selected component will be used
     * @returns void
     */
    enter: (compRef?: DS.CompRef) =>
      getEnterMode(editorAPI, mode)(compRef || getSelectedCompRef(editorAPI)),

    /** exits component from stage mode
     * @param compRef - component reference to exit stage mode, if not provided, selected component will be used
     * @returns void
     */
    exit: (compRef?: DS.CompRef) =>
      getExitMode(editorAPI, mode)(compRef || getSelectedCompRef(editorAPI)),

    /** checks if component is in stage mode
     * @param compRef - component reference to check if in stage mode, if not provided, selected component will be used
     * @returns boolean
     */
    isIn: (compRef?: DS.CompRef) =>
      getIsInMode(editorAPI, mode)(compRef || getSelectedCompRef(editorAPI)),
  });

/** Stage modes API
 * @example
 * const selectModeAPI = stageModesAPI.selected(editorAPI);
 * // enter stage mode
 * selectModeAPI.enter();
 * // exit stage mode
 * selectModeAPI.exit();
 * // check if component is in stage mode
 * selectModeAPI.isIn();
 * // enter stage mode for specific component
 * selectModeAPI.enter(compRef);
 */
export const stageModesAPI = Object.values(StageModes).reduce(
  (acc, mode) => ({ ...acc, [mode.toLowerCase()]: modeGetterFactory(mode) }),
  {} as StageModesAPI,
);
