import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IAccordionContainerControllerProps,
  IAccordionContainerMapperProps,
  IAccordionContainerProps,
} from '../types';

export default withCompController<
  IAccordionContainerMapperProps,
  IAccordionContainerControllerProps,
  IAccordionContainerProps
>(({ mapperProps, controllerUtils }) => {
  const { updateProps } = controllerUtils;

  return {
    ...mapperProps,
    onOpenedChange: opened => {
      updateProps({ opened });
    },
  };
});
