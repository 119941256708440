import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { FacebookShareProps } from '../FacebookShare.types';
import style from './style/FacebookShare.scss';

const facebookSharerUrl = 'http://www.facebook.com/sharer.php?u=';
const facebookSharerName = 'wix_share_to_facebook';
const facebookSharerParams = 'width = 635, height = 346';

const FacebookShare: React.FC<FacebookShareProps> = props => {
  const { id, label = 'Share', socialUrl, className } = props;
  const openSharePop = () => {
    window.open(
      facebookSharerUrl + encodeURIComponent(socialUrl),
      facebookSharerName,
      facebookSharerParams,
    );
  };
  return (
    <div id={id} {...getDataAttributes(props)} className={className}>
      <button
        title={label}
        aria-label={label}
        className={style.facebookShareButton}
        tabIndex={0}
        onClick={openSharePop}
      >
        <div className={style.shareButton} tabIndex={-1}>
          <span className={style.icon} />
          <span className={style.label}>{label}</span>
        </div>
      </button>
    </div>
  );
};

export default FacebookShare;
