import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISelectableContainerControllerProps,
  ISelectableContainerMapperProps,
  ISelectableContainerStateRefValues,
} from '../types';
import type { ISelectableContainerProps } from '../SelectableContainer.props';

export default withCompController<
  ISelectableContainerMapperProps,
  ISelectableContainerControllerProps,
  ISelectableContainerProps,
  ISelectableContainerStateRefValues
>(({ mapperProps, stateValues }) => {
  const { selectedVariantName } = mapperProps;
  return {
    ...mapperProps,
    applySelectedVariant: (id: string) => {
      if (selectedVariantName && id) {
        stateValues.applyEffects(id, [selectedVariantName]);
      }
    },
    removeSelectedVariant: (id: string) => {
      if (selectedVariantName && id) {
        stateValues.removeEffects(id, [selectedVariantName]);
      }
    },
  };
});
