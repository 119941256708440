import {
  withStateRefsValues,
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { VideoBoxDefinition } from '@wix/thunderbolt-components';
import { VideoBoxProps } from '../VideoBox.types';
import * as TranslationKeys from './constants';

export const props = withCompInfo<
  VideoBoxProps,
  VideoBoxDefinition,
  VideoBoxProps
>()(['translate'], ({ translate }, carmiData) => {
  return {
    ...carmiData,
    translations: {
      ariaLabel:
        translate(
          TranslationKeys.ARIA_LABEL_NAMESPACE,
          TranslationKeys.VIDEOBOX_PLAY_ARIA_LABEL_KEY,
        ) || TranslationKeys.VIDEOBOX_PLAY_ARIA_LABEL_DEFAULT,
    },
  };
});

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
