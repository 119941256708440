import * as React from 'react';
import type { SvgImageProps } from '../SvgImage.types';
import { getVectorId } from './constants';
import { SvgImageBase } from './SvgImageBase';

function SvgImageDefaultSkin(props: SvgImageProps) {
  return (
    <SvgImageBase {...props}>
      <use href={`#${getVectorId(props.id)}`} />
    </SvgImageBase>
  );
}

export default SvgImageDefaultSkin;
