import React from 'react';
import { CustomDialog } from 'wix-ui-tpa/cssVars';
import { classes, st } from '../style/Dialog.st.css';

type DialogProps = {
  className: string;
  isMobileView: boolean;
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode;
};

const Dialog: React.FC<DialogProps> = ({
  isMobileView,
  className,
  isOpen,
  onClose,
  children,
}) => {
  return (
    <CustomDialog
      className={st(classes.root, { mobile: isMobileView }, className)}
      isOpen={isOpen}
      onClose={onClose}
      appendToBody={false}
    >
      {children}
    </CustomDialog>
  );
};

export default Dialog;
