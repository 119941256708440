import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getSocialCompBlockingLayerProps,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  ItunesButtonDefinition,
  ItunesButtonPlatformProps,
} from '../ItunesButton.types';
import { props as viewerPropsMapper } from './ItunesButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<ItunesButtonPlatformProps>,
  ItunesButtonDefinition,
  ItunesButtonPlatformProps
>()(
  viewerPropsMapper,
  {
    add: ['isSocialInteractionAllowed'],
    remove: ['compId', 'fullNameCompType', 'language', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
