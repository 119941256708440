import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';

import type { CompInfoDependency } from '@wix/editor-elements-types/thunderbolt';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  RichTextBoxCssCarmiData,
  RichTextBoxCSSVars,
  RichTextBoxDefinition,
  IRichTextBoxMapperProps,
} from '../RichTextBox.types';

import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './RichTextBox.mapper';

const props = composeMapper<
  PreviewWrapperProps<IRichTextBoxMapperProps>,
  RichTextBoxDefinition
>()(
  viewerPropsMapper,
  { add: ['compPreviewState', 'shouldResetComponent', 'componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      compPreviewState,
      shouldResetComponent,
      isMobileView,
      componentViewMode,
    } = deps;
    return {
      ...viewerProps,
      previewWrapperProps: {
        compPreviewState,
        shouldResetComponent,
        isMobileView,
        componentViewMode,
      },
    };
  },
);

/*
 * Preview mapper is merged with regular mapper
 * so if your preview `props`/`css`/etc repeats
 * logic of regular one, there is no need to write it
 */

const viewerCssDeps = Object.keys(
  viewerCssMapper.deps,
) as Array<CompInfoDependency>;

export const css = withCompPreviewInfo<
  RichTextBoxCSSVars,
  RichTextBoxDefinition,
  RichTextBoxCssCarmiData
>()(viewerCssDeps, (deps, carmiData) => {
  const viewerCss = viewerCssMapper.resolver(deps, carmiData);
  return viewerCss;
});

export default createComponentPreviewMapperModel({ props, css });
