import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';

export const props = withCompInfo<any, any>()(
  ['compData', 'compStylableClass'],
  ({ compData, compStylableClass }) => {
    return {
      ...{
        items: [{ label: 'Action 1', value: 'action1' }],
        icon: {},
        popoverLabel: 'Open',
      },
      ...compData,
      stylableClassName: compStylableClass,
    };
  },
);

export default createComponentMapperModel({
  props,
});
