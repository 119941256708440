import React, { ReactElement } from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import type { IComponentCustomMeasureImperativeAPI } from '@wix/thunderbolt-becky-types';
import { useCustomMeasuresDependency } from '@wix/editor-elements-preview-utils';
import { IAppWidgetProps } from '../AppWidget.types';

function withComponentPreview(
  ViewerComponent: React.ComponentType<IAppWidgetProps>,
): IComponentPreviewWrapper<IAppWidgetProps> {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI,
    PreviewWrapperProps<IAppWidgetProps>
  >((props, ref) => {
    const { id, children } = props;

    const childrenRef = useCustomMeasuresDependency(children);

    const childrenArray = React.Children.toArray(childrenRef.current());
    const child = childrenArray[0] as ReactElement;
    const childId = child?.props.id;

    React.useImperativeHandle(
      ref,
      () => ({
        getCustomMeasures: () => ({
          relativeToScreenOverrides: {
            [id]: () => {
              const container = document.getElementById(id);
              if (container) {
                const { x } = container.getBoundingClientRect();
                return { x };
              }
              return;
            },
          },
          height: {
            [id]: siteMeasureMap => childId && siteMeasureMap.height[childId],
          },
          width: {
            [id]: siteMeasureMap => childId && siteMeasureMap.width[childId],
          },
        }),
      }),
      [id, childId],
    );

    return <ViewerComponent {...props} />;
  });
}

export default (ViewerComponent: React.ComponentType<IAppWidgetProps>) =>
  createComponentPreviewEntry(withComponentPreview(ViewerComponent));
