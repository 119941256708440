import {
  CURRENCY_DATA,
  TranslationKeys,
  TranslationsFallback,
} from '../constants';
import {
  PayPalButtonButtonType,
  ViewerTranslateType,
} from '../PayPalButton.types';

export const isPayableAmount = (amount: string, currencyCode: string) => {
  const supportsDecimal = getCurrencyData(currencyCode)?.supportsDecimal;
  const amountAsNumber = Number(amount);
  if (supportsDecimal) {
    return amountAsNumber >= 0.01;
  }
  const isInteger = amountAsNumber % 1 === 0 && amountAsNumber > 0;
  return isInteger;
};

export const getCurrencyData = (currencyCode: string) =>
  CURRENCY_DATA.find(currencyData => currencyData.value === currencyCode);

export const isBuy = (buttonType: PayPalButtonButtonType) =>
  buttonType === 'buy';

export const isNumbersOnly = (amount: string) =>
  new RegExp(/^[\d.]+$/).test(amount);

export const getCurrencyMinAmount = (currencyCode: string) =>
  getCurrencyData(currencyCode)?.supportsDecimal ? '0.01' : '1.00';

export const getItemName = (
  originalItemName: string,
  buttonType: PayPalButtonButtonType,
  translate: ViewerTranslateType,
) => {
  if (!isBuy(buttonType) && !originalItemName) {
    return translate(
      TranslationKeys.DonateModal.organizationLabel,
      TranslationsFallback.DonateModal.organizationLabel,
    );
  }
  return originalItemName;
};

export const isValidAmountInput = (amount: string) =>
  isNumbersOnly(amount) && Number(amount) >= 0;
