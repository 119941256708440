import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  IBackToTopButtonMapperProps,
  BackToTopButtonDefinition,
  BackToTopButtonCarmiProps,
} from '../BackToTopButton.types';
import {
  SCROLL_BREAKPOINT,
  SCROLL_BREAKPOINT_LEGACY,
  legacyComponentCompType,
} from '../constants';

export const props = withCompInfo<
  IBackToTopButtonMapperProps,
  BackToTopButtonDefinition,
  BackToTopButtonCarmiProps
>()(['fullNameCompType'], ({ fullNameCompType }, carmiData) => {
  const { svgContent } = carmiData;
  const isLegacyBackToTop = fullNameCompType === legacyComponentCompType;
  const scrollBreakpoint = isLegacyBackToTop
    ? SCROLL_BREAKPOINT_LEGACY
    : SCROLL_BREAKPOINT;
  return { svgContent, scrollBreakpoint };
});

export default createComponentMapperModel({
  props,
});
