import React from 'react';
import { DownloadLogo, WixLogo } from '../../common/assets/logos';
import { wixLogoPlaceHolder } from './constants';
import style from './style/FreemiumBannerMobile.scss';

type GenericContentProps = { bannerText: string };

const GenericContent: React.FC<GenericContentProps> = ({ bannerText }) => {
  const [textBeforeLogo, textAfterLogo] = bannerText.split(wixLogoPlaceHolder);
  return (
    <>
      {textBeforeLogo}
      <WixLogo rootClass={style.wixLogo} dotClass={style.dot} />
      {textAfterLogo}
      <DownloadLogo className={style.downloadLogo} />
    </>
  );
};
export default GenericContent;
