import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  getInputHeight,
  getLabelPaddingsValues,
  getRequiredIndicationDisplay,
  migrateFields,
  addUnitToEveryField,
  convertPhysicalInputAlignmentToLogical,
  convertPhysicalInputAlignmentToDirection,
  LogicalAlignment,
  Direction,
  WithInherit,
  getHeightInPixels,
} from '@wix/editor-elements-common-utils';
import { extractUnit } from '@wix/editor-elements-common-utils/src/commons/scaleProportionally';
import {
  ITextAreaInputMapperProps,
  TextAreaInputDefinition,
  TextAreaInputCSSVars,
  TextAreaInputCssCarmiData,
  TextAreaInputStyleProperties,
} from '../TextAreaInput.types';
import { translationKeys } from './constants';

const formatValueWithUnit = (valueWithUnit: string | number) => {
  const value: string =
    typeof valueWithUnit === 'number'
      ? valueWithUnit.toString()
      : valueWithUnit;
  const numericValue = parseFloat(value);
  const unit = extractUnit(value);
  return `${numericValue}${unit}`;
};

export const props = withCompInfo<
  ITextAreaInputMapperProps,
  TextAreaInputDefinition
>()(
  ['compData', 'compProps', 'skin', 'translate', 'experiments'],
  ({
    compData,
    compProps,
    skin,
    translate,
    experiments: {
      'specs.thunderbolt.keepTextInputHeight': keepTextInputHeightExperiment,
    },
  }) => ({
    skin:
      skin === 'ResponsiveTextAreaDefaultSkin'
        ? 'ResponsiveTextAreaDefaultSkin'
        : 'TextAreaDefaultSkin',
    value: compData.value || '',
    label: compData.label,
    placeholder: compData.placeholder || compProps.placeholder,
    readOnly: compProps.readOnly || false,
    required: compProps.required || false,
    isDisabled: compProps.isDisabled || false,
    maxLength: compData.maxLength,
    errorMessageType: compProps.errorMessageType,
    translations: {
      errorMessage: translate(
        translationKeys.NAMESPACE,
        translationKeys.INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE,
      ),
    },
    keepInputHeightEnabled: String(keepTextInputHeightExperiment) === 'true',
  }),
);

export const css = withCompInfo<
  TextAreaInputCSSVars,
  TextAreaInputDefinition,
  TextAreaInputCssCarmiData
>()(
  [
    'compLayout',
    'compSingleLayout',
    'compProps',
    'compData',
    'experiments',
    'styleProperties',
    'isMobileView',
    'hasResponsiveLayout',
    'isOneDocMigrated',
  ],
  (
    {
      compLayout,
      compSingleLayout,
      compProps,
      compData,
      styleProperties,
      isMobileView,
      hasResponsiveLayout,
      isOneDocMigrated,
    },
    cssVars,
  ) => {
    const {
      direction = 'inherit',
      labelDirection = 'inherit',
      inputDirection = 'inherit',
      errorDirection = 'inherit',
    } = compData;
    const {
      align,
      labelMargin,
      labelPadding,
      textPadding = 3,
    } = styleProperties as TextAreaInputStyleProperties;

    const sharedProps = {
      ...cssVars,
      '--direction': direction as WithInherit<Direction>,
      '--labelDirection': labelDirection,
      '--inputDirection': inputDirection,
      '--errorDirection': errorDirection,
      '--align': align as LogicalAlignment,
      '--textPaddingTop': hasResponsiveLayout ? '0.75em' : '3px',
      '--textPaddingStart': `${formatValueWithUnit(textPadding)}`,
      '--textPaddingEnd':
        align === 'center' ? `${formatValueWithUnit(textPadding)}` : '10px',
      '--labelMarginBottom': `${formatValueWithUnit(labelMargin)}`,
      '--requiredIndicationDisplay':
        getRequiredIndicationDisplay(styleProperties),
      ...addUnitToEveryField(
        getLabelPaddingsValues({
          labelPadding,
          align: align as LogicalAlignment,
        }),
      ),
    };

    if (hasResponsiveLayout) {
      return sharedProps;
    } else {
      const height = isOneDocMigrated
        ? getHeightInPixels(compSingleLayout)
        : compLayout.height;

      return {
        ...sharedProps,
        ...(!isOneDocMigrated && {
          height: 'auto',
        }),
        '--inputHeight': getInputHeight({
          inputHeightProps: compProps,
          height,
          isMobileView,
        }),
      };
    }
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: ['labelMargin', 'labelPadding', 'textPadding', 'textAlignment'],
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: convertPhysicalInputAlignmentToLogical,
      },
      {
        sourceNamespace: 'styleProperties',
        targetNamespace: 'compData',
        fields: [{ source: 'textAlignment', target: 'direction' }],
        enhancer: convertPhysicalInputAlignmentToDirection,
      },
    ]),
  ],
);

export default createComponentMapperModel({ props, css });
