import { ExternalComponentErrorType } from '../../../ExternalComponent.types';

export const DEFAULT_ERROR =
  'Make sure you’ve added all required props and check your code for errors in the browser console.';
export const ERROR_MESSAGE_DELAY = 10 * 1000;

export const ALERT_STROKE_COLOR = '#EE4437';
export const DEFAULT_STROKE_COLOR = '#000';
export const enum skinsOptions {
  warning = 'warning',
  alert = 'alert',
  default = '',
}

export const enum iconOptions {
  error = 'error',
  admin = 'admin',
  loader = 'loader',
}

export interface errorTypeToRenderParamsType {
  key: string | Array<string>;
  skin: skinsOptions;
  icon: iconOptions;
}
export const errorTypeToRenderParams: Record<
  ExternalComponentErrorType,
  errorTypeToRenderParamsType
> = {
  [ExternalComponentErrorType.Runtime]: {
    icon: iconOptions.error,
    skin: skinsOptions.warning,
    key: 'ExternalComponent_render_error_on_stage_message',
  },
  [ExternalComponentErrorType.WrongExport]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_wrong_export_error_on_stage_message',
  },
  [ExternalComponentErrorType.MissingExport]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_wrong_export_error_on_stage_message',
  },
  [ExternalComponentErrorType.Timeout]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_fetch_error_on_stage_message',
  },
  [ExternalComponentErrorType.Fetch]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_fetch_error_on_stage_message',
  },
  [ExternalComponentErrorType.Bundling]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_bundling_failed_code_error_on_stage_message',
  },
  [ExternalComponentErrorType.FileNotFound]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_file_not_found_error_on_stage_message',
  },
  [ExternalComponentErrorType.UnsupportedBrowser]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_render_error_on_stage_error_message_incognito',
  },
  [ExternalComponentErrorType.UserOfUserDisplay]: {
    icon: iconOptions.error,
    skin: skinsOptions.alert,
    key: 'ExternalComponent_UoU_message_component_not_loading',
  },
  [ExternalComponentErrorType.MissingConsent]: {
    icon: iconOptions.admin,
    skin: skinsOptions.default,
    key: 'ExternalComponent_authorize_code_on_stage_message',
  },
  [ExternalComponentErrorType.Reloading]: {
    icon: iconOptions.loader,
    skin: skinsOptions.default,
    key: [
      'ExternalComponent_update_loader_message_one',
      'ExternalComponent_update_loader_message_two',
      'ExternalComponent_update_loader_message_three',
      'ExternalComponent_update_loader_message_four',
    ],
  },
};
