import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { ExpandableMenuCSSVars } from '@wix/thunderbolt-components-native';
import type {
  ExpandableMenuProps,
  ExpandableMenuStateRefs,
  ExpandableMenuDefinition,
  ExpandableMenuProperties,
  GridSettings,
  ExpandableMenuSdkData,
} from '../ExpandableMenu.types';
import * as translationKeys from '../translations/constants';

export const props = withCompInfo<
  ExpandableMenuProps,
  ExpandableMenuDefinition,
  ExpandableMenuProps
>()(
  [
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'translate',
    'trackClicksAnalytics',
  ],
  ({ translate, ...compPropsRest }, carmiData) => ({
    ...compPropsRest,
    ...carmiData,
    translations: {
      ariaLabel:
        translate(
          translationKeys.ARIA_LABEL_NAMESPACE,
          translationKeys.ARIA_LABEL_KEY,
        ) || translationKeys.ARIA_LABEL_DEFAULT,
    },
  }),
);

export const stateRefs = withStateRefsValues<keyof ExpandableMenuStateRefs>([
  'reportBi',
  'currentUrl',
]);

const getGridSettings = (
  itemsAlignment: ExpandableMenuProperties['itemsAlignment'],
): GridSettings =>
  itemsAlignment === 'center'
    ? {
        templateColumns: `calc(40px + 1em) 1fr calc(40px + 1em)`,
        labelColumns: '2',
        arrowColumns: '3',
      }
    : {
        templateColumns: `1fr calc(40px + 1em)`,
        labelColumns: '1',
        arrowColumns: '2',
      };

export const css = withCompInfo<
  ExpandableMenuCSSVars,
  ExpandableMenuDefinition
>()(
  ['compProps', 'styleProperties', 'experiments'],
  ({ compProps, styleProperties }) => {
    const menuSpacing = styleProperties.menuSpacing;
    const subMenuSpacing = styleProperties.subMenuSpacing;
    const menuItemHeight = compProps.menuItemHeight;
    const itemHeight = `${menuItemHeight === null ? 50 : menuItemHeight}px`;
    const itemsAlignment = compProps.itemsAlignment;
    const menuArrowDirection = compProps.menuArrowDirection;
    const gridSettings = getGridSettings(itemsAlignment);

    const paddingLeft = itemsAlignment === 'left' ? menuSpacing : '0px';
    const subPaddingLeft = itemsAlignment === 'left' ? subMenuSpacing : '0px';
    const paddingRight = itemsAlignment === 'right' ? menuSpacing : '0px';
    const subPaddingRight = itemsAlignment === 'right' ? subMenuSpacing : '0px';

    return {
      height: 'auto',
      '--direction':
        menuArrowDirection === 'right' ? ('ltr' as const) : ('rtl' as const),
      '--item-height': itemHeight,
      '--text-align': itemsAlignment,
      '--template-columns': gridSettings.templateColumns,
      '--label-grid-column': gridSettings.labelColumns,
      '--arrow-grid-column': gridSettings.arrowColumns,
      '--padding-left': paddingLeft,
      '--sub-padding-left': subPaddingLeft,
      '--padding-right': paddingRight,
      '--sub-padding-right': subPaddingRight,
    };
  },
);

export const sdkData = withCompInfo<
  ExpandableMenuSdkData,
  ExpandableMenuDefinition
>()(['pagesMap', 'compData'], ({ pagesMap }) => {
  return {
    pageList: pagesMap,
  };
});

export default createComponentMapperModel({ props, stateRefs, css, sdkData });
