const SELECTED = 'SELECTED_ITEM=';
export const DESELECTED = 'DESELECTED';

export const selectItemId = (id: string) => `${SELECTED}${id}`;

export const hasItemIdSelected = (previewState: string) =>
  previewState.startsWith(SELECTED);

export const isDeselected = (previewState: string) =>
  previewState === DESELECTED;

export const getSelectedId = (previewState?: string | null) =>
  hasItemIdSelected(previewState || '')
    ? previewState!.replace(SELECTED, '')
    : '';
