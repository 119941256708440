import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  ICustomElementComponentMapperProps,
  ICustomElementComponentPreviewMapperProps,
  CustomElementComponentDefinition,
} from '../types';
import { Namespace, TranslationKeys } from '../translations';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './CustomElementComponent.mapper';

const props = composeMapper<
  PreviewWrapperProps<
    ICustomElementComponentMapperProps,
    ICustomElementComponentPreviewMapperProps
  >,
  CustomElementComponentDefinition
>()(
  viewerPropsMapper,
  {
    add: [
      'componentViewMode',
      'compLayout',
      'isWixBlocks',
      'translate',
      'isDescendantOfBlocksWidget',
      'experiments',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const {
      componentViewMode,
      compLayout,
      isWixBlocks,
      translate,
      isDescendantOfBlocksWidget,
      experiments,
    } = deps;
    const { width, height } = compLayout;
    const withSandbox =
      experiments['specs.thunderbolt.sandboxForCustomElement'] === true;
    const shouldUpdateHeight =
      experiments['specs.thunderbolt.customElementUpdateHeight'] === true;

    return {
      ...viewerProps,
      previewWrapperProps: {
        componentViewMode,
        isWixBlocks,
        isDescendantOfBlocksWidget,
        containerSizes: { width, height },
        ...(withSandbox && {
          sandbox:
            'allow-scripts allow-forms allow-downloads allow-presentation allow-modals',
        }),
        shouldUpdateHeight,
        translations: {
          blocksUpgrade: translate(Namespace, TranslationKeys.BlocksUpgrade),
          upgradePremium: translate(Namespace, TranslationKeys.UpgradePremium),
          connectDomain: translate(Namespace, TranslationKeys.ConnectDomain),
          upgradeNoAds: translate(Namespace, TranslationKeys.UpgradeNoAds),
        },
      },
    };
  },
);

const css = composeMapper()(
  viewerCssMapper,
  {
    add: ['hasResponsiveLayout'],
  },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const { hasResponsiveLayout } = dependencies;

    return hasResponsiveLayout
      ? {
          ...viewerCss,
          '--custom-element-height': 'auto',
        }
      : viewerCss;
  },
);

export default createComponentPreviewMapperModel({
  props,
  css,
});
