import React from 'react';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import { Keys, TestIds } from '../constants';
import type { ITinyMenuItemProps } from '../TinyMenu.types';

type TinyMenuSeoFriendlyContainerProps = {
  items: Array<ITinyMenuItemProps>;
};

const TinyMenuSeoFriendlyListItem: React.FC<ITinyMenuItemProps> = ({
  label,
  link,
  items,
}) => {
  return items && items.length > 0 ? (
    <li>
      <ul>
        {items.map((item: ITinyMenuItemProps, index: number) => (
          <li
            key={item.id || `${TestIds.seoListItem}-${index}`}
            data-testid={TestIds.seoListItem}
          >
            <Link {...item.link} tabIndex={-1}>
              {item.label}
            </Link>
          </li>
        ))}
      </ul>
    </li>
  ) : (
    <li data-testid={TestIds.seoListItem}>
      <Link {...link} tabIndex={-1}>
        {label}
      </Link>
    </li>
  );
};

const TinyMenuSeoFriendlyList: React.FC<TinyMenuSeoFriendlyContainerProps> = ({
  items,
}) => {
  return (
    <ul aria-hidden={true} style={{ display: 'none' }}>
      {items.map((item: ITinyMenuItemProps, index: number) => (
        <TinyMenuSeoFriendlyListItem
          key={item.id || `${Keys.seoListItem}-${index}`}
          {...item}
        />
      ))}
    </ul>
  );
};

export default TinyMenuSeoFriendlyList;
