import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  SoundCloudWidgetDefinition,
  ISoundCloudWidgetMapperProps,
  SoundCloudWidgetCSSVars,
  SoundCloudWidgetData,
} from '../SoundCloudWidget.types';
import { TRANSLATION_FEATURE, TRANSLATION_KEY } from '../constants';

function encodeSemicolon(str: string): string {
  return str.replace(/;/g, '%3b');
}

function getSoundCloudUrl(
  url: string,
  { showArtWork }: Partial<SoundCloudWidgetData>,
): string {
  // if URL includes 2 characters of '?'
  // for example: "https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Ftracks%2F68403644?"
  const urlInstance: URL = new URL(url);
  const questionMarkPosition: number = urlInstance.search.indexOf('?', 1);
  if (questionMarkPosition > 0) {
    urlInstance.search = urlInstance.search.substr(0, questionMarkPosition);
  }
  // Copied from the legacy component implementation:
  // Checks for true in either boolean or text values. This is done because
  // when setting the data from the panel, i get actual boolean values.
  // When setting the panel via the server, i get string values.
  const validShowArtWork: string =
    typeof showArtWork !== 'undefined' ? '' + showArtWork : 'true';
  urlInstance.searchParams.set('visual', validShowArtWork);
  // remove color url param which defects autoplay
  urlInstance.searchParams.delete('color');
  return urlInstance.href;
}

export const props = withCompInfo<
  ISoundCloudWidgetMapperProps,
  SoundCloudWidgetDefinition
>()(['compData', 'translate'], ({ compData, translate }) => {
  let soundCloudUrl = '';
  const { url, showArtWork, autoPlay } = compData;
  if (url) {
    soundCloudUrl = getSoundCloudUrl(url, { showArtWork });
    soundCloudUrl = decodeURIComponent(soundCloudUrl);
    soundCloudUrl = encodeSemicolon(soundCloudUrl);
  }
  return {
    url: soundCloudUrl,
    autoPlay,
    translations: {
      ariaLabel: translate(TRANSLATION_FEATURE, TRANSLATION_KEY),
    },
  };
});

export const css = withCompInfo<
  SoundCloudWidgetCSSVars,
  SoundCloudWidgetDefinition
>()(['compData'], ({ compData }) => {
  const isValidUrl = compData.url && compData.url.length > 0;
  return {
    '--display-placeholder-var': isValidUrl ? 'none' : 'block',
    '--display-iframe-var': isValidUrl ? 'block' : 'none',
  } as SoundCloudWidgetCSSVars;
});

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, css, stateRefs });
