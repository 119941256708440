const semanticClassNames = {
  root: 'radio-button-group',
  label: 'radio-button-group__label',
  option: 'radio-button-group__option',
  input: 'radio-button-group__input',
  selection: 'radio-button-group__input-selection',
  optionLabel: 'radio-button-group__option-label',
} as const;

export default semanticClassNames;
