import { RefObject, useCallback } from 'react';
import { useResizeObserver } from '../../../../providers/useResizeObserver/useResizeObserver';

export const useResizeObserverForContentHeight = (
  contentRef: RefObject<any>,
  wrapperRef: RefObject<any>,
) => {
  const callback = useCallback(() => {
    const roundedContentHeight = contentRef.current
      ? contentRef.current.offsetHeight
      : 0;

    if (roundedContentHeight > 0) {
      wrapperRef.current?.style.setProperty(
        '--height',
        `${roundedContentHeight}px`,
      );
    }
  }, [wrapperRef, contentRef]);

  useResizeObserver({
    callback,
    ref: contentRef,
  });
};
