import React from 'react';
import { TestIds } from '../../constants';
import type { TinyMenuItemProps } from '../TinyMenuItem';
import TinyMenuItem from '../TinyMenuItem';

export type ITinyMenuLoginButtonProps = Pick<
  TinyMenuItemProps,
  'label' | 'onClick' | 'skinsStyle'
>;

const TinyMenuLoginButton: React.FC<ITinyMenuLoginButtonProps> = ({
  onClick,
  label,
  skinsStyle,
}) => (
  <TinyMenuItem
    dataTestId={TestIds.logInButton}
    label={label}
    onClick={onClick}
    skinsStyle={skinsStyle}
  />
);

export default TinyMenuLoginButton;
