import { withCompController } from '@wix/editor-elements-integrations';

import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  PayPalButtonMapperProps,
  PayPalButtonStateRefs,
  PayPalButtonControllerProps,
} from '../PayPalButton.types';

const metasiteAppDefinitionId = '22bef345-3c5b-4c18-b782-74d4085112ff';

const compController = withCompController<
  PayPalButtonMapperProps,
  PayPalButtonControllerProps,
  never,
  PayPalButtonStateRefs
>(({ stateValues, mapperProps }) => {
  const {
    compId,
    language,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;
  const {
    getVisitorId,
    userId,
    currentUrlWithQueryParams,
    getAppInstanceByAppDefId,
    reportBi,
  } = stateValues;

  const metaSiteInstance =
    (getAppInstanceByAppDefId &&
      getAppInstanceByAppDefId(metasiteAppDefinitionId)) ||
    '';

  const reportBiOnClick: PayPalButtonControllerProps['reportBiOnClick'] =
    event => {
      const {
        amount,
        itemName,
        buttonType,
        itemNumber,
        currencyCode,
        submitImageAriaLabel,
      } = restMapperProps;

      tryReportAnalyticsClicksBi(reportBi, {
        language,
        trackClicksAnalytics,
        elementType: fullNameCompType,
        elementTitle: submitImageAriaLabel,
        elementGroup: AnalyticsClicksGroups.Button,
        element_id: compId ?? event.currentTarget.id,
        details: { itemName, itemNumber, amount, currencyCode, buttonType },
      });
    };

  return {
    ...restMapperProps,
    userId,
    metaSiteInstance,
    reportBiOnClick,
    currentUrl: currentUrlWithQueryParams,
    getVisitorId: getVisitorId as PayPalButtonControllerProps['getVisitorId'],
  };
});

export default compController;
