export const testIds = {
  input: 'input',
  suggestions: 'suggestions',
  label: 'label',
  srSearchResults: 'sr-search-results',
};

export const ariaNamespace = 'Address_Input';
export const ariaLabelsNamespace = 'ariaLabels';

export const TranslationKeys = {
  exampleAddressText: 'address_input_design_sample_address',
  apiDisconnectedPreviewText: 'address_input_states_error_feature_setup',
  noResults: 'address_input_states_no_results',
  generalError: 'address_input_states_error_general',
  ariaLabel: 'AddressInput_AriaLabel_TopLevel',
  srSearchResults: 'AddressInput_AriaLabel_Results_Number',
} as const;

export const TranslationFallbacks: Record<
  keyof typeof TranslationKeys,
  string
> = {
  noResults: 'No result was found for this request.',
  generalError:
    'The PlacesService request could not be processed due to a server error. The request may succeed if you try again.',
  exampleAddressText: 'Suggested Address',
  apiDisconnectedPreviewText: '',
  ariaLabel: 'Address Input - {{results}} results found',
  srSearchResults: '{{results}} results found',
};
