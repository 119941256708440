import * as React from 'react';
import { IAudioPlayerProps } from '../../../AudioPlayer.types';
import BasePlayer from '../../BasePlayer';
import style from './styles/BoldPlayer.scss';

const BoldPlayer: React.FC<IAudioPlayerProps> = props => {
  return <BasePlayer {...props} style={style} />;
};

export default BoldPlayer;
