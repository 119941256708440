import React from 'react';
import {
  toQueryString,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import { IWTwitterFollowProps } from '../WTwitterFollow.types';
import { WTwitterContent } from '../../WTwitterContent/viewer/WTwitterContent';
import { WidgetType } from '../../WTwitterContent/constants';
import { TITLE_NAME, TWITTER_URL_PREFIX } from './constants';

const WTwitterFollow: React.FC<IWTwitterFollowProps> = props => {
  const {
    id,
    className,
    onMouseEnter,
    onMouseLeave,
    translations,
    isConsentPolicyActive,
  } = props;

  const title = translations.title || TITLE_NAME;
  const queryString = toQueryString({
    screen_name: props.accountToFollow,
    href: `${TWITTER_URL_PREFIX}/${props.accountToFollow}`,
    show_count: props.showCount,
    show_screen_name: props.showScreenName,
    lang: props.lang,
    align: 'left',
    compId: props.id,
    origin: props.currentUrl,
    widgetType: WidgetType.FOLLOW,
  });

  return (
    <WTwitterContent
      {...{
        id,
        ...getDataAttributes(props),
        className,
        title,
        widgetType: WidgetType.FOLLOW,
        queryString,
        onMouseEnter,
        onMouseLeave,
        isConsentPolicyActive,
      }}
    />
  );
};

export default WTwitterFollow;
