import classNames from 'clsx';
import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { useGesture } from '@wix/thunderbolt-elements/src/providers/useGesture/useGesture';
import TinyMenuButton from '../../TinyMenuButton';
import TinyMenuContainer from '../../TinyMenuContainer';
import type { TinyMenuContentProps } from '../../TinyMenuRoot';
import TinyMenuRoot from '../../TinyMenuRoot';
import type { SkinTinyMenuProps } from '../SkinTinyMenu';
import TinyMenuSeoFriendlyList from '../../TinyMenuSeoFriendlyList';
import type { ITinyMenuItemProps } from '../../../TinyMenu.types';

type IconProps = {
  skinsStyle: Record<string, string>;
  preserveAspectRatio: string;
};

type BaseMenuSkinProps = SkinTinyMenuProps & {
  shouldCloseOnSwipe?: 'left' | 'right';
  skinsStyle: Record<string, string>;
};

const Icon: React.FC<IconProps> = ({ skinsStyle, preserveAspectRatio }) => (
  <svg
    className={skinsStyle.icon}
    preserveAspectRatio={preserveAspectRatio}
    viewBox="0 0 17 17"
  >
    <line
      className={classNames(skinsStyle.line1, skinsStyle.animatingLine)}
      x2="100%"
    />
    <line
      className={classNames(skinsStyle.line2, skinsStyle.animatingLine)}
      x2="100%"
    />
    <line
      className={classNames(skinsStyle.line3, skinsStyle.animatingLine)}
      x2="100%"
    />
  </svg>
);

const BaseMenu: React.FC<BaseMenuSkinProps> = ({
  wrapperProps,
  skinsStyle,
  shouldCloseOnSwipe,
}) => {
  const {
    id,
    className,
    items,
    isOpen,
    setIsOpen,
    textAlignment,
    currentPrimaryPageHref,
    activeAnchor,
    ariaLabel,
    members,
    isHidden,
    onMouseEnter,
    onMouseLeave,
    onMenuOpen,
    onMenuClose,
    onLogin,
    onLogout,
    isLoggedIn,
    userName,
    avatarUri,
    languages,
    onLanguageChange,
    reportBiOnMenuButtonClick,
    reportBiOnMenuItemClick,
  } = wrapperProps;

  const toggleMenu = () => setIsOpen(!isOpen);

  const onButtonClick = () => {
    reportBiOnMenuButtonClick?.();
    toggleMenu();
  };

  const onMenuItemClick = (item: ITinyMenuItemProps) => {
    reportBiOnMenuItemClick?.(item);
    toggleMenu();
  };

  const MenuContent: React.FC<TinyMenuContentProps> = ({
    itemsWithSelection,
    containerRef,
    buttonRef,
  }) => {
    const layoutRef = React.useRef(null);
    const closeOnSwipe = () => {
      if (shouldCloseOnSwipe && isOpen) {
        toggleMenu();
      }
    };
    useGesture(
      shouldCloseOnSwipe === 'left' ? 'onSwipeLeft' : 'onSwipeRight',
      closeOnSwipe,
      layoutRef,
    );
    return (
      <>
        <div
          ref={layoutRef}
          className={classNames(
            // Both `fullScreenOverlay` & `fullScreenOverlayContent` are global and arrive from TB
            'fullScreenOverlay',
            skinsStyle.fullScreenOverlay,
          )}
        >
          <div
            className={classNames(
              'fullScreenOverlayContent',
              skinsStyle.fullScreenOverlayContent,
            )}
          >
            <div className={skinsStyle.menuBackground}>
              {isOpen && (
                <TinyMenuContainer
                  id={id}
                  ref={containerRef}
                  items={itemsWithSelection}
                  textAlignment={textAlignment}
                  members={members}
                  skinsStyle={skinsStyle}
                  onLogin={onLogin}
                  onLogout={onLogout}
                  onItemClick={onMenuItemClick}
                  isLoggedIn={isLoggedIn}
                  userName={userName}
                  avatarUri={avatarUri}
                  currentHref={currentPrimaryPageHref}
                  currentAnchor={activeAnchor?.dataId}
                  languages={languages}
                  onLanguageChange={onLanguageChange}
                />
              )}
            </div>
          </div>
        </div>
        <TinyMenuSeoFriendlyList items={itemsWithSelection} />
        <TinyMenuButton
          className={skinsStyle.menuButton}
          isExpanded={isOpen}
          onClick={onButtonClick}
          ref={buttonRef}
        >
          <Icon
            preserveAspectRatio={isOpen ? '' : 'none'}
            skinsStyle={skinsStyle}
          />
        </TinyMenuButton>
      </>
    );
  };

  return (
    <TinyMenuRoot
      id={id}
      {...getDataAttributes(wrapperProps)}
      items={items}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      ariaLabel={ariaLabel}
      textAlignment={textAlignment}
      currentPrimaryPageHref={currentPrimaryPageHref}
      activeAnchor={activeAnchor}
      className={{
        root: classNames(className, skinsStyle.root),
        menuOpen: skinsStyle.menuOpen,
        menuClosed: skinsStyle.menuClosed,
        hidden: skinsStyle.hidden,
        left: skinsStyle.left,
        center: skinsStyle.center,
        right: skinsStyle.right,
      }}
      isHidden={isHidden}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {MenuContent}
    </TinyMenuRoot>
  );
};

export default BaseMenu;
