import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  AudioPlayerDefinition,
  IAudioPlayerMapperProps,
} from '../AudioPlayer.types';

const staticAudioUrl = 'https://music.wixstatic.com/mp3'; // TODO add to topology

export const props = withCompInfo<
  IAudioPlayerMapperProps,
  AudioPlayerDefinition
>()(['compData', 'skin'], ({ compData, skin }) => {
  return {
    skin: skin || 'SimplePlayer',
    playlist: [{ url: `${staticAudioUrl}/${compData.uri}` }],
    autoPlay: compData.autoPlay,
    loop: compData.loop,
    volume: compData.volume / 100,
    originalFileName: compData.originalFileName,
  };
});

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
