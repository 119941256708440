import React, { useEffect, useState, useCallback } from 'react';
import classNames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { IPinItPinWidgetProps } from '../PinItPinWidget.types';
import { IFrameEvent, useIFrame } from '../../../providers/useIFrame/useIFrame';
import { ConsentPolicyWrapperContext } from '../../ConsentPolicyWrapper/viewer/ConsentPolicyWrapper';
import { getComponentProps } from '../../ConsentPolicyWrapper/viewer/utils';
import style from './style/PinItPinWidget.scss';
import pinterestWidget from './assets/pinterestWidget.html?resource';

type StyleDimensions = {
  width?: string;
  height?: string;
};

const PinItPinWidget: React.FC<IPinItPinWidgetProps> = props => {
  const {
    id,
    className,
    pinUrl,
    isConsentPolicyActive,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const iframeSrc = `${pinterestWidget}?${new URLSearchParams({
    pinUrl,
    compId: id,
  })}`;

  const [styles, setStyles] = useState<StyleDimensions>({});
  const [render, setRender] = useState(false);
  const [showError, setShowError] = useState(false);
  const context = React.useContext(ConsentPolicyWrapperContext);

  const handleMessage = useCallback(
    ({ type, payload }: IFrameEvent) => {
      if (type !== 'message' || typeof payload !== 'string') {
        return;
      }
      try {
        const data = JSON.parse(payload);
        if (data.type !== 'pinterest' || data.compId !== id) {
          return;
        }
        if (data.showError) {
          setShowError(true);
          setStyles({ width: '225px', height: '274px' });
          context.setWrapperStyle({ width: '225px', height: '274px' });
        } else {
          setShowError(false);
          setStyles({ width: `${data.width}px`, height: `${data.height}px` });
          context.setWrapperStyle({
            width: `${data.width}px`,
            height: `${data.height}px`,
          });
        }
      } catch (error) {}
    },
    [id, context],
  );

  const [ref] = useIFrame({ reducer: handleMessage });

  useEffect(() => {
    setRender(true);
  }, []);

  const baseComponentProps = getComponentProps(isConsentPolicyActive, {
    id,
    className,
    ...getDataAttributes(props),
  });

  return (
    <div
      {...baseComponentProps}
      style={styles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {render && (
        <wix-iframe data-src={iframeSrc}>
          {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
          <iframe
            className={classNames(style.iframe, {
              [style.iframeError]: showError,
            })}
            data-src={iframeSrc}
            ref={ref}
          />
          {showError && <div className={style.error} />}
        </wix-iframe>
      )}
    </div>
  );
};

export default PinItPinWidget;
