import * as React from 'react';
import {
  IDatePickerImperativeActions,
  IDatePickerProps,
} from '../DatePicker.types';
import { DatePickerSkinMap } from './skinMap';

const DatePicker: React.ForwardRefRenderFunction<
  IDatePickerImperativeActions,
  IDatePickerProps
> = (props, ref) => {
  const { skin } = props;

  const SkinComponent = DatePickerSkinMap[skin];

  return <SkinComponent ref={ref} {...props}></SkinComponent>;
};

export default React.forwardRef(DatePicker);
