import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { css as viewerCssMapper } from './AccordionItem.mapper';

const css = composeMapper()(
  viewerCssMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const isEditMode = dependencies.componentViewMode === 'editor';

    /* during resize viewerApi adds "position:fixed" to the root div,
       which breaks layout of the AccordionContainer */
    const overridePositionForResize = isEditMode && {
      position: 'static !important',
    };

    return {
      ...viewerCss,
      ...overridePositionForResize,
    };
  },
);

export default createComponentPreviewMapperModel({
  css,
});
