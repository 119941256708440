import * as React from 'react';
import type {
  IDropDownMenuProps,
  IDropDownMenuImperativeActions,
} from '../../../DropDownMenu.types';
import DropDownMenuBase from '../../DropDownMenuBase';
import ArrowsMenuButtonSkin from '../../../../MenuButton/viewer/skinComps/ArrowsMenuButtonSkin/ArrowsMenuButtonSkin';
import styles from './ArrowsMenuSkin.scss';

const ArrowsMenuSkin: React.ForwardRefRenderFunction<
  IDropDownMenuImperativeActions,
  IDropDownMenuProps
> = (props, ref) => {
  return (
    <DropDownMenuBase
      {...props}
      ref={ref}
      styles={styles}
      Button={ArrowsMenuButtonSkin}
    />
  );
};

export default React.forwardRef(ArrowsMenuSkin);
