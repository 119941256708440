import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getSocialCompBlockingLayerProps,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  DisqusCommentsDefinition,
  DisqusCommentsMapperProps,
} from '../DisqusComments.types';

import { props as viewerPropsMapper } from './DisqusComments.mapper';

const props = composeMapper<
  PreviewWrapperProps<DisqusCommentsMapperProps>,
  DisqusCommentsDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      currentUrl: 'https://www.wix.com',
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
