import { withCompController } from '@wix/editor-elements-integrations';
import { getValidationControllerProps } from '@wix/editor-elements-common-utils';
import {
  IRatingsInputMapperProps,
  IRatingsInputControllerProps,
  IRatingsInputProps,
} from '../RatingsInput.types';

export default withCompController<
  IRatingsInputMapperProps,
  IRatingsInputControllerProps,
  IRatingsInputProps
>(({ mapperProps, controllerUtils }) => {
  const { updateProps } = controllerUtils;
  const { setValidityIndication } = getValidationControllerProps(updateProps);

  return {
    ...mapperProps,
    onRatingChange: event => {
      updateProps({
        value: parseInt(event.target.value, 10) || null,
      });
    },
    setValidityIndication,
  };
});
