export const TWITTER_URL = 'https://twitter.com/share';
export const WIDGET_TYPE_TWEET = 'TWEET';
export const COMPONENT_LABEL = 'component_label';
export const TITLE_NAME = 'component_label_ twitterTweetTitle';

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_WTwitterTweet',
  componentNameDefault: 'Twitter Tweet',
  companyNameKey: 'CookieConsent_Dataset_Company_Twitter',
  companyNameDefault: 'Twitter',
};
