import * as React from 'react';
import classnames from 'clsx';
import { IVideoLegacyProps } from '../VideoLegacy.types';
import style from './style/VideoLegacy.scss';

// Skins extra markup
const LiftedShadowSkin: React.FC = () => (
  <>
    <div className={classnames(style.left, style.shd)} />
    <div className={classnames(style.right, style.shd)} />
  </>
);
const SloppyBordersSkin: React.FC = () => (
  <>
    <div className={classnames(style.brd, style.one)} />
    <div className={classnames(style.brd, style.two)} />
  </>
);

const SKIN_BORDERS = {
  VideoLiftedShadow: LiftedShadowSkin,
  VideoSloppy: SloppyBordersSkin,
  VideoSkin: () => <></>,
  VideoDefault: () => <></>,
} as Record<IVideoLegacyProps['skin'], React.FC>;

export function renderSkinBorders(skin: IVideoLegacyProps['skin']) {
  const SkinBorders = SKIN_BORDERS[skin] || SKIN_BORDERS.VideoDefault;
  return <SkinBorders />;
}
