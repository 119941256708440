import React from 'react';
import classnames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import type { SkinPageProps } from '../SkinPage';
import Page from '../../Page';
import semanticClassNames from '../../../Page.semanticClassNames';
import styles from './styles/LiftedBottomSkin.scss';

const LiftedBottomPageSkin: React.FC<SkinPageProps> = ({
  id,
  className,
  customClassNames = [],
  pageDidMount,
  onClick,
  onDblClick,
  children,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div
      id={id}
      className={classnames(styles.pageWrapper, className)}
      ref={pageDidMount}
      onClick={onClick}
      onDoubleClick={onDblClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <div
        className={classnames(
          styles.bg,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
      >
        <div className={`${styles.shadow} ${styles.leftBottom}`} />
        <div className={`${styles.shadow} ${styles.centerBottom}`} />
        <div className={`${styles.shadow} ${styles.rightBottom}`} />
        <div className={styles.innerBg} />
      </div>
      <div className={styles.inlineContent}>{children()}</div>
    </div>
  );
};

const LiftedBottomPage: React.FC<SkinPageProps> = props => (
  <Page {...props} skin={LiftedBottomPageSkin} />
);

export default LiftedBottomPage;
