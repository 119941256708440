import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  IVerticalLineProps,
  VerticalLineDefinition,
} from '../VerticalLine.types';

/**
 * This mapper makes sure verticalLine will be affected by the css editing mappers
 */
export const props = withCompInfo<
  IVerticalLineProps,
  VerticalLineDefinition,
  IVerticalLineProps
>()([], (_, carmiData) => {
  return {
    ...carmiData,
  };
});

export default createComponentMapperModel({ props });
