import * as React from 'react';
import classnames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import { IFormContainerResponsiveProps } from '../../../FormContainer.types';
import { FormContainerRoot } from '../../shared/FormContainerRoot';
import ResponsiveContainer from '../../../../../thunderbolt-core-components/ResponsiveContainer/viewer/ResponsiveContainer';
import semanticClassNames from '../../../FormContainer.semanticClassNames';
import style from './ResponsiveSkin.scss';

const ResponsiveSkin: React.FC<IFormContainerResponsiveProps> = props => {
  const {
    id,
    containerProps,
    onSubmit,
    children,
    containerRootClassName,
    customClassNames = [],
    onMouseEnter,
    onMouseLeave,
  } = props;
  const responsiveContainerProps = {
    id,
    ...containerProps,
    children,
  };
  const classNames = classnames(
    style.root,
    containerRootClassName,
    formatClassNames(semanticClassNames.root, ...customClassNames),
  );
  return (
    <FormContainerRoot
      id={id}
      className={classNames}
      onSubmit={onSubmit}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ResponsiveContainer {...responsiveContainerProps} />
    </FormContainerRoot>
  );
};

export default ResponsiveSkin;
