import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksCollapsibleTextExpandActionName,
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import type {
  ICollapsibleTextControllerProps,
  ICollapsibleTextMapperProps,
  ICollapsibleTextCompProps,
} from '../CollapsibleText.types';

export default withCompController<
  ICollapsibleTextMapperProps,
  ICollapsibleTextControllerProps,
  ICollapsibleTextCompProps
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { reportBi } = stateValues;

  const {
    compId,
    language,
    mainPageId,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const commonReportAnalyticsClicksBiProps = {
    language,
    trackClicksAnalytics,
    element_id: compId,
    elementType: fullNameCompType,
    elementGroup: AnalyticsClicksGroups.Text,
  };

  const reportBiOnExpandClick = (isExpanded: boolean) => {
    const { readLessText, readMoreText } = restMapperProps;

    tryReportAnalyticsClicksBi(reportBi, {
      ...commonReportAnalyticsClicksBiProps,
      actionName: AnalyticsClicksCollapsibleTextExpandActionName,
      elementTitle: isExpanded ? readMoreText : readLessText,
    });
  };

  const reportBiOnLinkClick = () => {
    const { link, linkText } = restMapperProps;

    tryReportAnalyticsClicksBi(reportBi, {
      ...commonReportAnalyticsClicksBiProps,
      link,
      elementTitle: linkText,
      pagesMetadata: { mainPageId },
    });
  };

  return {
    ...mapperProps,
    reportBiOnExpandClick,
    reportBiOnLinkClick,
    setIsExpanded: isExpanded => {
      controllerUtils.updateProps({ isExpanded });
    },
  };
});
