export const CAPTCHA_NAMESPACE = 'recaptcha';
export const ARIA_LABELS_NAMESPACE = 'ariaLabels';

export const CAPTCHA_DIALOG_KEY = 'recaptcha_popup_ariaLabel_topLevel';
export const CAPTCHA_DIALOG_DEFAULT = 'reCAPTCHA';

export const CAPTCHA_CLOSE_KEY = 'recaptcha_popup_ariaLabel_closeButton';
export const CAPTCHA_CLOSE_DEFAULT = 'Close';

export const CAPTCHA_TITLE_KEY = 'recaptcha_popup_title';
export const CAPTCHA_TITLE_DEFAULT = 'Verification';

export const CAPTCHA_SUBTITLE_KEY = 'recaptcha_popup_subtitle';
export const CAPTCHA_SUBTITLE_DEFAULT = "Please confirm you're human.";
