import React, { useEffect, useRef, useState, useContext } from 'react';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import classnames from 'clsx';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import {
  createLink,
  removeLink,
  getLinkData,
  linkExists,
  getSelectedText,
} from '../../RichTextBox/viewer/utils/draftUtils';
import { useClickOutside } from '../../../providers/useClickOutside/useClickOutside';
import { TestIds } from '../../RichTextBox/constants';
import StylesContext from '../../RichTextBox/viewer/StylesContext';
import { isValidUrl } from './linkUtils';
import styles from './styles/RichTextBoxLinkModal.scss';
import ErrorIcon from './ErrorIcon';

const RichTextBoxLinkModal = (
  props: Omit<ToolbarChildrenProps, 'theme' | 'onOverrideContent'> & {
    onClose: () => void;
    onMount?: Function;
    onUnmount?: () => void;
    staticDialog?: boolean;
    id?: string;
    className?: string;
    linkButtonRef: React.RefObject<HTMLDivElement>;
  },
) => {
  const {
    className,
    onClose,
    getEditorState,
    setEditorState,
    staticDialog = false,
    id,
    linkButtonRef,
  } = props;

  const stylesContext = useContext(StylesContext);
  const modalRef = useRef<HTMLDivElement>(null);
  useClickOutside([modalRef, linkButtonRef], onClose, false);

  const onKeyUp = (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      closeModal();
    }
  };

  useEffect(() => {
    window.addEventListener('keyup', onKeyUp);
    if (linkExists(getEditorState())) {
      const { url, targetNewWindow } = getLinkData(getEditorState());
      setLinkUrl(url);
      setLinkTargetNewWindow(targetNewWindow);
    }

    return () => window.removeEventListener('keyup', onKeyUp);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const inputRef = useRef<HTMLInputElement>(null);

  const [linkUrl, setLinkUrl] = useState('');
  const [linkError, setLinkError] = useState(false);
  const [linkTargetNewWindow, setLinkTargetNewWindow] = useState(false);

  const closeModal = () => {
    onClose();
  };

  const updateLink = () => {
    const newState = createLink(getEditorState(), {
      text: getSelectedText(getEditorState()),
      url: linkUrl,
      targetNewWindow: linkTargetNewWindow,
    });
    setEditorState(newState);
  };

  const onLinkUpdate = () => {
    if (isValidUrl(linkUrl)) {
      setLinkError(false);
      updateLink();
      closeModal();
    } else {
      setLinkError(true);
    }
  };

  const onLinkRemove = () => {
    const newState = removeLink(getEditorState());
    setEditorState(newState);
    closeModal();
  };

  const onInputClick = (e: React.MouseEvent<HTMLInputElement>) => {
    inputRef.current?.focus();

    e.preventDefault();
    e.stopPropagation();
  };

  const onClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLinkUrl(e.target.value);
  };

  const onLinkTargetToggle = () => {
    setLinkTargetNewWindow(!linkTargetNewWindow);
  };

  const inputPlaceholder = 'e.g., www.wix.com';
  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classnames(className, styles.modalWrapper)}
    >
      <div
        className={classnames(
          styles.linkDialog,
          staticDialog ? styles.linkDialogStatic : styles.linkDialogInline,
          {
            [styles.hovered]: stylesContext.hovered,
            [styles.focused]: stylesContext.focused,
            [styles.error]: stylesContext.error,
          },
        )}
        onClick={onClick}
        ref={modalRef}
      >
        <div className={styles.linkTitle}>Add a Link</div>
        <div className={styles.inputContainer}>
          <input
            ref={inputRef}
            className={classnames(styles.input, {
              [styles.inputError]: linkError,
            })}
            type="text"
            placeholder={inputPlaceholder}
            onChange={onInputChange}
            onClick={onInputClick}
            value={linkUrl}
            data-testid={TestIds.linkInput}
          />
          {linkError && (
            <span className={styles.errorContainer}>
              <ErrorIcon />
            </span>
          )}
        </div>
        <label className={styles.container}>
          <input
            type="checkbox"
            checked={linkTargetNewWindow}
            onChange={onLinkTargetToggle}
            onClick={onLinkTargetToggle}
          />
          Open link in a new tab
        </label>
        <div className={styles.buttons}>
          <div className={styles.leftButtons}>
            <button
              className={classnames(styles.button, styles.cancel)}
              onClick={closeModal}
              data-testid={TestIds.linkCancel}
            >
              Cancel
            </button>

            {linkExists(getEditorState()) && (
              <>
                <div className={styles.separator} />
                <button
                  className={classnames(styles.button, styles.cancel)}
                  onClick={onLinkRemove}
                  data-testid={TestIds.linkRemove}
                >
                  Remove
                </button>
              </>
            )}
          </div>
          <button
            className={classnames(styles.button, styles.update)}
            onClick={onLinkUpdate}
            data-testid={TestIds.linkUpdate}
          >
            Update
          </button>
        </div>
      </div>
    </div>
  );
};

export default RichTextBoxLinkModal;
