import type { RefObject } from 'react';
import { useEffect } from 'react';

export const useIntersectionObserver = ({
  callback,
  ref,
  elem,
  options = { threshold: 0.5 },
}: {
  callback: IntersectionObserverCallback;
  ref?: RefObject<any>;
  elem?: HTMLElement | null;
  options?: IntersectionObserverInit;
}) => {
  useEffect(() => {
    const observer = new IntersectionObserver(callback, options);

    if (ref?.current) {
      observer.observe(ref.current);
    }
    if (elem) {
      observer.observe(elem);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, elem, callback, options]);
};
