import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getSocialCompBlockingLayerProps,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  WTwitterTweetDefinition,
  WTwitterTweetMapperProps,
} from '../WTwitterTweet.types';
import { props as viewerPropsMapper } from './WTwitterTweet.mapper';

const props = composeMapper<
  PreviewWrapperProps<WTwitterTweetMapperProps>,
  WTwitterTweetDefinition
>()(
  viewerPropsMapper,
  { add: ['isSocialInteractionAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(deps.isSocialInteractionAllowed),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
