import { generateSkinDefaults } from '@wix/editor-elements-panel';
import type { SkinDefinition } from '@wix/editor-elements-panel/src/adapters/types';

export const svgImageSkinDefinitionMap: Record<string, SkinDefinition> = {
  'wixui.skins.SvgImage': {
    'shadow-blur-radius': {
      type: 'SIZE',
      state: 'regular',
      paramName: 'shadow-blur-radius',
    },
    'shadow-color': {
      type: 'CSS_COLOR',
      state: 'regular',
      paramName: 'shadow-color',
    },
    'shadow-spread-radius': {
      type: 'SIZE',
      state: 'regular',
      paramName: 'shadow-spread-radius',
    },
    'shadow-opacity': {
      type: 'ALPHA',
      state: 'regular',
      paramName: 'shadow-opacity',
    },
    'shadow-position': {
      type: 'CSSString',
      state: 'regular',
      paramName: 'shadow-x',
    },
    opacity: {
      type: 'ALPHA',
      state: 'regular',
      paramName: 'opacity',
    },
    'enable-stroke': {
      type: 'CSSString',
      state: 'regular',
      paramName: 'enable-stroke',
    },
    stroke: {
      type: 'COLOR',
      defaultValue: '#000000',
      state: 'regular',
      paramName: 'stroke',
    },
    'alpha-stroke': {
      type: 'ALPHA',
      state: 'regular',
      paramName: 'alpha-stroke',
    },
    'stroke-width': {
      type: 'SIZE',
      state: 'regular',
      paramName: 'stroke-width',
    },
    'fill-opacity': {
      type: 'ALPHA',
      state: 'regular',
      paramName: 'fill-opacity',
    },
    'svg-color-1': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-1',
    },
    'svg-color-2': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-2',
    },
    'svg-color-3': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-3',
    },
    'svg-color-4': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-4',
    },
    'svg-color-5': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-5',
    },
    'svg-color-6': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-6',
    },
    'svg-color-7': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-7',
    },
    'svg-color-8': {
      type: 'COLOR',
      state: 'regular',
      paramName: 'svg-color-8',
    },
    flip: {
      type: 'CSSString',
      state: 'regular',
      paramName: 'flip',
    },
  },
};

export default generateSkinDefaults(svgImageSkinDefinitionMap);
