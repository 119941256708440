import { withCompController } from '@wix/editor-elements-integrations';
import type { IStylableButtonMapperProps } from '../../../StylableButton/StylableButton.types';
import type {
  ISlideshowButtonControllerProps,
  ISlideshowButtonProps,
} from '../../types';

export default withCompController<
  IStylableButtonMapperProps,
  ISlideshowButtonControllerProps,
  ISlideshowButtonProps
>(({ mapperProps, controllerUtils }) => {
  const { updateProps } = controllerUtils;

  return {
    ...mapperProps,
    onDisabledChange: isDisabledByCtx => {
      updateProps({ isDisabledByCtx });
    },
  };
});
