import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import BlankScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/BlankScreen';
import FooterContainer from '../../FooterContainer';

const BlankScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => <FooterContainer {...props} skin={BlankScreen}></FooterContainer>;

export default BlankScreenFooter;
