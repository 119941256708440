import React from 'react';
import type { ITinyMenuProps } from '../../../TinyMenu.types';
import TinyMenu from '../../TinyMenu';
import type { SkinTinyMenuProps } from '../SkinTinyMenu';
import BaseMenu from './BaseMenu';
import skinsStyle from './styles/TinyMenuPullFromTopSkin.scss';

const Skin: React.FC<SkinTinyMenuProps> = props => (
  <BaseMenu {...props} skinsStyle={skinsStyle} />
);

const TinyMenuPullFromTopSkin: React.FC<
  Omit<ITinyMenuProps, 'skin'>
> = props => <TinyMenu {...props} skin={Skin} />;

export default TinyMenuPullFromTopSkin;
