import type {
  IFocusPropsImperativeActions,
  IFocusPropsSDKActions,
} from '@wix/editor-elements-corvid-utils';
import type { DefaultCompPlatformProps } from '@wix/editor-elements-types/thunderbolt';
import type {
  IComponentPanelProps,
  IComponentPanelWithStylable,
} from '@wix/editor-elements-types/editor';
import type { ViewerTranslations } from '@wix/editor-elements-types/components';
import type { IRangeSliderProps as IProps } from './RangeSlider.props';

type RangeSliderUpdatedProps = Omit<IProps, 'thumbSize' | 'thumbShape'>;

// TODO ADD VALIDATION
export type IRangeSliderImperativeActions = IFocusPropsImperativeActions;
export type IRangeSliderProps = DefaultCompPlatformProps &
  RangeSliderUpdatedProps & {
    className?: string;
    stylableClassName?: string;
    width: number;
  } & IFocusPropsSDKActions &
  ViewerTranslations<'minValueAriaLabel' | 'maxValueAriaLabel'>;

export type RangeSliderLayoutPanelProps = IComponentPanelWithStylable<
  {},
  IRangeSliderProps
>;

export type RangeSliderSettingsPanelProps = Omit<
  IComponentPanelProps<never, IRangeSliderProps>,
  | 'compProperties'
  | 'blockCommits'
  | 'releaseCommits'
  | 'setSelectorDeclarations'
  | 'getSelectorDeclarations'
>;

export const SliderTypes = {
  Stepped: 'Stepped',
  Continuous: 'Continuous',
} as const;
export type SliderType = keyof typeof SliderTypes;

export const ThumbShapesTypes = {
  Square: 'Square',
  Rectangle: 'Rectangle',
  Bar: 'Bar',
  Circle: 'Circle',
} as const;
export type ThumbShapesType = keyof typeof ThumbShapesTypes;

export const MarkTicksTypes = {
  Lines: 'Lines',
  Dots: 'Dots',
} as const;
export type MarkTicksType = keyof typeof MarkTicksTypes;

export const DirectionTypes = {
  ltr: 'ltr',
  rtl: 'rtl',
} as const;
export type DirectionType = keyof typeof DirectionTypes;

export const StepValueTypes = {
  Number: 'Number',
  Value: 'Value',
} as const;
export type StepValueType = keyof typeof StepValueTypes;

export const AlignmentTypes = {
  left: 'left',
  center: 'center',
  right: 'right',
} as const;
export type AlignmentType = keyof typeof AlignmentTypes;

export const ValuesRangeTypes = {
  min: 'min',
  max: 'max',
  minValue: 'minValue',
  maxValue: 'maxValue',
} as const;
export type ValueInRangeType = keyof typeof ValuesRangeTypes;

export const ValuesTextTypes = {
  prefix: 'prefix',
  suffix: 'suffix',
} as const;
export type ValuesTextType = keyof typeof ValuesTextTypes;
