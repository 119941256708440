import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import LiftedTopScreen from '../../../../ScreenWidthContainer/viewer/skinComps/LiftedTopScreen/LiftedTopScreen';
import FooterContainer from '../../FooterContainer';

const LiftedTopScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => <FooterContainer {...props} skin={LiftedTopScreen} />;

export default LiftedTopScreenFooter;
