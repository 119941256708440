import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { VideoLegacyDefinition, VideoLegacyProps } from '../VideoLegacy.types';
import { props as viewerPropsMapper } from './VideoLegacy.mapper';

const props = composeMapper<
  PreviewWrapperProps<VideoLegacyProps>,
  VideoLegacyDefinition
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed', 'shouldResetComponent'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      autoplay: deps.isPlayingAllowed && viewerProps.autoplay,
      previewWrapperProps: {
        shouldResetComponent: deps.shouldResetComponent,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
