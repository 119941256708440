import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { SubmenuDefinition, SubmenuCarmiProps } from '../types';

export const props = withCompInfo<{}, SubmenuDefinition, SubmenuCarmiProps>()(
  [],
  (_, carmiData) => carmiData,
);

export const css = withCompInfo<{}, SubmenuDefinition, SubmenuCarmiProps>()(
  ['compData'],
  ({ compData }) => {
    const { direction, itemDirection, subItemDirection } = compData;

    return {
      '--direction': direction,
      '--item-direction': itemDirection,
      '--sub-item-direction': subItemDirection,
    };
  },
);

export default createComponentMapperModel({ props, css });
