import type {
  CompDefinition,
  StyleProperties,
  CompInfo,
  Spx,
} from '@wix/editor-elements-types/thunderbolt';
import type { LogicalAlignment, PhysicalAlignment } from '../direction';
import { getOppositeAlign } from '../direction';
import { parseValueWithUnit } from './scaleProportionally';

type AnyCompDef = CompDefinition<any, any, any, any, any, any>;

export const getInputHeight = <T extends AnyCompDef>(
  {
    inputHeightProps,
    height,
    isMobileView,
  }: {
    inputHeightProps: T['property'];
    height: number | undefined;
    isMobileView: boolean;
  },
  formatCssValue?: CompInfo['formatCssValue'],
  spx?: Spx,
) => {
  const { inputHeightMobile, inputHeight } = inputHeightProps;
  const actualHeight = isMobileView
    ? inputHeightMobile || inputHeight || height
    : inputHeight || height;
  return formatCssValue
    ? formatCssValue(actualHeight, spx)
    : `${actualHeight}px`;
};

export const getLabelPadding = <T extends AnyCompDef>(
  labelPaddingProps: T['property'],
  formatCssValue?: CompInfo['formatCssValue'],
  spx?: Spx,
): string | undefined => {
  const { labelPadding, textAlignment, filesAlignment, alignment } =
    labelPaddingProps;

  const labelPaddingLimit = String(labelPadding).endsWith('spx') ? '20spx' : 20;
  const align = textAlignment || filesAlignment || alignment;
  switch (align) {
    case 'left':
      return formatCssValue
        ? formatCssValue(`0 ${labelPaddingLimit} 0 ${labelPadding}`, spx)
        : `0 ${labelPaddingLimit}px 0 ${labelPadding}px`;
    case 'right':
      return formatCssValue
        ? formatCssValue(`0 ${labelPadding} 0 ${labelPaddingLimit}`, spx)
        : `0 ${labelPadding}px 0 ${labelPaddingLimit}px`;
    default:
      return undefined;
  }
};

export const getRequiredIndicationDisplay = (
  styleProperties: StyleProperties,
): 'none' | 'inline' => {
  const { txtlblrq } = styleProperties;
  return !txtlblrq || txtlblrq === 'transparent' ? 'none' : 'inline';
};

type IGetLabelPaddingsProps = {
  labelPadding: number | string;
  align: LogicalAlignment;
};

export type ILabelPaddingsNumberValues = {
  '--labelPadding_start': number;
  '--labelPadding_end': number;
};

const LABEL_PADDING_LIMIT = 20;

export const getLabelPaddingsValues = ({
  labelPadding,
  align,
}: IGetLabelPaddingsProps): ILabelPaddingsNumberValues => {
  if (align === 'center') {
    return {
      '--labelPadding_start': 0,
      '--labelPadding_end': 0,
    };
  }
  const { value } = parseValueWithUnit(String(labelPadding), {
    fallbackValue: { value: 0, unit: 'px' },
  });

  return {
    [`--labelPadding_${align}`]: value,
    [`--labelPadding_${getOppositeAlign(align)}`]: LABEL_PADDING_LIMIT,
  } as ILabelPaddingsNumberValues;
};

type IGetTextPaddingsCssVarProps = {
  align: LogicalAlignment;
  textPadding: number | string;
  hasPrefix: boolean;
};

type ITextPaddingsNumberValues = {
  '--textPadding': number;
  '--textPadding_start': number;
  '--textPadding_end': number;
};

const DEFAULT_PADDING = 3;
const PREFIX_PADDING = 50;

export const getTextPaddingsValues = ({
  align,
  textPadding,
  hasPrefix,
}: IGetTextPaddingsCssVarProps): ITextPaddingsNumberValues => {
  const { value } = parseValueWithUnit(String(textPadding), {
    fallbackValue: { value: DEFAULT_PADDING, unit: 'px' },
  });
  const addedPrefix = hasPrefix ? PREFIX_PADDING : 0;
  const isCenter = align === 'center';

  const paddingWithPrefix = value + addedPrefix;
  const defaulPaddingWithPrefix = DEFAULT_PADDING + addedPrefix;

  if (isCenter) {
    return {
      '--textPadding': DEFAULT_PADDING,
      '--textPadding_start': defaulPaddingWithPrefix,
      '--textPadding_end': defaulPaddingWithPrefix,
    };
  }
  return {
    '--textPadding': DEFAULT_PADDING,
    [`--textPadding_${align}`]: paddingWithPrefix,
    [`--textPadding_${getOppositeAlign(align)}`]: DEFAULT_PADDING,
  } as ITextPaddingsNumberValues;
};

export const convertPhysicalInputAlignmentToDirection = (
  physicalAlignment: PhysicalAlignment,
) => {
  return physicalAlignment === 'right' ? 'rtl' : 'inherit';
};

export const convertPhysicalInputAlignmentToLogical = (
  physicalAlignment: PhysicalAlignment,
) => {
  return physicalAlignment === 'center' ? 'center' : 'start';
};
