import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISocialAuthMapperProps,
  ISocialAuthControllerProps,
  ISocialAuthStateRefsValues,
  ISocialAuthProps,
} from '../SocialAuth.types';

export default withCompController<
  ISocialAuthMapperProps,
  ISocialAuthControllerProps,
  ISocialAuthProps,
  ISocialAuthStateRefsValues
>(({ mapperProps, stateValues }) => {
  return {
    ...mapperProps,
    ...stateValues,
  };
});
