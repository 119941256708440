import { sdk as imageClientSdk } from '@wix/image-kit';
import { Image } from '@wix/thunderbolt-components-native';
import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getWidthInPixels } from '@wix/editor-elements-common-utils';
import {
  SeoTpaGalleryDefinition,
  SeoTpaGalleryProps,
  SeoTpaGalleryCSSVars,
  SeoTpaGalleryPropsCarmiData,
} from '../SeoTpaGallery.types';
import { generateCss } from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const IMG_TRANSFORM_TYPE = { transformType: 'fit', quality: 90 };
const getImageUrl = ({
  uri,
  width,
  height,
}: {
  uri: string;
  width: number;
  height: number;
}) =>
  imageClientSdk.getScaleToFillImageURL(
    uri,
    width,
    height,
    width,
    height,
    IMG_TRANSFORM_TYPE,
  );

/**
 * Will split a given array to multiple arrays.
 * example: ([1,2,3,4,5], 2) => [[1,2],[3,4],[5]]
 * @param data data to be split to rows
 * @param itemsPerRow maximum number of items per row
 */
const splitToRows = (data: Array<Partial<Image>>, itemsPerRow: number) =>
  Array.from({ length: Math.ceil(data.length / itemsPerRow) }, (v, i) =>
    data.slice(i * itemsPerRow, i * itemsPerRow + itemsPerRow),
  );

const IMG_SPACING = 20;
export const IMG_WIDTH = 250;
export const IMG_BOX = IMG_WIDTH + IMG_SPACING;

const props = withCompInfo<
  SeoTpaGalleryProps,
  SeoTpaGalleryDefinition,
  SeoTpaGalleryPropsCarmiData
>()(
  ['compProps', 'compLayout', 'compSingleLayout', 'isOneDocMigrated'],
  (
    { compProps, compLayout, compSingleLayout, isOneDocMigrated },
    carmiProps,
  ) => {
    const { images = [] } = carmiProps;
    const compWidth =
      (isOneDocMigrated
        ? getWidthInPixels(compSingleLayout)
        : compLayout.width) ?? IMG_BOX;
    const { type, textMode } = compProps;
    const renderTitle = ['titleAndDescription', 'titleOnly'].includes(textMode);
    const renderDescription = [
      'titleAndDescription',
      'descriptionOnly',
    ].includes(textMode);
    const imagesForSeo = images.map(image => {
      const {
        uri,
        width: imageWidth,
        height: imageHeight,
        title,
        description,
        alt,
        ...imageProps
      } = image;
      const sizeRatio = imageWidth / imageHeight;
      const width = IMG_WIDTH;
      const height = Math.floor(IMG_WIDTH / sizeRatio);
      return {
        ...imageProps,
        alt: alt || title,
        uri: getImageUrl({ uri, width, height }),
        width,
        height,
        ...(renderTitle && { title }),
        ...(renderDescription && { description }),
      };
    });
    const itemsPerRow = Math.max(Math.floor(compWidth / IMG_BOX), 1);
    return {
      type: type.replace('Properties', ''),
      images: splitToRows(imagesForSeo, itemsPerRow),
    };
  },
);

const css = generateCss<SeoTpaGalleryCSSVars, SeoTpaGalleryDefinition>();

export default createComponentMapperModel({ css, props });
