import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FooterContainerDefinition,
  FooterContainerCarmiProps,
  FooterContainerCSSVars,
  FooterSdkStyles,
} from '../FooterContainer.types';

export const props = withCompInfo<
  FooterContainerCarmiProps,
  FooterContainerDefinition,
  FooterContainerCarmiProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  FooterContainerCSSVars,
  FooterContainerDefinition,
  FooterContainerCSSVars
>()([], (_, carmiCss) => {
  return carmiCss;
});

export const sdkData = withCompInfo<
  FooterSdkStyles,
  FooterContainerDefinition,
  FooterSdkStyles
>()([], (_, carmiSdkData) => {
  return carmiSdkData;
});

export default createComponentMapperModel({ props, css, sdkData });
