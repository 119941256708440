import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import ThreeDeeScreen from '../../../../ScreenWidthContainer/viewer/skinComps/ThreeDeeScreen/ThreeDeeScreen';
import HeaderContainer from '../../HeaderContainer';

const ThreeDeeScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => (
  <HeaderContainer {...props} skin={ThreeDeeScreen}></HeaderContainer>
);

export default ThreeDeeScreenHeader;
