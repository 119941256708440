import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { props as viewerPropsMapper } from './LottieEmbed.mapper';

const props = composeMapper<PreviewWrapperProps<any>, any>()(
  viewerPropsMapper,
  { add: ['componentViewMode'] },
  (resolver, dependencies, carmiData) => {
    const { componentViewMode } = dependencies;
    const viewerProps = resolver(dependencies, carmiData);
    const autoplay =
      componentViewMode === 'preview' ? viewerProps.autoplay : true;
    const loop = componentViewMode === 'preview' ? viewerProps.loop : false;
    return {
      ...viewerProps,
      autoplay,
      loop,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
} as any);
