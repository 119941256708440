import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  FreemiumBannerMobileDefinition,
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileProps,
} from '../FreemiumBannerMobile.types';
import { props as viewerPropsMapper } from './FreemiumBannerMobile.mapper';
import { isExperimentOpen } from '@wix/editor-elements-common-utils';
import translationKeys from '../../FreemiumBannerDesktop/viewer/constants';
import { TranslationFallbacks, TranslationKeys } from './constants';

export const props = composeMapper<
  FreemiumBannerMobilePlatformProps,
  FreemiumBannerMobileDefinition
>()(
  viewerPropsMapper,
  { add: ['experiments', 'translate'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    const translations = (viewerProps as FreemiumBannerMobileProps)
      .translations;

    const { experiments, translate } = deps;

    const shouldShowNewBanner = isExperimentOpen(
      experiments,
      'specs.thunderbolt.WixFreeSiteBannerMobile',
    );

    return {
      ...viewerProps,
      shouldReportBi: false,
      translations: {
        ...translations,
        ...(shouldShowNewBanner && {
          genericAppBannerText:
            translate(
              translationKeys.NAMESPACE,
              TranslationKeys.genericAppNewBannerPreview,
            ) || TranslationFallbacks.genericAppNewBannerPreview,
        }),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
