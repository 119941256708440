import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { CompInfo } from '@wix/editor-elements-types/thunderbolt';
import type { LogicalAlignment } from '@wix/editor-elements-common-utils';
import {
  addUnitToEveryField,
  castFromInheritIfNeeded,
  convertFlexDirectionToCssFlexDirection,
  convertPhysicalAlignmentToLogical,
  convertPhysicalInputAlignmentToDirection,
  convertPhysicalInputAlignmentToLogical,
  getHeightInPixels,
  getInputHeight,
  getLabelPaddingsValues,
  getScaledFont,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import type {
  IFileUploaderCSSVars,
  IFileUploaderDefinition,
  IFileUploaderMapperProps,
  IFileUploaderStyleProperties,
} from '../FileUploader.types';
import {
  GeneralErrorTranslation,
  MediaServerErrorKeysTranslationMapping,
  MIN_LABEL_FONT_SIZE,
  TRANSLATION_FALLBACKS,
  TRANSLATION_KEYS,
  translationsNamespace,
} from '../constants';
import { getButtonLabelPaddingsValues, getFallbackIconPosition } from './utils';

const getTranslations = (
  translate: CompInfo<IFileUploaderDefinition>['translate'],
) => {
  const errors = Object.keys(MediaServerErrorKeysTranslationMapping).reduce<{
    [key: string]: string | undefined;
  }>((acc, key) => {
    const fullTranslationKey = `upload-button.${key}`;
    acc[fullTranslationKey] = translate(
      translationsNamespace,
      fullTranslationKey,
    );
    return acc;
  }, {});

  return {
    singleFileSelectedFile:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.SingleSelectedFile'],
      ) || TRANSLATION_FALLBACKS['FileUploader.SingleSelectedFile'],
    numberOfSelectedFiles:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.NumberOfSelectedFiles'],
      ) || TRANSLATION_FALLBACKS['FileUploader.NumberOfSelectedFiles'],
    exceededFilesLimit:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.ExceededFilesLimit'],
      ) ||
      TRANSLATION_FALLBACKS[
        'upload_button.validation_error.exceeded_files_limit'
      ],
    mobileClosePopper:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS.FileUploader_Mobile_ClosePopper,
      ) || TRANSLATION_FALLBACKS['mobile_popper.close'],
    previewFileName:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS.Upload_Button_Filename_Preview_Text_Filename,
      ) || TRANSLATION_FALLBACKS['FileUploader.NumberOfSelectedFiles'],
    multipleErrors:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.MultipleErrors'],
      ) ||
      TRANSLATION_FALLBACKS[
        'upload_button.validation_error.multiple_file_errors'
      ],
    singleError:
      translate(
        translationsNamespace,
        TRANSLATION_KEYS['FileUploader.SingleError'],
      ) ||
      TRANSLATION_FALLBACKS['upload_button.validation_error.single_file_error'],
    generalError:
      translate(translationsNamespace, GeneralErrorTranslation.key) ||
      GeneralErrorTranslation.fallback,
    ...errors,
  };
};

export const props = withCompInfo<
  IFileUploaderMapperProps,
  IFileUploaderDefinition,
  IFileUploaderMapperProps
>()(
  ['compId', 'isMobileView', 'translate'],
  ({ compId, isMobileView, translate }, carmiData) => {
    const translations = getTranslations(translate);
    return { ...carmiData, compId, isMobileView, translations };
  },
);

export const css = withCompInfo<
  IFileUploaderCSSVars,
  IFileUploaderDefinition,
  never,
  { styleProperties: IFileUploaderStyleProperties }
>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'compSingleLayout',
    'styleProperties',
    'siteFonts',
    'isMobileView',
    'hasResponsiveLayout',
    'isOneDocMigrated',
  ],
  compInfo => {
    const {
      compData,
      compProps,
      compLayout,
      compSingleLayout,
      isMobileView,
      hasResponsiveLayout,
      isOneDocMigrated,
      styleProperties,
    } = compInfo;

    const {
      filesDirection = 'inherit',
      direction = 'inherit',
      buttonLabelDirection = 'inherit',
      labelDirection = 'inherit',
      iconPosition = 'row',
      placeholderLabel,
      buttonLabel,
    } = compData;

    const {
      labelAlign,
      filesAlign,
      buttonLabelAlign = 'inherit',
      align = 'center',
      txtlblrq,
      labelPadding,
      buttonLabelPadding,
    } = styleProperties;

    const {
      showPlaceholder,
      showFileName = true,
      labelMargin,
      inputMobileFontSize,
      textBelowButtonMobileFontSize,
      labelMobileFontSize,
    } = compProps;

    const hidePlaceholder = !showPlaceholder || !placeholderLabel;
    const hideTextBelowButton = !showFileName && hidePlaceholder;
    const notTransparentText = txtlblrq && txtlblrq !== 'transparent';

    const buttonFont = getScaledFont(compInfo, 'btn_fnt', inputMobileFontSize);
    const fileFont = getScaledFont(
      compInfo,
      'file_fnt',
      textBelowButtonMobileFontSize,
    );
    const labelFont = getScaledFont(
      compInfo,
      'fntlbl',
      labelMobileFontSize,
      MIN_LABEL_FONT_SIZE,
    );

    const notResponsiveLayoutVars = !hasResponsiveLayout && {
      '--inputHeight': getInputHeight({
        inputHeightProps: compProps,
        height: isOneDocMigrated
          ? getHeightInPixels(compSingleLayout)
          : compLayout.height,
        isMobileView,
      }),
      ...(!isOneDocMigrated && {
        height: 'auto',
      }),
    };

    const calculatedLabelAlign = castFromInheritIfNeeded<LogicalAlignment>(
      labelAlign,
      align,
    );
    const calculatedButtonLabelAlign =
      castFromInheritIfNeeded<LogicalAlignment>(buttonLabelAlign, align);
    const calculatedFilesAlign = castFromInheritIfNeeded<LogicalAlignment>(
      filesAlign,
      align,
    );

    const labelPaddings = addUnitToEveryField(
      getLabelPaddingsValues({
        labelPadding,
        align: castFromInheritIfNeeded(labelAlign, align),
      }),
    );

    const buttonLabelPaddings = addUnitToEveryField(
      getButtonLabelPaddingsValues({
        align: castFromInheritIfNeeded(buttonLabelAlign, align),
        padding: buttonLabelPadding,
      }),
    );

    return {
      '--direction': direction,
      '--align': align,
      '--labelDirection': labelDirection,
      '--labelAlign': calculatedLabelAlign,
      '--buttonLabelDirection': buttonLabelDirection,
      '--buttonLabelFlexDirection':
        convertFlexDirectionToCssFlexDirection(iconPosition),
      '--buttonLabelAlign': calculatedButtonLabelAlign,
      '--filesDirection': filesDirection,
      '--filesAlign': calculatedFilesAlign,
      '--filesFlexDirection':
        calculatedFilesAlign === 'end' ? 'row-reverse' : 'row',
      '--filesJustifyContent':
        calculatedFilesAlign === 'center' ? 'center' : 'space-between',
      '--fileMarginStart': calculatedFilesAlign === 'center' ? '15px' : '0',
      '--fileMarginEnd': calculatedFilesAlign === 'center' ? '5px' : '0',
      '--buttonPadding': buttonLabel ? '8px' : '0',
      ...(hidePlaceholder && { '--filesPlaceholderVisibility': 'hidden' }),
      '--textBelowButtonDisplay': hideTextBelowButton ? 'none' : 'block',
      '--btn_fnt': buttonFont,
      '--file_fnt': fileFont,
      '--labelMarginBottom': `${labelMargin}px`,
      '--fntlbl': labelFont,
      '--requiredIndicationDisplay': notTransparentText ? 'inline' : 'none',
      ...labelPaddings,
      '--xIconMargin': '8px',
      '--tooltipIconMargin': '8px',
      ...notResponsiveLayoutVars,
      ...buttonLabelPaddings,
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'filesAlignment', target: 'labelAlign' }],
        enhancer: convertPhysicalInputAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'filesAlignment', target: 'labelDirection' }],
        enhancer: convertPhysicalInputAlignmentToDirection,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'filesAlignment', target: 'filesAlign' }],
        enhancer: convertPhysicalAlignmentToLogical,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'compData',
        fields: [{ source: 'buttonAlignment', target: 'iconPosition' }],
        enhancer: getFallbackIconPosition,
      },
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: ['labelPadding'],
      },
    ]),
  ],
);

const stateRefs = withStateRefsValues([
  'enableCyclicTabbing',
  'disableCyclicTabbing',
  'setSiteScrollingBlocked',
  'scopedClassName',
]);

export default createComponentMapperModel({ props, css, stateRefs });
