export const ViewerType = 'wixui.Tabs' as const;
export const PropertiesType = 'TabsProperties' as const;
export const TabItemClickedEventType = 'OnTabItemClicked' as const;

export const INITIAL_DEFAULT_TAB = 'FIRST_TAB' as const;
export const COMP_NAME = 'Tabs' as const;
export const TAB_REFERENCES_PROPERTY = 'tabReferences' as const;
export const SINGLE_TAB_REFERENCE_PROPERTY = 'tabReference' as const;

export enum Transition {
  None = 'none',
  Float = 'float',
  CrossFade = 'crossFade',
}

export enum CrossFadeDirection {
  In = 'fadeIn',
  Out = 'fadeOut',
}

export enum FloatTransitionDirection {
  FromTop = 'floatFromTop',
  FromBottom = 'floatFromBottom',
}

export const TranslationKeys = {
  gfppHelpId: 'help.gfpp-Tabs',
  manageItemsGfppAction: 'tabs_main_GFPP_Manage',
  settingsPanel: {
    title: 'tabs_settings_header',
    transitionEffectLabel: 'tabs_settings_transition_title',
    transitionEffectNone: 'tabs_settings_transition_label_none',
    transitionEffectCrossFade: 'tabs_settings_transition_label_cross_fade',
    transitionEffectFloat: 'tabs_settings_transition_label_slide',
    transitionEffectFloatFromTop: 'tabs_settings_direction_selection_ltr',
    transitionEffectFloatFromBottom: 'tabs_settings_direction_selection_rtl',
    transitionEffectDirection: 'tabs_settings_direction_title',
    transitionEffectDuration: 'tabs_settings_duration_title',
  },
} as const;

export const Skins = {
  containerDefaultSkin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
} as const;

export const TestIds = {
  AddPanel: 'tabs-add-panel-section',
  ManageItemsAction: 'tabs-manage-items-panel-gfpp-action',
  ManageItemsPanelContainer: 'tabs-manage-items-panel-container',
  SortDragList: 'SortDragList',
  AddTabButton: 'AddTabButton',
  DuplicateTabButton: 'DuplicateTabButton',
  EditTabNameMenuItem: 'EditTabNameMenuItem',
  DuplicateTabMenuItem: 'DuplicateTabMenuItem',
  DeleteTabMenuItem: 'DeleteTabMenuItem',
  TabItem: 'tab-item',
  TabItemLabel: 'tab-item-label',
  ForwardScrollBtn: 'ForwardScrollBtn',
  BackwardScrollBtn: 'BackwardScrollBtn',
  TabsList: 'TabsListWrapper',
  ActiveTabItem: 'ActiveTabItem',
  TabPanel: 'TabPanel',
  TransitionEffect: 'tabs-settings-panel-transition-effect',
  TransitionDurationSlider: 'tabs-settings-panel-transition-duration-slider',
  TransitionDirectionDropDown:
    'tabs-settings-panel-transition-direction-dropdown',
} as const;

export const selectors = {
  root: '.root',
  tabsList: '.root::tabsList',
  tabsListItem: '.root::tabsList::tab',
  tabLabel: '.root::tabsList::label',
  scrollButton: '.root::tabsList::scrollButton',
  scrollButtonIcon: '.root::tabsList::scrollButton::icon',
  hoveredScrollButtonIcon: '.root::tabsList::scrollButton:hover::icon',
  tabContainer: '.root::multiStateBoxWrapper',
};

export enum MenuMode {
  wrap = 'wrap',
  scroll = 'scroll',
}

export const contentAlignmentMap = {
  ltr: {
    left: 'flex-start',
    center: 'center',
    right: 'flex-end',
  },
  rtl: {
    left: 'flex-end',
    center: 'center',
    right: 'flex-start',
  },
} as const;

export enum Experiments {
  ClassicLayoutPanel = 'specs.santa-editor.eeTabsLayoutPanel',
  ResponsiveLayoutPanel = 'specs.responsive-editor.eeTabsLayoutPanel',
}

export enum HelpIds {
  GFPP = '500d715e-b9dd-450f-a64c-f9907ae997a5',
  GFPP_RESPONSIVE = 'component-editors.wixui.Tabs_help',
  GFPP_BLOCKS = 'component-editors.wixui.blocks-Tabs_help',
  LAYOUT_PANEL = '11aeec36-22ae-4b82-a816-b33f075ce3f1',
  LAYOUT_PANEL_BLOCKS = 'e7c5e8ea-67f3-4902-98fa-733c585dc486',
  MANAGE_PANEL = 'ab523039-9661-4fa0-a551-8b8221df0ae2',
  MANAGE_PANEL_BLOCKS = 'b15e1bae-51a3-4f9c-8472-4a7c46b962f8',
}

export enum AnimationStyleVars {
  DURATION = '--tab-animation-duration',
  ENTER = '--tab-enter-animation-name',
  EXIT = '--tab-exit-animation-name',
}
