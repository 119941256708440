import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  generatePreviewStateDataMap,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';

import type {
  SubmenuDefinition,
  SubmenuCarmiProps,
  SubmenuPreviewMapperProps,
} from '../types';
import { props as viewerPropsMapper } from './Submenu.mapper';

const props = composeMapper<
  PreviewWrapperProps<SubmenuPreviewMapperProps>,
  SubmenuDefinition,
  SubmenuCarmiProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;
    const previewStateMap = generatePreviewStateDataMap(compPreviewState);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: { compPreviewState },
      previewStateMap,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
