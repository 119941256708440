import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { usePreviewState } from '@wix/editor-elements-preview-utils';
import type { IMenuToggleProps } from '../MenuToggle.types';

export default (ViewerComponent: React.ComponentType<IMenuToggleProps>) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps = {},
    ...viewerProps
  }: PreviewWrapperProps<
    IMenuToggleProps,
    {
      compPreviewState?: string | null;
    }
  >) {
    usePreviewState(viewerProps.id, previewWrapperProps.compPreviewState);
    return <ViewerComponent {...viewerProps} />;
  });
