export const namespace = 'Wix_Ads';
export const logoPlaceHolder = '{X}';
export const texts = {
  responsive: {
    title: {
      translationKey: 'responsive_banner_text',
      defaultText: 'Created on {X}',
    },
    logoText: {
      translationKey: 'responsive_banner_alt_text_EditorX',
      defaultText: 'Editor X',
    },
    campaignLink: `//editorx.com?utm_campaign=vir_editorx_wixad_live`,
  },
  studio: {
    title: {
      translationKey: 'studio_banner_text',
      defaultText: 'Made with {X}',
    },
    logoText: {
      translationKey: 'studio_banner_alt_text_WixStudio',
      defaultText: 'Wix Studio',
    },
    campaignLink: `//wix.com/studio`,
  },
} as const;
