import type { SendBIFunction } from '@wix/editor-elements-types/editor';
import type { DropDownProps } from '@wix/wix-base-ui';
import createBIWrapper from '../../bi/createBIWrapper';

export enum TagValue {
  P = 'p',
  DIV = 'div',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
  BLOCKQUOTE = 'blockquote',
}

export const TranslationKeys = {
  headingLabel: 'text_editor_seo_accessibility_label',
  dropdownLabel: 'text_editor_tag_label',
};

export const testId = 'seo-and-a11y-section';

export interface SEOAndAccessibilityPanelSectionProps {
  dropdownData: DropDownProps<string>;
  showAccordion: boolean;
  isEditorX: boolean;
  sendBi: SendBIFunction;
}

export const seoAndA11yPanelBI = (isEditorX: boolean) => {
  const createComponentBIEvent = createBIWrapper(isEditorX);

  return {
    htmlTagChangeClicked: createComponentBIEvent({ classicEvid: 1282 }),
    htmlTagChanged: createComponentBIEvent({
      editorXEvid: 1206,
      classicEvid: 1283,
      params: {
        target: 'string',
        old_value: 'string',
      },
    }),
  };
};
