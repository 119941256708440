import type { ExpandableMenuItem } from '../../ExpandableMenu.types';

export const forceOpenMenu = (
  items: Array<ExpandableMenuItem>,
): Array<ExpandableMenuItem> => {
  const itemWithItems = items.find(item => item.items?.length);

  return items.map(item =>
    item === itemWithItems
      ? {
          ...item,
          isForceOpened: true,
        }
      : item,
  );
};
