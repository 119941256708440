
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "RichContentViewer2447840476",
      {"classes":{"root":"RichContentViewer2447840476__root"},"vars":{"display":"--display"},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  