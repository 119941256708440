import * as React from 'react';
import { FreemiumBannerResponsiveProps } from '../FreemiumBannerResponsive.types';
import { EditorXLogo } from './assets/editorXLogo';
import FreemiumBannerResponsiveCommon from './FreemiumBannerResponsiveCommon';
import style from './style/FreemiumBannerResponsive.scss';

const FreemiumBannerResponsive: React.FC<
  FreemiumBannerResponsiveProps
> = props => (
  <FreemiumBannerResponsiveCommon {...props} styles={style}>
    <EditorXLogo className={style.logo} logoText={props.logoText} />
  </FreemiumBannerResponsiveCommon>
);
export default FreemiumBannerResponsive;
