import { withCompController } from '@wix/editor-elements-integrations/src/thunderbolt';
import type {
  VideoLegacyProps,
  IVideoLegacyProps,
  IVideoLegacyStateRefValues,
  VideoLegacyControllerProps,
} from '../VideoLegacy.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

export default withCompController<
  VideoLegacyProps,
  VideoLegacyControllerProps,
  IVideoLegacyProps,
  IVideoLegacyStateRefValues
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});
