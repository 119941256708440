import * as React from 'react';
import type {
  IPaginatedGalleryImperativeActions,
  IPaginatedGridGalleryProps,
} from '../../../PaginatedGridGallery.types';
import PaginatedGridGallery from '../../PaginatedGridGallery';
import skinsItemStyle from '../../../../PaginatedGridGalleryItem/viewer/style/PaginatedGridRibbonArrows.scss';
import skinsStyle from './style/PaginatedGridRibbonArrows.scss';

const PaginatedGridRibbonArrows: React.ForwardRefRenderFunction<
  IPaginatedGalleryImperativeActions,
  Omit<IPaginatedGridGalleryProps, 'skin'>
> = (props, ref) => {
  return (
    <PaginatedGridGallery
      {...props}
      ref={ref}
      skinsStyle={skinsStyle}
      skinsItemStyle={skinsItemStyle}
    />
  );
};

export default React.forwardRef(PaginatedGridRibbonArrows);
