import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';

import { LoginButtonDefinition, LoginButtonProps } from '../LoginButton.types';
import { props as viewerPropsMapper } from './LoginButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<LoginButtonProps>,
  LoginButtonDefinition,
  LoginButtonProps
>()(
  viewerPropsMapper,
  {
    remove: ['compId', 'language', 'fullNameCompType', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
