import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import DefaultWithFillLayers from '../../../../ScreenWidthContainer/viewer/skinComps/DefaultWithFillLayers/DefaultWithFillLayers';
import FooterContainer from '../../FooterContainer';

const DefaultWithFillLayersFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => (
  <FooterContainer {...props} skin={DefaultWithFillLayers}></FooterContainer>
);

export default DefaultWithFillLayersFooter;
