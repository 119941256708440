import React from 'react';
import { IconButton } from 'wix-ui-tpa/cssVars';
import { Close } from '@wix/wix-ui-icons-common/on-stage';
import {
  TestIds,
  TranslationKeys,
  TranslationsFallback,
} from '../../constants';
import {
  PayPalButtonMapperProps,
  ViewerTranslateType,
} from '../../PayPalButton.types';
import { classes as dialogClasses } from '../style/Dialog.st.css';
import { classes, st } from '../style/ErrorDialog.st.css';
import Dialog from './Dialog';

type ErrorDialogProps = {
  isOpen: boolean;
  isMobileView: PayPalButtonMapperProps['isMobileView'];
  direction: PayPalButtonMapperProps['direction'];
  translate: ViewerTranslateType;
  onClose(): void;
};

const ErrorDialog: React.FC<ErrorDialogProps> = props => {
  const { isOpen, isMobileView, direction, onClose, translate } = props;

  return (
    <Dialog
      isMobileView={isMobileView}
      isOpen={isOpen}
      onClose={onClose}
      className={classes.root}
    >
      <div
        dir={direction}
        className={st(dialogClasses.box, classes.errorModalBox)}
        data-testid={TestIds.errorModal}
      >
        <IconButton
          className={dialogClasses.closeButton}
          icon={<Close />}
          onClick={() => onClose()}
        />
        <div className={dialogClasses.boxContent}>
          <div className={st(dialogClasses.title, classes.errorModalTextTitle)}>
            {translate(
              TranslationKeys.errorDialog.title,
              TranslationsFallback.errorDialog.title,
            )}
          </div>
          <div>
            {translate(
              TranslationKeys.errorDialog.subTitle,
              TranslationsFallback.errorDialog.subTitle,
            )}
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default ErrorDialog;
