import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  SingleAudioPlayerDefinition,
  SingleAudioPlayerProps,
  SingleAudioPlayerCSSVars,
} from '../SingleAudioPlayer.types';
import { SKIN_HEIGHT_MAP } from './constants';

const staticAudioUrl = 'https://music.wixstatic.com/mp3'; // TODO add to topology

export const props = withCompInfo<
  SingleAudioPlayerProps,
  SingleAudioPlayerDefinition
>()(['compData', 'compProps'], ({ compData, compProps }) => {
  const { volume, autoplay: autoPlay, loop } = compProps;
  const { uri, originalFileName, track, artist } = compData;
  const playlist = [
    {
      url: `${staticAudioUrl}/${uri}`,
      trackName: track,
      artistName: artist,
    },
  ];
  const ariaLabel = `Application, Audio player, ${track}`;

  return {
    volume: volume / 100,
    autoPlay,
    loop,
    originalFileName,
    playlist,
    ariaLabel,
  };
});

export const css = withCompInfo<
  SingleAudioPlayerCSSVars,
  SingleAudioPlayerDefinition
>()(['isMobileView', 'skin'], ({ isMobileView, skin }) => {
  const view = isMobileView ? 'mobile' : 'desktop';
  // NOTE: there are legacy sites which have "MusicPlayer" skin. Skin is not supported, an empty "div" will be rendered.
  if (typeof SKIN_HEIGHT_MAP[skin] !== 'undefined') {
    return { height: SKIN_HEIGHT_MAP[skin][view] };
  }
  return {};
});

export default createComponentMapperModel({ props, css });
