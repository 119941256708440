import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  usePreviewState,
  useResetComponent,
} from '@wix/editor-elements-preview-utils';
import classNames from 'clsx';
import {
  IHeaderContainerProps,
  HeaderContainerPreviewWrapperProps,
} from '../HeaderContainer.types';
import previewStyles from './styles/previewStyles.scss';

export default (ViewerComponent: React.ComponentType<IHeaderContainerProps>) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps = {},
    ...viewerProps
  }: PreviewWrapperProps<
    IHeaderContainerProps,
    HeaderContainerPreviewWrapperProps
  >) {
    const {
      componentViewMode,
      compPreviewState,
      shouldResetComponent,
      fixedPosition,
    } = previewWrapperProps;
    const key = useResetComponent({
      shouldResetComponent,
      id: viewerProps.id,
    });
    usePreviewState(viewerProps.id, compPreviewState);
    return (
      <ViewerComponent
        {...viewerProps}
        key={key}
        className={classNames({
          [previewStyles.fixed]:
            componentViewMode !== 'editor' && fixedPosition,
        })}
        animations={
          componentViewMode === 'editor' ? [] : viewerProps.animations
        }
      />
    );
  });
