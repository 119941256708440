import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  IMenuCarmiData,
  IMenuCssVars,
  IMenuDefinition,
  IMenuProperties,
  IMenuStateRefs,
} from '../Menu.types';
import {
  orientationToCssVarsMap,
  overflowToCssVarsMap,
  stretchedCssVars,
  notStretchedCssVars,
} from './utils/MapperUtils';
import type { IMenuSDKData } from '@wix/editor-elements-corvid-utils/src/props-factories/menuUtils';
import { MenuOverflow, MenuOrientation } from '../constants';

export const props = withCompInfo<
  IMenuProperties,
  IMenuDefinition,
  IMenuCarmiData
>()(['compData'], ({ compData }, carmiData) => {
  return {
    ...carmiData,
    ...compData,
  };
});

export const css = withCompInfo<IMenuCssVars, IMenuDefinition>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const {
      orientation = MenuOrientation.Horizontal,
      isContainerStretched = true,
      overflow = MenuOverflow.Scroll,
      'scroll-dropdown-icon-display': showDropDownIcon = 'none',
      addDividers = false,
      'scroll-button-display': scrollButtonDisplay = 'initial',
    } = styleProperties;

    return {
      height: 'auto',
      ...(isContainerStretched ? stretchedCssVars : notStretchedCssVars),
      ...orientationToCssVarsMap[
        orientation as keyof typeof orientationToCssVarsMap
      ],
      ...overflowToCssVarsMap[overflow as keyof typeof overflowToCssVarsMap][
        orientation as keyof typeof orientationToCssVarsMap
      ],
      '--dropdown-arrow-display':
        showDropDownIcon !== 'none' &&
        orientation === MenuOrientation.Horizontal
          ? 'block'
          : 'none',
      '--divider-display': addDividers ? 'block' : 'none',
      '--scroll-button-display':
        scrollButtonDisplay === 'initial' ? 'flex' : scrollButtonDisplay,
    };
  },
);

export const sdkData = withCompInfo<IMenuSDKData, IMenuDefinition>()(
  ['pagesMap'],
  ({ pagesMap }) => {
    return {
      pageList: pagesMap,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof IMenuStateRefs>([
  'currentUrl',
]);

export default createComponentMapperModel({ css, props, stateRefs, sdkData });
