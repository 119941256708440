import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { labelText } from '../constants';

export const props = withCompInfo()(
  ['compStylableClass', 'styleProperties'],
  ({ compStylableClass }) => {
    return {
      label: labelText.mapper,
      stylableClassName: compStylableClass,
    };
  },
);

export const uiType = withCompInfo<any, any>()(
  ['styleProperties'],
  ({ styleProperties }) => {
    const hasStCSS = styleProperties['st-css'] !== undefined;
    if (!hasStCSS) {
      return 'TestComp';
    }
    return 'Default';
  },
);

export default createComponentMapperModel({
  props,
  uiType,
});
