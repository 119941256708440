import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type { IPopupCloseIconButtonProps } from '../PopupCloseIconButton.types';
import {
  A11Y__LABEL_TRANS_KEY,
  A11Y_LABEL_TRANS_DEFAULT_VAL,
  A11Y_LABEL_TRANS_NAMESPACE,
} from '../constants';

export const props = withCompInfo<
  IPopupCloseIconButtonProps,
  any,
  IPopupCloseIconButtonProps
>()(['translate'], ({ translate }, carmiProps) => {
  return {
    ...carmiProps,
    translations: {
      a11yLabel:
        translate(A11Y_LABEL_TRANS_NAMESPACE, A11Y__LABEL_TRANS_KEY) ||
        A11Y_LABEL_TRANS_DEFAULT_VAL,
    },
  };
});

export default createComponentMapperModel({ props });
