import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  PinterestFollowDefinition,
  PinterestFollowProps,
} from '@wix/thunderbolt-components';
import { IPinterestFollowStateValues } from '../PinterestFollow.types';

export const props = withCompInfo<
  PinterestFollowProps,
  PinterestFollowDefinition,
  PinterestFollowProps
>()(
  ['fullNameCompType', 'language', 'compId', 'trackClicksAnalytics'],
  (compProps, carmiData) => {
    return {
      ...carmiData,
      ...compProps,
    };
  },
);

const stateRefs = withStateRefsValues<keyof IPinterestFollowStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({
  props,
  stateRefs,
});
