import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { ImageProps } from '@wix/thunderbolt-components-native';

import {
  FlashComponentDefinition,
  FlashComponentProps,
} from '../FlashComponent.types';

const props = withCompInfo<FlashComponentProps, FlashComponentDefinition>()(
  ['compData', 'compProps', 'topology'],
  ({ compData, compProps, topology }) => {
    const { uri, placeHolderImage } = compData;
    const placeholderImage: Partial<ImageProps> = { ...placeHolderImage };
    placeholderImage.displayMode =
      compProps.displayMode === 'stretch' ? 'fill' : 'fit';
    return {
      uri: uri ? `${topology.mediaRootUrl}media/${uri}` : undefined,
      placeholderImage,
      // TODO: Change when correct type is added to TB
      link: compData.link ? (compData.link as any).resolvedLink : null,
    };
  },
);

export default createComponentMapperModel({ props });
