import * as React from 'react';
import cn from 'clsx';
import ResponsiveContainer from '@wix/thunderbolt-elements/components/ResponsiveContainer';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import type { IAccordionItemResponsiveProps } from '../../../types';

const AccordionItemResponsive: React.FC<
  IAccordionItemResponsiveProps
> = props => {
  const { id, children, containerProps, containerRootClassName, className } =
    props;

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={cn(containerRootClassName, className)}
    >
      <ResponsiveContainer {...containerProps}>{children}</ResponsiveContainer>
    </div>
  );
};

export default AccordionItemResponsive;
