export const A11Y_TITLE = 'Spotify Player';
export const TRANSLATION_FEATURE = 'AriaLabels';
export const TRANSLATION_KEY = 'SpotifyPlayer_AriaLabel_TopLevel';

export const consetPolicyKeys = {
  componentNameKey: 'CookieConsent_Dataset_Component_SpotifyPlayer',
  componentNameDefault: 'Spotify Player',
  companyNameKey: 'CookieConsent_Dataset_Company_Spotify',
  companyNameDefault: 'Spotify',
};
