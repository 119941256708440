import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import type {
  CarouselDefinition,
  TPA3DCarouselPropsPlatformProps,
  CarouselPropsCarmiData,
  CarouselCSSVars,
} from '../TPA3DCarousel.types';
import {
  generateCss,
  generateSdkData,
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';
import { getUrl } from '../../../tpaGallery';
import carouselHtml from './assets/Carousel.html?resource';

export const props = withCompInfo<
  TPA3DCarouselPropsPlatformProps,
  CarouselDefinition,
  CarouselPropsCarmiData
>()([...propsDependencies], (data, carmiProps) => {
  const { compId, viewMode, language } = data;

  const overrideHtmlUrl = new URL(
    getUrl(carouselHtml, compId, viewMode, language),
  );
  overrideHtmlUrl.searchParams.append('imageSizingStrategy', 'fit');

  const resolvedProps = propsResolver<CarouselDefinition>(
    data,
    carmiProps,
    'Carousel/Carousel.html',
  );
  return {
    ...resolvedProps,
    url: overrideHtmlUrl.toString(),
    currentIndex: 0,
  };
});

const sdkData = generateSdkData<CarouselDefinition>();

const css = generateCss<CarouselCSSVars, CarouselDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
