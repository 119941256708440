import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getSlideShowContainerTranslations } from '../../SlideShowContainer/viewer/utils';
import type {
  StateBoxDefinition,
  IStateBoxMapperProps,
  StateBoxCSSVars,
} from '../StateBox.types';

export const props = withCompInfo<IStateBoxMapperProps, StateBoxDefinition>()(
  ['compProps', 'skin', 'translate', 'isMasterPage'],
  ({ compProps, skin, translate, isMasterPage }) => {
    return {
      skin,
      hasShadowLayer: true,
      currentSlideIndex: 0,
      transitionDuration: compProps.transDuration * 1000,
      transition: compProps.transition,
      isMasterPage,
      ...getSlideShowContainerTranslations(translate),
    };
  },
);

export const css = withCompInfo<StateBoxCSSVars, StateBoxDefinition>()(
  ['compProps'],
  ({ compProps }) => {
    const { transDuration } = compProps;
    return {
      height: 'auto',
      '--transition-duration': `${transDuration * 1000}ms`,
      '--transition-overflow': 'hidden',
      '--slides-overflow': 'visible',
      '--nav-dot-section-display': 'none',
      '--nav-button-display': 'none',
    };
  },
);

export const stateRefs = withStateRefsValues([
  'observeChildListChange',
  'observeChildListChangeMaster',
]);

export default createComponentMapperModel({ props, css, stateRefs });
