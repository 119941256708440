import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { withComponentPreview } from '@wix/editor-elements-preview-utils';
import React from 'react';
import { IPayPalButtonProps } from '../PayPalButton.types';

const withCustomUrl =
  (WrappedComponent: React.ComponentType<IPayPalButtonProps>) =>
  (props: IPayPalButtonProps) =>
    <WrappedComponent {...props} currentUrl="https://www.example.com" />;

export default (ViewerComponent: React.ComponentType<IPayPalButtonProps>) =>
  createComponentPreviewEntry(
    withComponentPreview(withCustomUrl(ViewerComponent)),
  );
