/* This file should be kept in sync between editor-elements and santa-editor codebases */
export const fontDefaults: Record<
  string,
  {
    familyFrom: `font_${0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8}`;
    size: number;
    mobileSize: number;
  }
> = {
  quote: {
    familyFrom: 'font_8',
    size: 16,
    mobileSize: 16,
  },
  p: {
    familyFrom: 'font_8',
    size: 16,
    mobileSize: 14,
  },
  h1: {
    familyFrom: 'font_0',
    size: 40,
    mobileSize: 28,
  },
  h2: {
    familyFrom: 'font_2',
    size: 32,
    mobileSize: 24,
  },
  h3: {
    familyFrom: 'font_3',
    size: 24,
    mobileSize: 20,
  },
  h4: {
    familyFrom: 'font_4',
    size: 20,
    mobileSize: 18,
  },
  h5: {
    familyFrom: 'font_5',
    size: 18,
    mobileSize: 16,
  },
  h6: {
    familyFrom: 'font_6',
    size: 16,
    mobileSize: 14,
  },
};

export const styleParamDefaults = {
  font: fontDefaults,
  fontColor: 'color_15',
  border: {
    color: 'color_15',
    alpha: 0.7,
  },
  background: {
    color: 'color_11',
    alpha: 1,
  },
  padding: {
    vertical: 0,
    horizontal: 0,
  },
  linkColor: 'color_18',
} as const;
