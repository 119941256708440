import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { LinkBarDefinition } from '@wix/thunderbolt-components/dist/components/LinkBar/LinkBar';
import { LinkBarCarmiProps, LinkBarMapperPropsNoStyle } from '../LinkBar.types';
import { props as viewerPropsMapper } from './LinkBar.mapper';

const props = composeMapper<
  PreviewWrapperProps<LinkBarMapperPropsNoStyle>,
  LinkBarDefinition,
  LinkBarCarmiProps
>()(
  viewerPropsMapper,
  {
    remove: ['compId', 'fullNameCompType', 'language', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
