import React from 'react';
import Preloader from '@wix/thunderbolt-elements/src/components/Preloader/viewer/Preloader';
import AdminIcon from '../../../../../assets/ExternalComponent/AdminAccess.svg';
import ErrorDefaultIcon from '../../../../../assets/ExternalComponent/Icon_Error.svg';
import type {
  ExternalComponentErrorType,
  IExternalComponentProps,
} from '../../../ExternalComponent.types';
import type { iconOptions } from './constants';
import {
  ALERT_STROKE_COLOR,
  DEFAULT_ERROR,
  DEFAULT_STROKE_COLOR,
  errorTypeToRenderParams,
  skinsOptions,
} from './constants';
import { classes } from './OnStageError.st.css';
import ErrorMessage from './ErrorMessage';

const ErrorOnStage: React.FC<{
  error: IExternalComponentProps['error'];
  translations: IExternalComponentProps['translations'];
}> = props => {
  const { error, translations } = props;
  const {
    key,
    skin,
    icon,
  }: {
    key: string | Array<string>;
    skin: skinsOptions;
    icon: iconOptions;
  } = errorTypeToRenderParams[error?.name as ExternalComponentErrorType] || {
    key: DEFAULT_ERROR,
    skin: skinsOptions.default,
  };

  const iconToRender: Record<keyof typeof iconOptions, React.ReactNode> = {
    error: (
      <ErrorDefaultIcon
        stroke={skin === 'alert' ? ALERT_STROKE_COLOR : DEFAULT_STROKE_COLOR}
      />
    ),
    admin: <AdminIcon />,
    loader: <Preloader enabled={true} />,
  };
  return (
    <div className={classes.root}>
      <div className={classes.errorIcon}>{iconToRender[`${icon}`]}</div>
      <ErrorMessage translations={translations} translationKey={key} />
    </div>
  );
};

export default ErrorOnStage;
