import * as React from 'react';
import classnames from 'clsx';

import {
  SuggestionListProps,
  SuggestionRenderItemProps,
} from '../Suggestion.types';
import {
  SearchSuggestionGroup,
  SearchSuggestionItem,
} from '../../../SearchBox.types';
import { Item } from './item/Item';
import { Group } from './group/Group';

import style from './SuggestionList.scss';

export const SuggestionList: React.FC<SuggestionListProps> = ({
  scrollIntoRef,
  suggestions,
}) => {
  const renderItem = ({
    action,
    id,
    isActive,
    item,
    testId,
    type,
  }: SuggestionRenderItemProps) => {
    return (
      <div
        aria-selected={isActive}
        className={classnames({
          [style.selectable]: !!item.url,
        })}
        data-testid={testId}
        key={id}
        ref={isActive ? scrollIntoRef : undefined}
        role="option"
      >
        {type === 'item' ? (
          <Item action={action} id={id} item={item as SearchSuggestionItem} />
        ) : (
          <Group action={action} id={id} item={item as SearchSuggestionGroup} />
        )}
      </div>
    );
  };
  return (
    <div
      className={classnames(style.root, {
        [style.withoutFooter]: !suggestions?.footer,
      })}
      role="listbox"
    >
      {suggestions?.items.map(renderItem)}
    </div>
  );
};
