import * as React from 'react';
import {
  ITimePickerProps,
  ITimePickerImperativeActions,
} from '../TimePicker.types';
import TimePickerBase from './TimePickerBase';

import TimePickerDropdownBase from './skinComps/TimePickerDropdown/TimePickerDropdownBase';
import TimePickerStepperBase from './skinComps/TimePickerStepper/TimePickerStepperBase';

const TimePicker: React.ForwardRefRenderFunction<
  ITimePickerImperativeActions,
  ITimePickerProps
> = (props, ref) => {
  const { controller } = props;

  const ControllerComponent =
    controller === 'dropdown' ? TimePickerDropdownBase : TimePickerStepperBase;

  return (
    <TimePickerBase
      {...props}
      ControllerComponent={ControllerComponent}
      ref={ref}
    ></TimePickerBase>
  );
};

export default React.forwardRef(TimePicker);
