import React from 'react';
import { TestIds } from '../../constants';
import { st, classes } from './RatingIcons.component.st.css';

const Icon = React.memo<{
  svgString: string;
  checked: boolean;
}>(({ svgString, checked }) => (
  <span className={st(classes.icon, { checked })}>
    <div dangerouslySetInnerHTML={{ __html: svgString || '' }} />
  </span>
));

const RatingIcons: React.ForwardRefRenderFunction<
  HTMLElement,
  {
    rating?: number | null;
    checkedThreshold: number;
    svgString: string;
  }
> = ({ rating, checkedThreshold, svgString }, ref) => {
  const shouldSplitIcon = React.useCallback(
    (value: number) => {
      const lastCheckedIcon =
        value <= checkedThreshold && value + 1 > checkedThreshold;
      const decimal = (rating || 0) % 1;
      return lastCheckedIcon && decimal >= 0.25 && decimal < 0.75;
    },
    [rating, checkedThreshold],
  );

  return (
    <span className={classes.icons} data-testid={TestIds.ratingIcons} ref={ref}>
      {[1, 2, 3, 4, 5].map(value => (
        <div
          key={value}
          className={classes.iconWrapper}
          aria-hidden="true"
          aria-checked={value <= checkedThreshold}
        >
          {shouldSplitIcon(value) ? (
            <span
              className={classes.icon}
              data-testid={`${TestIds.splitIcon}-${value}`}
            >
              <Icon checked={false} svgString={svgString} />
              <div className={classes.halfIcon}>
                <Icon checked={true} svgString={svgString} />
              </div>
            </span>
          ) : (
            <Icon checked={value <= checkedThreshold} svgString={svgString} />
          )}
        </div>
      ))}
    </span>
  );
};

export default React.forwardRef(RatingIcons);
