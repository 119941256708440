import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  PreviewTooltipProps,
  IComponentCustomMeasureImperativeAPI,
} from '@wix/thunderbolt-becky-types';
import {
  BlockingLayer,
  useCustomMeasuresDependency,
} from '@wix/editor-elements-preview-utils';
import { QuickActionBarProps } from '../QuickActionBar.types';
import { getDynamicActionId } from './QuickActionBar';
import { ACTION_CONTAINER, MAX_TEXT_WIDTH } from './constants';

export type QuickActionBarPreviewProps = QuickActionBarProps &
  PreviewTooltipProps;

type ElementMeasure = 'clientWidth' | 'clientHeight';

function getMaxLabelTextWidth(id: string) {
  const actionLabelElements = Array.from(
    document
      .getElementById(id)
      ?.querySelectorAll('div[data-quick-action="text"]') || [],
  );
  return Math.max(
    ...actionLabelElements.map(el => {
      const textNodeWidth = el.getBoundingClientRect().width;
      return Number.isNaN(textNodeWidth) ? 0 : textNodeWidth;
    }),
  );
}
const getElementMeasure = (id: string, measure: ElementMeasure) => {
  const element = document.getElementById(id);
  return element ? element[measure] : 0;
};

function withComponentPreview(
  WrappedComponent: React.ComponentType<QuickActionBarProps>,
): React.ForwardRefRenderFunction<
  IComponentCustomMeasureImperativeAPI,
  QuickActionBarPreviewProps
> {
  return (
    {
      previewWrapperProps = {},
      ...viewerProps
    }: PreviewWrapperProps<
      QuickActionBarPreviewProps,
      { compPreviewState?: string }
    >,
    ref,
  ) => {
    const { showTooltip, id, hideText } = viewerProps;
    const { compPreviewState } = previewWrapperProps;
    const isOpen = compPreviewState === 'open';

    const dynamicActions = viewerProps.dynamicActions || [];
    const dynamicActionIds = dynamicActions.map((_, index) =>
      getDynamicActionId(id, index),
    );

    const compPreviewStateRef = useCustomMeasuresDependency(
      previewWrapperProps.compPreviewState,
    );

    const isActionsContainerExpanded = () =>
      compPreviewStateRef.current === 'open';

    const getActionsContainerMeasure = (measure: ElementMeasure) => {
      // if actions container is not expanded (hidden), default to root component measures
      const elementToBeMeasured = isActionsContainerExpanded()
        ? `${id}${ACTION_CONTAINER}`
        : id;
      return getElementMeasure(elementToBeMeasured, measure);
    };

    const getActionLabelsMaxTextWidth = () => {
      return !hideText && isActionsContainerExpanded()
        ? getMaxLabelTextWidth(id)
        : 0;
    };

    React.useImperativeHandle(ref, () => {
      return {
        getCustomMeasures: () => {
          return {
            height: {
              [`${id}${ACTION_CONTAINER}`]: () =>
                getActionsContainerMeasure('clientHeight'),
            },
            width: {
              [`${id}${ACTION_CONTAINER}`]: () =>
                getActionsContainerMeasure('clientWidth'),
              [`${id}${MAX_TEXT_WIDTH}`]: getActionLabelsMaxTextWidth,
            },
          };
        },
      };
    });

    return (
      <>
        <WrappedComponent
          {...viewerProps}
          // isOpen prop should be overridden only in Editor when previewState value is set to 'open'. Otherwise "isOpen" prop value from controller should be used
          {...(isOpen && { isOpen })}
        />
        {/* Clicks on main actions are handled by the viewer (blocked if the link leads outside page), but clicks dynamic actions should be blocked manually */}
        {dynamicActionIds.map(actionId => (
          <BlockingLayer
            targetId={actionId}
            key={+new Date()} // without forced re-render BlockingLayer disappears after QAB re-renders
            onClick={ev => {
              showTooltip(
                ev.currentTarget.getBoundingClientRect(),
                'PREVIEW_TOOLTIP_GOTO_LIVE_SITE',
              );
            }}
          />
        ))}
      </>
    );
  };
}

export default (ViewerComponent: React.ComponentType<QuickActionBarProps>) =>
  createComponentPreviewEntry(
    React.forwardRef(withComponentPreview(ViewerComponent)),
  );
