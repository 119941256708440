import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { withComponentPreview as withComponentPreviewFromPreviewUtils } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type { IComponentCustomMeasureImperativeAPI } from '@wix/thunderbolt-becky-types';
import { reduce } from 'lodash';
import React from 'react';
import type {
  IWRichTextProps,
  WRichTextPreviewWrapperProps,
} from '../WRichText.types';
import { useResizeObserverForRichText } from './providers/useResizeObserverForRichText';
import styles from './style/WRichText.previewWrapper.scss';

function withComponentPreview(
  ViewerComponent: React.ComponentType<IWRichTextProps>,
) {
  return React.forwardRef<
    IComponentCustomMeasureImperativeAPI,
    IWRichTextProps
  >(
    (
      props: PreviewWrapperProps<IWRichTextProps, WRichTextPreviewWrapperProps>,
      ref,
    ) => {
      const { shouldWrapText, verticalText } = props.previewWrapperProps!;

      React.useImperativeHandle(ref, () => ({
        getCustomMeasures: () => {
          const getMinSize = () => {
            const wRichText = document.getElementById(props.id)!;
            return verticalText
              ? wRichText.offsetWidth
              : wRichText.offsetHeight;
          };

          return {
            minHeight: {
              [props.id]: getMinSize,
            },
            minWidth: {
              [props.id]: getMinSize,
            },
            contentHeight: {
              [props.id]: () => {
                const textComp = shouldWrapText
                  ? innerTextRef.current
                  : document.getElementById(props.id);
                const textParts = textComp?.children || textComp;

                // legacy wRichText can be componsed from several child text tags (h1, h2, ... , p)
                return reduce(
                  textParts,
                  (acc, textPartElement) =>
                    acc + (textPartElement as HTMLElement).offsetHeight,
                  0,
                );
              },
            },
          };
        },
      }));

      /**
       * This hook is used in order to make Thunderbolt measure the
       * correct height and width of the component.
       * We are wrapping the WRichText component with a div in order
       * to set the initial height and width and from that point on we measure
       * the height and width that came from the DOM.
       * We are using resize observer in order to update the height and width
       * of the wrapper div, that's because Thunderbolt is listening to the elment
       * with the id that stored in the Viewer side.
       * As you can see the compId is passed to the wrapper div, and the text
       * component itself get a suffix, which mean Thunderbolt does not know about
       * this inner text.
       * By updating the wrapper div, the resize observer in the Viewer side
       * gets called, and then the structure gets updated and the component displayed
       * correctly.
       *
       * The resize observer will be in used only in case the ref is passed to the
       * text component, it is passed if these conditions happen:
       * 1. The text is not defined as vertical
       * 2. The user is in Editor mode and not in preview
       * 3. The user is in Classic editor and not in EditorX
       *
       */

      const wrapperRef = React.useRef<HTMLDivElement>(null);
      const innerTextRef = React.useRef<HTMLDivElement>(null);

      const getContentHeight = () =>
        innerTextRef.current ? innerTextRef.current.offsetHeight : 0;

      useResizeObserverForRichText(innerTextRef, wrapperRef, {
        isFitToHeight: !verticalText,
        getContentHeight,
      });

      const renderWithParentDiv = () => {
        return (
          <div id={props.id} ref={wrapperRef} className={styles.wrapper}>
            <ViewerComponent
              {...props}
              ref={innerTextRef}
              id={`text-content-${props.id}`}
            />
          </div>
        );
      };

      const renderWithoutParentDiv = () => {
        return <ViewerComponent {...props} />;
      };

      return shouldWrapText ? renderWithParentDiv() : renderWithoutParentDiv();
    },
  );
}

export default (ViewerComponent: React.ComponentType<IWRichTextProps>) =>
  createComponentPreviewEntry(
    withComponentPreview(withComponentPreviewFromPreviewUtils(ViewerComponent)),
  );
