import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import { getHeightInPixels } from '@wix/editor-elements-common-utils';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  FreestyleDefinition,
  FreestylePlatformProps,
  FreestylePropsCarmiData,
  FreestyleCSSVars,
} from '../Freestyle.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  borderColor: 'color1',
};

export const props = withCompInfo<
  FreestylePlatformProps,
  FreestyleDefinition,
  FreestylePropsCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => ({
    ...propsResolver<FreestyleDefinition>(
      data,
      carmiProps,
      'Freestyle/Freestyle.html',
    ),
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
  }),
);

const sdkData = withCompInfo<TPAGallerySdkData, FreestyleDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<FreestyleCSSVars, FreestyleDefinition>()(
  ['compLayout', 'compSingleLayout', 'isOneDocMigrated'],
  ({ compLayout, compSingleLayout, isOneDocMigrated }) => {
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;
    return {
      '--height': `${height}px`,
    };
  },
);

export default createComponentMapperModel({ css, props, sdkData });
