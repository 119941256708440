import { ContentBlock, EditorState, RichUtils } from 'draft-js';
import styles from '../textSizeStyles.scss';

export type TextSizeBlockType = 'unstyled' | 'header-two' | 'header-three';

const nextTextSize: Record<TextSizeBlockType, TextSizeBlockType> = {
  unstyled: 'header-two',
  'header-two': 'header-three',
  'header-three': 'unstyled',
};

export const textSizeStylesFn = (contentBlock: ContentBlock) => {
  const { type } = contentBlock.toJS();

  if (type === 'header-two') {
    return styles.headerTwo;
  } else if (type === 'header-three') {
    return styles.headerThree;
  }
  return '';
};

export const getActiveTextSize = (
  editorState: EditorState,
): TextSizeBlockType => {
  const startKey = editorState.getSelection().getStartKey();
  const contentBlock = editorState.getCurrentContent().getBlockForKey(startKey);
  const { type } = contentBlock.toJS();
  if (type === 'header-two' || type === 'header-three') {
    return type;
  }
  return 'unstyled';
};

export const createStateWithNextTextSize = (editorState: EditorState) => {
  const activeTextSize = getActiveTextSize(editorState);
  const nextTextSizeBlockType = nextTextSize[activeTextSize];
  return RichUtils.toggleBlockType(editorState, nextTextSizeBlockType);
};
