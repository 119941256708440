import * as React from 'react';
import classnames from 'clsx';
import { keyCodes, getDataAttributes } from '@wix/editor-elements-common-utils';
import { ISelectionTagProps, ILabeledInputProps } from '../SelectionTag.types';
import { classes } from './style/SelectionTag.st.css';

const LabeledInput: React.FC<ILabeledInputProps> = props => {
  const {
    value,
    label,
    disabled,
    checked,
    id,
    children,
    inputRef,
    onChange,
    rootElementProps,
    required,
    className,
  } = props;
  return (
    <label
      {...getDataAttributes(props)}
      htmlFor={id}
      {...rootElementProps}
      className={classnames(className, rootElementProps?.className)}
    >
      <input
        ref={inputRef}
        className={classes.tagInput}
        type="checkbox"
        checked={checked}
        value={value}
        name={id || label}
        id={id}
        disabled={disabled}
        onChange={onChange}
        required={required}
      />

      {children}
    </label>
  );
};

const SelectionTag: React.FC<ISelectionTagProps> = props => {
  const {
    id,
    link,
    disabled,
    checked,
    rel,
    value,
    label,
    children,
    className,
    onChange,
    required,
  } = props;

  const inputRef = React.useRef<HTMLInputElement>(null);
  const anchorRef = React.useRef<HTMLAnchorElement>(null);

  const handleKeyDown: React.KeyboardEventHandler<
    HTMLAnchorElement | HTMLLabelElement
  > = ev => {
    /**
     * By default, list item can't be selected by Space button,
     * so we call click on input.
     */

    if (ev.keyCode === keyCodes.space) {
      ev.preventDefault();

      inputRef.current?.click();
    }
  };

  const handleTagClick = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.preventDefault();
    /**
     * Label wrapped in anchor, so we simulate click on input
     * to fire change event and onChange listener.
     */
    inputRef.current?.click();
  };

  const rootElementProps = {
    onKeyDown: handleKeyDown,
    className: classnames(classes.tag, className),
    'data-testid': 'tags-list-tag',
  };

  const labeledInputProps = {
    value,
    label,
    disabled,
    checked,
    id,
    children,
    inputRef,
    onChange,
    required,
  };

  return link ? (
    <a
      {...getDataAttributes(props)}
      ref={anchorRef}
      role="checkbox"
      aria-checked={checked}
      aria-disabled={disabled}
      href={link}
      rel={rel}
      onClick={handleTagClick}
      {...rootElementProps}
    >
      <LabeledInput {...labeledInputProps} />
    </a>
  ) : (
    <LabeledInput {...labeledInputProps} rootElementProps={rootElementProps} />
  );
};

export default SelectionTag;
