import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import BoxScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/BoxScreen';
import FooterContainer from '../../FooterContainer';

const BoxScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => <FooterContainer {...props} skin={BoxScreen}></FooterContainer>;

export default BoxScreenFooter;
