import * as React from 'react';
import Link from '@wix/thunderbolt-elements/src/components/Link/viewer/Link';
import {
  formatClassNames,
  getDataAttributes,
  isEmptyObject,
} from '@wix/editor-elements-common-utils';
import type { SvgImageProps } from '../SvgImage.types';
import styles from './SvgImage.scss';
import classNames from 'clsx';
import semanticClassNames from '../SvgImage.semanticClassNames';
import { isFallbackSvg } from '@wix/editor-elements-corvid-utils';
import { getVectorId } from './constants';

const ConditionalLink = ({
  link,
  children,
}: React.PropsWithChildren<Pick<SvgImageProps, 'link'>>) => {
  const hasLink = !isEmptyObject(link);

  if (!hasLink) {
    return <>{children}</>;
  }

  return <Link {...link}>{children}</Link>;
};

export const SvgImageBase = ({
  id,
  link,
  alt = '',
  svgString,
  svgType,
  bBox,
  viewBox,
  svgWidth,
  svgHeight,
  className,
  children,
  keepAspectRatio,
  customClassNames = [],
  ...props
}: React.PropsWithChildren<SvgImageProps>) => {
  const isValidSvg =
    !!svgString && !(isFallbackSvg(svgString) && typeof window === 'undefined');

  return (
    <div
      id={id}
      {...getDataAttributes(props)}
      className={classNames(
        formatClassNames(semanticClassNames.root, ...customClassNames),
      )}
    >
      {isValidSvg && (
        <>
          <svg data-testid={`svgsrc-${id}`}>
            <defs className={styles.svgImageSource}>
              <symbol
                id={getVectorId(id)}
                width={svgWidth}
                height={svgHeight}
                data-type={svgType}
                dangerouslySetInnerHTML={{ __html: svgString }}
              />
            </defs>
          </svg>
          <ConditionalLink link={link}>
            <div
              data-testid={`svgRoot-${id}`}
              className={classNames(styles.svgImageRoot, className)}
            >
              <svg
                aria-label={alt}
                role={alt ? 'img' : 'presentation'}
                aria-hidden={alt ? undefined : true}
                preserveAspectRatio={keepAspectRatio ? 'xMidYMid meet' : 'none'}
                data-type={svgType}
                viewBox={viewBox}
                xmlnsXlink="http://www.w3.org/1999/xlink"
                xmlns="http://www.w3.org/2000/svg"
                data-bbox={bBox}
              >
                {children}
              </svg>
            </div>
          </ConditionalLink>
        </>
      )}
    </div>
  );
};
