import { withCompController } from '@wix/editor-elements-integrations';
import { getSocialUrl } from '@wix/editor-elements-common-utils';
import {
  PinterestPinItMapperProps,
  PinterestPinItControllerProps,
  IPinterestPinItProps,
  PinterestPinItStateRefs,
} from '../PinterestPinIt.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

const compController = withCompController<
  PinterestPinItMapperProps,
  PinterestPinItControllerProps,
  IPinterestPinItProps,
  PinterestPinItStateRefs
>(({ stateValues, mapperProps }) => {
  const { urlFormat, externalBaseUrl, isHttpsEnabled, ...restProps } =
    mapperProps;

  const { pageId, relativeUrl } = stateValues;

  const socialUrl = getSocialUrl({
    urlFormat,
    isMasterPage: true,
    pageId,
    relativeUrl,
    externalBaseUrl,
    isHttpsEnabled,
    currentUrl: externalBaseUrl,
  });

  return {
    ...restProps,
    socialUrl,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});

export default compController;
