import { getDataAttributes } from '@wix/editor-elements-common-utils';
import * as React from 'react';

import type { IUnsupportedComponentProps } from '../UnsupportedComponent.types';
import { st, classes } from './style/UnsupportedComponent.st.css';

const UnsupportedComponent: React.FC<IUnsupportedComponentProps> = props => {
  const { id, className } = props;

  return (
    <div
      {...getDataAttributes(props)}
      id={id}
      className={st(classes.root, className)}
    >
      !
    </div>
  );
};

export default UnsupportedComponent;
