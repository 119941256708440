import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getLanguage } from '../../WTwitter/common/utils';
import {
  WTwitterTweetDefinition,
  WTwitterTweetMapperProps,
  WTwitterTweetStateRefs,
  WTwitterTweetCSSVars,
} from '../WTwitterTweet.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { COMPONENT_LABEL, TITLE_NAME, consetPolicyKeys } from './constants';

export const props = withCompInfo<
  WTwitterTweetMapperProps,
  WTwitterTweetDefinition
>()(
  [
    'compData',
    'compProps',
    'language',
    'isMobileView',
    'isMasterPage',
    'externalBaseUrl',
    'translate',
    ...consentPolicyPropsDeps,
  ],
  compInfo => {
    const {
      compData: { defaultText, accountToFollow, urlFormat, isHttpsEnabled },
      compProps,
      language,
      isMobileView,
      isMasterPage,
      externalBaseUrl,
      translate,
    } = compInfo;
    const originalProps = {
      defaultText,
      accountToFollow,
      isMobileView,
      lang: getLanguage(compProps.dataLang, language),
      urlFormat,
      isMasterPage,
      externalBaseUrl,
      isHttpsEnabled,
      translations: {
        title: translate(COMPONENT_LABEL, TITLE_NAME),
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

export const css = withCompInfo<
  WTwitterTweetCSSVars,
  WTwitterTweetDefinition
>()(['compLayout', 'isOneDocMigrated'], ({ compLayout, isOneDocMigrated }) => {
  return {
    ...(!isOneDocMigrated && {
      '--width': compLayout.width,
      '--height': compLayout.height,
      width: 'calc(var(--width) * 1px)',
      height: 'calc(var(--height) * 1px)',
    }),
    display: 'var(--display)',
    '--display': 'flex',
  };
});

export const stateRefs = withStateRefsValues<keyof WTwitterTweetStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
  'currentUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, css, stateRefs, uiType });
