import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type {
  VideoPlayerDefinition,
  IVideoPlayerMapperProps,
  VideoPlayerData,
} from '../VideoPlayer.types';
import {
  getConsentPolicyStateRefs,
  shouldShowConsentPolicyWrapper,
  consentPolicyUiTypeMapper,
  isMobile,
  translateConsentWrapperKeys,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import {
  componentNameKey,
  componentNameDefault,
  ARIA_LABEL_NAMESPACE,
  VIDEOPLAYER_PLAY_ARIA_LABEL_KEY,
  VIDEOPLAYER_PLAY_ARIA_LABEL_DEFAULT,
} from '../constants';
import { getPlayerName } from './utils';

export const props = withCompInfo<
  IVideoPlayerMapperProps,
  VideoPlayerDefinition
>()(
  [
    'compData',
    'compProps',
    'isMobileView',
    'uiType',
    'deviceType',
    'translate',
    ...consentPolicyPropsDeps,
  ],
  compInfo => {
    const { compData, compProps, isMobileView, uiType, deviceType, translate } =
      compInfo;

    const originalProps = {
      src: getVideoUrl(compData) || '',
      animatePoster: compProps.animatePoster,
      loop: compProps.loop,
      controls: compProps.controlsVisibility === 'hover',
      showTitle: compProps.showVideoTitle,
      playableConfig: {
        title: compData.videoTitle || '',
        description: compData.description || '',
        poster: compData.videoRef?.posterImageRef,
      },
      muted: compProps.autoplay,
      autoplay: compProps.autoplay,
      isMobileView,

      currentTime: 0,
      duration: compData.videoRef?.duration || 0,
      isPlaying: false,
      volume: compProps.autoplay ? 0 : 100,
      isMuted: compProps.autoplay,
      isConsentPolicyActive: shouldShowConsentPolicyWrapper(uiType),
      isMobile: isMobile(deviceType),
      translations: {
        ...translateConsentWrapperKeys(
          componentNameKey,
          componentNameDefault,
          translate,
        ),
        playVideoAriaLabel:
          translate(ARIA_LABEL_NAMESPACE, VIDEOPLAYER_PLAY_ARIA_LABEL_KEY) ||
          VIDEOPLAYER_PLAY_ARIA_LABEL_DEFAULT,
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      componentNameKey,
      componentNameDefault,
    );
  },
);

const getVideoUrl = (compData: VideoPlayerData) => {
  const { videoUrl } = compData;
  if (videoUrl) {
    return videoUrl;
  }

  const qualities = compData.videoRef?.qualities;

  if (!qualities || !qualities.length) {
    return;
  }

  type Quality = (typeof qualities)[0];

  const { url } = qualities.reduce((acc: Quality, next: Quality) =>
    acc.width! > next.width! ? acc : next,
  );

  return `https://video.wixstatic.com/${url}`; // TODO staticVideoUrl
};

export const stateRefs = withStateRefsValues([
  'reducedMotion',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = withCompInfo<any, any>()(
  ['compData', 'isSocialElementsBlocked'],
  ({ compData, isSocialElementsBlocked }) => {
    const src = getVideoUrl(compData) || '';
    const playerName = getPlayerName(src);
    const isPlayable = playerName === 'playable';

    return (
      (!isPlayable &&
        consentPolicyUiTypeMapper.resolver(
          { isSocialElementsBlocked },
          undefined,
        )) ||
      undefined
    );
  },
);

export default createComponentMapperModel({ props, stateRefs, uiType });
