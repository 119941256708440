import { withCompController } from '@wix/editor-elements-integrations';
import {
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeStateRefs,
  FlickrBadgeControllerProps,
} from '../FlickrBadgeWidget.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

const compController = withCompController<
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeControllerProps,
  never,
  FlickrBadgeStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    currentUrl: stateValues.currentUrl,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});

export default compController;
