import type { KeyboardEvent } from 'react';

export const handleKeyDown = (e: KeyboardEvent) => {
  if (['ArrowUp', 'ArrowDown'].includes(e.key)) {
    e.preventDefault();
    const siblingGroup = (e.target as HTMLElement).closest('[role="group"]')?.[
      e.key === 'ArrowDown' ? 'nextSibling' : 'previousSibling'
    ];
    const elementToFocus = siblingGroup?.firstChild?.firstChild as HTMLElement;
    elementToFocus?.focus();
  }
};
