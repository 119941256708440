import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import type {
  ImageButtonCarmiProps,
  ImageButtonDefinition,
  ImageButtonPlatformProps,
} from '../ImageButton.types';
import { props as viewerPropsMapper } from './ImageButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<ImageButtonPlatformProps>,
  ImageButtonDefinition,
  ImageButtonCarmiProps
>()(
  viewerPropsMapper,
  {
    add: ['compPreviewState'],
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { compPreviewState } = deps;
    return {
      ...viewerProps,
      previewWrapperProps: { compPreviewState },
      trackClicksAnalytics: false,
    };
  },
);

export default createComponentPreviewMapperModel({ props });
