export const ViewerType = 'wixui.AccordionContainer';
export const PropertiesType = 'AccordionContainerProperties';
export const DataType = 'AccordionContainerData';

export enum DefaultOpened {
  CLOSE_ALL = 'close_all',
  OPEN_FIRST = 'open_first',
  OPEN_ALL = 'open_all',
}

export const defaultOpenedVariants = Object.values(DefaultOpened);

export enum Icon {
  ARROW = 'arrow',
  PLUS = 'plus',
}

export const iconVariants = Object.values(Icon);

export const Selectors = {
  root: '.root',
  accordion: '.root::accordion',
  accordionItemHeader: '.root::accordionHeader',
  accordionItemHeaderLabel: '.root::accordionHeader::accordionLabel',
  accordionItemHeaderHoveredLabel:
    '.root::accordionHeader:hover::accordionLabel',
  accordionItemHeaderIcon: '.root::accordionHeader::accordionIcon',
  accordionItemHeaderHoveredIcon: '.root::accordionHeader:hover::accordionIcon',
  animationBox: '.root::animationBox',
  accordionItemContent: '.root::accordionContent',
};
