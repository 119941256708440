import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  YouTubeSubscribeButtonCSSVars,
  YouTubeSubscribeButtonDefinition,
  YouTubeSubscribeButtonProps,
  YouTubeSubscribeButtonTheme,
} from '../YouTubeSubscribeButton.types';
import { Layout } from '../documentManagement/YouTubeSubscribeButton.schemaTypes';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

const getSize = (layout: Layout) => {
  const [width, height] = layout === 'full' ? [212, 55] : [145, 33];
  return {
    width: `${width}px`,
    height: `${height}px`,
  };
};

export const props = withCompInfo<
  YouTubeSubscribeButtonProps,
  YouTubeSubscribeButtonDefinition
>()(['compData', 'compProps', ...consentPolicyPropsDeps], compInfo => {
  const {
    compData,
    compProps: { layout, theme },
  } = compInfo;
  const buttomTheme = theme === 'dark' ? 'dark' : 'default';
  const originalProps = {
    channel: compData.youtubeChannelId,
    layout,
    theme: buttomTheme as YouTubeSubscribeButtonTheme,
  };
  return withConsentPolicyProps(
    compInfo,
    originalProps,
    consetPolicyKeys.componentNameKey,
    consetPolicyKeys.componentNameDefault,
  );
});

export const css = withCompInfo<
  YouTubeSubscribeButtonCSSVars,
  YouTubeSubscribeButtonDefinition
>()(['compProps'], ({ compProps: { layout } }) => getSize(layout));

export const stateRefs = withStateRefsValues([...getConsentPolicyStateRefs()]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ css, props, stateRefs, uiType });
