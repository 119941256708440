export const VerificationCodeDialogTranslationKeys = {
  title: 'verificationCodeTitle',
  footerParagraph: 'verificationCodeFooterParagraph',
  footerParagraphLink: 'verificationCodeFooterParagraphLink',
  emailParagraph: 'verificationCodeEmailParagraph',
  submitButton: 'verificationCodeSubmitButton',
  generalErr: 'membersErrorGeneral',
  verificationErr: 'verificationCodeVerificationErr',
  Code_Not_Found: 'verificationCodeNotFoundError',
  Code_Bad_Code: 'verificationCodeBadCodeError',
  To_Many_Requests: 'membersServerError_19901',
  resendIndication: 'verificationCodeResendIndication',
  enterCode: 'verificationCodeEnterCode',
};

export const codeInput = 'codeInput';
export const testIds = {
  headline: 'verificationCode.headline',
  resendButton: 'verificationCode.resendButton',
  submit: 'verificationCode.submitButton',
};

export const RESEND_INDICATION_TTL_TIMER = 30000;
