import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  PaginatedGridGalleryCarmiData,
  PaginatedGridGalleryDefinition,
  IPaginatedGridGalleryMapperProps,
} from '../PaginatedGridGallery.types';
import { props as viewerPropsMapper } from './PaginatedGridGallery.mapper';

export const props = composeMapper<
  IPaginatedGridGalleryMapperProps,
  PaginatedGridGalleryDefinition,
  PaginatedGridGalleryCarmiData
>()(
  viewerPropsMapper,
  {
    add: ['isPlayingAllowed'],
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      autoPlay: deps.isPlayingAllowed ? viewerProps.autoPlay : false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
