import {
  createComponentPreviewMapperModel,
  withCompPreviewProps,
} from '@wix/editor-elements-integrations';

const props = withCompPreviewProps()(
  ['compPreviewState', 'isWixBlocks', 'componentViewMode'],
  (api, carmiData: any) => {
    return {
      ...carmiData,
      isPreviewMode: api.componentViewMode === 'preview',
      isWixBlocks: api.isWixBlocks ?? false,
      isHighlighted:
        api.compPreviewState?.includes('slot-highlighted') ?? false,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
