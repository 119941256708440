import { generateSkinDefaults } from '@wix/editor-elements-panel';

export const submenuSkinDefinition = {
  'wixui.skins.Submenu': {
    // item background
    'item-background': {
      type: 'BACKGROUND_FILL',
      defaultValue: 'rgba(255,255,255,0)',
      state: 'regular',
    },
    'item-hover-background': {
      type: 'BACKGROUND_FILL',
      state: 'hover',
    },
    'item-selected-background': {
      type: 'BACKGROUND_FILL',
      state: 'selected',
    },
    // item text regular
    'item-font': {
      type: 'FONT',
      state: 'regular',
      defaultValue: 'font_8',
    },
    'item-color': {
      type: 'CSS_COLOR',
      state: 'regular',
      defaultValue: 'color_15',
    },
    'item-letter-spacing': {
      type: 'SIZE',
      state: 'regular',
      defaultValue: '0em',
    },
    'item-line-height': {
      type: 'SIZE',
      state: 'regular',
      defaultValue: '1.4em',
    },
    'item-text-decoration': {
      type: 'CSSString',
      state: 'regular',
      defaultValue: 'none',
    },
    'item-text-highlight': {
      type: 'CSS_COLOR',
      defaultValue: 'none',
      state: 'regular',
    },
    'item-text-transform': {
      type: 'CSSString',
      defaultValue: 'none',
      state: 'regular',
    },
    'item-text-outline': {
      type: 'CSSString',
      defaultValue:
        '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
      state: 'regular',
    },
    'item-text-shadow': {
      type: 'TEXT_SHADOW',
      defaultValue: '0px 0px transparent',
      state: 'regular',
    },
    // item text hover
    'item-hover-font': {
      type: 'FONT',
      state: 'hover',
    },
    'item-hover-color': {
      type: 'CSS_COLOR',
      state: 'hover',
    },
    'item-hover-letter-spacing': {
      type: 'SIZE',
      state: 'hover',
    },
    'item-hover-line-height': {
      type: 'SIZE',
      state: 'hover',
    },
    'item-hover-text-decoration': {
      type: 'CSSString',
      state: 'hover',
    },
    'item-hover-text-highlight': {
      type: 'CSS_COLOR',
      state: 'hover',
    },
    'item-hover-text-transform': {
      type: 'CSSString',
      state: 'hover',
    },
    'item-hover-text-outline': {
      type: 'CSSString',
      state: 'hover',
    },
    'item-hover-text-shadow': {
      type: 'TEXT_SHADOW',
      state: 'hover',
    },
    // item text selected
    'item-selected-font': {
      type: 'FONT',
      state: 'selected',
    },
    'item-selected-color': {
      type: 'CSS_COLOR',
      state: 'selected',
    },
    'item-selected-letter-spacing': {
      type: 'SIZE',
      state: 'selected',
    },
    'item-selected-line-height': {
      type: 'SIZE',
      state: 'selected',
    },
    'item-selected-text-decoration': {
      type: 'CSSString',
      state: 'selected',
    },
    'item-selected-text-highlight': {
      type: 'CSS_COLOR',
      state: 'selected',
    },
    'item-selected-text-transform': {
      type: 'CSSString',
      state: 'selected',
    },
    'item-selected-text-outline': {
      type: 'CSSString',
      state: 'selected',
    },
    'item-selected-text-shadow': {
      type: 'TEXT_SHADOW',
      state: 'selected',
    },
    // item border regular
    'item-border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'item-border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'item-border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'item-border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    // item border hover
    'item-hover-border-left': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'item-hover-border-right': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'item-hover-border-top': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'item-hover-border-bottom': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    // item border selected
    'item-selected-border-left': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    'item-selected-border-right': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    'item-selected-border-top': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    'item-selected-border-bottom': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    // item border radius
    'item-border-radius': {
      type: 'SIDES',
      defaultValue: '0 0 0 0',
      state: 'regular',
    },
    'item-hover-border-radius': {
      type: 'SIDES',
      state: 'hover',
    },
    'item-selected-border-radius': {
      type: 'SIDES',
      state: 'selected',
    },
    // item box shadow
    'item-box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: 'none',
      state: 'regular',
    },
    'item-hover-box-shadow': {
      type: 'BOX_SHADOW',
      state: 'hover',
    },
    'item-selected-box-shadow': {
      type: 'BOX_SHADOW',
      state: 'selected',
    },
    // sub-item background
    'sub-item-background': {
      type: 'BACKGROUND_FILL',
      defaultValue: 'rgba(255,255,255,0)',
      state: 'regular',
    },
    'sub-item-hover-background': {
      type: 'BACKGROUND_FILL',
      state: 'hover',
    },
    'sub-item-selected-background': {
      type: 'BACKGROUND_FILL',
      state: 'selected',
    },
    // sub-item text regular
    'sub-item-font': {
      type: 'FONT',
      state: 'regular',
      defaultValue: 'font_8',
    },
    'sub-item-color': {
      type: 'CSS_COLOR',
      state: 'regular',
      defaultValue: 'color_15',
    },
    'sub-item-letter-spacing': {
      type: 'SIZE',
      state: 'regular',
      defaultValue: '0em',
    },
    'sub-item-line-height': {
      type: 'SIZE',
      state: 'regular',
      defaultValue: '1.4em',
    },
    'sub-item-text-decoration': {
      type: 'CSSString',
      state: 'regular',
      defaultValue: 'none',
    },
    'sub-item-text-highlight': {
      type: 'CSS_COLOR',
      defaultValue: 'none',
      state: 'regular',
    },
    'sub-item-text-transform': {
      type: 'CSSString',
      defaultValue: 'none',
      state: 'regular',
    },
    'sub-item-text-outline': {
      type: 'CSSString',
      defaultValue:
        '1px 0px transparent, -1px 0px transparent, 0px 1px transparent, 0px -1px transparent',
      state: 'regular',
    },
    'sub-item-text-shadow': {
      type: 'TEXT_SHADOW',
      defaultValue: '0px 0px transparent',
      state: 'regular',
    },
    // sub-item text hover
    'sub-item-hover-font': {
      type: 'FONT',
      state: 'hover',
    },
    'sub-item-hover-color': {
      type: 'CSS_COLOR',
      state: 'hover',
    },
    'sub-item-hover-letter-spacing': {
      type: 'SIZE',
      state: 'hover',
    },
    'sub-item-hover-line-height': {
      type: 'SIZE',
      state: 'hover',
    },
    'sub-item-hover-text-decoration': {
      type: 'CSSString',
      state: 'hover',
    },
    'sub-item-hover-text-highlight': {
      type: 'CSS_COLOR',
      state: 'hover',
    },
    'sub-item-hover-text-transform': {
      type: 'CSSString',
      state: 'hover',
    },
    'sub-item-hover-text-outline': {
      type: 'CSSString',
      state: 'hover',
    },
    'sub-item-hover-text-shadow': {
      type: 'TEXT_SHADOW',
      state: 'hover',
    },
    // sub-item text selected
    'sub-item-selected-font': {
      type: 'FONT',
      state: 'selected',
    },
    'sub-item-selected-color': {
      type: 'CSS_COLOR',
      state: 'selected',
    },
    'sub-item-selected-letter-spacing': {
      type: 'SIZE',
      state: 'selected',
    },
    'sub-item-selected-line-height': {
      type: 'SIZE',
      state: 'selected',
    },
    'sub-item-selected-text-decoration': {
      type: 'CSSString',
      state: 'selected',
    },
    'sub-item-selected-text-highlight': {
      type: 'CSS_COLOR',
      state: 'selected',
    },
    'sub-item-selected-text-transform': {
      type: 'CSSString',
      state: 'selected',
    },
    'sub-item-selected-text-outline': {
      type: 'CSSString',
      state: 'selected',
    },
    'sub-item-selected-text-shadow': {
      type: 'TEXT_SHADOW',
      state: 'selected',
    },
    // item border regular
    'sub-item-border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'sub-item-border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'sub-item-border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'sub-item-border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    // sub-item border hover
    'sub-item-hover-border-left': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'sub-item-hover-border-right': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'sub-item-hover-border-top': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    'sub-item-hover-border-bottom': {
      type: 'BORDER_SIDE',
      state: 'hover',
    },
    // sub-item border selected
    'sub-item-selected-border-left': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    'sub-item-selected-border-right': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    'sub-item-selected-border-top': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    'sub-item-selected-border-bottom': {
      type: 'BORDER_SIDE',
      state: 'selected',
    },
    // sub-item border radius
    'sub-item-border-radius': {
      type: 'SIDES',
      defaultValue: '0 0 0 0',
      state: 'regular',
    },
    'sub-item-hover-border-radius': {
      type: 'SIDES',
      state: 'hover',
    },
    'sub-item-selected-border-radius': {
      type: 'SIDES',
      state: 'selected',
    },
    // sub-item box shadow
    'sub-item-box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: 'none',
      state: 'regular',
    },
    'sub-item-hover-box-shadow': {
      type: 'BOX_SHADOW',
      state: 'hover',
    },
    'sub-item-selected-box-shadow': {
      type: 'BOX_SHADOW',
      state: 'selected',
    },
    // container
    'container-background': {
      type: 'BACKGROUND_FILL',
      defaultValue: 'rgba(255,255,255,0)',
      state: 'regular',
    },
    'container-box-shadow': {
      type: 'BOX_SHADOW',
      defaultValue: 'none',
      state: 'regular',
    },
    'container-border-left': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'container-border-right': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'container-border-top': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'container-border-bottom': {
      type: 'BORDER_SIDE',
      defaultValue: '0px none #000000',
      state: 'regular',
    },
    'container-border-radius': {
      type: 'SIDES',
      defaultValue: '0 0 0 0',
      state: 'regular',
    },
    'container-vertical-padding': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
    'container-horizontal-padding': {
      type: 'SIZE',
      defaultValue: '0px',
      state: 'regular',
    },
  },
} as const;

export default generateSkinDefaults(submenuSkinDefinition);
