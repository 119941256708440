import { cssStringToBuilderReturnType } from '@wix/fast-gallery-library';
import type { FastGalleryImages } from '../FastGallery.types';

export const itemsConverter = (items: Array<FastGalleryImages>) => {
  return items.map(fastGalleryItem => {
    const item = {
      id: fastGalleryItem.id,
      index: 'index',
      mediaUrl: 'https://static.wixstatic.com/media/' + fastGalleryItem.uri,
      metaData: {
        width: fastGalleryItem.width,
        height: fastGalleryItem.height,
        title: fastGalleryItem.title || '',
        description: `image ${fastGalleryItem.title} description` || '',
        alt: fastGalleryItem.alt || '',
        link: {
          url: fastGalleryItem.link,
          target: '_blank',
        },
      },
      type: fastGalleryItem.type.toLowerCase(),
    };
    return item;
  });
};

export const convertCustomCssToSelectorToCss = (customCss: string) => {
  if (!customCss) {
    return {};
  }
  try {
    const customCssObj = cssStringToBuilderReturnType(
      customCss.replaceAll('\n', ''),
    ).cssObject;

    const splitSelectorsCssObject: any = {};
    for (const [selectors, properties] of Object.entries(customCssObj)) {
      selectors.split(',').forEach(selector => {
        const trimmedSelector = selector.trim();
        splitSelectorsCssObject[trimmedSelector] = properties;
      });
    }
    return splitSelectorsCssObject;
  } catch {
    return {};
  }
};
