import type { FlexAlignment } from '@wix/editor-elements-common-utils';
import type { GridColumns, GridPaginationAlignment } from './Grid.types';

export const helpIds = {
  gfpp: '18f735ee-b53e-462e-b206-4adce65aed13',
  gfppMobile: 'b337a3f8-1a4e-4f31-afd5-185498884404',
};

export const dateFormats = {
  AMERICAN: 'mm/dd/yyyy',
  EUROPEAN: 'dd/mm/yyyy',
} as const;

export const linkTargets = {
  self: '_self',
  blank: '_blank',
} as const;

export const userSelectionTypes = {
  none: 'none',
  row: 'row',
  cell: 'cell',
} as const;

export const ColumnType = {
  String: 'string',
  Number: 'number',
  Date: 'date',
  Image: 'image',
  Bool: 'bool',
  RichText: 'richText',
} as const;

export const DataSource = {
  Static: 'static',
  Dynamic: 'dynamic',
} as const;

export const PaginationType = {
  None: 'normal',
  Pages: 'pagination',
  Scroll: 'virtual',
} as const;

export const UserSelectionType = {
  None: 'none',
  Row: 'row',
  Cell: 'cell',
} as const;

export const ColumnLayout = {
  Manual: 'manual',
  Equal: 'equal',
} as const;

export const LinkTarget = {
  Blank: '_blank',
  Self: '_self',
} as const;

export const HeaderColumn = {
  None: 'none',
  First: 'first',
  Last: 'last',
} as const;

export const TestId = {
  Pagination: 'pagination',
  PaginationText: 'pagination-text',
  PagePrev: 'page-prev',
  PageNext: 'page-next',
  Loader: 'loader',
} as const;

export const paginationAlignmentToFlex: Record<
  GridPaginationAlignment,
  FlexAlignment
> = {
  left: 'start',
  center: 'center',
  spread: 'space-between',
  right: 'end',
};

export const convertPaginationAlignmentToFlex = (
  paginationAlignment: GridPaginationAlignment,
) => paginationAlignmentToFlex[paginationAlignment];

export const PaginationStyle = {
  Text: 'text',
  Number: 'number',
  Simple: 'simple',
} as const;

export const DataFetcherType = {
  RowRange: 'rowRange',
  Directional: 'directional',
} as const;

export const DataFetcherDirection = {
  Next: 'next',
  Previous: 'previous',
} as const;

export const DEFAULT_COLUMN_WIDTH = 200;
export const DEFAULT_ROWS_PER_PAGE = 100;
export const LOAD_MORE_SCROLL_THRESHOLD = 100;

export const initialColumns: GridColumns = [...Array(3).keys()].map(i => ({
  dataPath: '',
  id: `grid_initial_column_${i}`,
  label: 'Title',
  type: 'string',
  visible: true,
  width: 100,
}));

export const translationKeys = {
  NAMESPACE: 'Grid',
  DEFAULT_COLUMN_LABEL: 'table_onstage_column_title',
} as const;
