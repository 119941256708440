import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  CompInfo,
  FillLayersProps,
} from '@wix/thunderbolt-components-native';
import { getFromColorMap } from '@wix/thunderbolt-commons/dist/color';
import type {
  TextMaskCSSVars,
  TextMaskDataProps,
  TextMaskDefinition,
  TextMaskPropsCarmiProps,
} from '../TextMask.types';

export const props = withCompInfo<
  TextMaskDataProps,
  TextMaskDefinition,
  TextMaskPropsCarmiProps
>()(
  ['compData', 'siteColors'],
  (
    { compData }: Partial<CompInfo>,
    carmiProps: TextMaskPropsCarmiProps & { fillLayers: FillLayersProps },
  ) => {
    const { fillLayers, ...design } = carmiProps || {};
    const viewBox = design.isThemeFont ? '0 0 0 0' : design.viewBox;

    return {
      ...compData,
      ...design,
      viewBox,
      fillLayers,
    };
  },
);

export const css = withCompInfo<TextMaskCSSVars, TextMaskDefinition>()(
  ['compDesign', 'siteColors'],
  (
    { compDesign, siteColors }: Partial<CompInfo>,
    carmiCssVars: any,
  ): TextMaskCSSVars => {
    const { shadow } = compDesign;
    const resolveColor = (color: string) => getFromColorMap(color, siteColors);
    const hasShadow = shadow && Object.keys(shadow).length > 0;
    const shadowFilter = hasShadow
      ? `drop-shadow(${shadow.x}px ${shadow.y}px ${
          shadow.blurRadius
        }px ${resolveColor(shadow.color)}${Math.round(
          shadow.opacity * 255,
        ).toString(16)})`
      : 'none';
    return {
      ...carmiCssVars,
      height: 'auto',
      '--shadow-filter': shadowFilter,
    };
  },
);

export default createComponentMapperModel({ css, props });
