import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { getHeightInPixels } from '@wix/editor-elements-common-utils';
import { getCompStyles } from '../../../tpaGallery';
import { TPAGallerySdkData } from '../../../tpaGallery/types';
import {
  StripSlideshowDefinition,
  IStripSlideshowMapperProps,
  StripSlideshowCarmiData,
  StripSlideshowCSSVars,
} from '../StripSlideshow.types';
import {
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  titleColor: 'color1',
  descriptionColor: 'color2',
  backgroundColor: 'color3',
};

export const props = withCompInfo<
  IStripSlideshowMapperProps,
  StripSlideshowDefinition,
  StripSlideshowCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => ({
    ...propsResolver<StripSlideshowDefinition>(
      data,
      carmiProps,
      'StripSlideshow/StripSlideshow.html',
    ),
    compStyles: getCompStyles(
      compStyleKeys,
      data.styleProperties,
      data.siteColors,
    ),
    currentIndex: 0,
    isScreenWidth: true,
  }),
);

const sdkData = withCompInfo<TPAGallerySdkData, StripSlideshowDefinition>()(
  ['compData'],
  ({ compData }) => {
    return {
      dataId: compData.id,
    };
  },
);

const css = withCompInfo<StripSlideshowCSSVars, StripSlideshowDefinition>()(
  ['compLayout', 'compSingleLayout', 'isOneDocMigrated'],
  ({ compLayout, compSingleLayout, isOneDocMigrated }) => {
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;
    return {
      '--height': `${height}px`,
    };
  },
);

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ css, props, sdkData, stateRefs });
