import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  SlideShowSlideDefinition,
  SlideShowSlideProps,
  SlideShowSlideCSSVars,
  SlideShowSlideSdkData,
  SlideShowSlideSDKCarmiData,
  SlideShowSlideCarmiData,
  SlideShowSlideCSSCarmiData,
} from '@wix/thunderbolt-components-native';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
  sdkData as viewerSdkMapper,
} from './SlideShowSlide.mapper';

const props = composeMapper<
  SlideShowSlideProps,
  SlideShowSlideDefinition,
  SlideShowSlideCarmiData
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps: any = resolver(deps, carmiData);
    const { isPlayingAllowed } = deps;
    // We need to clone fill layers so we don't override the original autoplay
    const fillLayers = JSON.parse(JSON.stringify(viewerProps.fillLayers));
    if (fillLayers?.video?.videoInfo?.autoPlay) {
      fillLayers.video.videoInfo.autoPlay = isPlayingAllowed;
    }
    return {
      ...viewerProps,
      fillLayers,
    };
  },
);

const css = composeMapper<
  SlideShowSlideCSSVars,
  SlideShowSlideDefinition,
  SlideShowSlideCSSCarmiData
>()(
  viewerCssMapper,
  { add: ['isComponentTransitionAllowed'] },
  (resolver, dependencies, carmiData) => {
    const viewerCss = resolver(dependencies, carmiData);
    const previewCss: Partial<SlideShowSlideCSSVars> = {};
    return { ...viewerCss, ...previewCss };
  },
);

const sdkData = composeMapper<
  SlideShowSlideSdkData,
  SlideShowSlideDefinition,
  SlideShowSlideSDKCarmiData
>()(viewerSdkMapper, {}, (resolver, dependencies, carmiData) => {
  return resolver(dependencies, carmiData);
});

export default createComponentPreviewMapperModel({
  props,
  css,
  sdkData,
});
