import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { CompInfo } from '@wix/editor-elements-types/thunderbolt';
import {
  Language,
  Layout,
  Action,
} from '../documentManagement/WFacebookLike.schemaTypes';
import {
  WFacebookLikeDefinition,
  WFacebookLikeMapperProps,
  WFacebookLikeCSSVars,
  FacebookLocale,
  WFacebookLikeStateRefs,
} from '../WFacebookLike.types';
import {
  DEFAULT_FACEBOOK_LOCALE,
  LAYOUT_STANDARD,
  LAYOUT_BUTTON_COUNT,
  LAYOUT_BOX_COUNT,
  languageToFacebookLocale,
  widthForStandardLayoutByAction,
  widthForStandardLayoutByActionWithFaces,
  widthForButtonCountLayoutByActionAndLocale,
  widthForButtonCountLayoutByActionWithFaces,
  widthForBoxCountLayoutByActionAndLocale,
  widthForBoxCountLayoutByActionWithFaces,
  heightForStandardLayoutByAction,
  heightForStandardLayoutByActionWithFaces,
  heightForButtonCountLayoutByAction,
  heightForButtonCountLayoutByActionWithFaces,
  heightForBoxCountLayoutByAction,
  heightForBoxCountLayoutByActionWithFaces,
} from '../constants';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import {
  TRANSLATIONS_TITLE_NAMESPACE,
  TRANSLATIONS_TITLE_KEY,
  consetPolicyKeys,
} from './constants';

const getFacebookLocale = (
  language: Language,
  userLanguage: CompInfo['language'],
): FacebookLocale =>
  languageToFacebookLocale[language === 'userLang' ? userLanguage : language] ||
  DEFAULT_FACEBOOK_LOCALE;

const getSizeWithoutFaces = (
  layout: Layout,
  action: Action,
  locale: FacebookLocale,
) => {
  const layoutToSize: Record<Layout, [number, number]> = {
    [LAYOUT_STANDARD]: [
      widthForStandardLayoutByAction[action],
      heightForStandardLayoutByAction[action],
    ],
    [LAYOUT_BUTTON_COUNT]: [
      widthForButtonCountLayoutByActionAndLocale[action][locale],
      heightForButtonCountLayoutByAction[action],
    ],
    [LAYOUT_BOX_COUNT]: [
      widthForBoxCountLayoutByActionAndLocale[action][locale],
      heightForBoxCountLayoutByAction[action],
    ],
  };
  return layoutToSize[layout];
};

const getSizeWithFaces = (layout: Layout, action: Action) => {
  const layoutToSize: Record<Layout, [number, number]> = {
    [LAYOUT_STANDARD]: [
      widthForStandardLayoutByActionWithFaces[action],
      heightForStandardLayoutByActionWithFaces[action],
    ],
    [LAYOUT_BUTTON_COUNT]: [
      widthForButtonCountLayoutByActionWithFaces[action],
      heightForButtonCountLayoutByActionWithFaces[action],
    ],
    [LAYOUT_BOX_COUNT]: [
      widthForBoxCountLayoutByActionWithFaces[action],
      heightForBoxCountLayoutByActionWithFaces[action],
    ],
  };
  return layoutToSize[layout];
};

const getSize = (
  layout: Layout,
  action: Action,
  locale: FacebookLocale,
  showFaces: boolean,
): Pick<WFacebookLikeCSSVars, 'width' | 'height'> => {
  const [width, height] = showFaces
    ? getSizeWithFaces(layout, action)
    : getSizeWithoutFaces(layout, action, locale);
  return {
    width: `${width}px`,
    height: `${height}px`,
  };
};

export const props = withCompInfo<
  WFacebookLikeMapperProps,
  WFacebookLikeDefinition
>()(
  [
    'compProps',
    'compData',
    'language',
    'isMasterPage',
    'externalBaseUrl',
    'translate',
    ...consentPolicyPropsDeps,
  ],
  compInfo => {
    const {
      compProps: {
        layout,
        action,
        colorScheme,
        send: enableSend,
        show_faces: showFaces,
        language,
      },
      compData: { urlFormat, isHttpsEnabled },
      language: userLanguage,
      isMasterPage,
      externalBaseUrl,
      translate,
    } = compInfo;
    const originalProps = {
      layout,
      action,
      colorScheme,
      enableSend,
      showFaces,
      locale: getFacebookLocale(language, userLanguage),
      urlFormat,
      isMasterPage,
      externalBaseUrl,
      isHttpsEnabled,
      translations: {
        title: translate(TRANSLATIONS_TITLE_NAMESPACE, TRANSLATIONS_TITLE_KEY),
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      consetPolicyKeys.componentNameKey,
      consetPolicyKeys.componentNameDefault,
    );
  },
);

export const css = withCompInfo<
  WFacebookLikeCSSVars,
  WFacebookLikeDefinition
>()(
  ['compProps', 'language'],
  ({
    compProps: { layout, show_faces: showFaces, action, language },
    language: userLanguage,
  }) => {
    const locale = getFacebookLocale(language, userLanguage);
    return getSize(layout, action, locale, showFaces);
  },
);

export const stateRefs = withStateRefsValues<keyof WFacebookLikeStateRefs>([
  'pageId',
  'relativeUrl',
  'currentUrlWithQueryParams',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ css, props, stateRefs, uiType });
