
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "OnStageError1456725229",
      {"classes":{"root":"OnStageError1456725229__root","errorIcon":"OnStageError1456725229__errorIcon","errorMsg":"OnStageError1456725229__errorMsg"},"vars":{},"stVars":{},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  