
import {
  withCompInfo,
  withStateRefsValues,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations/siteAssets';


const props = withCompInfo<any, any>()(
  ['compData'],
  ({compData}) => {
      
    return {
      ...{ alignment: 'ltr',
value: '#e74c3c',
label: 'Select a color',
 },
...compData,

    };
  },
);




export const stateRefs = withStateRefsValues(['editorType']);

export default createComponentMapperModel({ props, stateRefs });
