import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  ILoginButtonMapperProps,
  ILoginButtonControllerProps,
  LoginButtonProps,
  ILoginButtonStateValues,
} from '../LoginButton.types';

export default withCompController<
  ILoginButtonMapperProps,
  ILoginButtonControllerProps,
  LoginButtonProps,
  ILoginButtonStateValues
>(({ mapperProps, stateValues }) => {
  const {
    compId,
    language,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const reportBiOnClick: ILoginButtonControllerProps['reportBiOnClick'] = (
    event,
    label,
  ) => {
    const { reportBi } = stateValues;

    tryReportAnalyticsClicksBi(reportBi, {
      language,
      trackClicksAnalytics,
      elementTitle: label,
      elementType: fullNameCompType,
      elementGroup: AnalyticsClicksGroups.Login,
      element_id: compId ?? event.currentTarget.id,
    });
  };

  return {
    ...restMapperProps,
    reportBiOnClick,
  };
});
