import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISliderControllerProps,
  ISliderMapperProps,
  ISliderProps,
} from '../Slider.types';

export default withCompController<
  ISliderMapperProps,
  ISliderControllerProps,
  ISliderProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    updatePropsValue: value => {
      controllerUtils.updateProps({ value });
    },
  };
});
