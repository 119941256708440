import classNames from 'clsx';
import * as React from 'react';
import type { MenuButtonProps } from '../../../MenuButton.types';
import { MenuButtonBackgroundLabel } from '../../MenuButtonBackground';
import MenuButtonRoot from '../../MenuButtonRoot';
import skinsStyle from './styles/skins.scss';

const skin = 'SeparateArrowDownMenuButtonSkin';

const SeparateArrowDownMenuButtonSkin: React.FC<MenuButtonProps> = props => {
  const { id, dir, textAlign, className } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(className, skinsStyle[skin])}
    >
      {label => (
        <>
          <div className={skinsStyle.wrapper}>
            <div className={skinsStyle.sq} />
            <div className={skinsStyle.arrowUp}>
              <div className={skinsStyle.leftMask}>
                <div className={skinsStyle.left} />
              </div>
              <div className={skinsStyle.rightMask}>
                <div className={skinsStyle.right} />
              </div>
            </div>
            <div className={skinsStyle.arrowDown}>
              <div className={skinsStyle.leftMask}>
                <div className={skinsStyle.left} />
              </div>
              <div className={skinsStyle.rightMask}>
                <div className={skinsStyle.right} />
              </div>
            </div>
          </div>
          <div className={skinsStyle.bg}>
            <MenuButtonBackgroundLabel
              dir={dir}
              textAlign={textAlign}
              className={skinsStyle.label}
              tagName="div"
              id={id}
            >
              {label}
            </MenuButtonBackgroundLabel>
          </div>
        </>
      )}
    </MenuButtonRoot>
  );
};

export default SeparateArrowDownMenuButtonSkin;
