import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  FlickrBadgeWidgetDefinition,
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeStateRefs,
} from '../FlickrBadgeWidget.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { consetPolicyKeys } from '../constants';

export const props = withCompInfo<
  FlickrBadgeWidgetMapperProps,
  FlickrBadgeWidgetDefinition
>()(['compData', ...consentPolicyPropsDeps], compInfo => {
  const { compData } = compInfo;
  const originalProps = {
    userId: compData.userId,
    tag: compData.tag,
    imageCount: `${compData.imageCount}`,
    whichImages: compData.whichImages,
    imageSize: compData.imageSize,
    layoutOrientation: compData.layoutOrientation,
  };
  return withConsentPolicyProps(
    compInfo,
    originalProps,
    consetPolicyKeys.componentNameKey,
    consetPolicyKeys.componentNameDefault,
  );
});

export const stateRefs = withStateRefsValues<keyof FlickrBadgeStateRefs>([
  'currentUrl',
  ...getConsentPolicyStateRefs(),
]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
