export const calculatePenColor = (penColor?: string) => penColor || 'black';

// size of the drawn signature varies according mouse speed
// the penWidth represents the maximal width of the drawn
// signature line
export const transformPenSizeToWidths = (
  penWidth?: string,
): {
  minWidth: number;
  maxWidth: number;
} => {
  const maxWidth = penWidth && +penWidth ? +penWidth : 2.5;
  return {
    minWidth: Math.max(maxWidth / 5, 0.5),
    maxWidth,
  };
};
