import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  getHeightInPixels,
  getWidthInPixels,
} from '@wix/editor-elements-common-utils';
import { VideoLegacyDefinition, VideoLegacyProps } from '../VideoLegacy.types';
import {
  getConsentPolicyStateRefs,
  consentPolicyUiTypeMapper,
  withConsentPolicyProps,
  consentPolicyPropsDeps,
} from '../../ConsentPolicyWrapper/viewer/utils';
import { componentNameKey, componentNameDefault } from './constants';

export const props = withCompInfo<VideoLegacyProps, VideoLegacyDefinition>()(
  [
    'compData',
    'compProps',
    'compLayout',
    'skin',
    'compSingleLayout',
    'isOneDocMigrated',
    ...consentPolicyPropsDeps,
  ],
  compInfo => {
    const {
      compData,
      compProps,
      compLayout,
      skin,
      compSingleLayout,
      isOneDocMigrated,
    } = compInfo;
    const height = isOneDocMigrated
      ? getHeightInPixels(compSingleLayout)
      : compLayout.height;
    const width = isOneDocMigrated
      ? getWidthInPixels(compSingleLayout)
      : compLayout.width;

    const originalProps = {
      skin: skin || 'VideoDefault',
      videoId: compData.videoId,
      videoType: compData.videoType,
      showControls: compProps.showControls,
      autoplay: compProps.autoplay,
      loop: compProps.loop,
      showInfo: compProps.showinfo,
      youtubeConfig: {
        enablejsapi: compProps.enablejsapi,
        lightTheme: compProps.lightTheme,
      },
      facebookConfig: {
        width: `${width}px`,
        height: `${height}px`,
      },
    };
    return withConsentPolicyProps(
      compInfo,
      originalProps,
      componentNameKey,
      componentNameDefault,
    );
  },
);

export const stateRefs = withStateRefsValues([...getConsentPolicyStateRefs()]);

export const uiType = consentPolicyUiTypeMapper;

export default createComponentMapperModel({ props, stateRefs, uiType });
