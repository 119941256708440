import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import type { CompInfo } from '@wix/editor-elements-types/thunderbolt';
import type { VerticalMenuDefinition } from '@wix/thunderbolt-components';
import {
  isThemeColor,
  getFromColorMap,
  getRGBAColorString,
} from '@wix/thunderbolt-commons/dist/color';
import { getComboBoxInputNavigationCSSVars } from '../../ComboBoxInput/utils';
import type {
  VerticalMenuCSSVars,
  VerticalMenuProps,
  VerticalMenuStateRefs,
} from '../VerticalMenu.types';
import * as translationKeys from './constants';

export const props = withCompInfo<
  VerticalMenuProps,
  VerticalMenuDefinition,
  VerticalMenuProps
>()(
  [
    'translate',
    'fullNameCompType',
    'language',
    'compId',
    'mainPageId',
    'trackClicksAnalytics',
  ],
  ({ translate, ...compPropsRest }, carmiData) => {
    return {
      ...carmiData,
      ...compPropsRest,
      translations: {
        ariaLabel: translate(
          translationKeys.ARIA_LABEL_NAMESPACE,
          translationKeys.ARIA_LABEL_KEY,
        ),
      },
    };
  },
);

const extractBorderColor = (
  siteColors: CompInfo['siteColors'],
  styleProperties: CompInfo['styleProperties'],
) => {
  const colorBrd = styleProperties.brd;
  const opacity = Number(styleProperties['alpha-brd']);

  if (!isThemeColor(colorBrd)) {
    return colorBrd;
  }

  const myColor = getFromColorMap(colorBrd, siteColors);

  return getRGBAColorString(myColor, opacity);
};

const comboBoxInputCss = (
  compInfo: Pick<
    CompInfo,
    | 'compLayout'
    | 'compSingleLayout'
    | 'styleProperties'
    | 'siteFonts'
    | 'isMobileView'
    | 'compProps'
    | 'siteColors'
    | 'isOneDocMigrated'
  >,
) => {
  const { compProps, styleProperties, siteColors } = compInfo;
  const borders = {
    '--borderColor': extractBorderColor(siteColors, styleProperties),
    '--borderWidth': styleProperties.brw,
    '--arrowBorderWidth': '0',
  };

  return getComboBoxInputNavigationCSSVars(
    compInfo,
    compProps.itemsAlignment,
    borders,
  );
};

const itemsAlignmentOptions = {
  right: 'flex-end',
  left: 'flex-start',
  center: 'center',
} as const;

const getSeparatorHeightAdjusted = (
  styleProperties: CompInfo['styleProperties'],
) => {
  // Backward compatibility with Bolt behavior:
  // For a separator to appear it has to be > 2px
  // When a separator appears we reduce it by 2px
  const separatorHeight = styleProperties.separatorHeight;
  const separatorHeightAsNum = parseInt(
    (separatorHeight || '0').split('px')[0],
    10,
  );
  let separatorHeightAdjusted = '0px';
  if (separatorHeightAsNum > 2) {
    separatorHeightAdjusted = `${separatorHeightAsNum - 2}px`;
  }
  return separatorHeightAdjusted;
};

const getItemHeight = (
  styleProperties: CompInfo['styleProperties'],
  skin: CompInfo['skin'],
  menuItemHeight: number,
) => {
  const separatorHeight = styleProperties.separatorHeight;
  const borderWidth = styleProperties.brw;
  const borderWidthAsNum = parseInt((borderWidth || '0').replace('px', ''), 10);

  let itemHeight = menuItemHeight;

  if (separatorHeight === '0px' && borderWidthAsNum > 0) {
    itemHeight =
      skin === 'VerticalMenuSolidColorSkin'
        ? menuItemHeight
        : menuItemHeight + 2;
  } else if (separatorHeight === '1px' && borderWidthAsNum > 0) {
    itemHeight = menuItemHeight + 1;
  }

  return itemHeight;
};

const DEFAULT_MENU_ITEM_HEIGHT = 50;

const defaultCss = (
  compInfo: Pick<
    CompInfo<VerticalMenuDefinition>,
    'compProps' | 'styleProperties' | 'skin'
  >,
): VerticalMenuCSSVars => {
  const { compProps, styleProperties, skin } = compInfo;
  const { menuItemHeight, subMenuOpenSide, itemsAlignment } = compProps;
  const subMenuOpenDirectionRight = subMenuOpenSide === 'right' ? 'auto' : '0';
  const subMenuOpenDirectionLeft = subMenuOpenSide === 'right' ? '0' : 'auto';

  const itemHeight = `${
    menuItemHeight === null
      ? DEFAULT_MENU_ITEM_HEIGHT
      : getItemHeight(styleProperties, skin, menuItemHeight)
  }px`;

  const textAlign = itemsAlignmentOptions[itemsAlignment];
  const separatorHeightAdjusted = getSeparatorHeightAdjusted(styleProperties);

  return {
    '--item-height': itemHeight,
    '--item-align': itemsAlignment,
    '--text-align': textAlign,
    '--sub-menu-open-direction-right': subMenuOpenDirectionRight,
    '--sub-menu-open-direction-left': subMenuOpenDirectionLeft,
    '--separator-height-adjusted': separatorHeightAdjusted,
  };
};

export const css = withCompInfo<VerticalMenuCSSVars, VerticalMenuDefinition>()(
  [
    'compLayout',
    'compSingleLayout',
    'styleProperties',
    'siteFonts',
    'isMobileView',
    'compProps',
    'skin',
    'uiType',
    'siteColors',
    'isOneDocMigrated',
  ],
  ({
    compLayout,
    compSingleLayout,
    styleProperties,
    siteFonts,
    isMobileView,
    compProps,
    skin,
    uiType,
    siteColors,
    isOneDocMigrated,
  }) => {
    const compInfo = {
      compLayout,
      compSingleLayout,
      styleProperties,
      siteFonts,
      isMobileView,
      compProps,
      skin,
      siteColors,
      isOneDocMigrated,
    };
    return uiType === 'ComboBoxInputNavigation'
      ? comboBoxInputCss(compInfo)
      : defaultCss(compInfo);
  },
);

export const stateRefs = withStateRefsValues<keyof VerticalMenuStateRefs>([
  'currentUrl',
  'reportBi',
]);

export default createComponentMapperModel({ props, css, stateRefs });
