import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { isBrowser } from '@wix/editor-elements-common-utils';
import type {
  Tpa3DGalleryDefinition,
  TPA3DGalleryPropsPlatformProps,
  Tpa3DGalleryPropsCarmiData,
  Tpa3DGalleryCSSVars,
} from '../TPA3DGallery.types';
import {
  generateCss,
  propsResolver,
  generateSdkData,
  propsDependencies,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';
import { getUrl } from '../../../tpaGallery';
import sliceboxHtml from './assets/slicebox.html?resource';
import sliceboxScriptUrl from './assets/slicebox.js?resource';
import modernizrScriptUrl from './assets/modernizr.custom.46884.js?resource';
import leftArrowImageUrl from './assets/images/L_arrow.png?resource';
import rightArrowImageUrl from './assets/images/R_arrow.png?resource';
import shadowImageUrl from './assets/images/shadow.png?resource';

export const props = withCompInfo<
  TPA3DGalleryPropsPlatformProps,
  Tpa3DGalleryDefinition,
  Tpa3DGalleryPropsCarmiData
>()(propsDependencies, (data, carmiProps) => {
  const { compId, viewMode, language } = data;
  const additionalParams = new URLSearchParams({
    sliceboxScriptPath: new URL(sliceboxScriptUrl).pathname,
    modernizrScriptPath: new URL(modernizrScriptUrl).pathname,
    leftArrowImagePath: new URL(leftArrowImageUrl).pathname,
    rightArrowImagePath: new URL(rightArrowImageUrl).pathname,
    shadowImagePath: new URL(shadowImageUrl).pathname,
  });

  if (isBrowser()) {
    additionalParams.append('origin', window.origin);
  }
  const baseUrl = getUrl(sliceboxHtml, compId, viewMode, language);
  const overrideHtmlUrl = `${baseUrl}&${additionalParams}`;

  const resolvedProps = propsResolver<Tpa3DGalleryDefinition>(
    data,
    carmiProps,
    'Slicebox/Slicebox.html',
  );

  return {
    ...resolvedProps,
    url: overrideHtmlUrl,
    currentIndex: 0,
  };
});

const sdkData = generateSdkData<Tpa3DGalleryDefinition>();

const css = generateCss<Tpa3DGalleryCSSVars, Tpa3DGalleryDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
