export const TestHooks = {
  volume: 'volume',
  mute: 'mute',
  muteIcon: 'mute-icon',
  unMuteIcon: 'unmute-icon',
  volumeBarOn: 'volumebar-on',
  volumeBarOff: 'volumebar-off',
  play: 'play',
  playIcon: 'play-icon',
  pauseIcon: 'pause-icon',
} as const;

export const SKIN_HEIGHT_MAP = {
  EPlayerFramedPlay: {
    mobile: 82,
    desktop: 68,
  },
  EPlayerLargePlay: {
    mobile: 75,
    desktop: 75,
  },
  EPlayerRoundPlay: {
    mobile: 65,
    desktop: 52,
  },
  SingleAudioPlayerSkin: {
    mobile: 76,
    desktop: 60,
  },
};
