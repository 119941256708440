export const translations = {
  ARIA_LABEL_NAMESPACE: 'ariaLabels',
  ARIA_LABEL_KEY: 'ItunesButton_AriaLabel_Button_GetOnItunes',
  ARIA_LABEL_DEFAULT: 'Get it on iTunes',
};

export const image = {
  URL: 'https://static.parastorage.com/services/santa-resources/resources/viewer/itunesButton/iTunesBtn_{language}.svg',
  languagePlaceholder: '{language}',
};
