import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type { IComponentPreviewWrapper } from '@wix/editor-elements-types/thunderboltPreview';
import React from 'react';
import type { SelectableContainerInputComponentProps } from '../types';
import { selectionPreviewStateUtils } from '../../SelectableContainer/utils/selectionPreviewStateUtils';

function withComponentPreview(
  WrappedComponent: React.ComponentType<SelectableContainerInputComponentProps>,
): IComponentPreviewWrapper<SelectableContainerInputComponentProps> {
  return ({ previewWrapperProps = {}, ...viewerProps }) => {
    const { compPreviewState = null } = previewWrapperProps;

    return (
      <WrappedComponent
        {...viewerProps}
        selectedPreview={selectionPreviewStateUtils.isPreviewStateSelection(
          compPreviewState,
        )}
      />
    );
  };
}

export default (
  ViewerComponent: React.ComponentType<SelectableContainerInputComponentProps>,
) => createComponentPreviewEntry(withComponentPreview(ViewerComponent));
