import * as React from 'react';
import {
  QuickActionBarProps,
  QuickActionBarSkinProps,
  SkinComponent,
} from '../../../QuickActionBar.types';
import BaseFixedSkin from '../BaseFixedSkin';
import QuickActionBar from '../../QuickActionBar';
import styles from './styles/OvalSkin.scss';

export const OvalSkinComp: React.ForwardRefRenderFunction<
  never,
  QuickActionBarSkinProps
> = (props, ref) => <BaseFixedSkin {...props} ref={ref} />;

const OvalSkin: React.FC<QuickActionBarProps> = props => {
  return (
    <QuickActionBar
      {...props}
      skin={React.forwardRef(OvalSkinComp) as SkinComponent}
      skinStyles={styles}
    />
  );
};

export default OvalSkin;
