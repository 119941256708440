import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import { RSSButtonDefinition } from '@wix/thunderbolt-components-native';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  RSSButtonProps,
  RSSButtonPropsCarmiData,
} from '@wix/thunderbolt-components';

import { props as viewerPropsMapper } from './RSSButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<RSSButtonProps>,
  RSSButtonDefinition,
  RSSButtonPropsCarmiData
>()(
  viewerPropsMapper,
  {
    remove: [
      'fullNameCompType',
      'language',
      'compId',
      'mainPageId',
      'trackClicksAnalytics',
    ],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      link: {
        ...viewerProps.link,
        href: `https://editor.wix.com/feed.xml`,
      },
    };
  },
);

export default createComponentPreviewMapperModel({ props });
