import { withCompController } from '@wix/editor-elements-integrations';

import {
  SpotifyPlayerStateRefs,
  SpotifyPlayerMapperProps,
  SpotifyPlayerProps,
  SpotifyPlayerControllerProps,
} from '../SpotifyPlayer.types';
import { mapConsentPolicyStateRefValues } from '../../ConsentPolicyWrapper/viewer/utils';

const compController = withCompController<
  SpotifyPlayerMapperProps,
  SpotifyPlayerControllerProps,
  SpotifyPlayerProps,
  SpotifyPlayerStateRefs
>(({ stateValues, mapperProps }) => {
  return {
    ...mapperProps,
    ...mapConsentPolicyStateRefValues(stateValues),
  };
});

export default compController;
