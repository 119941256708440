import { withCompController } from '@wix/editor-elements-integrations';
import {
  AnalyticsClicksGroups,
  tryReportAnalyticsClicksBi,
} from '@wix/editor-elements-common-utils';
import {
  IQuickActionBarControllerProps,
  IQuickActionBarMapperProps,
  IQuickActionBarProps,
  IQuickActionBarStateValues,
} from '../QuickActionBar.types';

export default withCompController<
  IQuickActionBarMapperProps,
  IQuickActionBarControllerProps,
  IQuickActionBarProps,
  IQuickActionBarStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { trackEvent, reportBi } = stateValues;
  const { updateProps } = controllerUtils;
  const {
    compId,
    language,
    mainPageId,
    fullNameCompType,
    trackClicksAnalytics,
    ...restMapperProps
  } = mapperProps;

  const commonAnalyticsClicksBiParams = {
    language,
    trackClicksAnalytics,
    element_id: compId,
    elementType: fullNameCompType,
    elementGroup: AnalyticsClicksGroups.Menu,
  };

  const reportBiOnMenuButtonClick: IQuickActionBarControllerProps['reportBiOnMenuButtonClick'] =
    isOpen => {
      tryReportAnalyticsClicksBi(reportBi, {
        ...commonAnalyticsClicksBiParams,
        value: isOpen,
      });
    };

  const reportBiOnMenuItemClick: IQuickActionBarControllerProps['reportBiOnMenuItemClick'] =
    item => {
      const { text, itemType, link } = item;

      tryReportAnalyticsClicksBi(reportBi, {
        ...commonAnalyticsClicksBiParams,
        link,
        elementTitle: text,
        details: { itemType },
        pagesMetadata: { mainPageId },
      });
    };

  return {
    ...restMapperProps,
    reportBiOnMenuItemClick,
    reportBiOnMenuButtonClick,
    onTrackEvent(eventName: string, params: Record<string, string>) {
      if (trackEvent) {
        trackEvent({ eventName, params });
      }
    },
    openActionBar: () => {
      updateProps({
        isOpen: true,
      });
    },
    closeActionBar: () => {
      updateProps({
        isOpen: false,
      });
    },
  };
});
