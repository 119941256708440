import {
  formatClassNames,
  getDataAttributes,
} from '@wix/editor-elements-common-utils';
import * as React from 'react';

import type { ITestCompProps } from '../TestComp.types';
import { TestIds, labelText } from '../constants';
import { st, classes } from './style/TestComp.component.st.css';

const TestComp = (props: ITestCompProps, ref: any) => {
  const {
    id,
    className,
    stylableClassName,
    label: externalLabel = labelText.default,
    required,
    value,
    maxLength,
    placeholder,
    onFocus,
    onBlur,
    onMouseEnter,
    onMouseLeave,
    onClick,
    onDblClick,
    isDisabled,
    onChange,
  } = props;
  const [internalLabel, setInternalLabel] = React.useState('');
  const inputId = `${id}-input`;

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      setInternalLabel(labelText.focusedBySdk);
    },
    blur: () => {
      setInternalLabel(labelText.blurredBySdk);
    },
  }));

  return (
    // the viewer platform passes the id to the component
    // and uses it to inject css variables scoped for the component
    <div
      {...getDataAttributes(props)}
      data-testid={TestIds.Root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onDoubleClick={onDblClick}
      id={id}
      className={className}
    >
      <div
        className={st(
          classes.root,
          stylableClassName,
          formatClassNames('test-comp'),
        )}
      >
        <label
          htmlFor={inputId}
          data-testid={TestIds.InputLabel}
          className={classes.text}
        >
          {internalLabel || externalLabel}
        </label>
        <input
          id={inputId}
          disabled={isDisabled}
          data-testid={TestIds.Input}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          type="text"
          value={value}
          maxLength={maxLength ?? undefined}
          placeholder={placeholder}
          required={required}
        />
      </div>
    </div>
  );
};

export default React.forwardRef(TestComp);
