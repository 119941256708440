import React from 'react';
import { Icon } from '../../constants';
import { ArrowDown, ArrowUp } from './Arrow';
import { Plus, Minus } from './Plus';

export const iconsMap: Record<Icon, [React.ReactElement, React.ReactElement]> =
  {
    [Icon.ARROW]: [<ArrowDown />, <ArrowUp />],
    [Icon.PLUS]: [<Plus />, <Minus />],
  };
