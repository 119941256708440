import * as React from 'react';
import style from '../styles/TooltipAndTexts.scss';
import { ConsentText } from '../ConsentText/ConsentText';
import { Tooltip } from '../Tooltip/Tooltip';

export type TextWithLinksProps = {
  id: string;
  datahook: string;
  text: string;
  tooltipText?: string;
  ariaLabelText?: string;
  isMobile: boolean;
  onSettingsClick: () => void;
};

export const TextWithTooltip: React.FC<TextWithLinksProps> = ({
  id,
  datahook,
  text,
  tooltipText,
  ariaLabelText,
  isMobile,
  onSettingsClick,
}) => {
  return (
    <div className={style.textDiv}>
      <ConsentText
        text={text}
        textButtonClass={style.textButton}
        rootClass={style.textWithLinkBox}
        onSettingsClick={onSettingsClick}
      />
      {tooltipText && ariaLabelText && (
        <Tooltip
          id={id}
          datahook={datahook}
          text={tooltipText}
          ariaLabelText={ariaLabelText}
          isMobile={isMobile}
          onSettingsClick={onSettingsClick}
        ></Tooltip>
      )}
    </div>
  );
};
