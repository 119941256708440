import classNames from 'clsx';
import * as React from 'react';
import type { MenuButtonProps } from '../../../MenuButton.types';
import MenuButtonBackground from '../../MenuButtonBackground';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonRoot from '../../MenuButtonRoot';
import skinsStyle from './styles/skins.scss';

const skin = 'SeparateLinesMenuButtonNSkin';

const SeparateLinesMenuButtonNSkin: React.FC<MenuButtonProps> = props => {
  const {
    id,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    'aria-describedby': ariaDescribedBy,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
    className,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(className, skinsStyle[skin])}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton ? ariaHasPopup : 'false',
            ariaDescribedBy,
            isMoreButton,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <div className={skinsStyle.gapper}>
            <div className={skinsStyle.borderWrapper}>
              <MenuButtonBackground
                wrapperProps={{ dir, textAlign, id }}
                classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
              >
                {label}
              </MenuButtonBackground>
            </div>
          </div>
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default SeparateLinesMenuButtonNSkin;
