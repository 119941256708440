import * as React from 'react';
import { activateBySpaceOrEnterButton } from '@wix/editor-elements-common-utils';

import ClearIcon from '../../../assets/NewClearIcon.svg';

import style from './ClearButton.scss';

interface ClearButtonProps {
  label: string;
  onClick: React.MouseEventHandler;
}

export const ClearButton: React.FC<ClearButtonProps> = ({ onClick, label }) => {
  return (
    <div
      aria-label={label}
      role="button"
      tabIndex={0}
      className={style.root}
      data-testid="clear-button"
      onClick={onClick}
      onKeyDown={activateBySpaceOrEnterButton}
    >
      <ClearIcon />
    </div>
  );
};
