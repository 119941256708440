import React from 'react';
import classNames from 'clsx';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';
import { FiveGridLineWrapper } from '../../FiveGridLineWrapper';
import skinsStyle from './DoubleLine.scss';

const DoubleLine: React.FC<SkinFiveGridLineProps> = wrapperProps => {
  return (
    <FiveGridLineWrapper
      {...wrapperProps}
      className={classNames(wrapperProps.className, skinsStyle.root)}
    >
      <div className={skinsStyle.line} />
    </FiveGridLineWrapper>
  );
};

export default DoubleLine;
