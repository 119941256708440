import * as React from 'react';
import type { ISlideShowGalleryProps } from '../../../SlideShowGallery.types';
import SlideShowGallery from '../../SlideShowGallery';
import skinsItemStyle from '../../../../SlideShowGalleryItem/viewer/style/SlideShowTextRightItem.scss';
import skinsStyle from './styles/SlideShowTextRight.scss';

const SlideShowTextRight: React.FC<
  Omit<ISlideShowGalleryProps, 'skin'>
> = props => (
  <SlideShowGallery
    {...props}
    skinsStyle={skinsStyle}
    skinsItemStyle={skinsItemStyle}
  />
);

export default SlideShowTextRight;
