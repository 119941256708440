import type { FC } from 'react';
import React from 'react';

export const FullArrow: FC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
    <g>
      <polygon points="13,18.7 26,7.3 0,7.3  " />
    </g>
  </svg>
);
