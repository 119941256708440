import React from 'react';
import { IFooterContainerProps } from '../../../FooterContainer.types';
import IronScreen from '../../../../ScreenWidthContainer/viewer/skinComps/IronScreen/IronScreen';
import FooterContainer from '../../FooterContainer';

const IronScreenFooter: React.FC<
  Omit<IFooterContainerProps, 'skin'>
> = props => <FooterContainer {...props} skin={IronScreen}></FooterContainer>;

export default IronScreenFooter;
