const GOOGLE_DEFAULT_LANGUAGE_CODES = {
  af: 'af',
  ja: 'ja',
  sq: 'sq',
  kn: 'kn',
  am: 'am',
  kk: 'kk',
  ar: 'ar',
  km: 'km',
  hy: 'hy',
  ko: 'ko',
  az: 'az',
  ky: 'ky',
  eu: 'eu',
  lo: 'lo',
  be: 'be',
  lv: 'lv',
  bn: 'bn',
  lt: 'lt',
  bs: 'bs',
  mk: 'mk',
  bg: 'bg',
  ms: 'ms',
  my: 'my',
  ml: 'ml',
  ca: 'ca',
  mr: 'mr',
  zh: 'zh',
  mn: 'mn',
  ne: 'ne',
  no: 'no',
  pl: 'pl',
  hr: 'hr',
  pt: 'pt',
  cs: 'cs',
  da: 'da',
  nl: 'nl',
  pa: 'pa',
  en: 'en',
  ro: 'ro',
  ru: 'ru',
  sr: 'sr',
  et: 'et',
  si: 'si',
  fa: 'fa',
  sk: 'sk',
  fi: 'fi',
  sl: 'sl',
  es: 'es',
  fr: 'fr',
  sw: 'sw',
  gl: 'gl',
  sv: 'sv',
  ka: 'ka',
  ta: 'ta',
  de: 'de',
  te: 'te',
  el: 'el',
  th: 'th',
  gu: 'gu',
  tr: 'tr',
  iw: 'iw',
  uk: 'uk',
  hi: 'hi',
  ur: 'ur',
  hu: 'hu',
  uz: 'uz',
  is: 'is',
  vi: 'vi',
  id: 'id',
  zu: 'zu',
  it: 'it',
  fil: 'fil',
  'es-419': 'es-419',
  'fr-CA': 'fr-CA',
  'zh-CN': 'zh-CN',
  'zh-HK': 'zh-HK',
  'zh-TW': 'zh-TW',
  'pt-BR': 'pt-BR',
  'pt-PT': 'pt-PT',
  'en-AU': 'en-AU',
  'en-GB': 'en-GB',
};

export const WIX_RECAPTCHA_LANGUAGE_MAP = {
  ...GOOGLE_DEFAULT_LANGUAGE_CODES,
  he: 'iw',
} as const;
