const semanticClassNames = {
  root: 'table',
  row: 'table__row',
  header: 'table__header',
  cell: 'table__cell',
  body: 'table__body',
  pagination: 'table__pagination',
} as const;

export default semanticClassNames;
