export const OPTIONS_INDEX_OFFSET = 2; // first option is placeholder. second option is the hidden option

export const translationKeys = {
  NAMESPACE: 'Dropdown',
  INLINE_ERROR_MESSAGE_DEFAULT_ERROR_MESSAGE:
    'Dropdown_On_Stage_Error_Text_Label',
} as const;

export const migratedFields = [
  'labelMargin',
  'labelPadding',
  'textAlignment',
  'textPadding',
  'inputHeight',
  'dropdownItemsSpacing',
];

export const TEXT_PADDING_BASE_OFFSET = 48;
