export const SlideshowViewerType = 'wixui.Slideshow' as const;
export const RepeaterCompType = 'wysiwyg.viewer.components.Repeater' as const;
export const SlideshowProperties = 'SlideshowProperties';
export const SlideshowButtonViewerType = 'wixui.SlideshowButton' as const;
export const SlideshowStackingContainerCompType =
  'mobile.core.components.Container' as const;
export const SlideshowIndicatorViewerType = 'wixui.SlideshowIndicator' as const;

export const SlideshowItemViewerType = 'wixui.SlideshowItem' as const;

export const EXPERIMENT = 'specs.responsive-editor.SlideshowInBlocks';
export const Skins = {
  containerDefaultSkin: 'wysiwyg.viewer.skins.area.DefaultAreaSkin',
  skinlessSkin: 'wixui.skins.Skinless',
} as const;

export enum Transition {
  None = 'none',
  Slide = 'slide',
  CrossFade = 'crossFade',
  /*
  RevealOver = 'revealOver',
  FadeOver = 'fadeOver',
  */
}

export enum TransitionDirection {
  LeftToRight = 'leftToRight',
  RightToLeft = 'rightToLeft',
  BottomToTop = 'bottomToTop',
  TopToBottom = 'topToBottom',
}

export const TranslationKeys = {
  gfpp: {
    settings: 'slideshow_gfpp_main_button_settings',
    slideshowHelpId: 'component-editors.wixui.Slideshow_help',
    slideshowButtonHelpId: 'component-editors.wixui.SlideshowButton_help',
  },
  settingsPanel: {
    title: 'slideshow_settings_panel_header',
    showNavButtons: 'slideshow_settings_nav_button_label',
    transitionEffect: 'slideshow_settings_slide_transitions_label',
    none: 'slideshow_settings_transitions_none',
    slide: 'slideshow_settings_slide_transitions_slide',
    crossFade: 'slideshow_settings_transitions_cross_fade',
    revealOver: 'slideshow_settings_transitions_reveal',
    fadeOver: 'slideshow_settings_transitions_fade',
    direction: 'slideshow_settings_transition_direction_label',
    leftToRight: 'slideshow_settings_transition_direction_option_ltr',
    rightToLeft: 'slideshow_settings_transition_direction_option_rtl',
    bottomToTop:
      'slideshow_settings_transitions_direction_option_bottom_to_top',
    topToBottom:
      'slideshow_settings_transitions_direction_option_top_to_bottom',
    transitionDuration: 'slideshow_settings_transition_duration_label_new',
    isPlaying: 'slideshow_settings_autoplay_label',
    slideDuration: 'slideshow_settings_time_on_slide_label',
    pauseOnHover: 'slideshow_settings_pause_label',
    loop: 'slideshow_settings_infinite_loop_label',
    hideDisabledNavButton: 'slideshow_settings_hide_disabled_button',
  },
};

export const DataHooks = {
  settingsPanel: {
    showNavButtons: 'slideshow-settings-panel-show-navigation-buttons',
    transitionEffect: 'slideshow-settings-panel-transition-effect',
    direction: 'slideshow-settings-panel-direction',
    transitionDuration: 'slideshow-settings-panel-transition-duration',
    isPlaying: 'slideshow-settings-panel-autoplay',
    slideDuration: 'slideshow-settings-panel-slide-duration',
    pauseOnHover: 'slideshow-settings-panel-slide-pause-on-hover',
    loop: 'slideshow-settings-panel-slide-infinite-loop',
    hideDisabledNavButton: 'slideshow-settings-panel-hide-disabled-nav-button',
  },
};

export const HelpIDs = {
  SlideshowGFPP: '5a63d0ea-6a17-48d1-b58f-8735844179dc',
  SlideshowSettingsPanel: 'b2ac1635-5ed6-456a-a3b7-561e698e3666',
  SlideshowConnectPanel: '80a0c763-351d-4d15-8d67-ec7c4e1f1087',
  SlideshowManagePanel: '26247ead-92ed-41c8-960c-c8894efca348',
  SlideshowButtonGFPP: 'cab98c8a-fca2-48d6-85c6-879f054f1193',
};
