const semanticClassNames = {
  root: 'pagination',
  pageNumber: 'pagination__page-number',
  currentPage: 'pagination__current-page',
  navigationButton: 'pagination__navigation-button',
  inputField: 'pagination__input-field',
  totalPages: 'pagination__total-pages',
} as const;

export default semanticClassNames;
