import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  CoBrandingBannerDefinition,
  CoBrandingBannerPlatformProps,
} from '../CoBrandingBannerDesktop.types';
import { TRANSLATIONS } from '../constants';

export const props = withCompInfo<
  CoBrandingBannerPlatformProps,
  CoBrandingBannerDefinition
>()(['direction', 'translate'], ({ direction, translate }) => {
  return {
    direction,
    translations: {
      titleText:
        translate(TRANSLATIONS.feature, TRANSLATIONS.desktop.title.key) ||
        TRANSLATIONS.desktop.title.defaultText,
    },
  };
});

export default createComponentMapperModel({ props });
