import type { CompInfo } from '@wix/thunderbolt-components-native';
import { translations } from '../constants';
import type { SlideShowContainerTranslations } from '../SlideShowContainer.types';

export const getSlideShowContainerTranslations = (
  translate: CompInfo['translate'],
): SlideShowContainerTranslations => {
  const translateAria = (key: string) =>
    translate(translations.ARIA_LABEL_NAMESPACE, key);

  return {
    translations: {
      prevButtonAriaLabel: translateAria(translations.PREV_BTN_ARIA_LABEL_KEY),
      nextButtonAriaLabel: translateAria(translations.NEXT_BTN_ARIA_LABEL_KEY),
      navDotsAriaLabel: translateAria(
        translations.NAVIGATION_DOTS_ARIA_LABEL_KEY,
      ),
      slideShowAriaLabel: translateAria(translations.SLIDE_SHOW_ARIA_LABEL_KEY),
    },
  };
};
