import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { getWidthPixelValue } from '@wix/editor-elements-common-utils';
import {
  IRatingsInputMapperProps,
  RatingsInputDefinition,
  RatingsInputPropsCarmiData,
} from '../RatingsInput.types';
import {
  props as viewerPropsMapper,
  css as viewerCssMapper,
} from './RatingsInput.mapper';

const props = composeMapper<
  PreviewWrapperProps<IRatingsInputMapperProps>,
  RatingsInputDefinition,
  RatingsInputPropsCarmiData
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'shouldResetComponent',
      'componentViewMode',
      'compLayout',
      'compSingleLayout',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);

    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      compProps: { shapeSize, shapeSpacing, direction },
      compLayout,
      compSingleLayout,
    } = dependencies;

    const widthValue = getWidthPixelValue(compLayout, compSingleLayout);

    return {
      ...viewerProps,
      previewWrapperProps: {
        compWidth: widthValue,
        componentViewMode,
        shouldResetComponent,
        compPreviewState,
        shapeSize,
        shapeSpacing,
        rtl: direction === 'rtl',
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
  css: viewerCssMapper,
});
