import React from 'react';
import { IHeaderContainerProps } from '../../../HeaderContainer.types';
import BlankScreen from '../../../../ScreenWidthContainer/viewer/skinComps/BaseScreen/BlankScreen';
import HeaderContainer from '../../HeaderContainer';

const BlankScreenHeader: React.FC<
  Omit<IHeaderContainerProps, 'skin'>
> = props => <HeaderContainer {...props} skin={BlankScreen}></HeaderContainer>;

export default BlankScreenHeader;
