import { composeMapper } from '@wix/editor-elements-preview-utils';
import { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  createComponentPreviewMapperModel,
  withCompPreviewInfo,
} from '@wix/editor-elements-integrations';
import {
  FillLayersProps,
  MediaContainerDefinition,
  MediaCSSVars,
  MediaContainerPreviewProps,
} from '@wix/thunderbolt-components-native';
import { MediaContainerProps } from '@wix/editor-elements-definitions';
import type { IMediaContainerPreviewMapperProps } from '../MediaContainer.types';
import { props as viewerPropsMapper } from './MediaContainer.mapper';

const setBackgroundMediaProps = (fillLayers: FillLayersProps) => {
  if (fillLayers.backgroundMedia) {
    const image = fillLayers.image || fillLayers.video?.posterImageInfo;
    const width = image?.containerWidth;
    const height = image?.containerHeight;
    fillLayers.backgroundMedia.containerSize = {
      width,
      height,
    };
  }
};

const setVideoPlaybackProps = (
  fillLayers: FillLayersProps,
  isPlayingAllowed: boolean,
) => {
  if (fillLayers.video) {
    fillLayers.video!.videoInfo.autoPlay =
      fillLayers.video!.videoInfo.autoPlay && isPlayingAllowed;
  }
};

export const getFillLayersPreviewProps = (
  carmiData: MediaContainerPreviewProps,
  {
    isPlayingAllowed,
    componentViewMode,
  }: { isPlayingAllowed: boolean; componentViewMode: string },
): MediaContainerPreviewProps & {
  previewWrapperProps?: {
    autoPlayVideo?: boolean;
    isPlayingAllowed?: boolean;
  };
} => {
  let { fillLayers } = carmiData;
  if (!fillLayers.video && !fillLayers.backgroundMedia) {
    return carmiData;
  }
  fillLayers = JSON.parse(JSON.stringify(fillLayers));
  setBackgroundMediaProps(fillLayers);
  setVideoPlaybackProps(fillLayers, isPlayingAllowed);

  if (fillLayers.video) {
    fillLayers.video.videoInfo.isEditorMode = componentViewMode === 'editor';
  }

  return {
    ...carmiData,
    fillLayers,
    ...(fillLayers.video
      ? {
          previewWrapperProps: {
            autoPlayVideo: fillLayers.video?.videoInfo.autoPlay as boolean,
            isPlayingAllowed,
          },
        }
      : {}),
  };
};

export const props = composeMapper<
  PreviewWrapperProps<
    MediaContainerPreviewProps,
    IMediaContainerPreviewMapperProps
  >,
  MediaContainerDefinition,
  MediaContainerProps
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed', 'compDesign', 'componentViewMode'] },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);
    const { isPlayingAllowed, compDesign, componentViewMode } = deps;
    const borderWidth = compDesign?.cssStyle?.cssBorder.width;
    const fillLayersPreviewProps = getFillLayersPreviewProps(carmiData, {
      isPlayingAllowed,
      componentViewMode,
    });

    const previewWrapperProps =
      fillLayersPreviewProps.previewWrapperProps || {};

    return {
      ...viewerProps,
      ...fillLayersPreviewProps,
      previewWrapperProps: {
        ...previewWrapperProps,
        borderWidth,
      },
    };
  },
);

export const css = withCompPreviewInfo<
  MediaCSSVars,
  MediaContainerDefinition,
  MediaCSSVars
>()(['renderFixedPositionBackgrounds'], (deps, carmiData) => {
  const position = carmiData['--fill-layer-background-media-position'];
  if (position) {
    const isFixed = position === 'fixed' && deps.renderFixedPositionBackgrounds;
    return {
      ...carmiData,
      '--fill-layer-background-media-position': isFixed ? 'fixed' : 'absolute',
    };
  }
  return carmiData;
});

export default createComponentPreviewMapperModel({ props, css });
