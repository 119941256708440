import React from 'react';
import { ToolbarChildrenProps } from 'draft-js-inline-toolbar-plugin';
import InlineStyleButton from '../InlineStyleButton';
import { TestIds } from '../../../../constants';

export default (props: ToolbarChildrenProps) => (
  <InlineStyleButton
    styleType="BOLD"
    dataTestId={TestIds.boldButton}
    {...props}
  >
    <svg
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={19}
      height={19}
      viewBox="0 0 19 19"
    >
      <defs>
        <path
          id="bold-icon-path"
          d="M5.2 4h4.84c.515 0 .983.046 1.406.139.423.092.786.244 1.088.454.302.21.537.49.703.84.166.349.25.78.25 1.294 0 .544-.127 1.004-.379 1.379-.251.375-.614.68-1.088.916.665.196 1.161.537 1.489 1.025.327.488.491 1.076.491 1.764 0 .555-.106 1.035-.317 1.44a2.791 2.791 0 0 1-.854.994 3.745 3.745 0 0 1-1.224.57c-.459.123-.93.185-1.413.185H5.2a.2.2 0 0 1-.2-.2V4.2c0-.11.09-.2.2-.2zm2.051 4.452h2.403c.423 0 .77-.102 1.043-.308.272-.205.408-.539.408-1.001 0-.257-.046-.467-.136-.632a1.017 1.017 0 0 0-.363-.385 1.51 1.51 0 0 0-.521-.193 3.395 3.395 0 0 0-.612-.053H7.25v2.572zm0 4.668H9.79c.232 0 .453-.023.665-.069a1.61 1.61 0 0 0 .559-.231 1.15 1.15 0 0 0 .385-.439c.096-.185.144-.421.144-.709 0-.565-.156-.968-.469-1.21-.312-.24-.725-.361-1.239-.361H7.251v3.02z"
        />
      </defs>
      <g fillRule="evenodd">
        <mask id="bold-icon-mask">
          <use xlinkHref="#bold-icon-path" />
        </mask>
        <use fillRule="nonzero" xlinkHref="#bold-icon-path" />
      </g>
    </svg>
  </InlineStyleButton>
);
