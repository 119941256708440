import classNames from 'clsx';
import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  useCallback,
} from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { useClickOutside } from '@wix/thunderbolt-elements/src/providers/useClickOutside/useClickOutside';
import type { ITinyMenuItemProps } from '../TinyMenu.types';
import type { SkinTinyMenuWrapperProps } from './skinComps/SkinTinyMenu';
import { getItemsWithSelection } from './utils/ItemBehavior';

export interface TinyMenuContentProps {
  containerRef: React.RefObject<HTMLDivElement>;
  buttonRef: React.RefObject<HTMLButtonElement>;
  itemsWithSelection: Array<ITinyMenuItemProps>;
}

type TinyMenuRootProps = Omit<SkinTinyMenuWrapperProps, 'members'> & {
  className: Record<string, string>;
  children: (props: TinyMenuContentProps) => React.ReactNode;
};

const TinyMenuRoot: React.FC<TinyMenuRootProps> = props => {
  const {
    id,
    items,
    ariaLabel,
    textAlignment,
    currentPrimaryPageHref: currentHref,
    activeAnchor,
    className,
    children,
    isHidden,
    onMenuOpen,
    onMenuClose,
    onMouseEnter,
    onMouseLeave,
    isOpen,
    setIsOpen,
  } = props;
  const [wasOpened, setWasOpened] = useState(false);

  const currentAnchor = activeAnchor?.dataId;
  const itemsWithSelection = useMemo(
    () => getItemsWithSelection(items, currentHref, currentAnchor),
    [items, currentHref, currentAnchor],
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const onClickOutside = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
    }
  }, [setIsOpen, isOpen]);

  useClickOutside([containerRef, buttonRef], onClickOutside, true);

  useEffect(() => {
    if (isOpen) {
      if (!wasOpened) {
        onMenuOpen?.();
        setWasOpened(true);
      }
    } else if (wasOpened) {
      onMenuClose?.();
      setWasOpened(false);
    }
  }, [isOpen, wasOpened, onMenuOpen, onMenuClose]);

  return (
    <nav
      id={id}
      {...getDataAttributes(props)}
      className={classNames(className.root, className[textAlignment], {
        [className.menuOpen]: isOpen,
        [className.menuClosed]: !isOpen && wasOpened, // animation of menu closing
        [className.hidden]: isHidden,
      })}
      aria-label={ariaLabel}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children({
        containerRef,
        itemsWithSelection,
        buttonRef,
      })}
    </nav>
  );
};

export default TinyMenuRoot;
