import {
  withCompInfo,
  createComponentMapperModel,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import {
  LoginButtonDefinition,
  LoginButtonCSSVars,
  LoginButtonProps,
  ILoginButtonStateValues,
} from '../LoginButton.types';
import {
  LoginButtonKey,
  LoginButtonKeys,
  translationFeature,
} from './constants';

export const css = withCompInfo<LoginButtonCSSVars, LoginButtonDefinition>()(
  ['compLayout', 'isOneDocMigrated'],
  ({ compLayout, isOneDocMigrated }) => {
    return isOneDocMigrated
      ? {}
      : {
          height: 'auto',
          'min-height': `${compLayout.height}px`,
        };
  },
);

export const props = withCompInfo<
  LoginButtonProps,
  LoginButtonDefinition,
  LoginButtonProps
>()(
  [
    'translate',
    'compId',
    'language',
    'fullNameCompType',
    'trackClicksAnalytics',
  ],
  ({ translate, ...compPropsRest }, carmiData) => {
    const translations: Record<string, string | undefined> = {};
    Object.keys(LoginButtonKeys).forEach(key => {
      translations[key] = translate(
        translationFeature,
        LoginButtonKeys[key as LoginButtonKey],
      )!;
    });
    return {
      ...carmiData,
      ...compPropsRest,
      translations,
    };
  },
);

export const stateRefs = withStateRefsValues<keyof ILoginButtonStateValues>([
  'reportBi',
]);

export default createComponentMapperModel({ css, props, stateRefs });
