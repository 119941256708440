import * as React from 'react';
import {
  TPA3DGalleryProps,
  TPA3DGalleryImperativeActions,
} from '../TPA3DGallery.types';
import TPAGallery from '../../TPAGallery/viewer/TPAGallery';

const TPA3DGallery: React.ForwardRefRenderFunction<
  TPA3DGalleryImperativeActions,
  TPA3DGalleryProps
> = (props, forwardRef) => {
  return <TPAGallery {...props} forwardRef={forwardRef}></TPAGallery>;
};

export default React.forwardRef(TPA3DGallery);
