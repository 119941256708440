import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  RatingsInputCssCarmiData,
  RatingsInputPropsCarmiData,
} from '../../RatingsInput/RatingsInput.types';

import {
  RatingsDisplayDefinition,
  RatingsDisplayPlatformProps,
  RatingsDisplayCSSVars,
  RatingsDisplayProperties,
} from '../RatingsDisplay.types';
import { Translation } from '../constants';

export const props = withCompInfo<
  RatingsDisplayPlatformProps,
  RatingsDisplayDefinition,
  RatingsInputPropsCarmiData
>()(
  ['compData', 'compProps', 'translate'],
  ({ compData, compProps, translate }, carmiProps) => {
    const translations: { [key: string]: string } = {};
    Object.entries(Translation).forEach(([key, value]) => {
      translations[key] =
        translate(value.namespace, value.key) || value.default;
    });
    return {
      ...carmiProps,
      noReviewsMode: compProps.noReviewsMode,
      rating: compData.rating,
      noReviewsPlaceholder: compData.noReviewsPlaceholder,
      numRatings: compData.numRatings,
      reviewsCountLabel: compData.reviewsCountLabel,
      showReviewsCount: compProps.showReviewsCount,
      showRating: compProps.showRating,
      translations,
    };
  },
);

const getMargins = (
  compProps: RatingsDisplayProperties,
  positionAfter: boolean,
  rtl: boolean,
) => {
  const displayLabelsSpacing = `${Math.max(compProps.shapeSpacing, 15)}px`;
  const cssVars: Record<string, string> = {};

  if (compProps.showRating) {
    const ratingMargin = `--ratingMargin${
      (positionAfter && !rtl) || (!positionAfter && rtl) ? 'Left' : 'Right'
    }`;
    cssVars[ratingMargin] = displayLabelsSpacing;
  }

  if (compProps.showReviewsCount) {
    cssVars[`--numReviewsMargin${rtl ? 'Right' : 'Left'}`] = positionAfter
      ? '15px'
      : displayLabelsSpacing;
  }

  return cssVars;
};

export const css = withCompInfo<
  RatingsDisplayCSSVars,
  RatingsDisplayDefinition,
  RatingsInputCssCarmiData
>()(['compData', 'compProps'], ({ compData, compProps }, carmiCss) => {
  const {
    shapeSize,
    shapeSpacing,
    direction,
    alignment,
    ratingPosition,
    showRating,
    showReviewsCount,
  } = compProps;
  const positionAfter = ratingPosition === 'after';
  const rtl = direction === 'rtl';
  const justifyContent = {
    center: 'center',
    left: rtl ? 'flex-end' : 'flex-start',
    right: rtl ? 'flex-start' : 'flex-end',
  };

  const cssVars: RatingsDisplayCSSVars = {
    ...carmiCss,
    '--iconsMinWidth': `${4 * shapeSpacing + 5 * shapeSize}px`,
    '--iconsOrder': positionAfter ? 0 : 2,
    '--displayJustifyContent': justifyContent[alignment],
    '--iconSize': `${shapeSize}px`,
    [`--iconPadding${rtl ? 'Left' : 'Right'}`]: `${shapeSpacing}px`,
    '--direction': direction,
    '--halfIconWidth': `${shapeSize / 2}px`,
  };

  if (rtl) {
    cssVars['--halfIconLeft'] = `${shapeSize / 2}px`;
  }

  if (compData.rating && (showRating || showReviewsCount)) {
    return {
      ...cssVars,
      ...getMargins(compProps, positionAfter, rtl),
    };
  }

  return cssVars;
});

export default createComponentMapperModel({ props, css });
