import React from 'react';
import styles from './Separator.scss';

const Separator = () => {
  return (
    <div className={styles.separatorWrapper}>
      <div className={styles.separator} />
    </div>
  );
};

export default Separator;
