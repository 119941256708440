import React from 'react';

import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import type {
  IComponentPreviewWrapper,
  PreviewWrapperProps,
} from '@wix/editor-elements-types/thunderboltPreview';
import type { ICustomElementComponentProps } from '../types';
import CustomElementPreviewComponent from './CustomElementPreviewComponent/CustomElementPreviewComponent';
import type {
  ICustomElementPreviewComponentImperativeActions,
  ICustomElementPreviewWrapperProps,
} from './CustomElementPreviewComponent/CustomElementPreviewComponent.types';

function withComponentPreview(): IComponentPreviewWrapper<
  ICustomElementComponentProps,
  ICustomElementPreviewWrapperProps
> {
  return React.forwardRef<
    ICustomElementPreviewComponentImperativeActions,
    PreviewWrapperProps<
      ICustomElementComponentProps,
      ICustomElementPreviewWrapperProps
    >
  >((props, ref) => {
    const { previewWrapperProps, ...viewerProps } = props;

    return (
      <CustomElementPreviewComponent
        {...viewerProps}
        {...previewWrapperProps}
        ref={ref}
      />
    );
  });
}

export default () => createComponentPreviewEntry(withComponentPreview());
