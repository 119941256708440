import scopedObjBuilder from '../../scopedObjBuilder';
import type {
  ButtonAlignment,
  ButtonAlignmentStyle,
  ICollapsibleTextCompProps,
  ICollapsibleTextData,
} from './CollapsibleText.types';

export const HelpIds = {
  layoutPanel: 'cd1964dd-5a38-4414-9ab9-53ff6b3daed0',
  editText: '90bb2075-7174-4404-8d80-288c74ee3994',
  settingsPanel: 'e7b6d5d9-6dd6-4e24-b63a-27d30e9efd92',
  gfpp: 'e4059978-3a0a-43f8-ac9c-8b304c536a09',
  mobileGfpp: 'b6d9a270-f9c5-4837-995b-c3818b13a643',
  dataBinding: '313ea5f6-d081-4722-9ef9-62a4423785c1',
  responsive: 'help.gfpp-CollapsibleText',
  design: '75c8ffb6-dd77-494b-88a8-bf922d8c5bce',
};

const collapsibleTextScopedObj = scopedObjBuilder('ellipsis_text');
const stylableScopedObj = scopedObjBuilder('StylablePanel');
const dataBindingScopedObj = scopedObjBuilder('Connect_Panel');

export const TestIds = {
  ...collapsibleTextScopedObj('layout', [
    'root',
    'lines_display_thumbnails',
    'lines_show_ellipsis_switch',
    'max_lines_label',
    'max_lines_input',
    'button_spacing_slider',
    'button_spacing_input',
    'expand_mode_thumbnails',
    'align_thumbnail_left',
    'align_thumbnail_center',
    'align_thumbnail_right',
  ]),
  ...collapsibleTextScopedObj('settings', [
    'edit_text_input',
    'overflow_radio_buttons',
    'link_content_radio_button',
    'expand_radio_button',
    'link_placeholder_text',
    'read_more_button_toggle',
    'button_placeholder_text',
    'collapsed_text_label',
    'collapsed_text_input',
    'expanded_text_label',
    'link_read_more_label',
    'seo_accessibility_dropdown',
  ]),
  ...collapsibleTextScopedObj('viewer', [
    'root',
    'text_as_link',
    'expand_control',
  ]),
} as const;

export const TranslationKeys = {
  ...collapsibleTextScopedObj('settings', [
    'title',
    'edit_text_label',
    'section_label',
    'overflow_label',
    'overflow_tooltip',
    'link_content_radio_button',
    'expand_radio_button',
    'link_label',
    'link_placeholder_text',
    'read_more_button_toggle',
    'button_label',
    'button_placeholder_text',
    'default_text',
    'collapsed_text_label',
    'collapsed_text_placeholder',
    'expand_text_placeholder',
    'expand_text_label_expanded',
    'disabled_notification_link',
    'inputfield_empty_error',
    'button_does_label',
    'button_expand_text_button',
    'button_link_text_button',
    'SEO_accessibility_banner_label',
    'SEO_accessibility_alt_text_label',
    'SEO_accessibility_alt_text_box',
  ]),
  ...collapsibleTextScopedObj('layout', [
    'title',
    'display_label',
    'display_multiple_rows',
    'display_single_row',
    'display_max_rows',
    'show_ellipsis_toggle',
    'spacing_slider',
    'show_more_text_title',
    'thumbnail_expand_label',
    'thumbnail_click_label',
    'thumbnail_show_all_label',
    'show_button_toggle',
    'align_button_label',
    'single_line_text_toggle',
    'text_appearance_label',
    'thumbnail_collapsed_label',
    'thumbnail_Show_All_Text_label',
  ]),
  ...collapsibleTextScopedObj('db', [
    'comp_name',
    'manage_content',
    'edit_text_label',
  ]),
  ...stylableScopedObj('Design', [
    'Dropdown_Ellipsis_Text_text_label',
    'Dropdown_Ellipsis_Text_button_label',
    'Header_Ellipsis_Text',
    'main_gfpp_label',
    'State_Dropdown_Regular',
    'State_Dropdown_Hover',
    'State_Dropdown_Disabled',
    'Text_Tab_Title',
  ]),
  ...collapsibleTextScopedObj('Main', ['gfpp_label']),
  ...dataBindingScopedObj('Title', ['CollapsibleText']),
  ...dataBindingScopedObj('Connect', ['CollapsibleText_Label']),
} as const;

export const translationStylableLabel = 'ellipsis_text_design_main_gfpp_label';
export const translationSeoTagName = 'text_editor_tag_label';

export const defaultTestCompProps: ICollapsibleTextCompProps = {
  text: 'Test',
  readMoreText: 'Read More Text',
  readLessText: 'Read Less Text',
  linkText: 'Read More',
  showExpandControl: false,
  isExpanded: false,
  ellipsis: true,
  maxLines: 3,
  wrappingStyle: 'multi-line',
  expandMode: 'link',
  align: 'left',
  alignButton: 'left',
  id: 'mock-collapsible-text-id',
  expandControlSpacing: 20,
  direction: 'ltr',
  type: 'mock-type',
  tag: 'p',
  setIsExpanded: () => {},
  reportBiOnLinkClick: () => {},
  reportBiOnExpandClick: () => {},
  lastUserDefinedWidth: 300,
  textDescription: 'this is food blog text',
  stylableClassName: '',
};

export const defaultCompData: ICollapsibleTextData = {
  id: '',
  type: 'CollapsibleText',
  text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec tempor iaculis enim, venenatis imperdiet justo sollicitudin eget. Aliquam molestie ligula vitae maximus euismod. Quisque et erat et neque eleifend posuere eget et dolor. Sed dolor leo, varius in cursus eu, vestibulum vitae felis. Morbi pellentesque ex sed mi luctus vestibulum.',
  readMoreText: 'Read more',
  readLessText: 'Read less',
  linkText: 'Read more',
  tag: 'p',
  textDescription: '',
};

export const defaultTestPanelProps = {
  compData: defaultCompData,
  compProperties: defaultTestCompProps,
};

export const ActionIds = {
  ...collapsibleTextScopedObj('dataBinding', ['gfpp_edit_text']),
};
// TODO: Replace with a translation key
export const actionTypeError = `The requested operation is invalid. Try changing the "readMoreActionType" property first.`;

export const ViewerType = 'wixui.CollapsibleText' as const;
export const responsiveAddPanelAutomationId =
  'CollapsibleText-add-panel-editor-x';

export const collapsibleTextExperiment =
  'specs.responsive-editor.CollapsibleText';

export const expandControlDesignSectionsExperiment =
  'se_CollapsibleTextExpandButtonDesignPanel';

export const QUICK_EDIT_CONTROL_DATA_HOOKS = {
  THUMBNAIL: 'collapsible-text-background-quick-edit-control-thumbnail',
  SETTINGS_BUTTON: 'collapsible-text-quick-edit-settings-button',
};

export const enum PanelsNames {
  Layout = 'wixui.CollapsibleText.layoutPanel',
  Settings = 'wixui.CollapsibleText.settingsPanel',
}

export const enum ExpandModeValues {
  Link = 'link',
  Expand = 'button',
}

export const expandModeOptions = [
  {
    value: ExpandModeValues.Expand,
    label: TranslationKeys.settings.button_expand_text_button,
  },
  {
    value: ExpandModeValues.Link,
    label: TranslationKeys.settings.button_link_text_button,
  },
];

export const alignmentToStyle: Record<ButtonAlignment, ButtonAlignmentStyle> = {
  center: 'center',
  right: 'flex-end',
  left: 'flex-start',
};

export const LAYOUT_PANEL_EXPAND_CONTROL_SPACING_DEFAULT_VALUE = 0;
