import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  FreemiumBannerResponsiveDefinition,
  FreemiumBannerResponsiveMapperProps,
} from '../FreemiumBannerResponsive.types';
import { texts, namespace, logoPlaceHolder } from './constants';

export const props = withCompInfo<
  FreemiumBannerResponsiveMapperProps,
  FreemiumBannerResponsiveDefinition
>()(
  ['direction', 'translate', 'uiType'],
  ({ direction, translate, uiType }) => {
    const activeUiTypeConstants =
      uiType === 'Studio' ? texts.studio : texts.responsive;
    const mainText =
      translate(namespace, activeUiTypeConstants.title.translationKey) ||
      activeUiTypeConstants.title.defaultText;
    const [textBeforeLogo, textAfterLogo] = mainText.split(logoPlaceHolder);
    const logoText =
      translate(namespace, activeUiTypeConstants.logoText.translationKey) ||
      activeUiTypeConstants.logoText.defaultText;
    return {
      useOverlay: false,
      direction,
      href: activeUiTypeConstants.campaignLink,
      textBeforeLogo,
      textAfterLogo,
      logoText,
    };
  },
);

export default createComponentMapperModel({ props });
