import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ITabsMapperProps,
  ITabControllerProps,
  ITabsProps,
  ITabsStateRefValues,
} from '../Tabs.types';

export default withCompController<
  ITabsMapperProps,
  ITabControllerProps,
  ITabsProps,
  ITabsStateRefValues
>(({ mapperProps, stateValues, controllerUtils }) => {
  return {
    ...mapperProps,
    setCurrentTabId: currentTabId => {
      controllerUtils.updateProps({ currentTabId });
    },
    observeChildListChange: mapperProps.isMasterPage
      ? stateValues.observeChildListChangeMaster
      : stateValues.observeChildListChange,
  };
});
