import * as React from 'react';
import classnames from 'clsx';
import { VolumeBars, Mute } from '@wix/soundhead-react';
import { TestHooks } from '../../constants';
import style from './VolumeControls.scss';

const VolumeControls: React.FC<{
  className?: string;
  isMute: boolean;
}> = props => {
  const { isMute, className } = props;

  return (
    <div className={className}>
      <Mute
        className={classnames(style.button, style.volumeBtn)}
        dataHook={TestHooks.mute}
        iconMute={
          <span
            className={classnames(style.mediaButtonIcon, style.unmuted)}
            data-testid={TestHooks.muteIcon}
          ></span>
        }
        iconUnMute={
          <div className={classnames(style.volumeBtn, style.muted)}>
            <span
              className={classnames(style.mediaButtonIcon, style.muted)}
              data-testid={TestHooks.unMuteIcon}
            ></span>
          </div>
        }
      />
      <VolumeBars
        dataHook={TestHooks.volume}
        className={classnames(
          style.volumeScale,
          isMute ? style.muted : style.unmuted,
        )}
        barsCount={5}
        iconBarOn={
          <div className={style.on} data-testid={TestHooks.volumeBarOn}>
            <div className={style.colorBlock} />
            <div className={style.colorBlank} />
          </div>
        }
        iconBarOff={
          <div className={style.off} data-testid={TestHooks.volumeBarOff}>
            <div className={style.colorBlock} />
            <div className={style.colorBlank} />
          </div>
        }
      />
    </div>
  );
};

export default VolumeControls;
