import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import {
  composeMapper,
  getSocialCompBlockingLayerProps,
} from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import {
  PayPalButtonDefinition,
  PayPalButtonMapperProps,
} from '../PayPalButton.types';
import { props as viewerPropsMapper } from './PayPalButton.mapper';

const props = composeMapper<
  PreviewWrapperProps<PayPalButtonMapperProps>,
  PayPalButtonDefinition
>()(
  viewerPropsMapper,
  {
    add: ['isSocialInteractionAllowed'],
    remove: ['compId', 'fullNameCompType', 'trackClicksAnalytics'],
  },
  (resolver, deps, carmiData) => {
    const viewerProps = resolver(deps, carmiData);

    return {
      ...viewerProps,
      trackClicksAnalytics: false,
      previewWrapperProps: {
        ...getSocialCompBlockingLayerProps(
          deps.isSocialInteractionAllowed,
          true,
        ),
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
