import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  convertPhysicalAlignmentToLogical,
  LogicalAlignment,
  migrateFields,
} from '@wix/editor-elements-common-utils';
import {
  DatePickerDefinition,
  DatePickerCssCarmiData,
  DatePickerSkin,
  DatePickerMapperOverrides,
} from '../../DatePicker/DatePicker.types';
import { isMobileViewOrDevice } from '../../DatePicker/viewer/DatePicker.mapper';
import {
  CalendarNavButtonMarginVars,
  DatePickerCalendarCSSVars,
} from '../DatePickerCalendar.types';

const calendarNavButtonMarginVars = (
  skin: DatePickerSkin,
  isMobile: boolean,
): CalendarNavButtonMarginVars => {
  switch (skin) {
    case 'DatePickerTextBetweenNavSkin': {
      if (isMobile) {
        return {
          '--calendarNavYearPrevMargin_end': '-6px',
          '--calendarNavYearNextMargin_start': '-10px',
          '--calendarNavMonthPrevMargin_start': '6px',
          '--calendarNavMonthPrevMargin_end': '-10px',
          '--calendarNavMonthNextMargin_start': '-10px',
          '--calendarNavMonthNextMargin_end': '54px',
        };
      } else {
        return {
          '--calendarNavMonthPrevMargin_start': '25px',
          '--calendarNavMonthNextMargin_end': '20px',
        };
      }
    }
    case 'DatePickerTextYearNavSkin': {
      if (isMobile) {
        return {
          '--calendarNavMonthPrevMargin_start': '12px',
          '--calendarNavMonthPrevMargin_end': '-10px',
          '--calendarNavMonthNextMargin_start': '-10px',
          '--calendarNavMonthNextMargin_end': '12px',
        };
      } else {
        return {
          '--calendarNavMonthPrevMargin_start': '10px',
          '--calendarNavMonthNextMargin_end': '10px',
        };
      }
    }
    default: {
      if (isMobile) {
        return {};
      } else {
        return {
          '--calendarNavMonthPrevMargin_start': '5px',
          '--calendarNavMonthNextMargin_end': '5px',
        };
      }
    }
  }
};

const calendarPortalWidth = '320px';

const getCalendarInset = (align: LogicalAlignment) => {
  if (align === 'center') {
    return { '--calendarInset_start': 'calc(50% - 160px)' };
  }
  return align === 'start'
    ? { '--calendarInset_start': '0' }
    : { '--calendarInset_end': '0' };
};

export const css = withCompInfo<
  DatePickerCalendarCSSVars,
  DatePickerDefinition,
  DatePickerCssCarmiData,
  DatePickerMapperOverrides
>()(
  [
    'skin',
    'compData',
    'compProps',
    'isMobileView',
    'deviceType',
    'experiments',
    'styleProperties',
  ],
  (
    { skin, isMobileView, deviceType, experiments, styleProperties, compData },
    cssVars,
  ) => {
    const isMobile = isMobileViewOrDevice(isMobileView, deviceType);
    const withCalendarPortal =
      experiments['specs.thunderbolt.DatePickerPortal'] === 'new' ||
      experiments['specs.thunderbolt.DatePickerPortal'] === true;

    const nonMobileWidth = withCalendarPortal
      ? { width: calendarPortalWidth }
      : {};

    const calendarWidth = isMobile ? { width: '100%' } : nonMobileWidth;

    const { align } = styleProperties;

    const { direction = 'inherit' } = compData;

    return {
      '--calendarMarginTop': isMobile || withCalendarPortal ? '0' : '22px',
      '--direction': direction,
      'align-items': isMobile ? 'center' : align,
      ...getCalendarInset(align),
      ...cssVars,
      ...calendarNavButtonMarginVars(skin, isMobile),
      ...calendarWidth,
    };
  },
  [
    migrateFields([
      {
        sourceNamespace: 'compProps',
        targetNamespace: 'styleProperties',
        fields: [{ source: 'textAlignment', target: 'align' }],
        enhancer: convertPhysicalAlignmentToLogical,
      },
    ]),
  ],
);

export default createComponentMapperModel({ css });
