import * as React from 'react';
import { SkinFiveGridLineProps } from '../SkinFiveGridLine';

// if skin is unknown, don't try to render it
// example issue: https://jira.wixpress.com/browse/ECL-626 (skin undefined)
const SkinNotFound: React.FC<SkinFiveGridLineProps> = props => {
  const { id, className } = props;
  return <div id={id} className={className} />;
};

export default SkinNotFound;
