import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useGesture } from '@wix/thunderbolt-elements/src/providers/useGesture/useGesture';
import type {
  ISlideshowButtonAction,
  ISlideshowContextProviderProps,
  ISlideshowContextValue,
} from './types';

const SlideshowContext = createContext<ISlideshowContextValue>({
  currentIndex: 0,
  previousIndex: 0,
  slidesLength: 0,
  navigatingForward: true,
  isSlideshowInViewport: false,
  setSlidesLength: () => {},
  goToIndex: () => {},
  onAnimationEnd: () => {},
  onButtonClick: () => {},
  onIndicatorClick: () => {},
  isButtonDisabled: () => false,
  setIsSlideshowInViewport: () => {},
});

export const useSlideshowContext = () => useContext(SlideshowContext);

export const useUpdateSlidesLength = (length: number) => {
  const { setSlidesLength } = useSlideshowContext();
  useEffect(() => setSlidesLength(length), [setSlidesLength, length]);
};

export const SlideshowContextProvider: React.FC<
  ISlideshowContextProviderProps
> = ({
  children,
  currentIndex,
  setCurrentIndex,
  loop,
  navigatingForward,
  isPlaying,
  isHovered,
  isFocused,
  slideDuration,
  containerRef,
  pauseOnHover,
}) => {
  const [previousIndex, setPreviousIndex] = useState(currentIndex);
  const [slidesLength, setSlidesLength] = useState<number>(0);
  const [isSlideshowInViewport, setIsSlideshowInViewport] =
    useState<boolean>(false);
  const isAnimationOn = previousIndex !== currentIndex;

  const getBtnAction = (
    btn: ISlideshowButtonAction,
  ): ISlideshowButtonAction => {
    // TODO maybe will need to switch button action for RTL
    return btn;
  };
  const goToIndex = useCallback(
    (step: number, goingForward: boolean) => {
      if (isAnimationOn) {
        return;
      }
      setPreviousIndex(currentIndex);
      setCurrentIndex({
        currentIndex:
          currentIndex + step < 0
            ? slidesLength + step
            : (currentIndex + step) % slidesLength,
        navigatingForward: goingForward,
      });
    },
    [currentIndex, isAnimationOn, setCurrentIndex, slidesLength],
  );

  useEffect(() => {
    const shouldStopOnLastSlide = !loop && slidesLength - 1 === currentIndex;
    const autoplayPausedByUser = pauseOnHover && (isHovered || isFocused);
    if (
      !isPlaying ||
      isAnimationOn ||
      !isSlideshowInViewport ||
      autoplayPausedByUser ||
      slidesLength < 2 ||
      shouldStopOnLastSlide
    ) {
      return;
    }
    const timeout = setTimeout(() => {
      goToIndex(1, true);
    }, slideDuration * 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [
    currentIndex,
    goToIndex,
    isAnimationOn,
    isFocused,
    isHovered,
    isPlaying,
    isSlideshowInViewport,
    loop,
    slideDuration,
    slidesLength,
    pauseOnHover,
  ]);

  const onAnimationEnd = () => {
    setPreviousIndex(currentIndex);
  };

  const onButtonClick = React.useCallback(
    (btn: ISlideshowButtonAction) => {
      const step = getBtnAction(btn) === 'next' ? 1 : -1;
      goToIndex(step, step > 0);
    },
    [goToIndex],
  );

  const onSwipeLeft = React.useCallback(() => {
    onButtonClick('next');
  }, [onButtonClick]);

  const onSwipeRight = React.useCallback(() => {
    onButtonClick('prev');
  }, [onButtonClick]);

  useGesture('onSwipeLeft', onSwipeLeft, containerRef);
  useGesture('onSwipeRight', onSwipeRight, containerRef);

  const onIndicatorClick = (index: number) => {
    const step = index - currentIndex;
    goToIndex(step, step > 0);
  };

  const isButtonDisabled = useCallback(
    (btn: ISlideshowButtonAction) => {
      if (loop) {
        return false;
      }
      const action = getBtnAction(btn);
      return action === 'next'
        ? currentIndex === slidesLength - 1
        : currentIndex === 0;
    },
    [currentIndex, loop, slidesLength],
  );

  const contextValue: ISlideshowContextValue = {
    currentIndex,
    previousIndex,
    slidesLength,
    setSlidesLength,
    goToIndex,
    onAnimationEnd,
    onButtonClick,
    onIndicatorClick,
    isButtonDisabled,
    navigatingForward,
    isSlideshowInViewport,
    setIsSlideshowInViewport,
  };

  return (
    <SlideshowContext.Provider value={contextValue}>
      {children}
    </SlideshowContext.Provider>
  );
};
