
  var rt = require("@stylable/runtime/dist/css-runtime-stylesheet.js");

  module.exports = rt.create(
      "SlideshowIndicatorcomponent1180020854",
      {"classes":{"root":"SlideshowIndicatorcomponent1180020854__root","iconsList":"SlideshowIndicatorcomponent1180020854__iconsList","icon":"SlideshowIndicatorcomponent1180020854__icon"},"vars":{},"stVars":{"icons-spacing":"var(--icons-spacing)","numbers-spacing":"var(--numbers-spacing)"},"keyframes":{},"layers":{}},
      "",
      -1,
      module.id
  );
  