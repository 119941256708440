export const TranslationKeys = {
  layoutPanel: {
    directionHorizontal: 'slider_layout_direction_horizontal',
    directionVertical: 'slider_layout_direction_vertical',
    labelShowNever: 'slider_layout_value_label_show_never',
    labelShowAlways: 'slider_layout_value_label_show_always',
    labelShowOnHover: 'slider_layout_value_label_show_on_hover',
    labelPositionTop: 'slider_layout_value_label_position_top',
    labelPositionBottom: 'slider_layout_value_label_position_bottom',
    labelPositionLeft: 'slider_layout_value_label_position_left',
    labelPositionRight: 'slider_layout_value_label_position_right',
    stepsPositionTop: 'slider_layout_steps_position_top',
    stepsPositionBottom: 'slider_layout_steps_position_bottom',
    stepsPositionMiddle: 'slider_layout_steps_position_middle',
    stepsPositionLeft: 'slider_layout_steps_position_left',
    stepsPositionRight: 'slider_layout_steps_position_right',
    stepsShowNone: 'slider_layout_steps_show_none',
    stepsShowLines: 'slider_layout_steps_show_lines',
    stepsShowDots: 'slider_layout_steps_show_dots',
    labelPadding: 'slider_layout_label_padding',
    labelMargin: 'slider_layout_label_margin',
    handleShapesLabel: 'slider_layout_handle_shapes_label',
    handleSizeLabel: 'slider_layout_handle_size_label',
    trackSizeLabel: 'slider_layout_track_size_label',
    alignmentLabel: 'Slider_Layout_Alignment_Label',
    stepsSectionTitle: 'slider_layout_steps_section_title',
    stepsShowLabel: 'slider_layout_steps_show_label',
    stepsPositionLabel: 'slider_layout_steps_position_label',
    tooltip: 'slider_layout_value_label_tooltip',
    sectionTitle: 'slider_layout_value_label_section_title',
    showLabel: 'slider_layout_value_label_show_label',
    positionLabel: 'slider_layout_value_label_position_label',
    tooltipPrefixInfo: 'Slider_Layout_Tooltip_Prefix_Info',
    prefixLabel: 'slider_layout_value_label_prefix_label',
    prefixPlaceholder: 'Slider_layout_value_label_prefix_placeholder',
    tooltipSuffixInfo: 'Slider_Layout_Tooltip_Suffix_Info',
    suffixLabel: 'slider_layout_value_label_suffix_label',
    suffixPlaceholder: 'slider_layout_value_label_suffix_placeholder',
    title: 'slider_layout_title',
  },
  settingsPanel: {
    setStepsValue: 'slider_settings_set_steps_value',
    setStepsNumber: 'slider_settings_set_steps_number',
    sliderTypeContinuous: 'slider_settings_slider_type_continuous',
    sliderTypeStepped: 'slider_settings_slider_type_stepped',
    setStepsValueLabel: 'slider_settings_set_steps_value_step_value_label',
    setStepsNumberLabel:
      'slider_settings_set_steps_number_number_of_steps_label',
    tooltipText: 'slider_settings_label_empty_warning',
    linkLabel: 'slider_settings_label_empty_warning_link',
    textLabelText: 'slider_settings_label_text_label',
    textInputPlaceholder: 'slider_settings_label_placeholder',
    sliderTypeTooltip: 'slider_settings_slider_type_tooltip',
    sliderTypeLabel: 'slider_settings_slider_type_label',
    setStepsTooltip: 'slider_settings_set_steps_tooltip',
    setStepsLabel: 'slider_settings_set_steps_label',
    valueRangeTooltip: 'slider_settings_value_range_tooltip',
    valueRangeSectionTitle: 'slider_settings_value_range_section_title',
    valuePlaceholder: 'Input_Controls_Value_Placeholder',
    minimumValueLabel: 'slider_settings_value_range_minimum_value_label',
    maximumValueLabel: 'slider_settings_value_range_maximum_value_label',
    defaultValueLabel: 'slider_settings_value_range_default_value_label',
    generalSettingsLabel: 'Input_Controls_General_Settings_Label',
    readOnlyCheckbox: 'Input_Controls_General_Settings_Read_Only_Checkbox',
    title: 'slider_settings_title',
  },
};

export const DataHooks = {
  layoutPanel: {
    labelPadding: 'labelPadding',
    labelMargin: 'labelMargin',
    thumbShape: 'thumbShape',
    thumbSize: 'thumbSize',
    trackSize: 'trackSize',
    alignment: 'alignment',
    tickMarksShape: 'tickMarksShape',
    tickMarksPosition: 'tickMarksPosition',
    tooltipVisibility: 'tooltipVisibility',
    tooltipPosition: 'tooltipPosition',
    tooltipPrefix: 'tooltipPrefix',
    tooltipSuffix: 'tooltipSuffix',
  },
  settingsPanel: {
    textInput: 'slider-settings--text-input',
    sliderTypeSelector: 'slider-settings-type-selector',
    stepTypeDropdown: 'slider-settings-step-type-dropdown',
    stepInput: 'slider-settings-step-input',
    minInput: 'slider-settings-min-input',
    maxInput: 'slider-settings-max-input',
    valueInput: 'slider-settings-value-input',
    requiredCheckbox: 'slider-settings-required-checkbox',
  },
};

export const enum HelpIds {
  DataConnection = 'b8ec278c-78a9-463f-b3ff-9112ed9e7e40',
}

export const enum TranslationsKeys {
  PanelTitle = 'Connect_Panel_Title_Slider',
  ConnectTitle = 'Connect_Panel_Connect_Slider_Value_Label',
}

export const CONTINUOUS_STEP = 0.01;

export const TestIds = {
  root: 'root',
  track: 'track',
  ticksWrapper: 'ticks-wrapper',
  tick: 'tick',
  tooltip: 'tooltip',
  thumb: 'thumb',
} as const;

export const Sizes = {
  tickLineHeight: 10,
  tickDotSize: 3,
  tickMarksGap: 12,
  tickLineWidth: 1,
} as const;
