import React from 'react';
import classnames from 'clsx';
import { platformIds } from '../constants';
import type { CopyButtonWrapperProps } from '../types';
import { copyToClipboard } from '../utils/clipBoard';
import { classes, st } from './style/ShareButton.component.st.css';
import { classes as shareButtonsClasses } from './style/ShareButtons.component.st.css';

import Checkmark from '../assets/checkmark.svg';

const TOOLTIP_HIDE_TIMEOUT = 5000;
const ARROW_SIZE = 10;

const CopyButtonTooltip = ({ children }: { children: React.ReactNode }) => {
  const tooltipRef = React.useRef<HTMLDivElement>(null);
  const [tooltipTopPosition, setTooltipTopPosition] = React.useState(0);

  React.useEffect(() => {
    if (tooltipRef.current && tooltipRef.current.clientHeight > 0) {
      setTooltipTopPosition(tooltipRef.current.clientHeight + ARROW_SIZE);
    }
  }, [tooltipRef.current?.clientHeight]);

  return (
    <div className={st(classes.tooltipWrapper)}>
      <div
        role="tooltip"
        className={st(classes.tooltip)}
        style={{ top: `-${tooltipTopPosition}px` }}
        ref={tooltipRef}
        data-testid="copy-link-tooltip"
      >
        <span>{children}</span>
        <span className={st(classes.tooltipArrow)} />
      </div>
      <span className={st(classes.root, shareButtonsClasses.button)}>
        <span className={st(classes.icon)}>
          <Checkmark />
        </span>
      </span>
    </div>
  );
};

const CopyButtonWrapper: React.FC<CopyButtonWrapperProps> = ({
  id,
  children,
  url,
  ariaLabel,
  onCopyButtonClicked,
  linkCopiedText,
  isTooltipForCopyToClipboardEnabled,
}) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const _onClick = async () => {
    await copyToClipboard(decodeURI(url));
    onCopyButtonClicked();
    if (isTooltipForCopyToClipboardEnabled) {
      setShowTooltip(true);
    }
  };

  React.useEffect(() => {
    let hideTooltipTimeout: NodeJS.Timeout;

    if (showTooltip) {
      hideTooltipTimeout = setTimeout(() => {
        setShowTooltip(false);
      }, TOOLTIP_HIDE_TIMEOUT);
    }

    return () => {
      clearTimeout(hideTooltipTimeout);
    };
  }, [showTooltip]);

  const displayTooltip = showTooltip && isTooltipForCopyToClipboardEnabled;

  const classname = classnames(st(shareButtonsClasses.container), {
    [st(classes.showTooltip)]: displayTooltip,
  });

  return (
    <div className={classname}>
      {displayTooltip ? (
        <CopyButtonTooltip>{linkCopiedText}</CopyButtonTooltip>
      ) : null}
      <div className={st(classes.copyButtonWrapper)}>
        <button
          id={id}
          className={st(classes.root, shareButtonsClasses.button)}
          data-testid={platformIds.copyButton}
          onClick={_onClick}
          aria-label={ariaLabel}
          aria-live="assertive"
        >
          {children}
        </button>
      </div>
    </div>
  );
};

export default CopyButtonWrapper;
