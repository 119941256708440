import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import {
  HeaderSectionDefinition,
  HeaderSectionCss,
  HeaderSectionProps,
} from '../HeaderSection.types';

export const props = withCompInfo<
  HeaderSectionProps,
  HeaderSectionDefinition,
  HeaderSectionProps
>()([], (_, carmiData) => {
  return carmiData;
});

export const css = withCompInfo<
  HeaderSectionCss,
  HeaderSectionDefinition,
  HeaderSectionCss
>()([], (_, carmiCss) => {
  return {
    ...carmiCss,
    '--inherit-transition': 'var(--transition, none)',
  };
});

export default createComponentMapperModel({ props, css });
