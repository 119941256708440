import * as React from 'react';
import { createPortal } from 'react-dom';
import { usePortalPopper } from '../../../../providers/usePortalPopper';
import style from '../styles/TooltipAndTexts.scss';
import CookieIcon from '../assets/CookieIcon.svg';
import { LazyTooltipBox } from './LazyTooltipBox';

export type TooltipProps = {
  id: string;
  datahook: string;
  text: string;
  ariaLabelText: string;
  isMobile: boolean;
  onSettingsClick: () => void;
};

export const Tooltip: React.FC<TooltipProps> = ({
  id,
  datahook,
  text,
  ariaLabelText,
  isMobile,
  onSettingsClick,
}) => {
  const [arrowElement, setArrowElement] = React.useState<HTMLDivElement | null>(
    null,
  );
  const [showPopper, setShowPopper] = React.useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = React.useState(false);
  const [isAnimationEnd, setIsAnimationEnd] = React.useState(false);
  const [loadTooltip, setLoadTooltip] = React.useState(false);

  const tooltipButtonRef = React.useRef<HTMLButtonElement>(null);
  const compId = `${id}-${datahook}`;

  const {
    setRef: setPopperSourceEl,
    setPopper: setPopperTargetElem,
    styles: popperStyles,
    attributes,
    poppersWrapper,
    popper,
  } = usePortalPopper({
    id: `${compId}-tooltip`,
    containerId: 'SITE_CONTAINER',
    shouldMountWrapper: true,
    popperOptions: {
      placement: 'top',
      modifiers: [
        {
          name: 'arrow',
          options: { element: arrowElement },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 12],
          },
        },
        {
          name: 'flip',
          options: {
            fallbackPlacements: ['auto'],
          },
        },
      ],
    },
  });

  React.useEffect(() => {
    // move focus to tooltip online after the tooltip animation ended and tooltip is clicked
    if (showPopper && isTooltipClicked && isAnimationEnd) {
      popper?.focus();
      // to cancel the transtion when tooltip closes
      popper?.classList.add(style.tooltipEntered);
    }
  }, [showPopper, isTooltipClicked, popper, isAnimationEnd]);

  const showPopperTooltip = () => setShowPopper(true);
  const hidePopperTooltip = () => setShowPopper(false || isTooltipClicked);

  const tooltipButtonOnClick: React.PointerEventHandler<
    HTMLButtonElement
  > = () => {
    setShowPopper(!showPopper);
    setIsTooltipClicked(!isTooltipClicked);
    setIsAnimationEnd(false);
    setLoadTooltip(true);
  };

  const tooltipButtonOnMouseLeave: React.PointerEventHandler<
    HTMLButtonElement
  > = () => {
    if (!isMobile) {
      setShowPopper(false);
    }
    // on mouse leave reset isTooltipClicked to false
    setIsTooltipClicked(false);
  };

  const tooltipButtonOnMouseEnter: React.PointerEventHandler<
    HTMLButtonElement
  > = () => {
    if (!isMobile) {
      setLoadTooltip(true);
      showPopperTooltip();
    }
  };

  const closeTooltip = () => {
    setShowPopper(false);
    setIsTooltipClicked(false);
    tooltipButtonRef?.current?.focus();
  };

  const tooltipOnKeyUp: React.KeyboardEventHandler<HTMLDivElement> = e => {
    if (e.key === 'Escape') {
      closeTooltip();
    }
  };

  return (
    <>
      {poppersWrapper &&
        loadTooltip &&
        createPortal(
          <React.Suspense fallback={null}>
            <LazyTooltipBox
              compId={compId}
              text={text}
              isMobile={isMobile}
              showPopper={showPopper}
              setPopperTargetElem={setPopperTargetElem}
              setIsTooltipClicked={setIsTooltipClicked}
              setShowPopper={setShowPopper}
              setArrowElement={setArrowElement}
              onSettingsClick={onSettingsClick}
              popperStyles={popperStyles}
              tooltipButtonRef={tooltipButtonRef}
              showPopperTooltip={showPopperTooltip}
              hidePopperTooltip={hidePopperTooltip}
              attributes={attributes}
              tooltipOnKeyUp={tooltipOnKeyUp}
              setIsAnimationEnd={setIsAnimationEnd}
            ></LazyTooltipBox>
          </React.Suspense>,
          poppersWrapper,
        )}
      <button
        id={`${compId}-button`}
        className={style.tooltipButton}
        onMouseEnter={tooltipButtonOnMouseEnter}
        onMouseLeave={tooltipButtonOnMouseLeave}
        onBlur={hidePopperTooltip}
        onClick={tooltipButtonOnClick}
        aria-label={ariaLabelText}
        aria-expanded={showPopper}
        ref={tooltipButtonRef}
      >
        <div
          data-hook={`${compId}-tooltip-button`}
          className={style.tooltipIconWrapper}
          ref={setPopperSourceEl}
        >
          <CookieIcon></CookieIcon>
        </div>
      </button>
    </>
  );
};
