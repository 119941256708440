import classNames from 'clsx';
import React from 'react';

import { ITextInputImperativeActions } from '../../../TextInput/TextInput.types';
import { ISearchBoxProps } from '../../SearchBox.types';
import { SearchBoxLayout } from '../searchBoxLayout';

import style from './SearchBoxForm.scss';

export type ISearchBoxFormProps = Pick<
  ISearchBoxProps,
  | 'changeValue'
  | 'id'
  | 'isDisabled'
  | 'onAutocomplete'
  | 'onBlur'
  | 'onChange'
  | 'onClick'
  | 'onDblClick'
  | 'onFocus'
  | 'onKeyPress'
  | 'placeholder'
  | 'defaultFormAction'
> & {
  clearButtonLabel: string;
  dataTestId: string;
  expandedDesktopSuggestions?: boolean;
  handleKeyDown: (e: React.KeyboardEvent) => void;
  handleSubmit: React.FormEventHandler<HTMLFormElement>;
  inputRef: React.RefObject<ITextInputImperativeActions>;
  autocompleteValue: string;
  value: string;
  clearValue(): void;
  isModalSuggestionsShown: boolean;
};

export const SearchBoxForm: React.FC<ISearchBoxFormProps> = ({
  autocompleteValue,
  changeValue,
  clearButtonLabel,
  clearValue,
  dataTestId,
  expandedDesktopSuggestions,
  handleKeyDown,
  handleSubmit,
  id,
  inputRef,
  isDisabled,
  onAutocomplete,
  onBlur,
  onChange,
  onClick,
  onDblClick,
  onFocus,
  onKeyPress,
  placeholder,
  defaultFormAction,
  value,
  isModalSuggestionsShown,
}) => {
  return (
    <form
      className={classNames(style.container, {
        [style.expanded]: !!expandedDesktopSuggestions,
        [style.withAutocomplete]: !!autocompleteValue,
      })}
      data-testid={dataTestId}
      onSubmit={handleSubmit}
      role="search"
      action={defaultFormAction}
      onClick={() => inputRef.current?.focus()}
      onKeyDown={handleKeyDown}
    >
      <SearchBoxLayout
        clearButtonLabel={clearButtonLabel}
        isDisabled={isDisabled}
        id={id}
        inputRef={inputRef}
        onBlur={onBlur}
        clearValue={clearValue}
        onClick={onClick}
        onDblClick={onDblClick}
        onFocus={onFocus}
        onChange={onChange}
        onKeyPress={onKeyPress}
        onAutocomplete={onAutocomplete}
        placeholder={placeholder}
        searchButtonLabel={placeholder}
        changeValue={changeValue}
        autocompleteValue={autocompleteValue}
        value={value}
        isModalSuggestionsShown={isModalSuggestionsShown}
      />
      <input
        className={style.fakeSubmitButton}
        type="submit"
        tabIndex={-1}
        aria-hidden="true"
        value={placeholder}
      />
    </form>
  );
};
