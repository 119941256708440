import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type {
  TextMaskDataProps,
  TextMaskDefinition,
  TextMaskPropsCarmiProps,
} from '../TextMask.types';
import { props as viewerPropsMapper } from './TextMask.mapper';

const props = composeMapper<
  TextMaskDataProps,
  TextMaskDefinition,
  TextMaskPropsCarmiProps
>()(
  viewerPropsMapper,
  { add: ['isPlayingAllowed'] },
  (resolver, deps, carmiData) => {
    const viewerProps: any = resolver(deps, carmiData);
    const { isPlayingAllowed } = deps;
    // We need to clone fill layers so we don't override the original autoplay
    const fillLayers = JSON.parse(JSON.stringify(viewerProps.fillLayers));
    if (fillLayers?.video?.videoInfo?.autoPlay) {
      fillLayers.video.videoInfo.autoPlay = isPlayingAllowed;
    }
    return {
      ...viewerProps,
      fillLayers,
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
