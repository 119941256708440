import React from 'react';

export const ArrowDown: React.FC = () => (
  <svg viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.191928 1.49603L1.47573 0.198644C1.53857 0.135675 1.61321 0.0857166 1.69539 0.0516304C1.77756 0.0175441 1.86565 0 1.95461 0C2.04357 0 2.13166 0.0175441 2.21383 0.0516304C2.296 0.0857166 2.37065 0.135675 2.43349 0.198644L7.99662 5.81612L13.5598 0.198644C13.6226 0.135675 13.6972 0.0857166 13.7794 0.0516304C13.8616 0.0175441 13.9497 0 14.0386 0C14.1276 0 14.2157 0.0175441 14.2979 0.0516304C14.38 0.0857166 14.4547 0.135675 14.5175 0.198644L15.8013 1.49603C16.0662 1.76094 16.0662 2.20246 15.8013 2.46737L8.4789 9.8713C8.41606 9.93427 8.34141 9.98423 8.25924 10.0183C8.17707 10.0524 8.08898 10.07 8.00002 10.07C7.91106 10.07 7.82297 10.0524 7.7408 10.0183C7.65863 9.98423 7.58398 9.93427 7.52114 9.8713L0.198722 2.46737C0.0713654 2.3378 0 2.16338 0 1.9817C0 1.80002 0.0713654 1.6256 0.198722 1.49603H0.191928Z" />
  </svg>
);

export const ArrowUp: React.FC = () => (
  <svg viewBox="0 0 16 11" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.8081 8.57404L14.5243 9.87142C14.4614 9.93439 14.3868 9.98435 14.3046 10.0184C14.2224 10.0525 14.1344 10.0701 14.0454 10.0701C13.9564 10.0701 13.8683 10.0525 13.7862 10.0184C13.704 9.98435 13.6294 9.93439 13.5665 9.87142L8.00338 4.25395L2.44024 9.87143C2.3774 9.9344 2.30276 9.98435 2.22058 10.0184C2.13841 10.0525 2.05032 10.0701 1.96136 10.0701C1.8724 10.0701 1.78431 10.0525 1.70214 10.0184C1.61996 9.98435 1.54532 9.9344 1.48248 9.87143L0.198683 8.57404C-0.0662288 8.30913 -0.0662289 7.86761 0.198682 7.6027L7.5211 0.198766C7.58394 0.135796 7.65859 0.0858367 7.74076 0.0517505C7.82293 0.0176642 7.91102 0.000118793 7.99998 0.000118785C8.08894 0.000118777 8.17703 0.0176642 8.2592 0.0517504C8.34137 0.0858367 8.41602 0.135796 8.47886 0.198766L15.8013 7.6027C15.9286 7.73227 16 7.90668 16 8.08837C16 8.27005 15.9286 8.44446 15.8013 8.57404L15.8081 8.57404Z" />
  </svg>
);
