import React, { useMemo } from 'react';
import classNames from 'clsx';
import { formatClassNames } from '@wix/editor-elements-common-utils';
import semanticClassNames from '../FastGallery.semanticClassNames';
import type { IFastGalleryCompProps } from '../FastGallery.types';
import { itemsConverter } from './FastGallery.galleryUtils';
import styles from './style/FastGallery.scss';
import { Gallery } from '@wix/fast-gallery-components';
import type {
  EventData,
  EventsListenerFunction,
} from '@wix/fast-gallery-library';
import { GALLERY_EVENTS } from '@wix/fast-gallery-library';

const FastGallery: React.FC<IFastGalleryCompProps> = props => {
  const {
    id,
    customClassNames = [],
    wixSDKitems,
    items,
    onItemClicked = () => {},
  } = props;

  const galleryItems = useMemo(
    () => wixSDKitems || itemsConverter(items),
    [wixSDKitems, items],
  );

  const eventsListener: EventsListenerFunction = (
    eventName: GALLERY_EVENTS,
    eventData: EventData,
  ) => {
    switch (eventName) {
      case GALLERY_EVENTS.ON_ITEM_CLICKED:
        onItemClicked({
          itemIndex: eventData.idx,
          type: eventData.type,
        });
        break;
      default:
        break;
    }
  };

  return (
    <Gallery
      galleryId={id}
      dataList={galleryItems.map(item => {
        return {
          ...item,
          url: item.mediaUrl,
        };
      })}
      eventsListener={eventsListener}
      selectors={{
        root: classNames(
          'root',
          // only for the root
          formatClassNames(semanticClassNames.root, ...customClassNames),
          styles.root,
        ),
        container: classNames(
          'container-reset',
          'container',
          formatClassNames(semanticClassNames.container),
        ),
        item: classNames(
          'item-reset',
          'item',
          formatClassNames(semanticClassNames.item),
          styles.item,
        ),
        image: classNames(
          'image',
          formatClassNames(semanticClassNames.image),
          styles.image,
        ),
        info: classNames(
          'info',
          formatClassNames(semanticClassNames.info),
          styles.info,
        ),
        overlay: classNames('overlay', styles.overlay),
      }}
    />
  );
};

export default FastGallery;
