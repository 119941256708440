import classNames from 'clsx';
import * as React from 'react';
import { getDataAttributes } from '@wix/editor-elements-common-utils';
import { ISeoTpaGalleryProps } from '../SeoTpaGallery.types';
import style from './style/SeoTpaGallery.scss';

const LinkWrapper = ({
  href,
  target,
  children,
}: {
  href?: string;
  target?: string;
  children: React.ReactNode;
}) =>
  href ? (
    <a href={href} target={target}>
      {children}
    </a>
  ) : (
    <>{children}</>
  );

const SeoTpaGallery: React.FC<ISeoTpaGalleryProps> = props => {
  const { id: compId, className, type, images = [] } = props;
  return (
    <table
      id={compId}
      {...getDataAttributes(props)}
      data-testid={type}
      className={classNames(className, style.root)}
    >
      <tbody>
        {images.map((rowData, key) => (
          <tr key={key}>
            {rowData.map(
              ({
                alt,
                description,
                height,
                href,
                id,
                target,
                title,
                uri,
                width,
              }) => (
                <th
                  key={id}
                  id={id}
                  itemScope
                  itemType="http://schema.org/ImageObject"
                >
                  <LinkWrapper href={href} target={target}>
                    <img
                      src={uri}
                      alt={alt}
                      itemProp="contentURL"
                      width={width}
                      height={height}
                    />
                  </LinkWrapper>
                  <div>
                    {title && <h2 itemProp="name">{title}</h2>}
                    {description && (
                      <h3 itemProp="description">{description}</h3>
                    )}
                  </div>
                </th>
              ),
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default SeoTpaGallery;
