
import { withCompController } from '@wix/editor-elements-integrations';
import type {
  ISlotsPlaceholderProps,
  ISlotsPlaceholderMapperProps,
  ISlotsPlaceholderControllerProps,
  ISlotsPlaceholderStateValues,
} from '../../../components/SlotsPlaceholder/SlotsPlaceholder.props';

export default withCompController<
  ISlotsPlaceholderMapperProps,
  ISlotsPlaceholderControllerProps,
  ISlotsPlaceholderProps,
  ISlotsPlaceholderStateValues
>(({ mapperProps, controllerUtils, stateValues }) => {
  const { editorType } = stateValues
  return {
    ...mapperProps,
    editorType,
    updateComponentPropsInViewer: props => {
      controllerUtils.updateProps(props);
    }
  }
});
