import * as React from 'react';
import classNames from 'clsx';
import { activateBySpaceOrEnterButton } from '@wix/editor-elements-common-utils';
import SiteMembersDialogLayout from '../../SiteMembersDialogLayout/viewer/SiteMembersDialogLayout';
import { IConfirmationEmailDialogProps } from '../ConfirmationEmailDialog.types';
import style from './style/style.scss';
import {
  confirmationEmailDialogTranslationKeys as translationKeys,
  testIds,
} from './constants';

const ConfirmationEmailDialog: React.FC<
  IConfirmationEmailDialogProps
> = props => {
  const {
    id,
    className,
    isSignUp,
    onCloseDialogCallback,
    onResendConfirmationEmail,
    translations,
  } = props;
  const headlineId = `ConfirmationEmailHeadline_${id}`;
  const title = translations[translationKeys.title];
  const signUpTitle = translations[translationKeys.signUpTitle];
  const subtitle = translations[translationKeys.subtitle];
  const signUpSubtitle = translations[translationKeys.signUpSubtitle];
  const signUpSecondarySubtitle =
    translations[translationKeys.signUpSecondarySubtitle];
  const description = translations[translationKeys.description];
  const descriptionSecondary =
    translations[translationKeys.descriptionSecondary];
  const descriptionLink = translations[translationKeys.descriptionLink];

  return (
    <SiteMembersDialogLayout
      isCloseable={true}
      onCloseDialogCallback={onCloseDialogCallback}
      headlineId={headlineId}
      className={className}
      translations={translations}
    >
      <div
        id={id}
        className={classNames(style.confirmationEmailDialog, {
          [style.emailVerification]: !isSignUp,
        })}
      >
        <div className={style.header}>
          <h2 id={headlineId} className={style.title}>
            {isSignUp ? signUpTitle : title}
          </h2>
          {!isSignUp && <p className={style.subtitle}>{subtitle}</p>}
        </div>
        <div className={style.content}>
          {isSignUp && (
            <div className={style.signUpSubtitle}>
              <div data-testid={testIds.signUpSubtitle}>{signUpSubtitle}</div>
              <div data-testid={testIds.signUpSecondarySubtitle}>
                {signUpSecondarySubtitle}
              </div>
            </div>
          )}
          <div data-testid={testIds.description}>{description}</div>
          <div data-testid={testIds.secondaryDescription}>
            {descriptionSecondary}
            <span
              className={style.resendConfirmationEmailButton}
              data-testid={testIds.resendConfirmationEmailButton}
              onClick={onResendConfirmationEmail}
              role="button"
              tabIndex={0}
              onKeyDown={activateBySpaceOrEnterButton}
            >
              {descriptionLink}
            </span>
          </div>
        </div>
      </div>
    </SiteMembersDialogLayout>
  );
};

export default ConfirmationEmailDialog;
