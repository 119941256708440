import type { MenuItemProps } from '@wix/thunderbolt-components-native';
import type { SocialLink } from '../MobileActionsMenu.types';
import type { MobileActionsButtonMenuItem } from './MobileActionButtonMenu';

export const socialLinksToButtonMenuItems = (
  socialLinks: Array<SocialLink>,
): Array<MobileActionsButtonMenuItem> => {
  let resultList: Array<MobileActionsButtonMenuItem> = [];
  if (socialLinks && socialLinks.length > 0) {
    resultList = socialLinks.map(socialLink => {
      return {
        label: socialLink.label,
        link: {
          href: socialLink.url,
          target: '_blank',
        },
      };
    });
  }
  return resultList;
};

export const pageLinksToButtonMenuItems = (
  pageLinks: Array<MenuItemProps>,
): Array<MobileActionsButtonMenuItem> => {
  const resultList: Array<MobileActionsButtonMenuItem> = [];
  if (pageLinks && pageLinks.length > 0) {
    pageLinks.forEach(pageLink => {
      if (pageLink.items && pageLink.items.length > 0) {
        resultList.push({
          items: pageLinksToButtonMenuItems(pageLink.items),
        });
      } else {
        resultList.push({
          label: pageLink.label,
          link: pageLink.link,
        });
      }
    });
  }
  return resultList;
};
