import * as React from 'react';
import type {
  IIFrameConfig,
  IOnBackendSocialLogin,
  IPrivacyStatus,
} from '../MemberLoginDialog.types';
import { IGNORED_ERRORS } from './utils';
import { usePopUrl } from './usePopUrl';

export const useIDPPopup = (
  iframeConfig: IIFrameConfig,
  reportSocialAuthStarted: (idpConnectionId: string) => void,
  onBackendSocialLogin: IOnBackendSocialLogin,
  onError: (error: any) => void,
) => {
  const openPopUp = usePopUrl();

  return React.useCallback(
    async (idpConnectionId: string) => {
      reportSocialAuthStarted(idpConnectionId);

      const queryString = new URLSearchParams({
        visitorId: iframeConfig.biVisitorId,
        bsi: iframeConfig.bsi,
        svSession: iframeConfig.svSession,
        privacyStatus: getPrivacyStatus(iframeConfig.isCommunityChecked),
        tenantType: 'SITE',
      }).toString();

      const baseUrl = `/_api/iam/authentication/v1/sso/login/${iframeConfig.metaSiteId}/${idpConnectionId}`;
      const url = `${baseUrl}?${queryString}`;

      try {
        const loginResponse = await openPopUp(url);
        await onBackendSocialLogin(
          loginResponse,
          idpConnectionId,
          iframeConfig.isCommunityChecked,
        );
      } catch (error) {
        if (!IGNORED_ERRORS.includes(error as any)) {
          onError(error);
        }
      }
    },
    [
      reportSocialAuthStarted,
      onBackendSocialLogin,
      openPopUp,
      onError,
      iframeConfig,
    ],
  );
};

export const getPrivacyStatus = (
  isJoinCommunityChecked: boolean,
): IPrivacyStatus => {
  return isJoinCommunityChecked ? 'PUBLIC' : 'PRIVATE';
};
