import {
  createComponentMapperModel,
  withCompInfo,
} from '@wix/editor-elements-integrations';
import { MasonryDefinition } from '@wix/thunderbolt-components-native';
import { getCompStyles } from '../../../tpaGallery';
import {
  MasonryPlatformProps,
  MasonryPropsCarmiData,
  MasonryCSSVars,
} from '../Masonry.types';
import {
  generateCss,
  generateSdkData,
  propsDependencies,
  propsResolver,
} from '../../TPAGallery/viewer/TPAGalleryMapperUtil';

const compStyleKeys = {
  backgroundMouseoverColor: 'color4',
  descriptionColor: 'color2',
  textBackgroundColor: 'color3',
  textButtonColor: 'color5',
  textColor: 'color1',
};

const version1LegacyStyles: Record<string, string | number> = {
  textColor: '#000',
  descriptionColor: '#000',
  textBackgroundColor: '#fff',
  backgroundMouseoverColor: '#000',
  alphaBackgroundMouseoverColor: 0.4,
};

export const props = withCompInfo<
  MasonryPlatformProps,
  MasonryDefinition,
  MasonryPropsCarmiData
>()(
  [...propsDependencies, 'styleProperties', 'siteColors'],
  (data, carmiProps) => {
    const { styleProperties, siteColors } = data;
    let compStyles = getCompStyles(compStyleKeys, styleProperties, siteColors);
    if (
      !styleProperties.version ||
      parseInt(styleProperties.version, 10) === 1
    ) {
      compStyles = { ...compStyles, ...version1LegacyStyles };
    }

    return {
      ...propsResolver<MasonryDefinition>(
        data,
        carmiProps,
        'Masonry/Masonry.html',
      ),
      compStyles,
    };
  },
);

const sdkData = generateSdkData<MasonryDefinition>();

const css = generateCss<MasonryCSSVars, MasonryDefinition>();

export default createComponentMapperModel({ css, props, sdkData });
