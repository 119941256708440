import { createComponentPreviewMapperModel } from '@wix/editor-elements-integrations';
import { composeMapper } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { DatePickerCarmiProps } from '@wix/thunderbolt-components-native';
import {
  IDatePickerMapperProps,
  DatePickerDefinition,
} from '../DatePicker.types';
import { props as viewerPropsMapper } from './DatePicker.mapper';

const props = composeMapper<
  PreviewWrapperProps<IDatePickerMapperProps>,
  DatePickerDefinition,
  DatePickerCarmiProps
>()(
  viewerPropsMapper,
  {
    add: [
      'compPreviewState',
      'shouldResetComponent',
      'componentViewMode',
      'isConnectedToDataBinding',
    ],
  },
  (resolver, dependencies, carmiData) => {
    const viewerProps = resolver(dependencies, carmiData);
    const {
      shouldResetComponent,
      compPreviewState,
      componentViewMode,
      compProps,
      isConnectedToDataBinding,
    } = dependencies;
    return {
      ...viewerProps,
      previewWrapperProps: {
        shouldResetComponent,
        componentViewMode,
        compPreviewState,
        defaultTextType: compProps.defaultTextType,
        isConnectedToDataBinding,
      },
    };
  },
);

export default createComponentPreviewMapperModel({
  props,
});
