import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import type { Direction } from '@wix/editor-elements-common-utils';
import {
  getDataAttributes,
  formatClassNames,
  useDomDirection,
  INNER_FOCUS_RING_CLASSNAME,
} from '@wix/editor-elements-common-utils';
import type {
  IBreadcrumbsProps,
  IBreadcrumbProps,
  ISeparator,
  IBreadcrumb,
} from '../Breadcrumbs.types';
import {
  canRenderEllipsis,
  getHeadIndex,
  getTailIndex,
} from '../Breadcrumbs.utils';
import semanticClassNames from '../Breadcrumbs.semanticClassNames';
import { st, classes } from './style/Breadcrumbs.component.st.css';

type Separator = ISeparator | 'smaller-than' | 'reverse-slash';

const separatorsHash: Record<Separator, string> = {
  'greater-than': '>',
  'smaller-than': '<',
  slash: '/',
  'reverse-slash': '\\',
};

const getDisplayedSeparator = (
  separator: IBreadcrumbsProps['separator'],
  direction?: Direction,
): Separator => {
  if (separator === 'greater-than') {
    return separator;
  }
  return direction === 'rtl' ? 'reverse-slash' : 'slash';
};

const Icon: React.FC<{ src: string }> = ({ src }) => {
  return React.createElement('span', {
    dangerouslySetInnerHTML: {
      __html: src,
    },
    className: classes.icon,
  });
};

const SeparatorElement: React.FC<{ separator: Separator }> = ({
  separator,
}) => (
  <span aria-hidden="true" className={classes.separator}>
    {separatorsHash[separator] || separator}
  </span>
);

const Breadcrumb: React.FC<
  Omit<IBreadcrumbProps, 'separator'> & {
    separator: Separator;
    onClick?: () => void;
    title?: string;
    isEllipsis?: boolean;
  }
> = ({
  icon,
  label,
  link: url,
  separator,
  isCurrent,
  onClick,
  title = label,
  isEllipsis,
}) => {
  const BreadcrumbTag = url ? 'a' : 'button';
  const className = st(
    classes['breadcrumb-content'],
    {
      isCurrent: Boolean(isCurrent),
    },
    INNER_FOCUS_RING_CLASSNAME,
  );

  return (
    <li
      className={st(classes.breadcrumb, icon ? classes['breadcrumb-icon'] : '')}
    >
      <BreadcrumbTag
        aria-haspopup={true}
        aria-label={title}
        {...(isCurrent && { 'aria-current': 'page' })}
        {...(isEllipsis && { 'data-breadcrumb-ellipsis': 'true' })}
        className={className}
        onClick={onClick}
        {...(url && {
          href: url,
          target: '_self',
        })}
        data-breadcrumb-focus
      >
        <span className={classes.label}>
          {icon ? <Icon src={icon} /> : label}
        </span>
      </BreadcrumbTag>
      <SeparatorElement separator={separator} />
    </li>
  );
};

const PreviousPage: React.FC<{
  breadcrumbs: IBreadcrumbsProps['breadcrumbs'];
}> = ({ breadcrumbs }) => {
  const previousPage = React.useMemo(() => {
    const currentPageIndex = breadcrumbs.findIndex(item => item.isCurrent);

    for (let i = currentPageIndex - 1; i >= 0; i--) {
      if (breadcrumbs[i].link) {
        return breadcrumbs[i];
      }
    }

    return breadcrumbs[0];
  }, [breadcrumbs]);

  return previousPage ? (
    <Breadcrumb {...previousPage} separator="smaller-than" />
  ) : null;
};

type BreadcrumbsContentProps = Pick<
  IBreadcrumbsProps,
  | 'itemsAfterCollapse'
  | 'itemsBeforeCollapse'
  | 'breadcrumbs'
  | 'showHomePage'
  | 'showCurrentPage'
> & {
  displayedSeparator: Separator;
  showEllipsis: boolean;
  onEllipsisClick: () => void;
};

const BreadcrumbsContent: React.FC<BreadcrumbsContentProps> = props => {
  const {
    breadcrumbs,
    showEllipsis,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    displayedSeparator,
    onEllipsisClick,
    showCurrentPage,
    showHomePage,
  } = props;

  const getBreadcrumb = (item: IBreadcrumb, key: number) => (
    <Breadcrumb key={key} {...item} separator={displayedSeparator} />
  );

  if (showEllipsis && canRenderEllipsis(props)) {
    const headIndex = getHeadIndex(itemsBeforeCollapse, showHomePage);
    const tailIndex = getTailIndex(itemsAfterCollapse, showCurrentPage);

    const head = breadcrumbs.slice(0, headIndex).map(getBreadcrumb);
    const tail = tailIndex
      ? breadcrumbs.slice(-tailIndex).map(getBreadcrumb)
      : [];

    return (
      <>
        {head}
        <Breadcrumb
          key="ellipsis"
          separator={displayedSeparator}
          onClick={onEllipsisClick}
          label="..."
          title="Reveal the full breadcrumbs list"
          isEllipsis
        />
        {tail}
      </>
    );
  } else {
    return <>{breadcrumbs.map(getBreadcrumb)}</>;
  }
};

const focusElementAfterExpand = (list: HTMLOListElement | null, i: number) =>
  setTimeout(
    () =>
      (
        list?.querySelectorAll('[data-breadcrumb-focus]')[i] as HTMLElement
      )?.focus(),
    100,
  );

// https://www.w3.org/TR/wai-aria-practices-1.1/examples/breadcrumb/index.html
const Breadcrumbs: React.ForwardRefRenderFunction<
  HTMLDivElement,
  IBreadcrumbsProps
> = (props, ref) => {
  const {
    id,
    breadcrumbs,
    itemsBeforeCollapse,
    itemsAfterCollapse,
    separator,
    shouldWrap,
    showOnlyPreviousPageOnMobile,
    className,
    customClassNames = [],
    stylableClassName,
    onMouseEnter,
    onMouseLeave,
    showCurrentPage,
    showHomePage,
    onEllipsisVisibilityChange,
  } = props;

  const listRef = useRef<HTMLOListElement>(null);
  const { direction, directionRef } = useDomDirection();
  const displayedSeparator = getDisplayedSeparator(separator, direction);
  const [showEllipsis, setShowEllipsis] = useState<boolean>(!shouldWrap);

  useEffect(() => {
    setShowEllipsis(!shouldWrap);
  }, [shouldWrap]);

  const handleEllipsisClick = () => {
    onEllipsisVisibilityChange(false);
    setShowEllipsis(false);
    focusElementAfterExpand(listRef.current, itemsBeforeCollapse);
  };

  const contentClassName = showOnlyPreviousPageOnMobile
    ? classes['only-previous']
    : '';

  return (
    <div
      id={id}
      className={className}
      {...getDataAttributes(props)}
      ref={ref}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <nav
        ref={directionRef}
        aria-label="breadcrumbs"
        className={st(
          classes.root,
          stylableClassName,
          formatClassNames(semanticClassNames.root, ...customClassNames),
        )}
      >
        <ol className={st(classes.list, contentClassName)} ref={listRef}>
          {showOnlyPreviousPageOnMobile ? (
            <PreviousPage breadcrumbs={breadcrumbs} />
          ) : (
            <BreadcrumbsContent
              showEllipsis={showEllipsis}
              itemsBeforeCollapse={itemsBeforeCollapse}
              itemsAfterCollapse={itemsAfterCollapse}
              breadcrumbs={breadcrumbs}
              displayedSeparator={displayedSeparator}
              onEllipsisClick={handleEllipsisClick}
              showCurrentPage={showCurrentPage}
              showHomePage={showHomePage}
            />
          )}
        </ol>
      </nav>
    </div>
  );
};

export default React.forwardRef(Breadcrumbs);
