export const DEFAULT_SHAPE_SIZE = 25;
export const DEFAULT_SHAPE_SPACING = 8;

export const TestIds = {
  ratingsIcon: 'ratingsIcon',
  a11yLabel: 'a11yLabel',
} as const;

export const DataHooks = {
  ratingInputTitle: 'rating-input-title',
} as const;

export const inputLeftShiftExperiment =
  'specs.thunderbolt.fixRatingsInputLeftShift';
