import { withCompController } from '@wix/editor-elements-integrations';
import type {
  IToggleSwitchControllerProps,
  IToggleSwitchMapperProps,
  IToggleSwitchProps,
} from '../ToggleSwitch.types';

export default withCompController<
  IToggleSwitchMapperProps,
  IToggleSwitchControllerProps,
  IToggleSwitchProps
>(({ mapperProps, controllerUtils }) => {
  return {
    ...mapperProps,
    onChange: event => {
      controllerUtils.updateProps({
        checked: event.target.checked,
      });
    },
  };
});
