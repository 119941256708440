import type { ITinyMenuItemProps } from '../../TinyMenu.types';

export const getItemsWithSelection = (
  items: Array<ITinyMenuItemProps>,
  currentHref?: string,
  currentAnchor?: string,
): Array<ITinyMenuItemProps> => {
  return items.reduce((itemsWithSelection: Array<ITinyMenuItemProps>, item) => {
    const { link = {}, items: subItems = [] } = item;
    const isPageLink = !link.anchorDataId;

    // Step 1: Checking if the link points to the current href or anchor
    const hasCurrentHrefInLink =
      isPageLink && currentHref && link.href === currentHref;
    const hasCurrentAnchorInLink =
      currentAnchor && link.anchorDataId === currentAnchor;

    const subItemsWithSelection = getItemsWithSelection(
      subItems,
      currentHref,
      currentAnchor,
    );

    // Step 2: Checking if one of the child links points to the current href or anchor. Notice it's relevant only for page link
    const shouldBeSelectedBySubItem =
      isPageLink && subItemsWithSelection.some(child => child.isSelected);

    // Step 3: Removing selection from all sibling items in case the link points to an anchor
    const itemsWithCorrectSelection = hasCurrentAnchorInLink
      ? itemsWithSelection.map(itemWithSelection => ({
          ...itemWithSelection,
          isSelected: false,
        }))
      : itemsWithSelection;

    return [
      ...itemsWithCorrectSelection,
      {
        ...item,
        items: subItemsWithSelection,
        isSelected:
          hasCurrentHrefInLink ||
          hasCurrentAnchorInLink ||
          shouldBeSelectedBySubItem,
      },
    ];
  }, []);
};

export const getSubItemsWithClickHandler = (
  item: ITinyMenuItemProps,
  onClick: (item: ITinyMenuItemProps) => void,
) =>
  item.items?.map((subItem: ITinyMenuItemProps) => ({
    ...subItem,
    onClick: () => onClick(subItem),
  }));

export const hasSelectedSubItem = (subMenuItems?: Array<ITinyMenuItemProps>) =>
  subMenuItems?.some(item => item.isSelected);
