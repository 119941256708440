import * as React from 'react';

import SearchSymbolSvg from '../../assets/SearchSymbol.svg';
import { SuggestionFooterProps } from '../Suggestion.types';
import { ShowAllButton } from './ShowAllButton';

import style from './Footer.scss';

export const Footer: React.FC<SuggestionFooterProps> = ({
  id,
  onSuggestionsFooterClick,
  query,
  translations,
  useModalSuggestions,
}) => {
  const text = `${translations.footerSearchAllText} "${query}"`;

  const handleClick = () => {
    onSuggestionsFooterClick?.({ type: 'suggestionsFooterClick', compId: id });
  };

  return useModalSuggestions ? (
    <ShowAllButton onClick={handleClick}>{text}</ShowAllButton>
  ) : (
    <button
      className={style.root}
      role="link"
      data-testid="footer-link"
      onClick={handleClick}
    >
      <SearchSymbolSvg className={style.icon} />
      <span className={style.text}>{text}</span>
    </button>
  );
};
