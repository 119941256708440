export const biEvents = {
  ONE_APP_FREE_BANNER_SHOWN: {
    evid: 428,
    src: 42,
  },
  ONE_APP_FREE_BANNER_CLICK: {
    evid: 429,
    src: 42,
  },
};
