import * as React from 'react';
import SiteButton from '../../SiteButton';
import type { SkinButtonProps } from '../SkinButton.types';
import type {
  ISiteButtonImperativeActions,
  ISiteButtonProps,
} from '../../../SiteButton.types';
import ButtonArrowBaseSkin from './ButtonArrow';
import skinsStyle from './styles/ButtonArrow.scss';

const ButtonArrowSkin = React.forwardRef<
  ISiteButtonImperativeActions,
  SkinButtonProps
>((props, ref) => (
  <ButtonArrowBaseSkin
    {...props}
    skinsStyle={skinsStyle}
    ref={ref}
  ></ButtonArrowBaseSkin>
));

const ButtonArrow: React.ForwardRefRenderFunction<
  ISiteButtonImperativeActions,
  Omit<ISiteButtonProps, 'skin'>
> = (props, ref) => <SiteButton {...props} skin={ButtonArrowSkin} ref={ref} />;

export default React.forwardRef(ButtonArrow);
