import { storeGetters } from '@wix/editor-elements-preview-utils/panels';
import type { EditorAPI } from '@wix/editor-elements-types/editor';
import type { DS } from '@wix/editorx-core-api';
import { useEffect } from 'react';

interface UseSelectionPreviewStateProps {
  isInSelectionMode: boolean;
  enterSelectionPreviewState: () => void;
  exitSelectionPreviewState: () => void;
}

export type SelectionPreviewStateMapStateToProps = ReturnType<
  typeof selectionPreviewStateMapStateToProps
>;

const useSelectionPreviewState = ({
  isInSelectionMode,
  enterSelectionPreviewState,
  exitSelectionPreviewState,
}: UseSelectionPreviewStateProps) => {
  useEffect(() => {
    if (isInSelectionMode) {
      enterSelectionPreviewState();
    } else {
      exitSelectionPreviewState();
    }

    return () => {
      exitSelectionPreviewState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInSelectionMode]);
};

interface EnterExitSelectionPreviewStateProps {
  editorAPI: EditorAPI;
  compRef: DS.CompRef;
}

const enterSelectionPreviewState = ({
  editorAPI,
  compRef,
}: EnterExitSelectionPreviewStateProps) => {
  editorAPI.dsActions.documentMode.setComponentPreviewState(
    compRef.id,
    storeGetters.StageModes.Selected,
  );
};

const exitSelectionPreviewState = ({
  editorAPI,
  compRef,
}: EnterExitSelectionPreviewStateProps) => {
  editorAPI.dsActions.documentMode.setComponentPreviewState(compRef.id, null);
};

const isPreviewStateSelection = (previewState: string | null) =>
  previewState === storeGetters.StageModes.Selected;

const selectionPreviewStateMapStateToProps = ({
  editorAPI,
}: {
  editorAPI: EditorAPI;
}) => {
  const [compRef] = editorAPI.selection.getSelectedComponents() as [DS.CompRef];
  const selectModeAPI = storeGetters.stageModesAPI.selected(editorAPI);

  return {
    enterSelectionPreviewState: () =>
      enterSelectionPreviewState({
        editorAPI,
        compRef,
      }),
    exitSelectionPreviewState: () =>
      exitSelectionPreviewState({
        editorAPI,
        compRef,
      }),
    isInSelectionMode: selectModeAPI.isIn(),
  };
};

export const selectionPreviewStateUtils = {
  useSelectionPreviewState,
  selectionPreviewStateMapStateToProps,
  isPreviewStateSelection,
};
