import {
  withCompInfo,
  createComponentMapperModel,
} from '@wix/editor-elements-integrations';
import type {
  SliderGalleryProps,
  SliderGalleryCarmiData,
  SliderGalleryCSSVars,
  SliderGalleryDefinition,
  ISliderGallerySdkData,
} from '../SliderGallery.types';
import { translations } from '../constants';

export const props = withCompInfo<
  SliderGalleryProps,
  SliderGalleryDefinition,
  SliderGalleryCarmiData
>()(
  ['skin', 'compProps', 'isMobileView', 'translate'],
  ({ skin, compProps, isMobileView, translate }, carmiData) => {
    const {
      galleryImageOnClickAction,
      goToLinkText,
      aspectRatio,
      imageMode,
      loop,
      maxSpeed,
      margin,
    } = compProps;

    return {
      skin,
      goToLinkText: goToLinkText || 'Go to link',
      aspectRatio,
      keepImageAspectRatio: imageMode === 'flexibleWidth',
      items: carmiData.itemsProps,
      imageOnClickAction: galleryImageOnClickAction,
      playOnHover: !isMobileView,
      playInLoop: loop,
      maxSpeed,
      margin,
      translations: {
        galleryAriaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.SLIDER_GALLERY_ARIA_LABEL_KEY,
          ) || translations.SLIDER_GALLERY_ARIA_LABEL_DEFAULT,
        playButtonAriaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.SLIDER_PLAY_BUTTON_DESCRIPTION_KEY,
          ) || translations.SLIDER_PLAY_BUTTON_DESCRIPTION_DEFAULT,
        playBackwardAriaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.SLIDER_PLAY_BACKWARD_ARIA_LABEL_KEY,
          ) || translations.SLIDER_PLAY_BACKWARD_ARIA_LABEL_DEFAULT,
        playForwardAriaLabel:
          translate(
            translations.ARIA_LABEL_NAMESPACE,
            translations.SLIDER_PLAY_BACKWARD_ARIA_LABEL_KEY,
          ) || translations.SLIDER_PLAY_FORWARD_ARIA_LABEL_DEFAULT,
      },
    };
  },
);

export const css = withCompInfo<
  SliderGalleryCSSVars,
  SliderGalleryDefinition
>()(['compProps'], ({ compProps }) => {
  const { margin } = compProps;

  return {
    '--spacing': `${margin}px`,
  };
});

export const sdkData = withCompInfo<
  ISliderGallerySdkData,
  SliderGalleryDefinition
>()(['compData'], ({ compData }) => {
  return {
    imageDisplayMode: 'fill', // remove once mot used in EE
    dataId: compData.id,
  };
});

export default createComponentMapperModel({ css, sdkData, props });
