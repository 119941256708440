import {
  createComponentMapperModel,
  withCompInfo,
  withStateRefsValues,
} from '@wix/editor-elements-integrations';
import { VideoBoxPlayProps } from '../../VideoBox/VideoBox.types';

export const props = withCompInfo<VideoBoxPlayProps, any, VideoBoxPlayProps>()(
  [],
  (_, carmiData) => {
    return carmiData;
  },
);

export const stateRefs = withStateRefsValues(['reducedMotion']);

export default createComponentMapperModel({ props, stateRefs });
