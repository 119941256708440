import React from 'react';
import { createComponentPreviewEntry } from '@wix/editor-elements-integrations';
import { useResetComponent } from '@wix/editor-elements-preview-utils';
import type { PreviewWrapperProps } from '@wix/editor-elements-types/thunderboltPreview';
import { IAudioPlayerProps } from '../AudioPlayer.types';

export default (ViewerComponent: React.ComponentType<IAudioPlayerProps>) =>
  createComponentPreviewEntry(function ({
    previewWrapperProps = {},
    ...viewerProps
  }: PreviewWrapperProps<
    IAudioPlayerProps,
    {
      shouldResetComponent?: boolean;
    }
  >) {
    const key = useResetComponent({
      id: viewerProps.id,
      shouldResetComponent: previewWrapperProps.shouldResetComponent,
    });
    return <ViewerComponent {...viewerProps} key={key} />;
  });
