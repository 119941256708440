import * as React from 'react';
import classNames from 'clsx';
import MenuButtonLink from '../../MenuButtonLink';
import MenuButtonBackground from '../../MenuButtonBackground';
import type { MenuButtonProps } from '../../../MenuButton.types';
import MenuButtonRoot from '../../MenuButtonRoot';

type SeparateShinyIIMenuButtonNSkinProps = MenuButtonProps & {
  skinsStyle: any;
  skin:
    | 'SeparateShinyIIMenuButtonNSkin'
    | 'SeparateShinyIIMenuButtonNBorderRadiusFixSkin';
};

const SeparateShinyIIMenuButton: React.FC<
  SeparateShinyIIMenuButtonNSkinProps
> = props => {
  const {
    id,
    isDropDownButton,
    'aria-haspopup': ariaHasPopup,
    'aria-describedby': ariaDescribedBy,
    isMoreButton,
    dir,
    textAlign,
    positionInList,
    link,
    skinsStyle,
    skin,
  } = props;

  return (
    <MenuButtonRoot
      {...props}
      className={classNames(props.className, skinsStyle[skin])}
    >
      {label => (
        <MenuButtonLink
          wrapperProps={{
            positionInList,
            ariaHasPopup: isDropDownButton ? ariaHasPopup : 'false',
            ariaDescribedBy,
            isMoreButton,
          }}
          link={link}
          className={skinsStyle.linkElement}
        >
          <div className={skinsStyle.gapper}>
            <div className={skinsStyle.color}>
              <div className={skinsStyle.shade}>
                <div className={skinsStyle.shade2} />
              </div>
            </div>
            <MenuButtonBackground
              wrapperProps={{ dir, textAlign, id }}
              classNames={{ bg: skinsStyle.bg, label: skinsStyle.label }}
            >
              {label}
            </MenuButtonBackground>
          </div>
        </MenuButtonLink>
      )}
    </MenuButtonRoot>
  );
};

export default SeparateShinyIIMenuButton;
