import * as React from 'react';
import { CoBrandingBannerProps } from '../../../CoBrandingBannerMobile.types';
import CoBrandingBanner from '../../CoBrandingBannerMobile';
import dayfulStyle from '../../styles/DayfulCoBranding.scss';
import logoUri from '../../assets/dayful-logo.svg?url';

const DayfulCoBrandingBanner: React.FC<CoBrandingBannerProps> = props => {
  return (
    <CoBrandingBanner
      {...props}
      style={dayfulStyle}
      brandName="Dayful"
      brandLogoUrl={logoUri}
      brandSiteUrl="https://www.dayful.com"
    />
  );
};

export default DayfulCoBrandingBanner;
